import React, { FC, Dispatch } from "react";
import {
    GamestoreApplicationListDataType,
    ListWidgetDataTypes,
    ListWidgetUnit,
    RequestStatisticsListDataType,
} from "src/shared/dtos";
import styled from "styled-components";
import { IUser } from "src/shared/client";
import { DragScrollBox } from "src/shared/components";
import { RequestStatisticsTable } from "./RequestStatisticsTable";
import { GamestoreAppsTable } from "./GamestoreAppsTable";

const Main = styled.div`
    height: 100%;
    padding: 10px;
`;

export interface ListWidgetProps {
    listWidget: ListWidgetUnit;
    user: IUser | null;
    setLoading: Dispatch<boolean>;
}

export const ListWidget: FC<ListWidgetProps> = ({ listWidget: { dataType }, user, setLoading }) => {
    return (
        <Main>
            <DragScrollBox>
                {dataType === ListWidgetDataTypes.LastUpdatedApplications &&
                    <GamestoreAppsTable
                        user={user}
                        setLoading={setLoading}
                        orderBy={GamestoreApplicationListDataType.ModifiedDate}
                        columns={["title", "id", "rating", "modifiedAt"]}
                    />}
                {dataType === ListWidgetDataTypes.TopRatedApplications &&
                    <GamestoreAppsTable
                        user={user}
                        setLoading={setLoading}
                        orderBy={GamestoreApplicationListDataType.Rating}
                        columns={["title", "id", "rating", "reviewsCount"]}
                    />}
                {dataType === ListWidgetDataTypes.RequestsByCount &&
                    <RequestStatisticsTable
                        user={user}
                        setLoading={setLoading}
                        orderBy={RequestStatisticsListDataType.Count} />}
                {dataType === ListWidgetDataTypes.RequestsByAverageTime &&
                    <RequestStatisticsTable
                        user={user}
                        setLoading={setLoading}
                        orderBy={RequestStatisticsListDataType.AverageMilliseconds} />}
                {dataType === ListWidgetDataTypes.RequestsByTotalTime &&
                    <RequestStatisticsTable
                        user={user}
                        setLoading={setLoading}
                        orderBy={RequestStatisticsListDataType.TotalMilliseconds} />}
            </DragScrollBox>
        </Main>
    );
};