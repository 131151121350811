import React, { FC, useCallback, useState, useEffect, useMemo } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { defer } from "rxjs";
import { LoadingButton, notifyError, notifySuccess } from "src/shared/components";
import { CrawledIoApplication, CrawlingStatus, IoApplicationProviderData, IoApplicationSourceSummary } from "src/shared/dtos";
import { capitalize, useSubscription } from "src/shared/helpers";
import styled from "styled-components";
import api from "../api";

const ProviderCardBody = styled.div<{ active: boolean }>`
    position: relative;
    border-width: 3px;
    border-style: solid;
    border-color: ${p => p.active ? "#28a745" : "#d3d6d8"};
    border-radius: .5rem;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    & p {
        margin-bottom: 0;
    }
`;

const ProviderCheckBox = styled.button<{ active: boolean }>`
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background-color: ${p => p.active ? "#28a745" : "#e2e3e5"};
    border-color: ${p => p.active ? "#28a745" : "#e2e3e5"};
    &:focus{
        outline: none;
    }
    &:hover{
        border-color: "#28a745";
    }
    &::after{
        content: "✓";
        color: ${p => p.active ? "#fff" : "transparent"};
    }
`;

const ProviderName = styled.p`
    font-size: 1.1rem;
`;

const InfoBlock = styled.div`
    margin-top: .8rem;
`;

interface ProviderCardProps {
    data: IoApplicationProviderData;
    active: boolean;
    select: (provider: string) => void;
}

const ProviderCard: FC<ProviderCardProps> = ({ data, select, active }) => {
    const onSelect = useCallback(() => select(data.provider!), [data, select]);

    return (
        <ProviderCardBody active={active} >
            <ProviderCheckBox active={active} onClick={onSelect} />
            <ProviderName>{data.provider || "Any"}</ProviderName>
            {data.urls && data.urls.length > 0 && (
                <InfoBlock>
                    <p>Info:</p>
                    {data.urls.map(x => (
                        <div key={`${x.url}_${x.language}`}>
                            {capitalize(x.language!)}:
                            {" "}
                            <a href={x.url}>{x.url}</a>
                        </div>
                    ))}
                </InfoBlock>
            )}
        </ProviderCardBody>
    );
};

export interface ProviderModalProps {
    isOpen: boolean;
    app?: IoApplicationSourceSummary;
    updateItem: (appId: string, data: Partial<IoApplicationSourceSummary>) => void;
    close: () => void;
}

export const SelectProviderModal: FC<ProviderModalProps> = (props) => {
    const { isOpen, close, updateItem, app } = props;
    const [provider, setProvider] = useState(app?.preferredProvider || "");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setProvider(app?.preferredProvider || "");
    }, [app]);

    const changeProvider = useCallback(setProvider, []);

    const crawlApplication = useSubscription((req: CrawledIoApplication) => defer(() => api.crawlApp(req))
        .subscribe({
            next: (crawledApp) => {
                updateItem(req.id!, { status: CrawlingStatus.Crawling, preferredProvider: crawledApp.preferredProvider, error: "" });
                setLoading(false);
                close();
                notifySuccess(`Application "${req.id}" has been added to crawler queue successfully.`);
            },
            error: () => {
                setLoading(false);
                close();
                notifyError(`Failed to add application "${req.id}" to crawler queue.`);
            }
        }), [updateItem]);

    const crawlApp = useCallback(() => {
        if (!app?.id) {
            return;
        }
        setLoading(true);
        crawlApplication({ id: app.id, provider });
    }, [app, crawlApplication, close, provider]);

    const providers = useMemo(
        () => [new IoApplicationProviderData({ provider: "" })].concat(app?.providers ?? []),
        [app]);

    return (
        <Modal isOpen={isOpen} toggle={close} centered size="lg">
            <ModalHeader toggle={close}>App Id: {app?.id}</ModalHeader>
            <ModalBody>
                <p className="mb-4">Select source</p>
                {providers.map(p => (
                    <ProviderCard
                        key={"provider_" + p.provider}
                        data={p}
                        select={changeProvider}
                        active={p.provider === provider}
                    />))}
            </ModalBody>
            <ModalFooter>
                <LoadingButton loading={loading} loadingText="Saving..." color="primary" onClick={crawlApp}>
                    Add to crawl
                </LoadingButton>{" "}
                <Button color="secondary" onClick={close}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>);
};

