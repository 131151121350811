import React, { useCallback } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import styled from "styled-components";

const StyledDatepicker = styled.div`
    .react-datepicker-popper {
        z-index: 2;
    }
    .react-datepicker__navigation {
        overflow: inherit;
    }

    .react-datepicker__navigation-icon {
        width: 100%;
    }

    .react-datepicker__navigation-icon--previous::before {
        left: 10px;
    }

    .react-datepicker__navigation-icon--next::before {
        right: 10px;
        left: auto;
    }
`;

interface Props extends ReactDatePickerProps {
    dates: string[];
}

export const StatisticsDatePicker = (props: Props) => {

    const isDateEnabled = useCallback((date: Date) => {
        if (props.dates === undefined) {
            return false;
        }
        return props.dates.find(i => new Date(i).valueOf() === new Date(date).valueOf()) !== undefined;
    }, [props.dates]);

    return (
        <StyledDatepicker>
            <DatePicker
                className="form-control"
                popperPlacement="top-end"
                selected={props.selected}
                onChange={props.onChange}
                filterDate={isDateEnabled}
                placeholderText={props.dates === undefined ? "Loading..." : ""}
                disabled={props.dates === undefined}
            />
        </StyledDatepicker>
    );
};
