import { Category } from "src/shared/dtos";
import { deepEqual } from "src/shared/helpers";

export type CategoryFormData = Omit<Category, "defaultKeyBindings"> & {
    defaultKeyBindings: string | undefined;
};

const getCategoryFormData = (cat: Category): CategoryFormData  => ({
    ...cat,
    defaultKeyBindings: cat.defaultKeyBindings === undefined ? undefined : JSON.stringify(cat.defaultKeyBindings, null, 4)
});

export type CategoryState = {
    current?: CategoryFormData,
    initial?: CategoryFormData,
    changed: boolean
};

export type CategoryAction =
    { kind: "set", value: Category, initialValue?: Category } |
    { kind: "update", value: Partial<CategoryFormData> };

export const categoryReducer = (state: CategoryState, action: CategoryAction) => {
    switch (action.kind) {
        case "set": {
            state = {
                current: getCategoryFormData(action.value),
                initial: action.initialValue === undefined ? undefined : getCategoryFormData(action.initialValue),
                changed: false
            };
            break;
        }
        case "update": {
            if (!state.current) {
                break;
            }
            Object.assign(state.current, action.value);
            state.changed = !deepEqual(state.current, state.initial);
            break;
        }
    }

    return Object.assign({}, state);
};

export const categoryReducerDefault: CategoryState = {
    current: undefined,
    initial: undefined,
    changed: false
};