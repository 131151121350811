import { client } from "src/shared/client";
import {
    DeleteSourceSettings,
    GetAdCampaigns,
    GetAdTargeting,
    GetApplicationsShortDetails,
    GetAvailableApplicationShortDetails,
    GetSourceSettings,
    GetSourceSettingsList,
    GetSourceSettingsMetadata,
    IsSourceNameExist,
    ManageSourceSettings,
    SourceSettings
} from "src/shared/dtos";

export const SourceSettingsApi = {
    list: () => client.get(new GetSourceSettingsList()),
    get: (id: number) => client.get(new GetSourceSettings({ id })),
    create: (sourceSettings: SourceSettings) => client.post(new ManageSourceSettings({ sourceSettings })),
    update: (sourceSettings: SourceSettings) => client.put(new ManageSourceSettings({ sourceSettings })),
    delete: (id: number) => client.delete(new DeleteSourceSettings({ id })),
    isSourceNameExist: (source: string) => client.get(new IsSourceNameExist({ source })),
    getMetadata: () => client.get(new GetSourceSettingsMetadata()),
    getAdCampaigns: () => client.get(new GetAdCampaigns()),
    getAdTargeting: () => client.get(new GetAdTargeting()),
    getAvailableApplications: (idSearch: string) =>
        client.get(new GetAvailableApplicationShortDetails({ idSearch, limit: 100 })),
    getApplicationsDetails: (ids: string[]) =>
        client.get(new GetApplicationsShortDetails({ ids, language: "en" }))
};

export default SourceSettingsApi;