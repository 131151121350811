import React from "react";
import styled from "styled-components";
import { CellProps } from "src/shared/components/ReactBaseTable";

// NB: Class name was taken from internal API, to have cell be rendered as if renderer was not
// replaced. This class name, as well as markup, can be changed in future implementations of
// 'react-base-table' component.
const rowCellTextClassName = "BaseTable__row-cell-text";

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Content = styled.span<{ centerText: boolean }>`
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: ${props => props.centerText ? "center" : "inherit"};
`;

export interface DefaultCellRendererProps<TItem> extends CellProps<unknown, TItem> {
    className?: string;
    title?: string;
    centerText?: boolean;
}

export function DefaultCellRenderer<TItem>(props: DefaultCellRendererProps<TItem>) {
    const isElement = typeof props.cellData !== "string"
        && React.isValidElement(props.cellData as {} | null | undefined);
    const stringContent = isElement
        ? undefined
        : (props.cellData ? (props.cellData ?? "") + "" : undefined);
    const title = props.title !== undefined ? props.title : stringContent;
    const content = isElement ? (props.cellData as JSX.Element) : stringContent;
    const className = props.className === undefined
        ? rowCellTextClassName
        : `${rowCellTextClassName} ${props.className}`;
    return (
        <Container className={className} title={title}>
            <Content centerText={props.centerText === true}>{content}</Content>
        </Container>
    );
}