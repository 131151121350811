import { ValidationOptions } from "react-hook-form";
import { regex } from "./regex";

const appendValidator = (options: ValidationOptions, name: string, validator: (value: string) => boolean | string) => {
    if (options.validate === undefined) {
        options.validate = {};
    } else if (typeof options.validate === "function") {
        options.validate = {
            __defaultValidator: options.validate
        };
    }

    if (options.validate[name] !== undefined) {
        throw new Error(`Validator '${name}' already exist.`);
    }

    options.validate[name] = validator;
};

type CustomValidators = {
    email?: boolean,
    url?: boolean,
    isValid?: boolean
};

/**
 * Sets default messages for the validation options.
 * @param options The validation options.
 */
export const formValidation = (options: ValidationOptions & CustomValidators) => {
    if (typeof options.required === "boolean") {
        options.required = "This field is required";
    }
    if (typeof options.minLength === "number") {
        options.minLength = {
            value: options.minLength,
            message: `Minimum ${options.minLength} characters required`
        };
    }
    if (typeof options.maxLength === "number") {
        options.maxLength = {
            value: options.maxLength,
            message: `Maximum ${options.maxLength} characters required`
        };
    }
    if (typeof options.min === "number") {
        options.min = {
            value: options.min,
            message: `Count should be above or equal to ${options.min}`
        };
    }
    if (typeof options.max === "number") {
        options.max = {
            value: options.max,
            message: `Count should be below or equal to ${options.max}`
        };
    }
    if (typeof options.pattern === "number") {
        options.pattern = {
            value: options.pattern,
            message: `Invalid format`
        };
    }

    // Custom validators.
    if (options.email === true) {
        delete options.email;
        appendValidator(
            options,
            "__custom_emailValidator",
            value => !value || regex.email.test(value) ? true : "Invalid email");
    }
    if (options.url === true) {
        delete options.url;
        appendValidator(
            options,
            "__custom_urlValidator",
            value => !value || regex.url.test(value) ? true : "Invalid URL");
    }
    if (options.isValid !== undefined) {
        const value = options.isValid;
        delete options.isValid;

        appendValidator(options, "__custom_validityValidator", () => value);
    }

    return options;
};