import React, { useCallback, forwardRef, useImperativeHandle, useMemo } from "react";
import { AdSideUnit, SideAdUnitTypes } from "src/shared/dtos";
import { highlightTargetUrl, StyledCodeEditor, TargetUrlPlaceholdersTip } from "./EditForm";
import { EditField, Select } from "src/shared/components";
import { SelectOption, setFromInput, useValidate, ValidateAsyncRef } from "src/shared/helpers";
import { sideAdUnitValidationSchema } from "./validationSchema";
import { Input } from "reactstrap";

interface Props {
    value: AdSideUnit;
    initial: AdSideUnit;
    update: React.Dispatch<Partial<AdSideUnit> | undefined>;
    saving: boolean;
}

const mapSideSelectOption = (side: SideAdUnitTypes) => {
    const value = side;
    const label = side;
    return { value, label } as SelectOption<SideAdUnitTypes | undefined>;
};

const sideOptions = Object.values(SideAdUnitTypes).map(mapSideSelectOption);

export const EditSideAdUnit = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const { value, initial, update, saving } = props;

    const setUrl = useCallback((url: string) => update({ url }), [update]);
    const setWidth = useCallback((width: number) => update({ width }), [update]);
    const setHeight = useCallback((height: number) => update({ height }), [update]);
    const setSide = useCallback(
        (sideOption: SelectOption<SideAdUnitTypes | undefined>) => update({ side: sideOption.value }), [update]);

    const selectedSideOption = useMemo(
        () => sideOptions.find(o => o.value === value.side),
    [value.side]);

    const { validate, errors, register } = useValidate({ schema: sideAdUnitValidationSchema, value: props.value });

    useImperativeHandle(ref, () => ({
        validate: async () => (await validate(true)).isValid
    }), [validate]);

    return (
        <React.Fragment>
            <EditField
                fieldId="side"
                label="Side"
                isChanged={value.side !== initial.side}
            >
                <Select
                    id="side"
                    placeholder="Side"
                    value={selectedSideOption}
                    onChange={setSide}
                    options={sideOptions}
                    isMulti={false}
                    isSearchable={false}
                    isDisabled={saving}
                />
            </EditField>
            <EditField
                ref={register("url")}
                label="Target URL"
                fieldId="url"
                isChanged={value.url !== initial.url}
                errors={errors?.url}
            >
                <StyledCodeEditor
                    id="url"
                    value={value.url ?? ""}
                    onValueChange={setUrl}
                    highlight={highlightTargetUrl}
                    padding={10}
                    tabSize={4}
                    insertSpaces={true}
                    disabled={saving}
                />
                <TargetUrlPlaceholdersTip />
            </EditField>
            <EditField
                label="Width"
                fieldId="width"
                isChanged={value.width !== initial.width}
            >
                <Input
                    type="number"
                    id="width"
                    onChange={setFromInput(setWidth)}
                    value={value.width ?? ""}
                />
            </EditField>
            <EditField
                label="Height"
                fieldId="height"
                isChanged={value.height !== initial.height}
            >
                <Input
                    type="number"
                    id="height"
                    onChange={setFromInput(setHeight)}
                    value={value.height ?? ""}
                />
            </EditField>
        </React.Fragment>
    );
});