import { FeaturedApplicationLocalization } from "src/shared/dtos";
import { validateSource, validateUrlField, ValidationSchema } from "src/shared/helpers";

export const localizationValidationSchema: ValidationSchema<FeaturedApplicationLocalization> = {
    bannerUrl: url => validateUrlField({ url, isRequired: true }),
    logoUrl: url => validateUrlField({ url }),
};

export const localizationImagesValidationSchema: ValidationSchema<FeaturedApplicationLocalization> = {
    bannerUrl: url => validateSource({url, type: "image"}),
    logoUrl: url => validateSource({url, type: "image"}),
};