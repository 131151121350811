import React, { useMemo, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { ApkPlatform } from "src/shared/dtos";
import styled from "styled-components";

const StyledDropdown = styled(Dropdown)`
    .btn {
        z-index: inherit;
    }
`;

export interface PlatformSelectorProps extends React.HTMLAttributes<HTMLDivElement> {
    value?: ApkPlatform;
    setValue: (value: ApkPlatform) => void;
    platforms: ApkPlatform[];
}

const platformNames: {[key in ApkPlatform]: string} = {
    "X32": "32 bit",
    "X64": "64 bit"
};

export const PlatformSelector = (props: PlatformSelectorProps) => {
    const { value, setValue, platforms, ...restProps } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const set = (v: ApkPlatform) => () => setValue(v);
    const disabled = useMemo(() => platforms.length === 0, [platforms]);

    return (
        <StyledDropdown isOpen={dropdownOpen} toggle={toggle} disabled={disabled} {...restProps}>
            <DropdownToggle caret>
                {platformNames[value ?? ApkPlatform.X32]}
            </DropdownToggle>
            <DropdownMenu right>
                {platforms.map(p =>
                    <DropdownItem key={platformNames[p]} onClick={set(p)} disabled={value === p}>
                        {platformNames[p]}
                    </DropdownItem>)}
            </DropdownMenu>
        </StyledDropdown>
    );
};