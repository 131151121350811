import React, { useCallback, useEffect, useMemo, forwardRef, useImperativeHandle } from "react";
import { Button, Form, FormGroup, Input, Row, Col } from "reactstrap";
import styled from "styled-components";
import { DownloaderVideo, GamestoreApplicationLocalization, GoogleApplicationLocalization } from "src/shared/dtos";
import { setFromInput, ValidateAsyncRef, useValidate } from "src/shared/helpers";
import { SlideListEditor, Slide, HtmlEditor, ImageSelector, MultiValueEditor, LoadingButton } from "src/shared/components";
import { googleProperty } from "./googleProperty";
import { VideoSelector, EditField } from "src/shared/components";
import { localizationMediaValidationSchema, makeLocalizationValidationSchema } from "./validationSchema";

interface Props {
    value: GamestoreApplicationLocalization;
    initial?: GamestoreApplicationLocalization;
    googleLocale?: GoogleApplicationLocalization;
    isNewApp: boolean;
    update: (value: Partial<GamestoreApplicationLocalization>) => void;
    remove: () => void;
    saving: boolean;
    onSubmit: () => void;
    isGraphicsCustomized?: boolean;
    isLastLocalization: boolean;
}

const makeEmptyDownloaderVideoOptions = (): DownloaderVideo => ({
    videoUrl: undefined,
    locationX: 25,
    locationY: 28,
    width: 749,
    height: 427
});

const ShortDescriptionTextArea = styled(Input).attrs({ type: "textarea", rows: 4 })`
    resize: none;
`;

const StyledInputBlock = styled.div`
    padding: 0.4rem;
`;

export const EditLocalization = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const setTitle = (v: string) => props.update({ title: v ? v : undefined });
    const setPublisher = (v: string) => props.update({ publisher: v ? v : undefined });
    const setShortDescription = (v: string) => props.update({ shortDescription: v ? v : undefined });
    const setDescription = (v: string) => props.update({ description: v ? v : undefined });
    const setIconUrl = (v: string) => props.update({ iconUrl: v === "" ? undefined : v });
    const setThumbnailUrl = (v: string) => props.update({ thumbnailUrl: v === "" ? undefined : v });
    const setLoadingImageUrl = (v: string) => props.update({ loadingImageUrl: v === "" ? undefined : v });
    const setLogoUrl = (v: string) => props.update({ logoUrl: v === "" ? undefined : v });
    const setFeatureUrl = (v: string) => props.update({ featureUrl: v === "" ? undefined : v });
    const setBackgroundUrl = (v: string) => props.update({ backgroundUrl: v === "" ? undefined : v });
    const setVideos = (v: string[]) => props.update({ videos: v });
    const setScreenshots = (v: string[]) => props.update({ screenshots: v });
    const setBanners = (v: string[]) => props.update({ banners: v });
    const setDownloaderImageUrls = (v?: string[]) => props.update({ downloaderImageUrls: v });

    const updateDownloaderVideoOptions = (data: Partial<DownloaderVideo>) => {
        const current = props.value.downloaderVideoOptions ?? makeEmptyDownloaderVideoOptions();
        const updated = Object.assign(current, data);
        props.update({ downloaderVideoOptions: updated });
    };
    const setDownloaderVideoUrl = (v: string) => updateDownloaderVideoOptions({ videoUrl: v ? v : undefined });
    const setLocationX = (v: number) => updateDownloaderVideoOptions({ locationX: v });
    const setLocationY = (v: number) => updateDownloaderVideoOptions({ locationY: v });
    const setVideoWidth = (v: number) => updateDownloaderVideoOptions({ width: v });
    const setVideoHeight = (v: number) => updateDownloaderVideoOptions({ height: v });

    const validationSchema = useMemo(
        () => makeLocalizationValidationSchema(props.isGraphicsCustomized),
        [props.isGraphicsCustomized]);

    const { validate, errors, register } = useValidate({ schema: validationSchema, value: props.value });
    const { validate: validateMedia, errors: warnings } = useValidate({ schema: localizationMediaValidationSchema, value: props.value, isActive: true });

    useImperativeHandle(ref, () => ({
        validate: async () => {
            const isValid = (await validate(true)).isValid;
            validateMedia();
            return isValid;
        }
    }), [validate, validateMedia]);

    const setIcon = useCallback(
        (url: string) => {
            setIconUrl(url);
            return "";
        }, []);
    const setThumbnail = useCallback(
        (url: string) => {
            setThumbnailUrl(url);
            return "";
        }, []);
    const setLoadingImage = useCallback(
        (url: string) => {
            setLoadingImageUrl(url);
            return "";
        }, []);
    const setLogo = useCallback(
        (url: string) => {
            setLogoUrl(url);
            return "";
        }, []);
    const setFeature = useCallback(
        (url: string) => {
            setFeatureUrl(url);
            return "";
        }, []);
    const setBackground = useCallback(
        (url: string) => {
            setBackgroundUrl(url);
            return "";
        }, []);
    const setDownloaderVideo = useCallback(
        (url: string) => {
            setDownloaderVideoUrl(url);
            return "";
        }, []);

    const isVideosChanged = useMemo(
        () => props.value.videos.join() !== props.initial?.videos.join(),
        [props.value.videos, props.initial?.videos]);

    const addVideo = useCallback(
        (url: string): [string, number] => {
            if (url === "") {
                return ["", 0];
            }
            const videos = props.value.videos.length > 0 ? props.value.videos : (props.googleLocale?.videos ?? []);
            setVideos(videos.concat(url));

            return ["", videos.length - 1];
        }, [props.value.videos, props.googleLocale?.videos]);

    const removeVideo = useCallback(
        (url: string) => {
            const videos = props.value.videos.length > 0 ? props.value.videos : (props.googleLocale?.videos ?? []);
            setVideos(videos.filter(i => i !== url));
        }, [props.value.videos, props.googleLocale?.videos]);

    const isScreenshotsChanged = useMemo(
        () => props.value.screenshots.join() !== props.initial?.screenshots.join(),
        [props.value.screenshots, props.initial?.screenshots]);

    const addScreenshot = useCallback(
        (url: string): [string, number] => {
            if (url === "") {
                return ["", 0];
            }
            const screenshots = props.value.screenshots.length > 0 ? props.value.screenshots : (props.googleLocale?.screenshots ?? []);
            setScreenshots(screenshots.concat(url));

            return ["", screenshots.length - 1];
        }, [props.value.screenshots]);

    const removeScreenshot = useCallback(
        (url: string) => {
            const screenshots = props.value.screenshots.length > 0 ? props.value.screenshots : (props.googleLocale?.screenshots ?? []);
            setScreenshots(screenshots.filter(i => i !== url));
        }, [props.value.screenshots]);

    const isBannerChanged = useMemo(
        () => props.value.banners.join() !== props.initial?.banners.join(),
        [props.value.banners, props.initial?.banners]);

    const addBanner = useCallback(
        (url: string): [string, number] => {
            if (url === "") {
                return ["", 0];
            }
            const banners = props.value.banners.concat(url);
            setBanners(banners);

            return ["", props.value.banners.length - 1];
        }, [props.value.banners]);

    const removeBanner = useCallback(
        (url: string) => {
            const banners = props.value.banners.filter(i => i !== url);
            setBanners(banners);
        }, [props.value.banners]);

    const renderIcon = useCallback((url: string): Slide | null => url === "" ? null : { type: "image", url }, []);
    const renderImage = useCallback((url: string): Slide => ({ type: "image", url }), []);
    const renderVideo = useCallback((url: string): Slide => ({ type: "video", url }), []);
    const renderSingleVideo = useCallback((url: string): Slide | null => url === "" ? null : { type: "video", url }, []);

    const isDownloaderImageUrlsChanged = useMemo(
        () => props.value.downloaderImageUrls?.join() !== props.initial?.downloaderImageUrls?.join(),
        [props.value.downloaderImageUrls, props.initial?.downloaderImageUrls]);

    const addDownloaderImageUrls = useCallback(
        (url: string) => {
            if (!url) {
                return;
            }
            const downloaderImageUrls = (props.value.downloaderImageUrls ?? []).concat(url);
            setDownloaderImageUrls(downloaderImageUrls);
        }, [props.value.downloaderImageUrls]);

    const removeDownloaderImageUrls = useCallback(
        (url: string) => {
            if (!props.value.downloaderImageUrls) {
                return;
            }
            const downloaderImageUrls = props.value.downloaderImageUrls.filter(i => i !== url);
            setDownloaderImageUrls(downloaderImageUrls);
        }, [props.value.downloaderImageUrls]);

    useEffect(() => {
        if (props.value.downloaderImageUrls?.length === 0) {
            setDownloaderImageUrls(undefined);
            props.update({ downloaderVideoOptions: undefined });
        }
    }, [props.value.downloaderImageUrls, props.update, setDownloaderImageUrls]);

    useEffect(() => {
        if (!props.value.downloaderVideoOptions?.videoUrl) {
            props.update({ downloaderVideoOptions: undefined });
        }
    }, [props.value.downloaderVideoOptions, props.update]);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <Form className="mt-3" onSubmit={save} noValidate>
            <Row>
                <EditField
                    ref={register("thumbnailUrl")}
                    label="Thumbnail URL"
                    fieldId="thumbnailUrl"
                    isChanged={props.value.thumbnailUrl !== props.initial?.thumbnailUrl}
                    originalValue={(props.isNewApp ? undefined : props.initial?.thumbnailUrl)}
                    warnings={warnings?.thumbnailUrl}
                    errors={errors?.thumbnailUrl}>
                    <ImageSelector
                        setInputId="thumbnailUrl"
                        value={props.value.thumbnailUrl ?? ""}
                        set={setThumbnail}
                        render={renderIcon}
                        delete={setThumbnail}
                        imageDimensions={{ width: "12rem", height: "12rem" }}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("loadingImageUrl")}
                    label="Loading image URL"
                    fieldId="loadingImageUrl"
                    isChanged={props.value.loadingImageUrl !== props.initial?.loadingImageUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.loadingImageUrl}
                    warnings={warnings?.loadingImageUrl}
                    errors={errors?.loadingImageUrl}>
                    <ImageSelector
                        setInputId="loadingImageUrl"
                        value={props.value.loadingImageUrl ?? ""}
                        set={setLoadingImage}
                        render={renderIcon}
                        delete={setLoadingImage}
                        invalid={(errors?.loadingImageUrl?.length ?? 0) > 0}
                        imageDimensions={{ width: "12rem", height: "12rem" }}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("logoUrl")}
                    label="Logo URL"
                    fieldId="logoUrl"
                    isChanged={props.value.logoUrl !== props.initial?.logoUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.logoUrl}
                    warnings={warnings?.logoUrl}
                    errors={errors?.logoUrl}>
                    <ImageSelector
                        setInputId="logoUrl"
                        value={props.value.logoUrl ?? ""}
                        set={setLogo}
                        render={renderIcon}
                        delete={setLogo}
                        invalid={(errors?.logoUrl?.length ?? 0) > 0}
                        imageDimensions={{ width: "12rem", height: "12rem" }}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("featureUrl")}
                    label="Custom Icon URL"
                    fieldId="featureUrl"
                    isChanged={props.value.featureUrl !== props.initial?.featureUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.featureUrl}
                    warnings={warnings?.featureUrl}
                    errors={errors?.featureUrl}>
                    <ImageSelector
                        setInputId="featureUrl"
                        value={props.value.featureUrl ?? ""}
                        set={setFeature}
                        render={renderIcon}
                        delete={setFeature}
                        invalid={(errors?.featureUrl?.length ?? 0) > 0}
                        imageDimensions={{ width: "12rem", height: "12rem" }}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("backgroundUrl")}
                    label="Background URL"
                    fieldId="backgroundUrl"
                    isChanged={props.value.backgroundUrl !== props.initial?.backgroundUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.backgroundUrl}
                    warnings={warnings?.backgroundUrl}
                    errors={errors?.backgroundUrl}>
                    <ImageSelector
                        setInputId="backgroundUrl"
                        value={props.value.backgroundUrl ?? ""}
                        set={setBackground}
                        render={renderIcon}
                        delete={setBackground}
                        imageDimensions={{ width: "36rem", height: "31.875rem" }}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("banners")}
                    label="Banners"
                    fieldId="banners"
                    isChanged={isBannerChanged}
                    warnings={warnings?.banners}
                    errors={errors?.banners}>
                    <SlideListEditor
                        addInputId="banners"
                        values={props.value.banners ?? []}
                        add={addBanner}
                        remove={removeBanner}
                        render={renderImage}
                        invalid={(errors?.banners?.length ?? 0) > 0}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("title")}
                    label={googleProperty("Title", props.value.title === undefined)}
                    fieldId="title"
                    isChanged={props.value.title !== props.initial?.title}
                    originalValue={props.isNewApp ? undefined : props.initial?.title}
                    errors={errors?.title}>
                    <Input
                        type="text"
                        id="title"
                        value={props.value.title ?? ""}
                        placeholder={props.googleLocale?.title}
                        onChange={setFromInput(setTitle)}
                        disabled={props.saving}
                    />
                </EditField>
                <EditField
                    label={googleProperty("Publisher", props.value.publisher === undefined)}
                    fieldId="publisher"
                    isChanged={props.value.publisher !== props.initial?.publisher}
                    originalValue={props.isNewApp ? undefined : props.initial?.publisher}>
                    <Input
                        type="text"
                        id="publisher"
                        value={props.value.publisher ?? ""}
                        placeholder={props.googleLocale?.publisher}
                        onChange={setFromInput(setPublisher)}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    label={googleProperty("Description", props.value.description === undefined)}
                    fieldId="description"
                    isChanged={props.value.description !== props.initial?.description}
                    fullWidth={true}>
                    <HtmlEditor
                        id="description"
                        value={props.value.description}
                        onChange={setDescription}
                        disabled={props.saving}
                        placeholder={props.googleLocale?.description}
                    />
                </EditField>
                <EditField
                    label={googleProperty("Short Description", props.value.shortDescription === undefined)}
                    fieldId="shortDescription"
                    isChanged={props.value.shortDescription !== props.initial?.shortDescription}
                    originalValue={props.isNewApp ? undefined : props.initial?.shortDescription}>
                    <ShortDescriptionTextArea
                        id="shortDescription"
                        placeholder={props.googleLocale?.shortDescription}
                        value={props.value.shortDescription ?? ""}
                        onChange={setFromInput(setShortDescription)}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("iconUrl")}
                    label={googleProperty("Icon URL", props.value.iconUrl === undefined)}
                    fieldId="iconUrl"
                    isChanged={props.value.iconUrl !== props.initial?.iconUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.iconUrl}
                    warnings={warnings?.iconUrl}
                    errors={errors?.iconUrl}>
                    <ImageSelector
                        setInputId="iconUrl"
                        value={props.value.iconUrl ?? props.googleLocale?.iconUrl ?? ""}
                        set={setIcon}
                        render={renderIcon}
                        delete={setIcon}
                        imageDimensions={{ width: "12rem", height: "12rem" }}
                        deleteButtonHidden={props.value.iconUrl ? false : true}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("videos")}
                    label={googleProperty("Videos", props.value.videos.length === 0)}
                    fieldId="videos"
                    isChanged={isVideosChanged}
                    warnings={warnings?.videos}
                    errors={errors?.videos}>
                    <SlideListEditor
                        addInputId="videos"
                        values={props.value.videos.length === 0
                            ? (props.googleLocale?.videos ?? [])
                            : props.value.videos}
                        add={addVideo}
                        remove={removeVideo}
                        render={renderVideo}
                        placeholder="Add Youtube video link"
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("screenshots")}
                    label={googleProperty("Screenshots", props.value.screenshots.length === 0)}
                    fieldId="screenshots"
                    isChanged={isScreenshotsChanged}
                    warnings={warnings?.screenshots}
                    errors={errors?.screenshots}>
                    <SlideListEditor
                        addInputId="screenshots"
                        values={props.value.screenshots.length === 0
                            ? (props.googleLocale?.screenshots ?? [])
                            : props.value.screenshots}
                        add={addScreenshot}
                        remove={removeScreenshot}
                        render={renderImage}
                        invalid={(errors?.screenshots?.length ?? 0) > 0}
                        disabled={props.saving} />
                </EditField>
                <EditField fullWidth={true}>
                    <h2 style={{ display: "flex", justifyContent: "center", padding: "1rem" }}>Downloader options</h2>
                </EditField>
                <EditField
                    ref={register("downloaderImageUrls")}
                    label="Downloader image Urls"
                    fieldId="downloaderImageUrls"
                    isChanged={isDownloaderImageUrlsChanged}
                    warnings={warnings?.downloaderImageUrls}
                    errors={errors?.downloaderImageUrls}>
                    <MultiValueEditor
                        listContainerId="downloaderImageUrls_list"
                        mainInputId="downloaderImageUrls"
                        add={addDownloaderImageUrls}
                        remove={removeDownloaderImageUrls}
                        items={props.value.downloaderImageUrls ?? []}
                        disabled={props.saving}
                        invalid={(errors?.downloaderImageUrls?.length ?? 0) > 0}
                    />
                </EditField>
                <EditField />
                {props.value.downloaderImageUrls?.length! > 0 &&
                    <React.Fragment>
                        <EditField
                            ref={register("downloaderVideoOptions")}
                            label="Downloader Video"
                            fieldId="downloaderVideo"
                            isChanged={props.value.downloaderVideoOptions?.videoUrl !== props.initial?.downloaderVideoOptions?.videoUrl}
                            originalValue={props.isNewApp ? undefined : props.initial?.downloaderVideoOptions?.videoUrl}
                            warnings={warnings?.downloaderVideoOptions}
                            errors={errors?.downloaderVideoOptions}>
                            <VideoSelector
                                setInputId="downloaderVideo"
                                value={props.value.downloaderVideoOptions?.videoUrl ?? ""}
                                set={setDownloaderVideo}
                                render={renderSingleVideo}
                                delete={setDownloaderVideo}
                                deleteButtonHidden={props.value.downloaderVideoOptions?.videoUrl ? false : true}
                                videoDimensions={{ width: "12rem", height: "12rem" }}
                                disabled={props.saving} />
                        </EditField>
                        {props.value.downloaderVideoOptions?.videoUrl ?
                            <EditField>
                                <StyledInputBlock>
                                    <span>Location X</span>
                                    <Input
                                        min={0}
                                        type="number"
                                        id="locationX"
                                        value={props.value.downloaderVideoOptions?.locationX}
                                        onChange={setFromInput(setLocationX)}
                                        disabled={props.saving}
                                    />
                                </StyledInputBlock>
                                <StyledInputBlock>
                                    <span>Location Y</span>
                                    <Input
                                        min={0}
                                        type="number"
                                        id="locationY"
                                        value={props.value.downloaderVideoOptions?.locationY}
                                        onChange={setFromInput(setLocationY)}
                                        disabled={props.saving}
                                    />
                                </StyledInputBlock>
                                <StyledInputBlock>
                                    <span>Width</span>
                                    <Input
                                        min={1}
                                        type="number"
                                        id="width"
                                        value={props.value.downloaderVideoOptions?.width}
                                        onChange={setFromInput(setVideoWidth)}
                                        disabled={props.saving}
                                    />
                                </StyledInputBlock>
                                <StyledInputBlock>
                                    <span>Height</span>
                                    <Input
                                        min={1}
                                        type="number"
                                        id="height"
                                        value={props.value.downloaderVideoOptions?.height}
                                        onChange={setFromInput(setVideoHeight)}
                                        disabled={props.saving}
                                    />
                                </StyledInputBlock>
                            </EditField>
                            :
                            <EditField>
                                <div style={{ display: "block", textAlign: "center", margin: "0 auto", marginTop: "8.25rem" }}>[no video options]</div>
                            </EditField>}
                    </React.Fragment>}
            </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <FormGroup>
                        <Button
                            type="button"
                            color="danger"
                            className="mr-2"
                            title={props.isLastLocalization ? "Unable to remove last localization." : undefined}
                            disabled={props.isLastLocalization || props.saving}
                            onClick={props.remove}>
                            Remove localization
                        </Button>
                        <LoadingButton
                            loading={props.saving}
                            loadingText={"Saving..."}
                            position="end"
                            type="submit"
                            color="primary">
                            {props.isNewApp ? "Add" : "Save"} application
                        </LoadingButton>
                    </FormGroup>
                </Col>
            </Row>
        </Form >
    );
});
