import { client } from "src/shared/client";
import {
    OrderDirection,
    GetActiveInstallsStatistics,
    GetActiveInstallsStatisticsAvailableDates,
    GetActiveInstallsStatisticsById,
    GetActiveInstallsStatisticsSummaries,
    ActiveInstallsStatisticsListDataType,
    GetActiveInstallsStatisticsAppVersionsByDate,
    GetActiveInstallsStatisticsByVersionForPeriod
} from "src/shared/dtos";
import { serializeDate } from "src/shared/helpers/dateSerialization";

export interface ListRequest {
    limit?: number;
    offset?: number;
    date: Date;
    versionSearch?: string[];
    orderBy: ActiveInstallsStatisticsListDataType;
    orderDirection: OrderDirection;
}

export interface DetailsRequest {
    productVersion: string;
    startDate: Date;
    endDate: Date;
}

export const ActiveInstallsStatisticsApi = {
    getById: (id: number) => client.get(new GetActiveInstallsStatisticsById({ id })),
    list: async (request: ListRequest) => {
        const { date, ...restRequest } = request;
        return client.get(new GetActiveInstallsStatisticsSummaries({
            date: serializeDate(date),
            ...restRequest
        }));
    },
    all: () => client.get(new GetActiveInstallsStatistics()),
    getDates: () => client.get(new GetActiveInstallsStatisticsAvailableDates()),
    details: ({productVersion, startDate, endDate}: DetailsRequest) => client.get(
        new GetActiveInstallsStatisticsByVersionForPeriod({
            productVersion,
            startDate: serializeDate(startDate),
            endDate: serializeDate(endDate)})
    ),
    getVersionsByDate: (date: Date) => client.get(new GetActiveInstallsStatisticsAppVersionsByDate({ date: serializeDate(date) })),
};

export default ActiveInstallsStatisticsApi;
