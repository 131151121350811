import { client } from "src/shared/client";
import {
    FeaturedApplication,
    GetAvailableFeaturedApplicationIds,
    GetFeaturedApplication,
    GetFeaturedApplications,
    ReorderFeaturedApplication,
    SetupFeaturedApplication
} from "src/shared/dtos";

export const FeaturedApplicationApi = {
    list: async () => client.get(new GetFeaturedApplications()),
    get: (id: string) => client.get(new GetFeaturedApplication({ id })),
    setup: (application: FeaturedApplication) => client.post(new SetupFeaturedApplication({ application })),
    reorder: (id: string, placeAt: number) => client.post(new ReorderFeaturedApplication({ id, placeAt })),
    getAvailableAppIds: () => client.get(new GetAvailableFeaturedApplicationIds()),
};

export default FeaturedApplicationApi;