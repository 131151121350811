import { ApkPlatform, ApkDetails, GamestoreApplication, GoogleApplication } from "src/shared/dtos";
import { deepClone, makeEmptyApkDetails } from "src/shared/helpers";

export type PlatformDetailsState = {
    current?: ApkDetails,
    initial?: ApkDetails,
    all: ApkDetails[],
    allInitial: ApkDetails[],
};

export type PlatformDetailsAction =
    { kind: "set", app: GamestoreApplication } |
    { kind: "select", platform: ApkPlatform } |
    { kind: "update", value: Partial<ApkDetails> } |
    { kind: "set-google-app", value: GoogleApplication };

export const platformDetailsReducer = (state: PlatformDetailsState, action: PlatformDetailsAction) => {
    switch (action.kind) {
        case "set": {
            const enabledPlatforms = action.app.googleApplication?.apkDetails?.filter(a => a.enabled).map(a => a.platform) ?? [];
            const all = enabledPlatforms.map(p => {
                return action.app.apkDetails?.find(a => a.platform === p) ?? makeEmptyApkDetails(p);
            });
            const allInitial = deepClone(all);
            const current = all.find(apk => apk.platform === (state.current?.platform ?? ApkPlatform.X32)) ?? all[0];
            const initial = deepClone(current);

            state = { current, initial, all, allInitial };
            break;
        }
        case "set-google-app": {
            const platforms = action.value.apkDetails.filter(a => a.enabled).map(a => a.platform);
            const all = platforms.map(makeEmptyApkDetails);
            const allInitial = deepClone(all);
            const current = all.find(apk => apk.platform === (state.current?.platform ?? ApkPlatform.X32)) ?? all[0];
            const initial = deepClone(current);

            state = { current, initial, all, allInitial };
            break;
        }
        case "select": {
            const found = state.all.find(apk => apk.platform === action.platform);
            if (found === undefined) {
                break;
            }

            state.current = found;
            state.initial = state.allInitial.find(apk => apk.platform === action.platform);
            break;
        }
        case "update": {
            if (state.current === undefined) {
                break;
            }

            Object.assign(state.current, action.value);
            break;
        }
    }

    return Object.assign({}, state);
};

export const platformDetailsReducerDefault: PlatformDetailsState = {
    current: undefined,
    initial: undefined,
    all: [],
    allInitial: [],
};