import React, { useEffect, useState, useReducer, useMemo, useCallback, useRef, } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col } from "reactstrap";
import { forkJoin, defer } from "rxjs";
import { IoApplicationLocalization, Category, IoApplication, GamestoreIoApplication, Tag } from "src/shared/dtos";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    LeavingViewProtector,
    Loader,
    LocalizationSelector,
    DocumentTitle,
    RouteLink,
    RouteUserProps,
    EditError,
    EditErrorOptions,
    notifySuccess,
    notifyError,
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { availableLanguages, getFormErrorsAsStringArray, getLangName, getObjectFieldsArray, makeEmptyIoApp, queryString, structuredClone, useSubscription, ValidateAsyncRef, validateForm } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { EditForm } from "./EditForm";
import { EditInfo } from "./EditInfo";
import { EditLocalization } from "./EditLocalization";
import { ioApplicationReducer, ioApplicationReducerDefault, IoAppMainInfo } from "./ioApplicationReducer";
import { localizationReducer, makeLocalizationReducerDefault } from "./localizationReducer";
import api from "../api";
import { useConfirm } from "src/shared/helpers/useConfirm";
import { ConfirmFailedLocalizationSources, LocalizationsFailedFields } from "src/shared/components/confirmWindow/samples/ConfirmFailedSources";
import { localizationValidationSchema, locSourcesValidationSchema } from "./validationSchema";

const getDefaultLocale = (locs: IoApplicationLocalization[], lang: string): IoApplicationLocalization => {
    const locale = locs.find(l => l.language === lang) || locs[0];
    if (locale === undefined) {
        throw new Error(`Io Application has no localizations.`);
    }

    return locale;
};

type Props = RouteComponentProps<{ appId: string }> & RouteUserProps;

export const EditView = withRouter(({ match, location, history, user }: Props) => {
    const appId = match.params.appId;
    const isNewApp = useMemo(() => appId === undefined, [appId]);
    const mainFormRef = useRef<ValidateAsyncRef>(null);
    const localizationFormRef = useRef<ValidateAsyncRef>(null);

    const queryParams = queryString(location.search);
    const defaultLang = isNewApp ? "en" : queryParams.lang || "en";

    const [isNewAppSaved, setIsNewAppSaved] = useState(!isNewApp);
    const [categories, setCategories] = useState<Category[] | undefined>();
    const [tags, setTags] = useState<Tag[] | undefined>();
    const [gamestoreApp, setGamestoreApp] = useState<GamestoreIoApplication | undefined>();
    const [error, setError] = useState<EditErrorOptions | undefined>();
    const [saving, setSaving] = useState(false);
    const [app, changeApp] = useReducer(ioApplicationReducer, ioApplicationReducerDefault);
    const [loc, changeLocs] = useReducer(localizationReducer, makeLocalizationReducerDefault(defaultLang));
    const { confirm } = useConfirm();

    const backToList = useCallback(() => history.push(routes.ioApplications.url()), []);

    const setApp = useCallback((data?: IoApplication) => {
        const value = data ?? makeEmptyIoApp(categories?.find(c => c.parentId === "GAME")!);
        updateAppAndLocalizationsState(value);
    }, [categories]);

    const updateAppAndLocalizationsState = useSubscription((application: IoApplication) => forkJoin([
        defer(() => structuredClone(application)),
        defer(() => structuredClone(application.localizations)),
        defer(() => structuredClone(application.localizations))])
        .subscribe(clones => {
            changeApp({ kind: "set", value: application, initialValue: clones[0] });
            changeLocs({
                kind: "set",
                appId: application.id,
                resetCurrent: true,
                localizations: clones[1],
                initialLocalizations: clones[2]
            });

            // Check for an unknown localization languages.
            const allLangs = application.localizations.map(l => l.language);
            const unknownLangs = allLangs
                .filter(l => availableLanguages.find(al => al.value === l) === undefined)
                .join();
            if (unknownLangs.length > 0) {
                console.warn(
                    `IoApplication '${application.id}' contains unknown localization languages: '${unknownLangs}'.`);
            }
        }), []);

    const loadCategories = useSubscription(() => defer(() => api.getCategories()).subscribe(setCategories), []);
    const loadTags = useSubscription(() => defer(() => api.getTags()).subscribe(setTags), []);

    const loadApplication = useSubscription((applicationId: string) => defer(() => api.get(applicationId)).subscribe({
        next: result => {
            if (result.localizations?.length === undefined) {
                setError({
                    text: "Unable to load application localizations.",
                    actionText: "Back to list",
                    action: backToList
                });
                return;
            }
            setApp(result);
        },
        error: () => setError({
            text: "Unable to load application.",
            actionText: "Back to list",
            action: backToList
        })
    }), [api.get, setApp, backToList]);

    const loadGamestoreApplication = useSubscription((applicationId: string) => defer(() => api.getGamestoreApp(applicationId))
        .subscribe(result => {
            setGamestoreApp(result);
        }), [api.getGamestoreApp]);

    useEffect(() => {
        if (categories === undefined) {
            loadCategories();
            loadTags();
            return;
        }
        if (isNewApp) {
            setApp(undefined);
            return;
        }
        loadApplication(appId);
        loadGamestoreApplication(appId);
    }, [categories, setApp, loadApplication, isNewApp, appId]);

    useEffect(() => {
        // Go to edit view after successful save of the new entity.
        // It is implemented this way to let leaving view protector know, that there is no changes.
        if (isNewApp && isNewAppSaved && error === undefined) {
            history.push(routes.editIoApplication.url({ appId: app.current!.id, language: defaultLang }));
        }
    }, [isNewApp, isNewAppSaved, app.current]);

    const gamestoreLocale = useMemo(() =>
        gamestoreApp?.localizations?.find(o => o.language === loc.current?.language),
        [gamestoreApp, gamestoreApp?.localizations, loc.current?.language]);

    const saveApplication = useSubscription((application: IoApplication, isNew: boolean) => defer(() => isNew
        ? api.create(application) : api.update(application)).subscribe({
            next: result => {
                notifySuccess(`Io Application ${isNew ? "added" : "saved"} successfully.`);
                setApp(result);

                if (isNew) {
                    setIsNewAppSaved(true);
                }
            },
            error: () => {
                setSaving(false);
                setError({
                    text: "Unable to save application.",
                    actionText: "Continue",
                    action: () => setError(undefined)
                });
            },
            complete: () => setSaving(false)
        }), [api.create, api.update]);

    const save = useCallback(
        async () => {
            if (saving || app.current === undefined) {
                return;
            }

            if (loc.all.length === 0) {
                notifyError("Io Application must have at least one localization.");
                return;
            }

            setSaving(true);
            const isMainFormValid = await mainFormRef.current?.validate();
            if (!isMainFormValid) {
                setSaving(false);
                return;
            }
            const isLocalizationFormValid = await localizationFormRef.current?.validate();
            if (!isLocalizationFormValid) {
                setSaving(false);
                return;
            }

            const localizationsErrors = (await Promise.all(
                loc.all.map(async l => {
                    const locValidationResult = await validateForm(l, localizationValidationSchema);
                    const locErrors = getFormErrorsAsStringArray(locValidationResult.errors);
                    return locErrors.map(err => `${getLangName(l.language)} Localization: ${err}`);
                })
            )).flat();

            if (localizationsErrors.length > 0) {
                setSaving(false);
                localizationsErrors.forEach(notifyError);
                return;
            }

            if ((app.current?.rating ?? app.current?.rating ?? 0) === 0) {
                const confirmSave = await confirm({
                    body: "Do you want to save application with rating 0?",
                    title: "Confirm saving application"
                });
                if (!confirmSave) {
                    setSaving(false);
                    return;
                }
            }

            const failedSources = (await Promise.all(
                loc.all.map(async l => {
                    const locValidationResult = await validateForm(l, locSourcesValidationSchema);
                    if (locValidationResult.isValid) {
                        return null;
                    }
                    const failedFields = getObjectFieldsArray(locValidationResult.errors);
                    return { lang: getLangName(l.language) ?? l.language, fields: failedFields } as LocalizationsFailedFields;
                })
            )).filter(v => v !== null) as LocalizationsFailedFields[];

            if (failedSources.length > 0) {
                const confirmSave = await confirm({
                    body: <ConfirmFailedLocalizationSources failedFields={failedSources} />,
                    title: "Confirm saving application",
                    size: "lg"
                });
                if (!confirmSave) {
                    setSaving(false);
                    return;
                }
            }

            const application: IoApplication = {
                ...app.current,
                localizations: loc.all,
            };

            saveApplication(application, isNewApp);
        }, [app, app.current, loc, loc.all, saving, isNewApp, saveApplication, mainFormRef.current, localizationFormRef.current, confirm]);

    const updateApp = useCallback(
        (value: Partial<IoAppMainInfo>) => changeApp({ kind: "update", value }), []);

    const setIoApp = useCallback(
        (value: IoApplication) => {
            changeLocs({ kind: "change-app", value });
            changeLocs({ kind: "select", lang: loc.current?.language ?? "en" });
        }, []);

    const updateLocalization = useCallback(
        (value: Partial<IoApplicationLocalization>) => changeLocs({ kind: "update", value }), []);

    const removeLocalization = useCallback(() => changeLocs({ kind: "remove" }), []);

    const changed = useMemo(
        () => app.changed || loc.changedLanguages.length > 0 || loc.removedLocalizations.length > 0, [
        app.changed,
        loc.changedLanguages,
        loc.changedLanguages.length,
        loc.removedLocalizations,
        loc.removedLocalizations.length]);

    const title = useMemo(
        () => {
            const appTitle = isNewApp
                ? "[new application]"
                : (loc.current !== undefined
                    ? getDefaultLocale(loc.all, defaultLang).title
                    : "Loading...");
            return `Io Application - ${appTitle}`;
        },
        [loc.current, loc.all, defaultLang]);

    const setLanguage = (lang: string) => changeLocs({ kind: "select", lang });
    const addLanguage = (lang: string) => changeLocs({ kind: "add", lang });

    return (
        <VerticalBox>
            <DocumentTitle title={title} />
            <LeavingViewProtector showConfirmation={changed && (isNewApp ? !isNewAppSaved : true)} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.ioApplications}>
                                Io Applications
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {isNewApp ? "[new application]" : match.params.appId}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    {error && <EditError error={error} />}
                    {!error && app.current === undefined || loc.current === undefined || categories === undefined || tags === undefined &&
                        <Loader />}
                    {!error && categories !== undefined && tags !== undefined && app.current !== undefined && loc.current !== undefined &&
                        <React.Fragment>
                            <Row>
                                <Col sm={5} xs={12}>
                                    <EditInfo
                                        user={user}
                                        app={app.current}
                                        locale={loc.current}
                                        gamestoreApp={gamestoreApp}
                                        isNewApp={isNewApp} />
                                </Col>
                                <Col sm={7}>
                                    <EditForm
                                        ref={mainFormRef}
                                        value={app.current}
                                        initial={app.initial}
                                        isNewApp={isNewApp}
                                        categories={categories}
                                        tags={tags}
                                        gamestoreApp={gamestoreApp!}
                                        update={updateApp}
                                        setIoApplication={setIoApp}
                                        saving={saving}
                                        onSubmit={save}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <LocalizationSelector
                                        currentLanguage={loc.current.language ?? defaultLang}
                                        languages={loc.all.map(l => l.language)}
                                        removedLanguages={loc.removedLocalizations.map(l => l.language)}
                                        changedLanguages={loc.changedLanguages}
                                        setLanguage={setLanguage}
                                        addLanguage={addLanguage}
                                    />
                                    {loc.current &&
                                        <EditLocalization
                                            ref={localizationFormRef}
                                            value={loc.current}
                                            initial={loc.initial}
                                            gamestoreLocale={gamestoreLocale}
                                            isNewApp={isNewApp}
                                            update={updateLocalization}
                                            remove={removeLocalization}
                                            saving={saving}
                                            onSubmit={save}
                                            isLastLocalization={loc.all.length < 2} />}
                                </Col>
                            </Row>
                        </React.Fragment>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});