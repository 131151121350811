import React, { FC } from "react";
import { ChartTypes, ChartWidgetDataTypes, ChartWidgetOptions, ChartWidgetUnit, GamingMinutesChartOptions } from "src/shared/dtos";
import styled from "styled-components";
import { setFromInput } from "src/shared/helpers";
import { Input, Label, FormGroup } from "reactstrap";

type GamingMinutesOptionsProps = {
    update: (val: GamingMinutesChartOptions) => void;
    value?: GamingMinutesChartOptions;
};

const GamingMinutesOptions: FC<GamingMinutesOptionsProps> = ({ value, update }) => {
    const setShowPlaystoreActivity = (val: boolean) => update({ showPlaystoreActivity: val });
    return (
        <EditForm>
            <Title>Additional Options</Title>
            <FormGroup check>
                <Label for="showPlaystoreActivity" check>
                    <Input
                        type="checkbox"
                        id="showPlaystoreActivity"
                        name="showPlaystoreActivity"
                        checked={value?.showPlaystoreActivity}
                        onChange={setFromInput(setShowPlaystoreActivity)}
                    />
                    Show Playstore Activity
                </Label>
            </FormGroup>
        </EditForm>);
};

const Title = styled.h5`
    font-size: 1.1rem;
    margin-bottom: 1rem;
`;

const EditForm = styled.div`
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

type Props = {
    updateOptions: (val: ChartWidgetOptions) => void;
    chartWidget: ChartWidgetUnit;
};

export const ChartWidgetAdditionalOptions: FC<Props> = ({ updateOptions, chartWidget }) => {
    const updateGamingMinutesOptions = (val: GamingMinutesChartOptions) => updateOptions({ gamingMinutesChartOptions: val });
    return (
        <>
            {chartWidget.dataType === ChartWidgetDataTypes.GamingMinutesData && chartWidget.chartType !== ChartTypes.Pie &&
                <GamingMinutesOptions value={chartWidget.options?.gamingMinutesChartOptions} update={updateGamingMinutesOptions} />}
        </>
    );
};

