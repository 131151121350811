import {
    ApkDetails,
    ApkPlatform,
    Category,
    GamestoreApplication,
    GamestoreApplicationLocalization,
    GamestoreIoApplication,
    GamestoreIoApplicationLocalization,
    GoogleApkDetails,
    GoogleApplication,
    GoogleApplicationLocalization,
    IoApplication,
    IoApplicationLocalization,
    ScreenOrientationType
} from "../dtos";

export const ApkPlatforms = Object.values(ApkPlatform).filter(c => isNaN(Number(c)));

export const makeEmptyGoogleApp = (defaultCategory?: Category, withLocs: boolean = true): GoogleApplication => {
    const locs = !withLocs ? [] : [
        makeEmptyGoogleLoc("en"),
        makeEmptyGoogleLoc("ru")
    ];
    const apkDetails = ApkPlatforms.map(makeEmptyGoogleApkDetails);

    return {
        id: "",
        categoryId: "",
        category: defaultCategory ?? {} as Category,
        rating: 0,
        apkDetails,
        installsCount: 0,
        sdkVersion: 0,
        additionalFiles: [],
        localizations: locs
    };
};

export const makeEmptyGoogleLoc = (lang?: string): GoogleApplicationLocalization => ({
    id: 0,
    googleApplicationId: "",
    language: lang ?? "en",
    publisher: undefined,
    publisherId: undefined,
    title: undefined,
    iconUrl: undefined,
    shortDescription: undefined,
    description: undefined,
    size: 0,
    videos: [],
    screenshots: [],
    splitFiles: []
});

export const makeEmptyGoogleApkDetails = (platform: ApkPlatform): GoogleApkDetails => ({
    id: 0,
    apkDownloadSize: 0,
    googleApplicationId: "",
    platform,
    enabled: false,
    versionCode: 0,
    versionName: "",
    apkDownloadUrl: undefined
});

export const makeEmptyApkDetails = (platform: ApkPlatform): ApkDetails => ({
    id: 0,
    gamestoreApplicationId: "",
    downloadSize: 0,
    platform,
    versionName: undefined,
});

export const makeEmptyGamestoreLoc = (appId?: string, lang?: string): GamestoreApplicationLocalization => ({
    id: 0,
    gamestoreApplicationId: appId ?? "",
    language: lang ?? "en",
    publisher: undefined,
    publisherId: undefined,
    title: undefined,
    iconUrl: undefined,
    shortDescription: undefined,
    description: undefined,
    screenshots: [],
    videos: [],
    thumbnailUrl: undefined,
    banners: [],
    downloaderVideoOptions: {videoUrl: undefined, locationX: 20, locationY: 28, width: 749, height: 427}
});

export const makeEmptyGamestoreApp = (googleAppId: string): GamestoreApplication => {
    const googleApplication: GoogleApplication = makeEmptyGoogleApp();
    const locs = [makeEmptyGamestoreLoc()];
    const apkDetails = ApkPlatforms.map(makeEmptyApkDetails);

    return {
        id: googleAppId,
        googleApplication,
        rating: undefined,
        apkDetails,
        installsCount: undefined,
        modifiedAt: "",
        isDisabled: true,
        isGraphicsCustomized: false,
        localizations: locs,
        categories: [],
        categoryIds: [],
        defaultKeyBindings: undefined,
        needGoogleAccount: false,
        useGooglePlay: false,
        screenOrientation: ScreenOrientationType.Auto,
        screenWidth: 0,
        screenHeight: 0
    };
};

export const makeEmptyIoApp = (defaultCategory?: Category, withLocs: boolean = true): IoApplication => {
    const locs = !withLocs ? [] : [
        makeEmptyIoLoc("en"),
        makeEmptyIoLoc("ru")
    ];

    return {
        id: "",
        categoryId: defaultCategory?.id ?? "",
        category: defaultCategory ?? {} as Category,
        tagsIds: [],
        developer: "",
        rating: 0,
        updatedAt: "",
        localizations: locs
    };
};

export const makeEmptyIoLoc = (appId?: string, lang?: string): IoApplicationLocalization => ({
    id: 0,
    ioApplicationId: appId ?? "",
    applicationUrl: "",
    sourceUrl: "",
    language: lang ?? "en",
    title: undefined,
    iconUrl: undefined,
    thumbnailUrl: undefined,
    description: undefined,
    screenshots: [],
    videos: [],
});

export const makeEmptyIoGamestoreLoc = (appId?: string, lang?: string): GamestoreIoApplicationLocalization => ({
    id: 0,
    gamestoreIoApplicationId: appId ?? "",
    language: lang ?? "en",
    title: undefined,
    iconUrl: undefined,
    applicationUrl: undefined,
    description: undefined,
    screenshots: [],
    videos: [],
    thumbnailUrl: undefined,
    banners: []
});

export const makeEmptyGamestoreIoApp = (appId: string): GamestoreIoApplication => {
    const ioApplication: IoApplication = makeEmptyIoApp();

    return {
        id: appId,
        ioApplication,
        rating: undefined,
        installsCount: undefined,
        modifiedAt: "",
        isDisabled: true,
        localizations: [makeEmptyIoGamestoreLoc(appId, "en")],
        categories: [],
        categoryIds: [],
        tagIds: [],
        isGraphicsCustomized: false,
    };
};