import React, { ChangeEvent, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { defer } from "rxjs";
import api from "../api";
import styled from "styled-components";
import { Loader } from "../../../shared/components/Loader";
import { notifyError, notifySuccess } from "src/shared/components";
import { AdBrowserExtension } from "src/shared/dtos";

const StyledLoader = styled(Loader)`
    margin: 0;
`;

export interface ImportFileModalModalProps {
    isOpen: boolean;
    title: string;
    close: () => void;
    setResult: (items: AdBrowserExtension[]) => void;
}

export const ImportFileModal = (props: React.PropsWithChildren<ImportFileModalModalProps>) => {
    const [selectedFile, setSelectedFile] = useState<File | undefined>();
    const [importing, setImporting] = useState(false);

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e !== null && e.target !== null && e.target.files !== null && e.target.files.length > 0) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const onImportFormSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault(); // Stop form submit
        if (selectedFile !== undefined) {
            setImporting(true);
            defer(() => api.importData(selectedFile)).subscribe({
                next: (result) => {
                    setImporting(false);
                    notifySuccess(`File has been successfully imported.`);
                    props.setResult(result.sort((a, b) => a.id - b.id));
                    props.close();
                    setSelectedFile(undefined);
                },
                error: () => {
                    notifyError(`Unable to import file`);
                    setSelectedFile(undefined);
                }
            });
        }
    };

    return <React.Fragment>
        <Modal isOpen={props.isOpen} toggle={props.close}>
            <form onSubmit={onImportFormSubmit}>
                <ModalHeader toggle={props.close}>
                    {props.title}
                </ModalHeader>
                <ModalBody>
                    {importing && <StyledLoader />}
                    {!importing && <input type="file" id="file_upload" onChange={onFileChange} name="file" />}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit" disabled={importing} >Import</Button>
                    <Button color="secondary" type="button" disabled={importing} onClick={props.close}>Cancel</Button>
                </ModalFooter>
            </form>
        </Modal>
    </React.Fragment>;
};