// Solution taken from: https://stackoverflow.com/a/8260383/12123660
const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
const videoIdLength = 11;

export const getYoutubeVideoIdFromUrl = (url: string) => {
    const match = url.match(regExp);
    try {
        if (match && match[2].length === videoIdLength) {
            return match[2];
        } else {
            return;
        }
    } catch {
        return;
    }
};