import React, { useCallback, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useBootstrapBreakpoint } from "src/shared/helpers";
import styled from "styled-components";
import { SearchCellHeaderProps } from "./SearchCellHeaderProps";

const StyledCol = styled.div`
    width: 100%;

    .form-control {
        height: 27px;
        padding: 0 7px;
        font-size: 0.81em;
    }

    .react-datepicker-popper {
        z-index: 2;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__close-icon {
        padding: 0;
        right: 6px;
        &::after {
            padding: 0 1px 2px 1px;
        }
    }
`;

export function DateSearch<TRow>(props: SearchCellHeaderProps<[Date | null, Date | null] | undefined, TRow>) {
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [isCalendarClosed, setCalendarClosed] = useState(false);
    const [startDate, endDate] = dateRange;
    const handleCalendarOpen = useCallback(() => setCalendarClosed(false), []);
    const handleCalendarClose = useCallback(() => setCalendarClosed(true), []);
    const set = useCallback((date: [Date, Date]) => {
        setDateRange(date);

        const [currentStartDate, currentEndDate] = date;
        if (currentStartDate === null) {
            props.setSearchValue(undefined);
        } else if (currentStartDate !== null && currentEndDate !== null) {
            props.setSearchValue([currentStartDate, currentEndDate]);
        }
    }, []);

    useEffect(() => {
        if (props.searchValue !== undefined && startDate !== props.searchValue?.[0] && endDate !== props.searchValue?.[1] && isCalendarClosed) {
            setDateRange(props.searchValue);
        }
    }, [startDate, endDate, props.searchValue?.[0], props.searchValue?.[1], isCalendarClosed]);

    const breakpoint = useBootstrapBreakpoint();
    const isMobile = breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md";

    return (
        <StyledCol>
            <ReactDatePicker
                selectsRange={true}
                className="form-control"
                popperPlacement="top-end"
                startDate={startDate}
                endDate={endDate}
                onChange={set}
                isClearable
                withPortal={isMobile ? true : false}
                onCalendarOpen={handleCalendarOpen}
                onCalendarClose={handleCalendarClose}
            />
        </StyledCol>
    );
}