import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Form, Input, FormGroup, Label, Col, Row, Button } from "reactstrap";
import { ProductVersionInfo, UrlValidationType } from "src/shared/dtos";
import { SelectOption, setFromInput } from "src/shared/helpers";
import { ConfirmationModal, FormLabel, MarkdownEditor, Select, StyledTooltip } from "src/shared/components";
import { validateUrl } from "src/shared/components/propertyField/validation/validateUrl";
import { isProductVersionValid } from "src/shared/components/propertyField/validation/isProductVersionValid";

export interface EditFormRef {
    validate: () => boolean;
}

interface Props {
    value: ProductVersionInfo;
    initial?: ProductVersionInfo;
    source: string[];
    update: React.Dispatch<Partial<ProductVersionInfo>>;
    saving: boolean;
    onSubmit: () => void;
    onDelete: () => void;
}

type EditFieldProps = {
    children?: React.ReactNode;
    label?: string | React.ReactNode;
    fieldId?: string;
    isChanged?: boolean;
    originalValue?: unknown;
};

const EditField = forwardRef((props: EditFieldProps, ref: React.MutableRefObject<HTMLDivElement>) => {
    const { children, label, fieldId, isChanged, ...changesProps } = props;
    return (
        <div ref={ref} className="col-md-12">
            <FormGroup>
                {label !== undefined &&
                    <Label for={fieldId}>
                        {isChanged === undefined
                            ? label
                            : (
                                <FormLabel isChanged={isChanged} {...changesProps}>
                                    {label}
                                </FormLabel>
                            )}
                    </Label>}
                {props.children}
            </FormGroup>
        </div>
    );
});

const mapSourceOptions = (source: string): SelectOption => ({ value: source, label: source });

export const EditForm = forwardRef((props: Props, ref: React.MutableRefObject<EditFormRef>) => {
    const isNew = props.initial?.id === 0;

    const setVersion = (v: string) => props.update({ version: v });
    const setChangelogMarkdown = (v: string) => props.update({ changelogMarkdown: v });
    const setWindowsInstallerUrlX86 = (v: string) => props.update({ windowsInstallerUrlX86: v });
    const setWindowsInstallerUrlX64 = (v: string) => props.update({ windowsInstallerUrlX64: v });
    const setWindowsInstallerUrlX64AndroidX64 = (v: string) => props.update({ windowsInstallerUrlX64AndroidX64: v });
    const setIsDisabled = (v: boolean) => props.update({ isDisabled: !v });
    const setIsMandatory = (v: boolean) => props.update({ isMandatory: v });

    const sourceOptions = useMemo(() => props.source.map(mapSourceOptions), [props.source]);
    const selectedSourceOptions = useMemo(() =>
        sourceOptions.filter(o => props.value.settings.sources.find(id => id === o.value) !== undefined),
        [sourceOptions, props.value.settings.sources]);

    const [allSourcesSelected, setAllSourcesSelected] = useState<boolean>(selectedSourceOptions.length === 0);
    const changeAllSourcesSelected = (v: boolean) => {
        if (v) {
            setSources([]);
        }
        setAllSourcesSelected(v);
    };
    useEffect(() => setAllSourcesSelected(selectedSourceOptions.length === 0), [selectedSourceOptions]);

    const setSources = (v: SelectOption[]) => props.update({ settings: { sources: v === null ? [] : v.map(o => o.value) } });

    const [validate, setValidate] = useState(false);
    const [isSourcesValid, setIsSourcesValid] = useState(true);
    const [isVersionValid, setIsVersionValid] = useState(true);
    const [isWindows64UrlValid, setIsWindows64UrlValid] = useState(true);
    const [isWindows86UrlValid, setIsWindows86UrlValid] = useState(true);
    const [isWindows64UrlAndroid64Valid, setIsWindows64UrlAndroid64Valid] = useState(true);
    const sourcesFieldRef = useRef<HTMLDivElement>(null);
    const [isX64InstallerUrlValid,setIsX64InstallerUrlValid] = useState(true);
    const [isX86InstallerUrlValid, setIsX86InstallerUrlValid] = useState(true);
    const x64InstallerUrlFieldRef = useRef<HTMLDivElement>(null);
    const x86InstallerUrlFieldRef = useRef<HTMLDivElement>(null);

    const [isDeleteConfirmModalOpened, setIsDeleteConfirmModalOpened] = useState(false);

    const openDeleteConfirmModal = useCallback(() => setIsDeleteConfirmModalOpened(true), []);
    const closeDeleteConfirmModal = useCallback(() => setIsDeleteConfirmModalOpened(false), []);

    const validateFields = (scrollToInvalid: boolean = false): boolean => {

        let x64InstallerUrlValidationResult = true;
        let x86InstallerUrlValidationResult = true;

        if (props.value.windowsInstallerUrlX64 != null) {
            x64InstallerUrlValidationResult = validateUrl(props.value.windowsInstallerUrlX64!, UrlValidationType.Absolute) === null;
        }

        if (props.value.windowsInstallerUrlX86 != null) {
            x86InstallerUrlValidationResult = validateUrl(props.value.windowsInstallerUrlX86!, UrlValidationType.Absolute) === null;
        }

        const sourcesValidationResult = allSourcesSelected || selectedSourceOptions.length > 0;
        setIsSourcesValid(sourcesValidationResult);
        setIsX64InstallerUrlValid(x64InstallerUrlValidationResult);
        setIsX86InstallerUrlValid(x86InstallerUrlValidationResult);

        const windows86UrlValidationResult = !!props.value.windowsInstallerUrlX86 &&
            validateUrl(props.value.windowsInstallerUrlX86, UrlValidationType.Absolute) === null;
        setIsWindows86UrlValid(windows86UrlValidationResult);

        const windows64UrlValidationResult = !!props.value.windowsInstallerUrlX64 &&
            validateUrl(props.value.windowsInstallerUrlX64, UrlValidationType.Absolute) === null;
        setIsWindows64UrlValid(windows64UrlValidationResult);

        const windows64UrlAndroid64ValidationResult = !props.value.windowsInstallerUrlX64AndroidX64 ||
            validateUrl(props.value.windowsInstallerUrlX64AndroidX64, UrlValidationType.Absolute) === null;
        setIsWindows64UrlAndroid64Valid(windows64UrlAndroid64ValidationResult);

        const versionValidationResult = isProductVersionValid(props.value.version);
        setIsVersionValid(versionValidationResult);

        if (scrollToInvalid) {
            if (!sourcesValidationResult) {
                sourcesFieldRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
            }
            if(!x64InstallerUrlValidationResult) {
                x64InstallerUrlFieldRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
            }
            if (!x86InstallerUrlValidationResult) {
                x86InstallerUrlFieldRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
            }
        }

        return sourcesValidationResult && windows86UrlValidationResult && windows64UrlValidationResult && windows64UrlAndroid64ValidationResult &&
            versionValidationResult && x64InstallerUrlValidationResult && x86InstallerUrlValidationResult;
    };

    useEffect(() => {
        if (validate) {
            validateFields();
        }
    }, [validate,
        props.value.id,
        allSourcesSelected,
        selectedSourceOptions,
        props.value.windowsInstallerUrlX64,
        props.value.windowsInstallerUrlX86]);

    useImperativeHandle(ref, () => ({
        validate: () => {
            setValidate(true);
            return validateFields(true);
        }
    }), [sourcesFieldRef.current, allSourcesSelected, selectedSourceOptions]);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <Form className="mt-3" onSubmit={save} noValidate>
            <Row>
                <Col sm={12}>
                    <FormGroup check>
                        <Label for="isEnabled" check>
                            <Input
                                type="checkbox"
                                id="isEnabled"
                                name="isEnabled"
                                checked={!props.value.isDisabled}
                                onChange={setFromInput(setIsDisabled)}
                                disabled={props.saving}
                            />{" "}
                            <FormLabel
                                isChanged={props.value.isDisabled !== props.initial?.isDisabled}
                                originalValue={isNew ? undefined : props.initial?.isDisabled}>
                                Enabled
                            </FormLabel>
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <FormGroup check>
                        <Label for="mandatory" check>
                            <Input
                                type="checkbox"
                                id="mandatory"
                                name="mandatory"
                                checked={props.value.isMandatory}
                                onChange={setFromInput(setIsMandatory)}
                                disabled={props.saving}
                            />{" "}
                            <FormLabel
                                isChanged={props.value.isMandatory !== props.initial?.isMandatory}
                                originalValue={isNew ? undefined : props.initial?.isMandatory}>
                                Mandatory
                            </FormLabel>
                        </Label>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <EditField
                    label="Version"
                    fieldId="version"
                    isChanged={props.value.version !== props.initial?.version}
                    originalValue={isNew ? undefined : props.initial?.version}
                >
                    <Input
                        type="text"
                        id="version"
                        value={props.value.version ?? ""}
                        onChange={setFromInput(setVersion)}
                        disabled={props.saving}
                        placeholder="0.0.0.0"
                    />
                    <StyledTooltip placement="top" isOpen={!isVersionValid} target="version">
                        Version must have 4 segments and contain only numbers.
                    </StyledTooltip>
                </EditField>
                <EditField
                    label="Changelog Markdown"
                    fieldId="changelogMarkdown"
                    isChanged={props.value.changelogMarkdown !== props.initial?.changelogMarkdown}
                    originalValue={isNew ? undefined : props.initial?.changelogMarkdown}
                >
                    <MarkdownEditor
                        id="changelogMarkdown"
                        value={props.value.changelogMarkdown ?? ""}
                        onChange={setChangelogMarkdown}
                        disabled={props.saving}
                    />
                </EditField>
                <EditField
                    label="Windows Installer URL x86 / Android 32bit"
                    fieldId="windowsInstallerUrlX86"
                    isChanged={props.value.windowsInstallerUrlX86 !== props.initial?.windowsInstallerUrlX86}
                    originalValue={isNew ? undefined : props.initial?.windowsInstallerUrlX86}
                >
                    <div ref={x86InstallerUrlFieldRef} />
                    <Input
                        type="text"
                        id="windowsInstallerUrlX86"
                        value={props.value.windowsInstallerUrlX86 ?? ""}
                        onChange={setFromInput(setWindowsInstallerUrlX86)}
                        disabled={props.saving}
                    />
                    <StyledTooltip placement="top" isOpen={!isWindows86UrlValid} target="windowsInstallerUrlX86">
                        Url is required and must be absolute.
                    </StyledTooltip>
                </EditField>
                <StyledTooltip placement="bottom" isOpen={!isX86InstallerUrlValid} target="windowsInstallerUrlX86">
                    URL must be absolute.
                </StyledTooltip>
                <EditField
                    label="Windows Installer URL x64 / Android 32bit"
                    fieldId="windowsInstallerUrlX64"
                    isChanged={props.value.windowsInstallerUrlX64 !== props.initial?.windowsInstallerUrlX64}
                    originalValue={isNew ? undefined : props.initial?.windowsInstallerUrlX64}
                >
                    <div ref={x64InstallerUrlFieldRef} />
                    <Input
                        type="text"
                        id="windowsInstallerUrlX64"
                        value={props.value.windowsInstallerUrlX64 ?? ""}
                        onChange={setFromInput(setWindowsInstallerUrlX64)}
                        disabled={props.saving}
                    />
                    <StyledTooltip placement="top" isOpen={!isWindows64UrlValid} target="windowsInstallerUrlX64">
                        Url is required and must be absolute.
                    </StyledTooltip>
                </EditField>
                <EditField
                    label="Windows Installer URL x64 / Android 64bit"
                    fieldId="windowsInstallerUrlX64AndroidX64"
                    isChanged={props.value.windowsInstallerUrlX64AndroidX64 !== props.initial?.windowsInstallerUrlX64AndroidX64}
                    originalValue={isNew ? undefined : props.initial?.windowsInstallerUrlX64AndroidX64}
                >
                    <Input
                        type="text"
                        id="windowsInstallerUrlX64AndroidX64"
                        value={props.value.windowsInstallerUrlX64AndroidX64 ?? ""}
                        onChange={setFromInput(setWindowsInstallerUrlX64AndroidX64)}
                        disabled={props.saving}
                    />
                    <StyledTooltip placement="top" isOpen={!isWindows64UrlAndroid64Valid} target="windowsInstallerUrlX64AndroidX64">
                        Url must be absolute.
                    </StyledTooltip>
                </EditField>
                <StyledTooltip placement="bottom" isOpen={!isX64InstallerUrlValid} target="windowsInstallerUrlX64">
                    URL must be absolute.
                </StyledTooltip>
                <Col sm={12}>
                    <FormGroup check>
                        <Label for="allSourcesSelected" check>
                            <Input
                                type="checkbox"
                                id="allSourcesSelected"
                                name="allSourcesSelected"
                                checked={allSourcesSelected}
                                onChange={setFromInput(changeAllSourcesSelected)}
                                disabled={props.saving}
                            />
                                {" "}All sources
                        </Label>
                    </FormGroup>
                </Col>
                {allSourcesSelected === true
                ?
                !(<EditField
                    ref={sourcesFieldRef}
                    label={"Marketing Sources"}
                    fieldId="sourceSettings"
                    isChanged={props.value.settings.sources.join() !== props.initial?.settings.sources.join()}
                >
                    <Select
                        id="sourceSettings"
                        placeholder="Marketing Sources"
                        value={selectedSourceOptions}
                        onChange={setSources}
                        options={sourceOptions}
                        isMulti={true}
                        isSearchable={true}
                        isDisabled={props.saving || allSourcesSelected}
                    />
                </EditField>)
                :
                <EditField
                    ref={sourcesFieldRef}
                    label={"Marketing Sources"}
                    fieldId="sourceSettings"
                    isChanged={props.value.settings.sources.join() !== props.initial?.settings.sources.join()}
                >
                    <Select
                        id="sourceSettings"
                        placeholder="Marketing Sources"
                        value={selectedSourceOptions}
                        onChange={setSources}
                        options={sourceOptions}
                        isMulti={true}
                        isSearchable={true}
                        isDisabled={props.saving || allSourcesSelected}
                    />
                    <StyledTooltip placement="top" isOpen={!isSourcesValid} target="sourceSettings">
                        Sources must be selected.
                    </StyledTooltip>
                </EditField>}
            </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <Button type="submit" color="primary" disabled={props.saving}>
                        {isNew ? "Add" : "Save"} Product Version
                    </Button>
                    <Button
                        type="button"
                        className="ml-2"
                        color="danger"
                        disabled={props.saving || props.value.id === 0}
                        onClick={openDeleteConfirmModal}
                    >
                        Delete Product Version
                    </Button>
                    <ConfirmationModal
                        title="Confirm product version deletion"
                        open={isDeleteConfirmModalOpened}
                        confirm={props.onDelete}
                        cancel={closeDeleteConfirmModal}>
                        Do you want to delete product version <b>{props.value.version}</b>?
                    </ConfirmationModal>
                </Col>
            </Row>
        </Form>
    );
});
