import { client } from "src/shared/client";
import {
    GetGamestoreApplications,
    GetRequestStatisticsSummaries,
} from "src/shared/dtos";
import { ExcludeMethods } from "src/shared/helpers";

export type GetGamestoreAppsRequest = ExcludeMethods<GetGamestoreApplications>;
export type GetRequestStatisticsRequest = ExcludeMethods<GetRequestStatisticsSummaries>;

export const listWidgetsApi = {
    getGamestoreApplications: (request: GetGamestoreAppsRequest) => client.get(
        new GetGamestoreApplications(request)),
    getRequestStatistics: (request: GetRequestStatisticsRequest) => client.get(
        new GetRequestStatisticsSummaries(request)),
};

export default listWidgetsApi;