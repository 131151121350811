import React, { useState, useCallback, useMemo, useEffect } from "react";
import { BreadcrumbItem } from "reactstrap";
import { noop, defer } from "rxjs";
import { OrderDirection, ProductVersionInfoListDataType, ProductVersionInfo, SourceSettings } from "src/shared/dtos";
import { Breadcrumb, DocumentTitle, RouteLink, RouteUserProps, Loader } from "src/shared/components";
import {
    Column,
    DefaultCellRenderer,
    InfiniteTable,
    TopPanelRendererProps,
    TopPanelLeftContainer,
    LoadRequest,
    SearchOptions,
    multiValueSearchFactory,
    SuggestionTag,
    StringSearch,
    BooleanIndicator,
    BooleanSearch
} from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { Content, VerticalBox } from "src/shared/components/flex";
import {
    useFunctionState,
    deserializeQueryParameters,
    QueryParameterDescriptor,
    serializeQueryParameters,
    pushUrl,
    getGridVisibleColumns,
    isColumnHidden,
    useBootstrapBreakpoint
} from "src/shared/helpers";
import { routes } from "src/shared/routes";
import api from "../api";
import { withRouter, RouteComponentProps } from "react-router-dom";

const keyDataTypes: { [key: string]: ProductVersionInfoListDataType } = {
    "id": ProductVersionInfoListDataType.Id,
    "version": ProductVersionInfoListDataType.Version,
    "enabled": ProductVersionInfoListDataType.Enabled
};
const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType as ProductVersionInfoListDataType);

function getSearchValueByKey<T>(key: string, searchOptions?: Array<SearchOptions<unknown>>): T | undefined {
    return searchOptions?.find(s => s.key === key)?.value as T;
}

const normalizeVersion = (value: string) => {
    if (value === undefined) {
        return undefined;
    }

    const maxParts = 4;
    const parts = value.split(".")
        .map(p => p.trim())
        .filter((_, i) => i < maxParts);

    const validParts: string[] = [];
    for (const i in parts) {
        if (!parts.hasOwnProperty(i)) {
            continue;
        }

        const part = parts[i];
        const isNumber = /^\d+$/.test(part);
        if (!isNumber) {
            break;
        }

        validParts.push(part);
    }

    return validParts.join(".");
};

const searchQueryParameterDescriptors: QueryParameterDescriptor[] = [
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    { paramType: "search", paramName: "version" },
    {
        paramType: "search",
        paramName: "sources",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    { paramType: "search", paramName: "enabled", serializer: "boolean", deserializer: "boolean" },
    { paramType: "search", paramName: "mandatory", serializer: "boolean", deserializer: "boolean" }
];

export const ListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [tableFilters, sort] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...searchQueryParameterDescriptors);
        const searchParams = allParams
            .filter(p => p.paramType === "search")
            .map(qp => ({ key: qp.paramName, value: qp.value } as SearchOptions<unknown>));

        const orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        const orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;

        return [searchParams, { key: orderBy, order: orderDir } as SortOptions];
    }, [location.search]);

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    const [items, setItems] = useState<ProductVersionInfo[]>([]);
    const [visibleColumns, setVisibleColumns] = useState<string[] | "all" | undefined>();
    const [allSources, setAllSources] = useState<string[] | undefined>();

    const breakpoint = useBootstrapBreakpoint();

    useEffect(() => {
        const subscription = defer(() => api.getSourceSettings()).subscribe(sourceSettings => {
            const allSourcesTag = { id: 0, source: "All", settings: "[]", isDeleted: false } as SourceSettings;
            const sources = sourceSettings.map(s => s.source);
            sources.push(allSourcesTag.source);
            setAllSources(sources);
        });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        const subscription = defer(() => getGridVisibleColumns("ProductVersions")).subscribe(columns => {
            setVisibleColumns(columns ?? "all");
        });
        return () => subscription.unsubscribe();
    }, [breakpoint]);

    useEffect(() => unsubscribe, [unsubscribe]);
    const load = useCallback(
        (request: LoadRequest) => {
            if (request.reload) {
                setItems([]);
            }

            const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : ProductVersionInfoListDataType.Id;
            const searchRequest = {
                orderBy: orderByKey,
                orderDir: orderByKey !== undefined
                    ? request.sort?.order === SortOrder.DESC ? OrderDirection.Desc : OrderDirection.Asc
                    : undefined,
                version: getSearchValueByKey<string>("version", request.search),
                sources: getSearchValueByKey<string[]>("sources", request.search),
                enabled: getSearchValueByKey<boolean>("enabled", request.search),
                mandatory: getSearchValueByKey<boolean>("mandatory", request.search)
            };

            const observable = defer(() => api.list({
                offset: request.offset,
                limit: request.limit,
                orderBy: searchRequest.orderBy,
                orderDirection: searchRequest.orderDir ?? OrderDirection.Asc,
                versionSearch: searchRequest.version !== undefined ? normalizeVersion(searchRequest.version) : undefined,
                sourcesSearch: searchRequest.sources,
                isEnabledSearch: searchRequest.enabled !== undefined ? !searchRequest.enabled : undefined,
                isMandatorySearch: searchRequest.mandatory !== undefined ? searchRequest.mandatory : undefined
            }));

            const url = routes.productVersions.url({
                searchParams: serializeQueryParameters(searchRequest, ...searchQueryParameterDescriptors)
            });
            pushUrl(location, history, url);

            return new Promise<boolean>(resolve => {
                const subscription = observable.subscribe(result => {
                    if (result.length > 0) {
                        setItems(request.reload ? result : items.concat(result));
                    }
                    resolve(result.length === 0);
                });
                setUnsubscribe(() => subscription.unsubscribe());
            });
        }, [items, location]);

    const sourcesMapper = (id: string): SuggestionTag => ({ value: id, label: id });

    const TopPanel = useMemo(() =>
        (props: TopPanelRendererProps) =>
            <props.blockElement>
                <TopPanelLeftContainer>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Product Versions</BreadcrumbItem>
                    </Breadcrumb>
                </TopPanelLeftContainer>
                <RouteLink user={user} to={routes.addProductVersion} button className="mr-2">
                    Add Product Version
                </RouteLink>
                <props.reloadElement />
                <props.optionsElement />
            </props.blockElement>, []);

    const versionRenderer = useMemo(() =>
        (props: CellProps<string | undefined, ProductVersionInfo>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink user={user} to={{ route: routes.editProductVersion, args: { id: props.rowData.id + "" } }}>
                    {cellData}
                </RouteLink>
            );

            return <DefaultCellRenderer cellData={link} title={cellData} {...restProps} />;
        }, []);

    const sourcesRenderer = useMemo(() =>
        (props: CellProps<string[], ProductVersionInfo>) => {
            const { cellData, ...restProps } = props;
            const cell = (
                <React.Fragment>
                    {cellData.length === 0 && <span><i>[ALL]</i></span>}
                    {cellData.map((source, i) => {
                        const title = `Parent ID: ${source}`;

                        return (
                            <span key={`${source} ${i}`}>
                                <span title={title}>{source}</span>
                                {i < cellData.length - 1 &&
                                    <span>, </span>}
                            </span>
                        );
                    })}
                </React.Fragment>
            );

            return <DefaultCellRenderer cellData={cell} {...restProps} />;
        }, [allSources]);

    const isEnabledRenderer = useMemo(() =>
    (props: CellProps<boolean, ProductVersionInfo>) => {
        const { cellData, ...restProps } = props;
        const value = <BooleanIndicator value={cellData} />;
        const title = cellData
            ? "Product version is enabled."
            : "Product version is disabled.";

        return <DefaultCellRenderer cellData={value} title={title} centerText={true} {...restProps} />;
    }, []);

    const isMandatoryRenderer = useMemo(() => (props: CellProps<boolean, ProductVersionInfo>) => {
        const { cellData, ...restProps } = props;
        const value = <BooleanIndicator value={cellData} />;
        const title = cellData
            ? "Product version is mandatory."
            : "Product version isn't mandatory.";

        return <DefaultCellRenderer cellData={value} title={title} centerText={true} {...restProps} />;
    }, []);

    const select = makeSelect<ProductVersionInfo>();
    return (
        <VerticalBox>
            <DocumentTitle title="Product Versions" />
            <Content overflowHidden>
                {(allSources === undefined || visibleColumns === undefined) && <Loader />}
                {allSources !== undefined && visibleColumns !== undefined &&
                    <InfiniteTable
                        items={items}
                        load={load}
                        filters={tableFilters}
                        sortBy={sort}
                        topPanelRenderer={TopPanel}
                        visibleColumns={visibleColumns}
                    >
                        <Column
                            key="version"
                            dataGetter={select(a => a.version)}
                            title="Version"
                            width={250}
                            cellRenderer={versionRenderer}
                            searchRenderer={StringSearch}
                            hidden={isColumnHidden("version", visibleColumns)}
                        />
                        <Column
                            key="sources"
                            dataGetter={select(a => a.settings.sources)}
                            title="Sources"
                            width={400}
                            sortable={false}
                            cellRenderer={sourcesRenderer}
                            searchRenderer={multiValueSearchFactory(allSources, sourcesMapper)}
                            hidden={isColumnHidden("sources", visibleColumns)}
                        />
                        <Column
                            key="changelog"
                            dataGetter={select(a => a.changelogMarkdown)}
                            title="Changelog"
                            width={300}
                            sortable={false}
                            hidden={isColumnHidden("changelog", visibleColumns)}
                        />
                        <Column
                            key="enabled"
                            dataGetter={select(a => !a.isDisabled)}
                            title="Enabled"
                            width={100}
                            sortable={false}
                            cellRenderer={isEnabledRenderer}
                            searchRenderer={BooleanSearch}
                            hidden={isColumnHidden("enabled", visibleColumns)}
                        />
                        <Column
                            key="mandatory"
                            dataGetter={select(a => a.isMandatory)}
                            title="Mandatory"
                            width={100}
                            sortable={false}
                            cellRenderer={isMandatoryRenderer}
                            searchRenderer={BooleanSearch}
                            hidden={isColumnHidden("mandatory", visibleColumns)}
                        />
                    </InfiniteTable>}
            </Content>
        </VerticalBox>);
});
