import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemedProps } from "../../types";

export const DownloaderPreviewBlock = styled.div`
    position: relative;
    width: 800px;
`;

export const PreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${(p: ThemedProps) => p.theme.WindowBackColor};
    height: 100%;
`;

export const PreviewHeader = styled.div`
    height: 28px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
`;

export const LeftCornerIcon = styled.img`
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 100px;
    object-fit: contain;
`;

export const ControlButtonsBlock = styled.div`
    display: flex;

    & > span:not(:first-child) {
        margin-left: 4px;
    }
`;

export const CloseButton = styled.span`
    color: ${(p: ThemedProps) => p.theme.WindowCloseButtonColor};
    font-size: 1.275rem;
    margin-top: -0.3rem;
    cursor: pointer;
    &:hover {
        color: ${(p: ThemedProps) => p.theme.WindowCloseButtonMouseOverColor};
    }
    &:active {
        color: ${(p: ThemedProps) => p.theme.WindowCloseButtonMouseClickColor};
    }
    &:focus {
        outline: none;
    }
`;

export const CloseIcon = styled(FontAwesomeIcon).attrs({ icon: "times" })``;

export const MinimizeIcon = styled(FontAwesomeIcon).attrs({ icon: "minus" })`
    vertical-align: bottom;
`;

export const PreviewImage = styled.div<{ img: string }>`
    height: 420px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${ p => p.img });
`;

export const PreviewFooter = styled.div`
    display: flex;
    flex-direction: column;
    padding: 6px 8px 14px;
`;