import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Container } from "reactstrap";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    DocumentTitle,
    RouteLink,
    RouteUserProps
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { routes } from "src/shared/routes";
import { DetailsRequest } from "../api";
import { DetailsComponent } from "./DetailsComponent";

export type DetailsRouteParams = Partial<Record<keyof DetailsRequest, string>>;

export const DetailsView = withRouter(({ user, location, history }: RouteComponentProps<DetailsRouteParams> & RouteUserProps) => {
    return (
        <VerticalBox>
            <DocumentTitle title={`Playstore Activity Statistics Details`} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.playstoreActivityStatistics}>
                                Playstore Activity Statistics
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            Details
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    <Container fluid>
                        <DetailsComponent location={location} history={history} />
                    </Container>
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});
