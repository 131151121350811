import React, { useMemo, useEffect, forwardRef, useImperativeHandle, useCallback } from "react";
import { Styles } from "react-select";
import { Form, Input, FormGroup, Label, InputGroupAddon, InputGroup } from "reactstrap";
import { ApkDetails, ApkPlatform, Category, GamestoreApplication, GoogleApplication, ScreenOrientationType } from "src/shared/dtos";
import { CategorySelectOption as CategorySelectOptionBase, mapCategorySelectOption } from "src/shared/helpers/mapCategorySelectOption";
import { PlatformSelector } from "src/shared/components";
import { GamestoreAppMainInfo } from "./applicationReducer";
import { PlatformDetailsState } from "./platformDetailsReducer";
import { formatAsAbbreviation, setFromInput, SetFromInputType, SelectOption, ValidateAsyncRef, useValidate } from "src/shared/helpers";
import { HorizontalField, FormLabel, Select, LoadingButton } from "src/shared/components";
import { googleProperty } from "./googleProperty";
import { makeApplicationValidationSchema } from "./validationSchema";
import { CategoryType } from "src/shared/CategoryType";

const categorySelectStyles: Partial<Styles> = {
    multiValue: (styles, { data }: { data: CategorySelectOption, hasGameCategory: boolean }) => ({
        ...styles,
        backgroundColor: data.isInherited ? "#e6e6e6" : "#007bff",
    }),
    multiValueLabel: (styles, { data }: { data: CategorySelectOption }) => ({
        ...styles,
        color: data.isInherited ? "" : "white",
    }),
    multiValueRemove: (styles, { data }: { data: CategorySelectOption }) => ({
        ...styles,
        color: data.isInherited ? "#333333" : "white",
        ":hover": {
            backgroundColor: data.isInherited ? "#545b62" : "#0069d9",
            color: "white",
        },
    })
};

export type ScreenOrientationSelectOption = SelectOption<ScreenOrientationType | undefined>;

export const mapScreenOrientationSelectOption = (screenOrientationType: ScreenOrientationType) => {
    const value = screenOrientationType;
    const label = screenOrientationType;
    return { label, value } as ScreenOrientationSelectOption;
};

type CategorySelectOption = CategorySelectOptionBase & { isInherited: boolean };

const defaultAvailableInstallsCounts = [
    0, 1, 10, 50, 100, 500,
    1000, 5000, 10000, 50000, 100000, 500000,
    1000000, 5000000, 10000000, 50000000, 100000000,
    1000000000, 5000000000, 10000000000, 50000000000, 100000000000,
    1000000000000, 5000000000000, 10000000000000, 50000000000000, 100000000000000
];

interface Props {
    value: GamestoreAppMainInfo;
    platformDetails: PlatformDetailsState;
    initial?: GamestoreAppMainInfo;
    isNewApp: boolean;
    categories: Category[];
    googleApplications: GoogleApplication[];
    update: React.Dispatch<Partial<GamestoreAppMainInfo> | undefined>;
    updateDetails: React.Dispatch<Partial<ApkDetails> | undefined>;
    selectPlatform: React.Dispatch<ApkPlatform>;
    setGoogleApplication: React.Dispatch<GoogleApplication>;
    saving: boolean;
    onSubmit: () => void;
}

export const EditForm = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {

    const categoriesById = useMemo(
        () => new Map(props.categories.map(c => [c.id, c])), [props.categories]);

    const gsCategories = useMemo(
        () => props.value.categoryIds.map(catId => categoriesById.get(catId)).filter(c => c) as Category[],
        [categoriesById, props.value.categoryIds]);

    const validationSchema = useMemo(
        () => makeApplicationValidationSchema(gsCategories),
        [gsCategories]);

    const { validate, errors, register } = useValidate({ schema: validationSchema, value: props.value });

    useImperativeHandle(ref, () => ({
        validate: async () => (await validate(true)).isValid
    }), [validate]);

    const availableInstallsCounts = useMemo(
        () => {
            const currentInstallCount = props.value.installsCount ?? props.value.googleApplication.installsCount;
            return defaultAvailableInstallsCounts.indexOf(currentInstallCount) === -1
                ? defaultAvailableInstallsCounts.concat(currentInstallCount).sort((a, b) => a - b)
                : defaultAvailableInstallsCounts;
        }, [props.value.installsCount, props.value.googleApplication.installsCount]);

    const availableReviewsCounts = useMemo(
        () => {
            const currentReviewsCount = props.value.reviewsCount ?? props.value.googleApplication.reviewsCount ?? 0;
            return defaultAvailableInstallsCounts.indexOf(currentReviewsCount!) === -1
                ? defaultAvailableInstallsCounts.concat(currentReviewsCount!).sort((a, b) => a - b)
                : defaultAvailableInstallsCounts;
        }, [props.value.reviewsCount, props.value.googleApplication.reviewsCount]);

    const googleAppOptions = useMemo(
        () => props.googleApplications.map(a => ({ value: a.id, label: a.id } as SelectOption)),
        [props.googleApplications]);

    const selectedGoogleAppOption = useMemo(
        () => googleAppOptions.find(o => o.value === props.value.id),
        [props.value.id, googleAppOptions]);

    const categoryOptions = useMemo(
        () => props.categories
            .filter(c => c.parentId !== undefined && c.parentId !== CategoryType.Io && !c.isVirtual)
            .map(c => mapCategorySelectOption<CategorySelectOption>(c, props.categories)),
        [props.categories, props.value.googleApplication.category]);

    const selectedCategoryOptions = useMemo(
        () => gsCategories.map(c => {
            const option = mapCategorySelectOption<CategorySelectOption>(c, props.categories);
            option.isInherited = props.value.googleApplication.category.id === c.id;

            return option;
        }),
        [props.value.categoryIds, gsCategories]);

    const originalCategoriesValue = useMemo(
        () => {
            const categories = props.initial?.categoryIds
                .map(id => categoriesById.get(id))
                .filter(c => c !== undefined)
                .map(c => mapCategorySelectOption(c!, props.categories));
            return categories?.map(c => c.label).join(", ");
        },
        [props.categories, props.initial?.categoryIds, categoriesById]);

    const setAppId = (v: SelectOption<string | undefined>) => {
        props.update({ id: v.value });

        const googleApp = props.googleApplications.find(a => a.id === v.value);
        if (googleApp !== undefined) {
            props.setGoogleApplication(googleApp);
        }
    };
    const setRating = (v: number) => props.update({ rating: v });
    const setInstallsCount = (v: number) => props.update({
        installsCount:
            props.initial?.installsCount !== undefined
                ? v
                : v === props.value.googleApplication.installsCount ? undefined : v
    });
    const setReviewsCount = (v: number) => props.update({
        reviewsCount:
            props.initial?.reviewsCount !== undefined
                ? v
                : v === props.value.googleApplication.reviewsCount ? undefined : v
    });

    const setIsEnabled = (v: boolean) => props.update({ isDisabled: !v });
    const setIsGraphicsCustomized = (v: boolean) => props.update({ isGraphicsCustomized: v });
    const setUseGooglePlay = (v: boolean) => props.update({ useGooglePlay: v });
    const setNeedGoogleAccount = (v: boolean) => props.update({ needGoogleAccount: v });

    const setCategories = useCallback((v: CategorySelectOption[]) => {
        const categoryIds = v === null ? [] : v.map(c => c.value!);
        props.update({ categoryIds });
    }, []);

    const setScreenOrientation = (v: ScreenOrientationSelectOption) => props.update(
        { screenOrientation: v?.value });

    const screenOrientationOptions = useMemo(() => [
        mapScreenOrientationSelectOption(ScreenOrientationType.Auto),
        mapScreenOrientationSelectOption(ScreenOrientationType.Landscape),
        mapScreenOrientationSelectOption(ScreenOrientationType.Portrait)], []);

    const selectedScreenOrientation = useMemo(() =>
        screenOrientationOptions.find(s => s.value === props.value.screenOrientation),
        [props.value.screenOrientation, screenOrientationOptions]);

    const setScreenWidth = (v: number) => props.update({ screenWidth: Math.trunc(v) });
    const setScreenHeight = (v: number) => props.update({ screenHeight: Math.trunc(v) });

    const hasGameCategory = useMemo(() => {
        const googleCategory = categoriesById.get(props.value.googleApplication.categoryId);
        const resultingCategories = googleCategory === undefined || gsCategories.some(c => c.parentId === CategoryType.Game || c.parentId === CategoryType.App)
            ? gsCategories
            : gsCategories.concat(googleCategory);

        return resultingCategories.some(c => c.parentId === CategoryType.Game);
    }, [gsCategories, props.value.googleApplication, categoriesById]);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    useEffect(() => {
        const googleApp = props.googleApplications.find(a => a.id === props.value.id);
        if (googleApp !== undefined) {
            props.setGoogleApplication(googleApp);
        }
    }, [props.googleApplications, props.value.id, props.setGoogleApplication]);

    const setVersion = (v: string) => props.updateDetails({ versionName: v });
    const setPlatform = (v: ApkPlatform) => props.selectPlatform(v);
    const platforms = useMemo(() => props.platformDetails.all.map(a => a.platform), [props.platformDetails.all]);

    const { current: currentDetails, initial: initialDetails } = props.platformDetails;

    const googleVersion = useMemo(
        () => props.value.googleApplication.apkDetails?.find(apk => apk.platform === currentDetails?.platform)?.versionName ?? "",
        [props.value.googleApplication.apkDetails, currentDetails?.platform]);

    return (
        <Form onSubmit={save} noValidate>
            <HorizontalField className="text-right">
                <LoadingButton
                    loading={props.saving}
                    position="end"
                    type="submit"
                    color="primary"
                    className="ml-2 mb-2"
                    loadingText={"Saving..."}>
                    {props.isNewApp ? "Add" : "Save"} application
                </LoadingButton>
            </HorizontalField>
            {props.isNewApp &&
                <React.Fragment>
                    <HorizontalField
                        ref={register("id")}
                        label="Application ID"
                        fieldId="appId"
                        errors={errors?.id}>
                        <Select
                            id="appId"
                            value={selectedGoogleAppOption}
                            onChange={setAppId}
                            options={googleAppOptions}
                            isMulti={false}
                            isSearchable={true}
                            isDisabled={props.saving}
                            isInvalid={(errors?.id?.length ?? 0) > 0}
                        />
                    </HorizontalField>
                </React.Fragment>}
            <HorizontalField
                label={googleProperty("Version", !props.platformDetails.current?.versionName)}
                fieldId="version"
                isChanged={currentDetails?.versionName !== initialDetails?.versionName}
                originalValue={props.isNewApp ? undefined : initialDetails?.versionName}>
                <InputGroup>
                    <InputGroupAddon addonType="prepend">
                        <PlatformSelector value={currentDetails?.platform} setValue={setPlatform} platforms={platforms} />
                    </InputGroupAddon>
                    <Input
                        type="text"
                        id="version"
                        value={currentDetails?.versionName ?? ""}
                        placeholder={googleVersion}
                        onChange={setFromInput(setVersion)}
                        disabled={props.saving || platforms.length === 0}
                    />
                </InputGroup>
            </HorizontalField>
            <HorizontalField
                label={googleProperty("Rating", props.value.rating === undefined)}
                fieldId="rating"
                isChanged={props.value.rating !== props.initial?.rating}
                originalValue={props.isNewApp ? undefined : props.initial?.rating}>
                <Input
                    type="number"
                    id="rating"
                    min="0"
                    max="5"
                    step="0.01"
                    value={props.value.rating}
                    placeholder={props.value.googleApplication.rating + ""}
                    onChange={setFromInput(setRating)}
                    disabled={props.saving} />
            </HorizontalField>
            <HorizontalField
                label={googleProperty("Installs Count", props.value.installsCount === undefined)}
                fieldId="installsCount"
                isChanged={props.value.installsCount !== props.initial?.installsCount}
                originalValue={props.isNewApp ? undefined : (props.initial?.installsCount
                    ? formatAsAbbreviation(props.initial.installsCount)
                    : undefined)}>
                <Input
                    type="select"
                    id="installsCount"
                    value={props.value.installsCount ?? props.value.googleApplication.installsCount}
                    onChange={setFromInput(setInstallsCount, SetFromInputType.Number)}
                    disabled={props.saving}>
                    {availableInstallsCounts.map((v, i) =>
                        <option key={`${v}_${i}`} value={v}>{formatAsAbbreviation(v)}</option>)}
                </Input>
            </HorizontalField>
            <HorizontalField
                label={googleProperty("Reviews Count", props.value.reviewsCount === undefined)}
                fieldId="reviewsCount"
                isChanged={props.value.reviewsCount !== props.initial?.reviewsCount}
                originalValue={props.isNewApp ? undefined : (props.initial?.reviewsCount
                    ? formatAsAbbreviation(props.initial.reviewsCount)
                    : undefined)}>
                <Input
                    type="select"
                    id="reviewsCount"
                    value={props.value.reviewsCount ?? props.value.googleApplication.reviewsCount}
                    onChange={setFromInput(setReviewsCount, SetFromInputType.Number)}
                    disabled={props.saving}>
                    {availableReviewsCounts.map((v, i) =>
                        <option key={`${v}_${i}`} value={v}>{formatAsAbbreviation(v)}</option>)}
                </Input>
            </HorizontalField>
            <HorizontalField
                ref={register("categoryIds")}
                label="Categories"
                fieldId="categories"
                isChanged={props.value.categoryIds?.join() !== props.initial?.categoryIds?.join()}
                originalValue={originalCategoriesValue}
                errors={errors?.categoryIds}
            >
                <Select
                    id="categories"
                    value={selectedCategoryOptions}
                    onChange={setCategories}
                    options={categoryOptions}
                    isMulti={true}
                    isSearchable={true}
                    isDisabled={props.saving}
                    styles={categorySelectStyles}
                    isInvalid={(errors?.categoryIds?.length ?? 0) > 0}
                />
            </HorizontalField>
            <HorizontalField
                fieldId="screenOrientation"
                label="Screen Orientation"
                isChanged={props.value.screenOrientation !== props.initial?.screenOrientation}
            >
                <Select
                    id="screenOrientation"
                    placeholder={"Screen Orientation"}
                    value={selectedScreenOrientation}
                    onChange={setScreenOrientation}
                    options={screenOrientationOptions}
                    isMulti={false}
                    isSearchable={true}
                    isDisabled={props.saving}
                />
            </HorizontalField>
            {/* TODO: Uncomment fields below when all information concerning task #MEV-2241 will be clarified */}
            {/* <HorizontalField
                fieldId="screenWidth"
                label="Screen Width"
                isChanged={props.value.screenWidth !== props.initial?.screenWidth}
                originalValue={props.isNewApp ? undefined : props.initial?.screenWidth}
            >
                <Input
                    type="number"
                    id="screenWidth"
                    min="0"
                    step="1"
                    value={props.value.screenWidth}
                    onChange={setFromInput(setScreenWidth)}
                    disabled={props.saving || hasGameCategory} />
                {hasGameCategory && <p className="text-muted mb-0">Screen width is not adjustable for applications with GAME category</p>}
            </HorizontalField>
            <HorizontalField
                fieldId="screenHeight"
                label="Screen Height"
                isChanged={props.value.screenHeight !== props.initial?.screenHeight}
                originalValue={props.isNewApp ? undefined : props.initial?.screenHeight}
            >
                <Input
                    type="number"
                    id="screenHeight"
                    min="0"
                    step="1"
                    value={props.value.screenHeight}
                    onChange={setFromInput(setScreenHeight)}
                    disabled={props.saving || hasGameCategory} />
                {hasGameCategory && <p className="text-muted mb-0">Screen height is not adjustable for applications with GAME category</p>}
            </HorizontalField> */}
            <HorizontalField>
                <FormGroup check>
                    <Label for="isEnabled" check>
                        <Input
                            type="checkbox"
                            id="isEnabled"
                            name="isEnabled"
                            checked={!props.value.isDisabled}
                            onChange={setFromInput(setIsEnabled)}
                            disabled={props.saving}
                        />{" "}
                        <FormLabel
                            isChanged={props.value.isDisabled !== props.initial?.isDisabled}
                            originalValue={props.isNewApp ? undefined : !props.initial?.isDisabled}>
                            Enabled
                        </FormLabel>
                    </Label>
                </FormGroup>
            </HorizontalField>
            <HorizontalField>
                <FormGroup check>
                    <Label for="isGraphicsCustomized" check>
                        <Input
                            type="checkbox"
                            id="isGraphicsCustomized"
                            name="isGraphicsCustomized"
                            checked={props.value.isGraphicsCustomized}
                            onChange={setFromInput(setIsGraphicsCustomized)}
                            disabled={props.saving}
                        />{" "}
                        <FormLabel
                            isChanged={props.value.isGraphicsCustomized !== props.initial?.isGraphicsCustomized}
                            originalValue={props.isNewApp ? undefined : props.initial?.isGraphicsCustomized}>
                            Graphics Customized
                        </FormLabel>
                    </Label>
                </FormGroup>
            </HorizontalField>
            <HorizontalField>
                <FormGroup check>
                    <Label for="useGooglePlay" check>
                        <Input
                            type="checkbox"
                            id="useGooglePlay"
                            name="useGooglePlay"
                            checked={props.value.useGooglePlay}
                            onChange={setFromInput(setUseGooglePlay)}
                            disabled={props.saving}
                        />{" "}
                        <FormLabel
                            isChanged={props.value.useGooglePlay !== props.initial?.useGooglePlay}
                            originalValue={props.isNewApp ? undefined : props.initial?.useGooglePlay}>
                            Use Google Play
                        </FormLabel>
                    </Label>
                </FormGroup>
            </HorizontalField>
            <HorizontalField>
                <FormGroup check>
                    <Label for="needGoogleAccount" check>
                        <Input
                            type="checkbox"
                            id="needGoogleAccount"
                            name="needGoogleAccount"
                            checked={props.value.needGoogleAccount}
                            onChange={setFromInput(setNeedGoogleAccount)}
                            disabled={props.saving}
                        />{" "}
                        <FormLabel
                            isChanged={props.value.needGoogleAccount !== props.initial?.needGoogleAccount}
                            originalValue={props.isNewApp ? undefined : props.initial?.needGoogleAccount}>
                            Need Google Account
                        </FormLabel>
                    </Label>
                </FormGroup>
            </HorizontalField>
        </Form>
    );
});
