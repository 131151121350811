import React, { useState, createRef, useEffect } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import styled from "styled-components";
import { ThinScrollbar } from "src/shared/components/mixin";
import { VerticalBox, Header, Content } from "src/shared/components/flex";
import { useDragAndDrop } from "../helpers";

const Container = styled.div<{ highlight?: boolean }>`
    position: relative;
    background: ${props => props.highlight ? "lightyellow" : ""};
`;

const StyledNav = styled(Nav)`
    background: white;
`;

const StyledNavItem = styled(NavItem)<{ highlight?: number }>`
    .nav-link.active {
        background: ${props => props.highlight === 1 ? "lightyellow" : ""};
    }
`;

const NavHeader = styled(Header)`
    z-index: 1;
`;

const Preview = styled.div<{ hidden: boolean }>`
    display: ${props => props.hidden ? "none" : "block"};
    height: 100%;
    padding: 1rem;
    border: 1px solid #dee2e6;
    border-top: none;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    overflow-x: hidden;
    overflow-y: scroll;
    ${ThinScrollbar}
`;

const Editor = styled.textarea<{ hidden: boolean, highlight?: boolean }>`
    background: ${props => props.highlight ? "lightyellow" : ""};
    display: ${props => props.hidden ? "none" : "block"};
    height: 100%;
    min-height: 100%;
    resize: none;
    border-top-color: transparent;

    /* Overrides other !important style */
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;

    border-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    ${ThinScrollbar}

    :focus {
        background: ${props => props.highlight ? "lightyellow" : ""};
    }
`;

const ResizableHandler = styled.div`
    cursor: ns-resize;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 5px;
    z-index: 1000;
`;

const defaultHeight = 480;
const minHeight = 120;

export interface HtmlEditorProps {
    id?: string;
    className?: string;
    value?: string;
    onChange?: (value: string) => void;
    disabled?: boolean;
    placeholder?: string;
    highlight?: boolean;
}

export const HtmlEditor = (props: HtmlEditorProps) => {
    const [editing, setEditing] = useState(false);
    const [firstFocus, setFirstFocus] = useState(true);
    const [height, setHeight] = useState(defaultHeight);
    const [dragStartHeight, setDragStartHeight] = useState(0);
    const dnd = useDragAndDrop();

    const preview = () => setEditing(false);
    const edit = () => setEditing(true);

    const editorRef = createRef<HTMLTextAreaElement>();
    const containerRef = createRef<HTMLDivElement>();

    useEffect(() => {
        if (dnd.active) {
            setDragStartHeight(height);
        }
    }, [dnd.active]);

    useEffect(() => {
        if (dnd.active) {
            const newHeight = Math.max(minHeight, dragStartHeight - dnd.dragOffset.y);
            setHeight(newHeight);
        }
    }, [dnd.active && dnd.dragOffset, dragStartHeight]);

    useEffect(() => {
        if (editing) {
            editorRef.current!.focus();

            if (firstFocus) {
                editorRef.current!.setSelectionRange(0, 0);
                editorRef.current!.scroll({ top: 0 });
                setFirstFocus(false);
            }
        }
    }, [editing]);

    const onChange = (e: React.FormEvent<HTMLTextAreaElement>) =>
        props.onChange?.call(null, e.currentTarget.value);

    return (
        <Container
            ref={containerRef}
            className={props.className}
            highlight={props.highlight}
            style={{ height: height ?? `${height}px` }}
        >
            <ResizableHandler onMouseDown={dnd.mouseDownHandler} />
            <VerticalBox>
                <NavHeader>
                    <StyledNav tabs>
                        <StyledNavItem highlight={props.highlight ? 1 : 0}>
                            <NavLink href="#" active={!editing} onClick={preview}>
                                Preview
                            </NavLink>
                        </StyledNavItem>
                        <StyledNavItem highlight={props.highlight ? 1 : 0}>
                            <NavLink href="#" active={editing} onClick={edit}>
                                Edit
                            </NavLink>
                        </StyledNavItem>
                    </StyledNav>
                </NavHeader>
                <Preview hidden={editing} dangerouslySetInnerHTML={{ __html: props.value ?? props.placeholder ?? "" }} />
                <Content>
                    <Editor
                        ref={editorRef}
                        id={props.id}
                        className="form-control"
                        value={props.value ?? ""}
                        onChange={onChange}
                        disabled={props.disabled}
                        hidden={!editing}
                        placeholder={props.placeholder}
                        highlight={props.highlight}
                    />
                </Content>
            </VerticalBox>
        </Container>
    );
};