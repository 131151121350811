import React from "react";
import styled from "styled-components";

const Icon = styled.span`
    cursor: help;
    color: #007bff;
    font-size: 0.85em;
    vertical-align: top;
    margin-left: 2px;
    font-weight: bold;
`;

const Label = (props: { text: string, isInherited: boolean }) =>
    <React.Fragment>
        {props.text}
        {props.isInherited && <Icon title="This property is inherited from Io Application.">io</Icon>}
    </React.Fragment>;

export const ioProperty = (text: string, isInherited: boolean) =>
    <Label text={text} isInherited={isInherited} />;
