import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { Button, Form, FormGroup, Input, Row, Col } from "reactstrap";
import styled from "styled-components";
import { GoogleApplicationLocalization, GoogleSplitFile, GamestoreApplicationLocalization, ApkPlatform } from "src/shared/dtos";
import { deepEqual, setFromInput, useValidate, ValidateAsyncRef, ApkPlatforms } from "src/shared/helpers";
import { SlideListEditor, Slide, HtmlEditor, ImageSelector, EditField, LoadingButton, overriddenProperty } from "src/shared/components";
import { SplitFilesEditor } from "./SplitFilesEditor";
import { PlatformNavSelector } from "./PlatformNavSelector";
import { localizationValidationSchema, localizationMediaValidationSchema } from "./validationSchema";

interface Props {
    value: GoogleApplicationLocalization;
    initial?: GoogleApplicationLocalization;
    gamestoreLocale?: GamestoreApplicationLocalization;
    isNewApp: boolean;
    update: (value: Partial<GoogleApplicationLocalization>) => void;
    remove: () => void;
    saving: boolean;
    onSubmit: () => void;
    isLastLocalization: boolean;
}

const StyledInput = styled(Input) <{ exists: number }>`
    background: ${props => props.exists === 1 ? "lightyellow" : ""};

    :focus {
        background: ${props => props.exists === 1 ? "lightyellow" : ""};
    }
`;

const ShortDescriptionTextArea = styled(Input).attrs({ type: "textarea", rows: 8 }) <{ exists: number }>`
    background: ${props => props.exists === 1 ? "lightyellow" : ""};
    resize: none;
    flex: 1;
    :focus {
        background: ${props => props.exists === 1 ? "lightyellow" : ""};
    }
`;

type PlatformSplitFiles = Record<ApkPlatform, GoogleSplitFile[]>;

export const EditLocalization = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const setTitle = (v: string) => props.update({ title: v ? v : undefined });
    const setPublisher = (v: string) => props.update({ publisher: v ? v : undefined });
    const setShortDescription = (v: string) => props.update({ shortDescription: v ? v : undefined });
    const setDescription = (v: string) => props.update({ description: v ? v : undefined });
    const setIconUrl = (v: string) => props.update({ iconUrl: v === "" ? undefined : v });
    const setVideos = (v: string[]) => props.update({ videos: v });
    const setScreenshots = (v: string[]) => props.update({ screenshots: v });
    const setSize = (v: number) => props.update({ size: v ? v : undefined });
    const setSplitFiles = (v: GoogleSplitFile[]) => props.update({ splitFiles: v });
    const [platform, setPlatform] = useState<ApkPlatform>(ApkPlatform.X32);

    const initialSplitFiles: PlatformSplitFiles = useMemo(
        () => ApkPlatforms.reduce((acc, p) =>
            Object.assign(acc, { [p]: props.initial?.splitFiles?.filter(s => s.platform === p) ?? [] }),
            {} as PlatformSplitFiles),
        [props.initial?.splitFiles]);

    const platformSplitFiles: PlatformSplitFiles = useMemo(
        () => ApkPlatforms.reduce((acc, p) =>
            Object.assign(acc, { [p]: props.value.splitFiles?.filter(s => s.platform === p) ?? [] }),
            {} as PlatformSplitFiles),
        [props.value?.splitFiles]);

    const changedPlatforms = useMemo(() => ApkPlatforms.filter(pl =>
        initialSplitFiles[pl]?.join() !== platformSplitFiles[pl]?.join()
    ), [initialSplitFiles, platformSplitFiles]);

    const setIcon = useCallback((url: string) => {
        setIconUrl(url);
        return "";
    }, []);

    const { validate, errors, register, resetField } = useValidate({ schema: localizationValidationSchema, value: props.value });
    const { validate: validateMedia, errors: warnings } = useValidate({ schema: localizationMediaValidationSchema, value: props.value, isActive: true });

    useImperativeHandle(ref, () => ({
        validate: async () => {
            const isValid = (await validate(true)).isValid;
            validateMedia();
            return isValid;
        }
    }), [validate, validateMedia]);

    const isVideosChanged = useMemo(
        () => props.value.videos.join() !== props.initial?.videos.join(),
        [props.value.videos, props.initial?.videos]);

    const addVideo = useCallback(
        (url: string): [string, number] => {
            if (url === "") {
                return ["", 0];
            }
            const videos = props.value?.videos ?? [];
            setVideos(videos.concat(url));
            return ["", videos.length - 1];
        }, [props.value.videos]);

    const removeVideo = useCallback(
        (url: string) => {
            const videos = props.value.videos?.filter(i => i !== url) ?? [];
            setVideos(videos);
        }, [props.value.videos]);

    const isScreenshotsChanged = useMemo(
        () => props.value.screenshots.join() !== props.initial?.screenshots.join(),
        [props.value.screenshots, props.initial?.screenshots]);

    const addScreenshot = useCallback(
        (url: string): [string, number] => {
            if (url === "") {
                return ["", 0];
            }
            const screenshots = props.value?.screenshots ?? [];
            setScreenshots(screenshots.concat(url));
            return ["", screenshots.length - 1];
        }, [props.value.screenshots]);

    const removeScreenshot = useCallback(
        (url: string) => {
            const screenshots = props.value?.screenshots.filter(i => i !== url) ?? [];
            setScreenshots(screenshots);
        }, [props.value.screenshots]);

    const isSplitFilesChanged = useMemo(
        () => props.value.splitFiles?.join() !== props.initial?.splitFiles?.join(),
        [props.value.splitFiles, props.initial?.splitFiles]);

    const addSplitFiles = useCallback(
        (item: Omit<GoogleSplitFile, "platform">) => {
            const newFile: GoogleSplitFile = { ...item, platform };
            const splitFiles = props.value.splitFiles.concat(newFile);
            setSplitFiles(splitFiles);
        }, [props.value.splitFiles, platform]);

    const removeSplitFiles = useCallback(
        (item: Omit<GoogleSplitFile, "platform">) => {
            const removedFile: GoogleSplitFile = { ...item, platform };
            const splitFiles = props.value.splitFiles.filter(i => !deepEqual(i, removedFile));
            setSplitFiles(splitFiles);
        }, [props.value.splitFiles, platform]);

    const renderIcon = useCallback((url: string): Slide | null => url === "" ? null : { type: "image", url }, []);
    const renderImage = useCallback((url: string): Slide => ({ type: "image", url }), []);
    const renderVideo = useCallback((url: string): Slide => ({ type: "video", url }), []);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    const onSplitFilesFocus = useCallback(() => {
        resetField("splitFiles");
    }, [resetField]);

    return (
        <Form className="mt-3" onSubmit={save} noValidate>
            <Row>
                <EditField
                    label={overriddenProperty("Title", props.gamestoreLocale?.title !== undefined)}
                    fieldId="title"
                    isChanged={props.value.title !== props.initial?.title}
                    originalValue={props.isNewApp ? undefined : props.initial?.title}>
                    <StyledInput
                        exists={props.gamestoreLocale?.title !== undefined ? 1 : 0}
                        type="text"
                        id="title"
                        value={props.value.title ?? ""}
                        placeholder={props.value?.title}
                        onChange={setFromInput(setTitle)}
                        disabled={props.saving}
                    />
                </EditField>
                <EditField
                    label={overriddenProperty("Publisher", props.gamestoreLocale?.publisher !== undefined)}
                    fieldId="publisher"
                    isChanged={props.value.publisher !== props.initial?.publisher}
                    originalValue={props.isNewApp ? undefined : props.initial?.publisher}>
                    <StyledInput
                        exists={props.gamestoreLocale?.publisher !== undefined ? 1 : 0}
                        type="text"
                        id="publisher"
                        value={props.value.publisher ?? ""}
                        placeholder={props.value?.publisher}
                        onChange={setFromInput(setPublisher)}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    label="Size"
                    fieldId="size"
                    isChanged={props.value.size !== props.initial?.size}
                    originalValue={props.isNewApp ? undefined : props.initial?.size}>
                    <Input
                        type="number"
                        id="size"
                        value={props.value.size ?? ""}
                        placeholder={props.value?.size + ""}
                        onChange={setFromInput(setSize)}
                        disabled={props.saving} />
                </EditField>
                <Col md={6}>
                    <div className="form-group" />
                </Col>
                <EditField
                    ref={register("splitFiles")}
                    label="Split files"
                    fieldId="splitFiles"
                    isChanged={isSplitFilesChanged}
                    errors={errors?.splitFiles}>
                    <PlatformNavSelector
                        currentPlatform={platform}
                        changedPlatforms={changedPlatforms}
                        setPlatform={setPlatform}
                        style={{ borderBottom: 0 }}
                    />
                    <SplitFilesEditor
                        listContainerId="splitFiles"
                        mainInputId="splitFiles"
                        items={platformSplitFiles[platform]}
                        add={addSplitFiles}
                        remove={removeSplitFiles}
                        disabled={props.saving}
                        invalid={(errors?.splitFiles?.length ?? 0) > 0}
                        onFocus={onSplitFilesFocus}
                    />
                </EditField>
                <EditField
                    label={overriddenProperty("Short Description", props.gamestoreLocale?.shortDescription !== undefined)}
                    fieldId="shortDescription"
                    isChanged={props.value.shortDescription !== props.initial?.shortDescription}
                    originalValue={props.isNewApp ? undefined : props.initial?.shortDescription}>
                    <ShortDescriptionTextArea
                        exists={props.gamestoreLocale?.shortDescription !== undefined ? 1 : 0}
                        id="shortDescription"
                        placeholder={props.value?.shortDescription}
                        value={props.value.shortDescription ?? ""}
                        onChange={setFromInput(setShortDescription)}
                        disabled={props.saving}
                    />
                </EditField>
                <EditField
                    label={overriddenProperty("Description", props.gamestoreLocale?.description !== undefined)}
                    fieldId="description"
                    isChanged={props.value.description !== props.initial?.description}>
                    <HtmlEditor
                        highlight={props.gamestoreLocale?.description !== undefined}
                        id="description"
                        value={props.value.description}
                        onChange={setDescription}
                        disabled={props.saving}
                        placeholder={props.value?.description}
                    />
                </EditField>
                <EditField
                    ref={register("iconUrl")}
                    label={overriddenProperty("Icon URL", props.gamestoreLocale?.iconUrl !== undefined)}
                    fieldId="iconUrl"
                    isChanged={props.value.iconUrl !== props.initial?.iconUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.iconUrl}
                    warnings={warnings?.iconUrl}
                    errors={errors?.iconUrl}>
                    <ImageSelector
                        highlight={props.gamestoreLocale?.iconUrl !== undefined}
                        setInputId="iconUrl"
                        value={props.value?.iconUrl ?? ""}
                        set={setIcon}
                        render={renderIcon}
                        imageDimensions={{ width: "12rem", height: "25.5rem" }}
                        invalid={(errors?.iconUrl?.length ?? 0) > 0}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("videos")}
                    label={overriddenProperty("Videos", (props.gamestoreLocale?.videos.length ?? 0) !== 0)}
                    fieldId="videos"
                    isChanged={isVideosChanged}
                    warnings={warnings?.videos}
                    errors={errors?.videos}>
                    <SlideListEditor
                        highlight={(props.gamestoreLocale?.videos.length ?? 0) !== 0}
                        addInputId="videos"
                        values={props.value.videos.length === 0
                            ? ([])
                            : props.value.videos}
                        add={addVideo}
                        remove={removeVideo}
                        render={renderVideo}
                        placeholder="Add Youtube video link"
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("screenshots")}
                    label={overriddenProperty("Screenshots", (props.gamestoreLocale?.screenshots.length ?? 0) > 0)}
                    fieldId="screenshots"
                    isChanged={isScreenshotsChanged}
                    warnings={warnings?.screenshots}
                    errors={errors?.screenshots}>
                    <SlideListEditor
                        highlight={(props.gamestoreLocale?.screenshots.length ?? 0) > 0}
                        addInputId="screenshots"
                        values={props.value.screenshots.length === 0
                            ? ([])
                            : props.value.screenshots}
                        add={addScreenshot}
                        remove={removeScreenshot}
                        render={renderImage}
                        invalid={(errors?.screenshots?.length ?? 0) > 0}
                        disabled={props.saving} />
                </EditField>
            </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <FormGroup>
                        <Button
                            type="button"
                            color="danger"
                            className="mr-2"
                            title={props.isLastLocalization ? "Unable to remove last localization." : undefined}
                            disabled={props.isLastLocalization || props.saving}
                            onClick={props.remove}>
                            Remove localization
                        </Button>
                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={props.saving}
                            loadingText="Saving..."
                            position="end">
                            Save application
                        </LoadingButton>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
});
