import styled from "styled-components";
import { ThemedProps } from "../../../types";

export const ProgressBarTitle = styled.span`
    font-family: Arial;
    font-size: 12px;
    color: ${(p: ThemedProps) => p.theme.ProgressAppTitleTextColor};
`;

export const ProgressBar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 14px;
    border: 2px solid;
    border-color: ${(p: ThemedProps) => p.theme.ProgressBarBorderColor};
    height: 28px;
`;

export const ProgressLine = styled.div`
    border-radius: 14px 0 0 14px;
    height: 100%;
    width: 25%;
    position: absolute;
    left: 0;
    background-color: ${(p: ThemedProps) => p.theme.ProgressBarColor};
`;

export const ProgressBarText = styled.span`
    font-family: Arial;
    font-size: 12px;
    color: ${(p: ThemedProps) => p.theme.ProgressBarTextColor};
`;

export const DownloadInfoBlock = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
`;

export const DownloadSpeedText = styled.span`
    font-family: Arial;
    font-size: 12px;
    color: ${(p: ThemedProps) => p.theme.ProgressDownloadSpeedTextColor};
`;

export const DownloadTimeText = styled.span`
    font-family: Arial;
    font-size: 10px;
    color: ${(p: ThemedProps) => p.theme.ProgressDownloadSpeedTextColor};
`;