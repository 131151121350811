import React, { useMemo } from "react";
import { Form, Input, Button, FormGroup, Label, Row, Col } from "reactstrap";
import { AdUnitTriggerType, AdSchedulingDetails, AdUnitTrigger, AdUnitTypes } from "src/shared/dtos";
import { SelectOption, setFromInput, splitCamelCase } from "src/shared/helpers";
import { FormLabel, Select } from "src/shared/components";
import { EditField } from "./EditField";

type TriggerTypeSelectOption = SelectOption<AdUnitTriggerType | undefined>;

const mapTriggerTypeSelectOption = (triggerType: AdUnitTriggerType) => {
    const value = triggerType;
    const label = splitCamelCase(triggerType);
    return { value, label } as TriggerTypeSelectOption;
};

const makeEmptyAdSchedulingDetails = (): AdSchedulingDetails => ({
    displayEveryMinutes: 0
});

interface Props {
    value: AdUnitTrigger;
    initial?: AdUnitTrigger;
    isNewAdUnitTrigger: boolean;
    adUnitType: AdUnitTypes;
    update: React.Dispatch<Partial<AdUnitTrigger> | undefined>;
    saving: boolean;
    onSubmit: () => void;
}

export const EditForm = (props: Props) => {
    const setType = (v: TriggerTypeSelectOption) => props.update({ triggerType: v.value ? v.value : undefined });
    const setIsDisabled = (v: boolean) => props.update({ isDisabled: !v });
    const setScheduling = (v: number) => {
        const schedulingDetails = props.value.adSchedulingDetails ?? makeEmptyAdSchedulingDetails();
        schedulingDetails.displayEveryMinutes = v;
        props.update({ adSchedulingDetails: schedulingDetails });
    };

    const triggerTypeOptions = useMemo(() => {
        switch (props.adUnitType) {
            case AdUnitTypes.BannerAction:
                return [mapTriggerTypeSelectOption(AdUnitTriggerType.GameInstallation),
                    mapTriggerTypeSelectOption(AdUnitTriggerType.GameStart),
                    mapTriggerTypeSelectOption(AdUnitTriggerType.OnSchedule)];
            case AdUnitTypes.BrowserAction:
                return [mapTriggerTypeSelectOption(AdUnitTriggerType.GameStart),
                    mapTriggerTypeSelectOption(AdUnitTriggerType.GameInstallation),
                    mapTriggerTypeSelectOption(AdUnitTriggerType.GameExit),
                    mapTriggerTypeSelectOption(AdUnitTriggerType.OnSchedule)];
            case AdUnitTypes.RichMediaOverlay:
                return [mapTriggerTypeSelectOption(AdUnitTriggerType.AndroidAppStarted),
                    mapTriggerTypeSelectOption(AdUnitTriggerType.ApmDropped)];
            case AdUnitTypes.DynamicWeb:
                return [mapTriggerTypeSelectOption(AdUnitTriggerType.GameInstallation),
                    mapTriggerTypeSelectOption(AdUnitTriggerType.GameStart),
                    mapTriggerTypeSelectOption(AdUnitTriggerType.OnSchedule)];
            default: return [];
        }
    }, [props.adUnitType]);
    const selectedTriggerTypeOption = useMemo(
        () => triggerTypeOptions.find(o => o.value === props.value.triggerType),
        [props.value.triggerType]);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <Form onSubmit={save} noValidate>
            <React.Fragment>
                <Row>
                    <Col sm={8}>
                        <EditField>
                            <FormGroup check>
                                <Label for="isEnabled" check>
                                    <Input
                                        type="checkbox"
                                        id="isEnabled"
                                        name="isEnabled"
                                        checked={!props.value.isDisabled}
                                        onChange={setFromInput(setIsDisabled)}
                                        disabled={props.saving}
                                    />{" "}
                                    <FormLabel
                                        isChanged={props.value.isDisabled !== props.initial?.isDisabled}
                                        originalValue={props.isNewAdUnitTrigger ? undefined : props.initial?.isDisabled}>
                                        Enabled
                                    </FormLabel>
                                </Label>
                            </FormGroup>
                        </EditField>
                    </Col>
                    <Col sm={4} className="text-right">
                        <Button
                            type="submit"
                            color="primary"
                            className="ml-2 mb-2"
                            disabled={props.saving}>
                            {props.isNewAdUnitTrigger ? "Add" : "Save"} Unit Trigger
                            </Button>
                    </Col>
                </Row>
            </React.Fragment>
            <EditField
                fieldId="type"
                label="Type"
                isChanged={props.value.triggerType !== props.initial?.triggerType}
            >
                <Select
                    id="type"
                    placeholder="Type"
                    value={selectedTriggerTypeOption}
                    onChange={setType}
                    options={triggerTypeOptions}
                    isMulti={false}
                    isSearchable={false}
                    isDisabled={props.saving}
                />
            </EditField>
            {props.value.triggerType === AdUnitTriggerType.OnSchedule && (
                <EditField
                    label="Display every # minute(s)"
                    fieldId="scheduling"
                    isChanged={props.value.adSchedulingDetails?.displayEveryMinutes !== props.initial?.adSchedulingDetails?.displayEveryMinutes}
                >
                    <Input
                        type="number"
                        id="scheduling"
                        min={1}
                        step={1}
                        value={props.value.adSchedulingDetails?.displayEveryMinutes ?? ""}
                        onChange={setFromInput(setScheduling)}
                        disabled={props.saving}
                    />
                </EditField>
            )}
        </Form>
    );
};