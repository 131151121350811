import React, { useState } from "react";
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import styled from "styled-components";
import {
    ListEditor,
    ListEditorProps,
    ListEditorItemRendererProps,
    ListEditorAddControlRendererProps
} from "src/shared/components";
import { GoogleSplitFile } from "src/shared/dtos";
import { setFromInput } from "src/shared/helpers";

const ItemContainer = styled.a`
    position: relative;
    display: block;
    width: 100%;
    padding-right: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const itemRenderer = (props: ListEditorItemRendererProps<GoogleSplitFile>) =>
    <ItemContainer
        key={`${props.item.name}_${props.item.fileDownloadUrl}`}
        href={props.item.fileDownloadUrl}
        target="_blank"
        title={props.item.fileDownloadUrl}>

        <b>{props.item.name}</b>:{" "}
        {props.item.fileDownloadUrl}
        {props.removeButton(props)}
    </ItemContainer>;

const NameInput = styled(Input)`
    max-width: 12rem;
`;

const addControlRenderer = (props: ListEditorAddControlRendererProps<Omit<GoogleSplitFile, "platform">>) => {
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const add = () => {
        if (url.trim() === "") {
            return;
        }
        props.add({ name, fileDownloadUrl: url });
        setName("");
        setUrl("");
    };

    return (
        <div>
            <InputGroup className="mt-2">
                <NameInput
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={setFromInput(setName)}
                    disabled={props.disabled} />
                <Input
                    type="url"
                    placeholder="URL"
                    value={url}
                    onChange={setFromInput(setUrl)}
                    disabled={props.disabled} />
                <InputGroupAddon addonType="append">
                    <Button
                        type="button"
                        color="primary"
                        disabled={props.disabled || name === "" || url === "" || url.includes(" ")}
                        onClick={add}>
                        Add
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        </div>
    );
};

export type SplitFilesEditorProps = Omit<ListEditorProps<Omit<GoogleSplitFile, "platform">>, "itemRenderer" | "addControlRenderer">;

export const SplitFilesEditor = (props: SplitFilesEditorProps) => {
    return <ListEditor itemRenderer={itemRenderer} addControlRenderer={addControlRenderer} {...props} />;
};