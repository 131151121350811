import {
    createContext,
    Dispatch,
    SetStateAction,
} from "react";

export type ConfirmModalContent = {
    body: JSX.Element | string;
    title: string;
    noBtnText: string;
    yesBtnText: string;
};

export type ModalSize = "xs" | "md" | "sm" | "lg";

export const initialConfirmModalContent: ConfirmModalContent = {
    body: "",
    title: "Confirm Action",
    noBtnText: "No",
    yesBtnText: "Yes"
};

interface IConfirmContext {
    state: {
        show: boolean;
        size: ModalSize;
        content: ConfirmModalContent;
    };
    actions: {
        setShow: Dispatch<SetStateAction<boolean>>;
        setSize: Dispatch<SetStateAction<ModalSize>>;
        setContent: Dispatch<SetStateAction<ConfirmModalContent>>;
    };
}

const initialState: IConfirmContext = {
    state: {
        show: false,
        size: "md",
        content: initialConfirmModalContent
    },
    actions: {
        setShow: () => { return; },
        setSize: () => { return; },
        setContent: () => { return; },
    }
};

export const ConfirmContext = createContext<IConfirmContext>(initialState);