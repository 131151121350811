import React, { useEffect, useState, useReducer, useMemo, useCallback, useRef, } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col } from "reactstrap";
import { forkJoin, defer } from "rxjs";
import {
    GamestoreIoApplication,
    GamestoreIoApplicationLocalization,
    Category,
    IoApplication,
    Tag
} from "src/shared/dtos";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    LeavingViewProtector,
    Loader,
    LocalizationSelector,
    DocumentTitle,
    RouteLink,
    RouteUserProps,
    EditError,
    EditErrorOptions,
    notifySuccess,
    notifyError,
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { availableLanguages, getFormErrorsAsStringArray, getLangName, getObjectFieldsArray, makeEmptyGamestoreIoApp, queryString, structuredClone, useSubscription, ValidateAsyncRef, validateForm } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { EditForm } from "./EditForm";
import { EditInfo } from "./EditInfo";
import { EditLocalization, applyIoLocaleFallbackData } from "./EditLocalization";
import { applicationReducer, applicationReducerDefault } from "./applicationReducer";
import { localizationReducer, makeLocalizationReducerDefault } from "./localizationReducer";
import api from "../api";
import { CategoryType } from "src/shared/CategoryType";
import { useConfirm } from "src/shared/helpers/useConfirm";
import { locSourcesValidationSchema, makeLocalizationValidationSchema } from "./validationSchema";
import { ConfirmFailedLocalizationSources, LocalizationsFailedFields } from "src/shared/components/confirmWindow/samples/ConfirmFailedSources";

const getDefaultLocale = (locs: GamestoreIoApplicationLocalization[], lang: string): GamestoreIoApplicationLocalization => {
    const locale = locs.find(l => l.language === lang) || locs[0];
    if (locale === undefined) {
        throw new Error(`GamestoreIoApplication has no localizations.`);
    }

    return locale;
};

type Props = RouteComponentProps<{ appId: string }> & RouteUserProps;

export const EditView = withRouter(({ match, location, history, user }: Props) => {
    const applicationId = match.params.appId;
    const isNewApp = useMemo(() => applicationId === undefined, [applicationId]);
    const mainFormRef = useRef<ValidateAsyncRef>(null);
    const localizationFormRef = useRef<ValidateAsyncRef>(null);
    const queryParams = queryString(location.search);
    const ioAppId = queryParams.id;
    const defaultLang = isNewApp ? "en" : queryParams.lang || "en";

    const [isNewAppSaved, setIsNewAppSaved] = useState(!isNewApp);
    const [categories, setCategories] = useState<Category[] | undefined>([]);
    const [tags, setTags] = useState<Tag[] | undefined>([]);
    const [ioApps, setIoApps] = useState<IoApplication[]>([]);
    const [error, setError] = useState<EditErrorOptions | undefined>();
    const [saving, setSaving] = useState(false);
    const [app, changeApp] = useReducer(applicationReducer, applicationReducerDefault);
    const [loc, changeLocs] = useReducer(localizationReducer, makeLocalizationReducerDefault(defaultLang));
    const { confirm } = useConfirm();
    const backToList = useCallback(() => history.push(routes.gamestoreIoApplications.url()), []);

    const setApp = useCallback((application?: GamestoreIoApplication) => {
        if (application !== undefined && !application.categories.some(c => c.parentId === CategoryType.Io)) {
            application.categories.unshift(application.ioApplication.category);
            application.categoryIds = application.categories.map(c => c.id);
        }
        const value = application ?? makeEmptyGamestoreIoApp(ioAppId);

        updateAppAndLocalizationsState(value);
    }, [ioAppId]);

    const updateAppAndLocalizationsState = useSubscription((value: GamestoreIoApplication) => forkJoin([
        defer(() => structuredClone(value)),
        defer(() => structuredClone(value.localizations)),
        defer(() => structuredClone(value.localizations))])
        .subscribe(clones => {
            changeApp({ kind: "set", value, initialValue: clones[0] });
            changeLocs({
                kind: "set",
                app: value,
                resetCurrent: true,
                localizations: clones[1],
                initialLocalizations: clones[2]
            });

            // Check for an unknown localization languages.
            const allLangs = value.localizations.map(l => l.language);
            const unknownLangs = allLangs
                .filter(l => availableLanguages.find(al => al.value === l) === undefined)
                .join();
            if (unknownLangs.length > 0) {
                console.warn(
                    `GamestoreIoApplication '${value.id}' contains unknown localization languages: '${unknownLangs}'.`);
            }
        }), []);

    const loadIoApps = useSubscription(() => defer(() => api.getIoAppsWithoutGamestoreApplication()).subscribe({
        next: result => {
            setIoApps(result);
            setApp(undefined);
        },
        error: () => setError({
            text: "Unable to load io applications.",
            actionText: "Back to list",
            action: backToList
        })
    }), [api.getIoAppsWithoutGamestoreApplication, setApp, backToList]);

    const loadApplication = useSubscription((appId: string) => defer(() => api.get(appId)).subscribe({
        next: result => {
            if (result?.localizations === undefined) {
                setError({
                    text: "Unable to load application localizations.",
                    actionText: "Back to list",
                    action: backToList
                });
                return;
            }
            setApp(result);
        },
        error: () => setError({
            text: "Unable to load application.",
            actionText: "Back to list",
            action: backToList
        })
    }), [api.get, setApp, backToList]);

    const loadCategories = useSubscription(() => defer(() => api.getCategories()).subscribe(results => {
        setCategories(results);
    }), [api.getCategories]);

    const loadTags = useSubscription(() => defer(() => api.getTags()).subscribe(results => {
        setTags(results);
    }), [api.getTags]);

    useEffect(() => {
        loadCategories();
        loadTags();
        if (isNewApp) {
            loadIoApps();
            return;
        }
        loadApplication(applicationId);
    }, [isNewApp, loadIoApps, loadApplication, applicationId, loadTags]);

    useEffect(() => {
        // Go to edit view after successful save of the new entity.
        // It is implemented this way to let leaving view protector know, that there is no changes.
        if (isNewApp && isNewAppSaved && error === undefined) {
            history.push(routes.editGamestoreIoApplication.url({ appId: app.current!.id, language: defaultLang }));
        }
    }, [isNewApp, isNewAppSaved, app.current]);

    const saveApplication = useSubscription((data: GamestoreIoApplication) => defer(() => isNewApp
        ? api.create(data)
        : api.update(data)).subscribe({
            next: result => {
                notifySuccess(`Gamestore Application ${isNewApp ? "added" : "saved"} successfully.`);
                setApp(result);

                if (isNewApp) {
                    setIsNewAppSaved(true);
                }
            },
            error: () => {
                setSaving(false);
                setError({
                    text: "Unable to save application.",
                    actionText: "Continue",
                    action: () => setError(undefined)
                });
            },
            complete: () => setSaving(false)
        }), [isNewApp, api.create, api.update, setApp]);

    const validateLocalizations = useCallback(async (): Promise<string[]> => {
        const localizationValidationSchema = makeLocalizationValidationSchema(app.current!.isGraphicsCustomized);
        return (await Promise.all(
            loc.all.map(async l => {
                const ioLocale = loc.ioLocs.find(ioLoc => ioLoc.language === l.language);
                l = applyIoLocaleFallbackData(l, ioLocale);
                const locValidationResult = await validateForm(l, localizationValidationSchema);
                const locErrors = getFormErrorsAsStringArray(locValidationResult.errors);
                return locErrors.map(err => `${getLangName(l.language)} Localization: ${err}`);
            })
        )).flat();
    }, [app.current, loc.all, loc.ioLocs]);

    const validateMedia = useCallback(async (): Promise<boolean> => {
        const failedMediaSources = (await Promise.all(
            loc.all.map(async l => {
                const ioLocale = loc.ioLocs.find(ioLoc => ioLoc.language === l.language);
                l = applyIoLocaleFallbackData(l, ioLocale);
                const locValidationResult = await validateForm(l, locSourcesValidationSchema);
                if (locValidationResult.isValid) {
                    return null;
                }
                const failedFields = getObjectFieldsArray(locValidationResult.errors);
                return { lang: getLangName(l.language) ?? l.language, fields: failedFields } as LocalizationsFailedFields;
            })
        )).filter(v => v !== null) as LocalizationsFailedFields[];

        return failedMediaSources.length === 0
        ? true
        : confirm({
            body: <ConfirmFailedLocalizationSources failedFields={failedMediaSources} />,
            title: "Confirm saving application",
            size: "lg"
        });
    }, [loc.all, loc.ioLocs]);

    const validateRating = useCallback(async (): Promise<boolean> => {
        const rating = app.current?.rating ?? app.current?.ioApplication.rating ?? 0;
        return rating > 0 ? true : confirm({
            body: "Do you want to save application with rating 0?",
            title: "Confirm saving application"
        });
    }, [app.current?.rating, app.current?.ioApplication.rating]);

    const validateData = useCallback(async (): Promise<boolean> => {
        if (!(await mainFormRef.current!.validate())) {
            return false;
        }

        if (localizationFormRef.current && !(await localizationFormRef.current.validate())) {
            return false;
        }

        const localizationsErrors = await validateLocalizations();
        if (localizationsErrors.length > 0) {
            localizationsErrors.forEach(notifyError);
            return false;
        }

        if (!(await validateRating()) || !(await validateMedia())){
            return false;
        }

        return true;
    }, [mainFormRef.current, localizationFormRef.current, validateLocalizations, validateMedia, validateRating]);

    const save = useCallback(
        async () => {
            if (saving || app.current === undefined || mainFormRef.current === undefined) {
                return;
            }

            if (loc.all.length === 0) {
                notifyError("Gamestore Io Application must have at least one localization.");
                return;
            }

            setSaving(true);
            if (!app.current.isDisabled && !(await validateData())) {
                setSaving(false);
                return;
            }

            const application: GamestoreIoApplication = {
                ...app.current,
                localizations: loc.all,
            };
            saveApplication(application);
        }, [app, app.current, loc, loc.all, saving, isNewApp, saveApplication, mainFormRef.current, localizationFormRef.current, confirm]);

    const updateApp = useCallback(
        (value: Partial<GamestoreIoApplication>) => changeApp({ kind: "update", value }), []);
    const setIoApp = useCallback(
        (value: IoApplication) => {
            changeApp({ kind: "set-io-app", value });
            changeLocs({ kind: "set-io-app", value });
            changeLocs({ kind: "select", lang: loc.current?.language ?? "en" });
        }, []);
    const updateLocalization = useCallback(
        (value: Partial<GamestoreIoApplicationLocalization>) => changeLocs({ kind: "update", value }), []);
    const removeLocalization = useCallback(() => changeLocs({ kind: "remove" }), []);

    const changed = useMemo(
        () => app.changed || loc.changedLanguages.length > 0 || loc.removedLocalizations.length > 0, [
        app.changed,
        loc.changedLanguages,
        loc.changedLanguages.length,
        loc.removedLocalizations,
        loc.removedLocalizations.length]);

    const title = useMemo(
        () => {
            const appTitle = isNewApp
                ? "[new application]"
                : (loc.current !== undefined
                    ? getDefaultLocale(loc.all, defaultLang).title
                    : "Loading...");
            return `Gamestore Io Application - ${appTitle}`;
        },
        [loc.current, loc.all, defaultLang]);

    const setLanguage = (lang: string) => changeLocs({ kind: "select", lang });
    const addLanguage = (lang: string) => changeLocs({ kind: "add", lang });

    return (
        <VerticalBox>
            <DocumentTitle title={title} />
            <LeavingViewProtector showConfirmation={changed && (isNewApp ? !isNewAppSaved : true)} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.gamestoreIoApplications}>
                                Gamestore Io Applications
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {isNewApp ? "[new application]" : match.params.appId}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    {error && <EditError error={error} />}
                    {!error && app.current === undefined || loc.current === undefined || categories === undefined || tags === undefined &&
                        <Loader />}
                    {!error && categories !== undefined && tags !== undefined && app.current !== undefined && loc.current !== undefined &&
                        <React.Fragment>
                            <Row>
                                <Col sm={5} xs={12}>
                                    <EditInfo
                                        app={app.current}
                                        locale={loc.current}
                                        ioLocale={loc.ioLocale}
                                        isNewApp={isNewApp} />
                                </Col>
                                <Col sm={7}>
                                    <EditForm
                                        ref={mainFormRef}
                                        value={app.current}
                                        initial={app.initial}
                                        isNewApp={isNewApp}
                                        categories={categories}
                                        tags={tags}
                                        ioApplications={ioApps}
                                        update={updateApp}
                                        setIoApplication={setIoApp}
                                        saving={saving}
                                        onSubmit={save}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <LocalizationSelector
                                        currentLanguage={loc.current.language ?? defaultLang}
                                        languages={loc.all.map(l => l.language)}
                                        removedLanguages={loc.removedLocalizations.map(l => l.language)}
                                        changedLanguages={loc.changedLanguages}
                                        setLanguage={setLanguage}
                                        addLanguage={addLanguage}
                                    />
                                    {loc.current &&
                                        <EditLocalization
                                            ref={localizationFormRef}
                                            value={loc.current}
                                            initial={loc.initial}
                                            ioLocale={loc.ioLocale}
                                            isNewApp={isNewApp}
                                            update={updateLocalization}
                                            remove={removeLocalization}
                                            saving={saving}
                                            onSubmit={save}
                                            isGraphicsCustomized={app.current.isGraphicsCustomized}
                                            isAppDisabled={app.current.isDisabled}
                                            isLastLocalization={loc.all.length < 2} />}
                                </Col>
                            </Row>
                        </React.Fragment>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});