import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useCallback } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BreadcrumbItem, Button } from "reactstrap";
import { defer } from "rxjs";
import styled from "styled-components";
import {
    Breadcrumb,
    DocumentTitle,
    Loader,
    RouteLink,
    RouteUserProps,
    ToolBox,
    ToolBoxLeftContainer,
    ToolBoxRightContainer,
    notifyError,
    notifySuccess
} from "src/shared/components";
import { Tag } from "src/shared/dtos";
import { useSubscription } from "src/shared/helpers";
import { Content, VerticalBox, Header } from "src/shared/components/flex";
import { routes } from "src/shared/routes";
import api, { UpdateTagOrderRequest } from "../api";
import { TagItem } from "./TagItem";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";

const FullHeightDiv = styled.div`
    position: relative;
    height: 100%;
`;

const TagsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 16px;
    padding: 12px;
`;

interface TagState {
    prev: Tag[];
    current: Tag[];
}

interface ReorderTagsArgs {
    prevOrder: number;
    newOrder: number;
    tags: Tag[];
}

const reorderTags = ({ tags, newOrder, prevOrder }: ReorderTagsArgs): Tag[] => {
    if (newOrder === prevOrder) {
        return tags;
    }
    const targetTag = tags[prevOrder];
    tags = tags.filter((_, i) => i !== prevOrder);

    if (newOrder > prevOrder) {
        for (const tag of tags) {
            if (tag.order > prevOrder && tag.order <= newOrder) {
                tag.order--;
            }
        }
    } else {
        for (const tag of tags) {
            if (tag.order < prevOrder && tag.order >= newOrder) {
                tag.order++;
            }
        }
    }
    tags.splice(newOrder, 0, { ...targetTag, order: newOrder });
    return tags;
};

export const ListView = withRouter(({ user }: RouteComponentProps<{}> & RouteUserProps) => {
    const [tags, setTags] = useState<TagState>({ prev: [], current: [] });
    const [loading, setLoading] = useState<boolean>(false);

    const loadTags = useSubscription(() => {
        setLoading(true);
        return defer(() => api.list()).subscribe({
            next: v => setTags({ prev: v, current: v }),
            complete: () => setLoading(false)
        });
    }, []);

    useEffect(() => {
        loadTags();
    }, []);

    const updateTagOrder = useSubscription((req: UpdateTagOrderRequest) => {
        setLoading(true);
        return defer(() => api.updateTagOrder(req)).subscribe({
            next: () => {
                setTags(v => ({...v, prev: v.current}));
                notifySuccess("Tags order updated successfully");
                setLoading(false);
            },
            error: () => {
                setTags(v => ({...v, current: v.prev}));
                notifyError("Failed to update tags order");
                setLoading(false);
            }
        });
    }, []);

    const onDragEnd = useCallback(({ destination, source }: DropResult) => {
        if (!destination || destination.index === source.index) {
            return;
        }
        setTags(v => ({
            ...v,
            current: reorderTags({
                newOrder: destination.index,
                prevOrder: source.index,
                tags: v.current
            })
        }));
        const targetTag = tags.current[source.index];
        updateTagOrder({ tagId: targetTag.id, order: destination.index });
    }, [tags]);

    return (
        <VerticalBox>
            <DocumentTitle title="Categories" />
            <Header>
                <ToolBox>
                    <ToolBoxLeftContainer>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <RouteLink user={user} to={routes.home}>
                                    Home
                                </RouteLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                Tags
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </ToolBoxLeftContainer>
                    <ToolBoxRightContainer>
                        <RouteLink user={user} to={routes.addTag} button className="mr-2">
                            Add Tag
                        </RouteLink>
                        <Button onClick={loadTags}>
                            <FontAwesomeIcon icon="sync" />
                        </Button>
                    </ToolBoxRightContainer>
                </ToolBox>
            </Header>
            <Content style={{ overflow: "hidden" }}>
                {loading && <Loader />}
                <FullHeightDiv className="row">
                    <FullHeightDiv className="offset-sm-1 col-sm-11 offset-md-2 col-md-10 offset-lg-3 col-lg-9 offset-xl-3 col-xl-9">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="tagsList">
                                {(p) => (
                                    <TagsContainer {...p.droppableProps} ref={p.innerRef} >
                                        {tags.current.map((tag, i) => (
                                            <TagItem key={tag.id} tag={tag} idx={i} user={user} />
                                        ))}
                                        {p.placeholder}
                                    </TagsContainer>
                                )}
                            </Droppable>

                        </DragDropContext>
                    </FullHeightDiv>
                </FullHeightDiv>
            </Content>
        </VerticalBox>
    );
});