import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Col, Table } from "reactstrap";
import { defer } from "rxjs";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    DocumentTitle,
    RouteLink,
    RouteUserProps,
    Loader
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { GamingMinutesStatistics } from "src/shared/dtos";
import { routes } from "src/shared/routes";
import styled from "styled-components";
import api from "../api";

const StyledHighcharts = styled.div`
    text.highcharts-credits {
        display:none !important;
    }
`;

export const DistributionView = withRouter(({ match, user }: RouteComponentProps<{ id: string, movingAvgDays: string }> & RouteUserProps) => {
    const [dataCategories, setDataCategories] = useState<string[]>();
    const id = useMemo(() => parseInt(match.params.id, 10), [match.params.id]);
    const movingAvgDays = useMemo(() => parseInt(match.params.movingAvgDays, 10), [match.params.movingAvgDays]);
    const [gamingStatistics, setGamingStatistics] = useState<GamingMinutesStatistics>();

    useEffect(() => {
        const subscription = defer(() => api.getById(id)).subscribe(setGamingStatistics);
        return () => subscription.unsubscribe();
    }, [id]);

    const dateRenderer = (value: string) => {
        const date = new Date(value);
        return date.toLocaleDateString();
    };

    const decimalRenderer = (value: number) => {
        return value.toLocaleString(
            undefined,
            { maximumFractionDigits: 3, minimumFractionDigits: 3 }
        );
    };
    useEffect(() => {
        if (gamingStatistics === undefined) {
            return;
        }
        const categories = gamingStatistics.averageDistribution?.data?.map(r => r.start + "-" + r.end);
        setDataCategories(categories);
    }, [gamingStatistics]);

    const series = useMemo(() => {
        if (gamingStatistics === undefined) {
            return undefined;
        }

        const countSeries = {
            name: "Count", type: "column", data: gamingStatistics.averageDistribution?.data?.map(r => r.count)
        };
        return countSeries;
    }, [gamingStatistics]);

    const chartOptions = {
        title: {
            text: "<strong>Application - </strong> '" + gamingStatistics?.applicationId + "'"
        },
        plotOptions: {
            column: {
                pointPadding: 0,
                borderWidth: 0,
                groupPadding: 0,
                shadow: false
            }
        },
        yAxis: {
            title: {
                text: "Count",
            }
        },
        xAxis: {
            title: {
                text: "Data",
            },
            categories: dataCategories,

        },
        series
    };

    return (
        <React.Fragment>
            {gamingStatistics === undefined && <Loader />}
            {gamingStatistics !== undefined &&
                <VerticalBox>
                    <DocumentTitle title={`Gaming Minutes Statistics Distribution - for '${gamingStatistics?.applicationId}'`} />
                    <Header>
                        <ToolBox>
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <RouteLink user={user} to={routes.home}>
                                        Home
                                    </RouteLink>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <RouteLink user={user} to={routes.gamingStatistics}>
                                        Gaming Minutes Statistics
                                    </RouteLink>
                                </BreadcrumbItem>
                                <BreadcrumbItem>
                                    <RouteLink
                                        user={user}
                                        to={{
                                            route: routes.viewGamingStatistic, args: {
                                                appId: gamingStatistics.applicationId!,
                                                version: gamingStatistics.productVersion!,
                                                movingAvgDays,
                                                endDate: gamingStatistics.date.slice(0, 10)
                                            }
                                        }}
                                        className="d-inline">
                                        AppId - '{gamingStatistics?.applicationId}'
                                    </RouteLink>
                                </BreadcrumbItem>
                                <BreadcrumbItem active>
                                    Distribution for date {gamingStatistics !== undefined ? dateRenderer(gamingStatistics.date) : ""}
                                </BreadcrumbItem>
                            </Breadcrumb>
                        </ToolBox>
                    </Header>
                    <Content>
                        <ContentBox>
                            <Col sm={{ size: 8, offset: 2 }}>
                                {gamingStatistics !== undefined &&
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td><strong>Date: </strong>{dateRenderer(gamingStatistics.date)}</td>
                                                <td><strong>Avg (min): </strong>{decimalRenderer(gamingStatistics.averageMinutes)}</td>
                                                <td><strong>STD (min): </strong>{decimalRenderer(gamingStatistics.standardDeviationMinutes)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>}
                                <StyledHighcharts>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions}
                                    />
                                </StyledHighcharts>
                            </Col>
                        </ContentBox>
                    </Content>
                </VerticalBox>}
        </React.Fragment>
    );
});
