import React, { FC, Dispatch } from "react";
import { ChartTypes, ChartWidgetDataTypes, ChartWidgetUnit } from "src/shared/dtos";
import { GamingMinutesByIdChartWidget } from "./GamingMinutesByIdChartWidget";
import { GamingMinutesByDateChartWidget } from "./GamingMinutesByDateChartWidget";

export interface ChartWidgetProps {
    chartWidget: ChartWidgetUnit;
    resizeTrigger: boolean;
    setLoading: Dispatch<boolean>;
}

export const ChartWidget: FC<ChartWidgetProps> = (props) => {
    return (
        <>
            {props.chartWidget.dataType === ChartWidgetDataTypes.GamingMinutesData &&
                props.chartWidget.chartType === ChartTypes.Pie &&
                <GamingMinutesByIdChartWidget {...props} />}
            {props.chartWidget.dataType === ChartWidgetDataTypes.GamingMinutesData &&
                <GamingMinutesByDateChartWidget {...props} />}
        </>
    );
};