import { GoogleApplication, GoogleApkDetails, GoogleAdditionalFile, ApkPlatform } from "src/shared/dtos";
import { ApkPlatforms, deepClone, deepEqual, makeEmptyGoogleApkDetails } from "src/shared/helpers";

export type PlatformDetailsItem = GoogleApkDetails & {
    additionalFiles: GoogleAdditionalFile[];
};

export type PlatformDetailsState = {
    current?: PlatformDetailsItem,
    initial?: PlatformDetailsItem,
    all: PlatformDetailsItem[],
    allInitial: PlatformDetailsItem[],
    changedPlatforms: ApkPlatform[],
};

const makeEmptyDetails = (platform: ApkPlatform): PlatformDetailsItem => ({
    ...makeEmptyGoogleApkDetails(platform),
    additionalFiles: [],
});

const addMissingPlatforms = (details: PlatformDetailsItem[]): PlatformDetailsItem[] => ApkPlatforms.map(p => {
    const existing = details.find(d => d.platform === p);
    return existing ?? makeEmptyDetails(p);
});

export type PlatformDetailsAction =
    { kind: "set", app: GoogleApplication } |
    { kind: "select", platform: ApkPlatform } |
    { kind: "updateValues", value: Partial<GoogleApkDetails> } |
    { kind: "updateFiles", value: GoogleAdditionalFile[] };

export const platformDetailsReducer = (state: PlatformDetailsState, action: PlatformDetailsAction) => {
    const processChangedDetails = () => {
        const isChanged = !deepEqual(state.current, state.initial);
        if (state.current === undefined) {
            return;
        }
        const changedIndex = state.changedPlatforms.indexOf(state.current.platform);

        if (isChanged && changedIndex === -1) {
            state.changedPlatforms = state.changedPlatforms.concat(state.current.platform);
        }

        if (!isChanged && changedIndex !== -1) {
            state.changedPlatforms.splice(changedIndex, 1);
        }
    };

    switch (action.kind) {
        case "set": {
            let all: PlatformDetailsItem[] = action.app.apkDetails.map(apk => {
                const additionalFiles = action.app.additionalFiles.filter(f => f.platform === apk.platform);
                return { ...apk, additionalFiles } as PlatformDetailsItem;
            });
            all = addMissingPlatforms(all);
            const allInitial = deepClone(all);

            const current = all.find(apk => apk.platform === (state.current?.platform ?? ApkPlatform.X32)) ?? all[0];
            const initial = deepClone(current);

            state = {
                current,
                initial,
                all,
                allInitial,
                changedPlatforms: [],
            };
            break;
        }
        case "select": {
            const found = state.all.find(apk => apk.platform === action.platform);
            if (found === undefined) {
                break;
            }

            state.current = found;
            state.initial = state.allInitial.find(apk => apk.platform === action.platform);
            break;
        }
        case "updateValues": {
            if (state.current === undefined) {
                break;
            }

            Object.assign(state.current, action.value);
            processChangedDetails();
            break;
        }
        case "updateFiles": {
            if (state.current === undefined) {
                break;
            }

            state.current.additionalFiles = action.value;
            processChangedDetails();
            break;
        }
    }

    return Object.assign({}, state);
};

export const makePlatformDetailsReducerDefault = (): PlatformDetailsState => ({
    current: undefined,
    initial: undefined,
    all: [],
    allInitial: [],
    changedPlatforms: [],
});