import { useState, useCallback } from "react";

/**
 * Creates 'useState' that can be used as shorthand method to avoid calling of lazy evaluation signature when using
 * function as a value.
 * @param initial The initial value.
 */
export function useFunctionState<T>(initial: T): [T, (value: T) => void] {
    const [value, setValue] = useState<T>(() => initial);
    const setFunc = useCallback((v: T) => setValue(() => v), []);

    return [value, setFunc];
}