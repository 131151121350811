import React from "react";
import { Breadcrumb as ReactstrapBreadcrumb } from "reactstrap";
import styled from "styled-components";

const StyledBreadcrumb = styled(ReactstrapBreadcrumb)`
    .breadcrumb {
        margin: 0;
        padding: 0 0 0 12px;
        background: none;
        border-radius: 0;
    }
`;

export const Breadcrumb = (props: { className?: string, children: React.ReactNode }) =>
    <StyledBreadcrumb className={props.className}>
        {props.children}
    </StyledBreadcrumb>;