import React, { useEffect, useState, useReducer, useMemo, useCallback, useRef, } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col } from "reactstrap";
import { defer, of } from "rxjs";
import {
    AdCampaign,
    AdFilterType,
    AdUnit,
    AdUnitTypes
} from "src/shared/dtos";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    LeavingViewProtector,
    Loader,
    DocumentTitle,
    RouteLink,
    RouteUserProps,
    EditError,
    EditErrorOptions,
    notifySuccess
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { structuredClone, useSubscription, ValidateAsyncRef } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { EditForm } from "./EditForm";
import { adUnitReducer, adUnitReducerDefault, makeEmptyAdBannerUnit } from "./adUnitReducer";
import api from "../api";
import { map, switchMap } from "rxjs/operators";

const makeEmptyAdUnit = (adCampaignId: number): AdUnit => ({
    id: 0,
    adCampaignId,
    isDisabled: false,
    dailyLimit: 0,
    daysPerWeek: 0,
    weeksTotal: 0,
    disableOnClick: false,
    filterType: AdFilterType.Application,
    unitType: AdUnitTypes.BannerAction,
    adBannerUnit: makeEmptyAdBannerUnit(0),
    adBrowserUnit: undefined,
    adUnitTriggers: undefined
});

export const EditView = withRouter(({ match, history, user }: RouteComponentProps<{ adCampaignId: string, id?: string }> & RouteUserProps) => {

    const editFormRef = useRef<ValidateAsyncRef>(null);

    const [isNew, id, adCampaignId] = useMemo(() => {
        const parsedId = match.params.id !== undefined ? parseInt(match.params.id, 10) : 0;
        const parsedAdCampaignId = parseInt(match.params.adCampaignId, 10);
        return [parsedId === 0, parsedId, parsedAdCampaignId];
    }, [match.params.id, match.params.adCampaignId]);

    const [isNewSaved, setIsNewSaved] = useState(!isNew);
    const [error, setError] = useState<EditErrorOptions>();
    const [saving, setSaving] = useState(false);
    const [adUnit, changeAdUnit] = useReducer(adUnitReducer, adUnitReducerDefault);
    const [adCampaignName, setAdCampaignName] = useState<string>();

    const loadAdUnitAndAdCampaign = useSubscription((adId: number, campaignId: number, isNewAd: boolean) => defer(() => isNewAd
    ? of<AdUnit | undefined>(undefined)
    : api.get(adId)).pipe(switchMap(unit => {
            const promise = unit !== undefined
                ? api.getAdCampaign(unit.adCampaignId)
                : api.getAdCampaign(campaignId);

            return defer(() => promise).pipe(
                map(campaign => [unit, campaign] as [AdUnit, AdCampaign])
            );
        })
    ).subscribe({
        next: results => {
            setAdUnit(results[0]);
            setAdCampaignName(results[1].name);
        },
        error: () => setError({
            text: "Unable to load Ad Unit.",
            actionText: "Back to Ad Campaign",
            action: () => history.push(routes.editAdCampaign.url({ id: campaignId }))
        })
    }), [api.getAdCampaign]);

    useEffect(() => {
        loadAdUnitAndAdCampaign(id, adCampaignId, isNew);
    }, [loadAdUnitAndAdCampaign, id, adCampaignId, isNew]);

    useEffect(() => {
        // Go to edit view after successful save of the new entity.
        // It is implemented this way to let leaving view protector know, that there is no changes.
        if (isNew && isNewSaved && error === undefined && adUnit.current !== undefined && adUnit.current.adCampaignId !== 0 && adUnit.current!.id !== 0) {
            history.push(routes.editAdUnit.url({ id: adUnit.current.id, adCampaignId: adUnit.current.adCampaignId }));
        }
    }, [isNew, isNewSaved, adUnit.current]);

    const updateState = useSubscription((value: AdUnit) => defer(() => structuredClone(value)).subscribe(clone => {
        changeAdUnit({ kind: "set", value, initialValue: clone });
    }), []);

    const setAdUnit = (data?: AdUnit) => {
        const value = data ?? makeEmptyAdUnit(adCampaignId);
        updateState(value);
    };

    const saveAdUnit = useSubscription((value: AdUnit, isNewAd: boolean) => defer(() => isNewAd
    ? api.create(value)
    : api.update(value)).subscribe({
        next: result => {
            setAdUnit(result);
            if (isNew) {
                setIsNewSaved(true);
            }

            notifySuccess(`Ad unit ${isNewAd ? "added" : "saved"} successfully.`);
            setSaving(false);
        },
        error: () => {
            setSaving(false);
            setError({
                text: "Unable to save Ad Unit.",
                actionText: "Continue",
                action: () => setError(undefined)
            });
        }
    }), [api.create, api.update]);

    const save = useCallback(async () => {
        if (saving || adUnit.current === undefined) {
            return;
        }

        setSaving(true);
        const isEditFormValid = await editFormRef.current?.validate();
        if (!isEditFormValid) {
            setSaving(false);
            return;
        }

        saveAdUnit(adUnit.current!, isNew);
    }, [adUnit.current, saving, isNew, saveAdUnit]);

    const updateAdUnit = useCallback((value: Partial<AdUnit>) => changeAdUnit({ kind: "update", value }), []);
    const setAdUnitType = useCallback((value: AdUnitTypes) => changeAdUnit({ kind: "selectType", value }), []);

    const [title, adCampaignTitle] = useMemo(
        () => [
            isNew ? "[new]" : adUnit.current !== undefined ? `[${adUnit.current.id}]` : "Loading...",
            adCampaignName ?? "Loading..."
        ],
    [isNew, adUnit.current, adCampaignName]);

    return (
        <VerticalBox>
            <DocumentTitle title={`Ad Unit - ${title}`} />
            <LeavingViewProtector showConfirmation={adUnit.changed && (isNew ? !isNewSaved : true)} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.adCampaigns}>
                                Ad Campaigns
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={{ route: routes.editAdCampaign, args: { id: adCampaignId } }}>
                                {adCampaignTitle}
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            Ad Units
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {title}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    {error && <EditError error={error} />}
                    {!error && adUnit.current === undefined && <Loader />}
                    {!error && adUnit.current !== undefined &&
                        <React.Fragment>
                            <Row>
                                <Col sm={{ size: 8, offset: 2 }}>
                                    <EditForm
                                        ref={editFormRef}
                                        value={adUnit.current}
                                        initial={adUnit.initial}
                                        isNewAdUnit={isNew}
                                        update={updateAdUnit}
                                        setType={setAdUnitType}
                                        saving={saving}
                                        onSubmit={save}
                                        user={user}
                                    />
                                </Col>
                            </Row>
                        </React.Fragment>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});