import React, { useState, useEffect, useMemo, useCallback } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BreadcrumbItem } from "reactstrap";
import { defer } from "rxjs";
import { Breadcrumb, CrawlingStatusText, DocumentTitle, Loader, RouteLink, RouteUserProps } from "src/shared/components";
import { Content, VerticalBox } from "src/shared/components/flex";
import { Column, DateSearch, DefaultCellRenderer, InfiniteTable, LoadRequest, multiValueSearchFactory, SearchOptions, StringSearch, TopPanelLeftContainer, TopPanelRendererProps } from "src/shared/components/InfiniteTable";
import { SuggestionTag } from "src/shared/components/MultiValueSearch";
import { CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { CrawlingStatus, CrawlIoApplicationResult, IoCrawlingResultsOrderBy, OrderDirection } from "src/shared/dtos";
import { deserializeDate, deserializeQueryParameters, getGridVisibleColumns, isColumnHidden, pushUrl, QueryParameterDescriptor, serializeDate, serializeQueryParameters, useBootstrapBreakpoint, useSubscription } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import api, { ListRequest } from "../api";

const keyDataTypes: { [key: string]: IoCrawlingResultsOrderBy } = {
    "appId": IoCrawlingResultsOrderBy.AppId,
    "addedAt": IoCrawlingResultsOrderBy.AddedAt,
    "updatedAt": IoCrawlingResultsOrderBy.UpdatedAt,
};
const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType as IoCrawlingResultsOrderBy);

function getSearchValueByKey<T>(key: string, searchOptions?: Array<SearchOptions<unknown>>): T | undefined {
    return searchOptions?.find(s => s.key === key)?.value as T;
}

const searchQueryParameterDescriptors: QueryParameterDescriptor[] = [
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    { paramType: "search", paramName: "appId" },
    {
        paramType: "search",
        paramName: "provider",
        serializer: (value: string[]) => value?.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "preferredProvider",
        serializer: (value: string[]) => value?.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "status",
        serializer: (value: string[]) => value?.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    { paramType: "search", paramName: "error" },
    {
        paramType: "search",
        paramName: "addedAtStartDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "addedAtEndDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "updatedAtStartDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "updatedAtEndDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    }
];
const statuses = Object.values(CrawlingStatus);

export const ResultsListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [items, setItems] = useState<CrawlIoApplicationResult[]>([]);
    const [visibleColumns, setVisibleColumns] = useState<string[] | "all" | undefined>();
    const [providers, setProviders] = useState<string[] | undefined>();

    const updateProviders = useSubscription(() => defer(() => api.getProviders()).subscribe(setProviders), []);

    useEffect(() => {
        updateProviders();
    }, []);

    const preferredProviders = useMemo(() => ["Any"].concat(providers ?? []), [providers]);

    const breakpoint = useBootstrapBreakpoint();
    const updateVisibleColumns = useSubscription(() => defer(() => getGridVisibleColumns("IoCrawlingResults"))
        .subscribe(columns => setVisibleColumns(columns ?? "all")), []);

    useEffect(() => {
        updateVisibleColumns();
    }, [breakpoint]);

    const [tableFilters, sort] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...searchQueryParameterDescriptors);
        let searchParams = allParams
            .filter(p => p.paramType === "search")
            .map(qp => ({ key: qp.paramName, value: qp.value } as SearchOptions<unknown>));

        const addedAtStartDate = searchParams.find(p => p.key === "addedAtStartDate");
        const addedAtEndDate = searchParams.find(p => p.key === "addedAtEndDate");
        searchParams = searchParams.filter(p => p.key !== "addedAtStartDate" && p.key !== "addedAtEndDate");
        if (addedAtStartDate?.value !== undefined && addedAtEndDate?.value !== undefined) {
            searchParams.push({ key: "addedAt", value: [addedAtStartDate.value, addedAtEndDate.value] });
        }

        const updatedAtStartDate = searchParams.find(p => p.key === "updatedAtStartDate");
        const updatedAtEndDate = searchParams.find(p => p.key === "updatedAtEndDate");
        searchParams = searchParams.filter(p => p.key !== "updatedAtStartDate" && p.key !== "updatedAtEndDate");
        if (updatedAtStartDate?.value !== undefined && updatedAtEndDate?.value !== undefined) {
            searchParams.push({ key: "updatedAt", value: [updatedAtStartDate.value, updatedAtEndDate.value] });
        }

        const orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        const orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;

        return [searchParams, { key: orderBy, order: orderDir } as SortOptions];
    }, [location.search]);

    const updateData = useSubscription((
        request: ListRequest,
        isReload: boolean,
        resolve: (val: boolean) => void) => defer(() => api.list(request)).subscribe(result => {
            if (result.length > 0) {
                setItems(prev => isReload ? result : (prev ?? []).concat(result));
            }
            resolve(result.length === 0);
        }), [api.list]);

    const load = useCallback(
        (request: LoadRequest) => {
            if (request.reload) {
                setItems([]);
            }

            const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : IoCrawlingResultsOrderBy.AppId;
            const searchRequest = {
                orderBy: orderByKey,
                orderDir: orderByKey !== undefined
                    ? request.sort?.order === SortOrder.DESC ? OrderDirection.Desc : OrderDirection.Asc
                    : undefined,
                appId: getSearchValueByKey<string>("appId", request.search),
                provider: getSearchValueByKey<string[]>("provider", request.search),
                preferredProvider: getSearchValueByKey<string[]>("preferredProvider", request.search),
                status: getSearchValueByKey<CrawlingStatus[]>("status", request.search),
                error: getSearchValueByKey<string>("error", request.search),
                addedAtStartDate: getSearchValueByKey<Date[]>("addedAt", request.search)?.[0],
                addedAtEndDate: getSearchValueByKey<Date[]>("addedAt", request.search)?.[1],
                updatedAtStartDate: getSearchValueByKey<Date[]>("updatedAt", request.search)?.[0],
                updatedAtEndDate: getSearchValueByKey<Date[]>("updatedAt", request.search)?.[1]
            };

            const requestBody: ListRequest = {
                offset: request.offset,
                limit: request.limit,
                orderBy: searchRequest.orderBy,
                orderDirection: searchRequest.orderDir ?? OrderDirection.Asc,
                appIdSearch: searchRequest.appId,
                providerSearch: searchRequest.provider,
                preferredProviderSearch: searchRequest.preferredProvider,
                statusSearch: searchRequest.status,
                errorSearch: searchRequest.error,
                addedAtStartDateSearch: searchRequest.addedAtStartDate,
                addedAtEndDateSearch: searchRequest.addedAtEndDate,
                updatedAtStartDateSearch: searchRequest.updatedAtStartDate,
                updatedAtEndDateSearch: searchRequest.updatedAtEndDate
            };

            const url = routes.ioCrawlingResults.url({
                searchParams: serializeQueryParameters(searchRequest, ...searchQueryParameterDescriptors)
            });
            pushUrl(location, history, url);

            return new Promise<boolean>(resolve => {
                updateData(requestBody, request?.reload, resolve);
            });
        }, [items, location]);

    const tagMapper = useCallback((id: string): SuggestionTag => ({ value: id, label: id }), []);

    const TopPanel = useMemo(() =>
        (props: TopPanelRendererProps) =>
            <props.blockElement>
                <TopPanelLeftContainer>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Crawling Io Apps</BreadcrumbItem>
                    </Breadcrumb>
                </TopPanelLeftContainer>
                <RouteLink user={user} to={routes.ioCrawlingSources} button className="mr-2">
                    Crawl Applications
                </RouteLink>
                <props.reloadElement />
                <props.optionsElement />
            </props.blockElement>, []);

    const appIdRenderer = useMemo(() =>
        (props: CellProps<string | undefined, CrawlIoApplicationResult>) => {
            const { cellData, ...restProps } = props;
            const gsAppExists = restProps.rowData.gamestoreApplicationExists;
            const value = gsAppExists ? (
                <RouteLink
                    user={user}
                    to={{ route: routes.editGamestoreIoApplication, args: { appId: cellData!, language: "en" } }}
                    className="d-inline">
                    {props.rowData.id}
                </RouteLink>
            ) : cellData;
            return <DefaultCellRenderer cellData={value} title={cellData} {...restProps} />;
        }, []);

    const textRenderer = useMemo(() =>
        (props: CellProps<string | undefined, CrawlIoApplicationResult>) => {
            const { cellData, ...restProps } = props;
            return <DefaultCellRenderer cellData={cellData} title={cellData} {...restProps} />;
        }, []);

    const statusRenderer = useMemo(() =>
        (props: CellProps<CrawlingStatus | undefined, CrawlIoApplicationResult>) => {
            const { cellData, ...restProps } = props;
            const status = cellData ?? CrawlingStatus.None;
            const title = status !== CrawlingStatus.Failed ? status : restProps.rowData.errorMessage;
            const statusText = <CrawlingStatusText status={status} />;
            return <DefaultCellRenderer cellData={statusText} title={title} {...restProps} />;
        }, []);

    const dateRenderer = useMemo(() =>
        (props: CellProps<Date | undefined, CrawlIoApplicationResult>) => {
            const { cellData, ...restProps } = props;
            const value = cellData !== undefined ? new Date(cellData).toLocaleDateString() : undefined;

            return <DefaultCellRenderer cellData={value} title={value} {...restProps} />;
        }, []);

    const select = makeSelect<CrawlIoApplicationResult>();

    return (
        <VerticalBox>
            <DocumentTitle title="Crawling Io Apps" />
            <Content overflowHidden>
                {(!providers || !visibleColumns) ? <Loader /> : (
                    <InfiniteTable
                        items={items}
                        load={load}
                        filters={tableFilters}
                        sortBy={sort}
                        topPanelRenderer={TopPanel}
                        visibleColumns={visibleColumns}
                    >
                        <Column
                            key="appId"
                            dataGetter={select(a => a.id)}
                            title="AppID"
                            width={320}
                            cellRenderer={appIdRenderer}
                            searchRenderer={StringSearch}
                            hidden={isColumnHidden("appId", visibleColumns)}
                        />
                        <Column
                            key="status"
                            dataGetter={select(a => a.status)}
                            title="Status"
                            width={160}
                            sortable={false}
                            cellRenderer={statusRenderer}
                            searchRenderer={multiValueSearchFactory(statuses, tagMapper)}
                            hidden={isColumnHidden("status", visibleColumns)}
                        />
                        <Column
                            key="provider"
                            dataGetter={select(a => a.provider)}
                            title="Provider"
                            width={220}
                            sortable={false}
                            cellRenderer={textRenderer}
                            searchRenderer={multiValueSearchFactory(providers, tagMapper)}
                            hidden={isColumnHidden("provider", visibleColumns)}
                        />
                        <Column
                            key="preferredProvider"
                            dataGetter={select(a => a.preferredProvider)}
                            title="Preferred provider"
                            width={220}
                            sortable={false}
                            cellRenderer={textRenderer}
                            searchRenderer={multiValueSearchFactory(preferredProviders, tagMapper)}
                            hidden={isColumnHidden("preferredProvider", visibleColumns)}
                        />
                        <Column
                            key="addedAt"
                            dataGetter={select(a => a.addedAt)}
                            title="Added date"
                            width={220}
                            minWidth={100}
                            cellRenderer={dateRenderer}
                            searchRenderer={DateSearch}
                            hidden={isColumnHidden("addedAt", visibleColumns)}
                        />
                        <Column
                            key="updatedAt"
                            dataGetter={select(a => a.updatedAt)}
                            title="Updated date"
                            width={220}
                            minWidth={120}
                            cellRenderer={dateRenderer}
                            searchRenderer={DateSearch}
                            hidden={isColumnHidden("updatedAt", visibleColumns)}
                        />
                        <Column
                            key="error"
                            dataGetter={select(a => a.errorMessage)}
                            title="Error"
                            width={280}
                            sortable={false}
                            cellRenderer={textRenderer}
                            searchRenderer={StringSearch}
                            hidden={isColumnHidden("error", visibleColumns)}
                        />
                    </InfiniteTable>
                )}
            </Content>
        </VerticalBox>
    );
});