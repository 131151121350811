import { CategoryType } from "src/shared/CategoryType";
import { Category, GamestoreApplicationLocalization } from "src/shared/dtos";
import { isNullOrUndefined, validateSource, validateSources, validateUrlField, validateUrls, ValidationSchema, validateDownloaderVideoOptions } from "src/shared/helpers";
import { GamestoreAppMainInfo } from "./applicationReducer";

export const makeLocalizationValidationSchema = (isGraphicsCustomized?: boolean): ValidationSchema<GamestoreApplicationLocalization> => ({
    thumbnailUrl: url => validateUrlField({
        url,
        isRequired: isGraphicsCustomized,
        requiredMessage: "URL is required for Graphics Customized app."
    }),
    iconUrl: url => validateUrlField({ url }),
    loadingImageUrl: url => validateUrlField({ url }),
    logoUrl: url => validateUrlField({ url }),
    featureUrl: url => validateUrlField({ url }),
    backgroundUrl: url => validateUrlField({ url }),
    banners: urls => validateUrls({
        urls,
        itemName: "Banner",
        isRequired: isGraphicsCustomized,
        requiredMessage: "At least one banner must be added for Graphics Customized app."
    }),
    screenshots: urls => validateUrls({ urls, itemName: "Screenshot" }),
    videos: urls => validateUrls({ urls, itemName: "Video" }),
    downloaderVideoOptions: v => validateDownloaderVideoOptions(v),
    downloaderImageUrls: urls => isNullOrUndefined(urls) ? null : validateUrls({ urls: urls!, itemName: "Line" }),
});

export const localizationMediaValidationSchema: ValidationSchema<GamestoreApplicationLocalization> = {
    thumbnailUrl: url => validateSource({ url, type: "image" }),
    iconUrl: url => validateSource({ url, type: "image" }),
    loadingImageUrl: url => validateSource({ url, type: "image" }),
    logoUrl: url => validateSource({ url, type: "image" }),
    featureUrl: url => validateSource({ url, type: "image" }),
    backgroundUrl: url => validateSource({ url, type: "image" }),
    banners: urls => validateSources({ urls, itemName: "Banner", type: "image" }),
    downloaderImageUrls: async urls => isNullOrUndefined(urls) ? null : await validateSources({ urls: urls!, itemName: "Line", type: "image" }),
    downloaderVideoOptions: ops => validateSource({ url: ops?.videoUrl, type: "youTube" }),
    screenshots: urls => validateSources({ urls, itemName: "Screenshot", type: "image" }),
    videos: urls => validateSources({ urls, itemName: "Video", type: "youTube" }),
};

const validateCategories = (categories: Category[]): string | null => {
    const parentCategoryIds = categories.map(c => c.parentId).filter(v => v);
    return parentCategoryIds.some(id => [CategoryType.Game, CategoryType.App].includes(id!))
        ? null
        : "At least one GAME category or APPLICATION category must be added.";
};

export const makeApplicationValidationSchema = (categories: Category[]): ValidationSchema<GamestoreAppMainInfo> => ({
    categoryIds: _ => validateCategories(categories),
    id: val => !val ? "Application ID required." : null
});