import React, { useEffect, useState, useReducer, useMemo, useCallback, useRef, } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col } from "reactstrap";
import { forkJoin, defer, noop, of } from "rxjs";
import { AdCampaign, SourceSettings } from "src/shared/dtos";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    LeavingViewProtector,
    Loader,
    DocumentTitle,
    RouteLink,
    RouteUserProps,
    EditError,
    EditErrorOptions,
    notifySuccess
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { structuredClone, useFunctionState } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { EditForm, EditFormRef } from "./EditForm";
import { adCampaignReducer, adCampaignReducerDefault } from "./adCampaignReducer";
import api from "../api";

const makeEmptyAdCampaign = (): AdCampaign => {
    return {
        id: 0,
        name: "",
        isDisabled: false,
        sourceSettingsIds: []
    };
};

export const EditView = withRouter(({ match, history, user }: RouteComponentProps<{ id: string }> & RouteUserProps) => {
    const [id, isNew] = useMemo(() => {
        const parsedId = match.params.id !== undefined ? parseInt(match.params.id, 10) : 0;
        return [parsedId, parsedId === 0];
    }, [match.params.id]);

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);

    const [isNewSaved, setIsNewSaved] = useState(!isNew);
    const [sourceSettingsList, setSourceSettings] = useState<SourceSettings[]>();
    const [error, setError] = useState<EditErrorOptions>();
    const [saving, setSaving] = useState(false);
    const [campaign, changeCampaign] = useReducer(adCampaignReducer, adCampaignReducerDefault);

    const editFormRef = useRef<EditFormRef>(null);

    useEffect(() => {
        const subscription = forkJoin([
            defer(() => api.getSourceSettings()),
            defer(() => isNew ? of<AdCampaign | undefined>(undefined) : api.get(id))
        ]).subscribe({
            next: results => {
                setSourceSettings(results[0]);
                setAdCampaign(results[1]);
            },
            error: () => setError({
                text: "Unable to load Ad Campaign.",
                actionText: "Back to list",
                action: () => history.push(routes.adCampaigns.url())
            })
        });
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        // Go to edit view after successful save of the new entity.
        // It is implemented this way to let leaving view protector know, that there is no changes.
        if (isNew && isNewSaved && error === undefined && campaign.current!.id !== 0) {
            history.push(routes.editAdCampaign.url({ id: campaign.current!.id }));
        }
    }, [isNew, isNewSaved, campaign.current]);

    const setAdCampaign = (data?: AdCampaign) => {
        const value = data ?? makeEmptyAdCampaign();

        if (value.sourceSettingsIds === undefined) {
            value.sourceSettingsIds = [];
        }

        const subscription = defer(() => structuredClone(value))
            .subscribe(clone => changeCampaign({ kind: "set", value, initialValue: clone }));
        setUnsubscribe(() => subscription.unsubscribe());
    };

    const save = useCallback(() => {
        if (saving || editFormRef.current?.validate() === false) {
            return;
        }

        setSaving(true);
        const subscription = defer(() => isNew
            ? api.create(campaign.current!, campaign.current!.sourceSettingsIds)
            : api.update(campaign.current!, campaign.current!.sourceSettingsIds)).subscribe({
                next: result => {
                    setAdCampaign(result);
                    if (isNew) {
                        setIsNewSaved(true);
                    }

                    notifySuccess(`Ad Campaign ${isNew ? "added" : "saved"} successfully.`);
                    setSaving(false);
                },
                error: () => {
                    setSaving(false);
                    setError({
                        text: "Unable to save Ad Campaign.",
                        actionText: "Continue",
                        action: () => setError(undefined)
                    });
                }
            });
        setUnsubscribe(() => subscription.unsubscribe());
    }, [campaign, campaign.current, saving, isNew, editFormRef.current]);

    const updateAdCampaign = useCallback((value: Partial<AdCampaign>) => changeCampaign({ kind: "update", value }), []);
    const title = useMemo(() => isNew ? "[new]" : campaign.current?.name ?? "Loading...", [isNew, campaign.current]);

    return (
        <VerticalBox>
            <DocumentTitle title={`Ad Campaign - ${title}`} />
            <LeavingViewProtector showConfirmation={campaign.changed && (isNew ? !isNewSaved : true)} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.adCampaigns}>
                                Ad Campaigns
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {title}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    {error && <EditError error={error} />}
                    {!error && campaign.current === undefined || sourceSettingsList === undefined &&
                        <Loader />}
                    {!error && sourceSettingsList !== undefined && campaign.current !== undefined &&
                        <React.Fragment>
                            <Row>
                                <Col sm={{ size: 8, offset: 2 }}>
                                    <EditForm
                                        ref={editFormRef}
                                        value={campaign.current}
                                        initial={campaign.initial}
                                        isNew={isNew}
                                        saving={saving}
                                        sourceSettingsList={sourceSettingsList}
                                        update={updateAdCampaign}
                                        save={save}
                                        user={user}
                                    />
                                </Col>
                            </Row>
                        </React.Fragment>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});
