import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

export interface ConfirmationModalProps {
    open: boolean;
    title: string;
    confirm: () => void;
    cancel: () => void;
}

export const ConfirmationModal = (props: React.PropsWithChildren<ConfirmationModalProps>) =>
    <Modal isOpen={props.open} toggle={props.cancel}>
        <ModalHeader toggle={props.cancel}>
            {props.title}
        </ModalHeader>
        <ModalBody>
            {props.children}
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={props.confirm}>Confirm</Button>
            <Button color="secondary" onClick={props.cancel}>Cancel</Button>
        </ModalFooter>
    </Modal>;