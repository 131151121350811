import React, { useRef, useState, useEffect, useMemo } from "react";
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import { setFromInput, useSubscription } from "src/shared/helpers";
import { ColorOption, ColorSchemeMetadata, ColorSchemeType } from "../dtos";
import {
    ListEditor,
    ListEditorAddControlRendererProps,
    ListEditorItemRendererProps,
    ListEditorProps,
    StyledTooltip
} from "src/shared/components";
import styled from "styled-components";
import { defer } from "rxjs";
import api from "src/components/SourceSettings/api";

const ItemContainer = styled.a`
    position: relative;
    display: block;
    width: 100%;
    padding-right: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export type ColorOptionsEditorProps = Omit<ListEditorProps<ColorOption>, "itemRenderer" | "addControlRenderer">;

const DefaultColor = "#FFFFFF";

export const ColorOptionsEditor = (props: ColorOptionsEditorProps) => {
    const [color, setColor] = useState(DefaultColor);
    const [schemeItem, setSchemeItem] = useState<string>("");
    const [colorOptions, setColorOptions] = useState<ColorSchemeMetadata[]>([]);

    const updateColorOptions = useSubscription(
        () => defer(() => api.getMetadata()).subscribe(d => setColorOptions(d.colors)), []);

    useEffect(() => {
        updateColorOptions();
    }, []);

    const colorOptionsByName = useMemo(() => {
        return colorOptions.reduce((acc, opt) => {
            acc[opt.name] = opt;
            return acc;
        }, {} as Record<string, ColorSchemeMetadata>);
    }, [colorOptions]);

    const filteredColorOptions = useMemo(
        () => colorOptions.filter(c => !props.items.some(x => x.schemeItem === c.name)),
        [props.items, colorOptions]);

    useEffect(() => {
        const defaultValue = colorOptionsByName[schemeItem]?.defaultValue;
        if (defaultValue) {
            setColor(defaultValue);
        }
    }, [schemeItem, colorOptionsByName]);

    const colorInputRef = useRef<HTMLDivElement>(null);
    const hexRegExp = /^#([A-Fa-f0-9]{6})$/;

    const itemRenderer = (itemRenderedProps: ListEditorItemRendererProps<ColorOption>) => {
        const itemName = itemRenderedProps.item.schemeItem;
        const colorOption = itemName !== undefined ? colorOptionsByName[itemName] : undefined;
        return (
            <ItemContainer
                key={`${itemRenderedProps.item.schemeItem}_${itemRenderedProps.item.color}`}
                title={itemRenderedProps.item.schemeItem}>
                {colorOption?.label}:{" "}{itemRenderedProps.item.color}{" "}
                {itemRenderedProps.removeButton(itemRenderedProps)}
            </ItemContainer>
        );
    };

    const addControlRenderer = (addControlRendererProps: ListEditorAddControlRendererProps<ColorOption>) => {

        const add = () => {
            if (!schemeItem) {
                return;
            }
            addControlRendererProps.add({
                color,
                schemeItem: schemeItem as ColorSchemeType
            });
            setColor(DefaultColor);
            setSchemeItem("");
        };

        const isDisabled = useMemo(
            () => addControlRendererProps.disabled || !color || !schemeItem || !hexRegExp.test(color),
            [addControlRendererProps.disabled, color, schemeItem]);

        return (
            <div>
                <InputGroup className="mt-2" id="colorOptionsInputGroup">
                    <Input
                        type="color"
                        id={addControlRendererProps.mainInputId + "colorPicker"}
                        value={color}
                        onChange={setFromInput(setColor)}
                        disabled={addControlRendererProps.disabled}
                    />
                    <Input
                        type="text"
                        id="colorInputFieldId"
                        value={color}
                        style={{ width: "4rem" }}
                        onChange={setFromInput(setColor)}
                        disabled={addControlRendererProps.disabled}
                    />
                    <div ref={colorInputRef} />
                    <Input
                        type="select"
                        id="colorSchemeItemSelector"
                        style={{ width: "70%" }}
                        value={schemeItem}
                        onChange={setFromInput(setSchemeItem)}
                        disabled={addControlRendererProps.disabled}
                    >
                        <option value={""} disabled hidden>Select item...</option>
                        {filteredColorOptions.map((v, i) =>
                            <option key={`${v.name}_${i}`} value={v.name}>{v.label}</option>)}
                    </Input>
                    <StyledTooltip placement="bottom" isOpen={color && !hexRegExp.test(color)} target={colorInputRef}>
                        A color code should be only in HEX format (#******) where ****** are a/A-f/F and 0-9.
                    </StyledTooltip>
                    <InputGroupAddon addonType="append">
                        <Button
                            type="button"
                            color="primary"
                            disabled={isDisabled}
                            onClick={add}>
                            Add
                        </Button>
                    </InputGroupAddon>
                </InputGroup>
            </div>
        );
    };

    return (
        <React.Fragment>
            <ListEditor addControlRenderer={addControlRenderer} itemRenderer={itemRenderer} {...props} />
        </React.Fragment>
    );
};