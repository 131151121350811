import React, { useState, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    InputGroup,
    Input,
    InputGroupAddon,
    Button,
    Carousel,
    CarouselIndicators,
    CarouselControl,
    CarouselItem,
    CarouselCaption
} from "reactstrap";
import { setFromInput } from "../helpers/setFromInput";
import { getYoutubeVideoIdFromUrl } from "../helpers";

export type Slide = {
    type: "image",
    url: string,
    alt?: string,
    captionHeader?: string,
    captionText?: string
} | {
    type: "video",
    url: string,
    captionHeader?: string,
    captionText?: string
} | {
    type: "srcVideo",
    url: string,
    captionHeader?: string,
    captionText?: string
};

export interface ImageListEditorProps {
    /**
     * The add input element id prop.
     */
    addInputId?: string;

    /**
     * The root element class name.
     */
    className?: string;

    /**
     * The current values.
     */
    values: unknown[];

    /**
     * The add value method. Must return value that will be displayed in add input and display image index.
     */
    add?: (url: string, currentIndex: number) => [string, number];

    /**
     * The remove value method.
     */
    remove?: (value: unknown) => void;

    /**
     * The render method that must return image URL.
     */
    render: (value: unknown) => Slide;

    /**
     * The image CSS height. Defaults to '30rem';
     */
    imageHeight?: string;

    /**
     * Is control disabled.
     */
    disabled?: boolean;

    highlight?: boolean;

    invalid?: boolean;

    placeholder?: string;
}

const Container = styled.div<{ imageHeight?: string, highlight?: boolean }>`
    position: relative;

    .carousel-inner {
        height: ${props => props.imageHeight ?? "30rem"};
        background: ${props => props.highlight ? "lightyellow" : ""};
    }
`;

const StyledCarousel = styled(Carousel) <{ highlight?: boolean }>`
    padding-top: 1rem;
    background-color: ${props => props.highlight ? "lightyellow" : "#ced4da"};
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
`;

const StyledCarouselItem = styled(CarouselItem)`
    width: 100%;
    height: 100%;
`;



const StyledItemContainer = styled.a`
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
`;

const CenteredItem = css`
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const StyledImg = styled.img`
    ${CenteredItem}
`;

const StyledIFrame = styled.iframe`
    ${CenteredItem}
`;

const StyledVideo = styled.video`
    ${CenteredItem};
`;

const StyledCarouselIndicators = styled(CarouselIndicators) <{ highlight?: boolean }>`
    position: relative;
    background-color: ${props => props.highlight ? "lightyellow" : "#ced4da"};
    margin: 0;
    height: 1.75rem;
    padding: 0 3rem;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    li {
        height: 0.15rem;
        margin: 0.15rem;
        background-color:${props => props.highlight ? "gray" : ""};
    }
`;

const StyledCarouselControl = styled(CarouselControl) <{ highlight?: boolean }>`
     ${props => props.highlight && css`
        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            height: 100px;
            width: 100px;
            outline: black;
            background-size: 100%, 100%;
            background-image: none;
        }

        .carousel-control-next-icon:after {
            content: '>';
            font-size: 60px;
            color: gray;
        }

        .carousel-control-prev-icon:after {
            content: '<';
            font-size: 60px;
            color: gray;
        }`
    }
`;

const RemoveButton = styled.a<{ hidden?: boolean, highlight?: boolean }>`
    color: ${props => props.highlight ? "gray" : "white"};
    display: ${props => props.hidden ? "none" : "block"};
    position: absolute;
    top: 0;
    right: 0;
    width: 15%;
    padding-top: 1rem;
    font-size: 2rem;
    text-align: center;
    opacity: 0.5;
    z-index: 2;

    &:hover, &:focus {
        color:${props => props.highlight ? "lightgray" : "white"};
        opacity: 0.9;
    }
`;

const RemoveButtonIcon = styled(FontAwesomeIcon).attrs({ icon: "times" })`
    vertical-align: top;
`;

export const SlideListEditor = (props: ImageListEditorProps) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [nextIndex, setNextIndex] = useState<number | undefined>(undefined);
    const [animating, setAnimating] = useState(false);
    const [addUrl, setAddUrl] = useState("");

    const setStartAnimating = () => setAnimating(true);
    const setStopAnimating = () => setAnimating(false);

    useEffect(() => {
        const maxIndex = Math.max(0, props.values.length - 1);
        if (activeIndex > maxIndex) {
            setActiveIndex(maxIndex);
        }

        if (!animating && nextIndex !== undefined) {
            setActiveIndex(Math.min(maxIndex, nextIndex));
            setNextIndex(undefined);
        }
    }, [props.values, props.values.length, animating]);

    const keys = useMemo(() => props.values.map((_, i) => `${i}`), [props.values]);

    const next = () => {
        if (!animating) {
            const toIndex = activeIndex === props.values.length - 1 ? 0 : activeIndex + 1;
            setActiveIndex(toIndex);
        }
    };

    const previous = () => {
        if (!animating) {
            const toIndex = activeIndex === 0 ? props.values.length - 1 : activeIndex - 1;
            setActiveIndex(toIndex);
        }
    };

    const goToIndex = (newIndex: number) => {
        setActiveIndex(newIndex);
    };

    const onRemoveClicked = (e: React.FormEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        const current = props.values[activeIndex];
        props.remove?.call(null, current);

        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        } else {
            setActiveIndex(-1);
            setNextIndex(0);
        }
    };

    const onAddClicked = () => {
        const result = props.add?.call(null, addUrl) as [string, number];
        setAddUrl(result[0]);
        setNextIndex(result[1]);
    };

    return (
        <Container className={props.className} imageHeight={props.imageHeight} highlight={props.highlight}>
            <RemoveButton href="#" onClick={onRemoveClicked} hidden={props.disabled} highlight={props.highlight}>
                <RemoveButtonIcon />
            </RemoveButton>
            <StyledCarousel activeIndex={activeIndex} next={next} previous={previous} interval={false} highlight={props.highlight}>
                {props.values.map((v, i) => {
                    const slide = props.render(v);
                    const key = `${keys[i]}_${slide.url}`;


                    return (
                        <StyledCarouselItem key={key} onExiting={setStartAnimating} onExited={setStopAnimating}>
                            <StyledItemContainer href={slide.url} target="_blank">
                                {slide.type === "image" && <StyledImg src={slide.url} alt={slide.alt ?? slide.url} />}
                                {slide.type === "video" && (
                                    <StyledIFrame
                                        src={`https://www.youtube.com/embed/${getYoutubeVideoIdFromUrl(slide.url)}`}
                                        itemType="text/html"
                                        frameBorder={0}
                                        allow="autoplay"
                                        allowFullScreen
                                    />
                                )}
                                {slide.type === "srcVideo" && (
                                    <StyledVideo src={slide.url} autoPlay loop muted/>
                                )}
                            </StyledItemContainer>
                            <CarouselCaption
                                captionHeader={slide.captionHeader}
                                captionText={slide.captionText ?? ""} />
                        </StyledCarouselItem>
                    );
                })}
                <StyledCarouselControl direction="prev" directionText="Previous" onClickHandler={previous} highlight={props.highlight} />
                <StyledCarouselControl direction="next" directionText="Next" onClickHandler={next} highlight={props.highlight} />
            </StyledCarousel>
            <StyledCarouselIndicators
                items={keys}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
                highlight={props.highlight}
           />
            <InputGroup className="mt-3">
                <Input
                    type="text"
                    id={props.addInputId}
                    value={addUrl}
                    onChange={setFromInput(setAddUrl)}
                    placeholder={props.placeholder}
                    disabled={props.disabled} />
                <InputGroupAddon addonType="append">
                    <Button
                        type="button"
                        color="primary"
                        onClick={onAddClicked}
                        disabled={props.disabled || addUrl === "" || addUrl.includes(" ")}
                    >
                        Add
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        </Container>
    );
};