import React, { FC } from "react";
import { GameCardData } from "./gameCards";
import { GameCard } from "./GameCard";
import styled from "styled-components";

const Container = styled.div<{ columns: number }>`
    display: grid;
    grid-template-columns: repeat(${p => p.columns}, 1fr);
    grid-gap: 10px;
`;

interface Props {
    cards: GameCardData[];
    columns: number;
}

export const GameCardsContainer: FC<Props> = ({ cards, columns }) => {
    return (
        <Container columns={columns}>
            {cards.map(card => (
                <GameCard
                    key={card.title}
                    img={card.img}
                    title={card.title}
                    tag={card.tag}
                    score={card.score}
                />
            ))}
        </Container>
    );
};