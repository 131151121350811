const formatSectionsRegexDictionary: { [sectionSize: number]: RegExp } = {};

/**
 * Splits the numeric value by fixed size sections (e.g. 12345678 -> 12 345 678).
 * @param value The numeric value.
 * @param sectionSize The size of a section. Default: 3.
 * @param delimiter The section delimiter. Default: "".
 * @returns The formatted string.
 */
export const formatSections = (value: number, sectionSize: number = 3, delimiter: string = " "): string => {
    const getOrCreate = () => {
        if (formatSectionsRegexDictionary[sectionSize] === undefined) {
            formatSectionsRegexDictionary[sectionSize] = new RegExp(`(\\d)(?=(\\d{${sectionSize}})+(?!\\d))`, "g");
        }

        return formatSectionsRegexDictionary[sectionSize];
    };

    const regex = getOrCreate();
    return value.toString().replace(regex, `$1${delimiter}`);
};

/**
 * Formats numeric value as its abbreviation (e.g. 50000 -> 50K, 10000000 -> 10M).
 * Number less than thousand will not be abbreviated. Support up to trillions abbreviation.
 * @param value The numeric value.
 * @returns The formatted string.
 */
export const formatAsAbbreviation = (value: number): string => {
    if (value >= 1e3 && value < 1e6) {
        return +(value / 1e3).toFixed(1) + "K";
    }
    if (value >= 1e6 && value < 1e9) {
        return +(value / 1e6).toFixed(1) + "M";
    }
    if (value >= 1e9 && value < 1e12) {
        return +(value / 1e9).toFixed(1) + "B";
    }
    if (value >= 1e12) {
        return +(value / 1e12).toFixed(1) + "T";
    }

    return value.toString();
};

/**
 * Decodes HTML escaped characters and removes HTML tags. Replaces <br> tags with new line characters.
 * @param html The html string.
 * @returns The plain text.
 */
export const htmlToPlainText = (html: string): string => {
    html = html.replace(/<br>/g, "\n");
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
};