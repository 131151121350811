import React, { FC, useMemo, useEffect, useState } from "react";
import { Options as ChartOptions } from "highcharts";
import { ChartTypes, TotalGamingMinutesByDate } from "src/shared/dtos";
import { getHighChartsType, getStartDate } from "./utils";
import { formatAsAbbreviation, useSubscription } from "src/shared/helpers";
import { defer } from "rxjs";
import api, { StartEndDate } from "./api";
import { ChartWidgetContent } from "./ChartWidgetContent";
import { ChartWidgetProps } from "./ChartWidget";

const makeChartOptions = (data: TotalGamingMinutesByDate[], chartType: ChartTypes): ChartOptions => {
    const chartData = data.map(r => {
        const date = new Date(r.date);
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        return [utcDate.getTime(), r.totalMinutes];
    });
    return {
        title: {
            style: {
                display: "none"
            }
        },
        xAxis: {
            type: "datetime",
            maxPadding: 0.1,
            minPadding: 0.1,
            minTickInterval: 24 * 3600 * 1000,
            dateTimeLabelFormats: {
                day: "%e %b"
            },
        },
        yAxis: [{
            title: { text: "Minutes" },
            labels: {
                formatter() {
                    return formatAsAbbreviation(this.value);
                }
            }
        }],
        tooltip: {
            valueDecimals: 0
        },
        series: [{
            name: "Gaming Minutes",
            type: getHighChartsType(chartType),
            data: chartData
        }]
    };
};

export const GamingMinutesByDateChartWidget: FC<ChartWidgetProps> = ({ chartWidget, resizeTrigger, setLoading }) => {
    const [data, setData] = useState<TotalGamingMinutesByDate[]>([]);
    const [actualEndDate, setActualEndDate] = useState(new Date());

    const requestDates = useMemo<StartEndDate>(() => ({
        startDate: getStartDate(new Date(), chartWidget.timeSpan).toISOString(),
        endDate: new Date().toISOString()
    }), [chartWidget.timeSpan]);

    const actualDates = useMemo<[Date, Date]>(
        () => ([getStartDate(actualEndDate, chartWidget.timeSpan), actualEndDate]), [actualEndDate, chartWidget.timeSpan]);

    const getGamingMinutesByDate = useSubscription((dates: StartEndDate, showPlaystoreActivity: boolean) => defer(
        () => api.getGamingMinutesByDate({ ...dates, showPlaystoreActivity }))
        .subscribe(val => {
            setActualEndDate(new Date(val.endDate));
            setData(val.items ?? []);
            setLoading(false);
        }),
        [api.getGamingMinutesByDate]);

    const options = chartWidget.options?.gamingMinutesChartOptions;

    useEffect(() => {
        setLoading(true);
        getGamingMinutesByDate(requestDates, options?.showPlaystoreActivity ?? false);
    }, [requestDates, options]);

    const chartOptions = useMemo<ChartOptions>(
        () => makeChartOptions(data, chartWidget.chartType), [chartWidget, data]);

    return (
        <ChartWidgetContent
            startDate={actualDates[0]}
            endDate={actualDates[1]}
            resizeTrigger={resizeTrigger}
            chartOptions={chartOptions}
        />
    );
};