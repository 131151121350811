import { validateSource, validateUrlField, ValidationSchema } from "src/shared/helpers";
import { AdBannerUnit, AdBrowserUnit, AdDynamicWebUnit, AdRichMediaOverlayUnit, AdSideUnit } from "src/shared/dtos";

export interface AdUnitFormValues {
    allApplicationsSelected: boolean;
    allCategoriesSelected: boolean;
    applicationIds: string[];
    categoryIds: string[];
}

export const adUnitValidationSchema: ValidationSchema<AdUnitFormValues> = {
    applicationIds: (ids, form) => !form.allApplicationsSelected && ids.length === 0 ? "Please, define applications" : null,
    categoryIds: (ids, form) => !form.allCategoriesSelected && ids.length === 0 ? "Please, define categories" : null,
};

export const bannerUnitValidationSchema: ValidationSchema<AdBannerUnit> = {
    bannerImageUrl: [
        url => validateUrlField({ url, isRequired: true }),
        url => url.endsWith(".svg") ? "Banner image can't have svg format" : null,
        url => validateSource({ url, type: "image" })],
    bannerUrl: url => validateUrlField({ url, isRequired: true })
};

export const richMediaOverlayValidationSchema: ValidationSchema<AdRichMediaOverlayUnit> = {
    content: [url => validateUrlField({ url }), url => validateSource({ url, type: "image" })],
    targetUrl: url => validateUrlField({ url, isRequired: true })
};

export const browserUnitValidationSchema: ValidationSchema<AdBrowserUnit> = {
    url: url => validateUrlField({ url, isRequired: true })
};

export const dynamicWebValidationSchema: ValidationSchema<AdDynamicWebUnit> = {
    url: url => validateUrlField({ url, isRequired: true }),
    header: val => !val ? "Header is required." : null
};

export const sideAdUnitValidationSchema: ValidationSchema<AdSideUnit> = {
    url: url => validateUrlField({ url, isRequired: true })
};