import React, { useMemo } from "react";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import { GoogleApplicationLocalization, GamestoreApplication, GoogleApkDetails } from "src/shared/dtos";
import { Rating } from "src/shared/components/Rating";
import { GetGoogleAppLink, GetGoogleAppPublisherLink, GetGoogleAppCategoryLink, formatAsAbbreviation } from "src/shared/helpers";
import noImage from "src/images/no-image-icon.png";
import { RouteLink } from "src/shared/components";
import { routes } from "src/shared/routes";
import { IUser } from "src/shared/client";
import { GoogleAppMainInfo } from "./googleApplicationReducer";

const ResponsiveImg = styled.img`
    max-width: 100%;
`;

const DateTimeBlock = styled.div`
    color: #6c757d;
    margin-top: 0.25em;
`;

type Props = {
    user: IUser | null;
    app: GoogleAppMainInfo;
    locale?: GoogleApplicationLocalization;
    gamestoreApp?: GamestoreApplication;
    apkDetails?: GoogleApkDetails;
    isNewApp: boolean;
};

const toDateTime = (value?: string): string => {
    if (!value) {
        return "[not defined]";
    }

    const date = new Date(value);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const EditInfo = (props: Props) => {
    const onImageClick = props.isNewApp ? (e: React.FormEvent) => e.preventDefault() : undefined;

    const updatedAt = useMemo(
        () => toDateTime(props.apkDetails?.updatedAt),
        [props.apkDetails]);

    const updatedOn = useMemo(
        () => toDateTime(props.app.updatedOn),
        [props.app.updatedOn]);

    return (
        <Row className="pr-sm-4">
            <Col className="d-none d-sm-block mb-3" lg={4}>
                <a href={props.locale?.iconUrl ?? "#"} target="_blank" onClick={onImageClick}>
                    <ResponsiveImg
                        src={props.locale?.iconUrl ?? noImage}
                        alt={props.locale?.iconUrl ?? "Application icon"} />
                </a>
            </Col>
            <Col className="mb-3" lg={8}>
                <Rating
                    className="float-right"
                    title={`${props.app.rating}`}
                    value={props.app.rating}
                    max={5}
                    size={26} />
                {props.locale !== undefined &&
                    <React.Fragment>
                        <a
                            href={GetGoogleAppLink(props.app.id, props.locale.language)}
                            target="_blank">
                            <h3>
                                {props.locale?.title}
                            </h3>
                        </a>
                        <a
                            className="mr-3"
                            href={GetGoogleAppPublisherLink(
                                props.locale.language,
                                props.locale?.publisher,
                                props.locale?.publisherId)}
                            target="_blank">
                            {props.locale?.publisher ?? props.locale?.publisher}
                        </a>
                        <a
                            className="d-block d-md-inline"
                            href={GetGoogleAppCategoryLink(props.app.categoryId, props.locale.language)}
                            target="_blank">
                            {props.isNewApp ? "" : props.app.category?.name}
                        </a>
                    </React.Fragment>}
                <DateTimeBlock>
                    APK updated at: <span>{updatedAt}</span>
                </DateTimeBlock>
                <DateTimeBlock>
                    Google app updated on: <span>{updatedOn}</span>
                </DateTimeBlock>
                {!props.isNewApp &&
                    <React.Fragment>
                        {props.gamestoreApp === undefined
                            ? (
                                <RouteLink
                                    user={props.user}
                                    to={{ route: routes.addApplication, args: { appId: props.app.id } }}
                                >
                                    Create Gamestore Application
                                </RouteLink>
                            )
                            : (
                                <RouteLink
                                    user={props.user}
                                    to={{
                                        route: routes.editApplication, args: {
                                            appId: props.gamestoreApp?.id,
                                            language: "en"
                                        }
                                    }}
                                >
                                    Open Gamestore Application
                                </RouteLink>
                            )}
                    </React.Fragment>}
            </Col>
        </Row>
    );
};