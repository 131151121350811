import { css, keyframes } from "styled-components";

const MakeVisible = keyframes`
    0% {
        opacity: 0;
    }
    66% {
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 1;
    }
`;

export const DelayAppearance = css`
    visibility: hidden;
    animation: 1s ${MakeVisible};
    animation-fill-mode: forwards;
`;