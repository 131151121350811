import { ProductVersionInfo } from "src/shared/dtos";
import { deepEqual } from "src/shared/helpers";

export type EditReducerState = {
    current?: ProductVersionInfo,
    initial?: ProductVersionInfo,
    changed: boolean
};

export type EditReducerAction =
    { kind: "set", value: ProductVersionInfo, initialValue?: ProductVersionInfo } |
    { kind: "update", value: Partial<ProductVersionInfo> };

export const editReducer = (state: EditReducerState, action: EditReducerAction) => {
    switch (action.kind) {
        case "set": {
            state = {
                current: action.value,
                initial: action.initialValue,
                changed: false
            };
            break;
        }
        case "update": {
            Object.assign(state.current, action.value);
            state.changed = !deepEqual(state.current, state.initial);
            break;
        }
    }

    return Object.assign({}, state);
};

export const editReducerDefault: EditReducerState = {
    current: undefined,
    initial: undefined,
    changed: false
};
