import { client } from "src/shared/client";
import {
    IoApplication,
    GetGamestoreIoApplication,
    GetIoApplication,
    GetIoApplicationSummaries,
    ManageIoApplication,
    GetCategories,
    GetTags,
    TagsOrderBy,
    OrderDirection,
    ManageUserGridSettings,
} from "src/shared/dtos";
import { ExcludeMethods } from "src/shared/helpers";

export type ListRequest = ExcludeMethods<GetIoApplicationSummaries>;

export const IoApplicationApi = {
    getCategories: () => client.get(new GetCategories()),
    getTags: () => client.get(new GetTags({ orderBy: TagsOrderBy.Id, orderDirection: OrderDirection.Asc })),
    list: async (request: ListRequest) => client.get(new GetIoApplicationSummaries({ ...request })),
    get: (id: string) => client.get(new GetIoApplication({ id })),
    create: (application: IoApplication) => client.post(new ManageIoApplication({ application })),
    update: (application: IoApplication) => client.put(new ManageIoApplication({ application })),
    getGamestoreApp: async (id: string) => {
        const app = await client.get(new GetGamestoreIoApplication({ id }));
        return app.id === undefined ? undefined : app;
    },
    saveGridSettings: (breakpoint: string, visibleColumns: string[]) => client.post(new ManageUserGridSettings({
        gridName: "IoApplications",
        breakpoint,
        visibleColumns
    }))
};

export default IoApplicationApi;