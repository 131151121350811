import React, { useMemo } from "react";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import { GamestoreIoApplicationLocalization, IoApplicationLocalization } from "src/shared/dtos";
import { Rating } from "src/shared/components/Rating";
import noImage from "src/images/no-image-icon.png";
import { GamestoreIoAppMainInfo } from "./applicationReducer";

const ResponsiveImg = styled.img`
    max-width: 100%;
`;

const DateTimeBlock = styled.div`
    color: #6c757d;
    margin-top: 0.25em;
`;

interface Props {
    app: GamestoreIoAppMainInfo;
    locale: GamestoreIoApplicationLocalization;
    ioLocale?: IoApplicationLocalization;
    isNewApp: boolean;
}

const toDateTime = (value: string) => {
    if (!value) {
        return "[not defined]";
    }

    const date = new Date(value);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const EditInfo = (props: Props) => {
    const onImageClick = props.isNewApp && !props.locale.iconUrl
        ? (e: React.FormEvent) => e.preventDefault()
        : undefined;

    const ioAppUpdatedAt = useMemo(
        () => toDateTime(props.app.ioApplication.updatedAt),
        [props.app.ioApplication.updatedAt]);
    const modifiedAt = useMemo(() => toDateTime(props.app.modifiedAt), [props.app.modifiedAt]);

    return (
        <Row className="pr-sm-4">
            <Col className="d-none d-sm-block mb-3" lg={4}>
                <a href={props.locale.iconUrl ?? props.ioLocale?.iconUrl ?? "#"} target="_blank" onClick={onImageClick}>
                    <ResponsiveImg
                        src={props.locale.iconUrl ?? props.ioLocale?.iconUrl ?? noImage}
                        alt={props.locale.iconUrl ?? props.ioLocale?.iconUrl ?? "Application icon"} />
                </a>
            </Col>
            <Col className="mb-3" lg={8}>
                <Rating
                    className="float-right"
                    title={`${props.app.rating ?? props.app.ioApplication.rating}`}
                    value={props.app.rating ?? props.app.ioApplication.rating}
                    max={5}
                    size={26} />
                <a
                    href={props.locale.applicationUrl ?? props.ioLocale?.applicationUrl ?? "#"}
                    target="_blank">
                    <h3>
                        {props.locale.title ?? props.ioLocale?.title}
                    </h3>
                </a>
                {props.app.ioApplication.developer &&
                    <span className="d-block">
                        <b>Developer: </b>{props.app.ioApplication.developer}
                    </span>}
                <span className="d-block">
                    <b>Io Category: </b>{props.app.ioApplication.category.name}
                </span>
                <span className="d-block">
                    <b className="mr-2">Source Provider: </b>
                    <a href={`https://${props.app.ioApplication.sourceProvider}`}>
                        {props.app.ioApplication.sourceProvider}
                    </a>
                </span>
                <span className="d-block">
                    <b className="mr-2">Source Url: </b>
                    <a href={props.ioLocale?.sourceUrl ?? "#"}>{props.ioLocale?.sourceUrl}</a>
                </span>
                <DateTimeBlock>
                    Io game updated at: <span>{ioAppUpdatedAt}</span>
                </DateTimeBlock>
                <DateTimeBlock>
                    Modified at: <span>{modifiedAt}</span>
                </DateTimeBlock>
            </Col>
        </Row>
    );
};
