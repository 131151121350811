import React, { useState } from "react";
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import {
    ListEditor,
    ListEditorProps,
    ListEditorAddControlRendererProps
} from "src/shared/components";
import { setFromInput } from "src/shared/helpers";

const addControlRenderer = (props: ListEditorAddControlRendererProps<string>) => {
    const [url, setUrl] = useState("");
    const add = () => {
        props.add(url);
        setUrl("");
    };

    return (
        <div>
            <InputGroup className="mt-2">
                <Input
                    type="url"
                    placeholder="URL"
                    value={url}
                    onChange={setFromInput(setUrl)}
                    disabled={props.disabled} />
                <InputGroupAddon addonType="append">
                    <Button type="button" color="primary" disabled={props.disabled || url === "" || url.includes(" ")} onClick={add}>
                        Add
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        </div>
    );
};

export type MultiValueEditorProps = Omit<ListEditorProps<string>, "addControlRenderer">;

export const MultiValueEditor = (props: MultiValueEditorProps) => {
    return <ListEditor addControlRenderer={addControlRenderer} {...props} />;
};