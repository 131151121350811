import React, { FC, useMemo, useState, Dispatch, useEffect, useCallback } from "react";
import {
    GamestoreApplicationListDataType,
    GamestoreApplicationSummary,
    OrderDirection,
} from "src/shared/dtos";
import { IUser } from "src/shared/client";
import { formatAsAbbreviation, useSubscription } from "src/shared/helpers";
import { defer } from "rxjs";
import api, { GetGamestoreAppsRequest } from "./api";
import { ListWidgetTable, ListWidgetTableColumn } from "./ListWidgetTable";
import { RouteLink } from "src/shared/components";
import { Rating } from "src/shared/components/Rating";
import { routes } from "src/shared/routes";

type Props = {
    user: IUser | null;
    setLoading: Dispatch<boolean>;
    columns: Array<keyof GamestoreApplicationSummary>;
    orderBy: GamestoreApplicationListDataType
};

export const GamestoreAppsTable: FC<Props> = (props) => {
    const { setLoading, user, orderBy, columns: columnNames } = props;

    const [applications, setApplications] = useState<GamestoreApplicationSummary[]>([]);

    const getGamestoreApplications = useSubscription((req: GetGamestoreAppsRequest) => defer(
        () => api.getGamestoreApplications(req)).subscribe(val => {
            setLoading(false);
            setApplications(val);
        }),
        []);

    useEffect(() => {
        setLoading(true);
        getGamestoreApplications({
            offset: 0,
            limit: 10,
            orderBy,
            orderDirection: OrderDirection.Desc,
            language: "en"
        });
    }, [orderBy]);

    const columns = useMemo<{ [key in keyof GamestoreApplicationSummary]?: ListWidgetTableColumn<GamestoreApplicationSummary> }>(() => ({
        title: { label: "Title", style: { minWidth: 260 }, renderer: v => v.title },
        id: {
            label: "id",
            renderer: v => (
                <RouteLink
                    user={user}
                    to={{ route: routes.editApplication, args: { appId: v.id, language: "en" } }}
                    className="d-inline"
                >
                    {v.id}
                </RouteLink>
            ),
            style: { maxWidth: 260, overflowWrap: "break-word" }
        },
        rating: {
            label: "Rating",
            isSortedBy: props.orderBy === GamestoreApplicationListDataType.Rating,
            renderer: v => <Rating value={v.rating} max={5} />
        },
        modifiedAt: {
            label: "Modified at",
            isSortedBy: props.orderBy === GamestoreApplicationListDataType.ModifiedDate,
            renderer: val => new Date(val.modifiedAt).toLocaleDateString(), style: { minWidth: 100 }
        },
        reviewsCount: {
            label: "#reviews",
            isSortedBy: props.orderBy === GamestoreApplicationListDataType.ReviewsCount,
            renderer: val => formatAsAbbreviation(val.reviewsCount ?? 0)
        }
    }), [user]);

    const tableColumns = useMemo(() =>
        columnNames.reduce((result, columnName) => {
            if (columnName in columns) {
                result.push(columns[columnName]!);
            }
            return result;
        }, [] as ListWidgetTableColumn<GamestoreApplicationSummary>[])
        , [columns, columnNames]);

    const idSelector = useCallback((app: GamestoreApplicationSummary) => app.id, []);

    return <ListWidgetTable data={applications} columns={tableColumns} rowKeySelector={idSelector} />;
};