
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type WarningIndicatorProps = {
    warnings: string[];
    style?: CSSProperties;
    className?: string;
};

const WarningIcon = styled(FontAwesomeIcon).attrs({ icon: ["fas", "exclamation-triangle"] })`
    height: 1rem;
    color: #f46f0b;
    cursor: help;
`;

export const WarningIndicator = (props: WarningIndicatorProps) => {
    const title = props.warnings.join("\n");

    return (
        <WarningIcon title={title} style={props.style} className={props.className} />
    );
};