import React, { FC, useCallback, useEffect, useState } from "react";
import { defer } from "rxjs";
import { IUser } from "src/shared/client";
import { TextWidgetUnit, WidgetTypes, WidgetUnit, WidgetUnitSummary } from "src/shared/dtos";
import { useSubscription } from "src/shared/helpers";
import api from "../api";
import { ChartWidget } from "./ChartWidget";
import { InfoWidget } from "./InfoWidget";
import { ListWidget } from "./ListWidget";
import { TextWidget } from "./TextWidget";
import { WidgetBase } from "./WidgetBase";
import { WidgetModal } from "./WidgetModal/WidgetModal";
import { notifySuccess, notifyError } from "src/shared/components";

export interface WidgetProps {
    widget: WidgetUnitSummary;
    removeFromDashboard: (id: number) => void;
    editMode: boolean;
    resizeTrigger: boolean;
    user: IUser | null;
}

export const Widget: FC<WidgetProps> = (props) => {
    const { widget, removeFromDashboard, editMode, resizeTrigger, user } = props;
    const [loading, setLoading] = useState(false);
    const [widgetUnit, setWidgetUnit] = useState<WidgetUnit | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [editContent, setEditContent] = useState(false);

    const loadWidget = useSubscription((id: number) => defer(
        () => api.getWidget(id)).subscribe(val => {
            setLoading(false);
            setWidgetUnit(val);
        }),
        [api.getWidget]);

    const saveWidget = useSubscription((val: WidgetUnit) => defer(
        () => api.update(val))
        .subscribe({
            next: res => {
                setLoading(false);
                setWidgetUnit(res);
                notifySuccess("Widget has been saved.");
            },
            error: err => {
                setLoading(false);
                notifyError(err?.responseStatus?.message ?? "Failed to save widget.");
            },
        }),
        [api.update]);

    const removeWidget = useSubscription((id: number) => defer(
        () => api.delete(id))
        .subscribe({
            next: _ => {
                notifySuccess("Widget has been removed.");
                removeFromDashboard(id);
            },
            error: err => {
                setLoading(false);
                notifyError(err?.responseStatus?.message ?? "Failed to remove widget.");
            },
        }),
        [api.getWidgets, removeFromDashboard]);

    useEffect(() => {
        setLoading(true);
        loadWidget(widget.id);
    }, [widget, loadWidget]);

    const closeModal = useCallback(
        () => setModalOpen(false), []);

    const save = useCallback((val: WidgetUnit) => {
        setLoading(true);
        saveWidget(val);
    }, []);

    const remove = useCallback(() => {
        setLoading(true);
        removeWidget(widget.id);
    }, [widget.id]);

    const saveTextWidget = useCallback((val: TextWidgetUnit) => {
        if (!widgetUnit) {
            return;
        }
        setEditContent(false);
        save({ ...widgetUnit, textWidget: val });
    }, [save, widgetUnit]);

    const widgetType = widget.widgetType;

    const edit = useCallback(() => {
        if (widgetType === WidgetTypes.TextWidget) {
            setEditContent(true);
            return;
        }
        setModalOpen(true);
    }, [widgetType]);

    return (
        <WidgetBase
            title={widgetUnit?.title}
            remove={remove}
            edit={edit}
            loading={loading}
            editMode={editMode}
        >
            <WidgetModal
                widget={widgetUnit}
                close={closeModal}
                isOpen={modalOpen}
                save={save}
            />
            {widgetType === WidgetTypes.TextWidget && widgetUnit?.textWidget &&
                <TextWidget textWidget={widgetUnit.textWidget} editContent={editContent} save={saveTextWidget} />}
            {widgetType === WidgetTypes.InfoWidget && widgetUnit?.infoWidget &&
                <InfoWidget infoWidget={widgetUnit.infoWidget} setLoading={setLoading} />}
            {widgetType === WidgetTypes.ChartWidget && widgetUnit?.chartWidget &&
                <ChartWidget chartWidget={widgetUnit.chartWidget} resizeTrigger={resizeTrigger} setLoading={setLoading} />}
            {widgetType === WidgetTypes.ListWidget && widgetUnit?.listWidget &&
                <ListWidget listWidget={widgetUnit.listWidget} user={user} setLoading={setLoading} />}
        </WidgetBase>
    );
};