import React, { useEffect, useCallback } from "react";
import { History, Location } from "history";
import { Prompt } from "react-router";
import { matchPath } from "react-router-dom";
import { IRoute } from "../routes";

export function LeavingViewProtector<TRouteArgs>(props: { showConfirmation: boolean, ignoreRoute?: IRoute<TRouteArgs> }) {
    useEffect(() => {
        window.onbeforeunload = props.showConfirmation ? () => true : null;
        return () => { window.onbeforeunload = null; };
    }, [props.showConfirmation]);

    const validateRoute = useCallback((history: Location<History.PoorMansUnknown>) => {
        if (props.ignoreRoute !== undefined) {
            const isSameRoute = matchPath(history.pathname, {
                path: props.ignoreRoute.pattern.path,
                exact: props.ignoreRoute.pattern.exact,
                strict: false
            });
            if (isSameRoute) {
                return true;
            }
        }

        return "Changes you made may not be saved. Are you sure you want to leave?";
    }, [props.ignoreRoute]);

    return <Prompt when={props.showConfirmation} message={validateRoute} />;
}