import React, { useEffect, useState } from "react";
import {
    Column as BaseTableColumn,
    CellHeaderProps as BaseTableCellHeaderProps
} from "src/shared/components/ReactBaseTable";
import { isColumnHidden } from "src/shared/helpers";
import { Column, SearchCellHeaderProps } from ".";
import { ColumnOptions } from "./ColumnOptions";
import { DefaultCellRenderer } from "./DefaultCellRenderer";

// NB: Class name was taken from internal API, to have cell be rendered as if renderer was not
// replaced. This class name, as well as markup, can be changed in future implementations of
// 'react-base-table' component.
const headerCellTextClassName = "BaseTable__header-cell-text";

interface CellHeaderProps<TData, TItem> extends BaseTableCellHeaderProps<TData, TItem> {
    searchHeaderIndex: number;
    headerRenderer?: React.FC<BaseTableCellHeaderProps<TData, TItem>> | React.ReactNode | JSX.Element;
    searchRenderer?: React.FC<SearchCellHeaderProps<TData, TItem>> | React.ReactNode | JSX.Element;
}

function CellHeader<TData, TItem>(props: CellHeaderProps<TData, TItem>) {
    if (props.headerIndex !== props.searchHeaderIndex) {
        const BaseCellHeaderRenderer = props.headerRenderer! as React.FC<BaseTableCellHeaderProps<unknown, TItem>>;
        return props.headerRenderer !== undefined
            ? <BaseCellHeaderRenderer {...props} />
            : <div className={headerCellTextClassName}>{props.column.title}</div>;
    }

    const SearchCellHeaderRenderer = props.searchRenderer! as React.FC<SearchCellHeaderProps<TData, TItem>>;
    const searchProps = props as unknown as SearchCellHeaderProps<TData, TItem>;
    return props.searchRenderer !== undefined
        ? <SearchCellHeaderRenderer {...searchProps} />
        : null;
}

function customizeCellHeader<TData, TItem>(
    searchHeaderIndex: number,
    headerRenderer?: React.FC<BaseTableCellHeaderProps<TData, TItem>> | React.ReactNode | JSX.Element,
    searchRenderer?: React.FC<SearchCellHeaderProps<TData, TItem>> | React.ReactNode | JSX.Element) {

    return (props: BaseTableCellHeaderProps<TData, TItem>) =>
        <CellHeader
            searchHeaderIndex={searchHeaderIndex}
            headerRenderer={headerRenderer}
            searchRenderer={searchRenderer}
            {...props}
        />;
}

/**
 * The column customization helper, that provides default values for columns, and support additional properties,
 * provided by InfiniteTable Columns.
 * This approach was implemented, because BaseTable does not actually renders Columns, but iterates over children
 * and read their properties to internal state. Thus we can't modify properties inside of a component, only way to
 * extend behavior is to preprocess them before passing to 'children' of a BaseTable.
 * @param columns The column children.
 * @param searchHeaderIndex The index of a search header.
 * @returns [columns, hasSearch, columnOptions]
 */
export function customizeColumns<TItem, TRenderContext>(
    columns: React.ReactNode,
    searchHeaderIndex: number,
    resetColumnSearch: (key: string) => void,
    visibleColumns?: string[] | "all",
    renderContext?: TRenderContext) : [React.ReactNode, boolean, ColumnOptions[]] {

    const getStringKey = (element: React.ReactElement) => element.key !== null ? element.key + "" : null;
    const keys = (React.Children
        .map(columns, getStringKey) as string[])
        .filter(k => k !== null && k !== undefined);

    let hasSearchColumns = false;
    const columnOptions: ColumnOptions[] = [];
    const customColumns: React.ReactElement[] = [];

    React.Children.forEach(columns, (child: Column<unknown, TItem>, index: number) => {
        // Skip elements without key specified (e.g. code comment blocks). Column key is required.
        const stringKey = getStringKey(child as unknown as React.ReactElement);
        if (stringKey === null || keys.indexOf(stringKey) === -1) {
            return;
        }

        const { hidden, resizable, sortable, cellRenderer, searchRenderer, headerRenderer, ...restColumnProps }
            = child.props;
        if (searchRenderer !== undefined) {
            hasSearchColumns = true;
        }

        const key = keys[index];
        const customCellHeaderRenderer = customizeCellHeader(
            searchHeaderIndex,
            headerRenderer,
            searchRenderer);

        const [isHidden, setIsHidden] = useState(visibleColumns !== undefined ? isColumnHidden(key, visibleColumns) : false);
        useEffect(() => setIsHidden(visibleColumns !== undefined ? isColumnHidden(key, visibleColumns) : false), [visibleColumns]);

        const options = {
            key,
            title: restColumnProps.title,
            hidden: isHidden,
            toggleHidden: () => setIsHidden(prevState => {
                options.hidden = !prevState;
                if (options.hidden) {
                    resetColumnSearch(key);
                }

                return options.hidden;
            })
        };
        columnOptions.push(options);

        customColumns.push(<BaseTableColumn
            {...restColumnProps}
            key={key}
            resizable={resizable !== undefined ? resizable : true}
            sortable={sortable !== undefined ? sortable : true}
            hidden={isHidden}
            cellRenderer={cellRenderer || DefaultCellRenderer}
            headerRenderer={customCellHeaderRenderer}
            renderContext={renderContext}
             />);
    });

    return [customColumns, hasSearchColumns, columnOptions];
}
