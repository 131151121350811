import { client } from "src/shared/client";
import { GetGoogleCrawlingResultAvailableCountries, GetGoogleCrawlingResults } from "src/shared/dtos";
import { ExcludeMethods, serializeDate } from "src/shared/helpers";

export type ListRequest = Omit<ExcludeMethods<GetGoogleCrawlingResults>,
"addedAtStartDateSearch" | "addedAtEndDateSearch" | "updatedAtStartDateSearch" | "updatedAtEndDateSearch"> & {
    addedAtStartDateSearch?: Date,
    addedAtEndDateSearch?: Date,
    updatedAtStartDateSearch?: Date,
    updatedAtEndDateSearch?: Date
};

export const CrawlingAppsApi = {
    list: async (request: ListRequest) => {
        const {
            addedAtStartDateSearch,
            addedAtEndDateSearch,
            updatedAtStartDateSearch,
            updatedAtEndDateSearch,
            ...restRequest
        } = request;
        return client.get(new GetGoogleCrawlingResults({
            addedAtStartDateSearch: serializeDate(addedAtStartDateSearch),
            addedAtEndDateSearch: serializeDate(addedAtEndDateSearch),
            updatedAtStartDateSearch: serializeDate(updatedAtStartDateSearch),
            updatedAtEndDateSearch: serializeDate(updatedAtEndDateSearch),
            ...restRequest
        }));
    },
    getCountries: async () => client.get(new GetGoogleCrawlingResultAvailableCountries())
};

export default CrawlingAppsApi;