import React, { forwardRef, useCallback, useImperativeHandle } from "react";
import { Button, Form, FormGroup, Input, Row, Col } from "reactstrap";
import { FeaturedApplicationLocalization } from "src/shared/dtos";
import { isNullOrUndefined, setFromInput, useValidate, ValidateAsyncRef } from "src/shared/helpers";
import { EditField, Image, ImageSelector, LoadingButton } from "src/shared/components";
import { localizationValidationSchema, localizationImagesValidationSchema } from "./validationSchema";

interface Props {
    value: FeaturedApplicationLocalization;
    initial?: FeaturedApplicationLocalization;
    isNewApp: boolean;
    update: (value: Partial<FeaturedApplicationLocalization>) => void;
    remove: () => void;
    saving: boolean;
    onSubmit: () => void;
    isLastLocalization: boolean;
}

export const EditLocalization = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const setDescription = (v: string) => props.update({ description: v ? v : undefined });
    const setBannerUrl = (v: string) => props.update({ bannerUrl: v });
    const setLogoUrl = (v: string) => props.update({ logoUrl: v });

    const { validate, errors, register } = useValidate({ schema: localizationValidationSchema, value: props.value });
    const { validate: validateImages, errors: warnings } = useValidate({ schema: localizationImagesValidationSchema, value: props.value, isActive: true });

    useImperativeHandle(ref, () => ({
        validate: async () => {
            const isValid = (await validate(true)).isValid;
            validateImages();
            return isValid;
        }
    }), [validate, validateImages]);

    const setBanner = useCallback(
        (url: string) => {
            setBannerUrl(url);
            return "";
        }, []);
    const setLogo = useCallback(
        (url: string) => {
            setLogoUrl(url);
            return "";
        }, []);

    const renderBanner = useCallback((url: string): Image | null => !url ? null : ({ url }), []);
    const renderLogo = useCallback((url: string): Image | null => isNullOrUndefined(url) ? null : ({ url }), []);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <Form className="mt-3" onSubmit={save} noValidate>
            <Row>
                <EditField
                    ref={register("description")}
                    fullWidth
                    label="Description"
                    fieldId="description"
                    isChanged={props.value.description !== props.initial?.description}
                    originalValue={props.isNewApp ? undefined : props.initial?.description}
                >
                    <Input
                        type="text"
                        id="description"
                        value={props.value.description ?? ""}
                        onChange={setFromInput(setDescription)}
                        disabled={props.saving}
                    />
                </EditField>
                <EditField
                    fullWidth
                    ref={register("bannerUrl")}
                    label="Banner URL"
                    fieldId="bannerUrl"
                    isChanged={props.value.bannerUrl !== props.initial?.bannerUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.bannerUrl}
                    warnings={warnings?.bannerUrl}
                    errors={errors?.bannerUrl}
                >
                    <ImageSelector
                        setInputId="bannerUrl"
                        value={props.value.bannerUrl}
                        set={setBanner}
                        delete={setBanner}
                        render={renderBanner}
                        imageDimensions={{ width: "12rem" }}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    fullWidth
                    ref={register("logoUrl")}
                    label="Logo URL"
                    fieldId="logoUrl"
                    isChanged={props.value.logoUrl !== props.initial?.logoUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.logoUrl}
                    warnings={warnings?.logoUrl}
                    errors={errors?.logoUrl}
                >
                    <ImageSelector
                        setInputId="logoUrl"
                        value={props.value.logoUrl}
                        set={setLogo}
                        delete={setLogo}
                        render={renderLogo}
                        imageDimensions={{ width: "12rem" }}
                        disabled={props.saving} />
                </EditField>
            </Row>
            <Row className="mt-4">
                <Col xs={12} className="text-right">
                    <FormGroup>
                        <Button
                            type="button"
                            color="danger"
                            className="mr-2"
                            title={props.isLastLocalization ? "Unable to remove last localization." : undefined}
                            disabled={props.isLastLocalization || props.saving}
                            onClick={props.remove}>
                            Remove localization
                        </Button>
                        <LoadingButton
                            loading={props.saving}
                            loadingText={"Saving..."}
                            position="end"
                            type="submit"
                            color="primary">
                            {props.isNewApp ? "Add" : "Save"} application
                        </LoadingButton>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
});