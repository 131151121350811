import React from "react";
import { Row, Col, Alert, Button, FormGroup } from "reactstrap";

export type EditErrorOptions = { text: string, actionText: string, action: () => void };

export const EditError = (props: { error: EditErrorOptions }) => {
    return (
        <Row>
            <Col
                xl={{ size: 4, offset: 4 }}
                lg={{ size: 6, offset: 3 }}
                md={{ size: 8, offset: 2 }}>
                <Alert color="danger" className="text-center">
                    {props.error.text}
                </Alert>
                <FormGroup className="float-right">
                    <Button type="button" color="primary" onClick={props.error.action}>
                        {props.error.actionText}
                    </Button>
                </FormGroup>
            </Col>
        </Row>
    );
};