import React, { useCallback, forwardRef, useImperativeHandle} from "react";
import { AdBrowserUnit } from "src/shared/dtos";
import { highlightTargetUrl, StyledCodeEditor, TargetUrlPlaceholdersTip } from "./EditForm";
import { EditField } from "src/shared/components";
import { useValidate, ValidateAsyncRef} from "src/shared/helpers";
import { browserUnitValidationSchema } from "./validationSchema";

interface Props {
    value: AdBrowserUnit;
    initial: AdBrowserUnit;
    update: React.Dispatch<Partial<AdBrowserUnit> | undefined>;
    saving: boolean;
}

export const EditBrowserUnit = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const { value, initial, update, saving } = props;

    const setUrl = useCallback(
        (url: string) => update({ url }), [update]);

    const { validate, errors, register } = useValidate({ schema: browserUnitValidationSchema, value: props.value });

    useImperativeHandle(ref, () => ({
        validate: async () => (await validate(true)).isValid
    }), [validate]);

    return (
        <React.Fragment>
            <EditField
                ref={register("url")}
                label="Target URL"
                fieldId="browserUrl"
                isChanged={value.url !== initial.url}
                errors={errors?.url}
            >
                <StyledCodeEditor
                    id="browserUrl"
                    value={value.url ?? ""}
                    onValueChange={setUrl}
                    highlight={highlightTargetUrl}
                    padding={10}
                    tabSize={4}
                    insertSpaces={true}
                    disabled={saving}
                />
                <TargetUrlPlaceholdersTip />
            </EditField>
        </React.Fragment>
    );
});