import React, { useMemo, forwardRef, useState, useRef, useImperativeHandle, useEffect } from "react";
import { Form, Input, Button } from "reactstrap";
import { Category, GoogleApplication, GoogleAdditionalFile, GamestoreApplication } from "src/shared/dtos";
import { formatAsAbbreviation, setFromInput, SelectOption, validateForm, ValidateAsyncRef, FormErrors, useValidate } from "src/shared/helpers";
import { HorizontalField, Select, LoadingButton, overriddenProperty } from "src/shared/components";
import { CategorySelectOption, mapCategorySelectOption } from "src/shared/helpers/mapCategorySelectOption";
import styled from "styled-components";
import { CategoryType } from "src/shared/CategoryType";
import { Styles } from "react-select";
import { GoogleAppMainInfo } from "./googleApplicationReducer";
import { classNames } from "@servicestack/client";
import { applicationValidationSchema } from "./validationSchema";

export const StyledInput = styled(Input) <{ exists: number }>`
    background: ${props => props.exists === 1 ? "lightyellow" : ""};

    :focus {
        background: ${props => props.exists === 1 ? "lightyellow" : ""};
    }
`;

const customStyles: Partial<Styles> = {
    menu: (styles: any) => ({
        ...styles,
        backgroundColor: "white"
    }),
    control: (styles: any, state: any) => ({
        ...styles,
        backgroundColor: state.selectProps.exists ? "lightyellow" : ""
    })
};

const defaultAvailableInstallsCounts = [
    0, 1, 10, 50, 100, 500,
    1000, 5000, 10000, 50000, 100000, 500000,
    1000000, 5000000, 10000000, 50000000, 100000000,
    1000000000, 5000000000, 10000000000, 50000000000, 100000000000,
    1000000000000, 5000000000000, 10000000000000, 50000000000000, 100000000000000
];

const defaultAvailableReviewsCounts = [
    0, 1, 10, 50, 100, 500,
    1000, 5000, 10000, 50000, 100000, 500000,
    1000000, 5000000, 10000000, 50000000, 100000000,
    1000000000, 5000000000, 10000000000, 50000000000, 100000000000,
    1000000000000, 5000000000000, 10000000000000, 50000000000000, 100000000000000
];

interface Props {
    value: GoogleAppMainInfo;
    initial?: GoogleAppMainInfo;
    isNewApp: boolean;
    categories: Category[];
    gamestoreApp: GamestoreApplication;
    setGoogleApplication: React.Dispatch<GoogleAppMainInfo>;
    update: React.Dispatch<Partial<GoogleAppMainInfo> | undefined>;
    saving: boolean;
    onSubmit: () => void;
}

export const EditForm = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const validCategories = useMemo(() => props.categories.filter(c =>
        [CategoryType.Game, CategoryType.App].includes(c.parentId!) && !c.isVirtual),
        [props.categories]);

    const { validate, errors, register } = useValidate({ schema: applicationValidationSchema, value: props.value });

    useImperativeHandle(ref, () => ({
        validate: async () => (await validate(true)).isValid
    }), [validate]);

    const availableInstallsCounts = useMemo(
        () => {
            const currentInstallCount = props.value.installsCount;
            return defaultAvailableInstallsCounts.indexOf(currentInstallCount) === -1
                ? defaultAvailableInstallsCounts.concat(currentInstallCount).sort((a, b) => a - b)
                : defaultAvailableInstallsCounts;
        }, [props.value.installsCount]);

    const availableReviewsCounts = useMemo(
        () => {
            const currentReviewsCount = props.value.reviewsCount;
            return (currentReviewsCount !== undefined && defaultAvailableReviewsCounts.indexOf(currentReviewsCount) === -1)
                ? defaultAvailableReviewsCounts.concat(currentReviewsCount).sort((a, b) => a - b)
                : defaultAvailableReviewsCounts;
        }, [props.value.reviewsCount]);

    const selectInstallsCountOptions = availableInstallsCounts.map(value => ({ value, label: formatAsAbbreviation(value) }));
    const selectReviewsCountOptions = availableReviewsCounts.map(value => ({ value, label: formatAsAbbreviation(value) }));

    const selectedInstallsCount = useMemo(() =>
        selectInstallsCountOptions.find(o => o.value === props.value.installsCount),
        [props.value.installsCount, availableInstallsCounts]);

    const selectedReviewsCount = useMemo(() =>
        selectReviewsCountOptions.find(o => o.value === props.value.reviewsCount),
        [props.value.reviewsCount, availableReviewsCounts]);

    const categoryOptions = useMemo(
        () => validCategories.map(c => mapCategorySelectOption(c, validCategories)),
        [validCategories]);

    const selectedCategoryOptions = useMemo(() => {
        const category = validCategories.find(c => c.id === props.value.categoryId);
        return category !== undefined ? mapCategorySelectOption(category, validCategories) : undefined;
    }, [props.value.categoryId, validCategories]);

    const setAppId = (v: string) => props.update({ id: v ? v : undefined });
    const setRating = (v: number) => props.update({ rating: v ? v : undefined });
    const setInstallsCount = (v: SelectOption<number | undefined>) => props.update({ installsCount: v ? v.value : undefined });
    const setReviewsCount = (v: SelectOption<number | undefined>) => props.update({ reviewsCount: v ? v.value : undefined });
    const setCategory = (v: CategorySelectOption) => props.update({ categoryId: v === null ? "" : v.value });
    const setSdkVersion = (v: number) => props.update({ sdkVersion: v ? v : undefined });

    useEffect(() => {
        if (props.value.reviewsCount === undefined) {
            props.update({ reviewsCount: 0 });
        }
    }, [props.value.reviewsCount, props.update]);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <Form onSubmit={save} noValidate>
            <HorizontalField className="text-right">
                <LoadingButton
                    loading={props.saving}
                    loadingText="Saving..."
                    position="end"
                    type="submit"
                    color="primary"
                    className="ml-2 mb-2">
                    {props.isNewApp ? "Add" : "Save"} application
                </LoadingButton>
            </HorizontalField>
            {props.isNewApp &&
                <React.Fragment>
                    <HorizontalField
                        ref={register("id")}
                        label="Application ID"
                        fieldId="appId"
                        errors={errors?.id}>
                        <Input
                            id="appId"
                            type="text"
                            value={props.value.id ?? ""}
                            onChange={setFromInput(setAppId)}
                            disabled={props.saving}
                        />
                    </HorizontalField>
                </React.Fragment>}
            <HorizontalField
                label={overriddenProperty("Rating", props.gamestoreApp?.rating !== undefined)}
                fieldId="rating"
                isChanged={props.value.rating !== props.initial?.rating}
                originalValue={props.isNewApp ? 0 : props.initial?.rating}>
                <StyledInput
                    className="form-control"
                    exists={props.gamestoreApp?.rating !== undefined ? 1 : 0}
                    type="number"
                    id="rating"
                    min="0"
                    max="5"
                    step="0.01"
                    value={props.value.rating ?? 0}
                    placeholder={props.value.rating + ""}
                    onChange={setFromInput(setRating)}
                    disabled={props.saving}
                />
            </HorizontalField>
            <HorizontalField
                label={overriddenProperty("Installs count", props.gamestoreApp?.installsCount !== undefined)}
                fieldId="installsCount"
                isChanged={props.value.installsCount !== props.initial?.installsCount}
                originalValue={props.isNewApp ? undefined : props.initial?.installsCount
                    ? formatAsAbbreviation(props.initial.installsCount)
                    : undefined}>
                <Select
                    exists={props.gamestoreApp?.installsCount !== undefined}
                    id="installsCount"
                    value={selectedInstallsCount}
                    isSearchable={false}
                    onChange={setInstallsCount}
                    isDisabled={props.saving}
                    options={selectInstallsCountOptions}
                    styles={customStyles}
                />
            </HorizontalField>
            <HorizontalField
                label={overriddenProperty("Reviews count", props.gamestoreApp?.reviewsCount !== undefined)}
                fieldId="reviewsCount"
                isChanged={props.value.reviewsCount !== props.initial?.reviewsCount}
                originalValue={props.isNewApp ? undefined : props.initial?.reviewsCount
                    ? formatAsAbbreviation(props.initial.reviewsCount)
                    : undefined}>
                <Select
                    exists={props.gamestoreApp?.reviewsCount !== undefined}
                    id="reviewsCount"
                    value={selectedReviewsCount}
                    isSearchable={false}
                    onChange={setReviewsCount}
                    isDisabled={props.saving}
                    options={selectReviewsCountOptions}
                    styles={customStyles}
                />
            </HorizontalField>
            <HorizontalField
                ref={register("category")}
                label={overriddenProperty("Category", (props.gamestoreApp?.categories?.length ?? 0) !== 0)}
                fieldId="categories"
                isChanged={props.value.categoryId !== props.initial?.categoryId}
                originalValue={props.initial?.categoryId}
                errors={errors?.category}>
                <Select
                    classNamePrefix="inner"
                    exists={(props.gamestoreApp?.categories?.length ?? 0) !== 0}
                    id="categories"
                    value={selectedCategoryOptions}
                    onChange={setCategory}
                    placeholder={props.value.category.name}
                    options={categoryOptions}
                    isMulti={false}
                    isSearchable={true}
                    isDisabled={props.saving}
                    isInvalid={(errors?.category?.length ?? 0) > 0}
                />
            </HorizontalField>
            <HorizontalField
                label="SDK Version"
                fieldId="sdkVersion"
                isChanged={props.value.sdkVersion !== props.initial?.sdkVersion}>
                <Input
                    type="number"
                    id="sdkVersion"
                    value={props.value.sdkVersion}
                    placeholder={props.value.sdkVersion + ""}
                    onChange={setFromInput(setSdkVersion)}
                    disabled={props.saving}
                />
            </HorizontalField>
        </Form>
    );
});