import React, { FC, useCallback, useState, useEffect, useMemo } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ChartWidgetUnit, InfoWidgetUnit, ListWidgetUnit, WidgetTypes, WidgetUnit } from "src/shared/dtos";
import { EditChartWidget } from "./EditChartWidget";
import { EditInfoWidget } from "./EditInfoWidget";
import { EditListWidget } from "./EditListWidget";

export interface WidgetModalProps {
    isOpen: boolean;
    widget: WidgetUnit | null;
    save: (val: WidgetUnit) => void;
    close: () => void;
}

export const WidgetModal: FC<WidgetModalProps> = (props) => {
    const { isOpen, close, save, widget } = props;
    const [current, setCurrent] = useState<WidgetUnit | null>(null);

    const header = useMemo(() => {
        if (widget?.widgetType === WidgetTypes.ChartWidget) {
            return "Chart Options";
        }
        if (widget?.widgetType === WidgetTypes.InfoWidget) {
            return "Application Information";
        }
        if (widget?.widgetType === WidgetTypes.ListWidget) {
            return "Table Widget Options";
        }
        return "";
    }, [widget?.widgetType]);

    useEffect(() => {
        setCurrent(widget);
    }, [widget]);

    const saveWidget = useCallback(() => {
        if (current) {
            save(current);
        }
        close();
    }, [save, current]);

    const update = useCallback((val: Partial<WidgetUnit>) => {
        setCurrent(prev => prev === null ? prev : Object.assign({}, prev, val));
    }, []);

    const updateInfoWidget = useCallback((
        value: Partial<InfoWidgetUnit>) => {
        update({
            infoWidget: { ...current?.infoWidget!, ...value }
        });
    }, [current?.infoWidget, update]);

    const updateChartWidget = useCallback((
        value: Partial<ChartWidgetUnit>) => {
        update({
            chartWidget: { ...current?.chartWidget!, ...value }
        });
    }, [current?.chartWidget, update]);

    const updateListWidget = useCallback((
        value: Partial<ListWidgetUnit>) => {
        update({
            listWidget: { ...current?.listWidget!, ...value }
        });
    }, [current?.listWidget, update]);

    const closeModal = useCallback(() => {
        setCurrent(widget);
        close();
    }, [close, widget]);

    return (
        <Modal isOpen={isOpen} toggle={closeModal} centered>
            <ModalHeader toggle={close}>{header}</ModalHeader>
            <ModalBody>
                {widget?.widgetType === WidgetTypes.InfoWidget && <EditInfoWidget value={current?.infoWidget} update={updateInfoWidget} />}
                {widget?.widgetType === WidgetTypes.ChartWidget && <EditChartWidget value={current?.chartWidget} update={updateChartWidget} />}
                {widget?.widgetType === WidgetTypes.ListWidget && <EditListWidget value={current?.listWidget} update={updateListWidget} />}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={saveWidget}>
                    Save
                </Button>{" "}
                <Button color="secondary" onClick={closeModal}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>);
};