import React, { FC } from "react";
import { ChartTypes, ChartWidgetDataTypes, ChartWidgetOptions, ChartWidgetTimeSpan, ChartWidgetUnit } from "src/shared/dtos";
import styled from "styled-components";
import { setFromInput, splitCamelCase } from "src/shared/helpers";
import { Input, Label, FormGroup } from "reactstrap";
import { ChartWidgetAdditionalOptions } from "./ChartWidgetAdditionalOptions";

const EditForm = styled.div`
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

type EditChartWidgetProps = {
    update: (val: Partial<ChartWidgetUnit>) => void;
    value?: ChartWidgetUnit;
};

const allChartTypes = Object.values(ChartTypes);
const allDataTypes = Object.values(ChartWidgetDataTypes);
const allTimeSpans = Object.values(ChartWidgetTimeSpan);

export const EditChartWidget: FC<EditChartWidgetProps> = ({ value, update }) => {

    const updateChartType = (val: ChartTypes) => update({ chartType: val });
    const updateDataType = (val: ChartWidgetDataTypes) => update({ dataType: val });
    const updateTimeSpan = (val: ChartWidgetTimeSpan) => update({ timeSpan: val });
    const updateOptions = (val: ChartWidgetOptions) => update({ options: val });

    return (
        <>
            <EditForm>
                <FormGroup>
                    <Label for="dataType">
                        Select Data Type
                    </Label>
                    <Input
                        id="dataType"
                        name="dataType"
                        value={value?.dataType}
                        onChange={setFromInput(updateDataType)}
                        type="select"
                    >
                        {allDataTypes.map(t => <option key={t} value={t}>{splitCamelCase(t)}</option>)}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="chartType">
                        Select Chart Type
                    </Label>
                    <Input
                        id="chartType"
                        name="chartType"
                        value={value?.chartType}
                        onChange={setFromInput(updateChartType)}
                        type="select"
                    >
                        {allChartTypes.map(t => <option key={t} value={t}>{splitCamelCase(t)}</option>)}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="timeSpan">
                        Select Time Span
                    </Label>
                    <Input
                        id="timeSpan"
                        name="timeSpan"
                        value={value?.timeSpan}
                        onChange={setFromInput(updateTimeSpan)}
                        type="select"
                    >
                        {allTimeSpans.map(t => <option key={t} value={t}>{t}</option>)}
                    </Input>
                </FormGroup>
            </EditForm>
            {value !== undefined &&
                <ChartWidgetAdditionalOptions
                    updateOptions={updateOptions}
                    chartWidget={value}
                />}
        </>);
};