import { CellData } from ".";

export function makeSelect<TItem>()
    : (selector: (item: TItem) => any) =>
        (props: CellData<unknown, TItem>) => JSX.Element {
    return select;
}

export function select<TItem>(selector: (item: TItem) => any): (props: CellData<unknown, TItem>) => JSX.Element {
    return p => selector(p.rowData);
}