import { UrlValidationType } from "src/shared/dtos";

const isUrlValid = (value: string) => {
    try {
        const url = new URL(value, document.baseURI);
        return url !== undefined;
    } catch {
        return false;
    }
};

export const validateUrl = (value: string, type: UrlValidationType): string | null => {
    if (/\s/.test(value)){
        return "URL can not contain spaces.";
    }

    const isValid = isUrlValid(value);
    if (!isValid) {
        return "Invalid URL format.";
    }

    const isAbsolute = value.indexOf("://") > 0 || value.indexOf("//") === 0;

    switch (type) {
        case UrlValidationType.RelativeOrAbsolute:
            return null;
        case UrlValidationType.Absolute:
            return isAbsolute ? null : "URL should be absolute.";
        case UrlValidationType.Relative:
            return isAbsolute ? "URL should be relative." : null;
        default:
            throw new Error(`Unknown URL validation type: '${type}'.`);
    }
};
