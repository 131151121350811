import React, { FC, useState, useEffect, useRef, useCallback } from "react";
import { HorizontalField, NavTabs } from "src/shared/components";
import { CloseButton, CloseIcon, ControlButtonsBlock, DownloaderPreviewBlock, PreviewContainer, PreviewFooter, PreviewHeader, LeftCornerIcon, MinimizeIcon, PreviewImage } from "./components";
import { PageContainer } from "../../components";
import { Col, FormGroup, Input, Label } from "reactstrap";
import { PromptModal } from "./PromptModal";
import { MainFooter } from "./MainFooter";
import { ProgressFooter } from "./ProgressFooter";

enum Warning {
    Off = 0,
    HyperV = 1,
    Restart = 2,
}

const hyperVWindowText =
    "Your PC is using Hyper-V which requires few more components in order " +
    "to run games. Do you want to install these components automatically?";

const restartWindowText = "The components have been installed. Please restart your computer.";

interface Props {
    downloaderImages: string[] | undefined;
    installationLeftCornerIconUrl: string | undefined;
    promptHeaderText: string | undefined;
}

export const DownloaderView: FC<Props> = ({ downloaderImages, installationLeftCornerIconUrl, promptHeaderText }) => {
    const imageSlideInterval = 5000;

    const [page, setPage] = useState(0);
    const [warning, setWarning] = useState<Warning>(Warning.Off);
    const [imageIdx, setImageIdx] = useState(0);
    const imagesInterval = useRef<ReturnType<typeof setInterval>>();

    const hideWarning = useCallback(() => setWarning(Warning.Off), []);
    const showHyperV = useCallback(() => setWarning(Warning.HyperV), []);
    const showRestart = useCallback(() => setWarning(Warning.Restart), []);

    useEffect(() => {
        if (imagesInterval.current) {
            clearInterval(imagesInterval.current);
        }
        if (downloaderImages && downloaderImages.length > 1) {
            imagesInterval.current = setInterval(() => {
                setImageIdx(prevIndex => prevIndex === downloaderImages.length - 1 ? 0 : prevIndex + 1);
            }, imageSlideInterval);
        } else {
            setImageIdx(0);
        }
        return (() => {
            if (imagesInterval.current) {
                clearInterval(imagesInterval.current);
            }
        });
    }, [downloaderImages, imageSlideInterval, imagesInterval.current]);

    return (
        <>
            <NavTabs links={["Main", "Progress"]} page={page} setPage={setPage} />
            <PageContainer>
                <DownloaderPreviewBlock>
                    <PreviewContainer>
                        <PreviewHeader>
                            <LeftCornerIcon src={installationLeftCornerIconUrl} />
                            <ControlButtonsBlock>
                                {page === 1 && (
                                    <CloseButton>
                                        <MinimizeIcon />
                                    </CloseButton>
                                )}
                                <CloseButton>
                                    <CloseIcon />
                                </CloseButton>
                            </ControlButtonsBlock>
                        </PreviewHeader>
                        <PreviewImage img={downloaderImages?.[imageIdx] ?? ""}>
                            {warning === Warning.HyperV && (
                                <PromptModal
                                    iconUlr={installationLeftCornerIconUrl ?? ""}
                                    text={hyperVWindowText}
                                    yesBtnText="Yes"
                                    noBtnText="No"
                                    headerText={promptHeaderText}
                                />
                            )}
                            {warning === Warning.Restart && (
                                <PromptModal
                                    iconUlr={installationLeftCornerIconUrl ?? ""}
                                    text={restartWindowText}
                                    yesBtnText="Restart now"
                                    noBtnText="Restart later"
                                    headerText={promptHeaderText}
                                />
                            )}
                        </PreviewImage>
                        <PreviewFooter>
                            {page === 0 && <MainFooter />}
                            {page === 1 && <ProgressFooter />}
                        </PreviewFooter>
                    </PreviewContainer>
                </DownloaderPreviewBlock>
                <Col md={6} className="mt-4">
                    <HorizontalField style={{ position: "relative", right: "26%" }}>
                        <FormGroup check>
                            <Label for="warningOff" check>
                                <Input
                                    type="radio"
                                    id="warningOff"
                                    name="showWarning"
                                    onChange={hideWarning}
                                    checked={warning === Warning.Off}
                                />{"No warning"}
                            </Label>
                        </FormGroup>
                    </HorizontalField>
                    <HorizontalField style={{ position: "relative", right: "26%" }}>
                        <FormGroup check>
                            <Label for="showHyperVWindow" check>
                                <Input
                                    type="radio"
                                    id="showHyperVWindow"
                                    name="showWarning"
                                    onChange={showHyperV}
                                    checked={warning === Warning.HyperV}
                                />{"Show Hyper-V warning window"}
                            </Label>
                        </FormGroup>
                    </HorizontalField>
                    <HorizontalField style={{ position: "relative", right: "26%" }}>
                        <FormGroup check>
                            <Label for="showRestartWindow" check>
                                <Input
                                    type="radio"
                                    id="showRestartWindow"
                                    name="showWarning"
                                    onChange={showRestart}
                                    checked={warning === Warning.Restart}
                                />{"Show restart window"}
                            </Label>
                        </FormGroup>
                    </HorizontalField>
                </Col>
            </PageContainer>
        </>
    );
};