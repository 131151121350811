import { queryString as serviceStackQueryString } from "@servicestack/client";
import { History, Location, createPath } from "history";

/**
 * Parses URL query parameters.
 * @param url The URL.
 * @returns The key-value object that contains query parameters.
 */
export const queryString = (url: string) =>
    serviceStackQueryString(url) as { [key: string]: string };

/**
 * Adds or updates the URL query parameters based on input object.
 * @param url The input URL.
 * @param query The URL query parameters.
 * @returns The URL with updated query parameters.
 */
export const makeUrl = (url: string, query: { [name: string]: string | undefined }) => {
    const isRelative = url.indexOf("://") === -1;
    const value = new URL(url, isRelative ? "http://fake.url/" : undefined);

    for (const name of Object.keys(query)) {
        const param = query[name];
        if (param !== undefined) {
            value.searchParams.append(name, param);
        }
    }

    return "" + (isRelative ? `${value.pathname}${value.search}${value.hash}` : value);
};

/**
 * Represents query parameter serialization/deserialization information.
 */
export type QueryParameterDescriptor<T = string> = {
    /** @member {T} - Query parameter type. */
    paramType?: T;

    /** @member {string} - Query parameter name. */
    paramName: string;

    /**
     * @member {"string" | "number" | "boolean" | ((value: string) => unknown)} - Default or custom serializer.
     * @default "string"
     */
    serializer?: "string" | "number" | "boolean" | ((value: unknown) => string | undefined);

    /**
     * @member {"string" | "number" | "boolean" | ((value: string) => unknown)} - Default or custom deserializer.
     * @default "string"
     */
    deserializer?: "string" | "number" | "boolean" | ((value: string) => unknown);
};
type QPD<T = string> = QueryParameterDescriptor<T>;

/**
 * Represents query parameter.
 */
export type QueryParameterResult<T = string> = {
    /** @member {T} - Query parameter type. */
    paramType?: T;

    /** @member {string} - Query parameter name. */
    paramName: string;

    /** @member {unknown} - Query parameter value. */
    value: unknown;
};
type QPR<T = string> = QueryParameterResult<T>;

/**
 * Serializes query provided parameters to their string name/value pairs from descriptors.
 * @param {string} url - The URL.
 * @param {QueryParameterDescriptor[]} descriptors - The query parameter descriptors.
 */
export function serializeQueryParameters<T = string>(params: object, ...descriptors: QPD<T>[]): { [name: string]: string } {
    const entries = Object.entries(params);
    const resultEntries = descriptors
        .map(d => {
            const found = entries.find(e => e[0] === d.paramName)?.[1];
            const serializer = d.serializer ?? "string";
            let serialized: string | undefined;
            switch (serializer) {
                case "string":
                case "number": {
                    serialized = found !== undefined ? found + "" : undefined;
                    break;
                }
                case "boolean": {
                    serialized = found === true
                        ? "true"
                        : found === false
                            ? "false"
                            : undefined;
                    break;
                }
                default: {
                    serialized = serializer(found);
                    break;
                }
            }

            return serialized !== undefined
                ? [d.paramName, serialized] as [string, string]
                : undefined;
        })
        .filter(e => e !== undefined) as [string, string][];

    return Object.fromEntries(resultEntries);
}

/**
 * Deserializes query parameters provided with URL to their value from descriptors.
 * @param {string} url - The URL.
 * @param {QueryParameterDescriptor[]} descriptors - The query parameter descriptors.
 */
export function deserializeQueryParameters<T = string>(url: string, ...descriptors: QPD<T>[]): QPR<T>[] {
    const params = queryString(url);
    return descriptors
        .map(d => {
            const result: QPR<T> = {
                paramType: d.paramType,
                paramName: d.paramName,
                value: params[d.paramName]
            };
            if (result.value === undefined) {
                return undefined;
            }

            const deserializer = d.deserializer ?? "string";
            switch (deserializer) {
                case "string": {
                    result.value = (result.value as string).charAt(0).toLowerCase() + (result.value as string).substr(1);
                    break;
                }
                case "number": {
                    result.value = +(result.value as string);
                    break;
                }
                case "boolean": {
                    result.value = (result.value as string) === "true"
                        ? true
                        : (result.value as string) === "false"
                            ? false
                            : undefined;
                    break;
                }
                default: {
                    result.value = deserializer(result.value as string);
                    break;
                }
            }

            return result.value !== undefined ? result : undefined;
        })
        .filter(d => d !== undefined) as QPR<T>[];
}

/**
 * Push URL to history if it isn't equal to current URL.
 * @param location - The location object.
 * @param history - The history object.
 * @param url - The URL to push.
 */
export function pushUrl<TState>(location: Location<TState>, history: History<TState>, url: string): void {
    const locationUrl = createPath(location);
    if (locationUrl !== url) {
        history.push(url);
    }
}