import React, { useEffect, useState, useReducer, useMemo, useCallback, useRef, } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col } from "reactstrap";
import { defer, forkJoin, noop, of } from "rxjs";
import { OrderDirection, ProductVersionInfo, ProductVersionInfoListDataType } from "src/shared/dtos";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    LeavingViewProtector,
    Loader,
    DocumentTitle,
    RouteLink,
    RouteUserProps,
    EditError,
    EditErrorOptions,
    notifySuccess
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { structuredClone, useFunctionState } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { editReducer, editReducerDefault } from "./editReducer";
import api from "../api";
import { EditForm, EditFormRef } from "./EditForm";

type Props = RouteComponentProps<{ id: string }> & RouteUserProps;

const makeEmpty = (): ProductVersionInfo => {
    return {
        id: 0,
        version: "1.0.0.0",
        changelogMarkdown: "",
        isDisabled: true,
        isMandatory: false,
        windowsInstallerUrlX86: "",
        windowsInstallerUrlX64: "",
        windowsInstallerUrlX64AndroidX64: undefined,
        settings: {
            sources: []
        }
    };
};

export const EditView = withRouter(({ match, history, user }: Props) => {
    const id = match.params.id !== undefined ? parseInt(match.params.id, 10) : 0;
    const isNew = useMemo(() => id === 0, [id]);

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);

    const [isNewSaved, setIsNewSaved] = useState(!isNew);
    const [sources, setSources] = useState<string[] | undefined>([]);
    const [error, setError] = useState<EditErrorOptions | undefined>();
    const [saving, setSaving] = useState(false);
    const [data, changeData] = useReducer(editReducer, editReducerDefault);

    const editFormRef = useRef<EditFormRef>(null);

    const backToList = useCallback(() => history.push(routes.productVersions.url()), []);

    const loadData = () => {
        const showNotFoundError = () => setError({
            text: "Unable to load Product Version.",
            actionText: "Back to list",
            action: backToList
        });

        const subscription = forkJoin([
            isNew ? of(undefined) : defer(() => api.get(id)),
            defer(() => api.getSourceSettings()),
            defer(() => api.list({ orderBy: ProductVersionInfoListDataType.Version, orderDirection: OrderDirection.Desc, offset: 0, limit: 1 }))
        ]).subscribe({
            next: results => {
                if (!isNew && results[0]?.id === undefined) {
                    showNotFoundError();
                    return;
                }

                setDataValues(results[0], results[2][0]?.version);
                setSources(results[1].map(ss => ss.source));
            },
            error: showNotFoundError
        });
        setUnsubscribe(() => subscription.unsubscribe());
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        // Go to edit view after successful save of the new entity.
        // It is implemented this way to let leaving view protector know, that there is no changes.
        if (isNew && isNewSaved && data.current !== undefined && data.current.id !== 0 && error === undefined) {
            history.push(routes.editProductVersion.url({ id: data.current!.id + "" }));
        }
    }, [isNew, isNewSaved, data.current]);

    const setDataValues = (values?: ProductVersionInfo, lastVersion?: string) => {
        const value = values ?? makeEmpty();
        if (values === undefined && lastVersion !== undefined) {
            value.version = lastVersion;
        }

        const subscription = defer(() => structuredClone(value))
            .subscribe(clone => {
                changeData({ kind: "set", value, initialValue: clone });
                if (isNew && isNewSaved === true) {
                    setIsNewSaved(true);
                }
            });
        setUnsubscribe(() => subscription.unsubscribe());
    };

    const onSave = useCallback(() => {
        if (saving || data.current === undefined || editFormRef.current?.validate() === false) {
            return;
        }

        setSaving(true);
        const request: ProductVersionInfo = { ...data.current };
        const subscription = defer(() => isNew ? api.create(request) : api.update(request)).subscribe({
            next: result => {
                notifySuccess(`Product Version ${isNew ? "added" : "saved"} successfully.`);
                setDataValues(result);
                setSaving(false);

                if (isNew) {
                    setIsNewSaved(true);
                }
            },
            error: () => {
                setSaving(false);
                setError({
                    text: "Unable to save Product Version.",
                    actionText: "Continue",
                    action: () => setError(undefined)
                });
            }
        });
        setUnsubscribe(() => subscription.unsubscribe());
    }, [data, data.current, isNew, saving, editFormRef.current]);

    const onDelete = useCallback(() => {
        if (saving || data.current === undefined) {
            return;
        }

        setSaving(true);
        const subscription = defer(() => api.delete(id)).subscribe({
            next: () => {
                notifySuccess(`Product Version deleted successfully.`);
                setSaving(false);
                history.push(routes.productVersions.url());
            },
            error: () => {
                setSaving(false);
                setError({
                    text: "Unable to delete Product Version.",
                    actionText: "Continue",
                    action: () => setError(undefined)
                });
            }
        });
        setUnsubscribe(() => subscription.unsubscribe());
    }, [id, data.current, isNew, saving]);

    const updateData = useCallback((value: Partial<ProductVersionInfo>) => changeData({ kind: "update", value }), []);

    const title = useMemo(
        () => {
            const titleText = isNew
                ? "[new product version]"
                : (data.current !== undefined
                    ? data.current.version
                    : "Loading...");
            return `Product Version - ${titleText}`;
        },
        [data.current, isNew]);

    return (
        <VerticalBox>
            <DocumentTitle title={title} />
            <LeavingViewProtector showConfirmation={data.changed && (isNew ? !isNewSaved : true)} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.productVersions}>
                                Product Versions
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {isNew ? "[new product version]" : data.current?.version}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    {error && <EditError error={error} />}
                    {!error && (data.current === undefined || sources === undefined) && <Loader />}
                    {!error && data.current !== undefined && sources !== undefined &&
                        <Row>
                            <Col
                                sm={{ offset: 1, size: 10 }}
                                md={{ offset: 2, size: 8 }}
                                lg={{ offset: 2, size: 6 }}
                                xl={{ offset: 3, size: 5 }}
                            >
                            <Row>
                                <Col>
                                    <EditForm
                                        ref={editFormRef}
                                        value={data.current}
                                        initial={data.initial}
                                        source={sources}
                                        update={updateData}
                                        saving={saving}
                                        onSubmit={onSave}
                                        onDelete={onDelete}
                                    />
                                </Col>
                            </Row>
                            </Col>
                        </Row>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});
