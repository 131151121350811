import React from "react";
import { Input } from "reactstrap";
import styled from "styled-components";
import { SearchCellHeaderProps } from "./SearchCellHeaderProps";

const SearchInput = styled(Input)`
    height: 27px;
    padding: 0 7px;
    font-size: 13px;

    &:focus {
        box-shadow: none;
        border-color: #ced4da;
    }
`;

/**
 * Allow search by a string for an infinite table column.
 */
export function StringSearch<TRow>(props: SearchCellHeaderProps<string | undefined, TRow>) {
    const set = (event: React.FormEvent<HTMLInputElement>) =>
        props.setSearchValue(event.currentTarget.value === "" ? undefined : event.currentTarget.value);

    return <SearchInput
        type="text"
        value={props.searchValue ?? ""}
        onChange={set}
        placeholder="Search..." />;
}