import { Tag } from "src/shared/dtos";
import { deepClone, deepEqual } from "src/shared/helpers";

export type TagState = {
    current?: Tag,
    initial?: Tag,
    changed: boolean
};

export type TagAction =
    { kind: "set", value: Tag } |
    { kind: "update", value: Partial<Tag> };

export const tagReducer = (state: TagState, action: TagAction) => {
    switch (action.kind) {
        case "set": {
            state = {
                current: action.value,
                initial: deepClone(action.value),
                changed: false
            };
            break;
        }
        case "update": {
            if (!state.current) {
                break;
            }
            Object.assign(state.current, action.value);
            state.changed = !deepEqual(state.current, state.initial);
            break;
        }
    }

    return Object.assign({}, state);
};

export const makeEmptyTag = (): Tag => {
    return {
        id: "",
        name: "",
        iconUrl: undefined,
        importTagMapping: undefined,
        order: 0,
        showInNavbar: false
    };
};

export const tagReducerDefault: TagState = {
    current: undefined,
    initial: undefined,
    changed: false
};