import React, { useState, useEffect, useRef, useMemo } from "react";
import { RequestStatisticsSummary } from "src/shared/dtos";
import { Container, Row, Col, Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { RequestStatisticsListView } from "./RequestStatisticsListView";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import styled from "styled-components";
import { Loader, StatisticsDatePicker } from "src/shared/components";
import { defer, noop } from "rxjs";
import api from "../api";
import { useFunctionState, useSubscription } from "src/shared/helpers";
import { setFromInput } from "src/shared/helpers";
import { IUser } from "src/shared/client";

interface Props {
    movingAvgDays: number;
    path: string;
    endDate: Date;
    user: IUser | null;
}

const StyledContainer = styled(Container)`
    padding-bottom: 50px;
    .row {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid;
        align-items: center;
    }
`;

const StyledHighcharts = styled.div`
    text.highcharts-credits {
        display:none !important;
    }
`;


export const DetailsComponent = (props: Props) => {
    const defaultStartDate = new Date(props.endDate.toISOString());
    defaultStartDate.setDate(defaultStartDate.getDate() - 30);
    const [startDate, setStartDate] = useState<Date>(defaultStartDate);
    const [endDate, setEndDate] = useState<Date>(props.endDate);
    const [dates, setDates] = useState<string[]>([]);
    const [requestStatisticsList, setRequestStatisticsList] = useState<RequestStatisticsSummary[]>();
    const [movingAvgDays, setMovingAvgDays] = useState<number>(props.movingAvgDays);
    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    const [countData, setCountData] = useState<any[]>([]);
    const [maCountData, setMaCountData] = useState<any[]>([]);
    const [totalMillisecondsData, setTotalMillisecondsData] = useState<any[]>([]);
    const [maTotalMillisecondsData, setMaTotalMillisecondsData] = useState<any[]>([]);
    const [averageMillisecondsData, setAverageMillisecondsData] = useState<any[]>([]);
    const [maAverageMillisecondsData, setMaAverageMillisecondsData] = useState<any[]>([]);
    const [standardDeviationMillisecondsData, setStandardDeviationMillisecondsData] = useState<any[]>([]);
    const [isWrongStartDatePopupOpen, setIsWrongStartDatePopupOpen] = useState(false);
    const [isWrongEndDatePopupOpen, setIsWrongEndDatePopupOpen] = useState(false);

    const toggleWrongStartDatePopup = () => setIsWrongStartDatePopupOpen(v => !v);
    const toggleWrongEndDatePopup = () => setIsWrongEndDatePopupOpen(v => !v);

    const changeStartDate = (date: Date) => {
        if (date <= endDate) {
            setStartDate(date);
        } else {
            toggleWrongStartDatePopup();
        }
    };
    const changeEndDate = (date: Date) => {
        if (date >= startDate) {
            setEndDate(date);
        } else {
            toggleWrongEndDatePopup();
        }
    };

    const endDates = useMemo(() => dates.filter(d => new Date(d) > startDate), [dates, startDate]);
    const startDates = useMemo(() => dates.filter(d => new Date(d) < endDate), [dates, endDate]);

    const updateDates = useSubscription(() => defer(() => api.getDates()).subscribe(setDates), [api.getDates]);

    useEffect(() => {
        updateDates();
    }, []);

    const [optionsState, setOptionsState] = useState<any>(
        {
            title: {
                text: "<strong>Path - </strong> '" + props.path + "'"
            },
            xAxis: {
                type: "datetime",
                maxPadding: 0.1,
                minPadding: 0.1,
                minTickInterval: 24 * 3600 * 1000,
                dateTimeLabelFormats: {
                    day: "%e %b"
                },
            },
            yAxis: [{
                title: {
                    text: "Count",
                },
                labels: {
                    format: "{value}",
                }

            }, {
                title: {
                    text: "Total (ms)",
                },
                labels: {
                    format: "{value}",
                },
                opposite: true

            }, {
                title: {
                    text: "Values (ms)",
                },
                labels: {
                    format: "{value}",
                },
                opposite: true
            }],
            tooltip: {
                valueDecimals: 3
            },
            series: [{
                name: "Count",
                type: "spline",
                tooltip: { valueDecimals: 0 },
                data: countData
            }, {
                name: "Total (ms)",
                type: "spline",
                yAxis: 1,
                data: totalMillisecondsData
            }, {
                name: "Avg (ms)",
                type: "spline",
                yAxis: 2,
                data: averageMillisecondsData
            }, {
                name: "STD (ms)",
                type: "spline",
                yAxis: 2,
                data: standardDeviationMillisecondsData
            }, {
                name: "Moving Avg Count",
                type: "spline",
                visible: false,
                tooltip: { valueDecimals: 0 },
                data: maCountData
            }, {
                name: "Moving Avg Total (ms)",
                type: "spline",
                visible: false,
                yAxis: 1,
                data: maTotalMillisecondsData
            }, {
                name: "Moving Avg Avg (ms)",
                type: "spline",
                visible: false,
                yAxis: 2,
                data: maAverageMillisecondsData
            }]
        });

    const chartRef = useRef(optionsState);
    useEffect(() => {
        setOptionsState({
            ...chartRef.current,
            series: [{
                ...chartRef.current.series[0],
                data: countData
            }, {
                ...chartRef.current.series[1],
                data: totalMillisecondsData
            }, {
                ...chartRef.current.series[2],
                data: averageMillisecondsData
            }, {
                ...chartRef.current.series[3],
                data: standardDeviationMillisecondsData
            }, {
                ...chartRef.current.series[4],
                data: maCountData,
                events: {
                    legendItemClick: () => {
                        chartRef.current.series[4].visible = !chartRef.current.series[4].visible;
                    }
                }
            }, {
                ...chartRef.current.series[5],
                data: maTotalMillisecondsData,
                events: {
                    legendItemClick: () => {
                        chartRef.current.series[5].visible = !chartRef.current.series[5].visible;
                    }
                }
            }, {
                ...chartRef.current.series[6],
                data: maAverageMillisecondsData,
                events: {
                    legendItemClick: () => {
                        chartRef.current.series[6].visible = !chartRef.current.series[6].visible;
                    }
                }
            }]
        });
    }, [
        countData,
        maCountData,
        totalMillisecondsData,
        maTotalMillisecondsData,
        averageMillisecondsData,
        maAverageMillisecondsData,
        standardDeviationMillisecondsData
    ]);

    useEffect(() => unsubscribe, [unsubscribe]);

    useEffect(() => {
        if (startDate === undefined) {
            setStartDate(defaultStartDate);
            return;
        }
        if (startDate === undefined) {
            setEndDate(new Date());
        }

        const subscription = defer(() => api.details(movingAvgDays, props.path, startDate, endDate)).subscribe({
            next: result => {
                setRequestStatisticsList(result);
            }
        });
        return () => subscription.unsubscribe();
    }, [movingAvgDays, props.path, startDate, endDate]);

    const updateSeries = useEffect(() => {
        if (requestStatisticsList === undefined) {
            setCountData([]);
            setMaCountData([]);
            setTotalMillisecondsData([]);
            setMaTotalMillisecondsData([]);
            setAverageMillisecondsData([]);
            setMaAverageMillisecondsData([]);
            setStandardDeviationMillisecondsData([]);
            return;
        }

        setCountData(requestStatisticsList.map(r => {
            const date = new Date(r.date);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return [utcDate.getTime(), r.count];
        }));
        setMaCountData(requestStatisticsList.map(r => {
            const date = new Date(r.date);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return [utcDate.getTime(), r.movingAverageCount];
        }));
        setTotalMillisecondsData(requestStatisticsList.map(r => {
            const date = new Date(r.date);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return [utcDate.getTime(), r.totalMilliseconds];
        }));
        setMaTotalMillisecondsData(requestStatisticsList.map(r => {
            const date = new Date(r.date);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return [utcDate.getTime(), r.movingAverageTotalMilliseconds];
        }));
        setAverageMillisecondsData(requestStatisticsList.map(r => {
            const date = new Date(r.date);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return [utcDate.getTime(), r.averageMilliseconds];
        }));
        setMaAverageMillisecondsData(requestStatisticsList.map(r => {
            const date = new Date(r.date);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return [utcDate.getTime(), r.movingAverageAverageMilliseconds];
        }));
        setStandardDeviationMillisecondsData(requestStatisticsList.map(r => {
            const date = new Date(r.date);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return [utcDate.getTime(), r.standardDeviationMilliseconds];
        }));

    }, [requestStatisticsList]);

    return (
        <React.Fragment>
            {requestStatisticsList === undefined && <Loader />}
            {requestStatisticsList !== undefined &&
                <React.Fragment >
                    <StyledContainer fluid>
                        <Row>
                            <Modal isOpen={isWrongStartDatePopupOpen} toggle={toggleWrongStartDatePopup}>
                                <ModalHeader toggle={toggleWrongStartDatePopup}>Wrong date selected!</ModalHeader>
                                <ModalBody>You cannot choose a Start Date bigger than End Date.</ModalBody>
                            </Modal>
                            <Modal isOpen={isWrongEndDatePopupOpen} toggle={toggleWrongEndDatePopup}>
                                <ModalHeader toggle={toggleWrongEndDatePopup}>Wrong date selected!</ModalHeader>
                                <ModalBody>You cannot choose an End Date lower than Start Date.</ModalBody>
                            </Modal>
                            <Col sm={3}>
                                <strong>Start Date:</strong> <br />
                                <StatisticsDatePicker
                                    className="form-control"
                                    popperPlacement="top-end"
                                    selected={startDate}
                                    onChange={changeStartDate}
                                    dates={startDates}
                                />
                            </Col>
                            <Col sm={3}>
                                <strong>End Date:</strong><br />
                                <StatisticsDatePicker
                                    className="form-control"
                                    popperPlacement="top-end"
                                    selected={endDate}
                                    onChange={changeEndDate}
                                    dates={endDates}
                                />
                            </Col>
                            <Col sm={3}>
                                <strong>Moving Days:</strong>
                                <Input type="number" min={1} step={1} onChange={setFromInput(setMovingAvgDays)} value={movingAvgDays} />
                            </Col>
                        </Row>
                    </StyledContainer>
                    <StyledHighcharts>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsState}
                        />
                    </StyledHighcharts>
                    <br />
                    <RequestStatisticsListView list={requestStatisticsList} user={props.user} movingAvgDays={props.movingAvgDays} endDate={endDate.toISOString()} />
                </React.Fragment>
            }
        </React.Fragment>

    );
};