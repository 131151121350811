import { useState, useCallback, createRef, RefObject, useEffect, useRef } from "react";
import { FormErrors, validateForm, ValidationResult, ValidationSchema } from "./validateForm";

type FieldRefsObj<T> = { [key in keyof T]?: RefObject<HTMLDivElement> };

type UseFormArgs<T> = {
    schema: ValidationSchema<T>;
    value: T;
    isActive?: boolean;
};

export function useValidate<T extends object>({ schema, value, isActive = false }: UseFormArgs<T>) {
    const [active, setActive] = useState(isActive);
    const [errors, setErrors] = useState<FormErrors<T>>({});
    const fieldRefs = useRef<FieldRefsObj<T>>({});

    const register = useCallback((name: keyof T): RefObject<HTMLDivElement> => {
        if (fieldRefs.current[name] === undefined) {
            fieldRefs.current[name] = createRef<HTMLDivElement>();
        }
        return fieldRefs.current[name]!;
    }, [fieldRefs.current]);

    const scrollToInvalid = useCallback((refs: FieldRefsObj<T>, formErrors: FormErrors<T>) => {
        for (const key of Object.keys(formErrors)) {
            const fieldErrors = formErrors[key] as string[];
            if (fieldErrors.length > 0) {
                const fieldRef = refs[key] as RefObject<HTMLDivElement>;
                if (fieldRef?.current) {
                    fieldRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                }
            }
        }
    }, []);

    const validate = useCallback(async (scrollIntoView: boolean = false): Promise<ValidationResult<T>> => {
        setActive(true);
        const validationResult = await validateForm(value, schema);
        setErrors(validationResult.errors);
        if (scrollIntoView) {
            scrollToInvalid(fieldRefs.current, validationResult.errors);
        }
        return validationResult;
    }, [schema, JSON.stringify(value), fieldRefs.current]);

    const resetField = useCallback((fieldName: keyof T) => {
        setErrors(prev => ({ ...prev, [fieldName]: [] }));
    }, []);

    const reset = useCallback(() => {
        setActive(false);
        setTimeout(() => {
            setErrors({});
        }, 0);
    }, []);

    useEffect(() => {
        if (active) {
            validate();
        }
    }, [validate, active]);

    return { errors, validate, register, resetField, reset };
}