import React, { FC, useState, useMemo } from "react";
import { PageContainer } from "./components";
import { Col } from "reactstrap";
import { NavTabs } from "src/shared/components";
import { ColorOption, ColorSchemeMetadata } from "src/shared/dtos";
import { DownloaderView } from "./views";
import { ThemeProvider } from "styled-components";
import { PreviewColorTheme } from "./types";

const getPreviewThemeFromOptions = (options: ColorOption[]): Partial<PreviewColorTheme> => options.reduce((colorTheme, option) => {
    if (option.schemeItem) {
        colorTheme[option.schemeItem] = option.color;
    }
    return colorTheme;
}, {} as Partial<PreviewColorTheme>);

type Props = {
    options: ColorOption[] | undefined;
    colorSchemeMetadata: ColorSchemeMetadata[];
    downloaderImages: string[] | undefined;
    installationLeftCornerIconUrl: string | undefined;
    productName: string | undefined;
};

export const PreviewComponent: FC<Props> = (props) => {
    const [page, setPage] = useState(0);

    const { options, downloaderImages, installationLeftCornerIconUrl, productName, colorSchemeMetadata } = props;

    const defaultColors = useMemo(() => colorSchemeMetadata.reduce((acc, opt) => {
        acc[opt.name] = opt.defaultValue;
        return acc;
    }, {} as Partial<PreviewColorTheme>), [colorSchemeMetadata]);

    const previewTheme = useMemo<PreviewColorTheme>(() => {
        const customTheme = options ? getPreviewThemeFromOptions(options) : {};
        return { ...defaultColors, ...customTheme } as PreviewColorTheme;
    }, [options, defaultColors]);

    return (
        <Col md={12}>
            <p>Preview</p>
            <NavTabs links={["Downloader window"]} page={page} setPage={setPage} />
            <PageContainer>
                <ThemeProvider theme={previewTheme}>
                    {page === 0 && (
                        <DownloaderView
                            downloaderImages={downloaderImages}
                            installationLeftCornerIconUrl={installationLeftCornerIconUrl}
                            promptHeaderText={productName}
                        />
                    )}
                    {/* {page === 1 && (
                        <IoApplicationsView
                            leftCornerIconUrl={installationLeftCornerIconUrl}
                        />
                    )} */}
                </ThemeProvider>
            </PageContainer>
        </Col>
    );
};
