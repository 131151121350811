import { ReactNode } from "react";
import { ValueMapper } from "./valueMappers";

export interface SelectOption<TValue = string> {
    value: TValue;
    label: ReactNode;
}

export type SelectOptionMapper<T> = (value: T | undefined) => SelectOption;

export const makeSelectOptionMapper = <T extends unknown>(valueMapper: ValueMapper<T | undefined, string>): SelectOptionMapper<T> => (value) => {
    return {
        value: value !== undefined ? String(value) : "",
        label: valueMapper(value)
    } as SelectOption;
};