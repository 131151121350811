import React, { useState, useCallback, useMemo, useEffect } from "react";
import { noop, defer } from "rxjs";
import { AdUnit, AdUnitTypes } from "src/shared/dtos";
import { RouteLink, notifyError, notifySuccess } from "src/shared/components";
import { ActionButton, BooleanIndicator, Column, DefaultCellRenderer, InfiniteTable } from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect } from "src/shared/components/ReactBaseTable";
import { routes } from "src/shared/routes";
import { splitCamelCase, useFunctionState } from "src/shared/helpers";
import api from "../api";
import { IUser } from "src/shared/client";
import { Col } from "reactstrap";
import { adUnitTypeToString } from "src/shared/helpers/adUnitTypeToString";
import { BannerDimensionRenderer } from "src/shared/components/InfiniteTable/BannerDimensionRenderer";

type Props = { adCampaignId: number, user: IUser | null };

export const AdUnitListView = ({ adCampaignId, user }: Props) => {
    const [items, setItems] = useState<AdUnit[]>([]);

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);

    const load = useCallback(() => {
        const observable = defer(() => api.adUnitList(adCampaignId));
        return new Promise<boolean>(resolve => {
            const subscription = observable.subscribe(result => {
                setItems(result.sort((a, b) => a.id - b.id));
                resolve(true);
            });
            setUnsubscribe(() => subscription.unsubscribe());
        });
    }, []);

    const onDeleteAdUnit = useCallback((unit: AdUnit) => {
        defer(() => api.deleteAdUnit(unit.id)).subscribe({
            next: () => {
                notifySuccess(`Unit has been successfully deleted.`);
                setItems(old => old.filter(i => i.id !== unit.id));
            },
            error: () => notifyError(`Unable to delete unit.`)
        });
    }, []);

    const bannerDimensionRenderer = useMemo(() =>
        (props: CellProps<unknown, AdUnit>) => {
            const { ...restProps } = props;

            return <BannerDimensionRenderer
                unitType={props.rowData.unitType}
                sizeX={props.rowData.unitType === AdUnitTypes.BannerAction ? props.rowData.adBannerUnit?.bannerSizeX : props.rowData.adRichMediaOverlayUnit?.sizeX}
                sizeY={props.rowData.unitType === AdUnitTypes.BannerAction ? props.rowData.adBannerUnit?.bannerSizeY : props.rowData.adRichMediaOverlayUnit?.sizeY}
                pixelType={props.rowData.adRichMediaOverlayUnit?.pixelType}
                {...restProps} />;
        }, []);

    const enabledRenderer = useMemo(() =>
        (props: CellProps<boolean, AdUnit>) => {
            const { cellData, ...restProps } = props;
            const value = <BooleanIndicator value={cellData} />;
            const title = `Ad unit is ${cellData ? "enabled" : "disabled"}.`;
            return <DefaultCellRenderer cellData={value} title={title} centerText={true} {...restProps} />;
        }, []);

    const actionsRenderer = useMemo(() => (props: CellProps<number, AdUnit>) => {
        const { cellData, ...restProps } = props;
        const onDelete = () => onDeleteAdUnit(props.rowData);
        const value =
            <div>
                <RouteLink
                    user={user}
                    to={{ route: routes.editAdUnit, args: { id: props.rowData.id, adCampaignId } }}
                    className="d-inline">
                    <ActionButton icon="pencil-alt" className="text-primary" />
                </RouteLink>
                <ActionButton icon="trash" className="text-danger" onClick={onDelete} />
            </div>;

        return <DefaultCellRenderer cellData={value} centerText={true} {...restProps} />;
    }, [onDeleteAdUnit]);

    const select = makeSelect<AdUnit>();

    const adTriggersGetter = useCallback(
        () => select(a => a.adUnitTriggers?.map(c => splitCamelCase(c.triggerType)).join(", ")),
        [select]);

    return (
        <React.Fragment>
            <Col sm={{ size: 12 }} className="p-3">
                <RouteLink
                    disabled={adCampaignId === 0}
                    user={user}
                    to={{ route: routes.addAdUnit, args: { adCampaignId } }}
                    button
                    className="mr-2"
                >
                    New Ad Unit
                </RouteLink>
            </Col>
            <InfiniteTable
                className="p-3"
                hideTopPanel={true}
                items={items}
                height={600}
                load={load}
            >
                <Column
                    key="name"
                    title="Name"
                    dataGetter={select(a => a.name)}
                    width={300}
                    sortable={false}
                />
                <Column
                    key="type"
                    title="Type"
                    dataGetter={select(a => adUnitTypeToString(a.unitType))}
                    width={200}
                    sortable={false}
                />
                <Column
                    key="triggerEvent"
                    dataGetter={adTriggersGetter()}
                    title="Trigger Event"
                    width={500}
                    sortable={false}
                />
                <Column
                    key="bannerDim"
                    title="Banner Dimensions"
                    width={300}
                    sortable={false}
                    cellRenderer={bannerDimensionRenderer}
                />
                <Column
                    key="enabled"
                    dataGetter={select(a => !a.isDisabled)}
                    title="Enabled"
                    width={100}
                    sortable={false}
                    cellRenderer={enabledRenderer}
                />
                <Column
                    key="actions"
                    dataGetter={select(a => a.id)}
                    title=""
                    width={200}
                    sortable={false}
                    cellRenderer={actionsRenderer}
                />
            </InfiniteTable>
        </React.Fragment>);
};