import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    html, body {
        height: 100%;
        margin: 0;
    }

    #root {
        height: 100%;
    }
`;