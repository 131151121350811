import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExtendedThemedProps, ThemedProps } from "../../../types";

export const TermsAndConditionsBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;

export const TermsLeftBlock = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TermsLinkContainer = styled.div`
    display: flex;
    margin-bottom: 6px;
    color: #fff;
`;

export const TermsCheckBox = styled.div`
    display: flex;
    label {
        font-size: 12px;
        font-family: "Arial";
        display: flex;
        align-items: center;
        color: ${(p: ThemedProps) => p.theme.WindowTextColor};
        margin-bottom: 0;
        &:hover {
            &::before {
                background-color: ${(p: ThemedProps) => p.theme.CheckBoxTermsMouseOverBackColor};
            }
        }
    }

    input {
        cursor: pointer;
        opacity: 0;
        position: absolute;
    }

    label::before {
        content: "";
        border: 2px solid ${(p: ThemedProps) => p.theme.CheckBoxTermsBorderColor};
        height: 1.5em;
        width: 1.5em;
        margin-right: 0.5em;
        background-color: ${(p: ThemedProps) => p.theme.CheckBoxTermsBackColor};
    }

    input:checked {
        & + label::before {
            content: "\\002713";
            color: ${(p: ThemedProps) => p.theme.CheckBoxTermsCheckmarkColor};
            border-color: ${(p: ThemedProps) => p.theme.CheckBoxTermsCheckedBorderColor};
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${(p: ThemedProps) => p.theme.CheckBoxTermsCheckedBackColor};
        }
    }
`;

interface TermsLinkProps {
    isVisited: boolean;
}

export const TermsLink = styled.span<TermsLinkProps>`
    color: ${(p: ExtendedThemedProps<TermsLinkProps>) => p.isVisited ? p.theme.TermsLinkVisitedTextColor : p.theme.TermsLinkTextColor};
    cursor: pointer;
    font-weight: bold;
    font-family: "Arial";
    font-size: 12px;
    text-decoration: underline;
    margin-left: 4px;

    &:hover {
        color: ${(p: ThemedProps) => p.theme.TermsLinkActiveTextColor};
    }

    &:active {
        color: ${(p: ThemedProps) => p.theme.TermsLinkActiveTextColor};
    }
`;

export const CustomSettingsText = styled.div`
    display: flex;
    align-items: stretch;
    color: ${(p: ThemedProps) => p.theme.CustomSettingsTextColor};
    cursor: pointer;
    font-size: 12px;
    font-family: "Arial";
    margin-left: 2px;
    &:hover {
        color: ${(p: ThemedProps) => p.theme.CustomSettingsTextColor};
    }
`;

export const ChevronIcon = styled(FontAwesomeIcon).attrs({ icon: "chevron-up" })`
    font-size: 16px;
    vertical-align: middle;
    margin-right: 4px;
`;

const StyledButton = styled.button`
    height: 28px;
    width: 90px;
    font-size: 14px;
    font-weight: bold;
    font-family: "Arial";
    border-radius: 5px;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:disabled {
        border: none;
    }
`;

export const BrowseButton = styled(StyledButton)`
    border: 2px solid ${(p: ThemedProps) => p.theme.BrowseButtonEnableBorderColor};
    color: ${(p: ThemedProps) => p.theme.BrowseButtonTextColor};
    background: ${(p: ThemedProps) => p.theme.BrowseButtonEnableColor};

    &:hover {
        background: ${(p: ThemedProps) => p.theme.BrowseButtonMouseOverBackColor};
        border: 2px solid ${(p: ThemedProps) => p.theme.BrowseButtonMouseOverBorderColor};
    }

    &:active {
        background: ${(p: ThemedProps) => p.theme.BrowseButtonMouseClickColor};
        border: 2px solid ${(p: ThemedProps) => p.theme.BrowseButtonMouseClickBorderColor};
    }

    &:disabled {
        background: #808080;
    }
`;

export const ContinueButton = styled(StyledButton)`
    margin-left: auto;
    align-self: flex-end;
    border: 2px solid ${(p: ThemedProps) => p.theme.ContinueButtonEnableBorderColor};
    color: ${(p: ThemedProps) => p.theme.ContinueButtonTextColor};
    background: ${(p: ThemedProps) => p.theme.ContinueButtonEnableColor};

    &:hover {
        background: ${(p: ThemedProps) => p.theme.ContinueButtonMouseOverColor};
        border: 2px solid ${(p: ThemedProps) => p.theme.ContinueButtonMouseOverBorderColor};
    }

    &:active {
        background: ${(p: ThemedProps) => p.theme.ContinueButtonMouseClickColor};
        border: 2px solid ${(p: ThemedProps) => p.theme.ContinueButtonMouseClickBorderColor};
    }
    &:disabled {
        color: ${(p: ThemedProps) => p.theme.ContinueButtonDisableColor};
        background: #808080;
        border: none;
    }
`;

export const InstallationPathBlock = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    margin-top: 6px;
`;

export const PathLabel = styled.span`
    margin-right: 12px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    font-family: Arial;
    color: ${(p: ThemedProps) => p.theme.WindowTextColor};
`;

export const InstallationPath = styled.div`
    background-color: ${(p: ThemedProps) => p.theme.InstallationPathBackColor};
    border: 2px solid;
    border-radius: 5px;
    border-color: ${(p: ThemedProps) => p.theme.InstallationPathBorderColor};
    color: ${(p: ThemedProps) => p.theme.InstallationPathTextColor};
    padding: 0 6px;
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 28px;
    width: 540px;
    margin-right: 5px;
`;

export const ControlsBlock = styled.div`
    display: flex;
    margin-left: 140px;
    margin-top: 4px;
    div:not(:first-child) {
        margin-left: 16px;
    }
`;

export const CustomCheckBox = styled.div`
    display: flex;
    label {
        font-size: 12px;
        font-family: "Arial";
        display: flex;
        align-items: flex-end;
        color: ${(p: ThemedProps) => p.theme.WindowTextColor};
        height: 100%;
        line-height: 100%;
        text-align: end;
        margin-bottom: 0;
        &:hover {
            &::before {
                background-color: ${(p: ThemedProps) => p.theme.CustomSettingsCheckBoxMouseOverBackColor};
            }
        }
    }

    input {
        opacity: 0;
        border: none;
    }

    label::before {
        content: "";
        height: 1.2em;
        width: 1.2em;
        border-radius: 2px;
        margin-right: 0.8rem;
        background-color: ${(p: ThemedProps) => p.theme.CustomSettingsCheckBoxBackColor};
    }

    input:checked {
        & + label::before {
            content: "\\002713";
            color: ${(p: ThemedProps) => p.theme.CustomSettingsCheckBoxCheckmarkColor};
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: ${(p: ThemedProps) => p.theme.CustomSettingsCheckBoxCheckedBackColor};
        }
    }
`;