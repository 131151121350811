import React from "react";
import { Input } from "reactstrap";
import styled from "styled-components";
import { SearchCellHeaderProps } from "./SearchCellHeaderProps";

const SelectInput = styled(Input)`
    height: 27px;
    padding: 0 7px;
    font-size: 13px;

    &:focus {
        box-shadow: none;
        border-color: #ced4da;
    }
`;

/**
 * Allow search by a boolean value for an infinite table column.
 */
export function BooleanSearch<TItem>(props: SearchCellHeaderProps<boolean | undefined, TItem>) {
    const set = (event: React.ChangeEvent<HTMLSelectElement>) => {
        switch (event.currentTarget.value) {
            case "true":
                props.setSearchValue(true);
                break;
            case "false":
                props.setSearchValue(false);
                break;
            case "any":
            default:
                props.setSearchValue(undefined);
                break;
        }
    };

    let value: string = "any";
    switch (props.searchValue) {
        case true:
            value = "true";
            break;
        case false:
            value = "false";
            break;
    }

    return (
        <SelectInput type="select" value={value} onChange={set}>
            <option value="any">Any</option>
            <option value="true">True</option>
            <option value="false">False</option>
        </SelectInput>
    );
}