import { client } from "src/shared/client";
import {
    GetAllSourceSettingsList,
    AdCampaign,
    GetAdCampaigns,
    GetAdCampaign,
    GetAdTargeting,
    ManageAdCampaign,
    DeleteAdCampaign,
    GetAdUnits,
    DeleteAdUnit,
    AdCampaignListDataType,
    OrderDirection
} from "src/shared/dtos";

export interface ListRequest {
    orderBy: AdCampaignListDataType;
    orderDirection: OrderDirection;
    nameSearch?: string;
    disabledSearch?: boolean;
    sourceSearch?: number[];
    includeCampaignWithAllSourcesSearch?: boolean;
}

export const AdCampaignsApi = {
    getSourceSettings: () => client.get(new GetAllSourceSettingsList()),
    getAdTargeting: () => client.get(new GetAdTargeting()),
    list: (request: ListRequest) => client.get(new GetAdCampaigns({ ...request })),
    get: async (id: number) => {
        const result = await client.get(new GetAdCampaign({ id }));
        return result.id === undefined ? undefined : result;
    },
    create: (adCampaign: AdCampaign, sourceSettingsIds?: number[]) => client.post(new ManageAdCampaign({ adCampaign, sourceSettingsIds })),
    update: (adCampaign: AdCampaign, sourceSettingsIds?: number[]) => client.put(new ManageAdCampaign({ adCampaign, sourceSettingsIds })),
    delete: (id: number) => client.delete(new DeleteAdCampaign({ id })),
    adUnitList: (adCampaignId: number) => client.get(new GetAdUnits({ adCampaignId })),
    deleteAdUnit: (id: number) => client.delete(new DeleteAdUnit({ id }))
};

export default AdCampaignsApi;