import { client } from "src/shared/client";
import {
    GetRequestStatisticsById,
    GetRequestStatisticsSummaries,
    GetRequestStatisticsSummariesByPathForPeriod,
    GetRequestStatistics,
    OrderDirection,
    RequestStatisticsListDataType,
    GetRequestStatisticsAvailableDates
} from "src/shared/dtos";
import { serializeDate } from "src/shared/helpers/dateSerialization";

export const RequestStatisticsApi = {
    getById: (id: number) => client.get(new GetRequestStatisticsById({ id })),
    list: (
        date: Date,
        movingAvgDays: number,
        orderBy: RequestStatisticsListDataType,
        orderDirection: OrderDirection
    ) => client.get(new GetRequestStatisticsSummaries({
        date: serializeDate(date),
        movingAvgDays,
        orderBy,
        orderDirection,
        isWidget: false
    })),
    details: (
        movingAvgDays: number,
        path: string,
        startDate: Date,
        endDate: Date
    ) => client.get(new GetRequestStatisticsSummariesByPathForPeriod({
        path,
        movingAvgDays,
        startDate: serializeDate(startDate),
        endDate: serializeDate(endDate)
    })),
    all: () => client.get(new GetRequestStatistics()),
    getDates: () => client.get(new GetRequestStatisticsAvailableDates()),
};

export default RequestStatisticsApi;