import React, { useMemo, useCallback } from "react";
import { Label } from "reactstrap";
import styled from "styled-components";
import { PropertyMetadata, PropertyDataType, ColorOption } from "src/shared/dtos";
import { deepEqual } from "src/shared/helpers";
import { PropertyEditorProps } from "./PropertyEditorProps";
import { SingleLinePropertyEditor } from "./editors/SingleLinePropertyEditor";
import { MultiLinePropertyEditor } from "./editors/MultiLinePropertyEditor";
import { HtmlPropertyEditor } from "./editors/HtmlPropertyEditor";
import { CheckBoxPropertyEditor } from "./editors/CheckBoxPropertyEditor";
import { MultiValuePropertyEditor } from "./editors/MultiValuePropertyEditor";
import { SingleLineUrlWithPreviewPropertyEditor } from "./editors/SingleLineWithPreviewImagePropertyEditor";
import { InstallerColorOptionsPropertyEditor } from "./editors/InstallerColorOptionsPropertyEditor";
import { ApplicationSelectPropertyEditor } from "./editors/ApplicationSelectPropertyEditor";
import { FormLabel } from "..";
import { forwardRef } from "react";
import { IntegerPropertyEditor } from "./editors/IntegerPropertyEditor";

const CheckBoxLabel = styled(Label)`
    cursor: pointer;
`;

export interface PropertyFieldProps {
    source?: string;
    id?: string;
    metadata: PropertyMetadata;
    value: object;
    errors?: string[];
    originalValue?: object;
    setValue: (value: object) => void;
    preprocessEditorValue?: (propertyType: PropertyDataType, propertyName: string, value: unknown) => unknown;
    disabled?: boolean;
    warnings?: string[];
}

let uniqueIdSuffix = 0;
export const PropertyField = forwardRef((props: PropertyFieldProps, ref: React.MutableRefObject<HTMLDivElement>) => {
    const fieldId = useMemo(
        () => props.id ?? `${props.metadata.propertyName}-propertyField-${uniqueIdSuffix++}`,
        [props.id, props.metadata.propertyName]);
    const editorValue = props.value[props.metadata.propertyName];

    const onEditorValueChange = useCallback((newInputValue: unknown) => {
        if (props.preprocessEditorValue !== undefined) {
            newInputValue = props.preprocessEditorValue(props.metadata.propertyType, props.metadata.propertyName, newInputValue);
        }

        const newValue = Object.assign({}, props.value);
        newValue[props.metadata.propertyName] = newInputValue;

        props.setValue(newValue);
    }, [props.value, props.setValue, props.metadata.propertyName, props.preprocessEditorValue]);

    const isChanged = useMemo(() => {
        if (props.originalValue === undefined) {
            return false;
        }

        const originalEditorValue = props.originalValue[props.metadata.propertyName];
        const isArray = Array.isArray(originalEditorValue);
        if (isArray) {
            return editorValue?.join() !== originalEditorValue?.join();
        }
        return editorValue !== originalEditorValue || !deepEqual(editorValue, originalEditorValue);
    }, [props.originalValue, editorValue, props.metadata.propertyName]);

    const editorProps: PropertyEditorProps<unknown> = {
        id: fieldId,
        value: editorValue,
        onChange: onEditorValueChange,
        disabled: props.disabled,
        errors: props.errors
    };

    const renderLabel = (
        <FormLabel
            isChanged={isChanged}
            showWarnings={!props.errors || props.errors.length === 0}
            warnings={props.warnings}>
                {props.metadata.label}
        </FormLabel>);

    switch (props.metadata.propertyType) {
        case PropertyDataType.MultiLineEditor:
            return (
                <React.Fragment>
                    <Label for={props.id}>{renderLabel}</Label>
                    <MultiLinePropertyEditor ref={ref} {...editorProps as PropertyEditorProps<string>} />;
                </React.Fragment>
            );
        case PropertyDataType.MultiValueEditor:
            return (
                <React.Fragment>
                    <Label for={props.id}>{renderLabel}</Label>
                    <MultiValuePropertyEditor ref={ref} {...editorProps as PropertyEditorProps<string[]>} />
                </React.Fragment>
            );
        case PropertyDataType.HtmlEditor:
            return (
                <React.Fragment>
                    <Label for={props.id}>{renderLabel}</Label>
                    <HtmlPropertyEditor {...editorProps as PropertyEditorProps<string>} />
                </React.Fragment>
            );
        case PropertyDataType.CheckBox:
            return (
                <React.Fragment>
                    <CheckBoxLabel>
                        {renderLabel}
                    </CheckBoxLabel>
                    <br />
                    <CheckBoxPropertyEditor {...editorProps as PropertyEditorProps<boolean | undefined>} />
                </React.Fragment>
            );
        case PropertyDataType.SingleLineUrlEditorWithPreview:
            return (
                <React.Fragment>
                    <Label for={props.id}>{renderLabel}</Label>
                    <SingleLineUrlWithPreviewPropertyEditor ref={ref} {...editorProps as PropertyEditorProps<string>} />
                </React.Fragment>
            );
        case PropertyDataType.InstallerColorOptionsEditor:
            return (
                <React.Fragment>
                    <Label for={props.id}>{renderLabel}</Label>
                    <InstallerColorOptionsPropertyEditor ref={ref} {...editorProps as PropertyEditorProps<ColorOption[] | undefined>} />
                </React.Fragment>
            );
        case PropertyDataType.ApplicationSelect:
            return (
                <React.Fragment>
                    <Label for={props.id}>{renderLabel}</Label>
                    <ApplicationSelectPropertyEditor {...editorProps as PropertyEditorProps<string[] | undefined>} />
                </React.Fragment>
            );
        case PropertyDataType.IntEditor:
            return (
                <React.Fragment>
                    <Label for={props.id}>{renderLabel}</Label>
                    <IntegerPropertyEditor ref={ref} {...editorProps as PropertyEditorProps<number>} />
                </React.Fragment>
            );
        case PropertyDataType.SingleLineEditor:
        default:
            return (
                <React.Fragment>
                    <Label for={props.id}>{renderLabel}</Label>
                    <SingleLinePropertyEditor ref={ref} {...editorProps as PropertyEditorProps<string>} />
                </React.Fragment>
            );
    }
});
