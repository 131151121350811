import React from "react";
import { ActiveInstallsStatistics } from "src/shared/dtos";
import { Table } from "reactstrap";

interface Props {
    list: ActiveInstallsStatistics[];
}

export const ActiveInstallsStatisticsListView = ({list}: Props) => {
    const sortedList = list.slice();
    sortedList.sort((item1, item2) => item1.date > item2.date ? -1 : item1.date < item2.date ? 1 : 0 );

    const dateRenderer = (value: string) => {
        const date = new Date(value);
        return date.toLocaleDateString();
    };

    const intRenderer = (value: number) => {
        return value.toLocaleString(
            undefined,
            { maximumFractionDigits: 0, minimumFractionDigits: 0 }
        );
    };

    if(list.length === 0){
        return <h3>No data</h3>;
    }

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedList.map(item => {
                        return (<tr key={item.id}>
                            <th scope="row">{dateRenderer(item.date)}</th>
                            <td>{intRenderer(item.count)}</td>
                        </tr>);
                    })}
                </tbody>
            </Table>
        </React.Fragment >);
};
