import React from "react";
import { GamingMinutesStatisticsSummary } from "src/shared/dtos";
import { Table } from "reactstrap";
import { RouteLink } from "src/shared/components";
import { IUser } from "src/shared/client";
import { routes } from "src/shared/routes";

interface Props {
    list: GamingMinutesStatisticsSummary[];
    movingAvgDays: number;
    endDate: string;
    user: IUser | null;
}

export const GamingStatisticsListView = ({ list, movingAvgDays, endDate, user }: Props) => {
    const sortedList = list.slice();
    sortedList.sort((item1, item2) => item1.date > item2.date ? -1 : item1.date < item2.date ? 1 : 0);

    const dateRenderer = (value: string) => {
        const date = new Date(value);
        return date.toLocaleDateString();
    };

    const intRenderer = (value: number) => {
        return value.toLocaleString(
            undefined,
            { maximumFractionDigits: 0, minimumFractionDigits: 0 }
        );
    };

    const decimalRenderer = (value: number) => {
        return value.toLocaleString(
            undefined,
            { maximumFractionDigits: 3, minimumFractionDigits: 3 }
        );
    };

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Count</th>
                        <th>Moving Avg Count</th>
                        <th>Total (min)</th>
                        <th>Moving Avg Total (min)</th>
                        <th>Avg (min)</th>
                        <th>Moving Avg Avg (min)</th>
                        <th>STD (min)</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedList.map(item => {
                        return (<tr key={item.date}>
                            <th scope="row">{dateRenderer(item.date)}</th>
                            <td>{intRenderer(item.count)}</td>
                            <td>{intRenderer(item.movingAverageCount)}</td>
                            <td>{decimalRenderer(item.totalMinutes)}</td>
                            <td>{decimalRenderer(item.movingAverageTotalMinutes)}</td>
                            <td>
                                <RouteLink user={user} to={{ route: routes.gamingStatisticsDistribution, args: { id: item.id, movingAvgDays, endDate } }}>
                                    {decimalRenderer(item.averageMinutes)}
                                </RouteLink>
                            </td>
                            <td>{decimalRenderer(item.movingAverageAverageMinutes)}</td>
                            <td>{decimalRenderer(item.standardDeviationMinutes)}</td>
                        </tr>);
                    })}
                </tbody>
            </Table>

        </React.Fragment >);
};