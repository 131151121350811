import React, { forwardRef } from "react";
import BaseTable, { AutoResizer, Column } from "react-base-table";

/**
 * The BaseTable JSX element from 'react-base-table' wrapper to make component available in TypeScript.
 * @param {any} props definition can be found on https://autodesk.github.io/react-base-table/api/basetable
 */
export const BaseTableWrapper = forwardRef((props, ref) => {
    return <BaseTable ref={ref} {...props} />
})

/**
 * The Column JSX element from 'react-base-table' wrapper to make component available in TypeScript.
 * @param {any} props definition can be found on https://autodesk.github.io/react-base-table/api/column
 */
export const ColumnWrapper = (props) => {
    return <Column {...props} />
}

/**
 * The AutoResizer JSX element from 'react-base-table' wrapper to make component available in TypeScript.
 * @param {any} props definition can be found on https://autodesk.github.io/react-base-table/api/autoresizer
 */
export const AutoResizerWrapper = (props) => {
    return <AutoResizer {...props} />
}