import { GamestoreApplication, GoogleApplication } from "src/shared/dtos";
import { deepEqual } from "src/shared/helpers";

export type ApplicationState = {
    current?: GamestoreAppMainInfo,
    initial?: GamestoreAppMainInfo,
    changed: boolean
};

export type GamestoreAppMainInfo = Omit<GamestoreApplication, "localizations" | "apkDetails" | "defaultKeyBindings"> & {
    defaultKeyBindings: string | undefined;
};

const getMainInfo = (app?: GamestoreApplication): GamestoreAppMainInfo | undefined => {
    if (app === undefined) {
        return app;
    }
    const { localizations, apkDetails, ...rest } = app;
    return {
        ...rest,
        defaultKeyBindings: rest.defaultKeyBindings === undefined ? undefined : JSON.stringify(rest.defaultKeyBindings, null, 4)
    };
};

export type ApplicationAction =
    { kind: "reset" } |
    { kind: "set", value: GamestoreApplication, initialValue?: GamestoreApplication } |
    { kind: "update", value: Partial<GamestoreAppMainInfo> } |
    { kind: "set-google-app", value: GoogleApplication };

export const applicationReducer = (state: ApplicationState, action: ApplicationAction) => {
    switch (action.kind) {
        case "reset": {
            state = {
                current: undefined,
                initial: undefined,
                changed: false
            };
            break;
        }
        case "set": {
            state = {
                current: getMainInfo(action.value),
                initial: getMainInfo(action.initialValue),
                changed: false
            };
            break;
        }
        case "update": {
            if (state.current === undefined) {
                break;
            }
            Object.assign(state.current, action.value);
            state.changed = !deepEqual(state.current, state.initial);
            break;
        }
        case "set-google-app": {
            if (state.current === undefined) {
                break;
            }

            state.current.googleApplication = action.value;
            state.current.categories = [action.value.category];
            state.current.categoryIds = [action.value.category.id];
            break;
        }
    }

    return Object.assign({}, state);
};

export const applicationReducerDefault: ApplicationState = {
    current: undefined,
    initial: undefined as unknown as GamestoreAppMainInfo,
    changed: false
};