import React, { useCallback, useMemo } from "react";
import Editor from "react-simple-code-editor";
import Prism, { highlight } from "prismjs";
import "prismjs/components/prism-json";
import styled from "styled-components";

const StyledEditor = styled(Editor)`
    textarea {
        outline: none !important;
        border: none !important;
    }

    pre {
        border: 1px solid #ced4da !important;
        border-radius: 0.25rem !important;
        min-height: 240px !important;
        display: block;
    }
`;

interface Props {
    value: string;
    setValue: (value: string) => void;
    disabled: boolean;
    style?: React.CSSProperties;
}

export const KeyBindingsEditor = (props: Props) => {
    const highlightJson = useCallback(code => highlight(code, Prism.languages.json, "language-json"), []);
    const style = useMemo<React.CSSProperties>(() => {
        let baseStyle = { minHeight: 240 };
        if (props.style !== undefined) {
            baseStyle = Object.assign(baseStyle, props.style);
        }
        return baseStyle;
    }, [props.style]);

    return (
        <StyledEditor
            value={props.value}
            onValueChange={props.setValue}
            highlight={highlightJson}
            padding={10}
            tabSize={4}
            insertSpaces={true}
            disabled={props.disabled}
            style={style}
        />
    );
};