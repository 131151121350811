import { FeaturedApplication } from "src/shared/dtos";
import { deepEqual } from "src/shared/helpers";

export type FeaturedAppMainData = Omit<FeaturedApplication, "localizations">;

export type ApplicationState = {
    current?: FeaturedAppMainData,
    initial?: FeaturedAppMainData,
    changed: boolean
};

const getMainData = (app: FeaturedApplication): FeaturedAppMainData => {
    const {localizations, ...mainData} = app;
    return mainData;
};

export type ApplicationAction =
    { kind: "reset" } |
    { kind: "set", value: FeaturedApplication, initialValue: FeaturedApplication } |
    { kind: "update", value: Partial<FeaturedAppMainData> };

export const applicationReducer = (state: ApplicationState, action: ApplicationAction) => {
    switch (action.kind) {
        case "reset": {
            state = {
                current: undefined,
                initial: undefined,
                changed: false
            };
            break;
        }
        case "set": {
            state = {
                current: getMainData(action.value),
                initial: getMainData(action.initialValue),
                changed: false
            };
            break;
        }
        case "update": {
            if (!state.current) {
                break;
            }
            Object.assign(state.current, action.value);
            state.changed = !deepEqual(state.current, state.initial);
            break;
        }
    }

    return Object.assign({}, state);
};

export const applicationReducerDefault: ApplicationState = {
    current: undefined,
    initial: undefined,
    changed: false
};