import React, { useEffect, useState, useReducer, useMemo, useCallback, useRef, } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col } from "reactstrap";
import { forkJoin, defer } from "rxjs";
import {
    GoogleApplicationLocalization,
    Category,
    GoogleApplication,
    GamestoreApplication,
    GoogleApkDetails,
    GoogleAdditionalFile,
    ApkPlatform,
    ApkDetails
} from "src/shared/dtos";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    LeavingViewProtector,
    Loader,
    LocalizationSelector,
    DocumentTitle,
    RouteLink,
    RouteUserProps,
    EditError,
    EditErrorOptions,
    notifySuccess,
    notifyError,
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import {
    availableLanguages,
    capitalize,
    getFormErrorsAsStringArray,
    getLangName,
    getPlatformName,
    makeEmptyGoogleApp,
    queryString,
    splitCamelCase,
    structuredClone,
    useSubscription,
    ValidateAsyncRef,
    validateForm
} from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { EditForm } from "./EditForm";
import { EditInfo } from "./EditInfo";
import { EditLocalization } from "./EditLocalization";
import { googleApplicationReducer, googleApplicationReducerDefault, GoogleAppMainInfo } from "./googleApplicationReducer";
import { localizationReducer, makeLocalizationReducerDefault } from "./localizationReducer";
import api from "../api";
import { platformDetailsReducer, makePlatformDetailsReducerDefault } from "./platformDetailsReducer";
import { PlatformNavSelector } from "./PlatformNavSelector";
import { EditPlatformDetails } from "./EditPlatformDetails";
import { apkDetailsValidationSchema, localizationValidationSchema, localizationMediaValidationSchema } from "./validationSchema";
import { useConfirm } from "src/shared/helpers/useConfirm";
import { ConfirmFailedLocalizationSources, LocalizationsFailedFields } from "src/shared/components/confirmWindow/samples/ConfirmFailedSources";

const getDefaultLocale = (locs: GoogleApplicationLocalization[], lang: string): GoogleApplicationLocalization => {
    const locale = locs.find(l => l.language === lang) || locs[0];
    if (locale === undefined) {
        throw new Error(`Google Application has no localizations.`);
    }

    return locale;
};

type Props = RouteComponentProps<{ appId: string }> & RouteUserProps;

export const EditView = withRouter(({ match, location, history, user }: Props) => {
    const appId = match.params.appId;
    const isNewApp = useMemo(() => appId === undefined, [appId]);
    const mainFormRef = useRef<ValidateAsyncRef>(null);
    const localizationFormRef = useRef<ValidateAsyncRef>(null);
    const platformDetailsFormRef = useRef<ValidateAsyncRef>(null);

    const queryParams = queryString(location.search);
    const defaultLang = isNewApp ? "en" : queryParams.lang || "en";

    const [isNewAppSaved, setIsNewAppSaved] = useState(!isNewApp);
    const [categories, setCategories] = useState<Category[] | undefined>();
    const [gamestoreApp, setGamestoreApp] = useState<GamestoreApplication | undefined>();
    const [error, setError] = useState<EditErrorOptions | undefined>();
    const [saving, setSaving] = useState(false);
    const [app, changeApp] = useReducer(googleApplicationReducer, googleApplicationReducerDefault);
    const [loc, changeLocs] = useReducer(localizationReducer, makeLocalizationReducerDefault(defaultLang));
    const [platformDetails, changePlatformDetails] = useReducer(platformDetailsReducer, makePlatformDetailsReducerDefault());
    const { confirm } = useConfirm();

    const backToList = useCallback(() => history.push(routes.googleApplications.url()), []);

    const updateCategories = useSubscription(() => defer(() => api.getCategories()).subscribe(result => {
        setCategories(result);
    }), [api.getCategories]);

    useEffect(() => {
        updateCategories();
    }, []);

    const updateState = useSubscription((value: GoogleApplication) => forkJoin([
        defer(() => structuredClone(value)),
        defer(() => structuredClone(value.localizations)),
        defer(() => structuredClone(value.localizations))])
        .subscribe(clones => {
            changeApp({ kind: "set", value, initial: clones[0] });
            changeLocs({
                kind: "set",
                app: value,
                resetCurrent: true,
                localizations: clones[1],
                initialLocalizations: clones[2]
            });
            changePlatformDetails({ kind: "set", app: value });
            // Check for an unknown localization languages.
            const allLangs = value.localizations.map(l => l.language);
            const unknownLangs = allLangs
                .filter(l => availableLanguages.find(al => al.value === l) === undefined)
                .join();
            if (unknownLangs.length > 0) {
                console.warn(
                    `GoogleApplication '${value.id}' contains unknown localization languages: '${unknownLangs}'.`);
            }
        }), []);

    const setApp = useCallback((data?: GoogleApplication) => {
        if (categories === undefined || categories.length === 0) {
            return;
        }
        const value = data ?? makeEmptyGoogleApp(categories[0]);
        updateState(value);
    }, [categories, updateState]);

    const updateGoogleApp = useSubscription((id: string) => defer(() => api.get(id)).subscribe({
        next: result => {
            if (result.localizations?.length === undefined) {
                setError({
                    text: "Unable to load application localizations.",
                    actionText: "Back to list",
                    action: backToList
                });
                return;
            }

            setApp(result);
        },
        error: () => setError({
            text: "Unable to load application.",
            actionText: "Back to list",
            action: backToList
        })
    }), [api.get, setApp]);

    const gamestoreLocale = useMemo(() =>
        gamestoreApp?.localizations?.find(o => o.language === loc.current?.language),
        [gamestoreApp, gamestoreApp?.localizations, loc.current?.language]);

    const updateGamestoreApp = useSubscription((id: string) => defer(() => api.getGamestoreApp(id)).subscribe(result => {
        setGamestoreApp(result);
    }), [api.getGamestoreApp]);

    useEffect(() => {
        if (isNewApp) {
            setApp(undefined);
            return;
        }
        updateGoogleApp(appId);
        updateGamestoreApp(appId);
    }, [updateGoogleApp, isNewApp, setApp]);

    useEffect(() => {
        // Go to edit view after successful save of the new entity.
        // It is implemented this way to let leaving view protector know, that there is no changes.
        if (isNewApp && isNewAppSaved && error === undefined) {
            history.push(routes.editGoogleApplication.url({ appId: app.current!.id, language: defaultLang }));
        }
    }, [isNewApp, isNewAppSaved, app.current]);

    const saveApp = useSubscription((request: GoogleApplication, isNew: boolean) => defer(
        () => isNew ? api.create(request) : api.update(request))
        .subscribe({
            next: result => {
                notifySuccess(`Google Application ${isNew ? "added" : "saved"} successfully.`);
                setApp(result);

                if (isNew) {
                    setIsNewAppSaved(true);
                }
            },
            error: () => {
                setSaving(false);
                setError({
                    text: "Unable to save application.",
                    actionText: "Continue",
                    action: () => setError(undefined)
                });
            },
            complete: () => setSaving(false)
        }), [api.create, api.update, setApp]);

    const save = useCallback(
        async () => {
            if (saving) {
                return;
            }

            if (loc.all.length === 0) {
                notifyError("Google Application must have at least one localization.");
                return;
            }

            if (platformDetails.all === undefined || platformDetails.all.length === 0) {
                notifyError("Google Application must have at least one app version.");
                return;
            }

            if (!platformDetails.all.some(v => v.enabled)) {
                notifyError("At least one Application Platform must be enabled.");
                return;
            }

            setSaving(true);

            const isMainFormValid = await mainFormRef.current?.validate();
            if (!isMainFormValid) {
                setSaving(false);
                return;
            }
            const isPlatformDetailsFormValid = await platformDetailsFormRef.current?.validate();
            if (!isPlatformDetailsFormValid) {
                setSaving(false);
                return;
            }
            const isLocalizationFormValid = await localizationFormRef.current?.validate();
            if (!isLocalizationFormValid) {
                setSaving(false);
                return;
            }

            const apkDetailsErrors = (await Promise.all(
                platformDetails.all.map(async apk => {
                    const validationResult = await validateForm(apk, apkDetailsValidationSchema);
                    const apkErrors = getFormErrorsAsStringArray(validationResult.errors);
                    return apkErrors.map(err => `${getPlatformName(apk.platform)} Platform: ${err}`);
                })
            )).flat();

            if (apkDetailsErrors.length > 0) {
                setSaving(false);
                apkDetailsErrors.forEach(notifyError);
                return;
            }

            const localizationsErrors = (await Promise.all(
                loc.all.map(async l => {
                    const locValidationResult = await validateForm(l, localizationValidationSchema);
                    const locErrors = getFormErrorsAsStringArray(locValidationResult.errors);
                    return locErrors.map(err => `${getLangName(l.language)} Localization: ${err}`);
                })
            )).flat();

            if (localizationsErrors.length > 0) {
                setSaving(false);
                localizationsErrors.forEach(notifyError);
                return;
            }

            if ((app.current?.rating ?? app.current?.rating ?? 0) === 0) {
                const confirmSave = await confirm({
                    body: "Do you want to save application with rating 0?",
                    title: "Confirm saving application"
                });
                if (!confirmSave) {
                    setSaving(false);
                    return;
                }
            }

            const failedSources = (await Promise.all(
                loc.all.map(async l => {
                    const locValidationResult = await validateForm(l, localizationMediaValidationSchema);
                    if (locValidationResult.isValid) {
                        return null;
                    }
                    const failedFields = Object.keys(locValidationResult.errors).map(capitalize).map(splitCamelCase);
                    return { lang: getLangName(l.language) ?? l.language, fields: failedFields } as LocalizationsFailedFields;
                })
            )).filter(v => v !== null) as LocalizationsFailedFields[];

            if (failedSources.length > 0) {
                const confirmSave = await confirm({
                    body: <ConfirmFailedLocalizationSources failedFields={failedSources} />,
                    title: "Confirm saving application",
                    size: "lg"
                });
                if (!confirmSave) {
                    setSaving(false);
                    return;
                }
            }

            const additionalFiles = platformDetails.all.reduce((files, apk) => files.concat(apk.additionalFiles), [] as GoogleAdditionalFile[]);
            const apkDetails: GoogleApkDetails[] = platformDetails.all.map(apk => {
                const { additionalFiles: files, ...rest } = apk;
                return rest as GoogleApkDetails;
            });

            const request: GoogleApplication = {
                localizations: loc.all,
                apkDetails,
                additionalFiles,
                ...app.current!
            };
            saveApp(request, isNewApp);
        }, [app, app.current, loc, loc.all, isNewApp, platformDetails, platformDetailsFormRef.current, mainFormRef.current, localizationFormRef.current, confirm, saveApp]);

    const updateApp = useCallback(
        (value: Partial<GoogleAppMainInfo>) => changeApp({ kind: "update", value }), []);
    const setGoogleApp = useCallback(
        (value: GoogleApplication) => {
            changeLocs({ kind: "add-exist", value });
            changeLocs({ kind: "select", lang: loc.current?.language ?? "en" });
        }, []);

    const updateLocalization = useCallback(
        (value: Partial<GoogleApplicationLocalization>) => changeLocs({ kind: "update", value }), []);
    const removeLocalization = useCallback(() => changeLocs({ kind: "remove" }), []);

    const updateDetailsValues = useCallback(
        (value: Partial<GoogleApkDetails>) => changePlatformDetails({ kind: "updateValues", value }), []);

    const updateDetailsFiles = useCallback(
        (value: GoogleAdditionalFile[]) => changePlatformDetails({ kind: "updateFiles", value }), []);

    const changed = useMemo(
        () => app.changed || loc.changedLanguages.length > 0 || loc.removedLocalizations.length > 0, [
        app.changed,
        loc.changedLanguages,
        loc.changedLanguages.length,
        loc.removedLocalizations,
        loc.removedLocalizations.length]);

    const title = useMemo(
        () => {
            const appTitle = isNewApp
                ? "[new application]"
                : (loc.current !== undefined
                    ? getDefaultLocale(loc.all, defaultLang).title
                    : "Loading...");
            return `Google Application - ${appTitle}`;
        },
        [loc.current, loc.all, defaultLang]);

    const setLanguage = (lang: string) => changeLocs({ kind: "select", lang });
    const addLanguage = (lang: string) => changeLocs({ kind: "add", lang });

    const setPlatform = (v: ApkPlatform) => changePlatformDetails({ kind: "select", platform: v });
    const currentPlatform = useMemo(() => platformDetails.current?.platform ?? ApkPlatform.X32, [platformDetails.current]);

    const isLoading = useMemo(() => app.current === undefined || loc.current === undefined ||
        categories === undefined || platformDetails.current === undefined,
        [app.current, loc.current, categories, platformDetails.current]);

    return (
        <VerticalBox>
            <DocumentTitle title={title} />
            <LeavingViewProtector showConfirmation={changed && (isNewApp ? !isNewAppSaved : true)} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.googleApplications}>
                                Google Applications
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {isNewApp ? "[new application]" : match.params.appId}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    {error ? <EditError error={error} /> : isLoading
                        ? <Loader />
                        : <React.Fragment>
                            <Row>
                                <Col sm={5} xs={12}>
                                    <EditInfo
                                        user={user}
                                        app={app.current!}
                                        locale={loc.current}
                                        gamestoreApp={gamestoreApp}
                                        isNewApp={isNewApp}
                                        apkDetails={platformDetails.current} />
                                </Col>
                                <Col sm={7}>
                                    {app.current && (
                                        <EditForm
                                            ref={mainFormRef}
                                            value={app.current}
                                            initial={app.initial}
                                            isNewApp={isNewApp}
                                            categories={categories!}
                                            gamestoreApp={gamestoreApp!}
                                            update={updateApp}
                                            setGoogleApplication={setGoogleApp}
                                            saving={saving}
                                            onSubmit={save}
                                        />)}
                                    <div className="row mb-4">
                                        <Col
                                            md={{ size: 8, offset: 4 }}
                                            lg={{ size: 9, offset: 3 }}
                                        >
                                            <PlatformNavSelector
                                                currentPlatform={currentPlatform}
                                                changedPlatforms={platformDetails.changedPlatforms}
                                                setPlatform={setPlatform}
                                            />
                                        </Col>
                                    </div>
                                    {platformDetails.current && (
                                        <EditPlatformDetails
                                            isNewApp={isNewApp}
                                            ref={platformDetailsFormRef}
                                            value={platformDetails.current}
                                            initial={platformDetails.initial}
                                            updateValues={updateDetailsValues}
                                            updateFiles={updateDetailsFiles}
                                            saving={saving}
                                            onSubmit={save}
                                            gamestoreApp={gamestoreApp!} />)}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <LocalizationSelector
                                        currentLanguage={loc.current!.language ?? defaultLang}
                                        languages={loc.all.map(l => l.language)}
                                        removedLanguages={loc.removedLocalizations.map(l => l.language)}
                                        changedLanguages={loc.changedLanguages}
                                        setLanguage={setLanguage}
                                        addLanguage={addLanguage}
                                    />
                                    {loc.current &&
                                        <EditLocalization
                                            ref={localizationFormRef}
                                            value={loc.current}
                                            initial={loc.initial}
                                            gamestoreLocale={gamestoreLocale}
                                            isNewApp={isNewApp}
                                            update={updateLocalization}
                                            remove={removeLocalization}
                                            saving={saving}
                                            onSubmit={save}
                                            isLastLocalization={loc.all.length < 2} />}
                                </Col>
                            </Row>
                        </React.Fragment>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});
