import { JsonServiceClient, ErrorResponse } from "@servicestack/client";
import { Authenticate, IReturn, AuthenticateResponse } from "./dtos";
import { makeUrl } from "./helpers";

declare const global: any; // populated from package.json/jest
if (typeof global === "undefined") {
    (window as any).global = window;
}

export enum Role {
    Admin = "Admin"
}

export interface IUser {
    /**
     * The user id.
     */
    userId: number;

    /**
     * The username.
     */
    username: string;

    /**
     * The display name.
     */
    displayName: string;

    /**
     * Checks if the user has specified roles.
     */
    hasRoles(...roles: Role[]): boolean;
}

class User implements IUser {
    public readonly userId: number;
    public readonly username: string;
    public readonly displayName: string;

    private readonly roles: string[];

    constructor (auth: AuthenticateResponse) {
        if (auth.userId === undefined) {
            throw new Error("Unable to authenticate user: userId is undefined.");
        }
        if (auth.userName === undefined) {
            throw new Error(`Unable to authenticate user '${auth.userId}': userName is undefined.`);
        }
        if (auth.displayName === undefined) {
            throw new Error(`Unable to authenticate user '${auth.userId}': displayName is undefined.`);
        }

        this.userId = +auth.userId;
        this.username = auth.userName;
        this.displayName = auth.displayName;
        this.roles = auth.roles ?? [];
    }

    public hasRoles = (...roles: Role[]) =>
        roles.every(r => this.roles.indexOf(r) !== -1)
}

export interface IClient {
    /**
     * Resolves URL for specified HTTP method and request.
     * @param httpMethod The HTTP method.
     * @param request The request DTO.
     * @param format The response format.
     */
    resolveUrl<T>(httpMethod: string, request: IReturn<T>, language?: string, format?: string): string;

    /**
     * Sends the GET request to the server.
     * Will call 'onError' on non-successful response.
     * @param request The request DTO.
     */
    get<T>(request: IReturn<T> | string): Promise<T>;

    /**
     * Sends the POST request to the server.
     * Will call 'onError' on non-successful response.
     * @param request The request DTO.
     */
    post<T>(request: IReturn<T>): Promise<T>;

    /**
     * Sends the POST request with form data to the server.
     * Will call 'onError' on non-successful response.
     * @param request The request DTO.
     * @param body The body for post.
     */
    postBody<T>(request: IReturn<T>, body: FormData): Promise<T>;

    /**
     * Sends the PUT request to the server.
     * Will call 'onError' on non-successful response.
     * @param request The request DTO.
     */
    put<T>(request: IReturn<T>): Promise<T>;

    /**
     * Sends the DELETE request to the server.
     * Will call 'onError' on non-successful response.
     * @param request The request DTO.
     */
    delete<T>(request: IReturn<T> | string): Promise<T>;

    /**
     * Gets the logged in user.
     */
    getCurrentUser(): IUser | null;

    /**
     * Log in with credentials.
     * Will call 'onUserLoggedIn' if user is logged in successfully.
     * @param username The username.
     * @param password The password.
     * @param rememberMe Is session should be persistent.
     */
    login(username: string, password: string, rememberMe: boolean): Promise<IUser | null>;

    /**
     * Log out of the current session.
     * Will call 'onUserLoggedOut' if user logged out successfully.
     */
    logout(): Promise<void>;

    /**
     * Get session data from the server.
     * Will call 'onUserLoggedIn' if current current user was null, otherwise will call 'onUserLoggedOut' if current
     * user wasn't null.
     */
    checkSession(): Promise<IUser | null>;

    /**
     * Handles the user logged in event.
     * @param callback The callback.
     */
    onUserLoggedIn(callback: (user: IUser) => void): void;

    /**
     * Handles the user logged out event.
     * @param callback The callback.
     */
    onUserLoggedOut(callback: () => void): void;

    /**
     * Handles the request error event.
     * @param callback The callback.
     */
    onError(callback: (error: ErrorResponse) => void): void;
}

export class Client implements IClient {
    private readonly client: JsonServiceClient;
    private readonly onUserLoggedInCallbacks: Array<(user: IUser) => void>;
    private readonly onUserLoggedOutCallbacks: Array<() => void>;
    private readonly onErrorCallbacks: Array<(error: ErrorResponse) => void>;

    private currentUser: IUser | null;

    constructor() {
        this.client = new JsonServiceClient(global.BaseUrl || "/");
        this.onUserLoggedInCallbacks = [];
        this.onUserLoggedOutCallbacks = [];
        this.onErrorCallbacks = [];

        this.currentUser = this.authResponseToUser(global.AUTH as AuthenticateResponse);

        this.get.bind(this);
        this.post.bind(this);
        this.postBody.bind(this);
        this.put.bind(this);
        this.delete.bind(this);
    }

    public resolveUrl<T>(httpMethod: string, request: IReturn<T>, language?: string, format?: string): string {
        let url = this.client.createUrlFromDto(httpMethod, request);
        if (format !== undefined) {
            url = makeUrl(url, { language, format });
        }

        return url;
    }

    public get<T>(request: IReturn<T> | string): Promise<T> {
        const promise = this.client.get(request);
        return this.handleReject(promise);
    }

    public post<T>(request: IReturn<T>): Promise<T> {
        const promise = this.client.post(request);
        return this.handleReject(promise);
    }

    public postBody<T>(request: IReturn<T>, body: FormData): Promise<T> {
        const promise = this.client.postBody(request, body);
        return this.handleReject(promise);
    }

    public put<T>(request: IReturn<T>): Promise<T> {
        const promise = this.client.put(request);
        return this.handleReject(promise);
    }

    public delete<T>(request: IReturn<T> | string): Promise<T> {
        const promise = this.client.delete(request);
        return this.handleReject(promise);
    }

    public getCurrentUser = (): IUser | null => this.currentUser;

    public login = async (username: string, password: string, rememberMe: boolean): Promise<IUser | null> => {
        try {
            const response = await this.client.post(new Authenticate({
                provider: "credentials",
                userName: username,
                password,
                rememberMe }));
            const user = this.authResponseToUser(response);
            if (user !== null) {
                this.handleLoggedIn(user);
            }

            return user;
        } catch (reason) {
            const isUnauthorizedResponse =
                (reason as unknown as ErrorResponse)?.responseStatus?.errorCode === "Unauthorized";
            if (!isUnauthorizedResponse) {
                this.handleError(reason);
            }
            return null;
        }
    }

    public logout = async (): Promise<void> => {
        try {
            const response = await this.client.post(new Authenticate({ provider: "logout" }));
            const user = this.authResponseToUser(response);
            if (user !== null) {
                throw new Error("Unable to log out.");
            }

            this.handleLoggedOut();
        } catch (reason) {
            this.handleError(reason);
        }
    }

    public checkSession = async (): Promise<IUser | null> => {
        try {
            const current = this.getCurrentUser();
            const response = await client.post(new Authenticate());
            const user = this.authResponseToUser(response);

            if (user !== null && current === null) {
                this.handleLoggedIn(user);
            }
            if (user === null && current !== null) {
                this.handleLoggedOut();
            }

            return user;
        } catch (reason) {
            this.handleError(reason);
            return null;
        }
    }

    public onUserLoggedIn = (callback: (user: IUser) => void) =>
        this.onUserLoggedInCallbacks.push(callback)

    public onUserLoggedOut = (callback: () => void) =>
        this.onUserLoggedOutCallbacks.push(callback)

    public onError = (callback: (error: ErrorResponse) => void) =>
        this.onErrorCallbacks.push(callback)

    private async handleReject<T>(promise: Promise<T>): Promise<T> {
        try {
            return await promise;
        }
        catch (reason) {
            this.handleError(reason);
            throw reason;
        }
    }

    private authResponseToUser = (response: AuthenticateResponse): IUser | null =>
        response?.userId === undefined ? null : new User(response)

    private handleLoggedIn = (user: IUser) => {
        this.currentUser = user;

        this.onUserLoggedInCallbacks.forEach(callback => {
            callback(user);
        });
    }

    private handleLoggedOut = () => {
        this.currentUser = null;

        this.onUserLoggedOutCallbacks.forEach(callback => {
            callback();
        });
    }

    private handleError = (reason: any) => {
        this.onErrorCallbacks.forEach(callback => {
            callback((reason as unknown as ErrorResponse));
        });
    }
}

export const client: IClient = new Client();