import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Col, Table } from "reactstrap";
import { defer } from "rxjs";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    DocumentTitle,
    RouteLink,
    RouteUserProps
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { RequestStatistics } from "src/shared/dtos";
import { routes } from "src/shared/routes";
import styled from "styled-components";
import api from "../api";

const StyledHighcharts = styled.div`
    text.highcharts-credits {
        display:none !important;
    }
`;

export const DistributionView = withRouter(({ match, user }: RouteComponentProps<{ id: string, movingAvgDays: string, endDate: string }> & RouteUserProps) => {
    const [dataCategories, setDataCategories] = useState<string[]>();
    const id = useMemo(() => parseInt(match.params.id, 10), [match.params.id]);
    const movingAvgDays = useMemo(() => parseInt(match.params.id, 10), [match.params.movingAvgDays]);
    const [requestStatistics, setRequestStatistics] = useState<RequestStatistics>();

    useEffect(() => {
        const subscription = defer(() => api.getById(id)).subscribe(setRequestStatistics);
        return () => subscription.unsubscribe();
    }, [id]);

    const dateRenderer = (value: string) => {
        const date = new Date(value);
        return date.toLocaleDateString();
    };

    const decimalRenderer = (value: number) => {
        return value.toLocaleString(
            undefined,
            { maximumFractionDigits: 3, minimumFractionDigits: 3 }
        );
    };

    const series = useMemo(() => {
        if (requestStatistics === undefined) {
            return undefined;
        }

        const categories = requestStatistics.averageDistribution?.data?.map(r => r.start.toFixed(5));
        setDataCategories(categories);

        const countSeries = {
            name: "Count", type: "spline", data: requestStatistics.averageDistribution?.data?.map(r => r.count)
        };
        return countSeries;
    }, [requestStatistics]);

    const chartOptions = {
        chart: {
            zoomType: "x"
        },
        title: {
            text: "<strong>Distribution for path - </strong> '" + requestStatistics?.path + "'"
        },
        plotOptions: {
            column: {
                pointPadding: 0.05,
                borderWidth: 0,
                groupPadding: 0,
                shadow: false
            },
            scrollablePlotArea: {
                minWidth: 700,
                scrollPositionX: 1
            }
        },
        yAxis: {
            title: {
                text: "Count",
            }
        },
        xAxis: {
            title: {
                text: "Data",
            },
            type: "linear",
            categories: dataCategories
        },
        series,
        scrollbar: {
            enabled: true
        }
    };

    return (
        <VerticalBox>
            <DocumentTitle title={`Request Statistics Distribution - for '${requestStatistics?.path}'`} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.requestStatistics}>
                                Request Statistics
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink
                                user={user}
                                to={{ route: routes.viewRequestStatistic, args: { path: encodeURIComponent(requestStatistics?.path!), movingAvgDays, endDate: match.params.endDate } }}
                                className="d-inline">
                                Path - '{requestStatistics?.path}'
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            Distribution for date {requestStatistics !== undefined ? dateRenderer(requestStatistics.date) : ""}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    <Col sm={{ size: 8, offset: 2 }}>
                        {requestStatistics !== undefined &&
                            <Table>
                                <tbody>
                                    <tr>
                                        <td><strong>Date: </strong>{dateRenderer(requestStatistics.date)}</td>
                                        <td><strong>Count: </strong>{decimalRenderer(requestStatistics.count)}</td>
                                        <td><strong>Total (ms): </strong>{decimalRenderer(requestStatistics.totalMilliseconds)}</td>
                                        <td><strong>Avg (ms): </strong>{decimalRenderer(requestStatistics.averageMilliseconds)}</td>
                                        <td><strong>STD (ms): </strong>{decimalRenderer(requestStatistics.standardDeviationMilliseconds)}</td>
                                    </tr>
                                </tbody>
                            </Table>}
                        <StyledHighcharts>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chartOptions}
                            />
                        </StyledHighcharts>
                    </Col>
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});