import React, { FC, useCallback } from "react";
import type { MouseEvent } from "react";
import styled from "styled-components";
import { Nav, NavItem, NavLink } from "reactstrap";

export const StyledNav = styled(Nav)`
    background: #ffffff;
`;

export const StyledNavItem = styled(NavItem) <{ highlight?: number }>`
    .nav-link.active {
        background: ${props => props.highlight === 1 ? "lightyellow" : ""};
    }
`;

export const NavHeader = styled.div`
    flex: 0 1 auto;
`;

interface Props {
    page: number;
    setPage: (val: number) => void;
    links: string[];
}

export const NavTabs: FC<Props> = ({ links, page, setPage }) => {
    const changePage = useCallback((val: number) => (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setPage(val);
    }, []);

    return (
        <NavHeader>
            <StyledNav tabs>
                {links.map((link, idx) => (
                    <StyledNavItem key={link}>
                        <NavLink href="#" key={link} active={page === idx} onClick={changePage(idx)}>
                            {link}
                        </NavLink>
                    </StyledNavItem>
                ))}
            </StyledNav>
        </NavHeader>
    );
};