import React, { forwardRef, useCallback, useRef } from "react";
import { Input } from "reactstrap";
import { PropertyEditorProps } from "../PropertyEditorProps";
import { setFromInput } from "src/shared/helpers";
import { StyledTooltip } from "../../StyledTooltip";

export const IntegerPropertyEditor = forwardRef(
    (props: PropertyEditorProps<number | undefined>, ref: React.MutableRefObject<HTMLDivElement>) => {
        const tooltipRef = useRef<HTMLDivElement>(null);
        const onChange = useCallback(
            (v: number) => props.onChange(v ? Math.trunc(v) : undefined), [props.onChange]);
        return (
            <div ref={ref}>
                <Input
                    type="number"
                    min="0"
                    step="1"
                    id={props.id}
                    value={props.value ? props.value : ""}
                    onChange={setFromInput(onChange)}
                    disabled={props.disabled}
                    invalid={props.errors && props.errors.length > 0}
                />
                <div ref={tooltipRef} />
                <StyledTooltip placement="bottom" isOpen={props.errors && props.errors.length > 0} target={tooltipRef}>
                    {props.errors?.map((err, i) => <div key={`${err}_${i}`}>{err}</div>)}
                </StyledTooltip>
            </div>
        );
    });
