import {
    AdBannerUnit,
    AdBrowserType,
    AdBrowserUnit,
    AdDynamicWebUnit,
    AdOrientationType,
    AdPixelType,
    AdRichMediaOverlayUnit,
    AdSideUnit,
    AdUnit,
    AdUnitTypes,
    SideAdUnitTypes
} from "src/shared/dtos";
import { deepEqual } from "src/shared/helpers";

export type AdUnitState = {
    current?: AdUnit,
    initial?: AdUnit,
    changed: boolean
};

export type AdUnitAction =
    { kind: "set", value: AdUnit, initialValue?: AdUnit } |
    { kind: "update", value: Partial<AdUnit> } |
    { kind: "selectType", value: AdUnitTypes };

export const makeEmptyAdBannerUnit = (adUnitId: number): AdBannerUnit => ({
    id: adUnitId,
    bannerHeader: "Advertisement",
    bannerImageUrl: "",
    bannerSizeX: 0,
    bannerSizeY: 0,
    bannerUrl: ""
});

export const makeEmptyAdBrowserUnit = (adUnitId: number): AdBrowserUnit => ({
    id: adUnitId,
    adBrowserType: AdBrowserType.Default,
    url: ""
});

export const makeEmptyAdDynamicWebUnit = (adUnitId: number): AdDynamicWebUnit => ({
    id: adUnitId,
    url: "",
    sizeX: 0,
    sizeY: 0,
    header: "",
    launchInDesktopBrowser: true
});

export const makeEmptyAdRichMediaOverlayUnit = (adUnitId: number): AdRichMediaOverlayUnit => ({
    id: adUnitId,
    centerX: 0,
    centerY: 0,
    pixelType: AdPixelType.Dip,
    delay: 0,
    duration: 0,
    orientation: AdOrientationType.Any,
    sizeX: 0,
    sizeY: 0,
    animations: undefined,
    content: undefined,
    targetUrl: undefined
});

export const makeEmptySideAdUnit = (adUnitId: number): AdSideUnit => ({
    id: adUnitId,
    side: SideAdUnitTypes.Left,
    url: "",
    width: 0,
    height: 0
});

export const adUnitReducer = (state: AdUnitState, action: AdUnitAction): AdUnitState => {
    switch (action.kind) {
        case "set": {
            return {
                current: action.value,
                initial: action.initialValue,
                changed: false
            };
        }
        case "update": {
            if (!state.current) {
                return state;
            }
            const newCurrent = { ...state.current, ...action.value };
            return {
                ...state,
                current: newCurrent,
                changed: !deepEqual(newCurrent, state.initial)
            };
        }
        case "selectType": {
            if (!state.current || !state.initial) {
                return state;
            }
            const newAdUnits: Pick<AdUnit, "adBannerUnit" | "adBrowserUnit" | "adDynamicWebUnit" | "adRichMediaOverlayUnit" | "adSideUnit"> = {
                adBannerUnit: action.value === AdUnitTypes.BannerAction ? makeEmptyAdBannerUnit(state.current.id) : undefined,
                adDynamicWebUnit: action.value === AdUnitTypes.DynamicWeb ? makeEmptyAdDynamicWebUnit(state.current.id) : undefined,
                adRichMediaOverlayUnit: action.value === AdUnitTypes.RichMediaOverlay ? makeEmptyAdRichMediaOverlayUnit(state.current.id) : undefined,
                adBrowserUnit: action.value === AdUnitTypes.BrowserAction ? makeEmptyAdBrowserUnit(state.current.id) : undefined,
                adSideUnit: action.value === AdUnitTypes.SideAd ? makeEmptySideAdUnit(state.current.id) : undefined,
            };
            const newCurrent: AdUnit = { ...state.current, ...newAdUnits, unitType: action.value };
            const newInitial: AdUnit = { ...state.initial, ...newAdUnits, unitType: action.value };
            return {
                ...state,
                current: newCurrent,
                initial: newInitial,
                changed: !deepEqual(newCurrent, newInitial)
            };
        }
    }
};

export const adUnitReducerDefault: AdUnitState = {
    current: undefined,
    initial: undefined,
    changed: false
};