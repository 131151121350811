import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExtendedThemedProps, ThemedProps } from "../../../../types";

export const TagIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    font-size: 22px;
`;

export const TagIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
    vertical-align: middle;
    text-align: center;
`;

export const TagLabel = styled.div`
    position: absolute;
    bottom: -25px;
    height: 25px;
    width: 90%;
    left: 5%;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px;
    text-transform: uppercase;
`;

interface TagContainerProps {
    active: boolean;
}

export const TagContainer = styled.div<TagContainerProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    padding: 10px;
    height: 100%;
    cursor: pointer;

    ${TagIconContainer} {
        color: ${(p: ExtendedThemedProps<TagContainerProps>) => p.active ? p.theme.SubCategoryActiveIconColor : p.theme.SubCategoryIconColor};
        border-color: ${(p: ExtendedThemedProps<TagContainerProps>) => p.active ? p.theme.SubCategoryActiveIconColor : p.theme.SubCategoryIconColor};
    }

    ${TagLabel} {
        color: ${(p: ExtendedThemedProps<TagContainerProps>) => p.active ? p.theme.SubCategoryActiveTextColor : p.theme.SubCategoryTextColor};
        background-color: ${(p: ThemedProps) => p.theme.SubCategoryBarBackgroundColor};
    }

    &:hover {
        ${TagIconContainer} {
            color: ${(p: ThemedProps) => p.theme.SubCategoryMouseOverIconColor};
            border-color: ${(p: ThemedProps) => p.theme.SubCategoryMouseOverIconColor};
        }

        ${TagLabel} {
            color: ${(p: ThemedProps) => p.theme.SubCategoryMouseOverTextColor};
        }
    }

    &:active {
        ${TagIconContainer} {
            color: ${(p: ThemedProps) => p.theme.SubCategoryMouseClickIconColor};
            border-color: ${(p: ThemedProps) => p.theme.SubCategoryMouseClickIconColor};
        }

        ${TagLabel} {
            color: ${(p: ThemedProps) => p.theme.SubCategoryMouseClickTextColor};
        }
    }
`;