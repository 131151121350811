import React, { forwardRef, useMemo, useRef } from "react";
import { PropertyEditorProps } from "../PropertyEditorProps";
import { ColorOption } from "src/shared/dtos";
import { ColorOptionsEditor } from "../../ColorOptionsEditor";
import { StyledTooltip } from "../../StyledTooltip";

export const InstallerColorOptionsPropertyEditor = forwardRef(
    (props: PropertyEditorProps<ColorOption[] | undefined>, ref: React.MutableRefObject<HTMLDivElement>) => {
        const tooltipRef = useRef<HTMLDivElement>(null);
        const itemValues = useMemo(() => props.value ?? [], [props.value]);

        const addOption = (item: ColorOption) => {
            const values = itemValues.concat(item);
            props.onChange(values.length === 0 ? undefined : values);
        };

        const removeOption = (item: ColorOption) => {
            const values = itemValues.filter(i => i !== item);
            props.onChange(values.length === 0 ? undefined : values);
        };

        const listContainerId = `${props.id}_colorOptionsList`;

        return (
            <div ref={ref}>
                <ColorOptionsEditor
                    listContainerId={listContainerId}
                    mainInputId={props.id}
                    items={itemValues}
                    add={addOption}
                    remove={removeOption}
                    disabled={props.disabled}
                    invalid={props.errors && props.errors.length > 0}
                />
                <div ref={tooltipRef} />
                <StyledTooltip placement="bottom" isOpen={props.errors && props.errors.length > 0} target={tooltipRef}>
                    {props.errors && props.errors.slice(0, 4).map((err, i) => <div key={`${err}_${i}`}>{err}</div>)}
                </StyledTooltip>
            </div>
        );
    });
