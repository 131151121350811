import React, { useEffect, useState, useReducer, useMemo, useCallback, useRef, } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col } from "reactstrap";
import { defer } from "rxjs";
import { Tag } from "src/shared/dtos";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    LeavingViewProtector,
    Loader,
    DocumentTitle,
    RouteLink,
    RouteUserProps,
    EditError,
    EditErrorOptions,
    notifySuccess
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { useSubscription, ValidateAsyncRef } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { makeEmptyTag, tagReducer, tagReducerDefault } from "./tagReducer";
import api from "../api";
import { EditForm } from "./EditForm";

type Props = RouteComponentProps<{ id: string }> & RouteUserProps;

export const EditView = withRouter(({ match, history, user }: Props) => {

    const editFormRef = useRef<ValidateAsyncRef>(null);
    const tagId = match.params.id;
    const isNew = useMemo(() => tagId === undefined, [tagId]);

    const [tags, setTags] = useState<Tag[]>();
    const [saving, setSaving] = useState(false);
    const [tagState, changeTagState] = useReducer(tagReducer, tagReducerDefault);
    const [error, setError] = useState<EditErrorOptions | undefined>();

    const backToList = useCallback(() => history.push(routes.tags.url()), []);

    const loadTags = useSubscription(() => defer(() => api.list()).subscribe({
        next: res => {
            setTags(res);
            if (tagId === undefined) {
                changeTagState({ kind: "set", value: makeEmptyTag() });
                return;
            }
            const targetTag = res.find(t => t.id === tagId);
            if (!targetTag) {
                setError({
                    text: `Tag with id ${tagId} does not exist.`,
                    actionText: "Back to list",
                    action: backToList
                });
                return;
            }
            changeTagState({ kind: "set", value: targetTag });
        },
        error: () => setError({
            text: `Failed to load tags.`,
            actionText: "Back to list",
            action: backToList
        })
    }), [tagId]);

    useEffect(() => {
        loadTags();
    }, [loadTags]);

    const saveTag = useSubscription(
        (tag: Tag) => defer(
            () => isNew ? api.create(tag) : api.update(tag)).subscribe({
                next: res => {
                    notifySuccess(`Tag has been saved successfully.`);
                    setSaving(false);
                    if (isNew) {
                        return history.push(routes.editTag.url({ id: res.id }));
                    }
                    changeTagState({ kind: "set", value: res });
                },
                error: () => setSaving(false)
            }), [isNew]);

    const deleteTag = useSubscription((id: string) => defer(() => api.delete(id)).subscribe({
        next: () => {
            notifySuccess(`Tag deleted successfully.`);
            setSaving(false);
            backToList();
        },
        error: () => {
            setSaving(false);
            setError({
                text: "Unable to delete Tag.",
                actionText: "Continue",
                action: () => setError(undefined)
            });
        }
    }), []);

    const onSubmit = useCallback(async () => {
        const currentTag = tagState.current;
        if (saving || currentTag === undefined) {
            return;
        }

        setSaving(true);
        const isEditFormValid = await editFormRef.current?.validate();
        if (!isEditFormValid) {
            setSaving(false);
            return;
        }
        saveTag(currentTag);
    }, [tagState.current, saveTag, saving, editFormRef.current]);

    const onDelete = useCallback(() => {
        if (saving || !tagId) {
            return;
        }

        setSaving(true);
        deleteTag(tagId);
    }, [tagId, saving]);

    const updateTag = useCallback((value: Partial<Tag>) => changeTagState({ kind: "update", value }), []);

    const title = useMemo(
        () => {
            const tagTitle = isNew
                ? "[new tag]"
                : (tagState.current !== undefined
                    ? tagState.current.id
                    : "Loading...");
            return `Tag - ${tagTitle}`;
        },
        [tagState.current]);

    return (
        <VerticalBox>
            <DocumentTitle title={title} />
            <LeavingViewProtector showConfirmation={tagState.changed && !isNew} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.tags}>
                                Tags
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {isNew ? "[new tag]" : tagId}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    {error && <EditError error={error} />}
                    {!error && (tags === undefined || tagState.current === undefined) && <Loader />}
                    {!error && tagState.current !== undefined && tags !== undefined &&
                        <Row>
                            <Col
                                sm={{ offset: 1, size: 10 }}
                                md={{ offset: 2, size: 8 }}
                                lg={{ offset: 2, size: 6 }}
                                xl={{ offset: 3, size: 5 }}
                            >
                                <Row>
                                    <Col>
                                        <EditForm
                                            ref={editFormRef}
                                            value={tagState.current}
                                            initial={tagState.initial}
                                            tags={tags}
                                            update={updateTag}
                                            saving={saving}
                                            onSubmit={onSubmit}
                                            onDelete={onDelete}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});