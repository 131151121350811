import styled from "styled-components";

export const ToolBox = styled.div`
    display: flex;
    height: 50px;
    padding: 2px 0.5rem;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    box-shadow: #eee 0px 2px 4px 2px;
    margin-bottom: 1rem;
`;

export const ToolBoxLeftContainer = styled.div`
    margin-right: auto;
`;

export const ToolBoxRightContainer = styled.div`
    margin-left: auto;
`;