import React, { useCallback } from "react";
import { RequestStatisticsSummary } from "src/shared/dtos";
import { Table } from "reactstrap";
import { RouteLink } from "src/shared/components";
import { IUser } from "src/shared/client";
import { routes } from "src/shared/routes";

interface Props {
    list: RequestStatisticsSummary[];
    movingAvgDays: number;
    endDate: string;
    user: IUser | null;
}

export const RequestStatisticsListView = ({ list, movingAvgDays, endDate, user }: Props) => {
    const sortedList = list.slice();
    sortedList.sort((item1, item2) => item1.date > item2.date ? -1 : item1.date < item2.date ? 1 : 0 );

    const load = useCallback(() => {
        return new Promise<boolean>(resolve => { resolve(true); });
    }, []);

    const dateRenderer = (value: string) => {
        const date = new Date(value);
        return date.toLocaleDateString();
    };

    const intRenderer = (value: number) => {
        return value.toLocaleString(
            undefined,
            { maximumFractionDigits: 0, minimumFractionDigits: 0 }
        );
    };

    const decimalRenderer = (value: number) => {
        return value.toLocaleString(
            undefined,
            { maximumFractionDigits: 3, minimumFractionDigits: 3 }
        );
    };

    return (
        <React.Fragment>
            <Table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Count</th>
                        <th>Moving Avg Count</th>
                        <th>Total (ms)</th>
                        <th>Moving Avg Total (ms)</th>
                        <th>Avg (ms)</th>
                        <th>Moving Avg Avg (ms)</th>
                        <th>STD (ms)</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedList.map(item => {
                        return (<tr key={item.id}>
                            <th scope="row">{dateRenderer(item.date)}</th>
                            <td>{intRenderer(item.count)}</td>
                            <td>{intRenderer(item.movingAverageCount)}</td>
                            <td>{decimalRenderer(item.totalMilliseconds)}</td>
                            <td>{decimalRenderer(item.movingAverageTotalMilliseconds)}</td>
                            <td>
                                <RouteLink user={user} to={{ route: routes.requestStatisticsDistribution, args: { id: item.id, movingAvgDays, endDate } }}>
                                    {decimalRenderer(item.averageMilliseconds)}
                                </RouteLink>
                            </td>
                            <td>{decimalRenderer(item.movingAverageAverageMilliseconds)}</td>
                            <td>{decimalRenderer(item.standardDeviationMilliseconds)}</td>
                        </tr>);
                    })}
                </tbody>
            </Table>
        </React.Fragment >);
};