import { initialConfirmModalContent, ModalSize } from "../components/confirmWindow/confirmContext";
import { useConfirmContext } from "../components/confirmWindow/ConfirmProvider";

interface ConfirmArgs {
    body: JSX.Element | string;
    title?: string;
    yesBtnText?: string;
    noBtnText?: string;
    size?: ModalSize;
}
// useConfirm hook
let resolveCallback = (value: boolean) => { return; };

export const useConfirm = () => {
    const { actions, state } = useConfirmContext();

    const onConfirm = () => {
        actions.setShow(false);
        setTimeout(() => resolveCallback(true), 300);
    };

    const onCancel = () => {
        actions.setShow(false);
        setTimeout(() => resolveCallback(false), 300);
    };

    const confirm = (args: ConfirmArgs) => {
        const { body, title, yesBtnText, noBtnText, size } = args;
        actions.setContent({
            body,
            title: title ?? initialConfirmModalContent.title,
            yesBtnText: yesBtnText ?? initialConfirmModalContent.yesBtnText,
            noBtnText: noBtnText ?? initialConfirmModalContent.noBtnText
        });
        actions.setSize(size ?? "md");
        actions.setShow(true);
        return new Promise<boolean>((res, rej) => {
            resolveCallback = res;
        });
    };

    return { confirm, onConfirm, onCancel, state };
};