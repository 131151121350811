import React, { useState, useCallback, useMemo, useEffect } from "react";
import { defer, forkJoin } from "rxjs";
import { AdUnitTypes, AdUnitSummary, AdUnitListDataType, OrderDirection, AdBannerSize, AdUnitTriggerType } from "src/shared/dtos";
import { BreadcrumbItem } from "reactstrap";
import { Breadcrumb, DocumentTitle, RouteLink, notifyError, notifySuccess, RouteUserProps, Loader } from "src/shared/components";
import {
    ActionButton,
    BooleanIndicator,
    BooleanSearch,
    Column,
    DefaultCellRenderer,
    InfiniteTable,
    LoadRequest,
    multiValueSearchFactory,
    SearchOptions,
    StringSearch,
    SuggestionTag,
    TopPanelLeftContainer,
    TopPanelRendererProps
} from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { Content, VerticalBox } from "src/shared/components/flex";
import { routes } from "src/shared/routes";
import { deserializeQueryParameters, pushUrl, QueryParameterDescriptor, serializeQueryParameters, splitCamelCase, useSubscription } from "src/shared/helpers";
import api, { ListRequest } from "../api";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { adUnitTypeToString } from "src/shared/helpers/adUnitTypeToString";
import { BannerDimensionRenderer } from "src/shared/components/InfiniteTable/BannerDimensionRenderer";

const keyDataTypes: { [key: string]: AdUnitListDataType } = {
    "campaignName": AdUnitListDataType.CampaignName,
    "enabledCampaign": AdUnitListDataType.CampaignDisabled,
    "dimensions": AdUnitListDataType.Dimensions,
    "enabled": AdUnitListDataType.Disabled
};

const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType as AdUnitListDataType);

function getSearchValueByKey<T>(key: string, searchOptions?: Array<SearchOptions<unknown>>): T | undefined {
    return searchOptions?.find(s => s.key === key)?.value as T;
}

const searchQueryParameterDescriptors: QueryParameterDescriptor[] = [
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    { paramType: "search", paramName: "campaignName" },
    {
        paramType: "search",
        paramName: "triggerEvent",
        serializer: (value: string[]) => value?.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "type",
        serializer: (value: string[]) => value?.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "dimensions",
        serializer: (value: number[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",").map(v => parseInt(v, 10))
    },
    { paramType: "search", paramName: "enabled", serializer: "boolean", deserializer: "boolean" },
    { paramType: "search", paramName: "enabledCampaign", serializer: "boolean", deserializer: "boolean" }
];

export const ListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [tableFilters, sort] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...searchQueryParameterDescriptors);
        const searchParams = allParams
            .filter(p => p.paramType === "search")
            .map(qp => ({ key: qp.paramName, value: qp.value } as SearchOptions<unknown>));
        const orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        const orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;

        return [searchParams, { key: orderBy, order: orderDir } as SortOptions];
    }, [location.search]);

    const [items, setItems] = useState<AdUnitSummary[]>([]);
    const [adUnitTriggers, setAdUnitTriggers] = useState<AdUnitTriggerType[]>();
    const [adUnitTypes, setAdUnitTypes] = useState<AdUnitTypes[]>();
    const [adBannerSizes, setAdBannerSizes] = useState<AdBannerSize[]>();

    const updateAvailableOptions = useSubscription(() => forkJoin([
        defer(() => api.getAllAdUnitTriggers()),
        defer(() => api.getAdUnitTypes()),
        defer(() => api.getAdBannerSizes())]
    ).subscribe(results => {
        setAdUnitTriggers(results[0]);
        const unitTypes = results[1].filter((t, i) => t[i]);
        setAdUnitTypes(unitTypes);
        const nonStandard = { id: 0, sizeX: Number.MAX_VALUE, sizeY: Number.MAX_VALUE } as AdBannerSize;
        const bannerSizes = results[2].filter(s => s);
        bannerSizes.push(nonStandard);
        bannerSizes.sort((a, b) => (a.sizeX - b.sizeX) * 1000 + (a.sizeY - b.sizeY));
        setAdBannerSizes(bannerSizes);
    }), [api.getAllAdUnitTriggers, api.getAdUnitTypes, api.getAdBannerSizes]);

    useEffect(() => {
        updateAvailableOptions();
    }, [updateAvailableOptions]);

    const adBannerSizeIds = useMemo(() => adBannerSizes !== undefined
        ? adBannerSizes.map(s => s.id)
        : [], [adBannerSizes]);

    const adBannerSizesMapper = (id: number): SuggestionTag => {
        const found = adBannerSizes?.find(c => c.id === id);
        return {
            value: found?.id ?? "",
            label: (id === 0 ? "Non Standard" : `${found?.sizeX}x${found?.sizeY}`) ?? ""
        };
    };

    const typeList = useMemo(() => adUnitTypes !== undefined
        ? adUnitTypes.map(t => t)
        : [], [adUnitTypes]);

    const adUnitTypesMapper = (id: string): SuggestionTag => {
        const found = adUnitTypes?.find(t => t === id);
        return {
            value: found ?? "",
            label: found !== undefined ? splitCamelCase(String(found)) : ""
        };
    };

    const triggerList = useMemo(() => adUnitTriggers !== undefined
        ? adUnitTriggers.map(t => t)
        : [], [adUnitTriggers]);

    const adUnitTriggersMapper = (id: string): SuggestionTag => {
        const found = adUnitTriggers?.find(t => t === id);
        return {
            value: found ?? "",
            label: found !== undefined ? splitCamelCase(String(found)) : ""
        };
    };

    const updateItems = useSubscription((
        request: ListRequest,
        isReload: boolean,
        resolve: (val: boolean) => void) => defer(() => api.list(request)).subscribe(result => {
            if (result.length > 0) {
                setItems(prev => isReload ? result : prev.concat(result));
            }
            resolve(result.length === 0);
    }), [api.list]);

    const load = useCallback((request: LoadRequest) => {
        if (request.reload) {
            setItems([]);
        }

        const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : AdUnitListDataType.CampaignName;
        const searchRequest = {
            orderBy: orderByKey,
            orderDir: orderByKey !== undefined
                ? request.sort?.order === SortOrder.DESC ? OrderDirection.Desc : OrderDirection.Asc
                : undefined,
            campaignName: getSearchValueByKey<string>("campaignName", request.search),
            dimensions: getSearchValueByKey<number[]>("dimensions", request.search),
            triggerEvent: getSearchValueByKey<string[]>("triggerEvent", request.search),
            type: getSearchValueByKey<string[]>("type", request.search),
            enabledCampaign: getSearchValueByKey<boolean>("enabledCampaign", request.search),
            enabled: getSearchValueByKey<boolean>("enabled", request.search)
        };

        const requestBody: ListRequest = {
            offset: request.offset,
            limit: request.limit,
            orderBy: searchRequest.orderBy,
            orderDirection: searchRequest.orderDir ?? OrderDirection.Asc,
            campaignNameSearch: searchRequest.campaignName,
            triggerEventSearch: searchRequest.triggerEvent,
            typeSearch: searchRequest.type,
            dimensionByAdBannerSizeIdsSearch: searchRequest.dimensions?.filter(id => id !== 0),
            includeNonStandardSizesToDimensionSearch: searchRequest.dimensions !== undefined
                ? searchRequest.dimensions.indexOf(0) !== -1
                : undefined,
            campaignDisabledSearch: searchRequest.enabledCampaign !== undefined ? !searchRequest.enabledCampaign : undefined,
            disabledSearch: searchRequest.enabled !== undefined ? !searchRequest.enabled : undefined
        };

        const url = routes.adUnits.url({
            searchParams: serializeQueryParameters(searchRequest, ...searchQueryParameterDescriptors)
        });
        pushUrl(location, history, url);

        return new Promise<boolean>(resolve => {
            updateItems(requestBody, request?.reload, resolve);
        });
    }, [items, location, updateItems]);

    const onDeleteAdUnit = useSubscription((unit: AdUnitSummary) => {
        defer(() => api.delete(unit.id)).subscribe({
            next: () => {
                notifySuccess(`Ad Unit has been successfully deleted.`);
                setItems(old => old.filter(i => i.id !== unit.id));
            },
            error: () => notifyError(`Unable to delete Ad Unit.`)
        });
    }, [api.delete]);

    const campaignNameRenderer = useMemo(() => (props: CellProps<unknown, AdUnitSummary>) => {
        const { cellData, ...restProps } = props;
        const link = (
            <RouteLink
                user={user}
                to={{ route: routes.editAdCampaign, args: { id: props.rowData.adCampaignId } }}
            >
                {props.rowData.adCampaignName}
            </RouteLink>
        );

        return <DefaultCellRenderer cellData={link} title={props.rowData.adCampaignName} {...restProps} />;
    }, []);

    const enabledCampaignRenderer = useMemo(() => (props: CellProps<boolean, AdUnitSummary>) => {
        const { cellData, ...restProps } = props;
        const value = <BooleanIndicator value={cellData} />;
        const title = `Ad Campaign is ${cellData ? "enabled" : "disabled"}.`;
        return <DefaultCellRenderer cellData={value} title={title} centerText={true} {...restProps} />;
    }, []);

    const bannerDimensionRenderer = useMemo(() => (props: CellProps<unknown, AdUnitSummary>) => {
        const { ...restProps } = props;

        return <BannerDimensionRenderer
            unitType={props.rowData.unitType}
            sizeX={props.rowData.unitType === AdUnitTypes.BannerAction ? props.rowData.adBannerUnit?.bannerSizeX : props.rowData.adRichMediaOverlayUnit?.sizeX}
            sizeY={props.rowData.unitType === AdUnitTypes.BannerAction ? props.rowData.adBannerUnit?.bannerSizeY : props.rowData.adRichMediaOverlayUnit?.sizeY}
            pixelType={props.rowData.adRichMediaOverlayUnit?.pixelType}
            isNonStandard={props.rowData.hasNonStandardDimension}
            {...restProps} />;
    }, []);

    const enabledRenderer = useMemo(() => (props: CellProps<boolean, AdUnitSummary>) => {
        const { cellData, ...restProps } = props;
        const value = <BooleanIndicator value={cellData} />;
        const title = `Ad Unit is ${cellData ? "enabled" : "disabled"}.`;
        return <DefaultCellRenderer cellData={value} title={title} centerText={true} {...restProps} />;
    }, []);

    const actionsRenderer = useMemo(() => (props: CellProps<number, AdUnitSummary>) => {
        const { cellData, ...restProps } = props;
        const onDelete = () => onDeleteAdUnit(props.rowData);
        const value =
            <div>
                <RouteLink
                    user={user}
                    to={{ route: routes.editAdUnit, args: { id: props.rowData.id, adCampaignId: props.rowData.adCampaignId } }}
                >
                    <ActionButton icon="pencil-alt" className="text-primary" />
                </RouteLink>
                <ActionButton icon="trash" className="text-danger" onClick={onDelete} />
            </div>;

        return <DefaultCellRenderer cellData={value} centerText={true} {...restProps} />;
    }, [onDeleteAdUnit]);

    const TopPanel = useMemo(() =>
        (props: TopPanelRendererProps) =>
            <props.blockElement>
                <TopPanelLeftContainer>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Ad Units</BreadcrumbItem>
                    </Breadcrumb>
                </TopPanelLeftContainer>
            </props.blockElement>, []);

    const select = makeSelect<AdUnitSummary>();

    const adTriggersGetter = useCallback(
        () => select(a => a.adUnitTriggers?.map(c => splitCamelCase(c.triggerType)).join(", ")),
     [select]);

    return (
        <VerticalBox>
            <DocumentTitle title="Ad Units" />
            <Content overflowHidden>
                {adBannerSizes === undefined && adUnitTypes === undefined && AdUnitTriggerType === undefined && <Loader />}
                {adBannerSizes !== undefined && adUnitTypes !== undefined && AdUnitTriggerType !== undefined &&
                    <InfiniteTable
                        items={items}
                        filters={tableFilters}
                        sortBy={sort}
                        topPanelRenderer={TopPanel}
                        load={load}
                    >
                        <Column
                            key="campaignName"
                            title="Campaign Name"
                            dataGetter={select(a => a.adCampaignName)}
                            width={200}
                            sortable={true}
                            cellRenderer={campaignNameRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="enabledCampaign"
                            dataGetter={select(a => !a.adCampaignIsDisabled)}
                            title="Campaign Enabled"
                            width={150}
                            sortable={true}
                            cellRenderer={enabledCampaignRenderer}
                            searchRenderer={BooleanSearch}
                        />
                        <Column
                            key="type"
                            dataGetter={select(a => adUnitTypeToString(a.unitType))}
                            title="Type"
                            width={200}
                            sortable={false}
                            searchRenderer={multiValueSearchFactory(typeList, adUnitTypesMapper)}
                        />
                        <Column
                            key="triggerEvent"
                            dataGetter={adTriggersGetter()}
                            title="Trigger Event"
                            width={500}
                            sortable={false}
                            searchRenderer={multiValueSearchFactory(triggerList, adUnitTriggersMapper)}
                        />
                        <Column
                            key="dimensions"
                            title="Banner Dimensions"
                            width={300}
                            sortable={true}
                            cellRenderer={bannerDimensionRenderer}
                            searchRenderer={multiValueSearchFactory(adBannerSizeIds, adBannerSizesMapper)}
                        />
                        <Column
                            key="enabled"
                            dataGetter={select(a => !a.isDisabled)}
                            title="Enabled"
                            width={100}
                            sortable={true}
                            cellRenderer={enabledRenderer}
                            searchRenderer={BooleanSearch}
                        />
                        <Column
                            key="actions"
                            dataGetter={select(a => a.id)}
                            title=""
                            width={200}
                            sortable={false}
                            cellRenderer={actionsRenderer}
                        />
                    </InfiniteTable>}
            </Content>
        </VerticalBox>);
});