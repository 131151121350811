import { client } from "src/shared/client";
import {
    GetKeyBindingsSchemas,
    GetTags,
    GetTag,
    Tag,
    ManageTag,
    ManageTagOrder,
    DeleteTag,
    TagsOrderBy,
    OrderDirection
} from "src/shared/dtos";
import { ExcludeMethods } from "src/shared/helpers";

export type UpdateTagOrderRequest = ExcludeMethods<ManageTagOrder>;

export default {
    list: () => client.get(new GetTags({ orderBy: TagsOrderBy.Order, orderDirection: OrderDirection.Asc })),
    get: (id: string) => client.get(new GetTag({ id })),
    create: (tag: Tag) => client.post(new ManageTag({ tag })),
    update: (tag: Tag) => client.put(new ManageTag({ tag })),
    delete: (id: string) => client.delete(new DeleteTag({ id })),
    updateTagOrder: (req: UpdateTagOrderRequest) => client.post(new ManageTagOrder(req)),
};