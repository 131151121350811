import { classNames } from "@servicestack/client";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Input } from "reactstrap";
import styled, { keyframes } from "styled-components";
import { FormLabel, StyledTooltip } from "src/shared/components";
import { ensureInView, setFromInput } from "src/shared/helpers";
import { SourceSettingsItem } from "./sourceSettingsReducer";
import { SourceName } from "./SourceName";
import api from "../api";
import { defer, Observable, of } from "rxjs";
import { map } from "rxjs/operators";

const SourceItem = styled.li<{ default: boolean, new: boolean, hasNewItem: boolean, newItemHasError: boolean }>`
    cursor: pointer;
    position: ${props => props.default || props.new ? "sticky" : undefined};
    z-index: ${props => props.default || props.new ? "3" : undefined};
    top: ${props => props.default && props.hasNewItem ? "47px" : "0"};

    && {
        padding: ${props => props.new ? "0" : undefined};
        background-color: ${props => props.new ? "initial" : undefined};
        border-color: ${props => props.newItemHasError ? "#d9534f" : undefined};
    }
`;

const StyledInput = styled(Input)`
    font-style: italic;
    padding: 0.75rem 1.25rem;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;

    :focus {
        border: none;
        box-shadow: none;
    }
`;

const FlexBox = styled.div`
    display: flex;
`;

const breatheAnimation = keyframes`
    0% { height: 3px; width: 3px; }
    30% { height: 13px; width: 13px; opacity: 1 }
    40% { height: 15px; width: 15px; opacity: 0.3; }
    100% { height: 3px; width: 3px; opacity: 0.6; }
`;

const SourceNameCheckIndicator = styled.div`
    margin-right: 15px;
    height: 3px;
    width: 3px;
    border-style: solid;
    border-width: 3px;
    border-radius: 50%;
    color: gray;
    opacity: 0;
    animation: 1s ${breatheAnimation} 0.5s infinite linear;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 45px;
    width: 45px;
`;

export interface SourceListItemRef {
    validate: () => Observable<boolean>;
}

type Props = {
    value: SourceSettingsItem,
    onSelect: () => void,
    updateSource: (value: string) => void,
    delete: () => void,
    saving: boolean,
    hasNewItem: boolean,
};

export const SourceListItem = forwardRef((props: Props, ref: React.MutableRefObject<SourceListItemRef>) => {
    const [isSourceNameInvalid, setSourceNameInvalid] = useState(false);
    const [validateSourceName, setValidateSourceName] = useState(false);
    const [animationVisible, setAnimationVisible] = useState(false);
    const className = classNames(
        "list-group-item",
        "list-group-item-action", {
        active: props.value.selected,
        disabled: props.saving
    });

    const itemRef = useRef<HTMLLIElement>(null);
    const isNew = props.value.type === "new";

    const validate = useCallback(() => isNew
        ? defer(() => api.isSourceNameExist(props.value.current.source)).pipe(map(r => !r))
        : of(true), [isNew, props.value.current.source]);
    useImperativeHandle(ref, () => ({ validate }), [validate]);

    useEffect(() => {
        if (itemRef.current === null || props.value.selected === false) {
            return;
        }

        ensureInView(itemRef.current.parentElement!, itemRef.current);
    }, [props.value.selected, itemRef.current]);

    const sourceRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (isNew && sourceRef.current !== null) {
            // HACK: Avoiding async copying operation makes focus lost to body by mouse click on copy button.
            setTimeout(() => sourceRef.current?.focus(), 0);
        }
    }, [props.value.type, sourceRef.current]);

    useEffect(() => {
        if (!validateSourceName) {
            return undefined;
        }

        const newSource = props.value.current.source;
        if (newSource === "") {
            setSourceNameInvalid(true);
        }
        else if (newSource !== undefined) {
            setAnimationVisible(true);
            const subscription = validate().subscribe({
                next: isValid => {
                    setSourceNameInvalid(!isValid);
                    setAnimationVisible(false);
                },
                error: () => setAnimationVisible(false)
            });

            return () => {
                setAnimationVisible(false);
                subscription.unsubscribe();
            };
        }

        return undefined;
    }, [props.value.current.source, validateSourceName]);

    const onBlurValidation = useCallback(() => setValidateSourceName(true), []);

    const handleKeyPress = useCallback((e: KeyboardEvent) => {
        if (e.key === "Escape") {
            e.stopPropagation();
            props.delete();
        }
    }, [props.delete]);

    return (
        <SourceItem
            key={props.value.initial.id}
            ref={itemRef}
            className={className}
            onClick={props.onSelect}
            default={props.value.type === "default"}
            new={isNew}
            hasNewItem={props.hasNewItem}
            newItemHasError={isNew && isSourceNameInvalid}
        >
            {isNew && (
                <React.Fragment>
                    <FlexBox>
                        <StyledInput
                            innerRef={sourceRef}
                            id="settingsSource"
                            type="text"
                            value={undefined}
                            placeholder="Enter source name"
                            onChange={setFromInput(props.updateSource)}
                            disabled={props.saving}
                            autoComplete="off"
                            onBlur={onBlurValidation}
                            onKeyUp={handleKeyPress}
                        />
                        {animationVisible &&
                            <Container>
                                <SourceNameCheckIndicator />
                            </Container>}
                    </FlexBox>
                    <StyledTooltip placement="bottom"
                        isOpen={isSourceNameInvalid}
                        target="settingsSource"
                        toggle={undefined}
                    >
                        {props.value.current.source === ""
                            ? "Name of source required."
                            : "The same source name already exists."}
                    </StyledTooltip>
                </React.Fragment>
            )}
            <FormLabel isChanged={props.value.hasChanges && props.value.type !== "new"}>
                {props.value.type !== "new" && <SourceName type={props.value.type} source={props.value.initial.source} />}
            </FormLabel>
        </SourceItem>
    );
});