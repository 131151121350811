import React, { FC, useState, useCallback, MouseEvent } from "react";
import { TermsAndConditionsBlock, TermsLeftBlock, TermsCheckBox, TermsLinkContainer, TermsLink, CustomSettingsText, ChevronIcon, ContinueButton, InstallationPathBlock, PathLabel, InstallationPath, BrowseButton, ControlsBlock, CustomCheckBox } from "./components";

interface PreviewState {
    termsAndPrivacyCheckboxChecked: boolean;
    openAtStartupChecked: boolean;
    quickLaunchChecked: boolean;
    browserLaunchChecked: boolean;
    termsLinkVisited: boolean;
    privacyPolicyLinkVisited: boolean;
}

const initialState: PreviewState = {
    termsAndPrivacyCheckboxChecked: false,
    openAtStartupChecked: false,
    quickLaunchChecked: false,
    browserLaunchChecked: false,
    termsLinkVisited: false,
    privacyPolicyLinkVisited: false,
};

export const MainFooter: FC = () => {
    const [previewState, setPreviewState] = useState<PreviewState>(initialState);

    const toggleStateValue = useCallback((field: keyof PreviewState) => {
        setPreviewState(prev => ({ ...prev, [field]: !prev[field] }));
    }, []);

    const termsLinkHandler = useCallback((e: MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        toggleStateValue("termsLinkVisited");
    }, []);
    const privacyPolicyLinkHandler = useCallback((e: MouseEvent<HTMLSpanElement>) => {
        e.stopPropagation();
        toggleStateValue("privacyPolicyLinkVisited");
    }, []);
    const handleTermsAndPrivacyCheckbox = useCallback(() => toggleStateValue("termsAndPrivacyCheckboxChecked"), []);
    const handleOpenApStartupCheckbox = useCallback(() => toggleStateValue("openAtStartupChecked"), []);
    const handleQuickLaunchCheckbox = useCallback(() => toggleStateValue("quickLaunchChecked"), []);
    const handleBrowserLaunchCheckbox = useCallback(() => toggleStateValue("browserLaunchChecked"), []);

    return (
        <>
            <TermsAndConditionsBlock>
                <TermsLeftBlock>
                    <TermsLinkContainer>
                        <TermsCheckBox>
                            <input type="checkbox" id="termsCheckbox" onChange={handleTermsAndPrivacyCheckbox} checked={previewState.termsAndPrivacyCheckboxChecked} />
                            <label onClick={handleTermsAndPrivacyCheckbox}>
                                I have read and accept the&nbsp;
                                <TermsLink onClick={termsLinkHandler} isVisited={previewState.termsLinkVisited}>
                                    Terms
                                </TermsLink>
                                &nbsp;and
                                <TermsLink onClick={privacyPolicyLinkHandler} isVisited={previewState.privacyPolicyLinkVisited}>
                                    Privacy Policy
                                </TermsLink>
                                &nbsp;and certify that I'm at least 13 years old.
                            </label>
                        </TermsCheckBox>
                    </TermsLinkContainer>
                    <CustomSettingsText>
                        <ChevronIcon />
                        &nbsp;Custom
                    </CustomSettingsText>
                </TermsLeftBlock>
                <ContinueButton disabled={!previewState.termsAndPrivacyCheckboxChecked}>
                    Continue
                </ContinueButton>
            </TermsAndConditionsBlock>
            <InstallationPathBlock>
                <PathLabel>Installation Path:</PathLabel>
                <InstallationPath>
                    C:\Program Files\MobiGames
                </InstallationPath>
                <BrowseButton>Browse</BrowseButton>
            </InstallationPathBlock>
            <ControlsBlock>
                <CustomCheckBox>
                    <input
                        type="checkbox"
                        id="openAtStartup"
                        checked={previewState.openAtStartupChecked}
                        onChange={handleOpenApStartupCheckbox}
                    />
                    <label htmlFor="openAtStartup">
                        Open at startup
                    </label>
                </CustomCheckBox>
                <CustomCheckBox>
                    <input
                        type="checkbox"
                        id="enableQuickLaunch"
                        checked={previewState.quickLaunchChecked}
                        onChange={handleQuickLaunchCheckbox}
                    />
                    <label htmlFor="enableQuickLaunch">
                        Enable Quick Launch
                    </label>
                </CustomCheckBox>
                <CustomCheckBox>
                    <input
                        type="checkbox"
                        id="launchFromBrowser"
                        checked={previewState.browserLaunchChecked}
                        onChange={handleBrowserLaunchCheckbox}
                    />
                    <label htmlFor="launchFromBrowser">
                        Launch game from browser
                    </label>
                </CustomCheckBox>
            </ControlsBlock>
        </>
    );
};