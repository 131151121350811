import React, { useState, useCallback, useMemo, useEffect } from "react";
import { BreadcrumbItem } from "reactstrap";
import { noop, defer } from "rxjs";
import { User } from "src/shared/dtos";
import { Breadcrumb, DocumentTitle, RouteUserProps, RouteLink } from "src/shared/components";
import {
    Column,
    InfiniteTable,
    StringSearch,
    TopPanelLeftContainer,
    TopPanelRendererProps,
    DefaultCellRenderer,
    LoadRequest } from "src/shared/components/InfiniteTable";
import { makeSelect, CellProps, SortOrder } from "src/shared/components/ReactBaseTable";
import { useFunctionState } from "src/shared/helpers";
import { Content, VerticalBox } from "src/shared/components/flex";
import { routes } from "src/shared/routes";
import api from "../api";

export const ListView = ({ user }: RouteUserProps) => {
    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    const [items, setItems] = useState<User[]>([]);

    const TopPanel = useMemo(() =>
        (props: TopPanelRendererProps) =>
        <props.blockElement>
            <TopPanelLeftContainer>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <RouteLink user={user} to={routes.home}>
                            Home
                        </RouteLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Users</BreadcrumbItem>
                </Breadcrumb>
            </TopPanelLeftContainer>
            <RouteLink user={user} to={routes.addUser} button className="mr-2">
                Add user
            </RouteLink>
            <props.reloadElement />
            <props.optionsElement />
        </props.blockElement>, []);

    useEffect(() => unsubscribe, [unsubscribe]);
    const load = useCallback(
        (request: LoadRequest) => {
            if (request.reload) {
                setItems([]);
            }

            const observable = defer(() => api.list(request));
            return new Promise<boolean>(resolve => {
                const subscription = observable.subscribe(result => {
                    if (result.length > 0) {
                        setItems(request.reload ? result : items.concat(result));
                    }

                    resolve(result.length === 0);
                });
                setUnsubscribe(() => subscription.unsubscribe());
            });
        }, [items]);

    const editRenderer = useMemo(() =>
        (props: CellProps<string, User>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{ route: routes.editUser, args: { id: props.rowData.id } }}
                    className="d-inline">
                    {cellData}
                </RouteLink>
            );

            return <DefaultCellRenderer cellData={link} title={cellData} {...restProps} />;
        }, []);

    const select = makeSelect<User>();
    return (
        <VerticalBox>
            <DocumentTitle title="Users" />
            <Content overflowHidden>
                <InfiniteTable
                    items={items}
                    load={load}
                    topPanelRenderer={TopPanel}
                    sortBy={{ key: "id", order: SortOrder.ASC }}>
                    <Column
                        key="id"
                        title="ID"
                        dataGetter={select(a => a.id)}
                        width={120}
                        cellRenderer={editRenderer}
                        searchRenderer={StringSearch} />
                    <Column
                        key="username"
                        title="Email"
                        dataGetter={select(a => a.username)}
                        width={240}
                        cellRenderer={editRenderer}
                        searchRenderer={StringSearch} />
                    <Column
                        key="firstName"
                        title="First Name"
                        dataGetter={select(a => a.firstName)}
                        width={240}
                        cellRenderer={editRenderer}
                        searchRenderer={StringSearch} />
                    <Column
                        key="lastName"
                        title="Last Name"
                        dataGetter={select(a => a.lastName)}
                        width={240}
                        cellRenderer={editRenderer}
                        searchRenderer={StringSearch} />
                    <Column
                        key="isAdmin"
                        title="Admin"
                        dataGetter={select(a => a.isAdmin ? "Yes" : "No")}
                        width={120}
                        cellRenderer={editRenderer} />
                </InfiniteTable>
            </Content>
        </VerticalBox>);
};