import React, { useCallback } from "react";
import { PropertyEditorProps } from "../PropertyEditorProps";
import Checkbox from "react-three-state-checkbox";
import styled from "styled-components";

const StyledCheckBox = styled(Checkbox)`
    width: 38px;
    height: 38px;
    vertical-align: bottom;
    position: relative;
    top: -1px;
`;

const rotateValue = (current?: boolean) => {
    switch (current) {
        case false:
            return true;
        case true:
            return undefined;
        case undefined:
        default:
            return false;
    }
};

export const CheckBoxPropertyEditor = (props: PropertyEditorProps<boolean | undefined>) => {
    const onChange = useCallback(() => props.onChange(rotateValue(props.value)), [props.value, props.onChange]);

    return (
        <StyledCheckBox
            checked={props.value === true}
            indeterminate={props.value === undefined}
            onChange={onChange}
            disabled={props.disabled}
        />
    );
};
