import React, { useMemo, useState, useEffect, useCallback } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem } from "reactstrap";
import { noop, defer, of } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { VerticalBox, Header, Content } from "src/shared/components/flex";
import {
    DocumentTitle,
    LeavingViewProtector,
    ToolBox,
    Breadcrumb,
    ContentBox,
    Loader,
    RouteUserProps,
    RouteLink,
    EditError,
    EditErrorOptions,
    notifyError,
    notifySuccess } from "src/shared/components";
import { routes } from "src/shared/routes";
import { User } from "src/shared/dtos";
import { useFunctionState } from "src/shared/helpers";
import { EditForm, EditFormValues } from "./EditForm";
import { ChangePasswordForm } from "./ChangePasswordForm";
import api from "../api";

type Props = RouteComponentProps<{ id?: string }> & RouteUserProps;

const getUserInfo = (userId?: string, currentUserId?: number): [boolean, boolean, number] => {
    const isNewUser = userId === undefined;
    const id = isNewUser ? 0 : +(userId ?? 0);
    const isOwnProfile = id === currentUserId;
    return [isNewUser, isOwnProfile, id];
};

export const EditView = withRouter((props: Props) => {
    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);

    const [error, setError] = useState<EditErrorOptions | undefined>();
    const [hasChanges, setHasChanges] = useState(false);
    const [saving, setSaving] = useState(false);
    const [user, setUser] = useState<User | undefined>();

    const [isNewUser, isOwnProfile, userId] = useMemo(
        () => getUserInfo(props.match.params.id, props.user?.userId),
        [props.match.params.id, props.user?.userId]);
    const userDescription = useMemo(
        () => user !== undefined
            ? (isNewUser ? "[new user]" : user.username)
            : `#${userId}`, [isNewUser, user]);

    useEffect(() => {
        if (isNewUser) {
            setUser({
                id: 0,
                username: "",
                firstName: "",
                lastName: "",
                isAdmin: false
            });
            return;
        }

        const subscription = defer(() => api.get(userId)).subscribe({
            next: result => setUser(result),
            error: () => setError({
                text: `Unable to load user #${userId}.`,
                actionText: "Back to list",
                action: () => props.history.push(routes.users.url())
            })
        });
        return () => subscription.unsubscribe();
    }, [isNewUser, userId]);

    useEffect(() => {
        // Go to edit view after successful save of the new entity.
        // It is implemented this way to let leaving view protector know, that there is no changes.
        if (isNewUser && user !== undefined && user.id > 0 && error === undefined && !hasChanges) {
            props.history.push(routes.editUser.url({ id: user.id }));
        }
    }, [isNewUser, user, user?.id, error, hasChanges]);

    const add = useCallback(
        (data: Required<EditFormValues>) => {
            if (saving) {
                return;
            }

            const userToSave: User = {
                id: 0,
                username: data.username,
                firstName: data.firstName,
                lastName: data.lastName,
                isAdmin: data.isAdmin
            };

            setSaving(true);
            const subscription = defer(() => api.add(userToSave, data.password)).subscribe({
                next: addInfo => {
                    setUser(addInfo.user);
                    setSaving(false);
                    if (addInfo.isPasswordSet) {
                        notifySuccess("User added successfully.");
                    } else {
                        notifyError("Unable to save password for the new user.");
                    }
                },
                error: () => {
                    setSaving(false);
                    notifyError("Unable to add user.");
                }
            });
            setUnsubscribe(() => subscription.unsubscribe());
        }, [saving]);

    const save = useCallback(
        (data: EditFormValues) => {
            if (saving || user === undefined) {
                return;
            }

            const userToSave: User = {
                id: userId,
                username: data.username,
                firstName: data.firstName,
                lastName: data.lastName,
                isAdmin: data.isAdmin
            };

            setSaving(true);
            const subscription = defer(() => api.save(userToSave)).subscribe({
                next: saved => {
                    setUser(saved);
                    setSaving(false);
                    notifySuccess("User saved successfully.");
                },
                error: () => {
                    setSaving(false);
                    notifyError("Unable to save user.");
                }
            });
            setUnsubscribe(() => subscription.unsubscribe());
        }, [saving, user, user?.id]);

    const deleteUser = useCallback(
        () => {
            if (saving || user === undefined) {
                return;
            }

            setSaving(true);
            const subscription = defer(() => api.delete(user.id)).subscribe({
                next: () => {
                    notifySuccess(`User ${user.username} has been deleted.`);
                    props.history.push(routes.users.url());
                },
                error: () => {
                    setSaving(false);
                    notifyError("Unable to delete user.");
                }
            });
            setUnsubscribe(() => subscription.unsubscribe());
        }, [saving, user]);

    const resetPassword = useCallback(
        (newPassword: string, oldPassword?: string) => {
            if (saving || user === undefined) {
                return Promise.resolve(false);
            }

            setSaving(true);
            const observable = (oldPassword === undefined
                ? defer(() => api.changePassword(user.username, newPassword))
                : defer(() => api.resetPassword(user.username, oldPassword, newPassword)))
                .pipe(
                    catchError(() => of(false)),
                    tap(saved => saved
                        ? notifySuccess("Password changed successfully.")
                        : notifyError("Unable to change password.")),
                    finalize(() => setSaving(false)));

            return observable.toPromise();
        }, [saving, user?.username]);

    return (
        <VerticalBox>
            <DocumentTitle title={`User - ${userDescription}`} />
            <LeavingViewProtector showConfirmation={hasChanges} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={props.user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={props.user} to={routes.users}>
                                Users
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {userDescription}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    {error && <EditError error={error} />}
                    {!error && user === undefined &&
                        <Loader />}
                    {!error && user !== undefined &&
                        <React.Fragment>
                            <EditForm
                                user={user}
                                isNewUser={isNewUser}
                                saving={saving}
                                setHasChanges={setHasChanges}
                                onAdd={add}
                                onSave={save}
                                onDelete={deleteUser} />
                            {userId !== 0 &&
                                <ChangePasswordForm
                                    isOwnPassword={isOwnProfile}
                                    saving={saving}
                                    onSubmit={resetPassword} />}
                        </React.Fragment>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});