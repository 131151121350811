import { client } from "src/shared/client";
import {
    GetGamingMinutesStatistics,
    GetGamingMinutesStatisticsAvailableDates,
    GetGamingMinutesStatisticsById,
    GetGamingMinutesStatisticsSummaries,
    GetGamingMinutesStatisticsSummariesByAppIdForPeriod,
    GetProductVersionsByDate,
} from "src/shared/dtos";
import { ExcludeMethods } from "src/shared/helpers";
import { serializeDate } from "src/shared/helpers/dateSerialization";

export interface ListRequest extends Omit<ExcludeMethods<GetGamingMinutesStatisticsSummaries>, "date"> {
    date: Date;
}

export const GamingMinutesStatisticsApi = {
    getById: (id: number) => client.get(new GetGamingMinutesStatisticsById({ id })),
    list: async (request: ListRequest) => {
        const {
            date,
            ...restRequest
        } = request;
        return client.get(new GetGamingMinutesStatisticsSummaries({
            date: serializeDate(date),
            ...restRequest
        }));
    },
    details: (
        movingAvgDays: number,
        applicationId: string,
        productVersion: string,
        startDate: Date,
        endDate: Date
    ) => client.get(new GetGamingMinutesStatisticsSummariesByAppIdForPeriod({
        applicationId,
        productVersion,
        movingAvgDays,
        startDate: serializeDate(startDate),
        endDate: serializeDate(endDate)
    })),
    all: () => client.get(new GetGamingMinutesStatistics()),
    getDates: () => client.get(new GetGamingMinutesStatisticsAvailableDates()),
    getVersionsByDate: (date: Date) => client.get(new GetProductVersionsByDate({ date: serializeDate(date) })),
};

export default GamingMinutesStatisticsApi;