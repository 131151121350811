/**
 * Serializes Date to string without time and timezone information.
 * @param date Date to serialize.
 */
export const serializeDate = (date?: Date): string | undefined => {
    return date === undefined ? undefined : date.toISOString().slice(0, 10);
};

/**
 * Deserializes string to Date.
 * @param dateString String to deserialize.
 */
export const deserializeDate = (dateString?: string): Date | undefined => {
    return dateString === undefined ? undefined : new Date(dateString);
};