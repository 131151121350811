import { ChartTypes } from "src/shared/dtos";

type HighChartsType = "line" | "spline" | "column" | "pie" | "areaspline";

const highChartsTypes: { [k in ChartTypes]: HighChartsType } = {
    [ChartTypes.Pie]: "pie",
    [ChartTypes.Line]: "line",
    [ChartTypes.Spline]: "spline",
    [ChartTypes.Column]: "column",
    [ChartTypes.AreaSpline]: "areaspline"
};

export const getHighChartsType = (type: ChartTypes): HighChartsType => highChartsTypes[type] ?? "spline";