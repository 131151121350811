import React, { useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col } from "reactstrap";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    DocumentTitle,
    RouteLink,
    RouteUserProps
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { routes } from "src/shared/routes";
import { DetailsComponent } from "./DetailsComponent";

export const DetailsView = withRouter(({ match, user }: RouteComponentProps<{ path: string, movingAvgDays: string, endDate: string }> & RouteUserProps) => {
    const [path, movingAvgDays, endDate] = useMemo(() => {
        const parsedMovingAvgDays = match.params.movingAvgDays !== undefined ? parseInt(match.params.movingAvgDays, 10) : 7;
        const parsedEndDate = new Date(match.params.endDate);
        return [decodeURIComponent(match.params.path), parsedMovingAvgDays, parsedEndDate];
    }, [match.params.path, match.params.movingAvgDays]);

    return (
        <VerticalBox>
            <DocumentTitle title={`Request Statistics - for '${path}'`} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.requestStatistics}>
                                Request Statistics
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            Path - '{path}'
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    <React.Fragment>
                        <Row>
                            <Col sm={{ size: 8, offset: 2 }}>
                                <DetailsComponent
                                    path={path}
                                    movingAvgDays={movingAvgDays}
                                    endDate={endDate}
                                    user={user}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});