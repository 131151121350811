import React, { FC } from "react";
import { ListWidgetDataTypes, ListWidgetUnit } from "src/shared/dtos";
import styled from "styled-components";
import { setFromInput, splitCamelCase } from "src/shared/helpers";
import { Input, Label, FormGroup } from "reactstrap";

const EditForm = styled.div`
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

type EditListWidgetProps = {
    update: (val: Partial<ListWidgetUnit>) => void;
    value?: ListWidgetUnit;
};

const allDataTypes = Object.values(ListWidgetDataTypes);

export const EditListWidget: FC<EditListWidgetProps> = ({ value, update }) => {

    const updateDataType = (val: ListWidgetDataTypes) => update({ dataType: val });

    return (
        <EditForm>
            <FormGroup>
                <Label for="dataType">
                    Select Data Type
                </Label>
                <Input
                    id="dataType"
                    name="dataType"
                    value={value?.dataType}
                    onChange={setFromInput(updateDataType)}
                    type="select"
                >
                    {allDataTypes.map(t => <option key={t} value={t}>{splitCamelCase(t)}</option>)}
                </Input>
            </FormGroup>
        </EditForm>);
};