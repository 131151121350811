import React, { useCallback, useEffect, useMemo, useState } from "react";

export type DragAndDropPoint = { x: number, y: number };

export type DragAndDrop<THtmlElement extends HTMLElement> = {
    /**
     * Handles mouse down event that will be treated as drag start.
     */
    mouseDownHandler: (event: React.MouseEvent<THtmlElement, MouseEvent>) => void,

    /**
     * Indicates that drag is in progress.
     */
    active: false
} | {
    /**
     * Handles mouse down event that will be treated as drag start.
     */
    mouseDownHandler: (event: React.MouseEvent<THtmlElement, MouseEvent>) => void,

    /**
     * Indicates that drag is in progress.
     */
    active: true,

    /**
     * Indicates mouse pointer position on the client element that was remembered at a drag start event.
     */
    dragStartPoint: DragAndDropPoint,

    /**
     * Indicates current mouse pointer offset from the @param dragStartPoint.
     */
    dragOffset: DragAndDropPoint
};

/**
 * Handles drag and drop required mouse events.
 * @example
 * const Element = () => {
 *     const dnd = useDragAndDrop();
 *     const [position, setPosition] = useState({ x: 0, y: 0 });
 *     const [dragStartPosition, setDragStartPosition] = useState({ x: 0, y: 0 });
 *
 *     useEffect(() => {
 *         if (dnd.active) {
 *             setDragStartPosition(position);
 *         }
 *     }, [dnd.active]);
 *
 *     useEffect(() => {
 *         if (dnd.active) {
 *             setPosition({
 *                 x: dragStartPosition.x - dnd.dragOffset.x,
 *                 y: dragStartPosition.y - dnd.dragOffset.y
 *             });
 *         }
 *     }, [dnd.active && dnd.dragOffset, dragStartPosition]);
 *
 *     return (
 *         <div
 *             onMouseDown={dnd.mouseDownHandler}
 *             style={{ cursor: "move", left: `${position.x}px`, top: `${position.y}px` }}
 *         >Example</div>
 *     );
 * };
 */
export function useDragAndDrop<THtmlElement extends HTMLElement = HTMLElement>(): DragAndDrop<THtmlElement> {
    const [dragStartPoint, setDragStartPoint] = useState<DragAndDropPoint>();
    const [dragOffset, setDragOffset] = useState<DragAndDropPoint>({ x: 0, y: 0 });

    const mouseDownHandler = useCallback((event: React.MouseEvent<THtmlElement, MouseEvent>) => {
        const isLeftButton = event.button === 0;
        if (isLeftButton) {
            setDragStartPoint({ x: event.clientX, y: event.clientY });
            setDragOffset({ x: 0, y: 0 });
        }
    }, []);

    const onDocumentMouseUp = useCallback((event: MouseEvent): void => {
        const isLeftButton = event.button === 0;
        if (isLeftButton) {
            setDragStartPoint(undefined);
        }
    }, []);

    const onDocumentMouseMove = useCallback((event: MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        if (dragStartPoint !== undefined) {
            setDragOffset({ x: dragStartPoint.x - event.clientX, y: dragStartPoint.y - event.clientY });
        }
    }, [dragStartPoint]);

    useEffect(() => {
        if (dragStartPoint === undefined) {
            return;
        }

        document.addEventListener("mouseup", onDocumentMouseUp);
        document.addEventListener("mousemove", onDocumentMouseMove);

        return () => {
            document.removeEventListener("mouseup", onDocumentMouseUp);
            document.removeEventListener("mousemove", onDocumentMouseMove);
        };
    }, [dragStartPoint]);

    return useMemo(() => dragStartPoint === undefined
        ? { mouseDownHandler, active: false }
        : { mouseDownHandler, active: true, dragStartPoint, dragOffset }, [dragStartPoint, dragOffset]);
}