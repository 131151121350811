import React, { useState, useMemo } from "react";
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import { setFromInput } from "../helpers/setFromInput";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getYoutubeVideoIdFromUrl } from "../helpers";

export interface Video {
    url: string;
}

export interface VideoSelectorProps {
    /**
     * The add input element id prop.
     */
    setInputId?: string;

    /**
     * The root element class name.
     */
    className?: string;

    /**
     * The current value.
     */
    value: unknown;

    /**
     * The set value method. Must return value that will be displayed in the set input.
     */
    set?: (url: string) => string;

    /**
     * The delete image URL method.
     */
    delete?: (value: unknown) => void;

    /**
     * The render method that must return image URL.
     */
    render: (value: unknown) => Video | null;

    /**
     * The image dimensions.
     */
    videoDimensions?: { width?: string, height?: string };

    /**
     * Is control disabled.
     */
    disabled?: boolean;

    highlight?: boolean;
    /**
     * Is delete button hidden.
     */
    deleteButtonHidden?: boolean;

    invalid?: boolean;
}

const Container = styled.div<{ highlight?: boolean }>`
    background: ${props => props.highlight ? "lightyellow" : ""};
    text-align: center;
    padding-top: 15px;
`;

const StyledVideoContainer = styled.a`
    max-width: 100%;
    max-height: 100%;
`;

const StyledVideo = styled.iframe<{ videoWidth?: string, videoHeight?: string }>`
    width: ${props => props.videoWidth ?? "initial"};
    height: ${props => props.videoHeight ?? "initial"};
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const StyledVideoBlock = styled.div<{ width?: string, height?: string }>`
    height: ${props => props.height ?? "initial"};
    display: flex;
    align-items: center;
    justify-content: center;
`;


const StyledNoVideoBlock = styled.div<{ width?: string, height?: string }>`
    width: ${props => props.width ? "initial" : "10rem"};
    height: ${props => props.height ?? "initial"};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledDeleteButton = styled.a<{ hidden?: boolean, highlight?: boolean }>`
    color: ${props => props.highlight ? "gray" : "#fc1d00"};
    display: ${props => props.hidden ? "none" : "block"};
    position: absolute;
    top: 0;
    right: 2%;
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    font-size: 2rem;
    text-align: center;
    opacity: 0.5;
    z-index: 2;
    margin: -1rem;

    &:hover, &:focus {
        color:${props => props.highlight ? "lightgray" : "red"};
        opacity: 0.5;
    }
`;

const DeleteIcon = styled(FontAwesomeIcon).attrs({ icon: "times" })`
    vertical-align: top;
`;

export const VideoSelector = (props: VideoSelectorProps) => {
    const [url, setUrl] = useState("");

    const video = useMemo(() => props.render(props.value), [props.value]);

    const onSetClicked = () => {
        const result = props.set?.call(null, url) as string;
        setUrl(result);
    };

    const onDeleteVideoClicked = (e: React.FormEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const currentVideo = props.value;
        props.delete?.call(currentVideo, null);
    };

    return (
        <Container className={props.className} highlight={props.highlight}>
            {video === null &&
                <StyledNoVideoBlock width={props.videoDimensions?.width} height={props.videoDimensions?.height}>
                    [no video]
                </StyledNoVideoBlock>}
            {video !== null &&
                <StyledVideoBlock height={props.videoDimensions?.height}>
                    <StyledDeleteButton href="#" onClick={onDeleteVideoClicked} hidden={props.deleteButtonHidden} highlight={props.highlight}>
                        <DeleteIcon />
                    </StyledDeleteButton>
                    <StyledVideoContainer href={video.url} target="_blank">
                        <StyledVideo
                            src={`https://www.youtube.com/embed/${getYoutubeVideoIdFromUrl(video.url)}`}
                            itemType="text/html"
                            frameBorder={0}
                            allow="autoplay"
                            allowFullScreen
                            />
                    </StyledVideoContainer>
                </StyledVideoBlock>}
            <InputGroup className="mt-3">
                <Input
                    type="text"
                    placeholder="Add YouTube video link"
                    id={props.setInputId}
                    value={url}
                    onChange={setFromInput(setUrl)}
                    disabled={props.disabled} />
                <InputGroupAddon addonType="append">
                    <Button type="button" color="primary" onClick={onSetClicked} disabled={props.disabled || url === ""}>
                        Set
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        </Container>
    );
};