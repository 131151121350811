import React, { FC } from "react";
import { Tag } from "src/shared/dtos";
import styled from "styled-components";
import { Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteLink } from "src/shared/components";
import { routes } from "src/shared/routes";
import { IUser } from "src/shared/client";

const TagBox = styled.div<{ showInNavbar: boolean }>`
    display: flex;
    width: 400px;
    height: 50px;
    align-items: stretch;
    border-width: 1px;
    border-style: solid;
    border-color: ${p => p.showInNavbar ? "#007bff" : "#c6c6c6"};
    border-radius: 8px;
    position: relative;
    user-select: none;
    box-shadow: ${p => p.showInNavbar ? "1px 3px 4px #add3fbf0" : "1px 3px 4px #ececec"};
`;

const TagImage = styled.div<{ url: string }>`
    width: 50px;
    border: none;
    outline: none;
    border-radius: 8px 0 0 8px;
    background-image: ${p => `url("${p.url}")`};
    background-size: 90%;
    background-position: center;
    background-repeat: no-repeat;
`;

const FallbackImage = styled.div`
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    border-radius: 8px 0 0 8px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    color: #343a40;
    padding: 6px;
`;

const TagContent = styled.div`
    padding: 6px;
    padding-left: 12px;
    flex: 1;
`;

const EditIcon = styled(FontAwesomeIcon).attrs({ icon: "pencil-alt" })`
    position: absolute;
    top: 8px;
    right: 8px;
    color: #007bff;
    cursor: pointer;
    font-size: 14px;
`;

interface TagItemProps {
    tag: Tag;
    user: IUser | null;
    idx: number;
}

export const TagItem: FC<TagItemProps> = ({ tag, idx, user }) => {
    return (
        <Draggable draggableId={tag.id} index={idx}>
            {(p) => (
                <TagBox {...p.draggableProps} ref={p.innerRef} showInNavbar={tag.showInNavbar}>
                    {tag.iconUrl
                        ? <TagImage url={tag.iconUrl} />
                        : <FallbackImage><span>No Image</span></FallbackImage>
                    }
                    <TagContent {...p.dragHandleProps}>
                        {tag.name}
                    </TagContent>
                    <RouteLink user={user} to={{ route: routes.editTag, args: { id: tag.id } }}>
                        <EditIcon />
                    </RouteLink>
                </TagBox>
            )}
        </Draggable>
    );
};
