import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useCallback } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import SortableTree, {
    FullTree,
    NodeData,
    OnMovePreviousAndNextLocation,
    TreeItem,
    ExtendedNodeData,
    NodeRendererProps,
} from "react-sortable-tree";
import { BreadcrumbItem, Button } from "reactstrap";
import { defer } from "rxjs";
import styled, { css, keyframes } from "styled-components";
import {
    Breadcrumb,
    DocumentTitle,
    Loader,
    RouteLink,
    RouteUserProps,
    ToolBox,
    ToolBoxLeftContainer,
    ToolBoxRightContainer
} from "src/shared/components";
import { Category, CategoryOrder } from "src/shared/dtos";
import { useSubscription } from "src/shared/helpers";
import { Content, VerticalBox, Header } from "src/shared/components/flex";
import { routes } from "src/shared/routes";
import api from "../api";

const StyledSortableTree = styled(SortableTree)`
    .rst__rowContents {
        width: 230px;
    }

    .rst__rowLabel {
        width: 100%;

        a {
            width: 75%;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .posFix {
            position: fixed;
        }
    }

    .rst__rowSubtitle {
        width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 8px;
    }

    .disabled-node .rst__rowContents {
        background-color: #eff3f9;
    }
`;

const FullHeightDiv = styled.div`
    position: relative;
    height: 100%;
`;

const MakeOverlayVisible = keyframes`
    0% {
        opacity: 0;
    }
    66% {
        opacity: 0;
    }
    100% {
        visibility: visible;
        opacity: 0.1;
    }
`;

const DelayOverlayAppearance = css`
    visibility: hidden;
    animation: 1s ${MakeOverlayVisible};
    animation-fill-mode: forwards;
`;

const DragDisableOverlay = styled.div`
    ${DelayOverlayAppearance}
    cursor: not-allowed;
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.1;
    z-index: 1000;
`;

const EditButton = styled.span`
    position: absolute;
    right: 8px;
    cursor: pointer;
`;

type CategoryItem = TreeItem & { category: Category };

const normalizeTree = (categories: Category[], categoryItems: CategoryItem[]): CategoryItem[] => {
    const allItems: CategoryItem[] = categories.map(c => ({
        category: c,
        title: c.name,
        subtitle: c.description
            ? <span title={c.description}>{c.description}</span>
            : <i className="text-muted">{"<no description>"}</i>,
        expanded: categoryItems.find(i => i.category.id === c.id)?.expanded ?? true,
        children: []
    }));

    const rootItems: CategoryItem[] = [];
    categories.forEach(c => {
        const item = allItems.find(i => i.category.id === c.id)!;
        if (c.parentId !== undefined) {
            const parentItem = allItems.find(i => i.category.id === c.parentId);
            if (parentItem === undefined) {
                throw new Error(`Category '${c.id}' has parent '${c.parentId}' that is not exist.`);
            }

            const items = (parentItem.children as CategoryItem[]);
            items.push(item);
            items.sort((a, b) => a.category.order - b.category.order);
        }
        else {
            rootItems.push(item);
            rootItems.sort((a, b) => a.category.order - b.category.order);
        }
    });

    return rootItems;
};

type CategoryNode = Omit<CategoryItem, "children"> & {
    children: CategoryNode[];
    parentId: string | undefined;
    idx: number;
};

const getCategoriesOrderList = (node: CategoryNode) => {
    const categoriesOrderList: CategoryOrder[] = [];
    let queue: CategoryNode[] = [node];
    while (queue.length > 0) {
        const currentNode = queue.shift() as CategoryNode;
        if (currentNode.category?.id) {
            categoriesOrderList.push({ id: currentNode.category.id, order: currentNode.idx, parentId: currentNode.parentId });
        }
        if (currentNode.children && currentNode.children.length > 0) {
            queue = queue.concat(currentNode.children.map((c, idx) => ({ ...c, idx, parentId: currentNode.category?.id })));
        }
    }
    return categoriesOrderList;
};

export const ListView = withRouter(({ user }: RouteComponentProps<{}> & RouteUserProps) => {
    const [categories, setCategories] = useState<CategoryItem[]>();
    const [saving, setSaving] = useState<boolean>(false);

    const setCategoriesTree = (cats: Category[]) => {
        const normalizedTree = normalizeTree(cats, categories ?? []);
        setCategories(normalizedTree);
    };

    const loadCategories = useSubscription(
        () => defer(() => api.list()).subscribe(setCategoriesTree), [api.list]);

    useEffect(() => {
        loadCategories();
    }, []);

    const updateCategoriesOrder = useSubscription(
        (catOrderList: CategoryOrder[]) => defer(() => api.updateCategoriesOrder(catOrderList))
            .subscribe({
                next: cats => {
                    setCategoriesTree(cats);
                    setSaving(false);
                },
                error: () => setSaving(false)
            }), [api.updateCategoriesOrder]);

    const reload = () => {
        setCategories(undefined);
    };

    const onChange = (items: CategoryItem[]) => setCategories(items);
    const onMoveNode = useCallback((data: NodeData & FullTree & OnMovePreviousAndNextLocation) => {
        if (saving || !data || categories === undefined) {
            return;
        }

        setSaving(true);
        const rootNode: CategoryNode = {
            idx: 0,
            parentId: undefined,
            children: data.treeData.map((v, idx) => ({ ...v, idx, parentId: undefined })) as CategoryNode[]
        };

        const categoriesOrderList = getCategoriesOrderList(rootNode);
        updateCategoriesOrder(categoriesOrderList);
    }, [saving, categories]);

    const render = (data: ExtendedNodeData): Partial<NodeRendererProps> => {
        const item = data.node as CategoryItem;
        return {
            className: item.category.isActive ? undefined : "disabled-node",
            title: () => (
                <React.Fragment>
                    <RouteLink user={user} to={{ route: routes.editCategory, args: { id: item.category.id } }}>
                        <span title={item.category.name}>
                            {data.node.title}
                        </span>
                        <EditButton>
                            <FontAwesomeIcon icon="pencil-alt" />
                        </EditButton>
                    </RouteLink>
                    {item.category.isActive ? undefined : <i className="text-muted posFix"><small> (disabled)</small></i>}
                </React.Fragment>
            )
        };
    };

    return (
        <VerticalBox>
            <DocumentTitle title="Categories" />
            <Header>
                <ToolBox>
                    <ToolBoxLeftContainer>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <RouteLink user={user} to={routes.home}>
                                    Home
                                </RouteLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                Categories
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </ToolBoxLeftContainer>
                    <ToolBoxRightContainer>
                        <RouteLink user={user} to={routes.addCategory} button className="mr-2">
                            Add Category
                        </RouteLink>
                        <Button onClick={reload}>
                            <FontAwesomeIcon icon="sync" />
                        </Button>
                    </ToolBoxRightContainer>
                </ToolBox>
            </Header>
            <Content style={{ overflow: "hidden" }}>
                {categories === undefined && <Loader />}
                {categories !== undefined && (
                    <FullHeightDiv className="row">
                        <FullHeightDiv className="offset-sm-1 col-sm-11 offset-md-2 col-md-10 offset-lg-3 col-lg-9 offset-xl-3 col-xl-9">
                            {saving && <DragDisableOverlay />}
                            <StyledSortableTree
                                treeData={categories}
                                onChange={onChange}
                                onMoveNode={onMoveNode}
                                generateNodeProps={render}
                            />
                        </FullHeightDiv>
                    </FullHeightDiv>
                )}
            </Content>
        </VerticalBox>
    );
});