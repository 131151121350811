import React, { FC } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { RouteLink } from "src/shared/components";

import { routes } from "src/shared/routes";
import { IUser } from "src/shared/client";
import styled from "styled-components";

const ApplicationsList = styled.ul`
    list-style-type: none;
`;

interface Props {
    isOpen: boolean;
    onClose: () => void;
    googleAplicationsIds: string[];
    ioAplicationsIds: string[];
    user: IUser | null;
}

export const CategoryApplicationsModal: FC<Props> = ({ isOpen, onClose, googleAplicationsIds, ioAplicationsIds, user }) => {
    return (
        <Modal isOpen={isOpen} toggle={onClose}>
            <ModalHeader toggle={onClose}>
                Category Applications
            </ModalHeader>
            <ModalBody>
                {googleAplicationsIds.length > 0 && (
                    <div>
                        <h5>Gamestore apps</h5>
                        <ApplicationsList>
                            {googleAplicationsIds.map(appId => {
                                return (
                                    <li key={appId}>
                                        <RouteLink
                                            user={user}
                                            to={{ route: routes.editApplication, args: { appId, language: "en" } }}
                                            className="d-inline"
                                        >
                                            {appId}
                                        </RouteLink>
                                    </li>
                                );
                            })}
                        </ApplicationsList>
                    </div>
                )}
                {ioAplicationsIds.length > 0 && (
                    <div className="mt-2">
                        <h5>Gamestore Io apps</h5>
                        <ApplicationsList>
                            {ioAplicationsIds.map(appId => {
                                return (
                                    <li key={appId}>
                                        <RouteLink
                                            user={user}
                                            to={{ route: routes.editGamestoreIoApplication, args: { appId, language: "en" } }}
                                            className="d-inline"
                                        >
                                            {appId}
                                        </RouteLink>
                                    </li>
                                );
                            })}
                        </ApplicationsList>
                    </div>
                )}
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" type="button" onClick={onClose}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};