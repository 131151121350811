import React, { useState, useCallback, useMemo, useEffect } from "react";
import { BreadcrumbItem, Button } from "reactstrap";
import { defer } from "rxjs";
import {
    GamestoreIoApplicationSummary,
    GamestoreIoApplicationListDataType,
    OrderDirection,
    Category,
    ExportIoApplicationsList,
    Tag
} from "src/shared/dtos";
import { Breadcrumb, DocumentTitle, RouteLink, RouteUserProps, Loader, BulkApplicationToggler, notifySuccess, notifyError } from "src/shared/components";
import {
    Column,
    DefaultCellRenderer,
    InfiniteTable,
    StringSearch,
    TopPanelRendererProps,
    TopPanelLeftContainer,
    LoadRequest,
    SearchOptions,
    BooleanSearch,
    BooleanIndicator,
    DateSearch,
    multipleCategorySearchFactory,
    multiValueSearchFactory,
    SuggestionTag,
    CellRenderer
} from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { Content, VerticalBox } from "src/shared/components/flex";
import {
    formatSections,
    formatAsAbbreviation,
    htmlToPlainText,
    deserializeQueryParameters,
    QueryParameterDescriptor,
    serializeQueryParameters,
    pushUrl,
    getGridVisibleColumns,
    useBootstrapBreakpoint,
    serializeDate,
    deserializeDate,
    useSubscription
} from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { LanguageSelector } from "src/shared/components/LanguageSelector";
import { Rating } from "src/shared/components/Rating";
import api, { ListRequest } from "../api";
import { client } from "src/shared/client";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { CategoryType } from "src/shared/CategoryType";
import { SuggestionTagMapper } from "src/shared/helpers/InfiniteTable";

const keyDataTypes: { [key: string]: GamestoreIoApplicationListDataType } = {
    "appId": GamestoreIoApplicationListDataType.Id,
    "title": GamestoreIoApplicationListDataType.Title,
    "developer": GamestoreIoApplicationListDataType.Developer,
    "rating": GamestoreIoApplicationListDataType.Rating,
    "installsCount": GamestoreIoApplicationListDataType.InstallsCount,
    "modifiedAt": GamestoreIoApplicationListDataType.ModifiedDate,
    "srcUpdatedAt": GamestoreIoApplicationListDataType.SrcUpdatedDate,
    "sourceProviders": GamestoreIoApplicationListDataType.SourceProvider
};
const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType as GamestoreIoApplicationListDataType);

function getSearchValueByKey<T>(key: string, searchOptions?: Array<SearchOptions<unknown>>): T | undefined {
    return searchOptions?.find(s => s.key === key)?.value as T;
}

const searchQueryParameterDescriptors: QueryParameterDescriptor[] = [
    { paramType: "language", paramName: "language", serializer: (value: string) => value === "en" ? undefined : value },
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    { paramType: "search", paramName: "title" },
    { paramType: "search", paramName: "appId" },
    { paramType: "search", paramName: "developer" },
    {
        paramType: "search",
        paramName: "categories",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "tags",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "sourceProviders",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    { paramType: "search", paramName: "isGraphicsCustomized", serializer: "boolean", deserializer: "boolean" },
    { paramType: "search", paramName: "enabled", serializer: "boolean", deserializer: "boolean" },
    { paramType: "search", paramName: "isNew", serializer: "boolean", deserializer: "boolean" },
    {
        paramType: "search",
        paramName: "modifiedAtStartDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "modifiedAtEndDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "srcUpdatedAtStartDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "srcUpdatedAtEndDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    }
];

const makeBooleanRenderer = (trueTitle: string, falseTitle: string): CellRenderer<boolean, GamestoreIoApplicationSummary> =>
    (props: CellProps<boolean, GamestoreIoApplicationSummary>) => {
        const { cellData, ...restProps } = props;
        const value = <BooleanIndicator value={cellData || undefined} />;
        const title = cellData ? trueTitle : falseTitle;

        return <DefaultCellRenderer cellData={value} title={title} centerText={true} {...restProps} />;
    };

export const ListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [lang, tableFilters, sort] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...searchQueryParameterDescriptors);
        const languageParam = allParams.find(p => p.paramType === "language")?.value as string | undefined;
        let searchParams = allParams
            .filter(p => p.paramType === "search")
            .map(qp => ({ key: qp.paramName, value: qp.value } as SearchOptions<unknown>));

        const modifiedAtStartDate = searchParams.find(p => p.key === "modifiedAtStartDate");
        const modifiedAtEndDate = searchParams.find(p => p.key === "modifiedAtEndDate");
        searchParams = searchParams.filter(p => p.key !== "modifiedAtStartDate" && p.key !== "modifiedAtEndDate");
        if (modifiedAtStartDate?.value !== undefined && modifiedAtEndDate?.value !== undefined) {
            searchParams.push({ key: "modifiedAt", value: [modifiedAtStartDate.value, modifiedAtEndDate.value] });
        }

        const srcUpdatedAtStartDate = searchParams.find(p => p.key === "srcUpdatedAtStartDate");
        const srcUpdatedAtEndDate = searchParams.find(p => p.key === "srcUpdatedAtEndDate");
        searchParams = searchParams.filter(p => p.key !== "srcUpdatedAtStartDate" && p.key !== "srcUpdatedAtEndDate");
        if (srcUpdatedAtStartDate?.value !== undefined && srcUpdatedAtEndDate?.value !== undefined) {
            searchParams.push({ key: "srcUpdatedAt", value: [srcUpdatedAtStartDate.value, srcUpdatedAtEndDate.value] });
        }

        const orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        const orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;

        return [languageParam, searchParams, { key: orderBy, order: orderDir } as SortOptions];
    }, [location.search]);

    const [providers, setProviders] = useState<string[]>([]);
    const [items, setItems] = useState<GamestoreIoApplicationSummary[]>([]);
    const [visibleColumns, setVisibleColumns] = useState<string[] | "all" | undefined>();
    const [allCategories, setAllCategories] = useState<Category[] | undefined>();
    const [allTags, setAllTags] = useState<Tag[] | undefined>();
    const [language, setLanguage] = useState("en");
    const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);

    const allTagIds = useMemo(() => allTags?.map(t => t.id) ?? [], [allTags]);

    const forceReload = (() => setReloadTrigger(p => !p));

    const breakpoint = useBootstrapBreakpoint();
    useEffect(() => setLanguage(lang ?? "en"), [lang]);

    const updateCategories = useSubscription(() => defer(
        () => api.getCategories()).subscribe(categories => {
            const validCategories = categories
                .filter(c => !c.isVirtual && (c.id === CategoryType.Io || c.parentId === CategoryType.Io));
            setAllCategories(validCategories);
        }), [api.getCategories]);

    const updateTags = useSubscription(() => defer(() => api.getTags())
        .subscribe(setAllTags), []);

    const updateProviders = useSubscription(() => defer(() => api.getProviders())
        .subscribe(setProviders), []);

    useEffect(() => {
        updateCategories();
        updateTags();
        updateProviders();
    }, []);

    const updateVisibleColumns = useSubscription(() => defer(() => getGridVisibleColumns("GamestoreIoApplications")).subscribe(columns => {
        setVisibleColumns(columns ?? "all");
    }), [getGridVisibleColumns]);

    useEffect(() => {
        updateVisibleColumns();
    }, [breakpoint, updateVisibleColumns]);

    const updateData = useSubscription((requestObj: ListRequest, isReload: boolean, resolve: (val: boolean) => void) => defer(() =>
        api.list(requestObj)).subscribe(result => {
            if (result.length > 0) {
                setItems(prev => isReload ? result : prev.concat(result));
            }
            resolve(result.length === 0);
        }), [api.list]);

    const load = useCallback(
        (request: LoadRequest) => {
            if (request.reload) {
                setItems([]);
            }

            const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : GamestoreIoApplicationListDataType.Id;
            const searchRequest = {
                language,
                orderBy: orderByKey,
                orderDir: orderByKey !== undefined
                    ? request.sort?.order === SortOrder.DESC ? OrderDirection.Desc : OrderDirection.Asc
                    : undefined,
                appId: getSearchValueByKey<string>("appId", request.search),
                title: getSearchValueByKey<string>("title", request.search),
                developer: getSearchValueByKey<string>("developer", request.search),
                categories: getSearchValueByKey<string[]>("categories", request.search),
                tags: getSearchValueByKey<string[]>("tags", request.search),
                sourceProviders: getSearchValueByKey<string[]>("sourceProviders", request.search),
                isGraphicsCustomized: getSearchValueByKey<boolean>("isGraphicsCustomized", request.search),
                isNew: getSearchValueByKey<boolean>("isNew", request.search),
                enabled: getSearchValueByKey<boolean>("enabled", request.search),
                modifiedAtStartDate: getSearchValueByKey<Date[]>("modifiedAt", request.search)?.[0],
                modifiedAtEndDate: getSearchValueByKey<Date[]>("modifiedAt", request.search)?.[1],
                srcUpdatedAtStartDate: getSearchValueByKey<Date[]>("srcUpdatedAt", request.search)?.[0],
                srcUpdatedAtEndDate: getSearchValueByKey<Date[]>("srcUpdatedAt", request.search)?.[1]
            };

            const requestObj: ListRequest = {
                language: searchRequest.language,
                offset: request.offset,
                limit: request.limit,
                orderBy: searchRequest.orderBy,
                orderDirection: searchRequest.orderDir ?? OrderDirection.Asc,
                idSearch: searchRequest.appId,
                titleSearch: searchRequest.title,
                developerSearch: searchRequest.developer,
                categoriesSearch: searchRequest.categories,
                tagsSearch: searchRequest.tags,
                sourceProviderSearch: searchRequest.sourceProviders,
                isGraphicsCustomizedSearch: searchRequest.isGraphicsCustomized,
                isNewSearch: searchRequest.isNew,
                isAppDisabledSearch: searchRequest.enabled !== undefined ? !searchRequest.enabled : undefined,
                modifiedAtStartDateSearch: searchRequest.modifiedAtStartDate,
                modifiedAtEndDateSearch: searchRequest.modifiedAtEndDate,
                srcUpdatedAtStartDateSearch: searchRequest.srcUpdatedAtStartDate,
                srcUpdatedAtEndDateSearch: searchRequest.srcUpdatedAtEndDate
            };

            const url = routes.gamestoreIoApplications.url({
                searchParams: serializeQueryParameters(searchRequest, ...searchQueryParameterDescriptors)
            });
            pushUrl(location, history, url);

            return new Promise<boolean>(resolve => {
                updateData(requestObj, request?.reload, resolve);
            });
        }, [language, location, updateData]);

    const changeLanguage = useCallback(
        (value: string) => {
            setItems([]);
            setLanguage(value);
        }, [language]);

    const toggleApps = useCallback(
        (file: File, isDisabled: boolean) => api.toggleApps(file, isDisabled), []);

    const tagSuggestionMapper: SuggestionTagMapper<string> = useCallback(tagId => {
        const tag = allTags?.find(t => t.id === tagId);
        return {
            label: tag?.name ?? "",
            value: tag?.id ?? ""
        };
    }, [allTags]);

    const TopPanel = useMemo(() =>
        (props: TopPanelRendererProps) =>
            <props.blockElement>
                <TopPanelLeftContainer>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Gamestore Io Applications</BreadcrumbItem>
                    </Breadcrumb>
                </TopPanelLeftContainer>
                <RouteLink user={user} to={routes.addGamestoreIoApplication} button className="mr-2">
                    Add Application
                </RouteLink>
                <Button href={client.resolveUrl("GET", new ExportIoApplicationsList(), language, "csv")} className="mr-2">
                    Export
                </Button>
                <BulkApplicationToggler className="mr-2" toggleApps={toggleApps} onSUccess={forceReload} />
                <LanguageSelector value={language} setValue={changeLanguage} className="mr-2" />
                <props.reloadElement />
                <props.optionsElement />
            </props.blockElement>, [language]);

    const iconRenderer = useMemo(() =>
        (props: CellProps<string | undefined, GamestoreIoApplicationSummary>) =>
            <RouteLink
                user={user}
                to={{ route: routes.editGamestoreIoApplication, args: { appId: props.rowData.id, language } }}>
                <img src={props.cellData ?? ""} alt={props.cellData ?? "Application Icon"} width={50} height={50} />
            </RouteLink>, [language]);

    const titleRenderer = useMemo(() =>
        (props: CellProps<unknown, GamestoreIoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{ route: routes.editGamestoreIoApplication, args: { appId: props.rowData.id, language } }}
                    className="d-inline">
                    {props.rowData.title}
                </RouteLink>
            );

            return <DefaultCellRenderer cellData={link} title={props.rowData.title} {...restProps} />;
        }, [language]);

    const appIdRenderer = useMemo(() =>
        (props: CellProps<string, GamestoreIoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{ route: routes.editGamestoreIoApplication, args: { appId: props.rowData.id, language } }}
                    className="d-inline">
                    {props.rowData.id}
                </RouteLink>
            );

            return <DefaultCellRenderer cellData={link} title={cellData} {...restProps} />;
        }, [language]);

    const plainTextRenderer = useMemo(() =>
        (props: CellProps<string | undefined, GamestoreIoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const title = cellData ?? "";
            return <DefaultCellRenderer cellData={title} title={title} {...restProps} />;
        }, [language]);

    const ratingRenderer = useMemo(() =>
        (props: CellProps<number, GamestoreIoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const rating = <Rating value={cellData} max={5} />;
            const title = `${cellData}`;

            return <DefaultCellRenderer cellData={rating} title={title} {...restProps} />;
        }, []);

    const isGraphicsCustomizedRenderer = useMemo(() => makeBooleanRenderer(
        "Application has fully customized graphics and will have show priority over non-customized apps.",
        "Application does not have customized graphics."), []);

    const isEnabledRenderer = useMemo(() => makeBooleanRenderer(
        "Application is enabled.",
        "Application is disabled."), []);

    const isNewRenderer = useMemo(() => makeBooleanRenderer(
        "Application is marked as new with NEW tag.",
        "Application is not marked with NEW tag."), []);

    const categoriesRenderer = useMemo(() =>
        (props: CellProps<Category[], GamestoreIoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const sortedCats = cellData.sort((a, b) => {
                const parentResult = (a.parentId ?? "").localeCompare(b.parentId ?? "");
                return parentResult === 0
                    ? a.name.localeCompare(b.name)
                    : parentResult;
            });

            const cell = (
                <React.Fragment>
                    {sortedCats.map((c, i) => {
                        const title = `Parent ID: ${c.parentId}`;

                        return (
                            <span key={`${c.parentId} ${c.id}`}>
                                <span title={title}>{c.name}</span>
                                {i < sortedCats.length - 1 && <span>, </span>}
                            </span>
                        );
                    })}
                </React.Fragment>
            );

            return <DefaultCellRenderer cellData={cell} {...restProps} />;
        }, [language]);

    const tagsRenderer = useMemo(() =>
        (props: CellProps<string[] | undefined, GamestoreIoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            if (cellData === undefined) {
                return <DefaultCellRenderer cellData={""} title={"No tags"} {...restProps} />;
            }
            const appTags = allTags?.filter(t => cellData.includes(t.id)) ?? [];
            const value = (
                <React.Fragment>
                    {appTags?.map((t, i) => (
                        <span key={t.id} title={t.id}>
                            {t.name}
                            {appTags.length > 0 && i < appTags.length -1 && ", "}
                        </span>
                    ))}
                </React.Fragment>
            );
            return <DefaultCellRenderer cellData={value} {...restProps} />;
        }, [language, allTags]);

    const descriptionRenderer = useMemo(() =>
        (props: CellProps<string | undefined, GamestoreIoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const value = htmlToPlainText(cellData ?? "");
            return <DefaultCellRenderer cellData={value} title={value} {...restProps} />;
        }, [language]);

    const installCountRenderer = useMemo(() =>
        (props: CellProps<number, GamestoreIoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const size = formatAsAbbreviation(cellData);
            const title = `${formatSections(cellData)}+`;

            return <DefaultCellRenderer cellData={size} title={title} {...restProps} />;
        }, []);

    const dateRenderer = useMemo(() =>
        (props: CellProps<Date | undefined, GamestoreIoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const value = cellData !== undefined ? new Date(cellData).toLocaleDateString() : undefined;

            return <DefaultCellRenderer cellData={value} title={value} {...restProps} />;
        }, []);

    const select = makeSelect<GamestoreIoApplicationSummary>();

    const plainTextOptionMapper = useCallback((val: string): SuggestionTag => ({ value: val, label: val }), []);

    const onOptionsClose = useCallback((visColumns: string[]) => {
        if (Array.isArray(visibleColumns) && visColumns.join("") === visibleColumns.join("")) {
            return;
        }
        if (breakpoint) {
            api.saveGridSettings(breakpoint, visColumns)
                .then(_ => {
                    notifySuccess("Columns settings saved successfully");
                    setVisibleColumns(visColumns);
                })
                .catch(_ => notifyError("Failed to save column settings"));
        }
    }, [breakpoint, visibleColumns]);

    return (
        <VerticalBox>
            <DocumentTitle title="Gamestore Io Applications" />
            <Content overflowHidden>
                {(allCategories === undefined || visibleColumns === undefined) && <Loader />}
                {allCategories !== undefined && allTags !== undefined && visibleColumns !== undefined &&
                    <InfiniteTable
                        items={items}
                        load={load}
                        renderContext={language + reloadTrigger}
                        filters={tableFilters}
                        sortBy={sort}
                        topPanelRenderer={TopPanel}
                        visibleColumns={visibleColumns}
                        onOptionsClose={onOptionsClose}
                    >
                        <Column
                            key="icon"
                            dataGetter={select(a => a.iconUrl)}
                            width={70}
                            minWidth={70}
                            cellRenderer={iconRenderer}
                            resizable={false}
                            sortable={false}
                        />
                        <Column
                            key="title"
                            title="Title"
                            width={280}
                            cellRenderer={titleRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="appId"
                            dataGetter={select(a => a.id)}
                            title="AppID"
                            width={300}
                            cellRenderer={appIdRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="sourceProviders"
                            dataGetter={select(a => a.sourceProvider)}
                            title="Provider"
                            width={260}
                            cellRenderer={plainTextRenderer}
                            searchRenderer={multiValueSearchFactory(providers, plainTextOptionMapper)}
                        />
                        <Column
                            key="developer"
                            dataGetter={select(a => a.developer)}
                            title="Developer"
                            width={120}
                            cellRenderer={plainTextRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="rating"
                            dataGetter={select(a => a.rating)}
                            title="Rating"
                            width={125}
                            minWidth={75}
                            cellRenderer={ratingRenderer}
                            resizable={false}
                        />
                        <Column
                            key="categories"
                            dataGetter={select(a => a.categories)}
                            title="Categories"
                            width={280}
                            sortable={false}
                            cellRenderer={categoriesRenderer}
                            searchRenderer={multipleCategorySearchFactory(allCategories)}
                        />
                        <Column
                            key="tags"
                            dataGetter={select(a => a.tags)}
                            title="Tags"
                            width={280}
                            sortable={false}
                            cellRenderer={tagsRenderer}
                            searchRenderer={multiValueSearchFactory(allTagIds, tagSuggestionMapper)}
                        />
                        <Column
                            key="description"
                            dataGetter={select(a => a.description)}
                            title="Description"
                            width={320}
                            sortable={false}
                            cellRenderer={descriptionRenderer}
                        />
                        <Column
                            key="installsCount"
                            dataGetter={select(a => a.installsCount)}
                            title="#install"
                            width={140}
                            cellRenderer={installCountRenderer}
                        />
                        <Column
                            key="isGraphicsCustomized"
                            dataGetter={select(s => s.isGraphicsCustomized)}
                            title="Graphics Customized"
                            width={120}
                            cellRenderer={isGraphicsCustomizedRenderer}
                            searchRenderer={BooleanSearch}
                        />
                        <Column
                            key="isNew"
                            dataGetter={select(a => a.isNew)}
                            title="Is New"
                            width={120}
                            sortable={false}
                            cellRenderer={isNewRenderer}
                            searchRenderer={BooleanSearch}
                        />
                        <Column
                            key="enabled"
                            dataGetter={select(a => !a.isDisabled)}
                            title="Enabled"
                            width={120}
                            sortable={false}
                            cellRenderer={isEnabledRenderer}
                            searchRenderer={BooleanSearch}
                        />
                        <Column
                            key="modifiedAt"
                            dataGetter={select(a => a.modifiedAt)}
                            title="Modified date"
                            width={170}
                            minWidth={170}
                            sortable={true}
                            cellRenderer={dateRenderer}
                            searchRenderer={DateSearch}
                        />
                        <Column
                            key="srcUpdatedAt"
                            dataGetter={select(a => a.srcUpdatedAt)}
                            title="Io game updated date"
                            width={170}
                            minWidth={170}
                            sortable={true}
                            cellRenderer={dateRenderer}
                            searchRenderer={DateSearch}
                        />
                    </InfiniteTable>}
            </Content>
        </VerticalBox>);
});


