import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { availableLanguages } from "src/shared/helpers";

export interface Language {
    /**
     * The display name.
     */
    name: string;

    /**
     * The language code.
     */
    value: string;
}

export interface LanguageSelectorProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * The available languages.
     */
    languages?: Language[];

    /**
     * The selected value.
     */
    value: string;

    /**
     * Updates selected value.
     */
    setValue: (value: string) => void;
}

export const LanguageSelector = (props: LanguageSelectorProps) => {
    const { languages, value, setValue, ...restProps } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const set = (v: string) => () => setValue(v);

    const items = languages && languages.length > 0
        ? languages
        : availableLanguages;

    const getName = (v: string) => {
        const found = items.find(l => l.value === v);
        if (!found) {
            throw new Error(`Language with value '${v}' is not added to available languages.`);
        }

        return found.name;
    };

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} {...restProps}>
            <DropdownToggle caret>
                {getName(value)}
            </DropdownToggle>
            <DropdownMenu right>
                {items.map(l =>
                    <DropdownItem key={l.value} onClick={set(l.value)} disabled={value === l.value}>
                        {l.name}
                    </DropdownItem>)}
            </DropdownMenu>
        </Dropdown>
    );
};