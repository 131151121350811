import { client } from "src/shared/client";
import {
    DeleteProductVersionInfo,
    GetSourceSettingsList,
    GetProductVersionInfo,
    GetProductVersionInfos,
    ManageProductVersionInfo,
    OrderDirection,
    ProductVersionInfo,
    ProductVersionInfoListDataType
} from "src/shared/dtos";

export interface ListRequest {
    offset: number;
    limit: number;
    orderBy: ProductVersionInfoListDataType;
    orderDirection: OrderDirection;
    versionSearch?: string;
    sourcesSearch?: string[];
    isEnabledSearch?: boolean;
    isMandatorySearch?: boolean;
}

export const ProductVersionInfoApi = {
    list: (request: ListRequest) => client.get(new GetProductVersionInfos({ ...request })),
    get: (id: number) => client.get(new GetProductVersionInfo({ id })),
    create: (value: ProductVersionInfo) => client.post(new ManageProductVersionInfo({ value })),
    update: (value: ProductVersionInfo) => client.put(new ManageProductVersionInfo({ value })),
    delete: (id: number) => client.delete(new DeleteProductVersionInfo({ id })),
    getSourceSettings: () => client.get(new GetSourceSettingsList()),
};

export default ProductVersionInfoApi;
