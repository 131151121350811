import React, { useState, useCallback, useMemo, useEffect } from "react";
import { BreadcrumbItem } from "reactstrap";
import { noop, defer } from "rxjs";
import { AdCampaign, AdCampaignListDataType, AdTargeting, OrderDirection, SourceSettings } from "src/shared/dtos";
import { Breadcrumb, DocumentTitle, RouteLink, RouteUserProps, Loader, notifyError, notifySuccess, WarningIndicator } from "src/shared/components";
import {
    Column,
    DefaultCellRenderer,
    InfiniteTable,
    TopPanelRendererProps,
    TopPanelLeftContainer,
    BooleanIndicator,
    ActionButton,
    SearchOptions,
    SuggestionTag,
    LoadRequest,
    BooleanSearch,
    StringSearch,
    multiValueSearchFactory
} from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { Content, VerticalBox } from "src/shared/components/flex";
import { deserializeQueryParameters, pushUrl, QueryParameterDescriptor, serializeQueryParameters, useFunctionState } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import api from "../api";
import { withRouter, RouteComponentProps } from "react-router-dom";

const keyDataTypes: { [key: string]: AdCampaignListDataType } = {
    "name": AdCampaignListDataType.CampaignName,
    "enabled": AdCampaignListDataType.Disabled
};

const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType as AdCampaignListDataType);

function getSearchValueByKey<T>(key: string, searchOptions?: Array<SearchOptions<unknown>>): T | undefined {
    return searchOptions?.find(s => s.key === key)?.value as T;
}

const searchQueryParameterDescriptors: QueryParameterDescriptor[] = [
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    { paramType: "search", paramName: "name" },
    { paramType: "search", paramName: "enabled", serializer: "boolean", deserializer: "boolean" },
    {
        paramType: "search",
        paramName: "sourceSettings",
        serializer: (value: number[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",").map(v => parseInt(v, 10))
    }
];

export const ListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [tableFilters, sort] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...searchQueryParameterDescriptors);
        const searchParams = allParams
            .filter(p => p.paramType === "search")
            .map(qp => ({ key: qp.paramName, value: qp.value } as SearchOptions<unknown>));
        const orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        const orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;

        return [searchParams, { key: orderBy, order: orderDir } as SortOptions];
    }, [location.search]);

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    const [items, setItems] = useState<AdCampaign[]>([]);
    const [sourceSettings, setSourceSettings] = useState<SourceSettings[]>();

    useEffect(() => {
        const subscription = defer(() => api.getSourceSettings()).subscribe(result => {
            const allSources = { id: 0, source: "All", settings: "", isDeleted: false } as SourceSettings;
            result.push(allSources);
            setSourceSettings(result.sort((a, b) => a.source.localeCompare(b.source)));
        });
        return () => subscription.unsubscribe();
    }, []);

    const sourceSettingsById = useMemo(() => new Map(sourceSettings?.map(v => [v.id, v]) ?? []), [sourceSettings]);

    const sourceSettingsIds = useMemo(() => sourceSettings !== undefined
        ? sourceSettings.filter(v => !v.isDeleted).map(s => s.id)
        : [], [sourceSettings]);

    const sourceSettingsMapper = (id: number): SuggestionTag => {
        const found = sourceSettings?.find(c => c.id === id);
        return {
            value: found?.id ?? "",
            label: found?.source ?? ""
        };
    };
    useEffect(() => unsubscribe, [unsubscribe]);

    const load = useCallback(
        (request: LoadRequest) => {
            if (request.reload) {
                setItems([]);
            }

            const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : AdCampaignListDataType.CampaignName;
            const searchRequest = {
                orderBy: orderByKey,
                orderDir: orderByKey !== undefined
                    ? request.sort?.order === SortOrder.DESC ? OrderDirection.Desc : OrderDirection.Asc
                    : undefined,
                name: getSearchValueByKey<string>("name", request.search),
                sourceSettings: getSearchValueByKey<number[]>("sourceSettings", request.search),
                enabled: getSearchValueByKey<boolean>("enabled", request.search)
            };

            const observable = defer(() => api.list({
                orderBy: searchRequest.orderBy,
                orderDirection: searchRequest.orderDir ?? OrderDirection.Asc,
                nameSearch: searchRequest.name,
                sourceSearch: searchRequest.sourceSettings?.filter(id => id !== 0),
                includeCampaignWithAllSourcesSearch: searchRequest.sourceSettings !== undefined
                    ? searchRequest.sourceSettings.indexOf(0) !== -1
                    : undefined,
                disabledSearch: searchRequest.enabled !== undefined ? !searchRequest.enabled : undefined
            }));

            const url = routes.adCampaigns.url({
                searchParams: serializeQueryParameters(searchRequest, ...searchQueryParameterDescriptors)
            });
            pushUrl(location, history, url);

            return new Promise<boolean>(resolve => {
                const subscription = observable.subscribe(result => {
                    if (result.length > 0) {
                        setItems(result);
                    }
                    resolve(result.length === 0);
                });
                setUnsubscribe(() => subscription.unsubscribe());
            });
        }, [location]);

    const deleteCampaign = useCallback((campaign: AdCampaign) => {
        const deletedName = campaign.name;
        defer(() => api.delete(campaign.id)).subscribe({
            next: () => {
                notifySuccess(`Ad Campaign '${deletedName}' has been successfully deleted.`);
                setItems(old => old.filter(i => i.id !== campaign.id));
            },
            error: () => {
                notifyError(`Unable to delete AdCampaign '${deletedName}'`);
            }
        });
    }, []);

    const TopPanel = useMemo(() =>
        (props: TopPanelRendererProps) =>
            <props.blockElement>
                <TopPanelLeftContainer>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Ad Campaigns</BreadcrumbItem>
                    </Breadcrumb>
                </TopPanelLeftContainer>
                <RouteLink user={user} to={routes.addAdCampaign} button className="mr-2">
                    Add Campaign
                </RouteLink>
            </props.blockElement>, []);

    const nameRenderer = useMemo(() =>
        (props: CellProps<unknown, AdCampaign>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{ route: routes.editAdCampaign, args: { id: props.rowData.id } }}
                    className="d-inline">
                    {props.rowData.name}
                </RouteLink>
            );

            return <DefaultCellRenderer cellData={link} title={props.rowData.name} {...restProps} />;
        }, []);

    const sourceSettingsRenderer = useMemo(() =>
        (props: CellProps<number[] | undefined, AdCampaign>) => {
            const { cellData, ...restProps } = props;
            if (cellData === undefined || cellData.length === 0) {
                return <DefaultCellRenderer cellData={<span><i>[ALL]</i></span>} {...restProps} />;
            }

            const campaignSources = cellData.map(v => sourceSettingsById.get(v)).filter(v => v) as SourceSettings[];
            const cell = (
                <div>
                    {campaignSources.map((s, i) => (
                        <span key={s.source + i}>
                            <span style={s.isDeleted ? { color: "#dc3545" } : undefined}>{s.source}</span>
                            {s.isDeleted && <WarningIndicator warnings={["This source is deleted."]} className="ml-1" />}
                            {i < cellData.length - 1 && (<span>&#44;&#32;</span>)}
                        </span>
                    ))}
                </div>
            );
            return <DefaultCellRenderer cellData={cell} {...restProps} />;
        }, [sourceSettings]);

    const enabledRenderer = useMemo(() =>
        (props: CellProps<boolean, AdCampaign>) => {
            const { cellData, ...restProps } = props;
            const value = <BooleanIndicator value={cellData} />;
            const title = `Ad campaign is ${cellData ? "enabled" : "disabled"}.`;
            return <DefaultCellRenderer cellData={value} title={title} centerText={true} {...restProps} />;
        }, []);

    const actionsRenderer = useMemo(() =>
        (props: CellProps<number, AdCampaign>) => {
            const { cellData, ...restProps } = props;
            const onDelete = () => deleteCampaign(props.rowData);
            const value =
                <React.Fragment>
                    <RouteLink
                        user={user}
                        to={{ route: routes.editAdCampaign, args: { id: props.rowData.id } }}
                        className="d-inline"
                    >
                        <ActionButton icon="pencil-alt" className="text-primary" />
                    </RouteLink>
                    <ActionButton icon="trash" className="text-danger" onClick={onDelete} />
                </React.Fragment>;

            return <DefaultCellRenderer cellData={value} centerText={true} {...restProps} />;
        }, [deleteCampaign]);

    const select = makeSelect<AdCampaign>();
    return (
        <VerticalBox>
            <DocumentTitle title="Ad Campaigns" />
            <Content overflowHidden>
                {sourceSettings === undefined && <Loader />}
                {sourceSettings !== undefined &&
                    <InfiniteTable
                        items={items}
                        load={load}
                        filters={tableFilters}
                        sortBy={sort}
                        topPanelRenderer={TopPanel}
                    >
                        <Column
                            key="name"
                            title="Campaign Name"
                            width={500}
                            sortable={true}
                            cellRenderer={nameRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="sourceSettings"
                            title="Marketing Source"
                            dataGetter={select(a => a.sourceSettingsIds)}
                            width={500}
                            sortable={false}
                            cellRenderer={sourceSettingsRenderer}
                            searchRenderer={multiValueSearchFactory(sourceSettingsIds, sourceSettingsMapper)}
                        />
                        <Column
                            key="enabled"
                            dataGetter={select(a => !a.isDisabled)}
                            title="Enabled"
                            width={100}
                            sortable={true}
                            cellRenderer={enabledRenderer}
                            searchRenderer={BooleanSearch}
                        />
                        <Column
                            key="actions"
                            dataGetter={select(a => a.id)}
                            title=""
                            width={100}
                            sortable={false}
                            cellRenderer={actionsRenderer}
                        />
                    </InfiniteTable>}
            </Content>
        </VerticalBox>);
});