import React, { useEffect, useState, useReducer, useMemo, useCallback, useRef, } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col } from "reactstrap";
import { forkJoin, defer, noop } from "rxjs";
import { FeaturedApplication, FeaturedApplicationLocalization } from "src/shared/dtos";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    LeavingViewProtector,
    Loader,
    LocalizationSelector,
    DocumentTitle,
    RouteLink,
    RouteUserProps,
    EditError,
    EditErrorOptions,
    notifySuccess,
    notifyError
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { availableLanguages, capitalize, getFormErrorsAsStringArray, getLangName, queryString, splitCamelCase, structuredClone, useFunctionState, ValidateAsyncRef, validateForm } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { EditForm } from "./EditForm";
import { EditLocalization } from "./EditLocalization";
import { applicationReducer, applicationReducerDefault, FeaturedAppMainData } from "./applicationReducer";
import { localizationReducer, makeLocalizationReducerDefault } from "./localizationReducer";
import api from "../api";
import { localizationValidationSchema, localizationImagesValidationSchema } from "./validationSchema";
import { useConfirm } from "src/shared/helpers/useConfirm";
import { ConfirmFailedLocalizationSources, LocalizationsFailedFields } from "src/shared/components/confirmWindow/samples/ConfirmFailedSources";

type Props = RouteComponentProps<{ appId: string }> & RouteUserProps;

const makeEmptyApp = (): FeaturedApplication => {
    const locs = [{
        id: 0,
        language: "en",
        description: "",
        bannerUrl: "",
        logoUrl: undefined
    } as FeaturedApplicationLocalization];

    return {
        id: "",
        order: 0,
        isDisabled: false,
        localizations: locs
    };
};

export const EditView = withRouter(({ match, location, history, user }: Props) => {
    const appId = match.params.appId;
    const isNewApp = useMemo(() => appId === undefined, [appId]);

    const localizationFormRef = useRef<ValidateAsyncRef>(null);

    const queryParams = queryString(location.search);
    const defaultLang = isNewApp ? "en" : queryParams.lang || "en";

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);

    const [isNewAppSaved, setIsNewAppSaved] = useState(!isNewApp);
    const [newAppIds, setNewAppIds] = useState<string[]>([]);
    const [error, setError] = useState<EditErrorOptions | undefined>();
    const [saving, setSaving] = useState(false);
    const [app, changeApp] = useReducer(applicationReducer, applicationReducerDefault);
    const [loc, changeLocs] = useReducer(localizationReducer, makeLocalizationReducerDefault(defaultLang));
    const { confirm } = useConfirm();

    const backToList = useCallback(() => history.push(routes.featuredApplications.url()), []);

    const loadApp = () => {
        if (isNewApp) {
            const googleAppsSubscription = defer(() => api.getAvailableAppIds()).subscribe({
                next: result => {
                    setNewAppIds(result);
                    setApp(undefined);
                },
                error: () => setError({
                    text: "Unable to load available application IDs.",
                    actionText: "Back to list",
                    action: backToList
                })
            });
            setUnsubscribe(() => googleAppsSubscription.unsubscribe());
            return;
        }

        const subscription = defer(() => api.get(appId)).subscribe({
            next: result => {
                if (result.localizations?.length === undefined) {
                    setError({
                        text: "Unable to load application localizations.",
                        actionText: "Back to list",
                        action: backToList
                    });
                    return;
                }

                setApp(result);
            },
            error: () => setError({
                text: "Unable to load application.",
                actionText: "Back to list",
                action: backToList
            })
        });
        setUnsubscribe(() => subscription.unsubscribe());
    };

    useEffect(() => loadApp(), []);

    useEffect(() => {
        // Go to edit view after successful save of the new entity.
        // It is implemented this way to let leaving view protector know, that there is no changes.
        if (isNewApp && isNewAppSaved && error === undefined) {
            history.push(routes.editFeaturedApplication.url({ appId: app.current!.id }));
        }
    }, [isNewApp, isNewAppSaved, app.current]);

    const setApp = (data?: FeaturedApplication) => {
        const value = data ?? makeEmptyApp();

        const subscription = forkJoin([
            defer(() => structuredClone(value)),
            defer(() => structuredClone(value.localizations)),
            defer(() => structuredClone(value.localizations))])
            .subscribe(clones => {
                changeApp({ kind: "set", value, initialValue: clones[0] });
                changeLocs({
                    kind: "set",
                    app: value,
                    resetCurrent: true,
                    localizations: clones[1],
                    initialLocalizations: clones[2]
                });

                // Check for an unknown localization languages.
                const allLangs = value.localizations.map(l => l.language);
                const unknownLangs = allLangs
                    .filter(l => availableLanguages.find(al => al.value === l) === undefined)
                    .join();
                if (unknownLangs.length > 0) {
                    console.warn(
                        `FeaturedApplication '${value.id}' contains unknown localization languages: '${unknownLangs}'.`);
                }
            });
        setUnsubscribe(() => subscription.unsubscribe());
    };

    const save = useCallback(
        async () => {
            if (saving || !app.current) {
                return;
            }
            const isLocalizationFormValid = await localizationFormRef.current?.validate();
            if (!isLocalizationFormValid) {
                return;
            }

            if (loc.all.length === 0) {
                notifyError("FeaturedApplication must have at least one localization.");
                return;
            }

            setSaving(true);

            const localizationsErrors = (await Promise.all(
                loc.all.map(async l => {
                    const locValidationResult = await validateForm(l, localizationValidationSchema);
                    const locErrors = getFormErrorsAsStringArray(locValidationResult.errors);
                    return locErrors.map(err => `${getLangName(l.language)} Localization: ${err}`);
                })
            )).flat();

            if (localizationsErrors.length > 0) {
                setSaving(false);
                localizationsErrors.forEach(notifyError);
                return;
            }

            const failedImages = (await Promise.all(
                loc.all.map(async l => {
                    const imagesValidationResult = await validateForm(l, localizationImagesValidationSchema);
                    if (imagesValidationResult.isValid){
                        return null;
                    }
                    const failedFields = Object.keys(imagesValidationResult.errors).map(capitalize).map(splitCamelCase);
                    return { lang: getLangName(l.language) ?? l.language, fields: failedFields } as LocalizationsFailedFields;
                })
            )).filter(v => v !== null) as LocalizationsFailedFields[];

            if (failedImages.length > 0) {
                const confirmSave = await confirm({
                    body: <ConfirmFailedLocalizationSources failedFields={failedImages}/>,
                    title: "Confirm saving application",
                    size: "lg"
                });
                if (!confirmSave) {
                    setSaving(false);
                    return;
                }
            }

            const request: FeaturedApplication = {
                ...app.current,
                localizations: loc.all,
            };

            const subscription = defer(() => api.setup(request)).subscribe({
                next: result => {
                    notifySuccess(`Featured Application ${isNewApp ? "added" : "saved"} successfully.`);
                    setApp(result);

                    if (isNewApp) {
                        setIsNewAppSaved(true);
                    }
                },
                error: () => {
                    setSaving(false);
                    setError({
                        text: "Unable to save application.",
                        actionText: "Continue",
                        action: () => setError(undefined)
                    });
                },
                complete: () => setSaving(false)
            });
            setUnsubscribe(() => subscription.unsubscribe());
        }, [app, app.current, loc, loc.all, isNewApp, localizationFormRef.current]);

    const updateApp = useCallback(
        (value: Partial<FeaturedAppMainData>) => changeApp({ kind: "update", value }), []);
    const updateLocalization = useCallback(
        (value: Partial<FeaturedApplicationLocalization>) => changeLocs({ kind: "update", value }), []);
    const removeLocalization = useCallback(() => changeLocs({ kind: "remove" }), []);

    const changed = useMemo(
        () => app.changed || loc.changedLanguages.length > 0 || loc.removedLocalizations.length > 0, [
        app.changed,
        loc.changedLanguages,
        loc.changedLanguages.length,
        loc.removedLocalizations,
        loc.removedLocalizations.length]);

    const title = useMemo(
        () => {
            const appTitle = isNewApp
                ? "[new application]"
                : (app.current !== undefined
                    ? app.current.id
                    : "Loading...");
            return `Featured Application - ${appTitle}`;
        },
        [app.current, defaultLang]);

    const setLanguage = (lang: string) => changeLocs({ kind: "select", lang });
    const addLanguage = (lang: string) => changeLocs({ kind: "add", lang });

    return (
        <VerticalBox>
            <DocumentTitle title={title} />
            <LeavingViewProtector showConfirmation={changed && (isNewApp ? !isNewAppSaved : true)} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.featuredApplications}>
                                Featured Applications
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {isNewApp ? "[new application]" : match.params.appId}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    {error && <EditError error={error} />}
                    {!error && (app.current === undefined || loc.current === undefined) &&
                        <Loader />}
                    {!error && app.current !== undefined && loc.current !== undefined &&
                        <Row>
                            <Col
                                sm={{ offset: 1, size: 10 }}
                                md={{ offset: 2, size: 8 }}
                                lg={{ offset: 3, size: 6 }}
                                xl={{ offset: 4, size: 4 }}
                            >
                                <Row>
                                    <Col>
                                        <EditForm
                                            value={app.current}
                                            initial={app.initial}
                                            isNewApp={isNewApp}
                                            newAppIds={newAppIds}
                                            update={updateApp}
                                            saving={saving}
                                            onSubmit={save}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <LocalizationSelector
                                            currentLanguage={loc.current.language ?? defaultLang}
                                            languages={loc.all.map(l => l.language)}
                                            removedLanguages={loc.removedLocalizations.map(l => l.language)}
                                            changedLanguages={loc.changedLanguages}
                                            setLanguage={setLanguage}
                                            addLanguage={addLanguage}
                                        />
                                        {loc.current &&
                                            <EditLocalization
                                                ref={localizationFormRef}
                                                value={loc.current}
                                                initial={loc.initial}
                                                isNewApp={isNewApp}
                                                update={updateLocalization}
                                                remove={removeLocalization}
                                                saving={saving}
                                                onSubmit={save}
                                                isLastLocalization={loc.all.length < 2} />}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});