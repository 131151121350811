import React, { FC } from "react";
import {
    CloseIcon,
    ControlButton,
    ControlButtonsBlock,
    LowerHeader,
    MaximizeIcon,
    MenuIcon,
    MinimizeIcon,
    SearchIcon,
    SearchPanel,
    UpperHeader,
    WelcomeText,
    HeaderContainer,
    HeaderLeftIcon,
    TitleText
} from "./components";

const controlButtons = [
    {
        icon: <MenuIcon />,
        title: "menu"
    },
    {
        icon: <MinimizeIcon />,
        title: "minimize"
    },
    {
        icon: <MaximizeIcon />,
        title: "maximize"
    },
    {
        icon: <CloseIcon />,
        title: "close"
    }
];

interface Props {
    leftCornerIconUrl: string | undefined;
}

export const Header: FC<Props> = ({ leftCornerIconUrl }) => {
    return (
        <HeaderContainer>
            <HeaderLeftIcon src={leftCornerIconUrl} />
            <UpperHeader>
                <ControlButtonsBlock>
                    {controlButtons.map(btn => (
                        <ControlButton key={btn.title}>
                            {btn.icon}
                        </ControlButton>
                    ))}
                </ControlButtonsBlock>
            </UpperHeader>
            <LowerHeader>
                <WelcomeText>
                    Welcome to <TitleText>games.lol</TitleText>
                </WelcomeText>
                <SearchPanel>
                    <span><SearchIcon /></span>
                    <TitleText>game center</TitleText>
                    <span>my games</span>
                </SearchPanel>
            </LowerHeader>
        </HeaderContainer>
    );
};
