import {
    WidgetUnit,
    WidgetTypes,
    InfoWidgetDataTypes,
    ChartTypes,
    ChartWidgetDataTypes,
    ListWidgetDataTypes,
    ChartWidgetTimeSpan,
} from "src/shared/dtos";

export const makeEmptyTextWidget = (): WidgetUnit => ({
    id: 0,
    widgetType: WidgetTypes.TextWidget,
    textWidget: {
        id: 0,
        text: ""
    }
});

export const makeEmptyChartWidget = (dataType: ChartWidgetDataTypes): WidgetUnit => ({
    id: 0,
    widgetType: WidgetTypes.ChartWidget,
    chartWidget: {
        id: 0,
        chartType: ChartTypes.Spline,
        dataType,
        timeSpan: ChartWidgetTimeSpan.Week
    }
});

export const makeEmptyListWidget = (dataType: ListWidgetDataTypes): WidgetUnit => ({
    id: 0,
    widgetType: WidgetTypes.ListWidget,
    listWidget: {
        id: 0,
        dataType
    }
});

export const makeEmptyInfoWidget = (dataType: InfoWidgetDataTypes): WidgetUnit => ({
    id: 0,
    widgetType: WidgetTypes.InfoWidget,
    infoWidget: {
        id: 0,
        dataType
    }
});