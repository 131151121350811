import { client } from "src/shared/client";
import {
    AdUnit,
    GetAdUnit,
    ManageAdUnit,
    GetAdCampaign,
    GetAdUnitTriggers,
    DeleteAdUnitTrigger,
    GetAdUnitSummaries,
    DeleteAdUnit,
    SearchAvailableApplicationIds,
    GetAdBannerSizes,
    AdUnitListDataType,
    OrderDirection,
    GetAllAdUnitTriggers,
    GetAdUnitTypes,
    GetCategories
} from "src/shared/dtos";

export interface ListRequest {
    offset: number;
    limit: number;
    orderBy: AdUnitListDataType;
    orderDirection: OrderDirection;
    campaignNameSearch?: string;
    triggerEventSearch?: string[];
    typeSearch?: string[];
    dimensionByAdBannerSizeIdsSearch?: number[];
    includeNonStandardSizesToDimensionSearch?: boolean;
    campaignDisabledSearch?: boolean;
    disabledSearch?: boolean;
}

export const AdUnitsApi = {
    get: async (id: number) => {
        const app = await client.get(new GetAdUnit({ id }));
        return app.id === undefined ? undefined : app;
    },
    list: (request: ListRequest) => client.get(new GetAdUnitSummaries({ ...request })),
    create: (adUnit: AdUnit) => client.post(new ManageAdUnit({ adUnit })),
    update: (adUnit: AdUnit) => client.put(new ManageAdUnit({ adUnit })),
    delete: (id: number) => client.delete(new DeleteAdUnit({ id })),
    getAdCampaign: async (id: number) => {
        const adCampaign = await client.get(new GetAdCampaign({ id }));
        return adCampaign.id === undefined ? undefined : adCampaign;
    },
    getAdUnitTriggers: (adUnitId: number) => client.get(new GetAdUnitTriggers({ unitId: adUnitId })),
    getAllAdUnitTriggers: () => client.get(new GetAllAdUnitTriggers()),
    getAdUnitTypes: () => client.get(new GetAdUnitTypes()),
    getAdBannerSizes: () => client.get(new GetAdBannerSizes()),
    getAvailableApplicationIds: (idSearch: string) => client.get(new SearchAvailableApplicationIds({ idSearch })),
    deleteAdUnitTrigger: (id: number) => client.delete(new DeleteAdUnitTrigger({ id })),
    getCategories: () => client.get(new GetCategories())
};

export default AdUnitsApi;
