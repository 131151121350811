import React, { useMemo } from "react";
import styled from "styled-components";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Options } from "./Options";
import { ColumnOptions } from "./ColumnOptions";

export interface TopPanelRendererProps {
    width: number;
    height: number;
    title?: string | JSX.Element;
    blockElement: React.FC<TopPanelElementProps>;
    titleElement: React.FC<TopPanelElementProps>;
    reloadElement: React.FC<TopPanelElementProps>;
    optionsElement: React.FC<TopPanelElementProps>;
}

export interface TopPanelBlockProps {
    width: number;
    height: number;
}

export const TopPanelBlock = styled.div.attrs<TopPanelBlockProps>(props => ({
    style: {
        width: `${props.width}px`,
        height: `${props.height}px`
    }
}))<TopPanelBlockProps>`
    margin: 0px;
    padding: 2px 0.5rem;
    background-color: #fff;
    box-shadow: #eee 0px 2px 4px 2px;

    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
`;

export const TopPanelLeftContainer = styled.div`
    margin-right: auto;
`;

export const TopPanelRightContainer = styled.div`
    margin-left: auto;
`;

const Title = styled.h4`
    margin-left: 0.5rem;
`;

const ReloadButton = styled(Button).attrs({ type: "button" })`
    margin-right: 0.5rem;
`;

type TopPanelElementProps = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;

interface Props {
    width: number;
    height: number;
    title?: string | JSX.Element;
    columnOptions: ColumnOptions[];
    reload: () => void;
    onOptionsClose?: (visibleColumns: string[]) => void;
    renderer?: React.FC<TopPanelRendererProps>;
}

const BlockElement = (p: React.PropsWithChildren<{ topPanelProps: Props, props?: TopPanelElementProps }>) =>
    <TopPanelBlock width={p.topPanelProps.width} height={p.topPanelProps.height} {...p.props} />;

const TitleElement = (p: { topPanelProps: Props, props?: TopPanelElementProps }) =>
    <React.Fragment>
        {p.topPanelProps.title !== undefined &&
            <TopPanelLeftContainer {...p.props}>
                <Title>{p.topPanelProps.title}</Title>
            </TopPanelLeftContainer>}
    </React.Fragment>;

const ReloadElement = (p: { topPanelProps: Props, props?: TopPanelElementProps }) => {
    const reload = () => p.topPanelProps.reload();

    return (
        <ReloadButton onClick={reload} {...p.props}>
            <FontAwesomeIcon icon="sync" />
       </ReloadButton>
    );
};

const OptionsElement = (p: { topPanelProps: Props, props?: TopPanelElementProps }) =>
    <Options columns={p.topPanelProps.columnOptions} onClose={p.topPanelProps.onOptionsClose} {...p.props} />;

export const TopPanel = (props: Props) => {
    if (props.renderer !== undefined) {
        const rendererProps: TopPanelRendererProps = useMemo(() => {
            const blockElement = (p: TopPanelElementProps) =>
                <BlockElement topPanelProps={props} props={p} />;
            const titleElement = (p: TopPanelElementProps) =>
                <TitleElement topPanelProps={props} props={p} />;
            const reloadElement = (p: TopPanelElementProps) =>
                <ReloadElement topPanelProps={props} props={p} />;
            const optionsElement = (p: TopPanelElementProps) =>
                <OptionsElement topPanelProps={props} props={p} />;

            return {
                width: props.width,
                height: props.height,
                title: props.title,
                blockElement,
                titleElement,
                reloadElement,
                optionsElement
            };
        }, [props.width, props.height, props.title]);

        const CustomTopPanel = props.renderer!;
        return <CustomTopPanel {...rendererProps} />;
    }

    return (
        <BlockElement topPanelProps={props}>
            <TitleElement topPanelProps={props} />
            <ReloadElement topPanelProps={props} />
            <OptionsElement topPanelProps={props} />
        </BlockElement>
    );
};