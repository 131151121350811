import { GamestoreIoApplicationLocalization, IoApplicationLocalization, GamestoreIoApplication, IoApplication } from "src/shared/dtos";
import { availableLanguages, deepEqual, makeEmptyIoGamestoreLoc } from "src/shared/helpers";

export type LocalizationState = {
    defaultLanguage: string,
    appId: string,
    current?: GamestoreIoApplicationLocalization,
    initial?: GamestoreIoApplicationLocalization,
    ioLocale?: IoApplicationLocalization,
    all: GamestoreIoApplicationLocalization[],
    allInitial: GamestoreIoApplicationLocalization[],
    ioLocs: IoApplicationLocalization[],
    changedLanguages: string[],
    removedLocalizations: GamestoreIoApplicationLocalization[]
};

export type LocalizationAction =
    {
        kind: "set",
        app: GamestoreIoApplication,
        resetCurrent: boolean,
        localizations: GamestoreIoApplicationLocalization[],
        initialLocalizations: GamestoreIoApplicationLocalization[] } |
    { kind: "select", lang: string } |
    { kind: "add", lang: string } |
    { kind: "remove" } |
    { kind: "update", value: Partial<GamestoreIoApplicationLocalization> } |
    { kind: "set-io-app", value: IoApplication };

const sortByAvailableLanguages = (a: GamestoreIoApplicationLocalization, b: GamestoreIoApplicationLocalization) =>
    availableLanguages.findIndex(l => l.value === a.language)
    - availableLanguages.findIndex(l => l.value === b.language);

export const localizationReducer = (state: LocalizationState, action: LocalizationAction) => {
    const processChangedLanguages = () => {
        const isChanged = !deepEqual(state.current, state.initial);
        const changedIndex = state.changedLanguages.indexOf(state.current!.language);
        if (isChanged && changedIndex === -1) {
            state.changedLanguages = state.changedLanguages.concat(state.current!.language);
        }
        if (!isChanged && changedIndex !== -1) {
            state.changedLanguages.splice(changedIndex, 1);
        }
    };

    switch (action.kind) {
        case "set": {
            const current = action.resetCurrent === true
                ? action.localizations.find(l => l.language === state.defaultLanguage) || action.localizations[0]
                : state.current;
            const initial = action.initialLocalizations.find(l => l.language === state.defaultLanguage);
            const ioLocale = action.app?.ioApplication.localizations.find(l => l.language === state.defaultLanguage);

            state = {
                defaultLanguage: state.defaultLanguage,
                appId: action.app.id,
                ioLocs: action.app?.ioApplication.localizations ?? [],
                current,
                initial,
                ioLocale,
                all: action.localizations.sort(sortByAvailableLanguages),
                allInitial: action.initialLocalizations,
                changedLanguages: [],
                removedLocalizations: []
            };

            break;
        }
        case "select": {
            const found = state.all.find(l => l.language === action.lang);
            if (found === undefined) {
                break;
            }

            state.current = found;
            state.initial = state.allInitial.find(l => l.language === action.lang);
            state.ioLocale = state.ioLocs.find(l => l.language === action.lang);
            break;
        }
        case "add": {
            if (state.all.some(l => l.language === action.lang)) {
                break;
            }

            const removedLoc = state.removedLocalizations.find(l => l.language === action.lang);
            state.current = removedLoc ?? makeEmptyIoGamestoreLoc(state.appId, action.lang);
            state.initial = state.allInitial.find(l => l.language === action.lang);
            state.ioLocale = state.ioLocs.find(l => l.language === action.lang);
            state.all = state.all.concat(state.current).sort(sortByAvailableLanguages);

            const isChanged = !deepEqual(state.current, state.initial);
            if (isChanged && state.changedLanguages.indexOf(action.lang) === -1) {
                state.changedLanguages = state.changedLanguages.concat(action.lang);
            }

            const removedLanguageIndex = state.removedLocalizations.findIndex(l => l.language === action.lang);
            if (removedLanguageIndex !== -1) {
                state.removedLocalizations.splice(removedLanguageIndex, 1);
            }

            break;
        }
        case "remove": {
            if (state.current === undefined || state.all.length < 2) {
                break;
            }

            const index = state.all.findIndex(l => l.language === state.current!.language);
            state.all = state.all.filter(l => l.language !== state.current!.language);

            const changedLanguageIndex = state.changedLanguages.findIndex(l => l === state.current!.language);
            if (changedLanguageIndex !== -1) {
                state.changedLanguages.splice(changedLanguageIndex, 1);
            }

            if (state.allInitial.find(l => l.language === state.current!.language) !== undefined) {
                state.removedLocalizations = state.removedLocalizations.concat(state.current);
            }

            const nextIndex = Math.min(state.all.length - 1, Math.max(0, index));
            state.current = state.all[nextIndex];
            state.initial = state.allInitial.find(l => l.language === state.current?.language);
            state.ioLocale = state.ioLocs.find(l => l.language === state.current?.language);
            break;
        }
        case "update": {
            if (state.current === undefined) {
                break;
            }

            Object.assign(state.current, action.value);
            processChangedLanguages();
            break;
        }
        case "set-io-app": {
            if (state.current === undefined) {
                break;
            }

            const appLangs = action.value.localizations.map(l => l.language);
            const currentLangs = state.all.map(l => l.language);
            const newLangs = appLangs.filter(lang => !currentLangs.includes(lang));
            state.all = state.all.concat(newLangs.map(lang => makeEmptyIoGamestoreLoc(state.appId, lang)));

            break;
        }
    }

    return Object.assign({}, state);
};

export const makeLocalizationReducerDefault = (defaultLanguage: string): LocalizationState => ({
    defaultLanguage,
    appId: "",
    current: undefined,
    initial: undefined,
    all: [],
    allInitial: [],
    ioLocs: [],
    changedLanguages: [],
    removedLocalizations: []
});