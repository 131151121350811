import { GamestoreIoApplicationLocalization } from "src/shared/dtos";
import { isBlacklistUrl, isNullOrUndefined, validateDownloaderVideoOptions, validateSource, validateSources, validateUrlField, validateUrls, ValidationSchema } from "src/shared/helpers";
import { GamestoreIoAppMainInfo } from "./applicationReducer";

const validateApplicationUrl = (url: string | undefined): string | null => {
    if (isBlacklistUrl(url)) {
        return `URL '${url}' is in a blacklist.`;
    }
    return validateUrlField({ url, isRequired: true, requiredMessage: "URL is required" });
};

export const makeLocalizationValidationSchema = (isGraphicsCustomized: boolean): ValidationSchema<GamestoreIoApplicationLocalization> => ({
        title: v => isNullOrUndefined(v) || v.length < 2 ? "Title must be a minimum of two characters." : null,
        description: v => isNullOrUndefined(v) ? "Description is required" : null,
        applicationUrl: url => validateApplicationUrl(url),
        thumbnailUrl: url => validateUrlField({
            url,
            isRequired: isGraphicsCustomized,
            requiredMessage: "URL is required for Graphics Customized app."
        }),
        iconUrl: url => validateUrlField({ url, isRequired: true }),
        loadingImageUrl: url => validateUrlField({ url }),
        logoUrl: url => validateUrlField({ url }),
        backgroundUrl: url => validateUrlField({ url }),
        banners: urls => validateUrls({
            urls,
            itemName: "Banner",
            isRequired: isGraphicsCustomized,
            requiredMessage: "At least one banner must be added for Graphics Customized app."
        }),
        downloaderVideoOptions: v => validateDownloaderVideoOptions(v),
        screenshots: urls => validateUrls({ urls, itemName: "Screenshot" }),
        videos: urls => validateUrls({ urls, itemName: "Video" })
    });

export const locSourcesValidationSchema: ValidationSchema<GamestoreIoApplicationLocalization> = {
    thumbnailUrl: url => validateSource({ url, type: "image" }),
    iconUrl: url => validateSource({ url, type: "image" }),
    loadingImageUrl: url => validateSource({ url, type: "image" }),
    logoUrl: url => validateSource({ url, type: "image" }),
    backgroundUrl: url => validateSource({ url, type: "image" }),
    banners: urls => validateSources({ urls, itemName: "Banner", type: "image" }),
    downloaderVideoOptions: ops => validateSource({ url: ops?.videoUrl, type: "youTube" }),
    screenshots: urls => validateSources({ urls, itemName: "Screenshot", type: "image" }),
    videos: urls => validateSources({ urls, itemName: "Video", type: "youTube" }),
};

export const applicationValidationSchema: ValidationSchema<GamestoreIoAppMainInfo> = {
    id: val => !val ? "Application ID required." : null
};
