import React, { useState, useCallback, useMemo, useEffect } from "react";
import { noop, defer } from "rxjs";
import { RequestStatisticsSummary, RequestStatisticsListDataType, OrderDirection } from "src/shared/dtos";
import { serializeDate, setFromInput, useSubscription } from "src/shared/helpers";
import {
    DocumentTitle,
    HeaderField,
    RouteLink,
    RouteUserProps,
    StatisticsDatePicker,
    StatisticsHeader,
} from "src/shared/components";
import { Column, DefaultCellRenderer, LoadRequest, InfiniteTable } from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { Content, VerticalBox } from "src/shared/components/flex";
import { routes } from "src/shared/routes";
import {
    useFunctionState,
    deserializeQueryParameters,
    QueryParameterDescriptor,
    serializeQueryParameters,
    pushUrl
} from "src/shared/helpers";
import api from "../api";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { Input } from "reactstrap";

const StyledDefaultCellRenderer = styled(DefaultCellRenderer)`
    span {
        text-align: right;
        padding-right: 50px;
    }
`;

const keyDataTypes: { [key: string]: RequestStatisticsListDataType } = {
    "path": RequestStatisticsListDataType.Path,
    "count": RequestStatisticsListDataType.Count,
    "movingAverageCount": RequestStatisticsListDataType.MovingAverageCount,
    "totalMilliseconds": RequestStatisticsListDataType.TotalMilliseconds,
    "movingAverageTotalMilliseconds": RequestStatisticsListDataType.MovingAverageTotalMilliseconds,
    "averageMilliseconds": RequestStatisticsListDataType.AverageMilliseconds,
    "movingAverageAverageMilliseconds": RequestStatisticsListDataType.MovingAverageAverageMilliseconds,
    "standardDeviationMilliseconds": RequestStatisticsListDataType.StandardDeviationMilliseconds,
};

const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType as RequestStatisticsListDataType);

const sortQueryParameterDescriptors: QueryParameterDescriptor[] = [
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    {
        paramType: "sort",
        paramName: "reportDate",
        deserializer: (value: string) => new Date(value)
    },
    {
        paramType: "sort",
        paramName: "movingAvgDays",
        deserializer: (value: string) => value !== undefined ? parseInt(value, 10) : 7
    }
];

export const ListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [sort, initDate, initMovingAvgDays] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...sortQueryParameterDescriptors);
        let orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        let orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;
        let dateParam = allParams.find(p => p.paramType === "sort" && p.paramName === "reportDate")?.value as Date | undefined;
        let movingAvgDaysParam = allParams.find(p => p.paramType === "sort" && p.paramName === "movingAvgDays")?.value as number | undefined;

        const defaultMovingAvgDays = 7; // Week.
        movingAvgDaysParam = movingAvgDaysParam ?? defaultMovingAvgDays;

        if (orderBy === undefined) {
            orderBy = "count";
        }

        if (orderDir === undefined) {
            orderDir = SortOrder.DESC;
        }

        if (dateParam === undefined) {
            dateParam = new Date();
            dateParam.setDate(dateParam.getDate() - 1);
        }

        return [{ key: orderBy, order: orderDir } as SortOptions, dateParam, movingAvgDaysParam];
    }, [location.search]);

    const [items, setItems] = useState<RequestStatisticsSummary[]>([]);
    const [dates, setDates] = useState<string[]>([]);
    const [reportDate, setReportDate] = useState<Date>(initDate);
    const [loadRequest, setLoadRequest] = useState<LoadRequest>({ reload: true, limit: 200, offset: 0, sort: { key: "count", order: SortOrder.DESC } });
    const [movingAvgDays, setMovingAvgDays] = useState<number>(initMovingAvgDays);
    const changeReportDate = (date: Date) => setReportDate(date);

    const updateDates = useSubscription(() => defer(() => api.getDates()).subscribe(setDates), [api.getDates]);

    useEffect(() => {
        updateDates();
    }, []);

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);

    const load = useCallback(
        (request: LoadRequest | undefined) => {
            if (request === undefined) {
                request = loadRequest;
            }
            else {
                // checking the selected sorted column to set the default DESC order
                if (request.sort !== undefined && request.sort.key !== loadRequest.sort?.key) {
                    request.sort.order = SortOrder.DESC;
                }
                setLoadRequest(request);
            }
            const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : RequestStatisticsListDataType.Count;
            const sortRequest = {
                orderBy: orderByKey,
                orderDir: orderByKey !== undefined
                    ? request.sort?.order === SortOrder.ASC ? OrderDirection.Asc : OrderDirection.Desc
                    : undefined,
                reportDate: serializeDate(reportDate),
                movingAvgDays
            };
            const observable = defer(() => api.list(
                reportDate,
                movingAvgDays,
                sortRequest.orderBy,
                sortRequest.orderDir ?? OrderDirection.Desc
            ));
            const url = routes.requestStatistics.url({
                searchParams: serializeQueryParameters(sortRequest, ...sortQueryParameterDescriptors)
            });
            pushUrl(location, history, url);

            return new Promise<boolean>(resolve => {
                const subscription = observable.subscribe(result => {
                    setItems(result);
                    resolve(true);
                });
                setUnsubscribe(() => subscription.unsubscribe());
            });
        }, [items, reportDate, movingAvgDays, location]);

    useEffect(() => { load(undefined); }, [reportDate, movingAvgDays]);

    const pathRenderer = useMemo(() =>
        (props: CellProps<string, RequestStatisticsSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{ route: routes.viewRequestStatistic, args: { path: encodeURIComponent(cellData), movingAvgDays, endDate: props.rowData.date.slice(0, 10) } }}
                    className="d-inline">
                    {cellData}
                </RouteLink>
            );
            return <DefaultCellRenderer cellData={link} title={cellData} {...restProps} />;
        }, [reportDate, movingAvgDays]);

    const intRenderer = useMemo(() =>
        (props: CellProps<number, RequestStatisticsSummary>) => {
            const { cellData, ...restProps } = props;
            const text = cellData.toLocaleString(
                undefined,
                { maximumFractionDigits: 0, minimumFractionDigits: 0 }
            );
            const title = `${cellData}`;
            return <StyledDefaultCellRenderer cellData={text} title={title} {...restProps} />;
        }, [reportDate, movingAvgDays]);

    const decimalRenderer = useMemo(() =>
        (props: CellProps<number, RequestStatisticsSummary>) => {
            const { cellData, ...restProps } = props;
            const text = cellData.toLocaleString(
                undefined,
                { maximumFractionDigits: 3, minimumFractionDigits: 3 }
            );
            const title = `${cellData}`;
            return <StyledDefaultCellRenderer cellData={text} title={title} {...restProps} />;
        }, [reportDate, movingAvgDays]);

    const select = makeSelect<RequestStatisticsSummary>();
    return (
        <VerticalBox>
            <DocumentTitle title="Request Statistics" />
            <StatisticsHeader
                user={user}
                title="Request Statistics"
            >
                <HeaderField label="Moving Days">
                    <Input
                        type="number"
                        min={1}
                        step={1}
                        onChange={setFromInput(setMovingAvgDays)}
                        value={movingAvgDays}
                    />
                </HeaderField>
                <HeaderField label="Date">
                    <StatisticsDatePicker
                        id="date"
                        className="form-control"
                        popperPlacement="top-end"
                        selected={reportDate}
                        onChange={changeReportDate}
                        dates={dates}
                    />
                </HeaderField>
            </StatisticsHeader>
            <Content>
                <InfiniteTable
                    items={items}
                    load={load}
                    sortBy={sort}
                    renderContext={movingAvgDays.toString() + reportDate.toISOString()}
                    hideTopPanel={true}
                    minimumLoadCount={200}
                >
                    <Column
                        key="path"
                        title="Path"
                        width={500}
                        dataGetter={select(a => a.path)}
                        cellRenderer={pathRenderer}
                    />
                    <Column
                        key="count"
                        title="Count"
                        width={200}
                        dataGetter={select(a => a.count)}
                        cellRenderer={intRenderer}
                    />
                    <Column
                        key="movingAverageCount"
                        title="Moving Avg Count"
                        width={200}
                        dataGetter={select(a => a.movingAverageCount)}
                        cellRenderer={intRenderer}
                    />
                    <Column
                        key="totalMilliseconds"
                        title="Total (ms)"
                        width={200}
                        dataGetter={select(a => a.totalMilliseconds)}
                        cellRenderer={decimalRenderer}
                    />
                    <Column
                        key="movingAverageTotalMilliseconds"
                        title="Moving Avg Total (ms)"
                        width={200}
                        dataGetter={select(a => a.movingAverageTotalMilliseconds)}
                        cellRenderer={decimalRenderer}
                    />
                    <Column
                        key="averageMilliseconds"
                        title="Avg (ms)"
                        width={200}
                        dataGetter={select(a => a.averageMilliseconds)}
                        cellRenderer={decimalRenderer}
                    />
                    <Column
                        key="movingAverageAverageMilliseconds"
                        title="Moving Avg Avg (ms)"
                        width={200}
                        dataGetter={select(a => a.movingAverageAverageMilliseconds)}
                        cellRenderer={decimalRenderer}
                    />
                    <Column
                        key="standardDeviationMilliseconds"
                        title="Deviation (ms)"
                        width={200}
                        dataGetter={select(a => a.standardDeviationMilliseconds)}
                        cellRenderer={decimalRenderer}
                    />
                </InfiniteTable>
            </Content>
        </VerticalBox>);
});