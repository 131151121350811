export enum SetFromInputType {
    Text,
    Number,
    Checkbox,
    Radio
}

/**
 * Shorthand function that passes a value from an input value change event to the set state hook function.
 * @param dispatch The react set state hook function.
 * @param type The input type. If not set tries to resolve value type from input tag. If type not resolved uses 'Text'.
 */
export const setFromInput = (dispatch: React.Dispatch<React.SetStateAction<unknown>>, type?: SetFromInputType) => {
    return (event: React.FormEvent<HTMLInputElement>) => {
        if (type === undefined) {
            switch (event.currentTarget.type.toLowerCase()) {
                case "number":
                    type = SetFromInputType.Number;
                    break;
                case "checkbox":
                    type = SetFromInputType.Checkbox;
                    break;
                case "radio":
                    type = SetFromInputType.Radio;
                    break;
            }
        }

        let value: unknown;
        switch (type) {
            case SetFromInputType.Checkbox:
                value = event.currentTarget.checked;
                break;
            case SetFromInputType.Radio:
                value = event.currentTarget.value;
                break;
            case SetFromInputType.Number:
                value = +event.currentTarget.value;
                break;
            case SetFromInputType.Text:
            default:
                value = event.currentTarget.value;
                break;
       }

       dispatch(value);
    };
};