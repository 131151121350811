import { client } from "src/shared/client";
import {
    GetTotalGamingMinutesByIdForPeriod,
    GetTotalGamingMinutesByDateForPeriod,
} from "src/shared/dtos";
import { ExcludeMethods } from "src/shared/helpers";

export type GamingMinutesByDateRequest = ExcludeMethods<GetTotalGamingMinutesByDateForPeriod>;
export type GamingMinutesByIdRequest = ExcludeMethods<GetTotalGamingMinutesByIdForPeriod>;
export type StartEndDate = {
    startDate: string;
    endDate: string;
};

export const chartWidgetsApi = {
    getGamingMinutesById: (req: GamingMinutesByIdRequest) => client.get(
        new GetTotalGamingMinutesByIdForPeriod(req)),
    getGamingMinutesByDate: (req: GamingMinutesByDateRequest) => client.get(
        new GetTotalGamingMinutesByDateForPeriod(req)),
};

export default chartWidgetsApi;