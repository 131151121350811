import { css } from "styled-components";

export const ThinScrollbar = css`
    scrollbar-width: thin;

    ::-webkit-scrollbar {
        width: 8px;
    }

    ::-webkit-scrollbar-track {
        background: #f8f9fa;
        border-radius: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background: #d3d3d3;
        border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #d3d3d3;
    }
`;