import React, { useMemo, useCallback,  forwardRef, useImperativeHandle } from "react";
import { Input, Row, Col } from "reactstrap";
import { AdAnimationType, AdOrientationType, AdPixelType, AdRichMediaOverlayUnit } from "src/shared/dtos";
import {
    AnimationOption,
    highlightTargetUrl,
    OrientationTypeSelectOption,
    PixelTypeSelectOption,
    renderImage,
    StyledCodeEditor,
    TargetUrlPlaceholdersTip
} from "./EditForm";
import { setFromInput, useValidate, ValidateAsyncRef } from "src/shared/helpers";
import { ImageSelector, Select, EditField } from "src/shared/components";
import { richMediaOverlayValidationSchema } from "./validationSchema";

interface Props {
    value: AdRichMediaOverlayUnit;
    initial: AdRichMediaOverlayUnit;
    update: React.Dispatch<Partial<AdRichMediaOverlayUnit> | undefined>;
    saving: boolean;
}

const mapAnimationOption = (animationType: AdAnimationType) => {
    const value = animationType;
    const label = animationType;
    return { value, label } as AnimationOption;
};

const mapOrientationSelectOption = (orientationType: AdOrientationType) => {
    const value = orientationType;
    const label = orientationType;
    return { value, label } as OrientationTypeSelectOption;
};

const mapPixelTypeSelectOption = (pixelType: AdPixelType) => {
    const value = pixelType;
    const label = pixelType;
    return { value, label } as PixelTypeSelectOption;
};

export const EditRichMediaOverlayUnit = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const { value, initial, update, saving } = props;

    const orientationOptions = useMemo(
        () => [
            mapOrientationSelectOption(AdOrientationType.Any),
            mapOrientationSelectOption(AdOrientationType.Portrait),
            mapOrientationSelectOption(AdOrientationType.Landscape)
        ], []);

    const selectedOrientationOption = useMemo(
        () => orientationOptions.find(o => o.value === value.orientation),
    [value.orientation, orientationOptions]);

    const setSelectedAnimationsOptions = useCallback(
        (v: AnimationOption[]) => update({ animations: v === null ? [] : v.map(c => c.value!) }), [update]);

    const animationsOptions = useMemo(() => [
        mapAnimationOption(AdAnimationType.SlideInTop),
        mapAnimationOption(AdAnimationType.SlideInBottom),
        mapAnimationOption(AdAnimationType.SlideInLeft),
        mapAnimationOption(AdAnimationType.SlideInRight)],
    []);

    const selectedAnimationsOptions = useMemo(() =>
        animationsOptions?.filter(o => value.animations?.find(animation => animation === o.value) !== undefined),
    [animationsOptions, value.animations]);

    const pixelTypeOptions = useMemo(
        () => [
            mapPixelTypeSelectOption(AdPixelType.Dip),
            mapPixelTypeSelectOption(AdPixelType.Percent),
            mapPixelTypeSelectOption(AdPixelType.Pixel)
        ], []);

    const selectedPixelTypeOption = useMemo(
        () => pixelTypeOptions.find(o => o.value === value.pixelType),
    [value.pixelType, pixelTypeOptions]);

    const setRichMediaOverlayContent = useCallback(
        (url: string) => {
            update({ content: url });
            return url;
        }, [update]);

    const setRichMediaOverlayTargetUrl = useCallback(
        (targetUrl: string) => update({ targetUrl }), [update]);

    const setSizeX = useCallback(
        (sizeX: number) => update({ sizeX }), [update]);

    const setSizeY = useCallback(
        (sizeY: number) => update({ sizeY }), [update]);

    const setCenterX = useCallback(
        (centerX: number) => update({ centerX }), [update]);

    const setCenterY = useCallback(
        (centerY: number) => update({ centerY }), [update]);

    const setDelay = useCallback(
        (delay: number) => update({ delay }), [update]);

    const setDuration = useCallback(
        (duration: number) => update({ duration }), [update]);

    const setPixelType = useCallback(
        (pixelType: PixelTypeSelectOption) => update({ pixelType: pixelType.value }), [update]);

    const setOrientation = useCallback(
        (orientation: OrientationTypeSelectOption) => update({ orientation: orientation.value }), [update]);

    const { validate, errors, register } = useValidate({ schema: richMediaOverlayValidationSchema, value: props.value });

    useImperativeHandle(ref, () => ({
        validate: async () => (await validate(true)).isValid
    }), [validate]);

    return (
        <React.Fragment>
            <EditField
                ref={register("targetUrl")}
                label="Target URL"
                fieldId="targetUrl"
                isChanged={value.targetUrl !== initial.targetUrl}
                errors={errors?.targetUrl}
            >
                <StyledCodeEditor
                    id="targetUrl"
                    value={value.targetUrl ?? ""}
                    onValueChange={setRichMediaOverlayTargetUrl}
                    highlight={highlightTargetUrl}
                    padding={10}
                    tabSize={4}
                    insertSpaces={true}
                    disabled={saving}
                />
                <TargetUrlPlaceholdersTip />
            </EditField>
            <EditField
                label="Banner Image URL"
                fieldId="content"
                isChanged={value.content !== initial.content}
                errors={errors?.content}
            >
                <ImageSelector
                    setInputId="content"
                    value={value.content}
                    set={setRichMediaOverlayContent}
                    render={renderImage}
                    deleteButtonHidden={value.content ? true : false}
                    imageDimensions={{ width: "32rem" }}
                    disabled={saving} />
            </EditField>
            <Row>
                <Col md={6}>
                    <EditField
                        fullWidth={true}
                        fieldId="orientation"
                        label="Orientation"
                        isChanged={value.orientation !== initial.orientation}
                    >
                        <Select
                            id="orientation"
                            placeholder="Orientation"
                            value={selectedOrientationOption}
                            onChange={setOrientation}
                            options={orientationOptions}
                            isMulti={false}
                            isSearchable={false}
                            isDisabled={saving}
                        />
                    </EditField>
                </Col>
                <Col md={6}>
                    <EditField
                        fullWidth={true}
                        fieldId="animations"
                        label="Animations"
                        isChanged={value.animations?.join() !== initial.animations?.join()}
                    >
                        <Select
                            id="animations"
                            placeholder="Animations"
                            value={selectedAnimationsOptions}
                            onChange={setSelectedAnimationsOptions}
                            options={animationsOptions}
                            isMulti={true}
                            isDisabled={saving}
                        />
                    </EditField>
                </Col>
            </Row>
            <EditField
                fieldId="pixelType"
                label="Pixel Type"
                isChanged={value.pixelType !== initial.pixelType}
            >
                <Select
                    id="pixelType"
                    placeholder="Pixel Type"
                    value={selectedPixelTypeOption}
                    onChange={setPixelType}
                    options={pixelTypeOptions}
                    isMulti={false}
                    isSearchable={false}
                    isDisabled={saving}
                />
            </EditField>
            <Row>
                <Col md={6}>
                    <EditField
                        fullWidth={true}
                        label="Size X"
                        fieldId="richMediaOverlaySizeX"
                        isChanged={value.sizeX !== initial.sizeX}
                    >
                        <Input
                            type="number"
                            id="richMediaOverlaySizeX"
                            onChange={setFromInput(setSizeX)}
                            value={value.sizeX ?? ""}
                        />
                    </EditField>
                </Col>
                <Col md={6}>
                    <EditField
                        fullWidth={true}
                        label="Size Y"
                        fieldId="richMediaOverlaySizeY"
                        isChanged={value.sizeY !== initial.sizeY}
                    >
                        <Input
                            type="number"
                            id="richMediaOverlaySizeY"
                            onChange={setFromInput(setSizeY)}
                            value={value.sizeY ?? ""}
                        />
                    </EditField>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <EditField
                        fullWidth={true}
                        label="Center X"
                        fieldId="richMediaOverlayCenterX"
                        isChanged={value.centerX !== initial.centerX}
                    >
                        <Input
                            type="number"
                            id="richMediaOverlayCenterX"
                            onChange={setFromInput(setCenterX)}
                            value={value.centerX ?? ""}
                        />
                    </EditField>
                </Col>
                <Col md={6}>
                    <EditField
                        fullWidth={true}
                        label="Center Y"
                        fieldId="richMediaOverlayCenterY"
                        isChanged={value.centerY !== initial.centerY}
                    >
                        <Input
                            type="number"
                            id="richMediaOverlayCenterY"
                            onChange={setFromInput(setCenterY)}
                            value={value.centerY ?? ""}
                        />
                    </EditField>
                </Col>
            </Row>
            <Row>
                <Col md={6}>
                    <EditField
                        fullWidth={true}
                        label="Delay (ms)"
                        fieldId="richMediaOverlayDelay"
                        isChanged={value.delay !== initial.delay}
                    >
                        <Input
                            type="number"
                            id="richMediaOverlayDelay"
                            onChange={setFromInput(setDelay)}
                            value={value.delay ?? ""}
                        />
                    </EditField>
                </Col>
                <Col md={6}>
                    <EditField
                        fullWidth={true}
                        label="Duration (ms)"
                        fieldId="richMediaOverlayDuration"
                        isChanged={value.duration !== initial.duration}
                    >
                        <Input
                            type="number"
                            id="richMediaOverlayDuration"
                            onChange={setFromInput(setDuration)}
                            value={value.duration ?? ""}
                        />
                    </EditField>
                </Col>
            </Row>
        </React.Fragment>
    );
});