import { classNames } from "@servicestack/client";
import React, { forwardRef, MutableRefObject, useRef, useMemo } from "react";
import { Label, Col } from "reactstrap";
import { FormLabel, StyledTooltip } from "src/shared/components";

export interface HorizontalFieldProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    label?: string | React.ReactNode;
    skipOffset?: boolean;
    labelHelp?: string;
    fieldId?: string;
    isChanged?: boolean;
    originalValue?: unknown;
    errors?: string[] | null;
    warnings?: string[];
}

export const HorizontalField = forwardRef((props: HorizontalFieldProps, ref: MutableRefObject<HTMLDivElement>) => {
    const { className, label, labelHelp, fieldId, children, isChanged, originalValue, errors, warnings, ...restProps } = props;
    const tooltipRef = useRef<HTMLDivElement>(null);
    const showWarnings = useMemo(() => !props.errors || props.errors.length === 0, [props.errors]);
    return (
        <div ref={ref} className={classNames(className, "form-group", "row")} {...restProps}>
            {label !== undefined &&
                <Label
                    for={fieldId}
                    md={4}
                    lg={3}
                    className="text-md-right"
                    title={labelHelp}
                    style={{ cursor: (labelHelp !== undefined ? "help" : "arrow") }}
                >
                    <FormLabel
                        isChanged={isChanged}
                        originalValue={originalValue}
                        warnings={warnings}
                        showWarnings={showWarnings}
                    >
                        {label}
                    </FormLabel>
                </Label>}
            <Col
                md={{ size: 8, offset: label === undefined && !props.skipOffset ? 4 : 0 }}
                lg={{ size: 9, offset: label === undefined && !props.skipOffset ? 3 : 0 }}
                xl={{ size: 9, offset: label === undefined && !props.skipOffset ? 3 : 0 }}
            >
                {children}
                <div ref={tooltipRef} />
                <StyledTooltip placement="bottom" isOpen={errors && errors.length > 0} target={tooltipRef}>
                    {errors?.map((err, i) => <div key={`${err}_${i}`}>{err}</div>)}
                </StyledTooltip>
            </Col>
        </div>
    );
});