import React from "react";
import { ColumnProps } from "./ColumnProps";
import { Column as ReactBaseTableColumn } from "../ReactBaseTable";


/**
 * Column for {InfiniteTable}.
 */
export class Column<TData, TItem> extends React.Component<ColumnProps<TData, TItem>> {
    public render(): React.ReactNode {
        return <ReactBaseTableColumn {...this.props} />;
    }
}