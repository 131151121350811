import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";
import { Form, Input, FormGroup, Label, Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Category, CategoryApplicationsIds } from "src/shared/dtos";
import { setFromInput, useValidate, ValidateAsyncRef } from "src/shared/helpers";
import { FormLabel, EditField, KeyBindingsEditor, Image, ImageSelector, LoadingButton, Select, RouteLink } from "src/shared/components";
import { CategorySelectOption, mapCategorySelectOption } from "src/shared/helpers/mapCategorySelectOption";
import { imagesValidationSchema, makeValidationSchema } from "./validationSchema";
import { useConfirm } from "src/shared/helpers/useConfirm";
import { CategoryFormData } from "./categoryReducer";
import styled from "styled-components";
import { routes } from "src/shared/routes";
import { IUser } from "src/shared/client";
import { CategoryApplicationsModal } from "./CategoryApplicationsModal";

const LinkButton = styled.span`
    cursor: pointer;
    text-align: center;
    vertical-align: center;
    text-decoration: underline;
    font-weight: bold;

    &:hover, &:focus {
        opacity: 0.9;
    }
`;

interface Props {
    value: CategoryFormData;
    initial?: CategoryFormData;
    categories: Category[];
    keyBindingSchemas: object[];
    categoryApplicationsIds: CategoryApplicationsIds;
    update: React.Dispatch<Partial<CategoryFormData>>;
    saving: boolean;
    onSubmit: () => void;
    onDelete: () => void;
    user: IUser | null;
}

export interface EditFormRef {
    validate: () => boolean;
}

export const EditForm = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const { confirm } = useConfirm();
    const isNew = props.initial?.id === "";

    const [isApplicationsModalOpen, setIsApplicationsModalOpen] = useState<boolean>(false);
    const closeApplicationsModal = () => (setIsApplicationsModalOpen(false));
    const openApplicationsModal = () => (setIsApplicationsModalOpen(true));

    const setIsActive = (v: boolean) => props.update({ isActive: v });
    const setShowInNavbar = (v: boolean) => props.update({ showInNavbar: v });
    const setIsVirtual = (v: boolean) => props.update({ isVirtual: v });
    const setName = (v: string) => props.update({ name: v });
    const setDisplayName = (v: string) => props.update({ displayName: v });
    const setId = (v: string) => props.update({ id: v });

    const setDescription = (v: string) => props.update({ description: v ? v : undefined });
    const setBackgroundUrl = (v: string | undefined) => props.update({ backgroundImageUrl: v });
    const setParentId = (v: CategorySelectOption) => props.update({ parentId: v.value });
    const setKeyBindings = (v: string) => props.update({ defaultKeyBindings: v ? v : undefined });

    const parentCategory = props.categories.find(c => c.parentId === props.value.parentId);
    const isVirtual = parentCategory?.isVirtual || props.value.isVirtual;

    const googleAppsIds = props.categoryApplicationsIds.googleApplicationsIds ?? [];
    const ioAppsIds = props.categoryApplicationsIds.ioApplicationsIds ?? [];

    const setBackground = useCallback(
        (url: string) => {
            setBackgroundUrl((url.length !== 0) ? url : undefined);
            return "";
        }, []);

    const validationSchema = useMemo(
        () => makeValidationSchema(props.keyBindingSchemas), [props.keyBindingSchemas]);

    const { validate, errors } = useValidate({ schema: validationSchema, value: props.value });
    const { validate: validateImages, errors: warnings } = useValidate({ schema: imagesValidationSchema, value: props.value, isActive: true });

    useImperativeHandle(ref, () => ({
        validate: async () => {
            if (!(await validate(true)).isValid) {
                return false;
            }
            if ((await validateImages()).isValid) {
                return true;
            }
            const confirmSave = await confirm({
                body: "Some of images can't be found. Do you want to save category anyway?",
                title: "Confirm saving application"
            });
            return !!confirmSave;
        }
    }), [validate, validateImages]);

    const deleteBackground = useCallback((url: string) => {
        setBackgroundUrl(url);
    }, []);

    const renderImage = useCallback((url: string): Image | null => url === "" ? null : { url }, []);

    const isVirtualCheckboxDisabled = useMemo(
        () => props.saving || parentCategory?.isVirtual || googleAppsIds.length > 0 || ioAppsIds.length > 0,
        [props.saving, parentCategory?.isVirtual, props.categoryApplicationsIds]);

    const parentSelectOptions = useMemo(
        () => [{ value: undefined, label: <i>[root]</i> } as CategorySelectOption].concat(
            props.categories.filter(c => c.id !== props.initial?.id)
                .map(c => mapCategorySelectOption(c as Category, props.categories))),
        [props.categories, props.initial?.id]);

    const selectedParent = useMemo(
        () => parentSelectOptions.find(o => o.value === props.value.parentId),
        [props.value.parentId, parentSelectOptions]);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <>
            <CategoryApplicationsModal
                isOpen={isApplicationsModalOpen}
                onClose={closeApplicationsModal}
                googleAplicationsIds={googleAppsIds}
                ioAplicationsIds={ioAppsIds}
                user={props.user}
            />
            <Form className="mt-3" onSubmit={save} noValidate>
                <Row>
                    <EditField
                        fullWidth
                        label="Category ID"
                        fieldId="categoryId"
                        isChanged={props.value.id !== props.initial?.id}
                        originalValue={isNew ? undefined : props.initial?.id}
                    >
                        <Input
                            type="text"
                            id="categoryId"
                            value={props.value.id ?? ""}
                            onChange={setFromInput(setId)}
                            disabled={props.saving || !isNew}
                        />
                    </EditField>
                    <EditField
                        fullWidth
                        label="Parent"
                        fieldId="parentId"
                        isChanged={props.value.parentId !== props.initial?.parentId}
                        originalValue={isNew ? undefined : props.initial?.parentId}
                    >
                        <Select
                            styles={{
                                // Fixes the overlapping problem of the component.
                                menu: provided => ({ ...provided, zIndex: 100 })
                            }}
                            id="parentId"
                            value={selectedParent}
                            onChange={setParentId}
                            options={parentSelectOptions}
                            isMulti={false}
                            isSearchable={true}
                            isDisabled={props.saving}
                        />
                    </EditField>
                    <EditField
                        fullWidth
                        label="Name"
                        fieldId="name"
                        isChanged={props.value.name !== props.initial?.name}
                        originalValue={isNew ? undefined : props.initial?.name}
                    >
                        <Input
                            type="text"
                            id="name"
                            value={props.value.name ?? ""}
                            onChange={setFromInput(setName)}
                            disabled={props.saving}
                        />
                    </EditField>
                    <EditField
                        fullWidth
                        label="Display name"
                        fieldId="displayName"
                        isChanged={props.value.displayName !== props.initial?.displayName}
                        originalValue={isNew ? undefined : props.initial?.displayName}
                    >
                        <Input
                            type="text"
                            id="displayName"
                            value={props.value.displayName ?? ""}
                            onChange={setFromInput(setDisplayName)}
                            disabled={props.saving}
                        />
                    </EditField>
                    <EditField
                        fullWidth
                        label="Description"
                        fieldId="description"
                        isChanged={props.value.description !== props.initial?.description}
                        originalValue={isNew ? undefined : props.initial?.description}
                    >
                        <Input
                            type="text"
                            id="description"
                            value={props.value.description ?? ""}
                            onChange={setFromInput(setDescription)}
                            disabled={props.saving}
                        />
                    </EditField>
                    <EditField
                        fullWidth
                        label="Background URL"
                        fieldId="backgroundImageUrl"
                        isChanged={props.value.backgroundImageUrl !== props.initial?.backgroundImageUrl}
                        originalValue={isNew ? undefined : props.initial?.backgroundImageUrl}
                        warnings={warnings?.backgroundImageUrl}
                        errors={errors?.backgroundImageUrl}
                    >
                        <ImageSelector
                            setInputId="backgroundImageUrl"
                            value={props.value.backgroundImageUrl ?? ""}
                            set={setBackground}
                            render={renderImage}
                            delete={deleteBackground}
                            imageDimensions={{ width: "12rem" }}
                            disabled={props.saving} />
                    </EditField>
                </Row>
                <Row>
                    <EditField fullWidth>
                        <FormGroup check>
                            <Label for="isActive" check>
                                <Input
                                    type="checkbox"
                                    id="isActive"
                                    name="isActive"
                                    checked={props.value.isActive}
                                    onChange={setFromInput(setIsActive)}
                                    disabled={props.saving}
                                />{" "}
                                <FormLabel
                                    isChanged={props.value.isActive !== props.initial?.isActive}
                                    originalValue={isNew ? undefined : props.initial?.isActive}>
                                    Is Active
                                </FormLabel>
                            </Label>
                        </FormGroup>
                    </EditField>
                </Row>
                <Row>
                    <EditField fullWidth>
                        <FormGroup check>
                            <Label for="showInNavbar" check>
                                <Input
                                    type="checkbox"
                                    id="showInNavbar"
                                    name="showInNavbar"
                                    checked={props.value.showInNavbar}
                                    onChange={setFromInput(setShowInNavbar)}
                                    disabled={props.saving}
                                />{" "}
                                <FormLabel
                                    isChanged={props.value.showInNavbar !== props.initial?.showInNavbar}
                                    originalValue={isNew ? undefined : props.initial?.showInNavbar}>
                                    Show In Navbar
                                </FormLabel>
                            </Label>
                        </FormGroup>
                    </EditField>
                </Row>
                <Row>
                    <EditField fullWidth>
                        <FormGroup check>
                            <Label for="isVirtual" check>
                                <Input
                                    type="checkbox"
                                    id="isVirtual"
                                    name="isVirtual"
                                    checked={isVirtual}
                                    onChange={setFromInput(setIsVirtual)}
                                    disabled={isVirtualCheckboxDisabled}
                                />{" "}
                                <FormLabel
                                    isChanged={props.value.isVirtual !== props.initial?.isVirtual}
                                    originalValue={isNew ? undefined : props.initial?.isVirtual}>
                                    Is Virtual - Virtual categories cannot be assigned to applications
                                </FormLabel>
                            </Label>
                        </FormGroup>
                        {(googleAppsIds.length > 0 || ioAppsIds.length > 0) && (
                            <p className="text-info mt-2">
                                This category cannot be marked as virtual because it is assigned to{" "}
                                <LinkButton onClick={openApplicationsModal}>one or more applications</LinkButton>
                            </p>
                        )}
                    </EditField>
                </Row>
                <Row>
                    <EditField
                        errors={errors?.defaultKeyBindings}
                        label={
                            <span>Default Key Bindings{" "}
                                {errors?.defaultKeyBindings && <strong className="text-danger">(invalid)</strong>}
                            </span>
                        }
                        fieldId="defaultKeyBindings"
                        fullWidth={true}
                        isChanged={props.value.defaultKeyBindings !== props.initial?.defaultKeyBindings}
                    >
                        <KeyBindingsEditor
                            value={props.value.defaultKeyBindings ?? ""}
                            setValue={setKeyBindings}
                            disabled={props.saving}
                        />
                    </EditField>
                </Row>
                <Row>
                    <Col xs={12} className="text-right">
                        <LoadingButton
                            loading={props.saving}
                            loadingText={"Saving..."}
                            position="end"
                            type="submit"
                            color="primary">
                            {isNew ? "Add" : "Save"} Category
                        </LoadingButton>
                        <Button
                            type="button"
                            className="ml-2"
                            color="danger"
                            disabled={props.saving}
                            onClick={props.onDelete}
                        >
                            Delete Category
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
});