import { SourceSettings, SourceSettingsData } from "src/shared/dtos";
import { deepEqual } from "src/shared/helpers";

export type SourceSettingsItem = {
    type: "normal" | "default" | "new",
    current: SourceSettings,
    initial: SourceSettings,
    selected: boolean,
    hasChanges: boolean
};

export type SourceSettingsState = {
    items?: SourceSettingsItem[],
    selected?: SourceSettingsItem,
    hasChanges: boolean
};

export type SourceSettingsSetActionValue = { current: SourceSettings, initial: SourceSettings };
export type SourceSettingsUpdateActionValue = {
    source?: string,
    aliases?: string[],
    settings?: Partial<SourceSettingsData>,
    pinToDesktopApplicationIds?:string[]
};

export type SourceSettingsAction =
    { kind: "init", values: SourceSettingsSetActionValue[] } |
    { kind: "select", id?: number } |
    { kind: "ensure-new-exist" } |
    { kind: "set-new-value", value: SourceSettingsSetActionValue } |
    { kind: "update-selected", value: SourceSettingsUpdateActionValue } |
    { kind: "confirm-save-selected", value: SourceSettingsSetActionValue } |
    { kind: "confirm-delete-selected" } |
    { kind: "delete-unsaved-item" };

const makeNewSourceSettings = () => ({
    id: 0,
    source: "",
    aliases: undefined,
    settings: {},
    isDeleted: false
});

const addNewSourceSettings = (items: SourceSettingsItem[]) => {
    const newItem: SourceSettingsItem = {
        type: "new",
        current: makeNewSourceSettings(),
        initial: makeNewSourceSettings(),
        selected: false,
        hasChanges: false
    };

    const defaultIndex = Math.max(0, items.findIndex(i => i.type === "default"));
    items.splice(defaultIndex, 0, newItem);

    return newItem;
};

export const sourceSettingsReducer = (state: SourceSettingsState, action: SourceSettingsAction) => {
    switch (action.kind) {
        case "init": {
            const items = action.values.map(v => ({
                type: v.initial.source === "default" ? "default" : "normal",
                current: v.current,
                initial: v.initial,
                selected: false,
                hasChanges: !deepEqual(v.current, v.initial)
            } as SourceSettingsItem));

            state = {
                items,
                selected: undefined,
                hasChanges: false
            };
            break;
        }
        case "select": {
            state.items?.forEach(i => i.selected = i.initial.id === action.id);
            state.selected = state.items?.find(i => i.selected);
            break;
        }
        case "ensure-new-exist": {
            if (state.items !== undefined && state.items.find(i => i.type === "new") === undefined) {
                addNewSourceSettings(state.items);
            }

            break;
        }
        case "set-new-value": {
            if (state.items !== undefined) {
                let newItem = state.items.find(i => i.type === "new");
                if (newItem === undefined) {
                    newItem = addNewSourceSettings(state.items);
                }

                newItem.current = action.value.current;
                newItem.initial = action.value.initial;
                newItem.current.id = newItem.initial.id = 0;
                newItem.current.source = newItem.initial.source = "";
            }

            break;
        }
        case "update-selected": {
            const item = state.items?.find(i => i.initial.id === state.selected?.initial.id);
            if (item === undefined) {
                break;
            }

            if (item.type !== "default" && action.value.source !== undefined) {
                item.current.source = action.value.source;
            }
            if (action.value.settings !== undefined) {
                Object.assign(item.current.settings, action.value.settings);
            }
            if (action.value.pinToDesktopApplicationIds !== undefined) {
                item.current.settings.pinToDesktopApplicationIds = action.value.pinToDesktopApplicationIds.slice();
            }
            if (action.value.aliases !== undefined) {
                item.current.aliases = action.value.aliases.slice();
            }

            item.hasChanges = !deepEqual(item.current, item.initial);
            break;
        }
        case "confirm-save-selected": {
            if (state.items === undefined) {
                break;
            }

            const item = state.items.find(i => i.initial.id === state.selected?.initial.id);
            if (item === undefined) {
                break;
            }

            item.current = action.value.current;
            item.initial = action.value.initial;
            item.hasChanges = false;

            if (item.type === "new") {
                item.type = "normal";

                const itemIndex = state.items.findIndex(i => i === item);
                state.items.splice(itemIndex, 1);
                state.items.push(item);
            }

            state.items = state.items.slice();
            break;
        }
        case "confirm-delete-selected": {
            if (state.items === undefined) {
                break;
            }

            state.items = state.items.filter(i => i !== state.selected);
            state.selected = undefined;
            break;
        }
        case "delete-unsaved-item": {
            if (state.items !== undefined && state.items.find(i => i.type === "new") !== undefined) {
                const item = state.items.find(i => i.initial.id === state.selected?.initial.id);
                const itemIndex = state.items.findIndex(i => i === item);
                state.items.splice(itemIndex, 1);
            }
        }
    }

    state.hasChanges = state.items?.find(i => i.hasChanges) !== undefined;

    state.items?.sort((a, b) => {
        if (a.type === "normal" && b.type === "normal") {
            return a.initial.source < b.initial.source ? -1 : 1;
        }

        const typeToPriority = (type: "normal" | "default" | "new") =>
            type === "new" ? 0 : type === "default" ? 1 : 2;
        return typeToPriority(a.type) < typeToPriority(b.type) ? -1 : 1;
    });

    return Object.assign({}, state);
};

export const sourceSettingsReducerDefault: SourceSettingsState = {
    items: undefined,
    selected: undefined,
    hasChanges: false
};
