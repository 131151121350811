import styled from "styled-components";
import { ThemedProps } from "../../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { previewVariables } from "../previewVariables";

export const HeaderContainer = styled.div`
    position: relative;
`;

export const HeaderLeftIcon = styled.img`
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translate(0, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: contain;
`;

export const UpperHeader = styled.div`
    background-color: ${previewVariables.upperHeaderBgColor};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 12px;
    height: 34px;
`;

export const LowerHeader = styled.div`
    color: ${previewVariables.headerTextColor};
    background-color: ${previewVariables.lowerHeaderBgColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    height: 34px;
    text-transform: uppercase;
    font-size: 11px;
`;

export const ControlButtonsBlock = styled.div`
    display: flex;
    align-items: center;

    span:not(:first-child) {
        margin-left: 6px;
    }
`;

export const ControlButton = styled.span`
    color: ${previewVariables.controlButtonColor};
    font-size: .7rem;
    cursor: pointer;
    &:hover {
        color: ${(p: ThemedProps) => p.theme.WindowCloseButtonMouseOverColor};
    }
    &:active {
        color: ${(p: ThemedProps) => p.theme.WindowCloseButtonMouseClickColor};
    }
    &:focus {
        outline: none;
    }
`;

export const CloseIcon = styled(FontAwesomeIcon).attrs({ icon: "times" })`
    font-size: 14px;
    margin-bottom: -2px;
    vertical-align: bottom;
`;

export const MinimizeIcon = styled(FontAwesomeIcon).attrs({ icon: "window-minimize" })`
    vertical-align: bottom;
`;

export const MaximizeIcon = styled(FontAwesomeIcon).attrs({ icon: ["fas", "window-maximize"] })`
    vertical-align: bottom;
`;

export const MenuIcon = styled(FontAwesomeIcon).attrs({ icon: "bars" })`
    vertical-align: bottom;
`;

export const WelcomeText = styled.span`
    margin-left: 80px;
`;

export const TitleText = styled.span`
    color: ${previewVariables.headerTitleTextColor};
`;

export const SearchPanel = styled.div`
    display: flex;
    align-items: center;
    text-transform: uppercase;

    span:not(:first-child) {
        margin-left: 14px;
    }
`;

export const SearchIcon = styled(FontAwesomeIcon).attrs({ icon: "search" })`
    vertical-align: middle;
    font-size: 16px;
`;