import { getYoutubeVideoIdFromUrl } from "./getYoutubeVideoIdFromUrl";

const youTubeRegexp = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)(:\d{3})?\/.+$/;

export const youtubeVideoExists = (url: string): Promise<boolean> =>
    new Promise<boolean>((res) => {
        if (!youTubeRegexp.test(url)){
            return res(false);
        }
        // Get video Id from url
        let video_id = getYoutubeVideoIdFromUrl(url);
        if (!video_id){
            return res(false);
        }
        const ampersandPosition = video_id.indexOf("&");
        if (ampersandPosition !== -1) {
            video_id = video_id.substring(0, ampersandPosition);
        }
        // Get video thumbnail
        const thumbnailUrl = `https://img.youtube.com/vi/${video_id}/mqdefault.jpg`;
        const thumbnail = new Image();

        // Id video does not exist, youtube will send default thumbnail with width = 120px
        thumbnail.onload = () => res(thumbnail.width !== 120);
        thumbnail.onerror = () => res(false);
        thumbnail.src = thumbnailUrl;
    });
