import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

export interface SourceSettingsDeletionModalProps {
    open: boolean;
    title: string;
    delete: () => void;
    cancel: () => void;
}

export const SourceSettingsDeletionModal = (props: React.PropsWithChildren<SourceSettingsDeletionModalProps>) =>
    <Modal isOpen={props.open} toggle={props.cancel}>
        <ModalHeader toggle={props.cancel}>
            {props.title}
        </ModalHeader>
        <ModalBody>
            {props.children}
        </ModalBody>
        <ModalFooter>
            <Button color="danger" onClick={props.delete}>Delete</Button>
            <Button color="secondary" onClick={props.cancel}>Cancel</Button>
        </ModalFooter>
    </Modal>;