import React, { useEffect, useState, useReducer, useMemo, useCallback, } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col } from "reactstrap";
import { defer, noop, of } from "rxjs";
import { AdUnitTrigger, AdUnitTriggerType, AdCampaign, AdUnit, AdUnitTypes } from "src/shared/dtos";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    LeavingViewProtector,
    Loader,
    DocumentTitle,
    RouteLink,
    RouteUserProps,
    EditError,
    EditErrorOptions,
    notifySuccess
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { structuredClone, useFunctionState } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { EditForm } from "./EditForm";
import { adUnitTriggerReducer, adUnitTriggerReducerDefault } from "./adUnitTriggerReducer";
import api from "../api";
import { map, switchMap } from "rxjs/operators";

const makeEmptyAdUnitTrigger = (adUnitId: number): AdUnitTrigger => ({
    id: 0,
    isDisabled: false,
    adUnitId,
    triggerType: AdUnitTriggerType.GameExit,
    adSchedulingDetails: undefined
});

type Props = RouteComponentProps<{ id: string, adCampaignId: string, adUnitId: string }> & RouteUserProps;

export const EditView = withRouter(({ match, history, user }: Props) => {
    const [isNew, id, adCampaignId, adUnitId] = useMemo(() => {
        const parsedId = match.params.id !== undefined ? parseInt(match.params.id, 10) : 0;
        const parsedAdCampaignId = parseInt(match.params.adCampaignId, 10);
        const parsedAdUnitId = parseInt(match.params.adUnitId, 10);
        return [parsedId === 0, parsedId, parsedAdCampaignId, parsedAdUnitId];
    }, [match.params.id, match.params.adCampaignId, match.params.adUnitId]);

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);

    const [isNewSaved, setIsNewSaved] = useState(!isNew);
    const [error, setError] = useState<EditErrorOptions | undefined>();
    const [saving, setSaving] = useState(false);
    const [adUnitTrigger, changeAdUnitTrigger] = useReducer(adUnitTriggerReducer, adUnitTriggerReducerDefault);
    const [adCampaignName, setAdCampaignName] = useState<string | undefined>();
    const [adUnitType, setAdUnitType] = useState<AdUnitTypes>(AdUnitTypes.BannerAction);

    useEffect(() => {
        const subscription = defer(() => isNew ? of<AdUnitTrigger | undefined>(undefined) : api.get(id)).pipe(
            switchMap(trigger => {
                const unitPromise = trigger !== undefined
                    ? api.getAdUnit(trigger.adUnitId)
                    : api.getAdUnit(adUnitId);

                return defer(() => unitPromise).pipe(
                    switchMap(unit => {
                        const campaignPromise = unit !== undefined
                            ? api.getAdCampaign(unit.adCampaignId)
                            : api.getAdCampaign(adCampaignId);

                        return defer(() => campaignPromise).pipe(
                            map(campaign => [trigger, unit, campaign] as [AdUnitTrigger, AdUnit, AdCampaign])
                        );
                    })
                );
            })
        ).subscribe({
            next: results => {
                setAdUnitTrigger(results[0]);
                setAdUnitType(results[1].unitType);
                setAdCampaignName(results[2].name);
            },
            error: () => setError({
                text: "Unable to load Ad Unit Trigger.",
                actionText: "Back to Ad Unit",
                action: () => history.push(routes.editAdUnit.url({ id: adUnitId, adCampaignId }))
            })
        });
        return () => subscription.unsubscribe();
    }, [isNew, id, adCampaignId, adUnitId]);

    useEffect(() => {
        // Go to edit view after successful save of the new entity.
        // It is implemented this way to let leaving view protector know, that there is no changes.
        if (isNew && isNewSaved && error === undefined && adCampaignId !== undefined && adUnitTrigger.current !== undefined && adUnitTrigger.current.adUnitId !== 0 && adUnitTrigger.current!.id !== 0) {
            history.push(routes.editAdUnit.url({ id: adUnitId, adCampaignId }));
        }
    }, [isNew, isNewSaved, adUnitTrigger.current]);

    const setAdUnitTrigger = (data?: AdUnitTrigger) => {
        const value = data ?? makeEmptyAdUnitTrigger(adUnitId);
        const subscription = defer(() => structuredClone(value)).subscribe(clone => {
            changeAdUnitTrigger({ kind: "set", value, initialValue: clone });
        });
        setUnsubscribe(() => subscription.unsubscribe());
    };

    const save = useCallback(() => {
        if (saving || adUnitTrigger.current === undefined) {
            return;
        }

        setSaving(true);
        const subscription = defer(() => isNew ? api.create(adUnitTrigger.current!) : api.update(adUnitTrigger.current!)).subscribe({
            next: result => {
                setAdUnitTrigger(result);
                if (isNew) {
                    setIsNewSaved(true);
                }

                notifySuccess(`Ad Unit Trigger ${isNew ? "added" : "saved"} successfully.`);
                setSaving(false);
            },
            error: () => {
                setSaving(false);
                setError({
                    text: "Unable to save Ad Unit Trigger.",
                    actionText: "Continue",
                    action: () => setError(undefined)
                });
            }
        });
        setUnsubscribe(() => subscription.unsubscribe());
    }, [adUnitTrigger.current, saving, isNew]);

    const updateAdUnitTrigger = useCallback((value: Partial<AdUnitTrigger>) => changeAdUnitTrigger({ kind: "update", value }), []);
    const [title, adUnitTitle, adCampaignTitle] = useMemo(
        () => [
            isNew ? "[new]" : adUnitTrigger.current !== undefined ? `[${adUnitTrigger.current.id}]` : "Loading...",
            adUnitTrigger.current !== undefined ? `[${adUnitTrigger.current.adUnitId}]` : "Loading...",
            adCampaignName ?? "Loading..."
        ],
        [isNew, adUnitTrigger.current, adCampaignName]);

    return (
        <VerticalBox>
            <DocumentTitle title={`Ad Unit Trigger - ${title}`} />
            <LeavingViewProtector showConfirmation={adUnitTrigger.changed && (isNew ? !isNewSaved : true)} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.adCampaigns}>
                                Ad Campaigns
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={{ route: routes.editAdCampaign, args: { id: adCampaignId } }}>
                                {adCampaignTitle}
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            Ad Units
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            <RouteLink user={user} to={{ route: routes.editAdUnit, args: { id: adUnitId, adCampaignId } }}>
                                {adUnitTitle}
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            Ad Unit Triggers
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {title}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    {error && <EditError error={error} />}
                    {!error && adUnitTrigger.current === undefined && <Loader />}
                    {!error && adUnitTrigger.current !== undefined &&
                        <React.Fragment>
                            <Row>
                                <Col sm={{ size: 8, offset: 2 }}>
                                    <EditForm
                                        value={adUnitTrigger.current}
                                        initial={adUnitTrigger.initial}
                                        isNewAdUnitTrigger={isNew}
                                        adUnitType={adUnitType}
                                        update={updateAdUnitTrigger}
                                        saving={saving}
                                        onSubmit={save}
                                    />
                                </Col>
                            </Row>
                        </React.Fragment>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});