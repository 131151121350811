import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = styled(FontAwesomeIcon).attrs({ icon: ["fas", "angle-double-up"] })`
    cursor: help;
    color: #ffd500;
    font-size: 0.75em;
    vertical-align: top;
    margin-left: 2px;
`;

const Label = (props: { text: string, isOverridden: boolean }) =>
    <React.Fragment>
        {props.text}
        {props.isOverridden && <Icon title={"This property is overridden in Gamestore Application."} />}
    </React.Fragment>;

export const overriddenProperty = (text: string, isOverridden: boolean) =>
    <Label text={text} isOverridden={isOverridden} />;