import { client } from "src/shared/client";
import { GetUsers, ManageUsers, GetUser, User, SetPassword, DeleteUser } from "src/shared/dtos";
import { structuredClone, InfiniteTableRequest, infiniteTableResult } from "src/shared/helpers";

export const UserApi = {
    list: async (request: InfiniteTableRequest) => {
        const users = await client.get(new GetUsers());
        return infiniteTableResult(users, request);
    },
    get: async (id: number) => {
        const user = await client.get(new GetUser({ id }));
        if (!(user?.id > 0)) {
            throw new Error(`User with id '${id}' is not found.`);
        }

        return structuredClone(user);
    },
    add: async (user: User, password: string) => {
        const result = await client.post(new ManageUsers({ user }));
        let isPasswordSet: boolean;
        try {
            isPasswordSet = await UserApi.changePassword(result.username, password);
        } catch {
            isPasswordSet = false;
        }

        return {
            user: result,
            isPasswordSet
        };
    },
    save: (user: User) => {
        return client.put(new ManageUsers({ user }));
    },
    delete: (id: number) => {
        return client.delete(new DeleteUser({ id }));
    },
    changePassword: async (username: string, newPassword: string) => {
        const request = new SetPassword({ username, newPassword });
        return await client.post(request);
    },
    resetPassword: async (username: string, oldPassword: string, newPassword: string) => {
        const request = new SetPassword({ username, oldPassword, newPassword });
        return await client.post(request);
    }
};

export default UserApi;