import React, { useState, useCallback, useMemo, useEffect } from "react";
import { BreadcrumbItem } from "reactstrap";
import { defer } from "rxjs";
import { GoogleApplicationSummary, GoogleApplicationListDataType, OrderDirection, Category, ApkPlatform } from "src/shared/dtos";
import { Breadcrumb, DocumentTitle, RouteLink, RouteUserProps, Loader, notifySuccess, notifyError } from "src/shared/components";
import {
    Column,
    DefaultCellRenderer,
    InfiniteTable,
    StringSearch,
    TopPanelRendererProps,
    TopPanelLeftContainer,
    LoadRequest,
    SearchOptions,
    multipleCategorySearchFactory
} from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { Content, VerticalBox } from "src/shared/components/flex";
import {
    formatSections,
    formatAsAbbreviation,
    htmlToPlainText,
    GetGoogleAppPublisherLink,
    GetGoogleAppCategoryLink,
    deserializeQueryParameters,
    QueryParameterDescriptor,
    serializeQueryParameters,
    pushUrl,
    getGridVisibleColumns,
    useBootstrapBreakpoint,
    useSubscription
} from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { LanguageSelector } from "src/shared/components/LanguageSelector";
import { Rating } from "src/shared/components/Rating";
import api, { ListRequest } from "../api";
import { CategoryType } from "src/shared/CategoryType";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { multiValueCheckboxFactory } from "src/shared/components/InfiniteTable/MultiValueCheckboxSearch";

const keyDataTypes: { [key: string]: GoogleApplicationListDataType } = {
    "appId": GoogleApplicationListDataType.Id,
    "title": GoogleApplicationListDataType.Title,
    "publisher": GoogleApplicationListDataType.Publisher,
    "rating": GoogleApplicationListDataType.Rating,
    "version32": GoogleApplicationListDataType.Version32,
    "version64": GoogleApplicationListDataType.Version64,
    "installsCount": GoogleApplicationListDataType.InstallsCount
};
const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType as GoogleApplicationListDataType);

function getSearchValueByKey<T>(key: string, searchOptions?: Array<SearchOptions<unknown>>): T {
    return searchOptions?.find(s => s.key === key)?.value as T;
}

const searchQueryParameterDescriptors: QueryParameterDescriptor[] = [
    { paramType: "language", paramName: "language", serializer: (value: string) => value === "en" ? undefined : value },
    {
        paramType: "search",
        paramName: "platforms",
        serializer: (value: ApkPlatform[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    { paramType: "search", paramName: "title" },
    { paramType: "search", paramName: "appId" },
    { paramType: "search", paramName: "publisher" },
    {
        paramType: "search",
        paramName: "category",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    }
];

const platforms = Object.values(ApkPlatform);

export const ListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [lang, tableFilters, sort] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...searchQueryParameterDescriptors);
        const languageParam = allParams.find(p => p.paramType === "language")?.value as string | undefined;
        const searchParams = allParams
            .filter(p => p.paramType === "search")
            .map(qp => ({ key: qp.paramName, value: qp.value } as SearchOptions<unknown>));
        const orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        const orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;

        return [languageParam, searchParams, { key: orderBy, order: orderDir } as SortOptions];
    }, [location.search]);

    const [visibleColumns, setVisibleColumns] = useState<string[] | "all" | undefined>();
    const [allCategories, setAllCategories] = useState<Category[] | undefined>();
    const [items, setItems] = useState<GoogleApplicationSummary[]>([]);
    const [language, setLanguage] = useState("en");
    const breakpoint = useBootstrapBreakpoint();
    useEffect(() => setLanguage(lang ?? "en"), [lang]);

    const updateCategories = useSubscription(() => defer(() => api.getCategories()).subscribe(categories => {
            const validCategories = categories
                .filter(c => !c.isVirtual && [CategoryType.Game, CategoryType.App].some(v => c.parentId === v || c.id === v));
            setAllCategories(validCategories);
        }), [api.getCategories]);

    useEffect(() => {
        updateCategories();
    }, []);

    const updateVisibleColumns = useSubscription(() => defer(() => getGridVisibleColumns("GoogleApplications"))
        .subscribe(columns => setVisibleColumns(columns ?? "all")), []);

    useEffect(() => {
        updateVisibleColumns();
    }, [breakpoint]);

    const updateData = useSubscription((
        request: ListRequest,
        isReload: boolean,
        resolve: (val: boolean) => void) => defer(() => api.list(request)).subscribe(result => {
            if (result.length > 0) {
                setItems(prev => isReload ? result : prev.concat(result));
            }
            resolve(result.length === 0);
        }), [api.list]);

    const load = useCallback(
        (request: LoadRequest) => {
            if (request.reload) {
                setItems([]);
            }

            const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : GoogleApplicationListDataType.Id;
            const searchRequest = {
                language,
                platforms: getSearchValueByKey<ApkPlatform[]>("platforms", request.search),
                orderBy: orderByKey,
                orderDir: orderByKey !== undefined
                    ? request.sort?.order === SortOrder.DESC ? OrderDirection.Desc : OrderDirection.Asc
                    : undefined,
                appId: getSearchValueByKey<string>("appId", request.search),
                title: getSearchValueByKey<string>("title", request.search),
                publisher: getSearchValueByKey<string>("publisher", request.search),
                category: getSearchValueByKey<string[]>("category", request.search)
            };

            const requestBody: ListRequest = {
                language: searchRequest.language,
                offset: request.offset,
                limit: request.limit,
                orderBy: searchRequest.orderBy,
                orderDirection: searchRequest.orderDir ?? OrderDirection.Asc,
                idSearch: searchRequest.appId,
                titleSearch: searchRequest.title,
                publisherSearch: searchRequest.publisher,
                categoriesSearch: searchRequest.category,
                platformsSearch: searchRequest.platforms
            };

            const url = routes.googleApplications.url({
                searchParams: serializeQueryParameters(searchRequest, ...searchQueryParameterDescriptors)
            });
            pushUrl(location, history, url);

            return new Promise<boolean>(resolve => {
                updateData(requestBody, request?.reload, resolve);
            });

        }, [items, language, location]);

    const changeLanguage = useCallback(
        (value: string) => {
            setItems([]);
            setLanguage(value);
        }, [language]);

    const TopPanel = useMemo(() =>
        (props: TopPanelRendererProps) =>
            <props.blockElement>
                <TopPanelLeftContainer>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Google Applications</BreadcrumbItem>
                    </Breadcrumb>
                </TopPanelLeftContainer>
                <RouteLink user={user} to={routes.addGoogleApplication} button className="mr-2">
                    Add Application
                </RouteLink>
                <LanguageSelector value={language} setValue={changeLanguage} className="mr-2" />
                <props.reloadElement />
                <props.optionsElement />
            </props.blockElement>, [language]);

    const iconRenderer = useMemo(() =>
        (props: CellProps<string | undefined, GoogleApplicationSummary>) =>
            <RouteLink
                user={user}
                to={{ route: routes.editGoogleApplication, args: { appId: props.rowData.id, language } }}>
                <img src={props.cellData ?? ""} alt={props.cellData ?? "Application Icon"} width={50} height={50} />
            </RouteLink>, [language]);

    const titleRenderer = useMemo(() =>
        (props: CellProps<unknown, GoogleApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{ route: routes.editGoogleApplication, args: { appId: props.rowData.id, language } }}
                    className="d-inline">
                    {props.rowData.title}
                </RouteLink>
            );

            return <DefaultCellRenderer cellData={link} title={props.rowData.title} {...restProps} />;
        }, [language]);

    const appIdRenderer = useMemo(() =>
        (props: CellProps<string, GoogleApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{ route: routes.editGoogleApplication, args: { appId: props.rowData.id, language } }}
                    className="d-inline">
                    {props.rowData.id}
                </RouteLink>
            );

            return <DefaultCellRenderer cellData={link} title={cellData} {...restProps} />;
        }, [language]);

    const publisherRenderer = useMemo(() =>
        (props: CellProps<string | undefined, GoogleApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <a
                    href={GetGoogleAppPublisherLink(language, cellData, props.rowData.publisherId)}
                    target="_blank">
                    {cellData}
                </a>
            );

            return <DefaultCellRenderer cellData={link} title={cellData} {...restProps} />;
        }, [language]);

    const ratingRenderer = useMemo(() =>
        (props: CellProps<number, GoogleApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const rating = <Rating value={cellData} max={5} />;
            const title = `${cellData}`;

            return <DefaultCellRenderer cellData={rating} title={title} {...restProps} />;
        }, []);

    const categoryRenderer = useMemo(() =>
        (props: CellProps<Category, GoogleApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const category = cellData;

            const cell = (
                <span key={`${category.parentId} ${category.id}`}>
                    {category.parentId === CategoryType.Game || category.parentId === CategoryType.App
                        ? (
                            <a
                                href={GetGoogleAppCategoryLink(category.id, language)}
                                title={`Parent ID: ${category.parentId}`}
                                target="_blank"
                            >
                                {category.name}
                            </a>
                        )
                        : <span title={`Parent ID: ${category.parentId}`}>{category.name}</span>}
                </span>
            );

            return <DefaultCellRenderer cellData={cell} {...restProps} />;
        }, [language]);

    const descriptionRenderer = useMemo(() =>
        (props: CellProps<string | undefined, GoogleApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const title = `${cellData ?? ""}\n\n${htmlToPlainText(props.rowData.description ?? "")}`;

            return <DefaultCellRenderer cellData={cellData} title={title} {...restProps} />;
        }, [language]);

    const versionRenderer = useMemo(() =>
        (props: CellProps<string | undefined, GoogleApplicationSummary>) => {
            const { cellData, ...restProps } = props;

            return <DefaultCellRenderer cellData={cellData} title={cellData} {...restProps} />;
        }, []);

    const installCountRenderer = useMemo(() =>
        (props: CellProps<number, GoogleApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const size = formatAsAbbreviation(cellData);
            const title = `${formatSections(cellData)}+`;

            return <DefaultCellRenderer cellData={size} title={title} {...restProps} />;
        }, []);

    const platformMapper = useCallback((val: ApkPlatform): string => {
            switch (val) {
                case ApkPlatform.X64:
                    return "64 bit";
                case ApkPlatform.X32:
                    return "32 bit";
                default:
                    return "unknown";
            }
        }, []);

    const platformRenderer = useMemo(() =>
        (props: CellProps<ApkPlatform[], GoogleApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const label = cellData.sort().join(" & ");
            return <DefaultCellRenderer cellData={label} title={label} {...restProps} />;
        }, []);

    const select = makeSelect<GoogleApplicationSummary>();

    const onOptionsClose = useCallback((visColumns: string[]) => {
        if (Array.isArray(visibleColumns) && visColumns.join("") === visibleColumns.join("")) {
            return;
        }
        if (breakpoint) {
            api.saveGridSettings(breakpoint, visColumns)
                .then(_ => {
                    notifySuccess("Columns settings saved successfully");
                    setVisibleColumns(visColumns);
                })
                .catch(_ => notifyError("Failed to save column settings"));
        }
    }, [breakpoint, visibleColumns]);

    return (
        <VerticalBox>
            <DocumentTitle title="Google Applications" />
            <Content overflowHidden>
                {(allCategories === undefined || visibleColumns === undefined) && <Loader />}
                {allCategories !== undefined && visibleColumns !== undefined &&
                    <InfiniteTable
                        items={items}
                        load={load}
                        renderContext={language}
                        filters={tableFilters}
                        sortBy={sort}
                        topPanelRenderer={TopPanel}
                        visibleColumns={visibleColumns}
                        onOptionsClose={onOptionsClose}
                    >
                        <Column
                            key="icon"
                            dataGetter={select(a => a.iconUrl)}
                            width={70}
                            minWidth={70}
                            cellRenderer={iconRenderer}
                            resizable={false}
                            sortable={false}
                        />
                        <Column
                            key="title"
                            title="Title"
                            width={260}
                            cellRenderer={titleRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="appId"
                            dataGetter={select(a => a.id)}
                            title="AppID"
                            width={320}
                            cellRenderer={appIdRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="publisher"
                            dataGetter={select(a => a.publisher)}
                            title="Publisher"
                            width={140}
                            cellRenderer={publisherRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="rating"
                            dataGetter={select(a => a.rating)}
                            title="Rating"
                            width={125}
                            minWidth={125}
                            cellRenderer={ratingRenderer}
                            resizable={false}
                        />
                        <Column
                            key="category"
                            dataGetter={select(a => a.category)}
                            title="Category"
                            width={350}
                            sortable={false}
                            cellRenderer={categoryRenderer}
                            searchRenderer={multipleCategorySearchFactory(allCategories)}
                        />
                        <Column
                            key="description"
                            dataGetter={select(a => a.shortDescription)}
                            title="Description"
                            width={550}
                            sortable={false}
                            cellRenderer={descriptionRenderer}
                        />
                        <Column
                            key="platforms"
                            dataGetter={select(a => a.platforms)}
                            title="Platform"
                            width={160}
                            sortable={false}
                            cellRenderer={platformRenderer}
                            searchRenderer={multiValueCheckboxFactory(platforms, platformMapper)}
                        />
                        <Column
                            key="version32"
                            dataGetter={select(a => a.version32)}
                            title="Version32"
                            width={150}
                            cellRenderer={versionRenderer}
                        />
                        <Column
                            key="version64"
                            dataGetter={select(a => a.version64)}
                            title="Version64"
                            width={150}
                            cellRenderer={versionRenderer}
                        />
                        <Column
                            key="installsCount"
                            dataGetter={select(a => a.installsCount)}
                            title="#install"
                            width={100}
                            cellRenderer={installCountRenderer}
                        />
                    </InfiniteTable>}
            </Content>
        </VerticalBox>);
});
