import { client } from "src/shared/client";
import { GetFrontendSettings } from "../dtos";
import { BootstrapBreakpoint, getBootstrapBreakpoint } from "./getBootstrapBreakpoint";

/**
 * Returns visible column keys for specified grid.
 * @param gridName The grid name.
 */
export async function getGridVisibleColumns(gridName: string) {
    const settingsResult = await client.get(new GetFrontendSettings());
    const defaultResolutions = settingsResult.defaultSettings?.grids.find(g => g.name === gridName)?.resolutions;
    const userResolutions = settingsResult.userSettings?.grids?.find(g => g.name === gridName)?.resolutions;
    const resolutions = userResolutions ?? defaultResolutions;

    if (resolutions === undefined) {
        return "all";
    }

    let breakpoint: BootstrapBreakpoint | undefined = getBootstrapBreakpoint();
    while (breakpoint !== undefined) {
        const columns = resolutions[breakpoint]?.visibleColumns;
        if (columns !== undefined) {
            return columns;
        }

        breakpoint = getNextSize(breakpoint);
    }

    return "all";
}

/**
 * Returns columns hidden state for specified column.
 * @param columnKey The column key.
 * @param visibleColumns The visible column keys.
 */
export function isColumnHidden(columnKey: string, visibleColumns: string[] | "all") {
    return visibleColumns === "all" ? false : visibleColumns.indexOf(columnKey) === -1;
}

const getNextSize = (size: string): BootstrapBreakpoint | undefined => {
    switch (size) {
        case "xs":
            return "sm";
        case "sm":
            return "md";
        case "md":
            return "lg";
        case "lg":
            return "xl";
        default:
            return undefined;
    }
};
