import React, { useState, useCallback, useMemo } from "react";
import { defer } from "rxjs";
import { AdUnitTriggerType, AdUnitTrigger } from "src/shared/dtos";
import { RouteLink, notifyError, notifySuccess } from "src/shared/components";
import { ActionButton, BooleanIndicator, Column, DefaultCellRenderer, InfiniteTable } from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect } from "src/shared/components/ReactBaseTable";
import { routes } from "src/shared/routes";
import api from "../api";
import { IUser } from "src/shared/client";
import { Col } from "reactstrap";
import { splitCamelCase, useSubscription } from "src/shared/helpers";

interface Props {
    adCampaignId: number;
    adUnitId: number;
    user: IUser | null;
}

export const AdUnitTriggerListView = ({ adCampaignId, adUnitId, user }: Props) => {
    const [items, setItems] = useState<AdUnitTrigger[]>([]);

    const updateItems = useSubscription((
        resolve: (val: boolean) => void) => defer(() => api.getAdUnitTriggers(adUnitId)).subscribe(result => {
        setItems(result);
        resolve(true);
    }), [api.getAdUnitTriggers]);

    const load = useCallback(() => {
        return new Promise<boolean>(resolve => {
            updateItems(resolve);
        });
    }, [updateItems]);

    const onDeleteAdUnitTrigger = useSubscription((trigger: AdUnitTrigger) => {
        defer(() => api.deleteAdUnitTrigger(trigger.id)).subscribe({
            next: () => {
                notifySuccess(`Ad Unit Trigger has been successfully deleted.`);
                setItems(old => old.filter(i => i.id !== trigger.id));
            },
            error: () => notifyError(`Unable to delete Ad Unit Trigger.`)
        });
    }, [api.deleteAdUnitTrigger]);

    const triggerTypeRenderer = useMemo(() => (props: CellProps<AdUnitTriggerType, AdUnitTrigger>) => {
        const { cellData, ...restProps } = props;
        const value = splitCamelCase(String(cellData));
        return <DefaultCellRenderer cellData={value} title={value} {...restProps} />;
    }, []);

    const schedulingRenderer = useMemo(() => (props: CellProps<unknown, AdUnitTrigger>) => {
        const { cellData, ...restProps } = props;
        const value = props.rowData.triggerType === AdUnitTriggerType.OnSchedule && props.rowData.adSchedulingDetails !== undefined
            ? `Every ${props.rowData.adSchedulingDetails.displayEveryMinutes} minute(s)`
            : "";

        return <DefaultCellRenderer cellData={value} {...restProps} />;
    }, []);

    const enabledRenderer = useMemo(() => (props: CellProps<boolean, AdUnitTrigger>) => {
        const { cellData, ...restProps } = props;
        const value = <BooleanIndicator value={cellData} />;
        const title = `Ad Unit Trigger is ${cellData ? "enabled" : "disabled"}.`;
        return <DefaultCellRenderer cellData={value} title={title} centerText={true} {...restProps} />;
    }, []);

    const actionsRenderer = useMemo(() => (props: CellProps<unknown, AdUnitTrigger>) => {
        const { cellData, ...restProps } = props;
        const onDelete = () => onDeleteAdUnitTrigger(props.rowData);
        const value =
            <React.Fragment>
                <RouteLink
                    user={user}
                    to={{ route: routes.editAdUnitTrigger, args: { adCampaignId, adUnitId, id: props.rowData.id } }}
                    className="d-inline">
                    <ActionButton icon="pencil-alt" className="text-primary" />
                </RouteLink>
                <ActionButton icon="trash" className="text-danger" onClick={onDelete} />
            </React.Fragment>;

        return <DefaultCellRenderer cellData={value} centerText={true} {...restProps} />;
    }, [onDeleteAdUnitTrigger]);

    const select = makeSelect<AdUnitTrigger>();
    return (
        <React.Fragment>
            <Col sm={12} className="p-3">
                <RouteLink
                    disabled={adCampaignId === 0}
                    user={user}
                    to={{ route: routes.addAdUnitTrigger, args: { adCampaignId, adUnitId } }}
                    button
                    className="mr-2"
                >
                    New Ad Unit Trigger
                </RouteLink>
            </Col>
            <InfiniteTable
                className="p-3"
                hideTopPanel={true}
                items={items}
                height={600}
                load={load}
            >
                <Column
                    key="type"
                    dataGetter={select(a => a.triggerType)}
                    title="Trigger Type"
                    width={200}
                    cellRenderer={triggerTypeRenderer}
                />
                <Column
                    key="scheduling"
                    title="Scheduling Options"
                    width={500}
                    sortable={false}
                    cellRenderer={schedulingRenderer}
                />
                <Column
                    key="enabled"
                    dataGetter={select(a => !a.isDisabled)}
                    title="Enabled"
                    width={100}
                    sortable={false}
                    cellRenderer={enabledRenderer}
                />
                <Column
                    key="actions"
                    width={100}
                    sortable={false}
                    cellRenderer={actionsRenderer}
                />
            </InfiniteTable>
        </React.Fragment>);
};