import React, { useCallback, useMemo } from "react";
import { Redirect, Route as ReactRoute, RouteProps as ReactRouteProps, RouteComponentProps } from "react-router-dom";
import { IUser } from "src/shared/client";
import { routes, IRoute } from "src/shared/routes";

export interface RouteUserProps {
    user: IUser | null;
}

export interface RouteProps<TRouteArgs> {
    route: IRoute<TRouteArgs>;
    user: IUser | null;
}

const Empty = () => null;

export const Route = (props: ReactRouteProps & RouteProps<unknown>) => {
    const { route, user, component, render, ...restProps } = props;

    const loginRequired = useMemo(() => route.loginRequired(user), [route, user]);
    const hasAccess = useMemo(() => route.hasAccess(user), [route, user]);
    const Component = useMemo(() => component ?? route.component ?? Empty, [route, component]);

    const customRender = useCallback(
        (p: RouteComponentProps) => {
            const componentProps = { user, ...p };
            return !loginRequired && hasAccess
                ? (render ? render(componentProps) : <Component {...componentProps} />)
                : <Redirect to={{
                    pathname: loginRequired ? routes.login.url() : routes.home.url(),
                    state: { from: p.location } }} />;
        },
        [loginRequired, hasAccess, Component]);

    return <ReactRoute render={customRender} {...restProps} />;
};