import React, { useCallback } from "react";
import { PlaystoreActivityForPeriodSummary } from "src/shared/dtos";
import { Table } from "reactstrap";
import { FilterDescriptor, FilterName } from "./DetailsComponent";

interface Props {
    list: PlaystoreActivityForPeriodSummary[];
    activeFilters: FilterName[];
    filterDescriptors: FilterDescriptor[];
}

export const PlaystoreActivityStatisticsListView = ({ list, activeFilters, filterDescriptors }: Props) => {
    const sortedList = list.slice();
    sortedList.sort((item1, item2) => item1.date > item2.date ? -1 : item1.date < item2.date ? 1 : 0);

    const dateRenderer = (value: string) => {
        const date = new Date(value);
        return date.toLocaleDateString();
    };

    const intRenderer = (value: number) =>
        value.toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits: 0 });

    const renderTableHeader = useCallback(() => (
        <thead>
            <tr>
                <th>Date</th>
                {activeFilters.map(filterName => (
                    <th key={`th-${filterName}`}>
                        {filterDescriptors.find(x => x.name === filterName)?.title ?? filterName}
                    </th>
                ))}
                <th>Count</th>
            </tr>
        </thead>
    ), [activeFilters]);

    const renderRow = useCallback((rowData: PlaystoreActivityForPeriodSummary, idx: number) => (
        <tr key={rowData.date.toString()}>
            <th scope="row">{dateRenderer(rowData.date)}</th>
            {activeFilters.map(filterName =>
                <td key={`${filterName}-${idx}`}
                    style={{ maxWidth: 400, minWidth: ["apkPackageName", "status"].includes(filterName) ? 340 : undefined }}
                    className="px-3">
                    {renderValues(rowData, filterName)}
                </td>)}
            <td className="px-3">{intRenderer(rowData.count)}</td>
        </tr>
    ), [activeFilters]);

    const renderValues = useCallback((rowData: PlaystoreActivityForPeriodSummary, filterName: FilterName): string => {
        let values = rowData[filterName] as string[];
        if (values === undefined) {
            return "";
        }
        const descriptor = filterDescriptors.find(x => x.name === filterName);
        if (descriptor !== undefined && descriptor.valueMapper !== undefined) {
            values = values.map(descriptor.valueMapper);
        }
        return values.join(", ");
    }, [filterDescriptors]);

    if (list.length === 0) {
        return <h3>No data</h3>;
    }

    return (
        <React.Fragment>
            <Table striped responsive="lg">
                {renderTableHeader()}
                <tbody>
                    {sortedList.map(renderRow)}
                </tbody>
            </Table>
        </React.Fragment >);
};
