import React from "react";
import styled from "styled-components";
import { HeaderProps as BaseTableHeaderProps } from "src/shared/components/ReactBaseTable";
import { ColumnProps, SearchOptions, SearchCellHeaderProps } from ".";

const SearchHeaderRow = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`;

type SearchCellHeaderContainerProps = { width: number, minWidth?: number, maxWidth?: number };
const SearchCellHeaderContainer = styled.div.attrs<SearchCellHeaderContainerProps>(props => ({
    style: {
        width: `${props.width}px`,
        minWidth: props.minWidth === undefined ? "initial" : `${props.minWidth}px`,
        maxWidth: props.maxWidth === undefined ? "initial" : `${props.maxWidth}px`
    }
}))<SearchCellHeaderContainerProps>`
    display: flex;
    flex: 0 1 auto;
    padding: 0 4px;
    overflow: visible;

    &:first-child {
        padding-left: 15px;
    }

    &:last-child {
        padding-right: 15px;
    }
`;

export interface HeaderProps<TItem> extends BaseTableHeaderProps<TItem> {
    searchHeaderIndex: number;
    search: Array<SearchOptions<unknown>>;
    updateSearch: (key: string, value: unknown) => void;
    headerRenderer?: React.FC<BaseTableHeaderProps<TItem>> | React.ReactNode | JSX.Element;
}

function Header<TItem>(props: HeaderProps<TItem> & { className?: string }) {
    const { searchHeaderIndex, headerRenderer, ...baseHeaderProps } = props;

    if (props.headerIndex !== searchHeaderIndex) {
        const BaseHeaderRenderer = props.headerRenderer! as React.FC<BaseTableHeaderProps<TItem>>;
        return props.headerRenderer !== undefined
            ? <BaseHeaderRenderer {...baseHeaderProps} />
            : <React.Fragment>{props.cells}</React.Fragment>;
    }

    return (
        <SearchHeaderRow>
            {props.columns.map((col: ColumnProps<unknown, TItem>, index: number) => {
                const search = props.search.find(s => s.key === col.key);
                const cellHeaderProps = {
                    columns: props.columns,
                    column: col,
                    columnIndex: index,
                    headerIndex: props.headerIndex,
                    container: null as unknown, // TODO: Try find out how to get real value from this function.
                    searchValue: search?.value as unknown,
                    setSearchValue: (value: unknown) => props.updateSearch(col.key, value)
                } as SearchCellHeaderProps<unknown, TItem>;

                const SearchCellHeader = col.headerRenderer! as React.FC<SearchCellHeaderProps<unknown, TItem>>;
                const key = `header${baseHeaderProps.headerIndex}_column${index}:${col.key}`;
                return (
                    <SearchCellHeaderContainer
                        key={key}
                        width={col.width}
                        minWidth={col.minWidth}
                        maxWidth={col.maxWidth}>
                        <SearchCellHeader {...cellHeaderProps} />
                    </SearchCellHeaderContainer>
                );
            })}
        </SearchHeaderRow>
    );
}

export function customizeHeader<TItem>(
    searchHeaderIndex: number,
    search: Array<SearchOptions<unknown>>,
    updateSearch: (key: string, value: unknown) => void,
    headerRenderer?: React.FC<HeaderProps<TItem>> | React.ReactNode | JSX.Element) {

    return (props: BaseTableHeaderProps<TItem>) =>
        <Header
            searchHeaderIndex={searchHeaderIndex}
            search={search}
            updateSearch={updateSearch}
            headerRenderer={headerRenderer}
            {...props}
        />;
}