export const BootstrapBreakpointNames = ["xxl", "xl", "lg", "md", "sm", "xs"] as const;
export type BootstrapBreakpoint = typeof BootstrapBreakpointNames[number];

/**
 * Gets the current bootstrap breakpoint for current window size.
 * Solution taken from: https://github.com/shaack/bootstrap-detect-breakpoint
 */
export const getBootstrapBreakpoint = () => {
    const breakpointValues = {};
    for (const breakpointName of BootstrapBreakpointNames) {
        breakpointValues[breakpointName] = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue("--breakpoint-" + breakpointName);
    }

    for (const breakpointName of BootstrapBreakpointNames) {
        if (window.matchMedia("(min-width: " + breakpointValues[breakpointName] + ")").matches) {
            return breakpointName;
        }
    }

    return undefined;
};

export const BootstrapBreakpoints: {[key in BootstrapBreakpoint]: number} = {
    xxl: 1400,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 0
};
