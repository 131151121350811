import React, { FC, useState, useContext, useMemo } from "react";
import { ConfirmContext, ConfirmModalContent, initialConfirmModalContent, ModalSize } from "./confirmContext";

export const ConfirmProvider: FC = ({ children }) => {
  const [show, setShow] = useState<boolean>(false);
  const [size, setSize] = useState<ModalSize>("md");
  const [content, setContent] = useState<ConfirmModalContent>(initialConfirmModalContent);

  const actions = useMemo(() => ({
    setShow, setContent, setSize
  }), []);

  const state = useMemo(() => ({
    show, content, size
  }), [show, content, size]);

  return (
    <ConfirmContext.Provider
      value={{
        state,
        actions
      }}
    >
      {children}
    </ConfirmContext.Provider>
  );
};

export const useConfirmContext = () => useContext(ConfirmContext);
