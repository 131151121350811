import React, { useState, useEffect, useCallback } from "react";
import { Form, FormGroup, Label, Input, Button, FormFeedback, Row, Col } from "reactstrap";
import { User } from "src/shared/dtos";
import { formValidation } from "src/shared/helpers";
import { HorizontalField, ConfirmationModal, FormLabel } from "src/shared/components";
import { useForm, ErrorMessage } from "react-hook-form";
import { minPasswordLength } from "./ChangePasswordForm";

interface Props {
    user: User;
    isNewUser: boolean;
    saving: boolean;
    setHasChanges: React.Dispatch<boolean>;
    onAdd: (data: Required<EditFormValues>) => void;
    onSave: (data: EditFormValues) => void;
    onDelete: () => void;
}

export type EditFormValues = User & {
    password?: string,
    confirmPassword?: string
};
export const EditForm = (props: Props) => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const openConfirmDelete = useCallback(() => setConfirmDelete(true), []);
    const closeConfirmDelete = useCallback(() => setConfirmDelete(false), []);

    const { register, handleSubmit, errors, reset, watch, formState } =
        useForm<EditFormValues>({ reValidateMode: "onBlur" });
    const changes = watch();

    useEffect(() => reset({ password: "", confirmPassword: "", ...props.user }), [props.user]);
    useEffect(() => props.setHasChanges(formState.dirty), [formState.dirty]);

    const submit = useCallback((form: EditFormValues) => {
        props.isNewUser
            ? props.onAdd(form as Required<EditFormValues>)
            : props.onSave(form);
    }, [props.isNewUser, props.onAdd, props.onSave]);

    return (
        <Form onSubmit={handleSubmit(submit)} noValidate>
            <Row>
                <Col xl={{ offset: 2, size: 8 }}>
                    {!props.isNewUser &&
                        <ConfirmationModal
                            title="Confirm user deletion"
                            open={confirmDelete}
                            confirm={props.onDelete}
                            cancel={closeConfirmDelete}>
                            Do you want to delete user <b>{props.user.username}</b>?
                </ConfirmationModal>}
                    <HorizontalField>
                        <h2>
                            {props.isNewUser
                                ? "Add user"
                                : `Edit ${props.user.firstName + ` ` + props.user.lastName}`}
                        </h2>
                    </HorizontalField>
                    <HorizontalField
                        label="First Name"
                        fieldId="firstName"
                        isChanged={props.user.firstName !== changes.firstName}
                        originalValue={props.isNewUser ? undefined : props.user.firstName}>
                        <Input
                            type="text"
                            id="firstName"
                            name="firstName"
                            disabled={props.saving}
                            invalid={errors.firstName !== undefined}
                            innerRef={register(formValidation({ required: true }))} />
                        <ErrorMessage
                            as={FormFeedback}
                            errors={errors}
                            name="firstName" />
                    </HorizontalField>
                    <HorizontalField
                        label="Last Name"
                        fieldId="lastName"
                        isChanged={props.user.lastName !== changes.lastName}
                        originalValue={props.isNewUser ? undefined : props.user.lastName}>
                        <Input
                            type="text"
                            id="lastName"
                            name="lastName"
                            disabled={props.saving}
                            invalid={errors.lastName !== undefined}
                            innerRef={register(formValidation({ required: true }))} />
                        <ErrorMessage
                            as={FormFeedback}
                            errors={errors}
                            name="lastName" />
                    </HorizontalField>
                    <HorizontalField
                        label="Email"
                        fieldId="username"
                        isChanged={props.user.username !== changes.username}
                        originalValue={props.isNewUser ? undefined : props.user.username}>
                        <Input
                            type="text"
                            id="username"
                            name="username"
                            disabled={props.saving}
                            invalid={errors.username !== undefined}
                            innerRef={register(formValidation({ required: true, email: true }))} />
                        <ErrorMessage
                            as={FormFeedback}
                            errors={errors}
                            name="username" />
                    </HorizontalField>
                    <HorizontalField>
                        <FormGroup check>
                            <Label for="isAdmin" check>
                                <Input
                                    type="checkbox"
                                    id="isAdmin"
                                    name="isAdmin"
                                    disabled={props.saving}
                                    innerRef={register({})} />{" "}
                                <FormLabel
                                    isChanged={props.user.isAdmin !== changes.isAdmin}
                                    originalValue={props.isNewUser || props.user.isAdmin === undefined
                                        ? undefined
                                        : props.user.isAdmin === true ? "Yes" : "No"}>
                                    Is Administrator
                                </FormLabel>
                            </Label>
                        </FormGroup>
                    </HorizontalField>
                    {props.isNewUser &&
                        <React.Fragment>
                            <HorizontalField
                                label="Password"
                                fieldId="password"
                                isChanged={(changes.password?.length ?? 0) > 0}
                                originalValue={undefined}>
                                <Input
                                    type="password"
                                    autoComplete="new-password"
                                    id="password"
                                    name="password"
                                    disabled={props.saving}
                                    invalid={errors.password !== undefined}
                                    innerRef={register(formValidation({ required: true, minLength: minPasswordLength }))} />
                                <ErrorMessage
                                    as={FormFeedback}
                                    errors={errors}
                                    name="password" />
                            </HorizontalField>
                            <HorizontalField
                                label="Confirm password"
                                fieldId="confirmPassword"
                                isChanged={(changes.confirmPassword?.length ?? 0) > 0}
                                originalValue={undefined}>
                                <Input
                                    type="password"
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    disabled={props.saving}
                                    invalid={errors.confirmPassword !== undefined}
                                    innerRef={register(formValidation({
                                        required: true,
                                        validate: {
                                            match: v => v === changes.password ? true : "Passwords must match"
                                        }
                                    }))} />
                                <ErrorMessage
                                    as={FormFeedback}
                                    errors={errors}
                                    name="confirmPassword" />
                            </HorizontalField>
                        </React.Fragment>}
                        <HorizontalField className="text-right">
                            {!props.isNewUser &&
                                <Button
                                    type="button"
                                    color="danger"
                                    disabled={props.saving}
                                    onClick={openConfirmDelete}>
                                    Delete
                        </Button>}
                            <Button
                                type="submit"
                                color="primary"
                                className="ml-2"
                                disabled={props.saving}>
                                {props.isNewUser ? "Add user" : "Save changes"}
                            </Button>
                        </HorizontalField>
                </Col>
            </Row>
        </Form>
    );
};