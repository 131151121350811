import React, { FC } from "react";
import { useConfirm } from "src/shared/helpers/useConfirm";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  .modal {
    z-index: 1000;
  }
`;

const StyledBody = styled(ModalBody)`
  & p {
    margin-bottom: .6rem;
  }
`;

const ConfirmModal: FC = () => {
    const { onCancel, onConfirm, state: { show, content, size } } = useConfirm();

    return (
        <StyledModal
            isOpen={show}
            toggle={onCancel}
            size={size}
        >
            {content.title && <ModalHeader toggle={onCancel}>{content.title}</ModalHeader>}
            <StyledBody>{content.body}</StyledBody>
            <ModalFooter>
                <Button onClick={onCancel}>
                    {content.noBtnText}
                </Button>
                <Button onClick={onConfirm} color="error">
                    {content.yesBtnText}
                </Button>
            </ModalFooter>
        </StyledModal>
    );
};

export default ConfirmModal;
