import React, { FC } from "react";
import { SideNavContainer, CategoryList, SubCategoryList } from "./components";

interface CategoryData {
    name: string;
    subCategories: string[];
}

const categories: CategoryData[] = [
    {
        name: "discovery",
        subCategories: ["Weekly Recommended", "New Releases", "Never Get Bored"]
    },
    {
        name: "io",
        subCategories: ["Instant Play Games"]
    },
    {
        name: "genre",
        subCategories: ["Action", "Adventure", "Anime", "Board", "Card", "Casual", "Educational", "Music", "Puzzle", "Racing", "Role-Playing", "Simulation", "Sports", "Strategy"]
    }
];

interface Props {
    className?: string;
}

export const SideNav: FC<Props> = ({ className }) => {
    return (
        <SideNavContainer className={className}>
            <CategoryList>
                {categories.map(category => (
                    <li key={category.name}>
                        {category.name}
                        <SubCategoryList>
                            {category.subCategories.map(subCategory => (
                                <li key={subCategory}>{subCategory}</li>
                            ))}
                        </SubCategoryList>
                    </li>
                ))}
            </CategoryList>
        </SideNavContainer>
    );
};