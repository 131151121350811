import React, { useState } from "react";
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import styled from "styled-components";
import {
    ListEditor,
    ListEditorProps,
    ListEditorItemRendererProps,
    ListEditorAddControlRendererProps
} from "src/shared/components";
import { GoogleAdditionalFile } from "src/shared/dtos";
import { setFromInput } from "src/shared/helpers";

const ItemContainer = styled.a`
    position: relative;
    display: block;
    width: 100%;
    padding-right: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const itemRenderer = (props: ListEditorItemRendererProps<GoogleAdditionalFile>) => {
    const key = `${props.item.obbType}_${props.item.versionCode}_${props.item.fileDownloadSize}_`
        + `${props.item.fileDownloadUrl}`;

    return (
        <ItemContainer
            key={key}
            href={props.item.fileDownloadUrl}
            target="_blank"
            title={props.item.fileDownloadUrl}>

            <b>{props.item.obbType} v{props.item.versionCode} [{props.item.fileDownloadSize}b]</b>:{" "}
            {props.item.fileDownloadUrl}
            {props.removeButton(props)}
        </ItemContainer>
    );
};

const LeftInput = styled(Input)`
    max-width: 12rem;
`;

const addControlRenderer = (props: ListEditorAddControlRendererProps<Omit<GoogleAdditionalFile, "platform">>) => {
    const [obbType, setObbType] = useState("");
    const [versionCode, setVersionCode] = useState<number | string>("");
    const [size, setSize] = useState<number | string>("");
    const [url, setUrl] = useState("");
    const add = () => {
        props.add({
            obbType,
            versionCode: +versionCode,
            fileDownloadSize: +size,
            fileDownloadUrl: url
        });
        setObbType("");
        setVersionCode("");
        setSize("");
        setUrl("");
    };

    return (
        <div>
            <InputGroup className="mt-2">
                <LeftInput
                    type="text"
                    name="obbType"
                    placeholder="OBB Type"
                    value={obbType}
                    onChange={setFromInput(setObbType)}
                    disabled={props.disabled} />
                <Input
                    type="number"
                    name="versionCode"
                    placeholder="Version Code"
                    value={versionCode}
                    onChange={setFromInput(setVersionCode)}
                    disabled={props.disabled} />
            </InputGroup>
            <InputGroup className="mt-2">
                <LeftInput
                    type="number"
                    placeholder="Size"
                    id={props.mainInputId}
                    value={size}
                    onChange={setFromInput(setSize)}
                    disabled={props.disabled} />
                <Input
                    type="url"
                    placeholder="URL"
                    value={url}
                    onChange={setFromInput(setUrl)}
                    disabled={props.disabled} />
                <InputGroupAddon addonType="append">
                    <Button
                        type="button"
                        color="primary"
                        disabled={((obbType === "") ||
                            (versionCode === "") ||
                            (size === "") ||
                            (url === "") ||
                            (url.includes(" "))) ??
                            props.disabled}
                        onClick={add}>
                        Add
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        </div>
    );
};

export type AdditionalFilesEditorProps =
    Omit<ListEditorProps<GoogleAdditionalFile>, "itemRenderer" | "addControlRenderer">;

export const AdditionalFilesEditor = (props: AdditionalFilesEditorProps) => {
    return <ListEditor itemRenderer={itemRenderer} addControlRenderer={addControlRenderer} {...props} />;
};