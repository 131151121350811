import React, { ChangeEvent, FC, useState, useCallback, useEffect } from "react";
import { Button } from "reactstrap";
import { TextWidgetUnit } from "src/shared/dtos";
import styled, { css } from "styled-components";

const WidgetTextBody = styled.textarea<{ bgColor: string }>`
        ${({ bgColor }) => css`
            background-image: -webkit-linear-gradient(left, ${bgColor} 10px, transparent 10px), -webkit-linear-gradient(right, ${bgColor} 10px, transparent 10px), -webkit-linear-gradient(${bgColor} 30px, #ccc 30px, #ccc 31px, ${bgColor} 31px);
            background-image: -moz-linear-gradient(left, ${bgColor} 10px, transparent 10px), -moz-linear-gradient(right, ${bgColor} 10px, transparent 10px), -moz-linear-gradient(${bgColor} 30px, #ccc 30px, #ccc 31px, ${bgColor} 31px);
            background-image: -ms-linear-gradient(left, ${bgColor} 10px, transparent 10px), -ms-linear-gradient(right, ${bgColor} 10px, transparent 10px), -ms-linear-gradient(${bgColor} 30px, #ccc 30px, #ccc 31px, ${bgColor} 31px);
            background-image: -o-linear-gradient(left, ${bgColor} 10px, transparent 10px), -o-linear-gradient(right, ${bgColor} 10px, transparent 10px), -o-linear-gradient(${bgColor} 30px, #ccc 30px, #ccc 31px, ${bgColor} 31px);
            background-image: linear-gradient(left, ${bgColor} 10px, transparent 10px), linear-gradient(right, ${bgColor} 10px, transparent 10px), linear-gradient(${bgColor} 30px, #ccc 30px, #ccc 31px, ${bgColor} 31px);
        `}
        background-size: 100% 100%, 100% 100%, 100% 31px;
        background-attachment: local;
        border: 1px solid #ccc;
        border-radius: 8px;
        line-height: 31px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
        font-size: 1.1rem;
        padding: 8px;
        resize: none;
        flex: 1;
        outline: none;
        color: #121828;
        &::-webkit-scrollbar {
            display: none;
        };
`;

const Main = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    padding: 8px;
`;

const SaveBtn = styled(Button).attrs({ color: "primary" })`
    position: absolute;
    bottom: 16px;
    right: 16px;
`;

export interface TextWidgetProps {
    textWidget: TextWidgetUnit;
    save: (val: TextWidgetUnit) => void;
    editContent: boolean;
}

export const TextWidget: FC<TextWidgetProps> = ({ textWidget, save, editContent }) => {
    const [text, setText] = useState("");

    useEffect(() => {
        setText(textWidget.text ?? "");
    }, [textWidget]);

    const changeText = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        setText(e.target?.value);
    }, []);

    const saveWidget = useCallback(() => {
        save({ ...textWidget, text });
    }, [save, textWidget, text]);

    const bgColor = editContent ? "#f8f9fa" : "#fff";

    return <Main>
        <WidgetTextBody bgColor={bgColor} disabled={!editContent} value={text} onChange={changeText} />
        {editContent && <SaveBtn onClick={saveWidget}>Save</SaveBtn>}
    </Main>;
};