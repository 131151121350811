import React, { FC, useMemo, useState, Dispatch, useEffect, useCallback } from "react";
import {
    OrderDirection,
    RequestStatisticsListDataType,
    RequestStatisticsSummary,
} from "src/shared/dtos";
import { IUser } from "src/shared/client";
import { Col } from "reactstrap";
import { useSubscription } from "src/shared/helpers";
import { defer } from "rxjs";
import api, { GetRequestStatisticsRequest } from "./api";
import { RouteLink } from "src/shared/components";
import { routes } from "src/shared/routes";
import { ListWidgetTable, ListWidgetTableColumn } from "./ListWidgetTable";

type Props = {
    setLoading: Dispatch<boolean>;
    user: IUser | null;
    orderBy: RequestStatisticsListDataType
};

const formatDate = (value: string) => {
    if (!value) {
        return "[not defined]";
    }

    return new Date(value).toLocaleDateString();
};

export const RequestStatisticsTable: FC<Props> = (props) => {
    const [requestStatistics, setRequestStatistics] = useState<RequestStatisticsSummary[]>([]);
    const { setLoading, user, orderBy } = props;

    const getRequestStatistics = useSubscription((req: GetRequestStatisticsRequest) => defer(
        () => api.getRequestStatistics(req)).subscribe(val => {
            setLoading(false);
            setRequestStatistics(val);
        }),
        []);

    useEffect(() => {
        setLoading(true);
        getRequestStatistics({
            offset: 0,
            limit: 10,
            orderBy,
            orderDirection: OrderDirection.Desc,
            isWidget: true
        });
    }, [orderBy]);

    const requestStatisticsDate = useMemo(
        () => formatDate(requestStatistics.length > 0 ? requestStatistics[0].date : ""),
        [requestStatistics]);

    const defaultMovingAvgDays = 7; // Week.

    const columns = useMemo<ListWidgetTableColumn<RequestStatisticsSummary>[]>(() => [
        {
            label: "Path",
            renderer: val => !val.path ? undefined : (
                <RouteLink
                    user={user}
                    to={{
                        route: routes.viewRequestStatistic, args: {
                            path: encodeURIComponent(val.path),
                            movingAvgDays: defaultMovingAvgDays,
                            endDate: new Date().toISOString().slice(0, 10)
                        }
                    }}
                    className="d-inline"
                >
                    {val.path}
                </RouteLink>
            ),
            style: { minWidth: 200 }
        },
        {
            label: "Count",
            isSortedBy: props.orderBy === RequestStatisticsListDataType.Count,
            renderer: val => val.count
        },
        {
            label: "Total (ms)",
            isSortedBy: props.orderBy === RequestStatisticsListDataType.TotalMilliseconds,
            renderer: val => val.totalMilliseconds.toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 3 })
        },
        {
            label: "Avg (ms)",
            isSortedBy: props.orderBy === RequestStatisticsListDataType.AverageMilliseconds,
            renderer: val => val.averageMilliseconds.toLocaleString(undefined, { maximumFractionDigits: 3, minimumFractionDigits: 3 })
        }
    ], [user]);

    const idSelector = useCallback((v: RequestStatisticsSummary) => v.id, []);

    return (<React.Fragment>
        <Col className="text-center" lg={12}>
            <b>Statistics for date:</b> {requestStatisticsDate}
        </Col>
        {(requestStatistics === undefined || requestStatistics.length === 0) ?
            <Col className="text-center" lg={12}>
                <h3>[No data]</h3>
            </Col> :
            <React.Fragment>
                <ListWidgetTable data={requestStatistics} columns={columns} rowKeySelector={idSelector} />
            </React.Fragment>}
    </React.Fragment>);
};