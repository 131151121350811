import React from "react";
import { Col, Label, Row } from "reactstrap";
import styled from "styled-components";

const StyledRow = styled(Row)`
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const FieldLabel = styled(Label)`
    white-space: nowrap;
    text-align: end;
    padding-right: 0;
`;

const FieldContent = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
`;

type Props = {
    label?: string;
};

export const HeaderField = (props: React.PropsWithChildren<Props>) => {
    return (
        <Col xl={true} className="mb-2 mb-xl-0">
            <StyledRow>
                {props.label &&
                    <FieldLabel xs={6}>
                        {props.label + ":"}
                    </FieldLabel>}
                <FieldContent xs={props.label === undefined ? 12 : 6}>
                    {props.children}
                </FieldContent>
            </StyledRow>
        </Col>
    );
};