import React from "react";
import { Spinner } from "reactstrap";
import styled, { css } from "styled-components";
import { DelayAppearance } from "./mixin";

const Container = styled.div<{ transparent?: boolean }>`
    text-align: center;
    margin: ${props => props.transparent ? 0 : "6rem 0 0 0"};
    padding: 0;
    color: #343a40;
    ${DelayAppearance};
    ${props => props.transparent && css`
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        opacity: 0.4 !important;
        -webkit-opacity: 0.4;
        z-index: 100;
        background-color: white;
    `}
`;

const StyledSpinner = styled(Spinner).attrs({ animation: "border", role: "status" })`
    width: 6rem;
    height: 6rem;
    font-size: 4rem;
`;

export const Loader = (props: { className?: string, transparent?: boolean }) =>
    <Container className={props.className} transparent>
        <StyledSpinner />
    </Container>;
