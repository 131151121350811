import { useState, useEffect } from "react";

/**
 * The debounce function implementation.
 * Source taken from: https://usehooks.com/useDebounce/
 * @param value The value that needs to be debounced.
 * @param delay The delay in milliseconds.
 */
export function useDebounce<TValue>(value: TValue, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}