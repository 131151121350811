import React, { useState, useMemo } from "react";
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import { setFromInput } from "../helpers/setFromInput";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface Image {
    url: string;
    alt?: string;
}

export interface ImageSelectorProps {
    /**
     * The add input element id prop.
     */
    setInputId?: string;

    /**
     * The root element class name.
     */
    className?: string;

    /**
     * The current value.
     */
    value: unknown;

    /**
     * The set value method. Must return value that will be displayed in the set input.
     */
    set?: (url: string) => string;

    /**
     * The delete image URL method.
     */
    delete?: (value: unknown) => void;

    /**
     * The render method that must return image URL.
     */
    render: (value: unknown) => Image | null;

    /**
     * The image dimensions.
     */
    imageDimensions?: { width?: string, height?: string };

    /**
     * Is control disabled.
     */
    disabled?: boolean;

    highlight?: boolean;
    /**
     * Is delete button hidden.
     */
    deleteButtonHidden?: boolean;

    invalid?: boolean;
}

const Container = styled.div<{ highlight?: boolean }>`
    background: ${props => props.highlight ? "lightyellow" : ""};
    text-align: center;
    padding-top: 15px;
`;

const StyledImageContainer = styled.a`
    max-width: 100%;
    max-height: 100%;
`;

const StyledImg = styled.img<{ imgWidth?: string, imgHeight?: string }>`
    width: ${props => props.imgWidth ?? "initial"};
    height: ${props => props.imgHeight ?? "initial"};
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
`;

const StyledImageBlock = styled.div<{ width?: string, height?: string }>`
    height: ${props => props.height ?? "initial"};
    display: flex;
    align-items: center;
    justify-content: center;
`;


const StyledNoImageBlock = styled.div<{ width?: string, height?: string }>`
    width: ${props => props.width ? "initial" : "10rem"};
    height: ${props => props.height ?? "initial"};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledDeleteButton = styled.a<{ hidden?: boolean, highlight?: boolean }>`
    color: ${props => props.highlight ? "gray" : "#fc1d00"};
    display: ${props => props.hidden ? "none" : "block"};
    position: absolute;
    top: 0;
    right: 2%;
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    font-size: 2rem;
    text-align: center;
    opacity: 0.5;
    z-index: 2;
    margin: -1rem;

    &:hover, &:focus {
        color:${props => props.highlight ? "lightgray" : "red"};
        opacity: 0.5;
    }
`;

const RemoveIcon = styled(FontAwesomeIcon).attrs({ icon: "times" })`
    vertical-align: top;
`;

export const ImageSelector = (props: ImageSelectorProps) => {
    const [url, setUrl] = useState("");

    const image = useMemo(() => props.render(props.value), [props.value, props.render]);

    const onSetClicked = () => {
        const result = props.set?.call(null, url) as string;
        setUrl(result);
    };

    const onDeleteImageClicked = (e: React.FormEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const currentImage = props.value;
        props.delete?.call(currentImage, null);
    };

    return (
        <Container className={props.className} highlight={props.highlight}>
            {image !== null &&
                <StyledDeleteButton href="#" onClick={onDeleteImageClicked} hidden={props.deleteButtonHidden} highlight={props.highlight}>
                    <RemoveIcon />
                </StyledDeleteButton>}
            {image === null &&
                <StyledNoImageBlock width={props.imageDimensions?.width} height={props.imageDimensions?.height}>
                    [no image]
                </StyledNoImageBlock>}
            {image !== null &&
                <StyledImageBlock height={props.imageDimensions?.height}>
                    <StyledImageContainer href={image.url} target="_blank" >
                        <StyledImg
                            src={image.url}
                            alt={image.alt ?? image.url}
                            imgWidth={props.imageDimensions?.width}
                            imgHeight={props.imageDimensions?.height}
                            height={props.imageDimensions?.height} />
                    </StyledImageContainer>
                </StyledImageBlock>}
            <InputGroup className="mt-3">
                <Input
                    type="text"
                    id={props.setInputId}
                    value={url}
                    onChange={setFromInput(setUrl)}
                    disabled={props.disabled} />
                <InputGroupAddon addonType="append">
                    <Button
                        type="button"
                        color="primary"
                        onClick={onSetClicked}
                        disabled={props.disabled || url === "" || url.includes(" ")}>
                        Set
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        </Container>
    );
};