import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";
import { BooleanIndicator } from "./InfiniteTable";
import { notifySuccess } from "./Notification";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    toggleApps: (file: File, isDisabled: boolean) => Promise<void>;
    onSUccess?: () => void;
}

export const BulkApplicationToggler = ({ toggleApps, onSUccess, ...props }: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const enabledFileInputRef = useRef<HTMLInputElement>(null);
    const disabledFileInputRef = useRef<HTMLInputElement>(null);

    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    const getDisabledFile = useCallback(() => {
        setDropdownOpen(false);
        disabledFileInputRef?.current?.click();
    }, [disabledFileInputRef]);

    const getEnabledFile = useCallback(() => {
        setDropdownOpen(false);
        enabledFileInputRef?.current?.click();
    }, [enabledFileInputRef]);

    const handleFileInputChange = useCallback((isDisabled: boolean) => (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        const file = e.target.files?.[0];
        if (file === undefined) {
            return;
        }
        toggleApps(file, isDisabled)
            .then(() => {
                notifySuccess(`Apps have been ${isDisabled ? "disabled" : "enabled"} successfully`);
                if (!isDisabled && enabledFileInputRef.current) {
                    enabledFileInputRef.current.value = "";
                }
                if (isDisabled && disabledFileInputRef.current) {
                    disabledFileInputRef.current.value = "";
                }
                onSUccess?.call(null);
            });
    }, [enabledFileInputRef, disabledFileInputRef]);

    return (
        <div {...props}>
            <input
                type="file"
                accept=".txt"
                ref={enabledFileInputRef}
                onChange={handleFileInputChange(false)}
                style={{ display: "none" }}
            />
            <input
                type="file"
                accept=".txt"
                ref={disabledFileInputRef}
                onChange={handleFileInputChange(true)}
                style={{ display: "none" }}
            />
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle>Toggle Apps</DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={getEnabledFile}>
                        <span className="mr-3">Enable Apps</span>
                        <BooleanIndicator value={true} />
                    </DropdownItem>
                    <DropdownItem onClick={getDisabledFile}>
                        <span className="mr-3">Disable Apps</span>
                        <BooleanIndicator value={false} />
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};
