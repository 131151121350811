import React, { useMemo } from "react";
import { CrawlingStatus } from "../dtos";

const statusColors: Record<CrawlingStatus, string> = {
    [CrawlingStatus.None]: "#41464b",
    [CrawlingStatus.Crawling]: "#0791f5",
    [CrawlingStatus.Crawled]: "#28a745",
    [CrawlingStatus.Failed]: "#f25535",
    [CrawlingStatus.Updating]: "#0791f5",
    [CrawlingStatus.Updated]: "#28a745",
    [CrawlingStatus.UpdateFailed]: "#f25535",
    [CrawlingStatus.TokenIsAbsent]: "#f25535"
};

export interface CrawlingStatusTextProps {
    status: CrawlingStatus;
}

export const CrawlingStatusText = (props: CrawlingStatusTextProps) => {
    const color = useMemo(() => statusColors[props.status], [props.status]);
    return (
        <span style={{ color, fontWeight: "bold" }}>{props.status}</span>
    );
};
