import { useState, useEffect } from "react";
import { BootstrapBreakpoint, getBootstrapBreakpoint } from "./getBootstrapBreakpoint";
import { useWindowSize } from "./useWindowSize";

/**
 * The bootstrap breakpoint state-based hook.
 */
export function useBootstrapBreakpoint(): BootstrapBreakpoint | undefined {
    const [breakpoint, setBreakpoint] = useState<BootstrapBreakpoint | undefined>(getBootstrapBreakpoint());
    const windowSize = useWindowSize();

    useEffect(() => setBreakpoint(getBootstrapBreakpoint()), [windowSize]);

    return breakpoint;
}
