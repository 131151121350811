import React, { useMemo } from "react";
import { ChangesIndicator } from "./ChangesIndicator";
import { WarningIndicator } from "./WarningIndicator";

export type FormLabelProps = {
    isChanged?: boolean;
    originalValue?: unknown;
    warnings?: string[];
    showWarnings?: boolean;
};

export const FormLabel = (props: FormLabelProps & React.PropsWithChildren<{ className?: string }>) => {
    const showWarnings = useMemo(
        () => props.warnings && props.warnings.length > 0 && props.showWarnings,
        [props.warnings, props.showWarnings]);
    return (
        <span className={props.className}>
            {props.children}
            {props.isChanged && <ChangesIndicator originalValue={props.originalValue} />}
            {showWarnings && <WarningIndicator warnings={props.warnings!} style={{ marginLeft: "0.6rem" }} />}
        </span>
    );
};
