import React, { useCallback, CSSProperties } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { FormLabel } from "src/shared/components";
import { ApkPlatform } from "src/shared/dtos";
import { ApkPlatforms, getPlatformName } from "src/shared/helpers";

interface Props {
    currentPlatform: ApkPlatform;
    changedPlatforms: ApkPlatform[];
    setPlatform: (v: ApkPlatform) => void;
    style?: CSSProperties;
}

export const PlatformNavSelector = (props: Props) => {
    const makeSelectPlatform = useCallback(name => () => props.setPlatform(name), [props.setPlatform]);
    return (
        <Nav tabs style={props.style}>
            {ApkPlatforms.map(platform =>
                <NavItem key={platform} title={getPlatformName(platform)}>
                    <NavLink
                        href="#"
                        active={props.currentPlatform === platform}
                        onClick={makeSelectPlatform(platform)}>
                        <FormLabel isChanged={props.changedPlatforms.indexOf(platform) !== -1}>
                            {getPlatformName(platform)}
                        </FormLabel>
                    </NavLink>
                </NavItem>)}
        </Nav>
    );
};