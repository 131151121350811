import { client } from "src/shared/client";
import {
    GoogleApplication,
    GetGamestoreApplication,
    GetGoogleApplication,
    GetGoogleApplicationSummaries,
    ManageGoogleApplication,
    GetCategories,
    ManageUserGridSettings,
} from "src/shared/dtos";
import { ExcludeMethods } from "src/shared/helpers";

export type ListRequest = ExcludeMethods<GetGoogleApplicationSummaries>;

export const GoogleApplicationApi = {
    getCategories: () => client.get(new GetCategories()),
    list: async (request: ListRequest) => client.get(new GetGoogleApplicationSummaries({ ...request })),
    get: (id: string) => client.get(new GetGoogleApplication({ id })),
    create: (application: GoogleApplication) => client.post(new ManageGoogleApplication({ application })),
    update: (application: GoogleApplication) => client.put(new ManageGoogleApplication({ application })),
    getGamestoreApp: async (id: string) => {
        const app = await client.get(new GetGamestoreApplication({ id }));
        return app.id === undefined ? undefined : app;
    },
    saveGridSettings: (breakpoint: string, visibleColumns: string[]) => client.post(new ManageUserGridSettings({
        gridName: "GoogleApplications",
        breakpoint,
        visibleColumns
    }))
};

export default GoogleApplicationApi;