import { client } from "src/shared/client";
import {
    GamestoreIoApplication,
    GetCategories,
    GetGamestoreIoApplication,
    GetGamestoreIoApplications,
    ManageGamestoreIoApplication,
    GetIoApplicationsWithoutGamestoreApplication,
    GetIoCrawlingProviders,
    ToggleBulkIoApplications,
    GetTags,
    TagsOrderBy,
    OrderDirection,
    ManageUserGridSettings,
} from "src/shared/dtos";
import { ExcludeMethods, serializeDate } from "src/shared/helpers";

export type ListRequest = Omit<ExcludeMethods<GetGamestoreIoApplications>,
    "modifiedAtStartDateSearch" | "modifiedAtEndDateSearch" | "srcUpdatedAtStartDateSearch" | "srcUpdatedAtEndDateSearch"> & {
        modifiedAtStartDateSearch: Date | undefined;
        modifiedAtEndDateSearch: Date | undefined;
        srcUpdatedAtStartDateSearch: Date | undefined;
        srcUpdatedAtEndDateSearch: Date | undefined;
    };

export const GamestoreIoApplicationApi = {
    getCategories: () => client.get(new GetCategories()),
    getTags: () => client.get(new GetTags({ orderBy: TagsOrderBy.Id, orderDirection: OrderDirection.Asc })),
    getIoAppsWithoutGamestoreApplication: () => client.get(new GetIoApplicationsWithoutGamestoreApplication()),
    list: async (request: ListRequest) => {
        const {
            modifiedAtStartDateSearch,
            modifiedAtEndDateSearch,
            srcUpdatedAtStartDateSearch,
            srcUpdatedAtEndDateSearch,
            ...restRequest
        } = request;
        return client.get(new GetGamestoreIoApplications({
            modifiedAtStartDateSearch: serializeDate(modifiedAtStartDateSearch),
            modifiedAtEndDateSearch: serializeDate(modifiedAtEndDateSearch),
            srcUpdatedAtStartDateSearch: serializeDate(srcUpdatedAtStartDateSearch),
            srcUpdatedAtEndDateSearch: serializeDate(srcUpdatedAtEndDateSearch),
            ...restRequest
        }));
    },
    get: async (id: string) => {
        const app = await client.get(new GetGamestoreIoApplication({ id }));
        return app.id === undefined ? undefined : app;
    },
    create: (application: GamestoreIoApplication) => client.post(new ManageGamestoreIoApplication({ application })),
    update: (application: GamestoreIoApplication) => client.put(new ManageGamestoreIoApplication({ application })),
    getProviders: async () => client.get(new GetIoCrawlingProviders()),
    toggleApps: (file: File, isDisabled: boolean) => {
        const formData = new FormData();
        formData.append("appIds", file, "appIds");
        return client.postBody(new ToggleBulkIoApplications({ isDisabled }), formData);
    },
    saveGridSettings: (breakpoint: string, visibleColumns: string[]) => client.post(new ManageUserGridSettings({
        gridName: "GamestoreIoApplications",
        breakpoint,
        visibleColumns
    }))
};

export default GamestoreIoApplicationApi;