import React, { useMemo } from "react";
import { NavLinkProps } from "reactstrap";
import styled from "styled-components";
import { NavLink, NavLinkProps as OriginalNavLinkProps } from "react-router-dom";
import { IRoute } from "src/shared/routes";
import { IUser } from "../client";

export interface RouteLinkProps<TRouteArgs> extends NavLinkProps {
    /**
     * The CSS class for the DOM node.
     */
    className?: string;

    /**
     * The user for which this link will be displayed.
     */
    user: IUser | null;

    /**
     * The route this link will lead to.
     */
    to: IRoute<TRouteArgs> | { route: IRoute<TRouteArgs>, args: TRouteArgs };

    /**
     * If set to true, nav bar styles will be applied, and link will be hidden if user has no access.
     * If falsy, link will be displayed as a simple text if user has no access.
     */
    nav?: boolean;

    /**
     * Acts just like 'nav' property but uses different styles
     */
    button?: boolean;
}

const StyledNavLink = styled(NavLink)<{ hidden: boolean }>`
    display: ${props => props.hidden ? "none" : "inline-block"};
`;

export function RouteLink<TRouteArgs>(props: RouteLinkProps<TRouteArgs>): JSX.Element {
    const toRoute = (props.to as IRoute<TRouteArgs>);
    const toUnion = (props.to as { route: IRoute<TRouteArgs>, args: TRouteArgs });
    const [route, args] = toRoute.pattern !== undefined
        ? [toRoute, (undefined as unknown as TRouteArgs)]
        : [toUnion.route, toUnion.args];

    const url = useMemo(() => route.url(args), [route, args]);
    const canAccess = useMemo(
        () => !route.loginRequired(props.user) && route.hasAccess(props.user),
        [route, props.user]);

    const additionalProps = useMemo(
        () => {
            const { className, nav, button, to, hidden, ...restProps } = props;

            const classNames = className ? [className] : [];
            if (nav === true) {
                classNames.push("nav-link");
            }
            if (button === true) {
                classNames.push("btn btn-primary");
            }

            const resultProps: Partial<OriginalNavLinkProps> = {
                className: classNames.join(" "),
                activeClassName: props.nav === true ? "active" : undefined,
                ...restProps
            };
            return resultProps;
        }, [props]);

    const showText = useMemo(() => props.nav !== true && props.button !== true, [props.nav, props.button]);

    return (
        <React.Fragment>
            <StyledNavLink to={url} hidden={!canAccess} {...additionalProps} />
            {!canAccess && showText &&
                <span className={additionalProps.className}>{props.children}</span>}
        </React.Fragment>
    );
}