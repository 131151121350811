import { AdUnitTrigger } from "src/shared/dtos";
import { deepEqual } from "src/shared/helpers";

export type AdUnitTriggerState = {
    current?: AdUnitTrigger,
    initial?: AdUnitTrigger,
    changed: boolean
};

export type AdUnitTriggerAction =
    { kind: "set", value: AdUnitTrigger, initialValue?: AdUnitTrigger } |
    { kind: "update", value: Partial<AdUnitTrigger> };

export const adUnitTriggerReducer = (state: AdUnitTriggerState, action: AdUnitTriggerAction) => {
    switch (action.kind) {
        case "set": {
            state = {
                current: action.value,
                initial: action.initialValue,
                changed: false
            };
            break;
        }
        case "update": {
            Object.assign(state.current, action.value);
            state.changed = !deepEqual(state.current, state.initial);
            break;
        }
    }

    return Object.assign({}, state);
};

export const adUnitTriggerReducerDefault: AdUnitTriggerState = {
    current: undefined,
    initial: undefined as unknown as AdUnitTrigger,
    changed: false
};