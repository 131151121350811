import { useState, useEffect, useCallback } from "react";

/**
 * The window size state-based hook.
 */
export function useWindowSize() {
    const getSize = useCallback(() => ({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
        outerWidth: window.outerWidth,
        outerHeight: window.outerHeight,
    }), []);
    const [windowSize, setWindowSize] = useState(getSize());

    useEffect(() => {
        const onResize = () => setWindowSize(getSize());
        addEventListener("resize", onResize);
        return () => removeEventListener("resize", onResize);
    }, []);

    return windowSize;
}
