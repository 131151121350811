import { validateKeyBindings, validateSource, validateUrlField, ValidationSchema } from "src/shared/helpers";
import { CategoryFormData } from "./categoryReducer";

export const makeValidationSchema = (keyBindingSchemas: object[]): ValidationSchema<CategoryFormData> => ({
    backgroundImageUrl: url => validateUrlField({ url }),
    defaultKeyBindings: v => validateKeyBindings(v, keyBindingSchemas)
});

export const imagesValidationSchema: ValidationSchema<CategoryFormData> = {
    backgroundImageUrl: url => validateSource({ url, type: "image" })
};