import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const Container = styled.span`
    cursor: pointer;
    font-size: 1rem;
    text-align: center;
    vertical-align: center;
    opacity: 0.5;
    padding-right: 10px;

    &:hover, &:focus {
        opacity: 1;
    }
`;

export const ActionButton = (props: { icon: IconProp } & React.HTMLAttributes<HTMLSpanElement>) => {
    const { icon, ...restProps } = props;
    return (
        <Container {...restProps}>
            <FontAwesomeIcon icon={props.icon} />
        </Container>
    );
};