import React, { useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col } from "reactstrap";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    DocumentTitle,
    RouteLink,
    RouteUserProps
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { routes } from "src/shared/routes";
import { DetailsComponent } from "./DetailsComponent";

export const DetailsView = withRouter(({ match, user }: RouteComponentProps<{ version: string, endDate: string }> & RouteUserProps) => {
    const [version, versionName, endDate] = useMemo(() => {
        const parsedEndDate = new Date(match.params.endDate);
        const text = decodeURIComponent(match.params.version);
        const name = text === "0.0.0" ? "All versions" : text;
        return [text, name, parsedEndDate];
    }, [match.params.version, match.params.endDate]);

    return (
        <VerticalBox>
            <DocumentTitle title={`Active Users Statistics - for app version '${version}'`} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.activeInstallsStatistics}>
                                Active Users Statistics
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            Version: {versionName}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    <React.Fragment>
                        <Row>
                            <Col sm={{ size: 8, offset: 2 }}>
                                <DetailsComponent
                                    productVersion={version}
                                    endDate={endDate}
                                    user={user}
                                />
                            </Col>
                        </Row>
                    </React.Fragment>
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});
