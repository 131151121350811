import { Button, ButtonProps, Spinner } from "reactstrap";
import React, { FC } from "react";

export interface LoadingButtonProps extends Omit<ButtonProps, "disabled"> {
    loading: boolean;
    loadingText?: string;
    position?: "start" | "end";
}

export const LoadingButton: FC<LoadingButtonProps> = ({ loading, text, loadingText, children, position = "start", ...props }) => {
    return <Button disabled={loading} {...props}>
        {loading
            ? position === "start"
                ? (
                    <>
                        <Spinner size="sm" className="mr-2" />
                        <span>{loadingText || "Loading..."}</span>
                    </>
                )
                : (
                    <>
                        <span>{loadingText || "Loading..."}</span>
                        <Spinner size="sm" className="ml-2" />
                    </>
                )
            : <span>{children}</span>}
    </Button>;
};
