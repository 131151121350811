/* Options:
Date: 2024-01-10 20:35:46
Version: 6.90
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: http://localhost:5000

//GlobalNamespace:
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion:
//AddDescriptionAsComments: True
//IncludeTypes:
//ExcludeTypes:
//DefaultImports:
*/

// Server Fingerprint: 2496f6e9-7a63-44f1-9dea-e80350ef5b01

export interface IReturn<T>
{
    getPath(): string;
    createResponse(): T;
}

export interface IReturnVoid
{
    getPath(): string;
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId?: string;
}

export interface IHasBearerToken
{
    bearerToken?: string;
}

export interface IGet
{
}

export interface IPost
{
}

export enum ApkPlatform
{
    X32 = 'X32',
    X64 = 'X64',
}

export enum CrawlingStatus
{
    None = 'None',
    Crawling = 'Crawling',
    Crawled = 'Crawled',
    Failed = 'Failed',
    TokenIsAbsent = 'TokenIsAbsent',
    Updating = 'Updating',
    Updated = 'Updated',
    UpdateFailed = 'UpdateFailed',
}

export class ImportIoApplicationCategory
{
    // @Required()
    // @StringLength(2, 60)
    public name: string;

    public description?: string;
    public backgroundImageUrl?: string;

    public constructor(init?: Partial<ImportIoApplicationCategory>) { (Object as any).assign(this, init); }
}

export class ImportIoApplicationTag
{
    public id?: string;
    public name?: string;

    public constructor(init?: Partial<ImportIoApplicationTag>) { (Object as any).assign(this, init); }
}

export class IoApplicationLocalization
{
    public id: number;
    // @Required()
    public ioApplicationId: string;

    // @Required()
    public language: string;

    public title?: string;
    public iconUrl?: string;
    public thumbnailUrl?: string;
    public description?: string;
    // @Required()
    public applicationUrl: string;

    // @Required()
    public sourceUrl: string;

    // @Required()
    public screenshots: string[];

    // @Required()
    public videos: string[];

    public constructor(init?: Partial<IoApplicationLocalization>) { (Object as any).assign(this, init); }
}

export class ImportIoApplicationData
{
    // @Required()
    public id: string;

    // @Required()
    public category: ImportIoApplicationCategory;

    public rating: number;
    public developer?: string;
    public sourceProvider?: string;
    public tags?: ImportIoApplicationTag[];
    // @Required()
    public localizations: IoApplicationLocalization[];

    public constructor(init?: Partial<ImportIoApplicationData>) { (Object as any).assign(this, init); }
}

export class GoogleAdditionalFile
{
    public platform: ApkPlatform;
    // @Required()
    public fileDownloadUrl: string;

    // @Required()
    public obbType: string;

    // @Required()
    public fileDownloadSize: number;

    // @Required()
    public versionCode: number;

    public constructor(init?: Partial<GoogleAdditionalFile>) { (Object as any).assign(this, init); }
}

export class ImportApplicationSplitFileData
{
    public fileDownloadUrl?: string;
    public fileDownloadSize: number;
    public name?: string;

    public constructor(init?: Partial<ImportApplicationSplitFileData>) { (Object as any).assign(this, init); }
}

export class ImportApplicationData
{
    public appId?: string;
    public categoryId?: string;
    public rating: number;
    public versionCode: number;
    public versionName?: string;
    public installsCount: number;
    public reviewsCount: number;
    public updatedOn: string;
    public apkDownloadUrl?: string;
    public apkDownloadSize: number;
    public sdkVersion: number;
    public additionalFiles?: GoogleAdditionalFile[];
    public language?: string;
    public title?: string;
    public iconUrl?: string;
    public publisher?: string;
    public publisherId?: string;
    public shortDescription?: string;
    public description?: string;
    public size: number;
    public screenshots?: string[];
    public videos?: string[];
    public splitFiles?: ImportApplicationSplitFileData[];

    public constructor(init?: Partial<ImportApplicationData>) { (Object as any).assign(this, init); }
}

export enum ImportStrategy
{
    AddAndUpdate = 'AddAndUpdate',
    AddOnly = 'AddOnly',
    UpdateOnly = 'UpdateOnly',
}

export class ImportCategoryData
{
    public categoryId?: string;
    public parentId?: string;
    public name?: string;
    public description?: string;
    public backgroundImage?: string;
    public order: number;
    public isActive: boolean;

    public constructor(init?: Partial<ImportCategoryData>) { (Object as any).assign(this, init); }
}

export class CrawledIoApplication
{
    public id?: string;
    public provider?: string;

    public constructor(init?: Partial<CrawledIoApplication>) { (Object as any).assign(this, init); }
}

export interface ILimitedRequest
{
    limit: number;
    offset?: number;
}

export enum ApplicationSortOrder
{
    Rating = 'Rating',
    Random = 'Random',
}

export class GameCenterApplicationsLimitedInfo30 implements ILimitedRequest
{
    public limit: number;
    public offset?: number;

    public constructor(init?: Partial<GameCenterApplicationsLimitedInfo30>) { (Object as any).assign(this, init); }
}

export enum ContinuePlayingKind
{
    MostPlayed = 'MostPlayed',
    RecentlyPlayed = 'RecentlyPlayed',
}

export interface IGetSearchHistoryRequest
{
    hwid?: string;
    limit: number;
}

export interface IClearSearchHistoryRequest
{
    hwid?: string;
}

export class KeyBindingSwipe
{
    public key?: string;
    public description?: string;
    public x1: number;
    public x2: number;
    public y1: number;
    public y2: number;

    public constructor(init?: Partial<KeyBindingSwipe>) { (Object as any).assign(this, init); }
}

export class KeyBindingTap
{
    public key?: string;
    public description?: string;
    public x: number;
    public y: number;

    public constructor(init?: Partial<KeyBindingTap>) { (Object as any).assign(this, init); }
}

export class KeyBindingsJoy
{
    public keyUp?: string;
    public keyDown?: string;
    public keyLeft?: string;
    public keyRight?: string;
    public x: number;
    public y: number;
    public radius: number;
    public description?: string;

    public constructor(init?: Partial<KeyBindingsJoy>) { (Object as any).assign(this, init); }
}

export class KeyBindingsTilt
{
    public keyDown?: string;
    public keyLeft?: string;
    public keyRight?: string;
    public keyUp?: string;
    public sensitivity: number;
    public x: number;
    public y: number;
    public description?: string;

    public constructor(init?: Partial<KeyBindingsTilt>) { (Object as any).assign(this, init); }
}

export class KeyBindingsMultiTouch
{
    public description?: string;
    public holdType?: string;
    public key?: string;
    public multiTouchType?: string;

    public constructor(init?: Partial<KeyBindingsMultiTouch>) { (Object as any).assign(this, init); }
}

export class KeyBindingCameraControls
{
    public key?: string;
    public description?: string;
    public x: number;
    public y: number;
    public sensitivityX: number;
    public sensitivityY: number;
    public areaWidth: number;
    public areaHeight: number;

    public constructor(init?: Partial<KeyBindingCameraControls>) { (Object as any).assign(this, init); }
}

export class EmulatorOption
{
    public keyBindingSwipes?: KeyBindingSwipe[];
    public keyBindingTaps?: KeyBindingTap[];
    public keyBindingsJoy?: KeyBindingsJoy[];
    public keyBindingsTilt?: KeyBindingsTilt[];
    public keyBindingsMultiTouch?: KeyBindingsMultiTouch[];
    public keyBindingCameraControls?: KeyBindingCameraControls[];
    public keyBindingsSizeH?: number;
    public keyBindingsSizeW?: number;
    public pinchToZoomEnabled?: boolean;

    public constructor(init?: Partial<EmulatorOption>) { (Object as any).assign(this, init); }
}

export enum ClientSystemPlatform
{
    x86 = 'x86',
    x64 = 'x64',
}

export enum ClientOperatingSystem
{
    Windows = 'Windows',
    Mac = 'Mac',
    Linux = 'Linux',
}

export enum GoogleCrawlingResultsOrderBy
{
    AppId = 'AppId',
    AddedAt = 'AddedAt',
    UpdatedAt = 'UpdatedAt',
}

export enum OrderDirection
{
    Asc = 'Asc',
    Desc = 'Desc',
}

export enum AdCampaignListDataType
{
    CampaignName = 'CampaignName',
    Disabled = 'Disabled',
}

export enum AdUnitListDataType
{
    CampaignName = 'CampaignName',
    CampaignDisabled = 'CampaignDisabled',
    Dimensions = 'Dimensions',
    Disabled = 'Disabled',
}

export class CategoryOrder
{
    // @Required()
    public id: string;

    public parentId?: string;
    // @Required()
    public order: number;

    public constructor(init?: Partial<CategoryOrder>) { (Object as any).assign(this, init); }
}

export enum GamestoreApplicationListDataType
{
    Id = 'Id',
    Title = 'Title',
    Publisher = 'Publisher',
    Rating = 'Rating',
    Version32 = 'Version32',
    Version64 = 'Version64',
    InstallsCount = 'InstallsCount',
    ReviewsCount = 'ReviewsCount',
    X32ApkUpdatedDate = 'X32ApkUpdatedDate',
    X64ApkUpdatedDate = 'X64ApkUpdatedDate',
    ModifiedDate = 'ModifiedDate',
}

export enum ScreenOrientationType
{
    Auto = 'Auto',
    Landscape = 'Landscape',
    Portrait = 'Portrait',
}

export enum GamestoreIoApplicationListDataType
{
    Id = 'Id',
    Title = 'Title',
    Developer = 'Developer',
    Rating = 'Rating',
    InstallsCount = 'InstallsCount',
    ModifiedDate = 'ModifiedDate',
    SrcUpdatedDate = 'SrcUpdatedDate',
    SourceProvider = 'SourceProvider',
}

export enum GoogleApplicationListDataType
{
    Id = 'Id',
    Title = 'Title',
    Publisher = 'Publisher',
    Rating = 'Rating',
    Version32 = 'Version32',
    Version64 = 'Version64',
    InstallsCount = 'InstallsCount',
}

export enum IoApplicationListDataType
{
    Id = 'Id',
    Title = 'Title',
    Developer = 'Developer',
    Rating = 'Rating',
}

export enum IoCrawlingResultsOrderBy
{
    AppId = 'AppId',
    AddedAt = 'AddedAt',
    UpdatedAt = 'UpdatedAt',
}

export enum IoCrawlingSourcesOrderBy
{
    AppId = 'AppId',
    Title = 'Title',
}

export enum ProductVersionInfoListDataType
{
    Id = 'Id',
    Version = 'Version',
    Enabled = 'Enabled',
    Mandatory = 'Mandatory',
}

export enum TagsOrderBy
{
    Id = 'Id',
    Name = 'Name',
    Order = 'Order',
}

export enum ActiveInstallsStatisticsListDataType
{
    ProductVersion = 'ProductVersion',
    Count = 'Count',
}

export enum ClickInfoStatisticsListDataType
{
    Count = 'Count',
    ProductVersion = 'ProductVersion',
    ClickTarget = 'ClickTarget',
    ClickType = 'ClickType',
    Country = 'Country',
    SourceUrl = 'SourceUrl',
    Platform = 'Platform',
    EmulatorType = 'EmulatorType',
}

export enum GamingMinutesStatisticsListDataType
{
    ApplicationId = 'ApplicationId',
    Title = 'Title',
    Count = 'Count',
    MovingAverageCount = 'MovingAverageCount',
    TotalMinutes = 'TotalMinutes',
    MovingAverageTotalMinutes = 'MovingAverageTotalMinutes',
    AverageMinutes = 'AverageMinutes',
    MovingAverageAverageMinutes = 'MovingAverageAverageMinutes',
    StandardDeviationMinutes = 'StandardDeviationMinutes',
    MovingAverageStandardDeviationMinutes = 'MovingAverageStandardDeviationMinutes',
    ProductVersion = 'ProductVersion',
}

export enum PlaystoreActivityListDataType
{
    Count = 'Count',
    ProductVersion = 'ProductVersion',
    ApkPackageName = 'ApkPackageName',
    Country = 'Country',
    SourceUrl = 'SourceUrl',
    Platform = 'Platform',
    Status = 'Status',
}

export enum RequestStatisticsListDataType
{
    Path = 'Path',
    Count = 'Count',
    MovingAverageCount = 'MovingAverageCount',
    TotalMilliseconds = 'TotalMilliseconds',
    MovingAverageTotalMilliseconds = 'MovingAverageTotalMilliseconds',
    AverageMilliseconds = 'AverageMilliseconds',
    MovingAverageAverageMilliseconds = 'MovingAverageAverageMilliseconds',
    StandardDeviationMilliseconds = 'StandardDeviationMilliseconds',
    MovingAverageStandardDeviationMilliseconds = 'MovingAverageStandardDeviationMilliseconds',
}

export class AdditionalFile
{
    public fileDownloadUrl?: string;
    public obbType?: string;
    public fileDownloadSize: number;
    public versionCode: number;

    public constructor(init?: Partial<AdditionalFile>) { (Object as any).assign(this, init); }
}

export class SplitFile
{
    public fileDownloadUrl?: string;
    public name?: string;

    public constructor(init?: Partial<SplitFile>) { (Object as any).assign(this, init); }
}

export class GamestoreApplicationData371
{
    public id?: string;
    public title?: string;
    public iconUrl?: string;
    public imageUrl?: string;
    public isDisabled: boolean;
    public siteName?: string;
    public screenOrientation: ScreenOrientationType;
    public screenWidth: number;
    public screenHeight: number;
    public emulatorOptions?: EmulatorOption;
    public needGoogleAccount: boolean;
    public useGooglePlay: boolean;
    public publisher?: string;
    public apkUrl?: string;
    public additionalFiles?: AdditionalFile[];
    public splitFiles?: SplitFile[];
    public versionCode: number;
    public apkDownloadSize: number;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GamestoreApplicationData371>) { (Object as any).assign(this, init); }
}

export class Category30
{
    public id?: string;
    public parentId?: string;
    public name?: string;
    public description?: string;
    public backgroundImageUrl?: string;
    public order: number;
    public isActive: boolean;

    public constructor(init?: Partial<Category30>) { (Object as any).assign(this, init); }
}

export class Category371 extends Category30
{
    public hasGoogleApps: boolean;
    public hasIoApps: boolean;

    public constructor(init?: Partial<Category371>) { super(init); (Object as any).assign(this, init); }
}

export class DownloaderVideo
{
    public videoUrl?: string;
    public locationX: number;
    public locationY: number;
    public width: number;
    public height: number;

    public constructor(init?: Partial<DownloaderVideo>) { (Object as any).assign(this, init); }
}

export class GamestoreApplicationDetails371
{
    public id?: string;
    public title?: string;
    public rating: number;
    public isGraphicsCustomized: boolean;
    public isDisabled: boolean;
    public isNew: boolean;
    public description?: string;
    public shortDescription?: string;
    public publisher?: string;
    public version?: string;
    public updatedAt: string;
    public installCount: number;
    public reviewsCount?: number;
    public updatedOn?: string;
    public downloadSize: number;
    public categories?: Category371[];
    public iconUrl?: string;
    public thumbnailUrl?: string;
    public logoUrl?: string;
    public featureUrl?: string;
    public backgroundUrl?: string;
    public bannerUrls?: string[];
    public videoUrls?: string[];
    public screenshotUrls?: string[];
    public needGoogleAccount: boolean;
    public useGooglePlay: boolean;
    public screenOrientation: ScreenOrientationType;
    public screenWidth: number;
    public screenHeight: number;
    public downloaderVideoOptions?: DownloaderVideo;
    public downloaderImageUrls?: string[];

    public constructor(init?: Partial<GamestoreApplicationDetails371>) { (Object as any).assign(this, init); }
}

export class IoApplicationData371
{
    public id?: string;
    public title?: string;
    public iconUrl?: string;
    public imageUrl?: string;
    public isDisabled: boolean;
    public applicationUrl?: string;

    public constructor(init?: Partial<IoApplicationData371>) { (Object as any).assign(this, init); }
}

export class IoApplicationDetails371
{
    public id?: string;
    public title?: string;
    public rating: number;
    public isGraphicsCustomized: boolean;
    public isDisabled: boolean;
    public isNew: boolean;
    public applicationUrl?: string;
    public description?: string;
    public developer?: string;
    public updatedAt: string;
    public installCount: number;
    public categories?: Category371[];
    public iconUrl?: string;
    public thumbnailUrl?: string;
    public logoUrl?: string;
    public featureUrl?: string;
    public backgroundUrl?: string;
    public bannerUrls?: string[];
    public videoUrls?: string[];
    public screenshotUrls?: string[];
    public downloaderVideoOptions?: DownloaderVideo;

    public constructor(init?: Partial<IoApplicationDetails371>) { (Object as any).assign(this, init); }
}

export class Category387
{
    public id?: string;
    public parentId?: string;
    public name?: string;
    public backgroundImageUrl?: string;

    public constructor(init?: Partial<Category387>) { (Object as any).assign(this, init); }
}

export class GamestoreApplicationDetails387
{
    public id?: string;
    public title?: string;
    public rating: number;
    public isGraphicsCustomized: boolean;
    public isDisabled: boolean;
    public isNew: boolean;
    public description?: string;
    public shortDescription?: string;
    public publisher?: string;
    public version?: string;
    public updatedAt: string;
    public installCount: number;
    public reviewsCount?: number;
    public updatedOn?: string;
    public downloadSize: number;
    public categories?: Category387[];
    public iconUrl?: string;
    public thumbnailUrl?: string;
    public logoUrl?: string;
    public featureUrl?: string;
    public backgroundUrl?: string;
    public bannerUrls?: string[];
    public videoUrls?: string[];
    public screenshotUrls?: string[];
    public needGoogleAccount: boolean;
    public useGooglePlay: boolean;
    public screenOrientation: ScreenOrientationType;
    public screenWidth: number;
    public screenHeight: number;
    public downloaderVideoOptions?: DownloaderVideo;
    public downloaderImageUrls?: string[];

    public constructor(init?: Partial<GamestoreApplicationDetails387>) { (Object as any).assign(this, init); }
}

export class Tag387
{
    // @Required()
    public id: string;

    // @Required()
    public name: string;

    public iconUrl?: string;
    public showInNavbar: boolean;

    public constructor(init?: Partial<Tag387>) { (Object as any).assign(this, init); }
}

export class IoApplicationDetails387
{
    public id?: string;
    public title?: string;
    public rating: number;
    public isGraphicsCustomized: boolean;
    public isDisabled: boolean;
    public isNew: boolean;
    public applicationUrl?: string;
    public description?: string;
    public developer?: string;
    public updatedAt: string;
    public installCount: number;
    public categories?: Category387[];
    public tags?: Tag387[];
    public iconUrl?: string;
    public thumbnailUrl?: string;
    public logoUrl?: string;
    public featureUrl?: string;
    public backgroundUrl?: string;
    public bannerUrls?: string[];
    public videoUrls?: string[];
    public screenshotUrls?: string[];
    public downloaderVideoOptions?: DownloaderVideo;

    public constructor(init?: Partial<IoApplicationDetails387>) { (Object as any).assign(this, init); }
}

export class CrawlingResult
{
    public id?: string;
    public addedAt: string;
    public updatedAt: string;
    public errorMessageX32?: string;
    public errorMessageX64?: string;
    public availableAtCountries?: string[];
    public statusX32: CrawlingStatus;
    public statusX64: CrawlingStatus;

    public constructor(init?: Partial<CrawlingResult>) { (Object as any).assign(this, init); }
}

export class IoApplicationSourcesFailedApp
{
    public id?: string;
    public error?: string;

    public constructor(init?: Partial<IoApplicationSourcesFailedApp>) { (Object as any).assign(this, init); }
}

export class IoApplicationSourceUrl
{
    // @Required()
    public ioApplicationSourceId: string;

    public provider?: string;
    public language?: string;
    public srcUrl?: string;
    public infoUrl?: string;

    public constructor(init?: Partial<IoApplicationSourceUrl>) { (Object as any).assign(this, init); }
}

export class IoApplicationSource
{
    public id?: string;
    public title?: string;
    // @Required()
    public urls: IoApplicationSourceUrl[];

    public constructor(init?: Partial<IoApplicationSource>) { (Object as any).assign(this, init); }
}

export interface IApplicationDetails
{
    rating: number;
    isGraphicsCustomized: boolean;
    isDisabled: boolean;
}

export interface IHasTitle
{
    title?: string;
}

export interface IHasIsNew
{
    isNew: boolean;
}

export class ApplicationShortDetails387 implements IApplicationDetails, IHasIsNew
{
    public id?: string;
    public iconUrl?: string;
    public thumbnailUrl?: string;
    public title?: string;
    public publisher?: string;
    public installCount?: number;
    public rating: number;
    public isGraphicsCustomized: boolean;
    public isNew: boolean;
    public isDisabled: boolean;
    public isIoApplication: boolean;
    public gameCategory?: Category387;
    public tags?: Tag387[];

    public constructor(init?: Partial<ApplicationShortDetails387>) { (Object as any).assign(this, init); }
}

export interface ITitledApplicationDetails extends IApplicationDetails
{
    title?: string;
}

export class ApplicationShortDetails371 implements IApplicationDetails
{
    public id?: string;
    public iconUrl?: string;
    public thumbnailUrl?: string;
    public title?: string;
    public publisher?: string;
    public installCount?: number;
    public rating: number;
    public isGraphicsCustomized: boolean;
    public isNew: boolean;
    public isDisabled: boolean;
    public isIoApplication: boolean;
    public gameCategory?: Category371;

    public constructor(init?: Partial<ApplicationShortDetails371>) { (Object as any).assign(this, init); }
}

export class FeaturedApplication371
{
    public id?: string;
    public title?: string;
    public description?: string;
    public bannerUrl?: string;
    public logoUrl?: string;
    public order: number;

    public constructor(init?: Partial<FeaturedApplication371>) { (Object as any).assign(this, init); }
}

export class ContinuePlayingAppsShort371
{
    public kind: ContinuePlayingKind;
    // @Required()
    public applications: ApplicationShortDetails371[];

    public constructor(init?: Partial<ContinuePlayingAppsShort371>) { (Object as any).assign(this, init); }
}

export interface IApplicationData371_original_not_used
{
    id?: string;
    title?: string;
    iconUrl?: string;
    imageUrl?: string;
    isDisabled: boolean;
}

export class DownloaderOptions371
{
    public termsOfServiceEnabled: boolean;
    public termsOfServiceUrl?: string;
    public privacyPolicyUrl?: string;
    public imageUrls?: string[];
    public videoOptions?: DownloaderVideo;

    public constructor(init?: Partial<DownloaderOptions371>) { (Object as any).assign(this, init); }
}

export class IdleOption
{
    public idleUserTimeoutSeconds: number;
    public inactiveWindowTimeoutSeconds: number;

    public constructor(init?: Partial<IdleOption>) { (Object as any).assign(this, init); }
}

export class PlayerOptions371
{
    public gameIntroVideoUrl?: string;
    public idleOptions?: IdleOption;

    public constructor(init?: Partial<PlayerOptions371>) { (Object as any).assign(this, init); }
}

export class AdBrowserExtension30
{
    public id: number;
    // @Required()
    public extensionId: string;

    public constructor(init?: Partial<AdBrowserExtension30>) { (Object as any).assign(this, init); }
}

export enum AdUnitTypes
{
    BrowserAction = 'BrowserAction',
    BannerAction = 'BannerAction',
    RichMediaOverlay = 'RichMediaOverlay',
    DynamicWeb = 'DynamicWeb',
    SideAd = 'SideAd',
}

export enum AdFilterType
{
    Application = 'Application',
    Category = 'Category',
}

export enum AdUnitTriggerType
{
    GameStart = 'GameStart',
    GameExit = 'GameExit',
    GameInstallation = 'GameInstallation',
    GameDeleted = 'GameDeleted',
    OnSchedule = 'OnSchedule',
    AndroidAppStarted = 'AndroidAppStarted',
    ApmDropped = 'ApmDropped',
}

export class AdSchedulingDetails30
{
    public displayEveryHours: number;
    public displayEveryMinutes: number;

    public constructor(init?: Partial<AdSchedulingDetails30>) { (Object as any).assign(this, init); }
}

export class AdTrigger30
{
    public type: AdUnitTriggerType;
    // @Required()
    public schedulingDetails: AdSchedulingDetails30;

    public constructor(init?: Partial<AdTrigger30>) { (Object as any).assign(this, init); }
}

export class AdUnit30
{
    // @Required()
    public id: number;

    // @Required()
    public type: AdUnitTypes;

    public name?: string;
    public dailyLimit: number;
    public daysPerWeek: number;
    public weeksTotal: number;
    public disableOnClick: boolean;
    public filterType: AdFilterType;
    public applicationIds?: string[];
    public categoryIds?: string[];
    // @Required()
    public triggers: AdTrigger30[];

    public constructor(init?: Partial<AdUnit30>) { (Object as any).assign(this, init); }
}

export enum AdBrowserType
{
    Default = 'Default',
    Chrome = 'Chrome',
    Edge = 'Edge',
    Firefox = 'Firefox',
    Opera = 'Opera',
}

export class AdBrowserUnit30 extends AdUnit30
{
    public targetUrl?: string;
    public browserType: AdBrowserType;

    public constructor(init?: Partial<AdBrowserUnit30>) { super(init); (Object as any).assign(this, init); }
}

export class AdBannerUnit30 extends AdUnit30
{
    // @Required()
    public header: string;

    // @Required()
    public targetUrl: string;

    // @Required()
    public imageUrl: string;

    public imageWidth: number;
    public imageHeight: number;

    public constructor(init?: Partial<AdBannerUnit30>) { super(init); (Object as any).assign(this, init); }
}

export enum AdPixelType
{
    Dip = 'Dip',
    Pixel = 'Pixel',
    Percent = 'Percent',
}

export enum AdOrientationType
{
    Any = 'Any',
    Portrait = 'Portrait',
    Landscape = 'Landscape',
}

export class AdRichMediaOverlayUnit30 extends AdUnit30
{
    public pixelType: AdPixelType;
    public centerX: number;
    public centerY: number;
    public sizeX: number;
    public sizeY: number;
    public animations?: AdAnimationType[];
    public delay: number;
    public duration: number;
    public content?: string;
    public targetUrl?: string;
    public orientation: AdOrientationType;

    public constructor(init?: Partial<AdRichMediaOverlayUnit30>) { super(init); (Object as any).assign(this, init); }
}

export class AdDynamicWebUnit30 extends AdUnit30
{
    // @Required()
    public header: string;

    public targetUrl?: string;
    public sizeX: number;
    public sizeY: number;
    public launchInDesktopBrowser: boolean;

    public constructor(init?: Partial<AdDynamicWebUnit30>) { super(init); (Object as any).assign(this, init); }
}

export enum SideAdUnitTypes
{
    Left = 'Left',
    Right = 'Right',
}

export class AdSideUnit30 extends AdUnit30
{
    // @Required()
    public side: SideAdUnitTypes;

    public width: number;
    public height: number;
    // @Required()
    public url: string;

    public constructor(init?: Partial<AdSideUnit30>) { super(init); (Object as any).assign(this, init); }
}

export class AdCampaign30
{
    public id: number;
    // @Required()
    public name: string;

    // @Required()
    public browserUnits: AdBrowserUnit30[];

    // @Required()
    public bannerUnits: AdBannerUnit30[];

    // @Required()
    public richMediaOverlayUnits: AdRichMediaOverlayUnit30[];

    // @Required()
    public dynamicWebUnits: AdDynamicWebUnit30[];

    // @Required()
    public sideAdUnits: AdSideUnit30[];

    public constructor(init?: Partial<AdCampaign30>) { (Object as any).assign(this, init); }
}

export class AdCampaign31
{
    public id: number;
    // @Required()
    public name: string;

    // @Required()
    public units: AdUnit30[];

    public constructor(init?: Partial<AdCampaign31>) { (Object as any).assign(this, init); }
}

export class ApplicationShortDetails30 implements IApplicationDetails
{
    public id?: string;
    public rating: number;
    public title?: string;
    public publisher?: string;
    public installCount: number;
    public reviewsCount?: number;
    public updatedOn?: string;
    public categories?: Category30[];
    public iconUrl?: string;
    public thumbnailUrl?: string;
    public isDisabled: boolean;
    public isGraphicsCustomized: boolean;

    public constructor(init?: Partial<ApplicationShortDetails30>) { (Object as any).assign(this, init); }
}

export class FeaturedApplication30
{
    public id?: string;
    public title?: string;
    public description?: string;
    public bannerUrl?: string;
    public logoUrl?: string;
    public order: number;

    public constructor(init?: Partial<FeaturedApplication30>) { (Object as any).assign(this, init); }
}

export class ReleaseNotes30
{
    public version?: string;
    public changelogMarkdown?: string;

    public constructor(init?: Partial<ReleaseNotes30>) { (Object as any).assign(this, init); }
}

export class ApplicationShortDetails31
{
    public id?: string;
    public iconUrl?: string;
    public title?: string;
    public publisher?: string;
    public installCount?: number;

    public constructor(init?: Partial<ApplicationShortDetails31>) { (Object as any).assign(this, init); }
}

export enum ColorSchemeType
{
    BrowseButtonEnableBorderColor = 'BrowseButtonEnableBorderColor',
    BrowseButtonEnableColor = 'BrowseButtonEnableColor',
    BrowseButtonTextColor = 'BrowseButtonTextColor',
    BrowseButtonMouseClickBorderColor = 'BrowseButtonMouseClickBorderColor',
    BrowseButtonMouseClickColor = 'BrowseButtonMouseClickColor',
    BrowseButtonMouseOverBackColor = 'BrowseButtonMouseOverBackColor',
    BrowseButtonMouseOverBorderColor = 'BrowseButtonMouseOverBorderColor',
    ContinueButtonDisableColor = 'ContinueButtonDisableColor',
    ContinueButtonDisableBackColor = 'ContinueButtonDisableBackColor',
    ContinueButtonEnableBorderColor = 'ContinueButtonEnableBorderColor',
    ContinueButtonEnableColor = 'ContinueButtonEnableColor',
    ContinueButtonMouseClickBorderColor = 'ContinueButtonMouseClickBorderColor',
    ContinueButtonMouseClickColor = 'ContinueButtonMouseClickColor',
    ContinueButtonMouseOverBorderColor = 'ContinueButtonMouseOverBorderColor',
    ContinueButtonMouseOverColor = 'ContinueButtonMouseOverColor',
    ContinueButtonTextColor = 'ContinueButtonTextColor',
    CustomSettingsCheckBoxBackColor = 'CustomSettingsCheckBoxBackColor',
    CustomSettingsCheckBoxCheckedBackColor = 'CustomSettingsCheckBoxCheckedBackColor',
    CustomSettingsCheckBoxCheckmarkColor = 'CustomSettingsCheckBoxCheckmarkColor',
    CustomSettingsCheckBoxMouseOverBackColor = 'CustomSettingsCheckBoxMouseOverBackColor',
    CustomSettingsTextColor = 'CustomSettingsTextColor',
    HelpButtonTextColor = 'HelpButtonTextColor',
    HelpButtonEnableColor = 'HelpButtonEnableColor',
    HelpButtonEnableBorderColor = 'HelpButtonEnableBorderColor',
    HelpButtonMouseOverColor = 'HelpButtonMouseOverColor',
    HelpButtonMouseOverBorderColor = 'HelpButtonMouseOverBorderColor',
    HelpButtonMouseClickColor = 'HelpButtonMouseClickColor',
    HelpButtonMouseClickBorderColor = 'HelpButtonMouseClickBorderColor',
    InstallationPathBackColor = 'InstallationPathBackColor',
    InstallationPathBorderColor = 'InstallationPathBorderColor',
    InstallationPathTextColor = 'InstallationPathTextColor',
    ProgressAppTitleTextColor = 'ProgressAppTitleTextColor',
    ProgressBarBorderColor = 'ProgressBarBorderColor',
    ProgressBarColor = 'ProgressBarColor',
    ProgressBarTextColor = 'ProgressBarTextColor',
    ProgressDownloadSpeedTextColor = 'ProgressDownloadSpeedTextColor',
    CheckBoxTermsBackColor = 'CheckBoxTermsBackColor',
    CheckBoxTermsBorderColor = 'CheckBoxTermsBorderColor',
    CheckBoxTermsCheckedBackColor = 'CheckBoxTermsCheckedBackColor',
    CheckBoxTermsCheckedBorderColor = 'CheckBoxTermsCheckedBorderColor',
    CheckBoxTermsMouseOverBackColor = 'CheckBoxTermsMouseOverBackColor',
    CheckBoxTermsCheckmarkColor = 'CheckBoxTermsCheckmarkColor',
    TermsLinkActiveTextColor = 'TermsLinkActiveTextColor',
    TermsLinkTextColor = 'TermsLinkTextColor',
    TermsLinkVisitedTextColor = 'TermsLinkVisitedTextColor',
    WindowBackColor = 'WindowBackColor',
    WindowCloseButtonColor = 'WindowCloseButtonColor',
    WindowCloseButtonMouseClickColor = 'WindowCloseButtonMouseClickColor',
    WindowCloseButtonMouseOverColor = 'WindowCloseButtonMouseOverColor',
    WindowTextColor = 'WindowTextColor',
    SubCategoryBarBackgroundColor = 'SubCategoryBarBackgroundColor',
    SubCategoryIconColor = 'SubCategoryIconColor',
    SubCategoryTextColor = 'SubCategoryTextColor',
    SubCategoryActiveIconColor = 'SubCategoryActiveIconColor',
    SubCategoryActiveTextColor = 'SubCategoryActiveTextColor',
    SubCategoryMouseClickIconColor = 'SubCategoryMouseClickIconColor',
    SubCategoryMouseClickTextColor = 'SubCategoryMouseClickTextColor',
    SubCategoryMouseOverIconColor = 'SubCategoryMouseOverIconColor',
    SubCategoryMouseOverTextColor = 'SubCategoryMouseOverTextColor',
}

export class ColorOption
{
    public schemeItem?: ColorSchemeType;
    public color?: string;

    public constructor(init?: Partial<ColorOption>) { (Object as any).assign(this, init); }
}

export class SourceSettingsData
{
    public productName?: string;
    public faqUrl?: string;
    public hyperVFaqUrl?: string;
    public gameNotFoundImageUrl?: string;
    public aboutText?: string;
    public systemInfoReportText?: string;
    public termsOfServiceEnabled?: boolean;
    public termsOfServiceUrl?: string;
    public privacyPolicyUrl?: string;
    public contactUsUrl?: string;
    public defaultGameIconUrl?: string;
    public uninstallIconUrl?: string;
    public isIntroVideoEnabled?: boolean;
    public gameIntroVideoUrl?: string;
    public downloaderImageUrls?: string[];
    public updateImageUrls?: string[];
    public repairWindowImageUrl?: string;
    public generalControlsLandscapeImageUrl?: string;
    public generalControlsPortraitImageUrl?: string;
    public useApplicationIcon?: boolean;
    public installationLeftCornerIconUrl?: string;
    public installationTaskbarIconUrl?: string;
    public speedUpEmulatorOptimizationInfoUrl?: string;
    public gameCompatibilityOptimizationInfoUrl?: string;
    public moreQuestionsOptimizationInfoUrl?: string;
    public portraitWidth?: number;
    public portraitHeight?: number;
    public landscapeWidth?: number;
    public landscapeHeight?: number;
    public pinToDesktopApplicationIds?: string[];
    public searchDisabledApps?: boolean;
    public searchAppsInDisabledCategories?: boolean;
    public installerColorOptions?: ColorOption[];

    public constructor(init?: Partial<SourceSettingsData>) { (Object as any).assign(this, init); }
}

export class GridResolutionSettings
{
    // @Required()
    public visibleColumns: string[];

    public constructor(init?: Partial<GridResolutionSettings>) { (Object as any).assign(this, init); }
}

export class GridSettings
{
    // @Required()
    public name: string;

    // @Required()
    public resolutions: { [index: string]: GridResolutionSettings; };

    public constructor(init?: Partial<GridSettings>) { (Object as any).assign(this, init); }
}

export class WidgetLayoutSettings
{
    public minW?: number;
    public maxW?: number;
    public minH?: number;
    public maxH?: number;

    public constructor(init?: Partial<WidgetLayoutSettings>) { (Object as any).assign(this, init); }
}

export class DashboardSettings
{
    // @Required()
    public widgetsSettings: { [index: string]: WidgetLayoutSettings; };

    // @Required()
    public columns: { [index: string]: number; };

    public rowHeight: number;
    public maxRows: number;

    public constructor(init?: Partial<DashboardSettings>) { (Object as any).assign(this, init); }
}

export class FrontendSettings
{
    // @Required()
    public grids: GridSettings[];

    // @Required()
    public dashboardSettings: DashboardSettings;

    public constructor(init?: Partial<FrontendSettings>) { (Object as any).assign(this, init); }
}

export class CrawlingResultSummary
{
    public id?: string;
    public addedAt: string;
    public updatedAt: string;
    public errorMessageX32?: string;
    public errorMessageX64?: string;
    public availableAtCountries?: string[];
    public statusX32: CrawlingStatus;
    public statusX64: CrawlingStatus;
    public gamestoreApplicationExists: boolean;

    public constructor(init?: Partial<CrawlingResultSummary>) { (Object as any).assign(this, init); }
}

export class AdTargetingSettings
{
    public sources?: string[];

    public constructor(init?: Partial<AdTargetingSettings>) { (Object as any).assign(this, init); }
}

export class AdTargeting
{
    public id: number;
    public adCampaignId: number;
    public adTargetingSettings?: AdTargetingSettings;

    public constructor(init?: Partial<AdTargeting>) { (Object as any).assign(this, init); }
}

export class AdBrowserUnit
{
    public id: number;
    public adBrowserType: AdBrowserType;
    // @Required()
    public url: string;

    public constructor(init?: Partial<AdBrowserUnit>) { (Object as any).assign(this, init); }
}

export class AdBannerUnit
{
    public id: number;
    // @Required()
    public bannerHeader: string;

    // @Required()
    public bannerImageUrl: string;

    public bannerSizeX: number;
    public bannerSizeY: number;
    // @Required()
    public bannerUrl: string;

    public constructor(init?: Partial<AdBannerUnit>) { (Object as any).assign(this, init); }
}

export enum AdAnimationType
{
    SlideInLeft = 'SlideInLeft',
    SlideInRight = 'SlideInRight',
    SlideInTop = 'SlideInTop',
    SlideInBottom = 'SlideInBottom',
}

export class AdRichMediaOverlayUnit
{
    public id: number;
    public pixelType: AdPixelType;
    public centerX: number;
    public centerY: number;
    public sizeX: number;
    public sizeY: number;
    public animations?: AdAnimationType[];
    public delay: number;
    public duration: number;
    public content?: string;
    public targetUrl?: string;
    public orientation: AdOrientationType;

    public constructor(init?: Partial<AdRichMediaOverlayUnit>) { (Object as any).assign(this, init); }
}

export class AdDynamicWebUnit
{
    public id: number;
    // @Required()
    public url: string;

    // @Required()
    public header: string;

    public sizeX: number;
    public sizeY: number;
    public launchInDesktopBrowser: boolean;

    public constructor(init?: Partial<AdDynamicWebUnit>) { (Object as any).assign(this, init); }
}

export class AdSideUnit
{
    public id: number;
    public side: SideAdUnitTypes;
    public width: number;
    public height: number;
    // @Required()
    public url: string;

    public constructor(init?: Partial<AdSideUnit>) { (Object as any).assign(this, init); }
}

export class AdSchedulingDetails
{
    public displayEveryMinutes: number;

    public constructor(init?: Partial<AdSchedulingDetails>) { (Object as any).assign(this, init); }
}

export class AdUnitTrigger
{
    public id: number;
    public adUnitId: number;
    public triggerType: AdUnitTriggerType;
    public adSchedulingDetails?: AdSchedulingDetails;
    public isDisabled: boolean;

    public constructor(init?: Partial<AdUnitTrigger>) { (Object as any).assign(this, init); }
}

export class AdUnit
{
    public id: number;
    public adCampaignId: number;
    public unitType: AdUnitTypes;
    public name?: string;
    public isDisabled: boolean;
    public dailyLimit: number;
    public daysPerWeek: number;
    public weeksTotal: number;
    public disableOnClick: boolean;
    public filterType: AdFilterType;
    public adBrowserUnit?: AdBrowserUnit;
    public adBannerUnit?: AdBannerUnit;
    public adRichMediaOverlayUnit?: AdRichMediaOverlayUnit;
    public adDynamicWebUnit?: AdDynamicWebUnit;
    public adSideUnit?: AdSideUnit;
    public adUnitTriggers?: AdUnitTrigger[];
    public applicationIds?: string[];
    public categoryIds?: string[];

    public constructor(init?: Partial<AdUnit>) { (Object as any).assign(this, init); }
}

export class AdUnitSummary extends AdUnit
{
    public adCampaignName?: string;
    public adCampaignIsDisabled: boolean;
    public hasNonStandardDimension: boolean;

    public constructor(init?: Partial<AdUnitSummary>) { super(init); (Object as any).assign(this, init); }
}

export class AdBannerSize
{
    public id: number;
    public sizeX: number;
    public sizeY: number;

    public constructor(init?: Partial<AdBannerSize>) { (Object as any).assign(this, init); }
}

export class FeaturedApplicationLocalization
{
    public id: number;
    // @Required()
    public language: string;

    // @Required()
    public description: string;

    // @Required()
    public bannerUrl: string;

    public logoUrl?: string;

    public constructor(init?: Partial<FeaturedApplicationLocalization>) { (Object as any).assign(this, init); }
}

export class ApkDetails
{
    public id: number;
    // @Required()
    public gamestoreApplicationId: string;

    public platform: ApkPlatform;
    public versionName?: string;
    public downloadSize: number;

    public constructor(init?: Partial<ApkDetails>) { (Object as any).assign(this, init); }
}

export class GamestoreApplicationLocalization
{
    public id: number;
    // @Required()
    public gamestoreApplicationId: string;

    // @Required()
    public language: string;

    public title?: string;
    public iconUrl?: string;
    public publisher?: string;
    public publisherId?: string;
    public shortDescription?: string;
    public description?: string;
    // @Required()
    public screenshots: string[];

    // @Required()
    public videos: string[];

    public thumbnailUrl?: string;
    public logoUrl?: string;
    public featureUrl?: string;
    public backgroundUrl?: string;
    public loadingImageUrl?: string;
    // @Required()
    public banners: string[];

    public downloaderVideoOptions?: DownloaderVideo;
    public downloaderImageUrls?: string[];

    public constructor(init?: Partial<GamestoreApplicationLocalization>) { (Object as any).assign(this, init); }
}

export class GamestoreApplicationDisableReason
{
    public id?: string;
    public disabledBy?: string;
    public description?: string;
    public testedVersions?: string[];
    public disableDate: string;

    public constructor(init?: Partial<GamestoreApplicationDisableReason>) { (Object as any).assign(this, init); }
}

export class Category
{
    // @Required()
    public id: string;

    public parentId?: string;
    // @Required()
    public name: string;

    public displayName?: string;
    public description?: string;
    public backgroundImageUrl?: string;
    public order: number;
    public showInNavbar: boolean;
    public isActive: boolean;
    public isVirtual: boolean;
    public defaultKeyBindings?: EmulatorOption;

    public constructor(init?: Partial<Category>) { (Object as any).assign(this, init); }
}

export class GamestoreApplicationSummary
{
    // @Required()
    public id: string;

    // @Required()
    public language: string;

    public iconUrl?: string;
    public title?: string;
    public description?: string;
    public shortDescription?: string;
    public publisher?: string;
    public publisherId?: string;
    public rating: number;
    public version32?: string;
    public version64?: string;
    public platforms?: ApkPlatform[];
    public installsCount: number;
    public reviewsCount?: number;
    public updatedOn?: string;
    public screenOrientation: ScreenOrientationType;
    public isFeatured: boolean;
    public isGraphicsCustomized: boolean;
    public isNew: boolean;
    public isDisabled: boolean;
    public disableReason?: string;
    public disableDate?: string;
    public testedVersions?: string[];
    public categories?: Category[];
    public modifiedAt: string;
    public x32ApkUpdatedAt?: string;
    public x64ApkUpdatedAt?: string;
    public downloaderVideoOptions?: DownloaderVideo;

    public constructor(init?: Partial<GamestoreApplicationSummary>) { (Object as any).assign(this, init); }
}

export class GamestoreIoApplicationLocalization
{
    public id: number;
    // @Required()
    public gamestoreIoApplicationId: string;

    // @Required()
    public language: string;

    public title?: string;
    public iconUrl?: string;
    public description?: string;
    public applicationUrl?: string;
    // @Required()
    public screenshots: string[];

    // @Required()
    public videos: string[];

    public thumbnailUrl?: string;
    public logoUrl?: string;
    public backgroundUrl?: string;
    public loadingImageUrl?: string;
    // @Required()
    public banners: string[];

    public downloaderVideoOptions?: DownloaderVideo;

    public constructor(init?: Partial<GamestoreIoApplicationLocalization>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GamestoreIoApplicationExportSummary
{
    // @DataMember(Name="id")
    public id?: string;

    // @DataMember(Name="title")
    public title?: string;

    // @DataMember(Name="source_provider")
    public source_provider?: string;

    // @DataMember(Name="developer")
    public developer?: string;

    // @DataMember(Name="rating")
    public rating: number;

    // @DataMember(Name="categories")
    public categories?: string[];

    // @DataMember(Name="tags")
    public tags?: string[];

    // @DataMember(Name="graphics customized")
    public graphics_customized: boolean;

    // @DataMember(Name="#install")
    public _install: number;

    // @DataMember(Name="application_modified_date")
    public application_modified_date: string;

    // @DataMember(Name="src_updated_date")
    public src_updated_date: string;

    // @DataMember(Name="disabled")
    public disabled: boolean;

    public constructor(init?: Partial<GamestoreIoApplicationExportSummary>) { (Object as any).assign(this, init); }
}

export class GamestoreIoApplicationSummary
{
    // @Required()
    public id: string;

    // @Required()
    public language: string;

    public applicationUrl?: string;
    public iconUrl?: string;
    public title?: string;
    public description?: string;
    public developer?: string;
    public sourceProvider?: string;
    public rating: number;
    public installsCount: number;
    public isGraphicsCustomized: boolean;
    public isNew: boolean;
    public isDisabled: boolean;
    public categories?: Category[];
    public tags?: string[];
    public modifiedAt: string;
    public srcUpdatedAt: string;
    public downloaderVideoOptions?: DownloaderVideo;

    public constructor(init?: Partial<GamestoreIoApplicationSummary>) { (Object as any).assign(this, init); }
}

export class GoogleApkDetails
{
    public id: number;
    // @Required()
    public googleApplicationId: string;

    public platform: ApkPlatform;
    public enabled: boolean;
    public versionCode: number;
    public versionName?: string;
    public apkDownloadUrl?: string;
    public apkDownloadSize: number;
    public updatedAt?: string;

    public constructor(init?: Partial<GoogleApkDetails>) { (Object as any).assign(this, init); }
}

export class GoogleSplitFile
{
    // @Required()
    public fileDownloadUrl: string;

    public platform: ApkPlatform;
    // @Required()
    public name: string;

    public constructor(init?: Partial<GoogleSplitFile>) { (Object as any).assign(this, init); }
}

export class GoogleApplicationLocalization
{
    public id: number;
    // @Required()
    public googleApplicationId: string;

    // @Required()
    public language: string;

    public title?: string;
    public iconUrl?: string;
    public publisher?: string;
    public publisherId?: string;
    public shortDescription?: string;
    public description?: string;
    public size: number;
    // @Required()
    public screenshots: string[];

    // @Required()
    public videos: string[];

    // @Required()
    public splitFiles: GoogleSplitFile[];

    public constructor(init?: Partial<GoogleApplicationLocalization>) { (Object as any).assign(this, init); }
}

export class GoogleApplicationSummary
{
    // @Required()
    public id: string;

    // @Required()
    public language: string;

    public iconUrl?: string;
    public title?: string;
    public description?: string;
    public shortDescription?: string;
    public publisher?: string;
    public publisherId?: string;
    public rating: number;
    public version32?: string;
    public version64?: string;
    public platforms?: ApkPlatform[];
    public installsCount: number;
    public reviewsCount?: number;
    public category?: Category;

    public constructor(init?: Partial<GoogleApplicationSummary>) { (Object as any).assign(this, init); }
}

export class GoogleVersionInfo
{
    public platform: ApkPlatform;
    public versionCode: number;

    public constructor(init?: Partial<GoogleVersionInfo>) { (Object as any).assign(this, init); }
}

export class GoogleAppVersionInfo
{
    // @Required()
    public id: string;

    public versionInfo?: GoogleVersionInfo[];
    public categoryId?: string;

    public constructor(init?: Partial<GoogleAppVersionInfo>) { (Object as any).assign(this, init); }
}

export class IoApplicationSummary
{
    // @Required()
    public id: string;

    // @Required()
    public language: string;

    public applicationUrl?: string;
    public iconUrl?: string;
    public title?: string;
    public description?: string;
    public developer?: string;
    public sourceUrl?: string;
    public sourceProvider?: string;
    public rating: number;
    public installsCount: number;
    public tags?: string[];
    public category?: Category;

    public constructor(init?: Partial<IoApplicationSummary>) { (Object as any).assign(this, init); }
}

export class IoApplicationProviderUrl
{
    public url?: string;
    public language?: string;

    public constructor(init?: Partial<IoApplicationProviderUrl>) { (Object as any).assign(this, init); }
}

export class IoApplicationProviderData
{
    public provider?: string;
    public urls?: IoApplicationProviderUrl[];

    public constructor(init?: Partial<IoApplicationProviderData>) { (Object as any).assign(this, init); }
}

export class IoApplicationSourceSummary
{
    public id?: string;
    public title?: string;
    public provider?: string;
    public preferredProvider?: string;
    public status: CrawlingStatus;
    public error?: string;
    public providers?: IoApplicationProviderData[];
    public gamestoreApplicationExists: boolean;

    public constructor(init?: Partial<IoApplicationSourceSummary>) { (Object as any).assign(this, init); }
}

export class ProductVersionInfoSettings
{
    // @Required()
    public sources: string[];

    public constructor(init?: Partial<ProductVersionInfoSettings>) { (Object as any).assign(this, init); }
}

export class PinnedApplicationShortDetails
{
    public id?: string;
    public iconUrl?: string;
    public title?: string;

    public constructor(init?: Partial<PinnedApplicationShortDetails>) { (Object as any).assign(this, init); }
}

export enum PropertyDataType
{
    CheckBox = 'CheckBox',
    SingleLineEditor = 'SingleLineEditor',
    MultiLineEditor = 'MultiLineEditor',
    HtmlEditor = 'HtmlEditor',
    MultiValueEditor = 'MultiValueEditor',
    ApplicationSelect = 'ApplicationSelect',
    SingleLineUrlEditorWithPreview = 'SingleLineUrlEditorWithPreview',
    InstallerColorOptionsEditor = 'InstallerColorOptionsEditor',
    IntEditor = 'IntEditor',
}

export enum UrlValidationType
{
    RelativeOrAbsolute = 'RelativeOrAbsolute',
    Absolute = 'Absolute',
    Relative = 'Relative',
}

export class PropertyMetadata
{
    public propertyType: PropertyDataType;
    // @Required()
    public propertyName: string;

    // @Required()
    public label: string;

    public validateUrl?: UrlValidationType;

    public constructor(init?: Partial<PropertyMetadata>) { (Object as any).assign(this, init); }
}

export class ColorSchemeMetadata
{
    // @Required()
    public name: string;

    // @Required()
    public label: string;

    public defaultValue?: string;

    public constructor(init?: Partial<ColorSchemeMetadata>) { (Object as any).assign(this, init); }
}

export enum WidgetTypes
{
    TextWidget = 'TextWidget',
    InfoWidget = 'InfoWidget',
    ChartWidget = 'ChartWidget',
    ListWidget = 'ListWidget',
}

export class TextWidgetUnit
{
    public id: number;
    public text?: string;

    public constructor(init?: Partial<TextWidgetUnit>) { (Object as any).assign(this, init); }
}

export enum InfoWidgetDataTypes
{
    ApplicationsInfo = 'ApplicationsInfo',
    CrawledApplicationInfo = 'CrawledApplicationInfo',
}

export class InfoWidgetUnit
{
    public id: number;
    public dataType: InfoWidgetDataTypes;

    public constructor(init?: Partial<InfoWidgetUnit>) { (Object as any).assign(this, init); }
}

export enum ChartTypes
{
    Line = 'Line',
    Spline = 'Spline',
    Column = 'Column',
    AreaSpline = 'AreaSpline',
    Pie = 'Pie',
}

export enum ChartWidgetDataTypes
{
    GamingMinutesData = 'GamingMinutesData',
}

export enum ChartWidgetTimeSpan
{
    Week = 'Week',
    Month = 'Month',
    Year = 'Year',
}

export class GamingMinutesChartOptions
{
    public showPlaystoreActivity: boolean;

    public constructor(init?: Partial<GamingMinutesChartOptions>) { (Object as any).assign(this, init); }
}

export class ChartWidgetOptions
{
    public gamingMinutesChartOptions?: GamingMinutesChartOptions;

    public constructor(init?: Partial<ChartWidgetOptions>) { (Object as any).assign(this, init); }
}

export class ChartWidgetUnit
{
    public id: number;
    public chartType: ChartTypes;
    public dataType: ChartWidgetDataTypes;
    public timeSpan: ChartWidgetTimeSpan;
    public options?: ChartWidgetOptions;

    public constructor(init?: Partial<ChartWidgetUnit>) { (Object as any).assign(this, init); }
}

export enum ListWidgetDataTypes
{
    LastUpdatedApplications = 'LastUpdatedApplications',
    TopRatedApplications = 'TopRatedApplications',
    RequestsByAverageTime = 'RequestsByAverageTime',
    RequestsByTotalTime = 'RequestsByTotalTime',
    RequestsByCount = 'RequestsByCount',
}

export class ListWidgetUnit
{
    public id: number;
    public dataType: ListWidgetDataTypes;

    public constructor(init?: Partial<ListWidgetUnit>) { (Object as any).assign(this, init); }
}

export class WidgetUnitLayout
{
    public breakPoint?: string;
    public x: number;
    public y: number;
    public w: number;
    public h: number;

    public constructor(init?: Partial<WidgetUnitLayout>) { (Object as any).assign(this, init); }
}

export class WidgetUnitSummary
{
    public id: number;
    public widgetType: WidgetTypes;
    public layouts?: WidgetUnitLayout[];

    public constructor(init?: Partial<WidgetUnitSummary>) { (Object as any).assign(this, init); }
}

export class WidgetUnitLayouts
{
    public id: number;
    public layouts?: WidgetUnitLayout[];

    public constructor(init?: Partial<WidgetUnitLayouts>) { (Object as any).assign(this, init); }
}

export class CrawledApplicationsInfoRow
{
    public x32: number;
    public x64: number;
    public total: number;

    public constructor(init?: Partial<CrawledApplicationsInfoRow>) { (Object as any).assign(this, init); }
}

export enum ClickType
{
    Unknown = 'Unknown',
    DesktopShortcut = 'DesktopShortcut',
    PlaystoreCategory = 'PlaystoreCategory',
    PinnedDesktopShortcut = 'PinnedDesktopShortcut',
}

export class ClickInfoStatisticsForPeriodSummary
{
    public date: string;
    public country?: string[];
    public sourceUrl?: string[];
    public platform?: string[];
    public productVersion?: string[];
    public emulatorType?: string[];
    public clickType?: ClickType[];
    public clickTarget?: string[];
    public count: number;

    public constructor(init?: Partial<ClickInfoStatisticsForPeriodSummary>) { (Object as any).assign(this, init); }
}

export class ClickInfoStatisticsByPeriodFiltersSuggestions
{
    public clickType?: string[];
    public country?: string[];
    public platform?: string[];
    public productVersion?: string[];
    public sourceUrl?: string[];
    public emulatorType?: string[];

    public constructor(init?: Partial<ClickInfoStatisticsByPeriodFiltersSuggestions>) { (Object as any).assign(this, init); }
}

export class ClickInfoStatisticsForDateSummary
{
    public date: string;
    public country?: string;
    public sourceUrl?: string;
    public platform?: string;
    public productVersion?: string;
    public emulatorType?: string;
    public clickType?: ClickType;
    public clickTarget?: string;
    public count: number;

    public constructor(init?: Partial<ClickInfoStatisticsForDateSummary>) { (Object as any).assign(this, init); }
}

export class ClickInfoStatisticsByDateFiltersSuggestions
{
    public clickTarget?: string[];
    public clickType?: string[];
    public country?: string[];
    public platform?: string[];
    public productVersion?: string[];
    public sourceUrl?: string[];
    public emulatorType?: string[];

    public constructor(init?: Partial<ClickInfoStatisticsByDateFiltersSuggestions>) { (Object as any).assign(this, init); }
}

export class DistributionDataItem
{
    public start: number;
    public end: number;
    public count: number;

    public constructor(init?: Partial<DistributionDataItem>) { (Object as any).assign(this, init); }
}

export class Distribution
{
    public confidence: number;
    public confidenceParts: number;
    public min: number;
    public minConfidence: number;
    public max: number;
    public maxConfidence: number;
    public data?: DistributionDataItem[];

    public constructor(init?: Partial<Distribution>) { (Object as any).assign(this, init); }
}

export class GamingMinutesStatisticsSummary
{
    public id: number;
    public date: string;
    public iconUrl?: string;
    public title?: string;
    public applicationId?: string;
    public productVersion?: string;
    public count: number;
    public movingAverageCount: number;
    public totalMinutes: number;
    public movingAverageTotalMinutes: number;
    public averageMinutes: number;
    public movingAverageAverageMinutes: number;
    public standardDeviationMinutes: number;
    public movingAverageStandardDeviationMinutes: number;

    public constructor(init?: Partial<GamingMinutesStatisticsSummary>) { (Object as any).assign(this, init); }
}

export class TotalGamingMinutesByDate
{
    public date: string;
    public totalMinutes: number;

    public constructor(init?: Partial<TotalGamingMinutesByDate>) { (Object as any).assign(this, init); }
}

export class TotalGamingMinutesById
{
    public applicationId?: string;
    public totalMinutes: number;

    public constructor(init?: Partial<TotalGamingMinutesById>) { (Object as any).assign(this, init); }
}

export enum PlaystoreActivityStatus
{
    PlayStoreApplicationDetailsClicked = 67,
    PlayStoreStartAppDownloading = 68,
    PlayStoreApplicationInstalled = 69,
    PlayStoreApplicationStart = 70,
    PlayStoreLaunched = 71,
    PlayStoreAppInstallCanceled = 72,
    PlayStoreStartPageLoaded = 73,
}

export class PlaystoreActivityForPeriodSummary
{
    public date: string;
    public country?: string[];
    public sourceUrl?: string[];
    public platform?: string[];
    public productVersion?: string[];
    public status?: PlaystoreActivityStatus[];
    public apkPackageName?: string[];
    public count: number;

    public constructor(init?: Partial<PlaystoreActivityForPeriodSummary>) { (Object as any).assign(this, init); }
}

export class PlaystoreActivityByPeriodFilterSuggestions
{
    public status?: string[];
    public country?: string[];
    public platform?: string[];
    public productVersion?: string[];
    public sourceUrl?: string[];

    public constructor(init?: Partial<PlaystoreActivityByPeriodFilterSuggestions>) { (Object as any).assign(this, init); }
}

export class PlaystoreActivityForDateSummary
{
    public date: string;
    public country?: string;
    public sourceUrl?: string;
    public platform?: string;
    public productVersion?: string;
    public status?: PlaystoreActivityStatus;
    public apkPackageName?: string;
    public count: number;

    public constructor(init?: Partial<PlaystoreActivityForDateSummary>) { (Object as any).assign(this, init); }
}

export class PlaystoreActivityByDateFilterSuggestions
{
    public apkPackageName?: string[];
    public status?: string[];
    public country?: string[];
    public platform?: string[];
    public productVersion?: string[];
    public sourceUrl?: string[];

    public constructor(init?: Partial<PlaystoreActivityByDateFilterSuggestions>) { (Object as any).assign(this, init); }
}

export class RequestStatisticsSummary
{
    public id: number;
    public date: string;
    public path?: string;
    public count: number;
    public movingAverageCount: number;
    public totalMilliseconds: number;
    public movingAverageTotalMilliseconds: number;
    public averageMilliseconds: number;
    public movingAverageAverageMilliseconds: number;
    public standardDeviationMilliseconds: number;
    public movingAverageStandardDeviationMilliseconds: number;

    public constructor(init?: Partial<RequestStatisticsSummary>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode?: string;

    // @DataMember(Order=2)
    public fieldName?: string;

    // @DataMember(Order=3)
    public message?: string;

    // @DataMember(Order=4)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode?: string;

    // @DataMember(Order=2)
    public message?: string;

    // @DataMember(Order=3)
    public stackTrace?: string;

    // @DataMember(Order=4)
    public errors?: ResponseError[];

    // @DataMember(Order=5)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key?: string;

    // @DataMember(Order=2)
    public keyType?: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export interface IApplicationDetails371_original_not_used extends ITitledApplicationDetails
{
}

export interface IApplicationDetails387_original_not_used extends IApplicationDetails, IHasTitle, IHasIsNew
{
}

export class BindedTypes
{
    public _1?: GamestoreApplicationData371;
    public _2?: GamestoreApplicationDetails371;
    public _3?: IoApplicationData371;
    public _4?: IoApplicationDetails371;
    public _5?: GamestoreApplicationDetails387;
    public _6?: IoApplicationDetails387;

    public constructor(init?: Partial<BindedTypes>) { (Object as any).assign(this, init); }
}

export class PingResponse
{
    public status?: string;
    public serverTime: string;

    public constructor(init?: Partial<PingResponse>) { (Object as any).assign(this, init); }
}

export class Tag
{
    // @Required()
    public id: string;

    public name?: string;
    public iconUrl?: string;
    public importTagMapping?: string[];
    public order: number;
    public showInNavbar: boolean;

    public constructor(init?: Partial<Tag>) { (Object as any).assign(this, init); }
}

export class IoApplication
{
    // @Required()
    public id: string;

    // @Required()
    public categoryId: string;

    // @Required()
    public category: Category;

    public rating: number;
    public developer?: string;
    public sourceProvider?: string;
    public updatedAt: string;
    // @Required()
    public tagsIds: string[];

    public tags?: Tag[];
    // @Required()
    public localizations: IoApplicationLocalization[];

    public constructor(init?: Partial<IoApplication>) { (Object as any).assign(this, init); }
}

export class CrawlIoApplicationResult
{
    public id?: string;
    public provider?: string;
    public preferredProvider?: string;
    public addedAt: string;
    public updatedAt: string;
    public errorMessage?: string;
    public status: CrawlingStatus;
    public gamestoreApplicationExists: boolean;

    public constructor(init?: Partial<CrawlIoApplicationResult>) { (Object as any).assign(this, init); }
}

export class IoApplicationSourcesUpdateResult
{
    public applicationSources?: IoApplicationSource[];
    public failedApps?: IoApplicationSourcesFailedApp[];

    public constructor(init?: Partial<IoApplicationSourcesUpdateResult>) { (Object as any).assign(this, init); }
}

export class ContinuePlayingApps387
{
    public kind: ContinuePlayingKind;
    // @Required()
    public applications: ApplicationShortDetails387[];

    public constructor(init?: Partial<ContinuePlayingApps387>) { (Object as any).assign(this, init); }
}

export class GameCenterApplications387
{
    // @Required()
    public featuredApplications: FeaturedApplication371[];

    // @Required()
    public continuePlayingApplications: ContinuePlayingApps387;

    // @Required()
    public categoriesApplications: { [index: string]: ApplicationShortDetails387[]; };

    public randomPlayedApplication?: ApplicationShortDetails387;
    // @Required()
    public similarToRandomPlayedApplications: ApplicationShortDetails387[];

    public constructor(init?: Partial<GameCenterApplications387>) { (Object as any).assign(this, init); }
}

export class ContinuePlayingApps371
{
    public kind: ContinuePlayingKind;
    // @Required()
    public applications: ApplicationShortDetails371[];

    public constructor(init?: Partial<ContinuePlayingApps371>) { (Object as any).assign(this, init); }
}

export class GameCenterCategories30
{
    // @Required()
    public gameCategories: Category30[];

    // @Required()
    public discoveryCategories: Category30[];

    public constructor(init?: Partial<GameCenterCategories30>) { (Object as any).assign(this, init); }
}

export class GameCenterApplications371
{
    // @Required()
    public featuredApplications: FeaturedApplication371[];

    // @Required()
    public continuePlayingApplications: ContinuePlayingAppsShort371;

    // @Required()
    public categoriesApplications: { [index: string]: ApplicationShortDetails371[]; };

    public randomPlayedApplication?: ApplicationShortDetails371;
    // @Required()
    public similarToRandomPlayedApplications: ApplicationShortDetails371[];

    public constructor(init?: Partial<GameCenterApplications371>) { (Object as any).assign(this, init); }
}

export class ApplicationSettings371
{
    public application?: IApplicationData371;
    public source?: string;
    public googleAnalyticsId?: string;
    public defaultGameIconUrl?: string;
    public downloaderOptions?: DownloaderOptions371;
    public playerOptions?: PlayerOptions371;

    public constructor(init?: Partial<ApplicationSettings371>) { (Object as any).assign(this, init); }
}

export class SearchSuggestions30
{
    public typeahead?: string;
    public suggestions?: string[];

    public constructor(init?: Partial<SearchSuggestions30>) { (Object as any).assign(this, init); }
}

export class CheckAdBundleOfferResult30
{
    public hasOffer: boolean;

    public constructor(init?: Partial<CheckAdBundleOfferResult30>) { (Object as any).assign(this, init); }
}

export class AdBundleOffer30
{
    // @Required()
    public bundleUrl: string;

    // @Required()
    public backgroundImageUrl: string;

    // @Required()
    public contentHtml: string;

    public constructor(init?: Partial<AdBundleOffer30>) { (Object as any).assign(this, init); }
}

export class ApplicationKeyBindings30
{
    // @Required()
    public applicationId: string;

    public keyBindings?: EmulatorOption;

    public constructor(init?: Partial<ApplicationKeyBindings30>) { (Object as any).assign(this, init); }
}

export class ApplicationDetails30 implements IApplicationDetails
{
    public id?: string;
    public rating: number;
    public title?: string;
    public description?: string;
    public shortDescription?: string;
    public publisher?: string;
    public version?: string;
    public updatedAt: string;
    public installCount: number;
    public reviewsCount?: number;
    public updatedOn?: string;
    public downloadSize: number;
    public categories?: Category30[];
    public iconUrl?: string;
    public thumbnailUrl?: string;
    public logoUrl?: string;
    public featureUrl?: string;
    public backgroundUrl?: string;
    public bannerUrls?: string[];
    public videoUrls?: string[];
    public screenshotUrls?: string[];
    public needGoogleAccount: boolean;
    public useGooglePlay: boolean;
    public isDisabled: boolean;
    public isGraphicsCustomized: boolean;
    public screenOrientation: ScreenOrientationType;
    public downloaderVideoOptions?: DownloaderVideo;
    public downloaderImageUrls?: string[];

    public constructor(init?: Partial<ApplicationDetails30>) { (Object as any).assign(this, init); }
}

export class ContinuePlaying30
{
    public kind: ContinuePlayingKind;
    // @Required()
    public applications: ApplicationDetails30[];

    public constructor(init?: Partial<ContinuePlaying30>) { (Object as any).assign(this, init); }
}

export class GameCenterApplications30
{
    // @Required()
    public featuredApplications: FeaturedApplication30[];

    // @Required()
    public categoriesApplications: { [index: string]: ApplicationShortDetails30[]; };

    // @Required()
    public continuePlayingApplications: ApplicationShortDetails30[];

    public randomPlayedApplication?: ApplicationShortDetails30;
    // @Required()
    public similarToRandomPlayedApplications: ApplicationShortDetails30[];

    public constructor(init?: Partial<GameCenterApplications30>) { (Object as any).assign(this, init); }
}

export class ServerVersionDetails30
{
    public version?: string;
    public downloadUrl?: string;
    public downloadUrlX86?: string;
    public downloadUrlX64AndroidX64?: string;
    public isMandatory: boolean;

    public constructor(init?: Partial<ServerVersionDetails30>) { (Object as any).assign(this, init); }
}

export class SourceSettingsData30 extends SourceSettingsData
{
    public pinToDesktopApplications?: ApplicationShortDetails31[];

    public constructor(init?: Partial<SourceSettingsData30>) { super(init); (Object as any).assign(this, init); }
}

export class UserFrontendSettings
{
    public id: number;
    public grids?: GridSettings[];

    public constructor(init?: Partial<UserFrontendSettings>) { (Object as any).assign(this, init); }
}

export class FrontendSettingsResult
{
    public defaultSettings?: FrontendSettings;
    public userSettings?: UserFrontendSettings;

    public constructor(init?: Partial<FrontendSettingsResult>) { (Object as any).assign(this, init); }
}

export class AdBrowserExtension
{
    public id: number;
    // @Required()
    public extensionId: string;

    public constructor(init?: Partial<AdBrowserExtension>) { (Object as any).assign(this, init); }
}

export class AdCampaign
{
    public id: number;
    // @Required()
    public name: string;

    public isDisabled: boolean;
    public adUnits?: AdUnit[];
    public adTargeting?: AdTargeting;
    public sourceSettingsIds?: number[];

    public constructor(init?: Partial<AdCampaign>) { (Object as any).assign(this, init); }
}

export class CategoryApplicationsIds
{
    public googleApplicationsIds?: string[];
    public ioApplicationsIds?: string[];

    public constructor(init?: Partial<CategoryApplicationsIds>) { (Object as any).assign(this, init); }
}

export class FeaturedApplication
{
    // @Required()
    public id: string;

    public order: number;
    public isDisabled: boolean;
    // @Required()
    public localizations: FeaturedApplicationLocalization[];

    public constructor(init?: Partial<FeaturedApplication>) { (Object as any).assign(this, init); }
}

export class GoogleApplication
{
    // @Required()
    public id: string;

    // @Required()
    public categoryId: string;

    // @Required()
    public category: Category;

    public rating: number;
    public installsCount: number;
    public reviewsCount?: number;
    public updatedOn?: string;
    public sdkVersion: number;
    // @Required()
    public additionalFiles: GoogleAdditionalFile[];

    // @Required()
    public apkDetails: GoogleApkDetails[];

    // @Required()
    public localizations: GoogleApplicationLocalization[];

    public constructor(init?: Partial<GoogleApplication>) { (Object as any).assign(this, init); }
}

export class GamestoreApplication
{
    // @Required()
    public id: string;

    // @Required()
    public googleApplication: GoogleApplication;

    public rating?: number;
    public installsCount?: number;
    public reviewsCount?: number;
    public modifiedAt: string;
    public isDisabled: boolean;
    public isGraphicsCustomized: boolean;
    public needGoogleAccount: boolean;
    public useGooglePlay: boolean;
    public screenOrientation: ScreenOrientationType;
    public screenWidth: number;
    public screenHeight: number;
    // @Required()
    public apkDetails: ApkDetails[];

    // @Required()
    public localizations: GamestoreApplicationLocalization[];

    // @Required()
    public categoryIds: string[];

    // @Required()
    public categories: Category[];

    public defaultKeyBindings?: EmulatorOption;
    public disableReason?: GamestoreApplicationDisableReason;

    public constructor(init?: Partial<GamestoreApplication>) { (Object as any).assign(this, init); }
}

export class GetKeyBindingsSchemasResult
{
    // @Required()
    public camelCaseSchema: string;

    // @Required()
    public pascalCaseSchema: string;

    public constructor(init?: Partial<GetKeyBindingsSchemasResult>) { (Object as any).assign(this, init); }
}

export class GamestoreIoApplication
{
    // @Required()
    public id: string;

    // @Required()
    public ioApplication: IoApplication;

    public rating?: number;
    public installsCount?: number;
    public modifiedAt: string;
    public isDisabled: boolean;
    public isGraphicsCustomized: boolean;
    // @Required()
    public categoryIds: string[];

    // @Required()
    public categories: Category[];

    // @Required()
    public tagIds: string[];

    public tags?: Tag[];
    // @Required()
    public localizations: GamestoreIoApplicationLocalization[];

    public constructor(init?: Partial<GamestoreIoApplication>) { (Object as any).assign(this, init); }
}

export class ProductVersionInfo
{
    public id: number;
    // @Required()
    public version: string;

    // @Required()
    public changelogMarkdown: string;

    public isDisabled: boolean;
    public isMandatory: boolean;
    // @Required()
    public windowsInstallerUrlX86: string;

    // @Required()
    public windowsInstallerUrlX64: string;

    public windowsInstallerUrlX64AndroidX64?: string;
    // @Required()
    public settings: ProductVersionInfoSettings;

    public constructor(init?: Partial<ProductVersionInfo>) { (Object as any).assign(this, init); }
}

export class SourceSettings
{
    public id: number;
    // @Required()
    public source: string;

    // @Required()
    public settings: SourceSettingsData;

    public aliases?: string[];
    public isDeleted: boolean;

    public constructor(init?: Partial<SourceSettings>) { (Object as any).assign(this, init); }
}

export class SourceSettingsMetadata
{
    // @Required()
    public properties: PropertyMetadata[];

    // @Required()
    public colors: ColorSchemeMetadata[];

    public constructor(init?: Partial<SourceSettingsMetadata>) { (Object as any).assign(this, init); }
}

export class User
{
    public id: number;
    // @Required()
    public username: string;

    // @Required()
    public firstName: string;

    // @Required()
    public lastName: string;

    public isAdmin: boolean;

    public constructor(init?: Partial<User>) { (Object as any).assign(this, init); }
}

export class WidgetUnit
{
    public id: number;
    public title?: string;
    public widgetType: WidgetTypes;
    public textWidget?: TextWidgetUnit;
    public infoWidget?: InfoWidgetUnit;
    public chartWidget?: ChartWidgetUnit;
    public listWidget?: ListWidgetUnit;

    public constructor(init?: Partial<WidgetUnit>) { (Object as any).assign(this, init); }
}

export class ApplicationsInfo
{
    public applicationsCount: number;
    public featuredApplicationsCount: number;
    public disabledApplicationsCount: number;
    public graphicsCustomizedApplicationsCount: number;
    public lastUpdate: string;

    public constructor(init?: Partial<ApplicationsInfo>) { (Object as any).assign(this, init); }
}

export class CrawledApplicationsInfo
{
    public none?: CrawledApplicationsInfoRow;
    public crawled?: CrawledApplicationsInfoRow;
    public failed?: CrawledApplicationsInfoRow;
    public updating?: CrawledApplicationsInfoRow;
    public updated?: CrawledApplicationsInfoRow;
    public updateFailed?: CrawledApplicationsInfoRow;
    public tokenIsAbsent?: CrawledApplicationsInfoRow;
    public total?: CrawledApplicationsInfoRow;

    public constructor(init?: Partial<CrawledApplicationsInfo>) { (Object as any).assign(this, init); }
}

export class ActiveInstallsStatistics
{
    public id: number;
    public date: string;
    public productVersion?: string;
    public count: number;

    public constructor(init?: Partial<ActiveInstallsStatistics>) { (Object as any).assign(this, init); }
}

export class ClickInfoStatistics
{
    public id: number;
    public date: string;
    public country?: string;
    public sourceUrl?: string;
    public platform?: string;
    public productVersion?: string;
    public emulatorType?: string;
    public clickType?: ClickType;
    public clickTarget?: string;
    public count: number;

    public constructor(init?: Partial<ClickInfoStatistics>) { (Object as any).assign(this, init); }
}

export class ClickInfoStatisticsForPeriod
{
    public items?: ClickInfoStatisticsForPeriodSummary[];
    public filtersSuggestions?: ClickInfoStatisticsByPeriodFiltersSuggestions;

    public constructor(init?: Partial<ClickInfoStatisticsForPeriod>) { (Object as any).assign(this, init); }
}

export class ClickInfoStatisticsForDate
{
    public items?: ClickInfoStatisticsForDateSummary[];
    public filtersSuggestions?: ClickInfoStatisticsByDateFiltersSuggestions;

    public constructor(init?: Partial<ClickInfoStatisticsForDate>) { (Object as any).assign(this, init); }
}

export class GamingMinutesStatistics
{
    public id: number;
    public date: string;
    public applicationId?: string;
    public productVersion?: string;
    public count: number;
    public hwidCount: number;
    public totalMinutes: number;
    public averageMinutes: number;
    public standardDeviationMinutes: number;
    public averageDistribution?: Distribution;

    public constructor(init?: Partial<GamingMinutesStatistics>) { (Object as any).assign(this, init); }
}

export class TotalGamingMinutesByDateResult
{
    public items?: TotalGamingMinutesByDate[];
    public endDate: string;

    public constructor(init?: Partial<TotalGamingMinutesByDateResult>) { (Object as any).assign(this, init); }
}

export class TotalGamingMinutesByIdResult
{
    public items?: TotalGamingMinutesById[];
    public endDate: string;

    public constructor(init?: Partial<TotalGamingMinutesByIdResult>) { (Object as any).assign(this, init); }
}

export class PlaystoreActivityStatistics
{
    public id: number;
    public date: string;
    public country?: string;
    public sourceUrl?: string;
    public platform?: string;
    public productVersion?: string;
    public status?: PlaystoreActivityStatus;
    public apkPackageName?: string;
    public count: number;

    public constructor(init?: Partial<PlaystoreActivityStatistics>) { (Object as any).assign(this, init); }
}

export class PlaystoreActivityStatisticsForPeriod
{
    public items?: PlaystoreActivityForPeriodSummary[];
    public filtersSuggestions?: PlaystoreActivityByPeriodFilterSuggestions;

    public constructor(init?: Partial<PlaystoreActivityStatisticsForPeriod>) { (Object as any).assign(this, init); }
}

export class PlaystoreActivityStatisticsForDate
{
    public items?: PlaystoreActivityForDateSummary[];
    public filtersSuggestions?: PlaystoreActivityByDateFilterSuggestions;

    public constructor(init?: Partial<PlaystoreActivityStatisticsForDate>) { (Object as any).assign(this, init); }
}

export class RequestStatistics
{
    public id: number;
    public date: string;
    public hour: number;
    public path?: string;
    public params?: string;
    public count: number;
    public totalMilliseconds: number;
    public averageMilliseconds: number;
    public standardDeviationMilliseconds: number;
    public averageDistribution?: Distribution;

    public constructor(init?: Partial<RequestStatistics>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId?: string;

    // @DataMember(Order=2)
    public sessionId?: string;

    // @DataMember(Order=3)
    public userName?: string;

    // @DataMember(Order=4)
    public displayName?: string;

    // @DataMember(Order=5)
    public referrerUrl?: string;

    // @DataMember(Order=6)
    public bearerToken?: string;

    // @DataMember(Order=7)
    public refreshToken?: string;

    // @DataMember(Order=8)
    public profileUrl?: string;

    // @DataMember(Order=9)
    public roles?: string[];

    // @DataMember(Order=10)
    public permissions?: string[];

    // @DataMember(Order=11)
    public responseStatus?: ResponseStatus;

    // @DataMember(Order=12)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results?: UserApiKey[];

    // @DataMember(Order=2)
    public meta?: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus?: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results?: UserApiKey[];

    // @DataMember(Order=2)
    public meta?: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus?: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

export class FakeTypeBinding implements IReturn<BindedTypes>
{
    public getPath() { return ''; }

    public constructor(init?: Partial<FakeTypeBinding>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FakeTypeBinding'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BindedTypes(); }
}

// @Route("/ping")
export class Ping implements IReturn<PingResponse>
{
    public getPath() { return '/ping'; }

    public constructor(init?: Partial<Ping>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Ping'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PingResponse(); }
}

// @Route("/import/crawl-applications", "POST")
export class CrawlApplications implements IReturnVoid
{
    public getPath() { return '/import/crawl-applications'; }
    public appIds?: string[];

    public constructor(init?: Partial<CrawlApplications>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CrawlApplications'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/import/crawl-applications", "GET")
export class GetCrawlingResult implements IReturn<CrawlingResult[]>
{
    public getPath() { return '/import/crawl-applications'; }
    public applicationIdsFilter?: string[];

    public constructor(init?: Partial<GetCrawlingResult>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCrawlingResult'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CrawlingResult>(); }
}

// @Route("/import/update-crawling-status", "PUT")
export class UpdateCrawlingStatus implements IReturnVoid
{
    public getPath() { return '/import/update-crawling-status'; }
    public appId?: string;
    public platform: ApkPlatform;
    public status?: CrawlingStatus;
    public availableAtCountries?: string[];
    public errorMessage?: string;

    public constructor(init?: Partial<UpdateCrawlingStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateCrawlingStatus'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/admin/io-application-import", "POST")
export class ImportIoApplication implements IReturn<IoApplication>
{
    public getPath() { return '/admin/io-application-import'; }
    public application?: ImportIoApplicationData;

    public constructor(init?: Partial<ImportIoApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportIoApplication'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IoApplication(); }
}

// @Route("/import/application", "POST")
export class ImportApplication implements IReturnVoid
{
    public getPath() { return '/import/application'; }
    public application?: ImportApplicationData;
    public importStrategy: ImportStrategy;
    public platform: ApkPlatform;
    public lang?: string;
    public apiKey?: string;
    public apiSecret?: string;

    public constructor(init?: Partial<ImportApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportApplication'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/import/applications", "POST")
export class ImportApplications implements IReturnVoid
{
    public getPath() { return '/import/applications'; }
    public applications?: ImportApplicationData[];
    public importStrategy: ImportStrategy;
    public platform: ApkPlatform;
    public lang?: string;
    public apiKey?: string;
    public apiSecret?: string;

    public constructor(init?: Partial<ImportApplications>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportApplications'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/import/categories", "POST")
export class ImportCategories implements IReturnVoid
{
    public getPath() { return '/import/categories'; }
    public categories?: ImportCategoryData[];
    public importStrategy: ImportStrategy;
    public lang?: string;
    public apiKey?: string;
    public apiSecret?: string;

    public constructor(init?: Partial<ImportCategories>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportCategories'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/import/crawl-io-applications", "POST")
export class CrawlIoApplications implements IReturnVoid
{
    public getPath() { return '/import/crawl-io-applications'; }
    public apps?: CrawledIoApplication[];

    public constructor(init?: Partial<CrawlIoApplications>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CrawlIoApplications'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/import/crawl-io-application", "POST")
export class CrawlIoApplication implements IReturn<CrawlIoApplicationResult>
{
    public getPath() { return '/import/crawl-io-application'; }
    public app?: CrawledIoApplication;

    public constructor(init?: Partial<CrawlIoApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CrawlIoApplication'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CrawlIoApplicationResult(); }
}

// @Route("/import/crawl-all-io-applications", "POST")
export class CrawlAllIoApplications implements IReturnVoid
{
    public getPath() { return '/import/crawl-all-io-applications'; }

    public constructor(init?: Partial<CrawlAllIoApplications>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CrawlAllIoApplications'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/import/crawl-io-applications", "GET")
export class GetIoCrawlingResult implements IReturn<CrawlIoApplicationResult[]>
{
    public getPath() { return '/import/crawl-io-applications'; }
    public applicationIdsFilter?: string[];
    public status?: CrawlingStatus;

    public constructor(init?: Partial<GetIoCrawlingResult>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIoCrawlingResult'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CrawlIoApplicationResult>(); }
}

// @Route("/import/update-io-crawling-status", "PUT")
export class UpdateIoCrawlingStatus implements IReturnVoid
{
    public getPath() { return '/import/update-io-crawling-status'; }
    public appId?: string;
    public status?: CrawlingStatus;
    public provider?: string;
    public errorMessage?: string;

    public constructor(init?: Partial<UpdateIoCrawlingStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateIoCrawlingStatus'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

// @Route("/import/io-application-sources", "POST")
export class UpdateIoApplicationSources implements IReturn<IoApplicationSourcesUpdateResult>
{
    public getPath() { return '/import/io-application-sources'; }
    public appSources?: IoApplicationSource[];

    public constructor(init?: Partial<UpdateIoApplicationSources>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateIoApplicationSources'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IoApplicationSourcesUpdateResult(); }
}

// @Route("/import/io-application-sources", "GET")
export class GetIoApplicationSources implements IReturn<IoApplicationSource[]>, ILimitedRequest
{
    public getPath() { return '/import/io-application-sources'; }
    public titleSearch?: string;
    public offset?: number;
    public limit: number;

    public constructor(init?: Partial<GetIoApplicationSources>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIoApplicationSources'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<IoApplicationSource>(); }
}

// @Route("/api387/getApplication", "GET")
export class GetApplication387 implements IReturn<IApplicationDetails387>, IGet
{
    public getPath() { return '/api387/getApplication'; }
    public id?: string;
    public language?: string;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetApplication387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApplication387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return {} as IApplicationDetails387; }
}

// @Route("/api387/getIoApplications", "GET")
export class GetIoApplications387 implements IReturn<ApplicationShortDetails387[]>, ILimitedRequest
{
    public getPath() { return '/api387/getIoApplications'; }
    public tagIdSearch?: string;
    // @Required()
    public categoryIdSearch: string;

    // @Required()
    public language: string;

    public order: ApplicationSortOrder;
    public limit: number;
    public offset?: number;
    public showGraphicsCustomizedOnly?: boolean;

    public constructor(init?: Partial<GetIoApplications387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIoApplications387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails387>(); }
}

// @Route("/api387/getCategoryApplications", "GET")
export class GetCategoryApplications387 implements IReturn<ApplicationShortDetails387[]>, ILimitedRequest
{
    public getPath() { return '/api387/getCategoryApplications'; }
    public categoryId?: string;
    public language?: string;
    public platform: ApkPlatform;
    public order: ApplicationSortOrder;
    public limit: number;
    public offset?: number;
    public showGraphicsCustomizedOnly?: boolean;

    public constructor(init?: Partial<GetCategoryApplications387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategoryApplications387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails387>(); }
}

// @Route("/api387/getCategoryListApplications", "GET")
export class GetCategoryListApplications387 implements IReturn<ApplicationShortDetails387[]>, ILimitedRequest
{
    public getPath() { return '/api387/getCategoryListApplications'; }
    public categoriesSearch?: string[];
    public language?: string;
    public platform: ApkPlatform;
    public order: ApplicationSortOrder;
    public limit: number;
    public offset?: number;
    public showGraphicsCustomizedOnly?: boolean;

    public constructor(init?: Partial<GetCategoryListApplications387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategoryListApplications387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails387>(); }
}

// @Route("/api387/getGameCenterApplications", "POST")
export class GetGameCenterApplications387 implements IReturn<GameCenterApplications387>
{
    public getPath() { return '/api387/getGameCenterApplications'; }
    public hwid?: string;
    public language?: string;
    public featuredApplications?: GameCenterApplicationsLimitedInfo30;
    public categoriesApplications?: { [index: string]: GameCenterApplicationsLimitedInfo30; };
    public continuePlayingApplications?: GameCenterApplicationsLimitedInfo30;
    public similarToRandomPlayedApplications?: GameCenterApplicationsLimitedInfo30;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetGameCenterApplications387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGameCenterApplications387'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GameCenterApplications387(); }
}

// @Route("/api387/getSimilarIoApps", "GET")
export class GetSimilarIoApps387 implements IReturn<ApplicationShortDetails387[]>, ILimitedRequest
{
    public getPath() { return '/api387/getSimilarIoApps'; }
    // @Required()
    public appId: string;

    // @Required()
    public language: string;

    public limit: number;
    public offset?: number;

    public constructor(init?: Partial<GetSimilarIoApps387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSimilarIoApps387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails387>(); }
}

// @Route("/api387/getSimilarGoogleApps", "GET")
export class GetSimilarGoogleApps387 implements IReturn<ApplicationShortDetails387[]>, ILimitedRequest, IGet
{
    public getPath() { return '/api387/getSimilarGoogleApps'; }
    public appId?: string;
    public language?: string;
    public platform: ApkPlatform;
    public order?: ApplicationSortOrder;
    public limit: number;
    public offset?: number;

    public constructor(init?: Partial<GetSimilarGoogleApps387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSimilarGoogleApps387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails387>(); }
}

// @Route("/api387/getRecommendedIoApps", "GET")
export class GetRecommendedIoApps387 implements IReturn<ApplicationShortDetails387[]>, ILimitedRequest
{
    public getPath() { return '/api387/getRecommendedIoApps'; }
    // @Required()
    public language: string;

    public limit: number;
    public offset?: number;

    public constructor(init?: Partial<GetRecommendedIoApps387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRecommendedIoApps387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails387>(); }
}

// @Route("/api387/getBulkApplications", "GET")
export class GetBulkApplications387 implements IReturn<IApplicationDetails387[]>, IGet
{
    public getPath() { return '/api387/getBulkApplications'; }
    public ids?: string[];
    public language?: string;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetBulkApplications387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBulkApplications387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<IApplicationDetails387>(); }
}

// @Route("/api387/getContinuePlayingApplications", "GET")
export class GetContinuePlayingApplications387 implements IReturn<ContinuePlayingApps387>, IGet, ILimitedRequest
{
    public getPath() { return '/api387/getContinuePlayingApplications'; }
    // @Required()
    public hwid: string;

    // @Required()
    public language: string;

    public kind?: ContinuePlayingKind;
    public platform: ApkPlatform;
    public limit: number;
    public offset?: number;

    public constructor(init?: Partial<GetContinuePlayingApplications387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContinuePlayingApplications387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ContinuePlayingApps387(); }
}

// @Route("/api387/getRandomLastPlayedApplication", "GET")
export class GetRandomLastPlayedApplication387 implements IReturn<IApplicationDetails387>, IGet
{
    public getPath() { return '/api387/getRandomLastPlayedApplication'; }
    public hwid?: string;
    public language?: string;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetRandomLastPlayedApplication387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRandomLastPlayedApplication387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return {} as IApplicationDetails387; }
}

// @Route("/api387/getCategory", "GET")
export class GetCategory387 implements IReturn<Category387>
{
    public getPath() { return '/api387/getCategory'; }
    public id?: string;

    public constructor(init?: Partial<GetCategory387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategory387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Category387(); }
}

// @Route("/api387/getCategories", "GET")
export class GetCategories387 implements IReturn<Category387[]>
{
    public getPath() { return '/api387/getCategories'; }

    public constructor(init?: Partial<GetCategories387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategories387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<Category387>(); }
}

// @Route("/api387/search/topApplications", "GET")
export class GetTopSearchApplications387 implements IReturn<ApplicationShortDetails387[]>
{
    public getPath() { return '/api387/search/topApplications'; }
    // @Required()
    public language: string;

    public platform: ApkPlatform;
    public limit: number;

    public constructor(init?: Partial<GetTopSearchApplications387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTopSearchApplications387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails387>(); }
}

// @Route("/api387/search", "GET")
export class Search387 implements IReturn<IApplicationDetails387[]>
{
    public getPath() { return '/api387/search'; }
    // @Required()
    public hwid: string;

    // @Required()
    public text: string;

    // @Required()
    public language: string;

    public platform: ApkPlatform;
    public source?: string;
    public searchDisabledApps?: boolean;
    public searchAppsInDisabledCategories?: boolean;
    public limit: number;

    public constructor(init?: Partial<Search387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Search387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<IApplicationDetails387>(); }
}

// @Route("/api387/getIoTags", "GET")
export class GetIoTags387 implements IReturn<Tag387[]>
{
    public getPath() { return '/api387/getIoTags'; }

    public constructor(init?: Partial<GetIoTags387>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIoTags387'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<Tag387>(); }
}

// @Route("/api371/getApplication", "GET")
export class GetApplication371 implements IReturn<IApplicationDetails371>, IGet
{
    public getPath() { return '/api371/getApplication'; }
    public id?: string;
    public language?: string;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetApplication371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApplication371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return {} as IApplicationDetails371; }
}

// @Route("/api371/getBulkApplications", "GET")
export class GetBulkApplications371 implements IReturn<IApplicationDetails371[]>, IGet
{
    public getPath() { return '/api371/getBulkApplications'; }
    public ids?: string[];
    public language?: string;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetBulkApplications371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBulkApplications371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<IApplicationDetails371>(); }
}

// @Route("/api371/getDisabledApplications", "GET")
export class GetDisabledApplications371 implements IReturn<string[]>
{
    public getPath() { return '/api371/getDisabledApplications'; }
    public ids?: string[];

    public constructor(init?: Partial<GetDisabledApplications371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDisabledApplications371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/api371/getCategoryApplications", "GET")
export class GetCategoryApplications371 implements IReturn<ApplicationShortDetails371[]>, ILimitedRequest
{
    public getPath() { return '/api371/getCategoryApplications'; }
    public categoryId?: string;
    public language?: string;
    public platform: ApkPlatform;
    public order: ApplicationSortOrder;
    public limit: number;
    public offset?: number;
    public showGraphicsCustomizedOnly?: boolean;

    public constructor(init?: Partial<GetCategoryApplications371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategoryApplications371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails371>(); }
}

// @Route("/api371/gameslol/getCategoryApplications", "GET")
export class GetGamesLolCategoryApplications371 implements IReturn<IApplicationDetails371[]>, ILimitedRequest
{
    public getPath() { return '/api371/gameslol/getCategoryApplications'; }
    public categoryId?: string;
    public language?: string;
    public platform: ApkPlatform;
    public order: ApplicationSortOrder;
    public limit: number;
    public offset?: number;
    public showGraphicsCustomizedOnly?: boolean;

    public constructor(init?: Partial<GetGamesLolCategoryApplications371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGamesLolCategoryApplications371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<IApplicationDetails371>(); }
}

// @Route("/api371/getCategoryListApplications", "GET")
export class GetCategoryListApplications371 implements IReturn<ApplicationShortDetails371[]>, ILimitedRequest
{
    public getPath() { return '/api371/getCategoryListApplications'; }
    public categoriesSearch?: string[];
    public language?: string;
    public platform: ApkPlatform;
    public order: ApplicationSortOrder;
    public limit: number;
    public offset?: number;
    public showGraphicsCustomizedOnly?: boolean;

    public constructor(init?: Partial<GetCategoryListApplications371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategoryListApplications371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails371>(); }
}

// @Route("/api371/getSimilarApplications", "GET")
export class GetSimilarApplications371 implements IReturn<ApplicationShortDetails371[]>, ILimitedRequest, IGet
{
    public getPath() { return '/api371/getSimilarApplications'; }
    public appId?: string;
    public language?: string;
    public platform: ApkPlatform;
    public order?: ApplicationSortOrder;
    public limit: number;
    public offset?: number;

    public constructor(init?: Partial<GetSimilarApplications371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSimilarApplications371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails371>(); }
}

// @Route("/api371/getContinuePlayingApplications", "GET")
export class GetContinuePlayingApplications371 implements IReturn<ContinuePlayingApps371>, IGet, ILimitedRequest
{
    public getPath() { return '/api371/getContinuePlayingApplications'; }
    // @Required()
    public hwid: string;

    // @Required()
    public language: string;

    public kind?: ContinuePlayingKind;
    public platform: ApkPlatform;
    public limit: number;
    public offset?: number;

    public constructor(init?: Partial<GetContinuePlayingApplications371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContinuePlayingApplications371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ContinuePlayingApps371(); }
}

// @Route("/api371/getFeaturedApplications", "GET")
export class GetFeaturedApplications371 implements IReturn<FeaturedApplication371[]>, IGet
{
    public getPath() { return '/api371/getFeaturedApplications'; }
    public language?: string;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetFeaturedApplications371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFeaturedApplications371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<FeaturedApplication371>(); }
}

// @Route("/api371/getRandomLastPlayedApplication", "GET")
export class GetRandomLastPlayedApplication371 implements IReturn<IApplicationDetails371>, IGet
{
    public getPath() { return '/api371/getRandomLastPlayedApplication'; }
    public hwid?: string;
    public language?: string;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetRandomLastPlayedApplication371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRandomLastPlayedApplication371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return {} as IApplicationDetails371; }
}

// @Route("/api371/getGameCenterCategories", "GET")
export class GetGameCenterCategories371 implements IReturn<GameCenterCategories30>
{
    public getPath() { return '/api371/getGameCenterCategories'; }

    public constructor(init?: Partial<GetGameCenterCategories371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGameCenterCategories371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GameCenterCategories30(); }
}

// @Route("/api371/getGameCenterApplications", "POST")
export class GetGameCenterApplications371 implements IReturn<GameCenterApplications371>
{
    public getPath() { return '/api371/getGameCenterApplications'; }
    public hwid?: string;
    public language?: string;
    public featuredApplications?: GameCenterApplicationsLimitedInfo30;
    public categoriesApplications?: { [index: string]: GameCenterApplicationsLimitedInfo30; };
    public continuePlayingApplications?: GameCenterApplicationsLimitedInfo30;
    public similarToRandomPlayedApplications?: GameCenterApplicationsLimitedInfo30;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetGameCenterApplications371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGameCenterApplications371'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GameCenterApplications371(); }
}

// @Route("/api371/getAppJson/{Language}/{AppId}", "GET")
export class GetAppJson371 implements IReturn<ApplicationSettings371>, IGet
{
    public getPath() { return '/api371/getAppJson/{Language}/{AppId}'; }
    public appId?: string;
    public language?: string;
    public source?: string;
    public hwid?: string;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetAppJson371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAppJson371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ApplicationSettings371(); }
}

// @Route("/api371/getCategory", "GET")
export class GetCategory371 implements IReturn<Category30>
{
    public getPath() { return '/api371/getCategory'; }
    public id?: string;

    public constructor(init?: Partial<GetCategory371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategory371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Category30(); }
}

// @Route("/api371/getSubcategories", "GET")
export class GetSubcategories371 implements IReturn<Category30[]>
{
    public getPath() { return '/api371/getSubcategories'; }
    public parentCategoryId?: string;

    public constructor(init?: Partial<GetSubcategories371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSubcategories371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<Category30>(); }
}

// @Route("/api371/search", "GET")
export class Search371 implements IReturn<IApplicationDetails371[]>
{
    public getPath() { return '/api371/search'; }
    // @Required()
    public hwid: string;

    // @Required()
    public text: string;

    // @Required()
    public language: string;

    public platform: ApkPlatform;
    public source?: string;
    public searchDisabledApps?: boolean;
    public searchAppsInDisabledCategories?: boolean;
    public limit: number;

    public constructor(init?: Partial<Search371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Search371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<IApplicationDetails371>(); }
}

// @Route("/api371/search/topApplications", "GET")
export class GetTopSearchApplications371 implements IReturn<ApplicationShortDetails371[]>
{
    public getPath() { return '/api371/search/topApplications'; }
    // @Required()
    public language: string;

    public platform: ApkPlatform;
    public limit: number;

    public constructor(init?: Partial<GetTopSearchApplications371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTopSearchApplications371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails371>(); }
}

// @Route("/api371/search/suggestions", "GET")
export class GetSearchSuggestions371 implements IReturn<SearchSuggestions30>
{
    public getPath() { return '/api371/search/suggestions'; }
    // @Required()
    public hwid: string;

    // @Required()
    public language: string;

    // @Required()
    public text: string;

    public suggestionLimit: number;

    public constructor(init?: Partial<GetSearchSuggestions371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSearchSuggestions371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SearchSuggestions30(); }
}

// @Route("/api371/search/history", "GET")
export class GetSearchHistory371 implements IReturn<string[]>, IGetSearchHistoryRequest
{
    public getPath() { return '/api371/search/history'; }
    // @Required()
    public hwid: string;

    public limit: number;

    public constructor(init?: Partial<GetSearchHistory371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSearchHistory371'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/api371/search/clear", "DELETE")
export class ClearSearchHistory371 implements IReturnVoid, IClearSearchHistoryRequest
{
    public getPath() { return '/api371/search/clear'; }
    // @Required()
    public hwid: string;

    public constructor(init?: Partial<ClearSearchHistory371>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClearSearchHistory371'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api30/getAdBrowserExtensions", "GET")
export class GetAdBrowserExtensions30 implements IReturn<AdBrowserExtension30[]>
{
    public getPath() { return '/api30/getAdBrowserExtensions'; }

    public constructor(init?: Partial<GetAdBrowserExtensions30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdBrowserExtensions30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdBrowserExtension30>(); }
}

// @Route("/api30/getHasAnyInstalledExtension", "GET")
export class GetHasAnyInstalledExtension30 implements IReturn<boolean>
{
    public getPath() { return '/api30/getHasAnyInstalledExtension'; }
    public installedExtensionIds?: string[];

    public constructor(init?: Partial<GetHasAnyInstalledExtension30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetHasAnyInstalledExtension30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return false; }
}

// @Route("/api30/getAdCampaigns", "GET")
export class GetAdCampaigns30 implements IReturn<AdCampaign30[]>
{
    public getPath() { return '/api30/getAdCampaigns'; }
    public source?: string;

    public constructor(init?: Partial<GetAdCampaigns30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdCampaigns30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdCampaign30>(); }
}

// @Route("/api31/getAdCampaigns", "GET")
export class GetAdCampaigns31 implements IReturn<AdCampaign31[]>, IGet
{
    public getPath() { return '/api31/getAdCampaigns'; }
    public source?: string;

    public constructor(init?: Partial<GetAdCampaigns31>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdCampaigns31'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdCampaign31>(); }
}

// @Route("/api375/getAdCampaigns", "GET")
export class GetAdCampaigns375 implements IReturn<AdCampaign30[]>
{
    public getPath() { return '/api375/getAdCampaigns'; }
    public source?: string;

    public constructor(init?: Partial<GetAdCampaigns375>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdCampaigns375'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdCampaign30>(); }
}

// @Route("/api30/checkAdBundleOffer", "GET")
export class CheckAdBundleOffer30 implements IReturn<CheckAdBundleOfferResult30>
{
    public getPath() { return '/api30/checkAdBundleOffer'; }
    // @Required()
    public applicationId: string;

    // @Required()
    public hwid: string;

    public constructor(init?: Partial<CheckAdBundleOffer30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CheckAdBundleOffer30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CheckAdBundleOfferResult30(); }
}

// @Route("/api30/getAdBundleOffer", "GET")
export class GetAdBundleOffer30 implements IReturn<AdBundleOffer30>
{
    public getPath() { return '/api30/getAdBundleOffer'; }
    // @Required()
    public applicationId: string;

    // @Required()
    public hwid: string;

    public constructor(init?: Partial<GetAdBundleOffer30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdBundleOffer30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdBundleOffer30(); }
}

// @Route("/api30/applicationKeyBindings", "GET")
export class GetApplicationKeyBindings30 implements IReturn<ApplicationKeyBindings30>
{
    public getPath() { return '/api30/applicationKeyBindings'; }
    // @Required()
    public applicationId: string;

    // @Required()
    public hwid: string;

    public constructor(init?: Partial<GetApplicationKeyBindings30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApplicationKeyBindings30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ApplicationKeyBindings30(); }
}

// @Route("/api30/applicationKeyBindings", "POST")
export class SaveApplicationKeyBindings30 implements IReturnVoid
{
    public getPath() { return '/api30/applicationKeyBindings'; }
    // @Required()
    public applicationId: string;

    // @Required()
    public hwid: string;

    public keyBindings?: EmulatorOption;

    public constructor(init?: Partial<SaveApplicationKeyBindings30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaveApplicationKeyBindings30'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/api30/applicationKeyBindings", "DELETE")
export class DeleteApplicationKeyBindings30 implements IReturnVoid
{
    public getPath() { return '/api30/applicationKeyBindings'; }
    // @Required()
    public applicationId: string;

    // @Required()
    public hwid: string;

    public constructor(init?: Partial<DeleteApplicationKeyBindings30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteApplicationKeyBindings30'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api30/getApplication", "GET")
export class GetApplication30 implements IReturn<ApplicationDetails30>, IGet
{
    public getPath() { return '/api30/getApplication'; }
    public id?: string;
    public language?: string;

    public constructor(init?: Partial<GetApplication30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApplication30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ApplicationDetails30(); }
}

// @Route("/api30/getBulkApplications", "GET")
export class GetBulkApplications30 implements IReturn<ApplicationDetails30[]>, IGet
{
    public getPath() { return '/api30/getBulkApplications'; }
    public ids?: string[];
    public language?: string;

    public constructor(init?: Partial<GetBulkApplications30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBulkApplications30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationDetails30>(); }
}

// @Route("/api30/getDisabledApplications", "GET")
export class GetDisabledApplications30 implements IReturn<string[]>, IGet
{
    public getPath() { return '/api30/getDisabledApplications'; }
    public ids?: string[];

    public constructor(init?: Partial<GetDisabledApplications30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDisabledApplications30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/api30/getCategoryApplications", "GET")
export class GetCategoryApplications30 implements IReturn<ApplicationShortDetails30[]>, ILimitedRequest
{
    public getPath() { return '/api30/getCategoryApplications'; }
    public categoryId?: string;
    public language?: string;
    public order: ApplicationSortOrder;
    public limit: number;
    public offset?: number;
    public showGraphicsCustomizedOnly?: boolean;

    public constructor(init?: Partial<GetCategoryApplications30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategoryApplications30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails30>(); }
}

// @Route("/api30/gameslol/getCategoryApplications", "GET")
export class GetGamesLolCategoryApplications30 implements IReturn<ApplicationDetails30[]>, ILimitedRequest
{
    public getPath() { return '/api30/gameslol/getCategoryApplications'; }
    public categoryId?: string;
    public language?: string;
    public order: ApplicationSortOrder;
    public limit: number;
    public offset?: number;
    public showDisabled?: boolean;
    public showGraphicsCustomizedOnly?: boolean;

    public constructor(init?: Partial<GetGamesLolCategoryApplications30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGamesLolCategoryApplications30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationDetails30>(); }
}

// @Route("/api30/getApplicationsWithCustomGraphic", "GET")
export class GetApplicationsWithCustomGraphic30 implements IReturn<ApplicationShortDetails30[]>, ILimitedRequest
{
    public getPath() { return '/api30/getApplicationsWithCustomGraphic'; }
    public categoriesSearch?: string[];
    public language?: string;
    public order: ApplicationSortOrder;
    public limit: number;
    public offset?: number;
    public isGraphicsCustomized?: boolean;

    public constructor(init?: Partial<GetApplicationsWithCustomGraphic30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApplicationsWithCustomGraphic30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails30>(); }
}

// @Route("/api30/getSimilarApplications", "GET")
export class GetSimilarApplications30 implements IReturn<ApplicationDetails30[]>, ILimitedRequest
{
    public getPath() { return '/api30/getSimilarApplications'; }
    public appId?: string;
    public language?: string;
    public order?: ApplicationSortOrder;
    public limit: number;
    public offset?: number;

    public constructor(init?: Partial<GetSimilarApplications30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSimilarApplications30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationDetails30>(); }
}

// @Route("/api30/getContinuePlayingApplications", "GET")
export class GetContinuePlayingApplications30 implements IReturn<ContinuePlaying30>, ILimitedRequest
{
    public getPath() { return '/api30/getContinuePlayingApplications'; }
    // @Required()
    public hwid: string;

    // @Required()
    public language: string;

    public kind?: ContinuePlayingKind;
    public limit: number;
    public offset?: number;

    public constructor(init?: Partial<GetContinuePlayingApplications30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContinuePlayingApplications30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ContinuePlaying30(); }
}

// @Route("/api30/getFeaturedApplications", "GET")
export class GetFeaturedApplications30 implements IReturn<FeaturedApplication30[]>, IGet
{
    public getPath() { return '/api30/getFeaturedApplications'; }
    public language?: string;

    public constructor(init?: Partial<GetFeaturedApplications30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFeaturedApplications30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<FeaturedApplication30>(); }
}

// @Route("/api30/getRandomLastPlayedApplication", "GET")
export class GetRandomLastPlayedApplication30 implements IReturn<ApplicationDetails30>
{
    public getPath() { return '/api30/getRandomLastPlayedApplication'; }
    public hwid?: string;
    public language?: string;

    public constructor(init?: Partial<GetRandomLastPlayedApplication30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRandomLastPlayedApplication30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ApplicationDetails30(); }
}

// @Route("/api30/getGameCenterCategories", "GET")
export class GetGameCenterCategories30 implements IReturn<GameCenterCategories30>
{
    public getPath() { return '/api30/getGameCenterCategories'; }

    public constructor(init?: Partial<GetGameCenterCategories30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGameCenterCategories30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GameCenterCategories30(); }
}

// @Route("/api30/getGameCenterApplications30", "POST")
export class GetGameCenterApplications30 implements IReturn<GameCenterApplications30>
{
    public getPath() { return '/api30/getGameCenterApplications30'; }
    public hwid?: string;
    public language?: string;
    public featuredApplications?: GameCenterApplicationsLimitedInfo30;
    public categoriesApplications?: { [index: string]: GameCenterApplicationsLimitedInfo30; };
    public continuePlayingApplications?: GameCenterApplicationsLimitedInfo30;
    public similarToRandomPlayedApplications?: GameCenterApplicationsLimitedInfo30;

    public constructor(init?: Partial<GetGameCenterApplications30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGameCenterApplications30'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GameCenterApplications30(); }
}

// @Route("/api30/getAppJsonSecured/{Language}/{AppId}", "GET")
export class GetAppJsonSecured30 implements IGet
{
    public appId?: string;
    public language?: string;
    public source?: string;
    public hwid?: string;

    public constructor(init?: Partial<GetAppJsonSecured30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAppJsonSecured30'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/api30/getAppJson/{Language}/{AppId}", "GET")
export class GetAppJson30 implements IGet
{
    public appId?: string;
    public language?: string;
    public source?: string;
    public hwid?: string;
    public imgWidth?: number;
    public imgHeight?: number;

    public constructor(init?: Partial<GetAppJson30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAppJson30'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/api364/getApplication", "GET")
export class GetApplication364 implements IReturn<ApplicationDetails30>
{
    public getPath() { return '/api364/getApplication'; }
    public id?: string;
    public language?: string;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetApplication364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApplication364'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ApplicationDetails30(); }
}

// @Route("/api364/getBulkApplications", "GET")
export class GetBulkApplications364 implements IReturn<ApplicationDetails30[]>, IGet
{
    public getPath() { return '/api364/getBulkApplications'; }
    public ids?: string[];
    public language?: string;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetBulkApplications364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetBulkApplications364'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationDetails30>(); }
}

// @Route("/api364/getCategoryApplications", "GET")
export class GetCategoryApplications364 implements IReturn<ApplicationShortDetails30[]>, ILimitedRequest
{
    public getPath() { return '/api364/getCategoryApplications'; }
    public categoryId?: string;
    public language?: string;
    public platform: ApkPlatform;
    public order: ApplicationSortOrder;
    public limit: number;
    public offset?: number;
    public showGraphicsCustomizedOnly?: boolean;

    public constructor(init?: Partial<GetCategoryApplications364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategoryApplications364'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails30>(); }
}

// @Route("/api364/gameslol/getCategoryApplications", "GET")
export class GetGamesLolCategoryApplications364 implements IReturn<ApplicationDetails30[]>, ILimitedRequest
{
    public getPath() { return '/api364/gameslol/getCategoryApplications'; }
    public categoryId?: string;
    public language?: string;
    public order: ApplicationSortOrder;
    public platform: ApkPlatform;
    public limit: number;
    public offset?: number;
    public showDisabled?: boolean;
    public showGraphicsCustomizedOnly?: boolean;

    public constructor(init?: Partial<GetGamesLolCategoryApplications364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGamesLolCategoryApplications364'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationDetails30>(); }
}

// @Route("/api364/getSimilarApplications", "GET")
export class GetSimilarApplications364 implements IReturn<ApplicationDetails30[]>, ILimitedRequest
{
    public getPath() { return '/api364/getSimilarApplications'; }
    public appId?: string;
    public language?: string;
    public order?: ApplicationSortOrder;
    public platform: ApkPlatform;
    public limit: number;
    public offset?: number;

    public constructor(init?: Partial<GetSimilarApplications364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSimilarApplications364'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationDetails30>(); }
}

// @Route("/api364/getContinuePlayingApplications", "GET")
export class GetContinuePlayingApplications364 implements IReturn<ContinuePlaying30>, ILimitedRequest
{
    public getPath() { return '/api364/getContinuePlayingApplications'; }
    // @Required()
    public hwid: string;

    // @Required()
    public language: string;

    // @Required()
    public platform: ApkPlatform;

    public kind?: ContinuePlayingKind;
    public limit: number;
    public offset?: number;

    public constructor(init?: Partial<GetContinuePlayingApplications364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetContinuePlayingApplications364'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ContinuePlaying30(); }
}

// @Route("/api364/getFeaturedApplications", "GET")
export class GetFeaturedApplications364 implements IReturn<FeaturedApplication30[]>
{
    public getPath() { return '/api364/getFeaturedApplications'; }
    public language?: string;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetFeaturedApplications364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFeaturedApplications364'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<FeaturedApplication30>(); }
}

// @Route("/api364/getRandomLastPlayedApplication", "GET")
export class GetRandomLastPlayedApplication364 implements IReturn<ApplicationDetails30>
{
    public getPath() { return '/api364/getRandomLastPlayedApplication'; }
    public platform: ApkPlatform;
    public hwid?: string;
    public language?: string;

    public constructor(init?: Partial<GetRandomLastPlayedApplication364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRandomLastPlayedApplication364'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ApplicationDetails30(); }
}

// @Route("/api364/getGameCenterApplications", "POST")
export class GetGameCenterApplications364 implements IReturn<GameCenterApplications30>
{
    public getPath() { return '/api364/getGameCenterApplications'; }
    public hwid?: string;
    public language?: string;
    public featuredApplications?: GameCenterApplicationsLimitedInfo30;
    public categoriesApplications?: { [index: string]: GameCenterApplicationsLimitedInfo30; };
    public continuePlayingApplications?: GameCenterApplicationsLimitedInfo30;
    public similarToRandomPlayedApplications?: GameCenterApplicationsLimitedInfo30;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetGameCenterApplications364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGameCenterApplications364'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GameCenterApplications30(); }
}

// @Route("/api364/getAppJsonSecured/{Language}/{AppId}", "GET")
export class GetAppJsonSecured364 implements IGet
{
    public appId?: string;
    public language?: string;
    public source?: string;
    public hwid?: string;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetAppJsonSecured364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAppJsonSecured364'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/api364/getAppJson/{Language}/{AppId}", "GET")
export class GetAppJson364 implements IGet
{
    public appId?: string;
    public language?: string;
    public source?: string;
    public hwid?: string;
    public imgWidth?: number;
    public imgHeight?: number;
    public platform: ApkPlatform;

    public constructor(init?: Partial<GetAppJson364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAppJson364'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/api30/getCategory", "GET")
export class GetCategory30 implements IReturn<Category30>
{
    public getPath() { return '/api30/getCategory'; }
    public id?: string;

    public constructor(init?: Partial<GetCategory30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategory30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Category30(); }
}

// @Route("/api30/getSubcategories", "GET")
export class GetSubcategories30 implements IReturn<Category30[]>
{
    public getPath() { return '/api30/getSubcategories'; }
    public parentCategoryId?: string;

    public constructor(init?: Partial<GetSubcategories30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSubcategories30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<Category30>(); }
}

// @Route("/api30/getServerVersion", "GET")
export class GetServerVersion30 implements IReturn<ServerVersionDetails30>
{
    public getPath() { return '/api30/getServerVersion'; }
    public source?: string;
    public hwid?: string;
    public clientVersion?: string;
    public platform: ClientSystemPlatform;
    public os: ClientOperatingSystem;

    public constructor(init?: Partial<GetServerVersion30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetServerVersion30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ServerVersionDetails30(); }
}

// @Route("/api30/getReleaseNotes", "GET")
export class GetReleaseNotes30 implements IReturn<ReleaseNotes30[]>
{
    public getPath() { return '/api30/getReleaseNotes'; }
    public clientVersion?: string;
    public serverVersion?: string;
    public source?: string;
    public hwid?: string;

    public constructor(init?: Partial<GetReleaseNotes30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetReleaseNotes30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ReleaseNotes30>(); }
}

// @Route("/api30/search", "GET")
export class Search30 implements IReturn<ApplicationDetails30[]>
{
    public getPath() { return '/api30/search'; }
    // @Required()
    public hwid: string;

    // @Required()
    public text: string;

    // @Required()
    public language: string;

    public source?: string;
    public searchDisabledApps?: boolean;
    public searchAppsInDisabledCategories?: boolean;
    public limit: number;

    public constructor(init?: Partial<Search30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Search30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationDetails30>(); }
}

// @Route("/api31/search", "GET")
export class Search31 implements IReturn<ApplicationDetails30[]>
{
    public getPath() { return '/api31/search'; }
    // @Required()
    public hwid: string;

    // @Required()
    public text: string;

    // @Required()
    public language: string;

    public source?: string;
    public searchDisabledApps?: boolean;
    public searchAppsInDisabledCategories?: boolean;
    public limit: number;

    public constructor(init?: Partial<Search31>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Search31'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationDetails30>(); }
}

// @Route("/api30/search/suggestions", "GET")
export class GetSearchSuggestions30 implements IReturn<SearchSuggestions30>
{
    public getPath() { return '/api30/search/suggestions'; }
    // @Required()
    public hwid: string;

    // @Required()
    public language: string;

    // @Required()
    public text: string;

    public suggestionLimit: number;

    public constructor(init?: Partial<GetSearchSuggestions30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSearchSuggestions30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SearchSuggestions30(); }
}

// @Route("/api30/search/topApplications", "GET")
export class GetTopSearchApplications30 implements IReturn<ApplicationShortDetails30[]>
{
    public getPath() { return '/api30/search/topApplications'; }
    // @Required()
    public language: string;

    public limit: number;

    public constructor(init?: Partial<GetTopSearchApplications30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTopSearchApplications30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails30>(); }
}

// @Route("/api30/search/history", "GET")
export class GetSearchHistory30 implements IReturn<string[]>, IGetSearchHistoryRequest
{
    public getPath() { return '/api30/search/history'; }
    // @Required()
    public hwid: string;

    public limit: number;

    public constructor(init?: Partial<GetSearchHistory30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSearchHistory30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/api30/search/clear", "DELETE")
export class ClearSearchHistory30 implements IReturnVoid, IClearSearchHistoryRequest
{
    public getPath() { return '/api30/search/clear'; }
    // @Required()
    public hwid: string;

    public constructor(init?: Partial<ClearSearchHistory30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ClearSearchHistory30'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/api364/search", "GET")
export class Search364 implements IReturn<ApplicationDetails30[]>
{
    public getPath() { return '/api364/search'; }
    // @Required()
    public hwid: string;

    // @Required()
    public text: string;

    // @Required()
    public language: string;

    public platform: ApkPlatform;
    public source?: string;
    public searchDisabledApps?: boolean;
    public searchAppsInDisabledCategories?: boolean;
    public limit: number;

    public constructor(init?: Partial<Search364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Search364'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationDetails30>(); }
}

// @Route("/api364/search/topApplications", "GET")
export class GetTopSearchApplications364 implements IReturn<ApplicationShortDetails30[]>
{
    public getPath() { return '/api364/search/topApplications'; }
    // @Required()
    public language: string;

    public platform: ApkPlatform;
    public limit: number;

    public constructor(init?: Partial<GetTopSearchApplications364>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTopSearchApplications364'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ApplicationShortDetails30>(); }
}

// @Route("/api30/getSourceSettingsData", "GET")
export class GetSourceSettingsData30 implements IReturn<SourceSettingsData30>, IGet
{
    public getPath() { return '/api30/getSourceSettingsData'; }
    public source?: string;

    public constructor(init?: Partial<GetSourceSettingsData30>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSourceSettingsData30'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceSettingsData30(); }
}

// @Route("/admin/frontend-settings", "GET")
export class GetFrontendSettings implements IReturn<FrontendSettingsResult>
{
    public getPath() { return '/admin/frontend-settings'; }

    public constructor(init?: Partial<GetFrontendSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFrontendSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FrontendSettingsResult(); }
}

// @Route("/admin/user-grid-settings", "POST")
export class ManageUserGridSettings implements IReturn<UserFrontendSettings>
{
    public getPath() { return '/admin/user-grid-settings'; }
    public gridName?: string;
    public breakpoint?: string;
    public visibleColumns?: string[];

    public constructor(init?: Partial<ManageUserGridSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageUserGridSettings'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UserFrontendSettings(); }
}

// @Route("/admin/google-crawling-result-available-countries", "GET")
export class GetGoogleCrawlingResultAvailableCountries implements IReturn<string[]>
{
    public getPath() { return '/admin/google-crawling-result-available-countries'; }

    public constructor(init?: Partial<GetGoogleCrawlingResultAvailableCountries>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGoogleCrawlingResultAvailableCountries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/google-crawling-result", "GET")
export class GetGoogleCrawlingResults implements IReturn<CrawlingResultSummary[]>, ILimitedRequest
{
    public getPath() { return '/admin/google-crawling-result'; }
    public appIdSearch?: string;
    public countrySearch?: string[];
    public status32Search?: CrawlingStatus[];
    public status64Search?: CrawlingStatus[];
    public error32Search?: string;
    public error64Search?: string;
    public limit: number;
    public offset?: number;
    public orderBy: GoogleCrawlingResultsOrderBy;
    public orderDirection: OrderDirection;
    public addedAtStartDateSearch?: string;
    public addedAtEndDateSearch?: string;
    public updatedAtStartDateSearch?: string;
    public updatedAtEndDateSearch?: string;

    public constructor(init?: Partial<GetGoogleCrawlingResults>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGoogleCrawlingResults'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CrawlingResultSummary>(); }
}

// @Route("/admin/ad-browser-extension", "GET")
export class GetAdBrowserExtension implements IReturn<AdBrowserExtension>
{
    public getPath() { return '/admin/ad-browser-extension'; }
    public id?: string;

    public constructor(init?: Partial<GetAdBrowserExtension>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdBrowserExtension'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdBrowserExtension(); }
}

// @Route("/admin/ad-browser-extensions", "GET")
export class GetAdBrowserExtensions implements IReturn<AdBrowserExtension[]>
{
    public getPath() { return '/admin/ad-browser-extensions'; }

    public constructor(init?: Partial<GetAdBrowserExtensions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdBrowserExtensions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdBrowserExtension>(); }
}

// @Route("/admin/ad-browser-extensions/import", "POST")
export class ImportAdBrowserExtensions implements IReturn<AdBrowserExtension[]>
{
    public getPath() { return '/admin/ad-browser-extensions/import'; }

    public constructor(init?: Partial<ImportAdBrowserExtensions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImportAdBrowserExtensions'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<AdBrowserExtension>(); }
}

// @Route("/admin/ad-browser-extension", "POST,PUT")
export class ManageAdBrowserExtension implements IReturn<AdBrowserExtension>
{
    public getPath() { return '/admin/ad-browser-extension'; }
    public adBrowserExtension?: AdBrowserExtension;

    public constructor(init?: Partial<ManageAdBrowserExtension>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageAdBrowserExtension'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdBrowserExtension(); }
}

// @Route("/admin/ad-browser-extension/delete", "DELETE")
export class DeleteAdBrowserExtension implements IReturnVoid
{
    public getPath() { return '/admin/ad-browser-extension/delete'; }
    public id: number;

    public constructor(init?: Partial<DeleteAdBrowserExtension>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteAdBrowserExtension'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/ad-campaign", "GET")
export class GetAdCampaign implements IReturn<AdCampaign>
{
    public getPath() { return '/admin/ad-campaign'; }
    public id: number;

    public constructor(init?: Partial<GetAdCampaign>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdCampaign'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdCampaign(); }
}

export class GetAdTargeting implements IReturn<AdTargeting[]>
{
    public getPath() { return ''; }

    public constructor(init?: Partial<GetAdTargeting>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdTargeting'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdTargeting>(); }
}

// @Route("/admin/ad-campaigns", "GET")
export class GetAdCampaigns implements IReturn<AdCampaign[]>
{
    public getPath() { return '/admin/ad-campaigns'; }
    public orderBy: AdCampaignListDataType;
    public orderDirection: OrderDirection;
    public nameSearch?: string;
    public disabledSearch?: boolean;
    public includeCampaignWithAllSourcesSearch?: boolean;
    public sourceSearch?: number[];

    public constructor(init?: Partial<GetAdCampaigns>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdCampaigns'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdCampaign>(); }
}

// @Route("/admin/ad-campaign", "POST,PUT")
export class ManageAdCampaign implements IReturn<AdCampaign>
{
    public getPath() { return '/admin/ad-campaign'; }
    public adCampaign?: AdCampaign;
    public sourceSettingsIds?: number[];

    public constructor(init?: Partial<ManageAdCampaign>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageAdCampaign'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdCampaign(); }
}

// @Route("/admin/ad-campaign/delete", "DELETE")
export class DeleteAdCampaign implements IReturnVoid
{
    public getPath() { return '/admin/ad-campaign/delete'; }
    public id: number;

    public constructor(init?: Partial<DeleteAdCampaign>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteAdCampaign'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/ad-unit", "GET")
export class GetAdUnit implements IReturn<AdUnit>
{
    public getPath() { return '/admin/ad-unit'; }
    public id: number;

    public constructor(init?: Partial<GetAdUnit>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdUnit'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdUnit(); }
}

// @Route("/admin/ad-unit-types", "GET")
export class GetAdUnitTypes implements IReturn<AdUnitTypes[]>
{
    public getPath() { return '/admin/ad-unit-types'; }

    public constructor(init?: Partial<GetAdUnitTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdUnitTypes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdUnitTypes>(); }
}

// @Route("/admin/ad-unit-trigger-list", "GET")
export class GetAllAdUnitTriggers implements IReturn<AdUnitTriggerType[]>
{
    public getPath() { return '/admin/ad-unit-trigger-list'; }

    public constructor(init?: Partial<GetAllAdUnitTriggers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAllAdUnitTriggers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdUnitTriggerType>(); }
}

// @Route("/admin/ad-units", "GET")
export class GetAdUnits implements IReturn<AdUnit[]>
{
    public getPath() { return '/admin/ad-units'; }
    public adCampaignId: number;

    public constructor(init?: Partial<GetAdUnits>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdUnits'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdUnit>(); }
}

// @Route("/admin/ad-units-summaries", "GET")
export class GetAdUnitSummaries implements IReturn<AdUnitSummary[]>, ILimitedRequest
{
    public getPath() { return '/admin/ad-units-summaries'; }
    public offset?: number;
    public limit: number;
    public orderBy: AdUnitListDataType;
    public orderDirection: OrderDirection;
    public campaignNameSearch?: string;
    public campaignDisabledSearch?: boolean;
    public typeSearch?: string[];
    public triggerEventSearch?: string[];
    public dimensionByAdBannerSizeIdsSearch?: number[];
    public includeNonStandardSizesToDimensionSearch?: boolean;
    public disabledSearch?: boolean;

    public constructor(init?: Partial<GetAdUnitSummaries>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdUnitSummaries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdUnitSummary>(); }
}

// @Route("/admin/ad-banner-sizes", "GET")
export class GetAdBannerSizes implements IReturn<AdBannerSize[]>
{
    public getPath() { return '/admin/ad-banner-sizes'; }

    public constructor(init?: Partial<GetAdBannerSizes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdBannerSizes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdBannerSize>(); }
}

// @Route("/admin/ad-unit", "POST,PUT")
export class ManageAdUnit implements IReturn<AdUnit>
{
    public getPath() { return '/admin/ad-unit'; }
    public adUnit?: AdUnit;

    public constructor(init?: Partial<ManageAdUnit>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageAdUnit'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdUnit(); }
}

// @Route("/admin/ad-unit/delete", "DELETE")
export class DeleteAdUnit implements IReturnVoid
{
    public getPath() { return '/admin/ad-unit/delete'; }
    public id: number;

    public constructor(init?: Partial<DeleteAdUnit>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteAdUnit'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/ad-unit-trigger", "GET")
export class GetAdUnitTrigger implements IReturn<AdUnitTrigger>
{
    public getPath() { return '/admin/ad-unit-trigger'; }
    public id: number;

    public constructor(init?: Partial<GetAdUnitTrigger>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdUnitTrigger'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdUnitTrigger(); }
}

// @Route("/admin/ad-unit-triggers", "GET")
export class GetAdUnitTriggers implements IReturn<AdUnitTrigger[]>
{
    public getPath() { return '/admin/ad-unit-triggers'; }
    public unitId: number;

    public constructor(init?: Partial<GetAdUnitTriggers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAdUnitTriggers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<AdUnitTrigger>(); }
}

// @Route("/admin/ad-unit-trigger", "POST,PUT")
export class ManageAdUnitTrigger implements IReturn<AdUnitTrigger>
{
    public getPath() { return '/admin/ad-unit-trigger'; }
    public adUnitTrigger?: AdUnitTrigger;

    public constructor(init?: Partial<ManageAdUnitTrigger>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageAdUnitTrigger'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdUnitTrigger(); }
}

// @Route("/admin/ad-unit-trigger/delete", "DELETE")
export class DeleteAdUnitTrigger implements IReturnVoid
{
    public getPath() { return '/admin/ad-unit-trigger/delete'; }
    public id: number;

    public constructor(init?: Partial<DeleteAdUnitTrigger>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteAdUnitTrigger'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/category", "GET")
export class GetCategory implements IReturn<Category>
{
    public getPath() { return '/admin/category'; }
    public id?: string;

    public constructor(init?: Partial<GetCategory>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategory'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Category(); }
}

// @Route("/admin/categories", "GET")
export class GetCategories implements IReturn<Category[]>
{
    public getPath() { return '/admin/categories'; }

    public constructor(init?: Partial<GetCategories>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategories'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<Category>(); }
}

// @Route("/admin/category", "POST,PUT")
export class ManageCategory implements IReturn<Category>
{
    public getPath() { return '/admin/category'; }
    public category?: Category;

    public constructor(init?: Partial<ManageCategory>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageCategory'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Category(); }
}

// @Route("/admin/category/reorder", "POST")
export class ReorderCategory implements IReturn<Category[]>
{
    public getPath() { return '/admin/category/reorder'; }
    public id?: string;
    public placeAt: number;
    public parentId?: string;

    public constructor(init?: Partial<ReorderCategory>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReorderCategory'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<Category>(); }
}

// @Route("/admin/categories-order", "POST")
export class ManageCategoriesOrder implements IReturn<Category[]>
{
    public getPath() { return '/admin/categories-order'; }
    public categories?: CategoryOrder[];

    public constructor(init?: Partial<ManageCategoriesOrder>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageCategoriesOrder'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<Category>(); }
}

// @Route("/admin/category/delete", "DELETE")
export class DeleteCategory implements IReturnVoid
{
    public getPath() { return '/admin/category/delete'; }
    public id?: string;

    public constructor(init?: Partial<DeleteCategory>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteCategory'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/categoryApplicationsIds", "GET")
export class GetCategoryApplicationsIds implements IReturn<CategoryApplicationsIds>
{
    public getPath() { return '/admin/categoryApplicationsIds'; }
    public categoryId?: string;

    public constructor(init?: Partial<GetCategoryApplicationsIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCategoryApplicationsIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CategoryApplicationsIds(); }
}

// @Route("/admin/featured-application", "GET")
export class GetFeaturedApplication implements IReturn<FeaturedApplication>
{
    public getPath() { return '/admin/featured-application'; }
    public id?: string;

    public constructor(init?: Partial<GetFeaturedApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFeaturedApplication'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FeaturedApplication(); }
}

// @Route("/admin/featured-applications", "GET")
export class GetFeaturedApplications implements IReturn<FeaturedApplication[]>
{
    public getPath() { return '/admin/featured-applications'; }

    public constructor(init?: Partial<GetFeaturedApplications>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFeaturedApplications'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<FeaturedApplication>(); }
}

// @Route("/admin/featured-application", "POST")
export class SetupFeaturedApplication implements IReturn<FeaturedApplication>
{
    public getPath() { return '/admin/featured-application'; }
    public application?: FeaturedApplication;

    public constructor(init?: Partial<SetupFeaturedApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetupFeaturedApplication'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FeaturedApplication(); }
}

// @Route("/admin/featured-application/reorder", "POST")
export class ReorderFeaturedApplication implements IReturn<FeaturedApplication[]>
{
    public getPath() { return '/admin/featured-application/reorder'; }
    public id?: string;
    public placeAt: number;

    public constructor(init?: Partial<ReorderFeaturedApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReorderFeaturedApplication'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<FeaturedApplication>(); }
}

// @Route("/admin/featured-applications/available-ids", "GET")
export class GetAvailableFeaturedApplicationIds implements IReturn<string[]>
{
    public getPath() { return '/admin/featured-applications/available-ids'; }

    public constructor(init?: Partial<GetAvailableFeaturedApplicationIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAvailableFeaturedApplicationIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/gamestore-application", "GET")
export class GetGamestoreApplication implements IReturn<GamestoreApplication>
{
    public getPath() { return '/admin/gamestore-application'; }
    public id?: string;

    public constructor(init?: Partial<GetGamestoreApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGamestoreApplication'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GamestoreApplication(); }
}

// @Route("/admin/gamestore-application/export", "GET")
export class ExportApplicationsList implements IReturn<Blob>
{
    public getPath() { return '/admin/gamestore-application/export'; }
    public language?: string;

    public constructor(init?: Partial<ExportApplicationsList>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportApplicationsList'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/admin/gamestore-applications", "GET")
export class GetGamestoreApplications implements IReturn<GamestoreApplicationSummary[]>, ILimitedRequest
{
    public getPath() { return '/admin/gamestore-applications'; }
    public language?: string;
    public offset?: number;
    public limit: number;
    public orderBy: GamestoreApplicationListDataType;
    public orderDirection: OrderDirection;
    public platformsSearch?: ApkPlatform[];
    public idSearch?: string;
    public titleSearch?: string;
    public publisherSearch?: string;
    public isFeaturedSearch?: boolean;
    public isGraphicsCustomizedSearch?: boolean;
    public isNewSearch?: boolean;
    public screenOrientationSearch?: ScreenOrientationType[];
    public isAppDisabledSearch?: boolean;
    public categoriesSearch?: string[];
    public modifiedAtStartDateSearch?: string;
    public modifiedAtEndDateSearch?: string;
    public x32ApkUpdatedAtStartDateSearch?: string;
    public x32ApkUpdatedAtEndDateSearch?: string;
    public x64ApkUpdatedAtStartDateSearch?: string;
    public x64ApkUpdatedAtEndDateSearch?: string;

    public constructor(init?: Partial<GetGamestoreApplications>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGamestoreApplications'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<GamestoreApplicationSummary>(); }
}

// @Route("/admin/gamestore-application", "POST,PUT")
export class ManageGamestoreApplication implements IReturn<GamestoreApplication>
{
    public getPath() { return '/admin/gamestore-application'; }
    public application?: GamestoreApplication;

    public constructor(init?: Partial<ManageGamestoreApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageGamestoreApplication'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GamestoreApplication(); }
}

// @Route("/admin/gamestore-application/key-bindings-schema", "GET")
export class GetKeyBindingsSchemas implements IReturn<GetKeyBindingsSchemasResult>
{
    public getPath() { return '/admin/gamestore-application/key-bindings-schema'; }

    public constructor(init?: Partial<GetKeyBindingsSchemas>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetKeyBindingsSchemas'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetKeyBindingsSchemasResult(); }
}

// @Route("/admin/gamestore-application/available-ids", "GET")
export class GetAvailableApplicationIds implements IReturn<string[]>
{
    public getPath() { return '/admin/gamestore-application/available-ids'; }

    public constructor(init?: Partial<GetAvailableApplicationIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAvailableApplicationIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/gamestore-application/search-available-ids", "GET")
export class SearchAvailableApplicationIds implements IReturn<string[]>
{
    public getPath() { return '/admin/gamestore-application/search-available-ids'; }
    public idSearch?: string;
    public limit: number;

    public constructor(init?: Partial<SearchAvailableApplicationIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SearchAvailableApplicationIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/toggleBulkGamestoreApplications", "POST")
export class ToggleBulkGamestoreApplications implements IReturnVoid, IPost
{
    public getPath() { return '/admin/toggleBulkGamestoreApplications'; }
    public isDisabled: boolean;

    public constructor(init?: Partial<ToggleBulkGamestoreApplications>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ToggleBulkGamestoreApplications'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/admin/io-gamestore-application", "GET")
export class GetGamestoreIoApplication implements IReturn<GamestoreIoApplication>
{
    public getPath() { return '/admin/io-gamestore-application'; }
    public id?: string;

    public constructor(init?: Partial<GetGamestoreIoApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGamestoreIoApplication'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GamestoreIoApplication(); }
}

// @Route("/admin/io-gamestore-application/export", "GET")
export class ExportIoApplicationsList implements IReturn<GamestoreIoApplicationExportSummary[]>
{
    public getPath() { return '/admin/io-gamestore-application/export'; }
    public language?: string;

    public constructor(init?: Partial<ExportIoApplicationsList>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportIoApplicationsList'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<GamestoreIoApplicationExportSummary>(); }
}

// @Route("/admin/io-gamestore-applications", "GET")
export class GetGamestoreIoApplications implements IReturn<GamestoreIoApplicationSummary[]>, ILimitedRequest
{
    public getPath() { return '/admin/io-gamestore-applications'; }
    public language?: string;
    public offset?: number;
    public limit: number;
    public orderBy: GamestoreIoApplicationListDataType;
    public orderDirection: OrderDirection;
    public idSearch?: string;
    public titleSearch?: string;
    public developerSearch?: string;
    public isGraphicsCustomizedSearch?: boolean;
    public isNewSearch?: boolean;
    public isAppDisabledSearch?: boolean;
    public categoriesSearch?: string[];
    public tagsSearch?: string[];
    public sourceProviderSearch?: string[];
    public modifiedAtStartDateSearch?: string;
    public modifiedAtEndDateSearch?: string;
    public srcUpdatedAtStartDateSearch?: string;
    public srcUpdatedAtEndDateSearch?: string;

    public constructor(init?: Partial<GetGamestoreIoApplications>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGamestoreIoApplications'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<GamestoreIoApplicationSummary>(); }
}

// @Route("/admin/io-gamestore-application", "POST,PUT")
export class ManageGamestoreIoApplication implements IReturn<GamestoreIoApplication>
{
    public getPath() { return '/admin/io-gamestore-application'; }
    public application?: GamestoreIoApplication;

    public constructor(init?: Partial<ManageGamestoreIoApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageGamestoreIoApplication'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GamestoreIoApplication(); }
}

// @Route("/admin/io-gamestore-application/available-ids", "GET")
export class GetAvailableIoApplicationIds implements IReturn<string[]>
{
    public getPath() { return '/admin/io-gamestore-application/available-ids'; }

    public constructor(init?: Partial<GetAvailableIoApplicationIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAvailableIoApplicationIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/toggleBulkIoApplications", "POST")
export class ToggleBulkIoApplications implements IReturnVoid, IPost
{
    public getPath() { return '/admin/toggleBulkIoApplications'; }
    public isDisabled: boolean;

    public constructor(init?: Partial<ToggleBulkIoApplications>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ToggleBulkIoApplications'; }
    public getMethod() { return 'POST'; }
    public createResponse() {}
}

// @Route("/admin/google-application", "GET")
export class GetGoogleApplication implements IReturn<GoogleApplication>
{
    public getPath() { return '/admin/google-application'; }
    public id?: string;

    public constructor(init?: Partial<GetGoogleApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGoogleApplication'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GoogleApplication(); }
}

// @Route("/admin/google-applications", "GET")
export class GetGoogleApplications implements IReturn<GoogleApplication[]>, ILimitedRequest
{
    public getPath() { return '/admin/google-applications'; }
    public offset?: number;
    public limit: number;

    public constructor(init?: Partial<GetGoogleApplications>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGoogleApplications'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<GoogleApplication>(); }
}

// @Route("/admin/google-application-summaries", "GET")
export class GetGoogleApplicationSummaries implements IReturn<GoogleApplicationSummary[]>, ILimitedRequest
{
    public getPath() { return '/admin/google-application-summaries'; }
    public language?: string;
    public platformsSearch?: ApkPlatform[];
    public offset?: number;
    public limit: number;
    public orderBy: GoogleApplicationListDataType;
    public orderDirection: OrderDirection;
    public idSearch?: string;
    public titleSearch?: string;
    public publisherSearch?: string;
    public categoriesSearch?: string[];

    public constructor(init?: Partial<GetGoogleApplicationSummaries>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGoogleApplicationSummaries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<GoogleApplicationSummary>(); }
}

// @Route("/admin/google-applications/without-gamestore-applications", "GET")
export class GetGoogleApplicationsWithoutGamestoreApplication implements IReturn<GoogleApplication[]>
{
    public getPath() { return '/admin/google-applications/without-gamestore-applications'; }

    public constructor(init?: Partial<GetGoogleApplicationsWithoutGamestoreApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGoogleApplicationsWithoutGamestoreApplication'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<GoogleApplication>(); }
}

// @Route("/admin/google-applications/versions", "GET")
export class GetGoogleApplicationsVersions implements IReturn<GoogleAppVersionInfo[]>
{
    public getPath() { return '/admin/google-applications/versions'; }

    public constructor(init?: Partial<GetGoogleApplicationsVersions>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGoogleApplicationsVersions'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<GoogleAppVersionInfo>(); }
}

// @Route("/admin/google-application", "POST,PUT")
export class ManageGoogleApplication implements IReturn<GoogleApplication>
{
    public getPath() { return '/admin/google-application'; }
    public application?: GoogleApplication;

    public constructor(init?: Partial<ManageGoogleApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageGoogleApplication'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GoogleApplication(); }
}

// @Route("/admin/io-application", "GET")
export class GetIoApplication implements IReturn<IoApplication>
{
    public getPath() { return '/admin/io-application'; }
    public id?: string;

    public constructor(init?: Partial<GetIoApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIoApplication'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new IoApplication(); }
}

// @Route("/admin/io-applications", "GET")
export class GetIoApplications implements IReturn<IoApplication[]>, ILimitedRequest
{
    public getPath() { return '/admin/io-applications'; }
    public offset?: number;
    public limit: number;

    public constructor(init?: Partial<GetIoApplications>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIoApplications'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<IoApplication>(); }
}

// @Route("/admin/io-application-summaries", "GET")
export class GetIoApplicationSummaries implements IReturn<IoApplicationSummary[]>, ILimitedRequest
{
    public getPath() { return '/admin/io-application-summaries'; }
    public language?: string;
    public offset?: number;
    public limit: number;
    public orderBy: IoApplicationListDataType;
    public orderDirection: OrderDirection;
    public idSearch?: string;
    public titleSearch?: string;
    public developerSearch?: string;
    public categoriesSearch?: string[];
    public tagsSearch?: string[];

    public constructor(init?: Partial<GetIoApplicationSummaries>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIoApplicationSummaries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<IoApplicationSummary>(); }
}

// @Route("/admin/io-applications/without-gamestore-applications", "GET")
export class GetIoApplicationsWithoutGamestoreApplication implements IReturn<IoApplication[]>
{
    public getPath() { return '/admin/io-applications/without-gamestore-applications'; }

    public constructor(init?: Partial<GetIoApplicationsWithoutGamestoreApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIoApplicationsWithoutGamestoreApplication'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<IoApplication>(); }
}

// @Route("/admin/io-application", "POST,PUT")
export class ManageIoApplication implements IReturn<IoApplication>
{
    public getPath() { return '/admin/io-application'; }
    public application?: IoApplication;

    public constructor(init?: Partial<ManageIoApplication>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageIoApplication'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IoApplication(); }
}

// @Route("/admin/io-crawling-results-providers", "GET")
export class GetIoCrawlingProviders implements IReturn<string[]>
{
    public getPath() { return '/admin/io-crawling-results-providers'; }

    public constructor(init?: Partial<GetIoCrawlingProviders>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIoCrawlingProviders'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/io-crawling-results", "GET")
export class GetIoCrawlingResults implements IReturn<CrawlIoApplicationResult[]>, ILimitedRequest
{
    public getPath() { return '/admin/io-crawling-results'; }
    public appIdSearch?: string;
    public providerSearch?: string[];
    public preferredProviderSearch?: string[];
    public statusSearch?: CrawlingStatus[];
    public errorSearch?: string;
    public limit: number;
    public offset?: number;
    public orderBy: IoCrawlingResultsOrderBy;
    public orderDirection: OrderDirection;
    public addedAtStartDateSearch?: string;
    public addedAtEndDateSearch?: string;
    public updatedAtStartDateSearch?: string;
    public updatedAtEndDateSearch?: string;

    public constructor(init?: Partial<GetIoCrawlingResults>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIoCrawlingResults'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<CrawlIoApplicationResult>(); }
}

// @Route("/admin/io-application-source-summaries", "GET")
export class GetIoApplicationSourceSummaries implements IReturn<IoApplicationSourceSummary[]>, ILimitedRequest
{
    public getPath() { return '/admin/io-application-source-summaries'; }
    public appIdSearch?: string;
    public titleSearch?: string;
    public statusSearch?: CrawlingStatus[];
    public availableProvidersSearch?: string[];
    public orderBy: IoCrawlingSourcesOrderBy;
    public orderDirection: OrderDirection;
    public offset?: number;
    public limit: number;

    public constructor(init?: Partial<GetIoApplicationSourceSummaries>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetIoApplicationSourceSummaries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<IoApplicationSourceSummary>(); }
}

// @Route("/admin/product-version-info", "GET")
export class GetProductVersionInfo implements IReturn<ProductVersionInfo>
{
    public getPath() { return '/admin/product-version-info'; }
    public id: number;

    public constructor(init?: Partial<GetProductVersionInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProductVersionInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProductVersionInfo(); }
}

// @Route("/admin/product-version-infos", "GET")
export class GetProductVersionInfos implements IReturn<ProductVersionInfo[]>, ILimitedRequest
{
    public getPath() { return '/admin/product-version-infos'; }
    public offset?: number;
    public limit: number;
    public orderBy: ProductVersionInfoListDataType;
    public orderDirection: OrderDirection;
    public isEnabledSearch?: boolean;
    public isMandatorySearch?: boolean;
    public versionSearch?: string;
    public sourcesSearch?: string[];

    public constructor(init?: Partial<GetProductVersionInfos>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProductVersionInfos'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ProductVersionInfo>(); }
}

// @Route("/admin/product-version-info", "POST,PUT")
export class ManageProductVersionInfo implements IReturn<ProductVersionInfo>
{
    public getPath() { return '/admin/product-version-info'; }
    public value?: ProductVersionInfo;

    public constructor(init?: Partial<ManageProductVersionInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageProductVersionInfo'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProductVersionInfo(); }
}

// @Route("/admin/product-version-info", "DELETE")
export class DeleteProductVersionInfo implements IReturnVoid
{
    public getPath() { return '/admin/product-version-info'; }
    public id: number;

    public constructor(init?: Partial<DeleteProductVersionInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteProductVersionInfo'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/source-settings/list", "GET")
export class GetSourceSettingsList implements IReturn<SourceSettings[]>
{
    public getPath() { return '/admin/source-settings/list'; }

    public constructor(init?: Partial<GetSourceSettingsList>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSourceSettingsList'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<SourceSettings>(); }
}

// @Route("/admin/source-settings/list", "GET")
export class GetAllSourceSettingsList implements IReturn<SourceSettings[]>
{
    public getPath() { return '/admin/source-settings/list'; }

    public constructor(init?: Partial<GetAllSourceSettingsList>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAllSourceSettingsList'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<SourceSettings>(); }
}

// @Route("/admin/source-settings", "GET")
export class GetSourceSettings implements IReturn<SourceSettings>
{
    public getPath() { return '/admin/source-settings'; }
    public id: number;

    public constructor(init?: Partial<GetSourceSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSourceSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceSettings(); }
}

// @Route("/admin/source-settings/check-name", "GET")
export class IsSourceNameExist implements IReturn<boolean>
{
    public getPath() { return '/admin/source-settings/check-name'; }
    public source?: string;

    public constructor(init?: Partial<IsSourceNameExist>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IsSourceNameExist'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return false; }
}

// @Route("/admin/source-settings/available-application-short-details", "GET")
export class GetAvailableApplicationShortDetails implements IReturn<PinnedApplicationShortDetails[]>
{
    public getPath() { return '/admin/source-settings/available-application-short-details'; }
    public idSearch?: string;
    public limit: number;

    public constructor(init?: Partial<GetAvailableApplicationShortDetails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAvailableApplicationShortDetails'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<PinnedApplicationShortDetails>(); }
}

// @Route("/admin/getApplicationsShortDetails", "GET")
export class GetApplicationsShortDetails implements IReturn<PinnedApplicationShortDetails[]>
{
    public getPath() { return '/admin/getApplicationsShortDetails'; }
    public ids?: string[];
    public language?: string;

    public constructor(init?: Partial<GetApplicationsShortDetails>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApplicationsShortDetails'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<PinnedApplicationShortDetails>(); }
}

// @Route("/admin/source-settings", "POST,PUT")
export class ManageSourceSettings implements IReturn<SourceSettings>
{
    public getPath() { return '/admin/source-settings'; }
    public sourceSettings?: SourceSettings;

    public constructor(init?: Partial<ManageSourceSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageSourceSettings'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceSettings(); }
}

// @Route("/admin/source-settings", "DELETE")
export class DeleteSourceSettings implements IReturnVoid
{
    public getPath() { return '/admin/source-settings'; }
    public id: number;

    public constructor(init?: Partial<DeleteSourceSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteSourceSettings'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/source-settings/metadata", "GET")
export class GetSourceSettingsMetadata implements IReturn<SourceSettingsMetadata>
{
    public getPath() { return '/admin/source-settings/metadata'; }

    public constructor(init?: Partial<GetSourceSettingsMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetSourceSettingsMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceSettingsMetadata(); }
}

// @Route("/admin/tag", "GET")
export class GetTag implements IReturn<Tag>
{
    public getPath() { return '/admin/tag'; }
    public id?: string;

    public constructor(init?: Partial<GetTag>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTag'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Tag(); }
}

// @Route("/admin/tags", "GET")
export class GetTags implements IReturn<Tag[]>
{
    public getPath() { return '/admin/tags'; }
    public orderBy: TagsOrderBy;
    public orderDirection: OrderDirection;

    public constructor(init?: Partial<GetTags>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTags'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<Tag>(); }
}

// @Route("/admin/tag", "POST,PUT")
export class ManageTag implements IReturn<Tag>
{
    public getPath() { return '/admin/tag'; }
    public tag?: Tag;

    public constructor(init?: Partial<ManageTag>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageTag'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Tag(); }
}

// @Route("/admin/tag-order", "POST")
export class ManageTagOrder implements IReturn<Tag>
{
    public getPath() { return '/admin/tag-order'; }
    // @Required()
    public tagId: string;

    // @Required()
    public order: number;

    public constructor(init?: Partial<ManageTagOrder>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageTagOrder'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Tag(); }
}

// @Route("/admin/tags/delete", "DELETE")
export class DeleteTag implements IReturnVoid
{
    public getPath() { return '/admin/tags/delete'; }
    public id?: string;

    public constructor(init?: Partial<DeleteTag>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteTag'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/users", "GET")
export class GetUsers implements IReturn<User[]>
{
    public getPath() { return '/admin/users'; }

    public constructor(init?: Partial<GetUsers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUsers'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<User>(); }
}

// @Route("/admin/user", "GET")
export class GetUser implements IReturn<User>
{
    public getPath() { return '/admin/user'; }
    public id: number;

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new User(); }
}

// @Route("/admin/user", "POST,PUT")
export class ManageUsers implements IReturn<User>
{
    public getPath() { return '/admin/user'; }
    public user?: User;

    public constructor(init?: Partial<ManageUsers>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageUsers'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new User(); }
}

// @Route("/admin/user", "DELETE")
export class DeleteUser implements IReturnVoid
{
    public getPath() { return '/admin/user'; }
    public id: number;

    public constructor(init?: Partial<DeleteUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUser'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/password", "POST")
export class SetPassword implements IReturn<boolean>
{
    public getPath() { return '/admin/password'; }
    public username?: string;
    public oldPassword?: string;
    public newPassword?: string;

    public constructor(init?: Partial<SetPassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetPassword'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return false; }
}

// @Route("/admin/widget", "GET")
export class GetWidget implements IReturn<WidgetUnit>
{
    public getPath() { return '/admin/widget'; }
    public id: number;

    public constructor(init?: Partial<GetWidget>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWidget'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new WidgetUnit(); }
}

// @Route("/admin/widgets", "GET")
export class GetWidgets implements IReturn<WidgetUnitSummary[]>
{
    public getPath() { return '/admin/widgets'; }

    public constructor(init?: Partial<GetWidgets>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetWidgets'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<WidgetUnitSummary>(); }
}

// @Route("/admin/widget", "POST,PUT")
export class ManageWidget implements IReturn<WidgetUnit>
{
    public getPath() { return '/admin/widget'; }
    public widget?: WidgetUnit;

    public constructor(init?: Partial<ManageWidget>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageWidget'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new WidgetUnit(); }
}

// @Route("/admin/widget", "DELETE")
export class DeleteWidget implements IReturnVoid
{
    public getPath() { return '/admin/widget'; }
    public id: number;

    public constructor(init?: Partial<DeleteWidget>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteWidget'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/widgets-layout", "PUT")
export class ManageWidgetsLayout implements IReturn<WidgetUnitLayouts[]>
{
    public getPath() { return '/admin/widgets-layout'; }
    public layouts?: WidgetUnitLayouts[];

    public constructor(init?: Partial<ManageWidgetsLayout>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageWidgetsLayout'; }
    public getMethod() { return 'PUT'; }
    public createResponse() { return new Array<WidgetUnitLayouts>(); }
}

// @Route("/admin/widgets/applications-info", "GET")
export class GetApplicationsInfo implements IReturn<ApplicationsInfo>
{
    public getPath() { return '/admin/widgets/applications-info'; }

    public constructor(init?: Partial<GetApplicationsInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApplicationsInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ApplicationsInfo(); }
}

// @Route("/admin/widgets/crawled-applications-info", "GET")
export class GetCrawledApplicationsInfo implements IReturn<CrawledApplicationsInfo>
{
    public getPath() { return '/admin/widgets/crawled-applications-info'; }

    public constructor(init?: Partial<GetCrawledApplicationsInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetCrawledApplicationsInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CrawledApplicationsInfo(); }
}

// @Route("/admin/statistics/active-installs-statistics-by-id", "GET")
export class GetActiveInstallsStatisticsById implements IReturn<ActiveInstallsStatistics>
{
    public getPath() { return '/admin/statistics/active-installs-statistics-by-id'; }
    public id: number;

    public constructor(init?: Partial<GetActiveInstallsStatisticsById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetActiveInstallsStatisticsById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ActiveInstallsStatistics(); }
}

// @Route("/admin/statistics/active-installs-statistics", "GET")
export class GetActiveInstallsStatistics implements IReturn<ActiveInstallsStatistics[]>
{
    public getPath() { return '/admin/statistics/active-installs-statistics'; }

    public constructor(init?: Partial<GetActiveInstallsStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetActiveInstallsStatistics'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ActiveInstallsStatistics>(); }
}

// @Route("/admin/statistics/active-installs-statistics-app-versions-by-date", "GET")
export class GetActiveInstallsStatisticsAppVersionsByDate implements IReturn<string[]>
{
    public getPath() { return '/admin/statistics/active-installs-statistics-app-versions-by-date'; }
    public date: string;

    public constructor(init?: Partial<GetActiveInstallsStatisticsAppVersionsByDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetActiveInstallsStatisticsAppVersionsByDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/statistics/active-installs-statistics-available-dates", "GET")
export class GetActiveInstallsStatisticsAvailableDates implements IReturn<string[]>
{
    public getPath() { return '/admin/statistics/active-installs-statistics-available-dates'; }

    public constructor(init?: Partial<GetActiveInstallsStatisticsAvailableDates>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetActiveInstallsStatisticsAvailableDates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/statistics/active-installs-statistics-by-version-for-period", "GET")
export class GetActiveInstallsStatisticsByVersionForPeriod implements IReturn<ActiveInstallsStatistics[]>
{
    public getPath() { return '/admin/statistics/active-installs-statistics-by-version-for-period'; }
    public productVersion?: string;
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<GetActiveInstallsStatisticsByVersionForPeriod>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetActiveInstallsStatisticsByVersionForPeriod'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ActiveInstallsStatistics>(); }
}

// @Route("/admin/statistics/active-installs-statistics-summaries", "GET")
export class GetActiveInstallsStatisticsSummaries implements IReturn<ActiveInstallsStatistics[]>
{
    public getPath() { return '/admin/statistics/active-installs-statistics-summaries'; }
    public limit: number;
    public offset?: number;
    public date?: string;
    public orderBy: ActiveInstallsStatisticsListDataType;
    public orderDirection: OrderDirection;
    public versionSearch?: string[];

    public constructor(init?: Partial<GetActiveInstallsStatisticsSummaries>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetActiveInstallsStatisticsSummaries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ActiveInstallsStatistics>(); }
}

// @Route("/admin/statistics/active-installs-statistics", "POST")
export class ManageActiveInstallsStatistics implements IReturn<ActiveInstallsStatistics>
{
    public getPath() { return '/admin/statistics/active-installs-statistics'; }
    public activeInstallsStatistics?: ActiveInstallsStatistics;

    public constructor(init?: Partial<ManageActiveInstallsStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageActiveInstallsStatistics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActiveInstallsStatistics(); }
}

// @Route("/admin/statistics/active-installs-statistics", "DELETE")
export class DeleteActiveInstallsStatisticsByDate implements IReturnVoid
{
    public getPath() { return '/admin/statistics/active-installs-statistics'; }
    public date: string;

    public constructor(init?: Partial<DeleteActiveInstallsStatisticsByDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteActiveInstallsStatisticsByDate'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/statistics/click-info-statistics-by-id", "GET")
export class GetClickInfoStatisticsById implements IReturn<ClickInfoStatistics>
{
    public getPath() { return '/admin/statistics/click-info-statistics-by-id'; }
    public id: number;

    public constructor(init?: Partial<GetClickInfoStatisticsById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClickInfoStatisticsById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClickInfoStatistics(); }
}

// @Route("/admin/statistics/click-info-statistics", "GET")
export class GetClickInfoStatistics implements IReturn<ClickInfoStatistics[]>
{
    public getPath() { return '/admin/statistics/click-info-statistics'; }

    public constructor(init?: Partial<GetClickInfoStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClickInfoStatistics'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<ClickInfoStatistics>(); }
}

// @Route("/admin/statistics/click-info-statistics-app-versions-by-date", "GET")
export class GetClickInfoStatisticsAppVersionsByDate implements IReturn<string[]>
{
    public getPath() { return '/admin/statistics/click-info-statistics-app-versions-by-date'; }
    public date: string;

    public constructor(init?: Partial<GetClickInfoStatisticsAppVersionsByDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClickInfoStatisticsAppVersionsByDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/statistics/click-info-statistics-available-dates", "GET")
export class GetClickInfoStatisticsAvailableDates implements IReturn<string[]>
{
    public getPath() { return '/admin/statistics/click-info-statistics-available-dates'; }

    public constructor(init?: Partial<GetClickInfoStatisticsAvailableDates>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClickInfoStatisticsAvailableDates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/statistics/click-info-statistics-available-targets", "GET")
export class GetClickInfoStatisticsAvailableTargets implements IReturn<string[]>
{
    public getPath() { return '/admin/statistics/click-info-statistics-available-targets'; }
    public idSearch?: string;
    public clickType?: string[];
    public country?: string[];
    public platform?: string[];
    public productVersion?: string[];
    public sourceUrl?: string[];
    public emulatorType?: string[];
    public limit: number;
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<GetClickInfoStatisticsAvailableTargets>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClickInfoStatisticsAvailableTargets'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/statistics/click-info-statistics-for-period", "GET")
export class GetClickInfoStatisticsForPeriod implements IReturn<ClickInfoStatisticsForPeriod>
{
    public getPath() { return '/admin/statistics/click-info-statistics-for-period'; }
    public clickTarget?: string[];
    public clickType?: string[];
    public country?: string[];
    public platform?: string[];
    public productVersion?: string[];
    public sourceUrl?: string[];
    public emulatorType?: string[];
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<GetClickInfoStatisticsForPeriod>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClickInfoStatisticsForPeriod'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClickInfoStatisticsForPeriod(); }
}

// @Route("/admin/statistics/click-info-statistics-for-date", "GET")
export class GetClickInfoStatisticsForDate implements IReturn<ClickInfoStatisticsForDate>, ILimitedRequest
{
    public getPath() { return '/admin/statistics/click-info-statistics-for-date'; }
    public limit: number;
    public offset?: number;
    public date?: string;
    public orderBy: ClickInfoStatisticsListDataType;
    public orderDirection: OrderDirection;
    public clickTypeSearch?: string[];
    public productVersionSearch?: string[];
    public clickTargetSearch?: string[];
    public countrySearch?: string[];
    public sourceUrlSearch?: string[];
    public platformSearch?: string[];
    public emulatorTypeSearch?: string[];

    public constructor(init?: Partial<GetClickInfoStatisticsForDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetClickInfoStatisticsForDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ClickInfoStatisticsForDate(); }
}

// @Route("/admin/statistics/click-info-statistics", "POST")
export class ManageClickInfoStatistics implements IReturn<ClickInfoStatistics>
{
    public getPath() { return '/admin/statistics/click-info-statistics'; }
    public clickInfoStatistics?: ClickInfoStatistics;

    public constructor(init?: Partial<ManageClickInfoStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageClickInfoStatistics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ClickInfoStatistics(); }
}

// @Route("/admin/statistics/click-info-statistics-list", "POST")
export class ManageClickInfoStatisticsList implements IReturn<ClickInfoStatistics[]>
{
    public getPath() { return '/admin/statistics/click-info-statistics-list'; }
    public clickInfoStatisticsList?: ClickInfoStatistics[];

    public constructor(init?: Partial<ManageClickInfoStatisticsList>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageClickInfoStatisticsList'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<ClickInfoStatistics>(); }
}

// @Route("/admin/statistics/click-info-statistics", "DELETE")
export class DeleteClickInfoStatisticsByDate implements IReturnVoid
{
    public getPath() { return '/admin/statistics/click-info-statistics'; }
    public date: string;

    public constructor(init?: Partial<DeleteClickInfoStatisticsByDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteClickInfoStatisticsByDate'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/statistics/gaming-minutes-statistics-by-id", "GET")
export class GetGamingMinutesStatisticsById implements IReturn<GamingMinutesStatistics>
{
    public getPath() { return '/admin/statistics/gaming-minutes-statistics-by-id'; }
    public id: number;

    public constructor(init?: Partial<GetGamingMinutesStatisticsById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGamingMinutesStatisticsById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GamingMinutesStatistics(); }
}

// @Route("/admin/statistics/gaming-minutes-statistics", "GET")
export class GetGamingMinutesStatistics implements IReturn<GamingMinutesStatistics[]>
{
    public getPath() { return '/admin/statistics/gaming-minutes-statistics'; }

    public constructor(init?: Partial<GetGamingMinutesStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGamingMinutesStatistics'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<GamingMinutesStatistics>(); }
}

// @Route("/admin/statistics/gaming-minutes-statistics-available-dates", "GET")
export class GetGamingMinutesStatisticsAvailableDates implements IReturn<string[]>
{
    public getPath() { return '/admin/statistics/gaming-minutes-statistics-available-dates'; }

    public constructor(init?: Partial<GetGamingMinutesStatisticsAvailableDates>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGamingMinutesStatisticsAvailableDates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/statistics/product-versions", "GET")
export class GetProductVersionsByDate implements IReturn<string[]>
{
    public getPath() { return '/admin/statistics/product-versions'; }
    public date: string;

    public constructor(init?: Partial<GetProductVersionsByDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProductVersionsByDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/statistics/gaming-statistics-summaries-by-appid-for-period", "GET")
export class GetGamingMinutesStatisticsSummariesByAppIdForPeriod implements IReturn<GamingMinutesStatisticsSummary[]>
{
    public getPath() { return '/admin/statistics/gaming-statistics-summaries-by-appid-for-period'; }
    public applicationId?: string;
    public productVersion?: string;
    public movingAvgDays?: number;
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<GetGamingMinutesStatisticsSummariesByAppIdForPeriod>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGamingMinutesStatisticsSummariesByAppIdForPeriod'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<GamingMinutesStatisticsSummary>(); }
}

// @Route("/admin/statistics/gaming-statistics-total-minutes-by-date", "GET")
export class GetTotalGamingMinutesByDateForPeriod implements IReturn<TotalGamingMinutesByDateResult>
{
    public getPath() { return '/admin/statistics/gaming-statistics-total-minutes-by-date'; }
    public startDate: string;
    public endDate: string;
    public showPlaystoreActivity: boolean;

    public constructor(init?: Partial<GetTotalGamingMinutesByDateForPeriod>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTotalGamingMinutesByDateForPeriod'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TotalGamingMinutesByDateResult(); }
}

// @Route("/admin/statistics/gaming-statistics-total-minutes-by-id", "GET")
export class GetTotalGamingMinutesByIdForPeriod implements IReturn<TotalGamingMinutesByIdResult>
{
    public getPath() { return '/admin/statistics/gaming-statistics-total-minutes-by-id'; }
    public startDate: string;
    public endDate: string;
    public limit: number;

    public constructor(init?: Partial<GetTotalGamingMinutesByIdForPeriod>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTotalGamingMinutesByIdForPeriod'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TotalGamingMinutesByIdResult(); }
}

// @Route("/admin/statistics/gaming-minutes-statistics-summaries", "GET")
export class GetGamingMinutesStatisticsSummaries implements IReturn<GamingMinutesStatisticsSummary[]>, ILimitedRequest
{
    public getPath() { return '/admin/statistics/gaming-minutes-statistics-summaries'; }
    public limit: number;
    public offset?: number;
    public date?: string;
    public movingAvgDays?: number;
    public orderBy: GamingMinutesStatisticsListDataType;
    public orderDirection: OrderDirection;
    public appIdSearch?: string;
    public titleSearch?: string;
    public productVersionSearch?: string[];
    public showPlaystoreActivity: boolean;

    public constructor(init?: Partial<GetGamingMinutesStatisticsSummaries>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetGamingMinutesStatisticsSummaries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<GamingMinutesStatisticsSummary>(); }
}

// @Route("/admin/statistics/gaming-minutes-statistics", "POST")
export class ManageGamingMinutesStatistics implements IReturn<GamingMinutesStatistics>
{
    public getPath() { return '/admin/statistics/gaming-minutes-statistics'; }
    public gamingMinutesStatistics?: GamingMinutesStatistics;

    public constructor(init?: Partial<ManageGamingMinutesStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageGamingMinutesStatistics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GamingMinutesStatistics(); }
}

// @Route("/admin/statistics/gaming-minutes-statistics", "DELETE")
export class DeleteGamingMinutesStatisticsByDate implements IReturnVoid
{
    public getPath() { return '/admin/statistics/gaming-minutes-statistics'; }
    public date: string;

    public constructor(init?: Partial<DeleteGamingMinutesStatisticsByDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteGamingMinutesStatisticsByDate'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/statistics/playstore-activity-statistics-by-id", "GET")
export class GetPlaystoreActivityStatisticsById implements IReturn<PlaystoreActivityStatistics>
{
    public getPath() { return '/admin/statistics/playstore-activity-statistics-by-id'; }
    public id: number;

    public constructor(init?: Partial<GetPlaystoreActivityStatisticsById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPlaystoreActivityStatisticsById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PlaystoreActivityStatistics(); }
}

// @Route("/admin/statistics/playstore-activity-statistics", "GET")
export class GetPlaystoreActivityStatistics implements IReturn<PlaystoreActivityStatistics[]>
{
    public getPath() { return '/admin/statistics/playstore-activity-statistics'; }

    public constructor(init?: Partial<GetPlaystoreActivityStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPlaystoreActivityStatistics'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<PlaystoreActivityStatistics>(); }
}

// @Route("/admin/statistics/playstore-activity-app-versions-by-date", "GET")
export class GetPlaystoreActivityAppVersionsByDate implements IReturn<string[]>
{
    public getPath() { return '/admin/statistics/playstore-activity-app-versions-by-date'; }
    public date: string;

    public constructor(init?: Partial<GetPlaystoreActivityAppVersionsByDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPlaystoreActivityAppVersionsByDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/statistics/playstore-activity-statistics-available-dates", "GET")
export class GetPlaystoreActivityStatisticsAvailableDates implements IReturn<string[]>
{
    public getPath() { return '/admin/statistics/playstore-activity-statistics-available-dates'; }

    public constructor(init?: Partial<GetPlaystoreActivityStatisticsAvailableDates>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPlaystoreActivityStatisticsAvailableDates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/statistics/playstore-activity-statistics-available-package-names", "GET")
export class GetPlaystoreActivityStatisticsAvailablePackageNames implements IReturn<string[]>
{
    public getPath() { return '/admin/statistics/playstore-activity-statistics-available-package-names'; }
    public idSearch?: string;
    public status?: string[];
    public country?: string[];
    public platform?: string[];
    public productVersion?: string[];
    public sourceUrl?: string[];
    public limit: number;
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<GetPlaystoreActivityStatisticsAvailablePackageNames>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPlaystoreActivityStatisticsAvailablePackageNames'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/statistics/playstore-activity-statistics-for-period", "GET")
export class GetPlaystoreActivityStatisticsByPeriod implements IReturn<PlaystoreActivityStatisticsForPeriod>
{
    public getPath() { return '/admin/statistics/playstore-activity-statistics-for-period'; }
    public apkPackageName?: string[];
    public status?: string[];
    public country?: string[];
    public platform?: string[];
    public productVersion?: string[];
    public sourceUrl?: string[];
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<GetPlaystoreActivityStatisticsByPeriod>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPlaystoreActivityStatisticsByPeriod'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PlaystoreActivityStatisticsForPeriod(); }
}

// @Route("/admin/statistics/playstore-activity-statistics-by-date", "GET")
export class GetPlaystoreActivityStatisticsByDate implements IReturn<PlaystoreActivityStatisticsForDate>, ILimitedRequest
{
    public getPath() { return '/admin/statistics/playstore-activity-statistics-by-date'; }
    public limit: number;
    public offset?: number;
    public date?: string;
    public orderBy: PlaystoreActivityListDataType;
    public orderDirection: OrderDirection;
    public statusSearch?: string[];
    public productVersionSearch?: string[];
    public apkPackageNameSearch?: string[];
    public countrySearch?: string[];
    public sourceUrlSearch?: string[];
    public platformSearch?: string[];

    public constructor(init?: Partial<GetPlaystoreActivityStatisticsByDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPlaystoreActivityStatisticsByDate'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PlaystoreActivityStatisticsForDate(); }
}

// @Route("/admin/statistics/playstore-activity-statistics", "POST")
export class ManagePlaystoreActivityStatistics implements IReturn<PlaystoreActivityStatistics>
{
    public getPath() { return '/admin/statistics/playstore-activity-statistics'; }
    public playstoreActivityStatistics?: PlaystoreActivityStatistics;

    public constructor(init?: Partial<ManagePlaystoreActivityStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManagePlaystoreActivityStatistics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PlaystoreActivityStatistics(); }
}

// @Route("/admin/statistics/playstore-activity-statistics-list", "POST")
export class ManagePlaystoreActivityStatisticsList implements IReturn<PlaystoreActivityStatistics[]>
{
    public getPath() { return '/admin/statistics/playstore-activity-statistics-list'; }
    public playstoreActivityStatisticsList?: PlaystoreActivityStatistics[];

    public constructor(init?: Partial<ManagePlaystoreActivityStatisticsList>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManagePlaystoreActivityStatisticsList'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Array<PlaystoreActivityStatistics>(); }
}

// @Route("/admin/statistics/playstore-activity-statistics", "DELETE")
export class DeletePlaystoreActivityStatisticsByDate implements IReturnVoid
{
    public getPath() { return '/admin/statistics/playstore-activity-statistics'; }
    public date: string;

    public constructor(init?: Partial<DeletePlaystoreActivityStatisticsByDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeletePlaystoreActivityStatisticsByDate'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

// @Route("/admin/statistics/request-statistics-by-id", "GET")
export class GetRequestStatisticsById implements IReturn<RequestStatistics>
{
    public getPath() { return '/admin/statistics/request-statistics-by-id'; }
    public id: number;

    public constructor(init?: Partial<GetRequestStatisticsById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRequestStatisticsById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new RequestStatistics(); }
}

// @Route("/admin/statistics/request-statistics", "GET")
export class GetRequestStatistics implements IReturn<RequestStatistics[]>
{
    public getPath() { return '/admin/statistics/request-statistics'; }

    public constructor(init?: Partial<GetRequestStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRequestStatistics'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<RequestStatistics>(); }
}

// @Route("/admin/statistics/request-statistics-available-dates", "GET")
export class GetRequestStatisticsAvailableDates implements IReturn<string[]>
{
    public getPath() { return '/admin/statistics/request-statistics-available-dates'; }

    public constructor(init?: Partial<GetRequestStatisticsAvailableDates>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRequestStatisticsAvailableDates'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<string>(); }
}

// @Route("/admin/statistics/request-statistics-summaries-by-path-for-period", "GET")
export class GetRequestStatisticsSummariesByPathForPeriod implements IReturn<RequestStatisticsSummary[]>
{
    public getPath() { return '/admin/statistics/request-statistics-summaries-by-path-for-period'; }
    public path?: string;
    public movingAvgDays?: number;
    public startDate: string;
    public endDate: string;

    public constructor(init?: Partial<GetRequestStatisticsSummariesByPathForPeriod>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRequestStatisticsSummariesByPathForPeriod'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<RequestStatisticsSummary>(); }
}

// @Route("/admin/statistics/request-statistic-summaries", "GET")
export class GetRequestStatisticsSummaries implements IReturn<RequestStatisticsSummary[]>
{
    public getPath() { return '/admin/statistics/request-statistic-summaries'; }
    public date?: string;
    public movingAvgDays?: number;
    public orderBy: RequestStatisticsListDataType;
    public orderDirection: OrderDirection;
    public offset?: number;
    public limit: number;
    public isWidget: boolean;

    public constructor(init?: Partial<GetRequestStatisticsSummaries>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetRequestStatisticsSummaries'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Array<RequestStatisticsSummary>(); }
}

// @Route("/admin/statistics/request-statistics", "POST")
export class ManageRequestStatistics implements IReturn<RequestStatistics>
{
    public getPath() { return '/admin/statistics/request-statistics'; }
    public requestStatistics?: RequestStatistics;

    public constructor(init?: Partial<ManageRequestStatistics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageRequestStatistics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RequestStatistics(); }
}

// @Route("/admin/statistics/request-statistics", "DELETE")
export class DeleteRequestStatisticsByDate implements IReturnVoid
{
    public getPath() { return '/admin/statistics/request-statistics'; }
    public date: string;

    public constructor(init?: Partial<DeleteRequestStatisticsByDate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteRequestStatisticsByDate'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() {}
}

/** @description Sign In */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    public getPath() { return ''; }
    /** @description AuthProvider, e.g. credentials */
    // @DataMember(Order=1)
    public provider?: string;

    // @DataMember(Order=2)
    public state?: string;

    // @DataMember(Order=3)
    public oauth_token?: string;

    // @DataMember(Order=4)
    public oauth_verifier?: string;

    // @DataMember(Order=5)
    public userName?: string;

    // @DataMember(Order=6)
    public password?: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView?: string;

    // @DataMember(Order=10)
    public nonce?: string;

    // @DataMember(Order=11)
    public uri?: string;

    // @DataMember(Order=12)
    public response?: string;

    // @DataMember(Order=13)
    public qop?: string;

    // @DataMember(Order=14)
    public nc?: string;

    // @DataMember(Order=15)
    public cnonce?: string;

    // @DataMember(Order=17)
    public accessToken?: string;

    // @DataMember(Order=18)
    public accessTokenSecret?: string;

    // @DataMember(Order=19)
    public scope?: string;

    // @DataMember(Order=20)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    public getPath() { return ''; }
    // @DataMember(Order=1)
    public environment?: string;

    // @DataMember(Order=2)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    public getPath() { return ''; }
    // @DataMember(Order=1)
    public environment?: string;

    // @DataMember(Order=2)
    public meta?: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

export type IApplicationDetails371 =
    ({__type: "Playstore.ServiceModel.Types.Api371.GamestoreApplicationDetails371, Playstore.ServiceModel"} & GamestoreApplicationDetails371) |
    ({__type: "Playstore.ServiceModel.Types.Api371.IoApplicationDetails371, Playstore.ServiceModel"} & IoApplicationDetails371);

export type IApplicationDetails387 =
    ({__type: "Playstore.ServiceModel.Types.Api387.GamestoreApplicationDetails387, Playstore.ServiceModel"} & GamestoreApplicationDetails387) |
    ({__type: "Playstore.ServiceModel.Types.Api387.IoApplicationDetails387, Playstore.ServiceModel"} & IoApplicationDetails387);

export type IApplicationData371 =
    ({__type: "Playstore.ServiceModel.Types.Api371.GamestoreApplicationData371, Playstore.ServiceModel"} & GamestoreApplicationData371) |
    ({__type: "Playstore.ServiceModel.Types.Api371.IoApplicationData371, Playstore.ServiceModel"} & IoApplicationData371);


