import React, { useRef, useCallback, useMemo, useEffect } from "react";
import ReactTags, { Tag } from "react-tags-typeahead";
import styled from "styled-components";
import { SearchCellHeaderProps } from ".";
import { ThinScrollbar } from "../mixin";

export const StyledTags = styled.div`
    background: white;
    margin: 8px 0 8px 0;
    border-radius: 5px;
    width: 100%;
    min-height: 27px;
    height: auto;
    border: 1px solid #d1d1d1;
    position: relative;

    ul {
        ${ThinScrollbar}
    }

    .react-tags {
        position: relative;
        padding: 1px 23px 1px 3px;
        border-radius: 5px;
        min-height: 27px;
        height: auto;
        font-size: 1em;
        line-height: 1.2;
        cursor: text;
    }

    .react-tags.is-focused {
        border-color: #b1b1b1;
    }

    .react-tags__selected {
        display: inline;
    }

    .react-tags__selected-tag {
        display: inline-block;
        box-sizing: border-box;
        margin: 3px 2px 1px 1px;
        padding: 2px 5px 6px 6px;
        border: none;
        border-radius: 2px;
        background: #e6e6e6;
        max-height: 21px;
        font-size: 13px;
        bottom: 10px;
        color: hsl(0,0%,20%);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: inherit;
    }

    .react-tags__selected-tag:after {
        content: "\\2715";
        color: #aaa;
        padding: 0px 0px 2px 6px;
        margin-left: 8px;
        font-size: 10px;
    }

    .react-tags__search {
        padding: 3px 0 0 0;
        left: 1px;
        bottom: 1px;
        max-width: 100%;
    }

    @media screen and (min-width: 30em) {
        .react-tags__search {
            position: relative;
        }
    }

    .react-tags__search-input {
        display: block;
        max-width: 100%;
        min-width: 100%;
        padding: 2px 4px;
        border: 0;
        outline: none;
        font-size: inherit;
        line-height: inherit;
    }

    .react-tags__search-input::-ms-clear {
        display: none;
    }

    .react-tags__suggestions {
        position: absolute;
        top: 100%;
        width: fit-content !important;
        max-width: 100%;
        left: 0;
        z-index: 1000;
    }

    @media screen and (min-width: 30em) {
        .react-tags__suggestions {
            width: 240px;
        }
    }

    .react-tags__suggestions ul {
        white-space: nowrap;
        max-width: fit-content;
        background: url(new_arrow.png) no-repeat right #ddd;
        border: 1px solid #ccc;
        margin: 0;
        padding: 0;
        font-size: 1rem;
        list-style: none;
        background: white;
        border-radius: 4px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
    }

    .react-tags__suggestions li {
        border-right: 7px solid #fff;
        padding: 8px 12px;
        font-weight: 400;
        font-family: "Segoe UI", Roboto;
        line-height: 1.5;
        display: block;
        font-size: inherit;
        max-width: 100%;
        min-width: 130px;
        padding: 5px;
        height: 34px;
    }

    .react-tags__suggestions li mark {
        display: none;
        background: none;
    }

    .react-tags__suggestions li mark:not(:empty) {
        display: inline-block;
        width: auto;
        margin: 0;
        padding: 0;
    }

    .react-tags__suggestions li:hover {
        cursor: default;
        border-right: 7px solid #deebff;
        background-color: #deebff;
    }

    .react-tags__suggestions li.is-active {
        border-right: 7px solid #2684ff;
        background: #2684ff;
        color: hsl(0,0%,100%);
    }

    .react-tags__suggestions li.is-disabled {
        opacity: 0.5;
        cursor: auto;
    }

    .clear {
        border: none;
        background: white;
        font-weight: 700;
        position: absolute;
        right: 3px;
        top: 3px;
        border-color: white;
    }

    .clear:focus {
        outline: none;
    }

    .clear:after {
        content: "\\2715"
    }
`;

export type SuggestionTag = {
    value: string | number,
    label: string
};

type Props = SearchCellHeaderProps<unknown[], unknown> & {
    suggestions: unknown[],
    valueMapper: (value: unknown) => SuggestionTag
};

const MultiValueSearch = (props: Props) => {

    const onAddition = useCallback(
        (tag: Tag) => props.setSearchValue((props.searchValue ?? []).concat(tag.id)),
        [props.searchValue]);

    const onDelete = useCallback(
        (index: number) => props.setSearchValue((props.searchValue ?? []).filter((_, i) => i !== index)),
        [props.searchValue]);

    const tags = useMemo(
        () => props.searchValue?.map(props.valueMapper)
        .map(v => ({ id: v.value, name: v.label } as Tag)) ?? [],
        [props.searchValue, props.valueMapper]);

    const suggestions = useMemo(
        () => props.suggestions.map(props.valueMapper).map(v => ({ id: v.value, name: v.label } as Tag))
        .filter(v => tags.find(t => t.id === v.id) === undefined),
        [tags, props.suggestions, props.valueMapper]);

    useEffect(() => {
        if (props.suggestions.length === 0) {
            return;
        }
        const suggestionsTags = props.suggestions.map(props.valueMapper);
        const existingTags = props.searchValue?.map(props.valueMapper) ?? [];
        const tagsToRemove = existingTags.map((tag, idx) => suggestionsTags.find(s => s.value === tag.value) === undefined ? idx : null)
            .filter(v => v !== null);
        if(tagsToRemove.length !== 0){
            props.setSearchValue((props.searchValue ?? []).filter((_, i) => !tagsToRemove.includes(i)));
        }
    }, [props.suggestions, props.valueMapper, props.searchValue]);

    const ref = useRef<ReactTags>(null);
    const clearTags = useCallback(() => {
        props.setSearchValue([]);
        ref.current?.clearInput();
    }, [ref, ref.current]);

    return (
        <StyledTags>
            <ReactTags
                ref={ref}
                tags={tags}
                minQueryLength={0}
                placeholderText="Search..."
                suggestions={suggestions}
                onAddition={onAddition}
                onDelete={onDelete}
                maxSuggestionsLength={suggestions.length}
            />
            <button className="clear" type="button" onClick={clearTags} />
        </StyledTags>
    );
};

export function multiValueSearchFactory(
    suggestions: unknown[],
    valueMapper: (value: unknown) => SuggestionTag) {

    return (props: SearchCellHeaderProps<unknown[], unknown>): JSX.Element => (
        <MultiValueSearch {...props} suggestions={suggestions} valueMapper={valueMapper} />
    );
}
