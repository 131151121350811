import { BootstrapBreakpoint, BootstrapBreakpoints } from "src/shared/helpers";
import { CSSObject, DefaultTheme, ThemedCssFunction, css } from "styled-components";

export const Breakpoints = Object.keys(BootstrapBreakpoints).reduce(
  (res, key) => {
    res[key] = (arg: TemplateStringsArray | CSSObject) => css`
      @media (min-width: ${BootstrapBreakpoints[key]}px) {
        ${css(arg)};
      }
    `;
    return res;
  }, {} as Record<BootstrapBreakpoint, ThemedCssFunction<DefaultTheme>>);