import { client } from "src/shared/client";
import {
    WidgetUnit,
    GetWidgets,
    ManageWidget,
    DeleteWidget,
    GetWidget,
    GetFrontendSettings,
    WidgetUnitLayouts,
    ManageWidgetsLayout,
} from "src/shared/dtos";

export const widgetsApi = {
    getWidgets: () => client.get(new GetWidgets()),
    getWidget: (id: number) => client.get(new GetWidget({ id })),
    create: (widget: WidgetUnit) => client.post(new ManageWidget({ widget })),
    delete: (id: number) => client.delete(new DeleteWidget({ id })),
    update: (widget: WidgetUnit) => client.put(new ManageWidget({ widget })),
    updateLayouts: (layouts: WidgetUnitLayouts[]) => client.put(new ManageWidgetsLayout({ layouts })),
    getFrontendSettings: () => client.get(new GetFrontendSettings())
};

export default widgetsApi;