import "bootstrap/dist/css/bootstrap.css";
import "react-base-table/styles.css";
import "react-toastify/dist/ReactToastify.minimal.css";
import "react-sortable-tree/style.css";
import "prismjs/themes/prism-solarizedlight.css";

import * as React from "react";
import * as ReactDOM from "react-dom";

import { App } from "./App";
import * as serviceWorker from "./serviceWorker";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { ConfirmProvider } from "./shared/components/confirmWindow/ConfirmProvider";

// Import font awesome icons.
library.add(fas, fab);

ReactDOM.render(
    <ConfirmProvider>
        <App />
    </ConfirmProvider>,
    document.getElementById("root") as HTMLElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
