import { client } from "src/shared/client";
import {
    GamestoreApplication,
    GetCategories,
    GetGamestoreApplication,
    GetGamestoreApplications,
    ManageGamestoreApplication,
    GetGoogleApplicationsWithoutGamestoreApplication,
    GetKeyBindingsSchemas,
    ToggleBulkGamestoreApplications,
    GetProductVersionInfos,
    ProductVersionInfoListDataType,
    ManageUserGridSettings,
} from "src/shared/dtos";
import { ExcludeMethods, serializeDate } from "src/shared/helpers";

export type ListRequest = Omit<ExcludeMethods<GetGamestoreApplications>,
    "modifiedAtStartDateSearch" |
    "modifiedAtEndDateSearch" |
    "x32ApkUpdatedAtStartDateSearch" |
    "x32ApkUpdatedAtEndDateSearch" |
    "x64ApkUpdatedAtStartDateSearch" |
    "x64ApkUpdatedAtEndDateSearch"> & {
        modifiedAtStartDateSearch?: Date;
        modifiedAtEndDateSearch?: Date;
        x32ApkUpdatedAtStartDateSearch?: Date;
        x32ApkUpdatedAtEndDateSearch?: Date;
        x64ApkUpdatedAtStartDateSearch?: Date;
        x64ApkUpdatedAtEndDateSearch?: Date;
    };

export const ApplicationApi = {
    getCategories: () => client.get(new GetCategories()),
    getKeyBindingsSchemas: () => client.get(new GetKeyBindingsSchemas()),
    getGoogleAppsWithoutGamestoreApp: () => client.get(new GetGoogleApplicationsWithoutGamestoreApplication()),
    getProductVersions: () => client.get(new GetProductVersionInfos({ orderBy: ProductVersionInfoListDataType.Id })),
    list: async (request: ListRequest) => {
        const {
            modifiedAtStartDateSearch,
            modifiedAtEndDateSearch,
            x32ApkUpdatedAtStartDateSearch,
            x32ApkUpdatedAtEndDateSearch,
            x64ApkUpdatedAtStartDateSearch,
            x64ApkUpdatedAtEndDateSearch,
            ...restRequest
        } = request;
        return client.get(new GetGamestoreApplications({
            ...restRequest,
            modifiedAtStartDateSearch: serializeDate(modifiedAtStartDateSearch),
            modifiedAtEndDateSearch: serializeDate(modifiedAtEndDateSearch),
            x32ApkUpdatedAtStartDateSearch: serializeDate(x32ApkUpdatedAtStartDateSearch),
            x32ApkUpdatedAtEndDateSearch: serializeDate(x32ApkUpdatedAtEndDateSearch),
            x64ApkUpdatedAtStartDateSearch: serializeDate(x64ApkUpdatedAtStartDateSearch),
            x64ApkUpdatedAtEndDateSearch: serializeDate(x64ApkUpdatedAtEndDateSearch),
        }));
    },
    get: async (id: string) => {
        const app = await client.get(new GetGamestoreApplication({ id }));
        return app.id === undefined ? undefined : app;
    },
    create: (application: GamestoreApplication) => client.post(new ManageGamestoreApplication({ application })),
    update: (application: GamestoreApplication) => client.put(new ManageGamestoreApplication({ application })),
    toggleApps: (file: File, isDisabled: boolean) => {
        const formData = new FormData();
        formData.append("appIds", file, "appIds");
        return client.postBody(new ToggleBulkGamestoreApplications({ isDisabled }), formData);
    },
    saveGridSettings: (breakpoint: string, visibleColumns: string[]) => client.post(new ManageUserGridSettings({
        gridName: "Applications",
        breakpoint,
        visibleColumns
    }))
};

export default ApplicationApi;