import styled from "styled-components";
import { previewVariables } from "../../previewVariables";

export const GameCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${previewVariables.gameCardWidth}px;
    height: ${previewVariables.gameCardHeight}px;
    border-radius: 10px;
    box-shadow: 0 0 8px ${previewVariables.gameCardBoxShadowColor};
    user-select: none;
`;

export const GameCardImage = styled.div<{ url: string }>`
    flex: 1;
    background-image: url(${p => p.url});
    background-position: center;
    background-size: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
`;

export const GameCardFooter = styled.div`
    height: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    text-transform: uppercase;
    color: ${previewVariables.gameCardTextColor};
    background-color: #000000;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
`;

export const GameCardFooterContent = styled.div`
    height: 100%;
    font-size: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const GameCardTag = styled.span`
    padding: 0 14px;
    border: 1px solid ${previewVariables.gameCardTextColor};
    border-radius: 6px;
`;

export const GameCardScore = styled.span`
    font-size: 14px;
    font-weight: bold;
    color: ${previewVariables.gameCardScoreColor};
`;
