function deepEqualRecursiveCheck(a: {}, b: {}, maxDepth: number, currentDepth: number) {
    // tslint:disable-next-line
    for (const key in a)
    {
        const aValue = a[key];
        const bValue = b[key];
        const bHasKey = key in b;

        if (!bHasKey && aValue === undefined) {
            continue;
        }

        if(!deepEqualImpl(aValue, bValue, maxDepth, currentDepth + 1)) {
            return false;
        }
    }

    return true;
}

function deepEqualImpl<T>(a: T, b: T, maxDepth: number, currentDepth: number): boolean {
    if (currentDepth > maxDepth) {
        return false;
    }

    if((typeof a === "object" && a)
        && (typeof b === "object" && b))
    {
        if (!deepEqualRecursiveCheck(a, b, maxDepth, currentDepth)) {
            return false;
        }
        if (!deepEqualRecursiveCheck(b, a, maxDepth, currentDepth)) {
            return false;
        }
        return true;
    }
    else
    {
        return a === b;
    }
}

/**
 * Checks if two object has equal keys and equal values recursively. Doesn't checks prototype equality.
 * Solution taken from https://stackoverflow.com/a/38416465
 * @param a The first value to compare.
 * @param b The second value to compare.
 * @param maxDepth Max recursive depth available to check. If hit returns false.
 * @returns The flag indicates the deep equality of objects.
 */
export function deepEqual<T>(a: T, b: T, maxDepth: number = 5): boolean {
    return deepEqualImpl(a, b, maxDepth, 0);
}