import React, { useState, useEffect, useMemo, useCallback } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BreadcrumbItem, Button, Container } from "reactstrap";
import { defer } from "rxjs";
import { Breadcrumb, CrawlingStatusText, DocumentTitle, Loader, notifyError, notifySuccess, RouteLink, RouteUserProps } from "src/shared/components";
import { Content, VerticalBox } from "src/shared/components/flex";
import { Column, DefaultCellRenderer, InfiniteTable, LoadRequest, multiValueSearchFactory, SearchOptions, StringSearch, TopPanelLeftContainer, TopPanelRendererProps } from "src/shared/components/InfiniteTable";
import { SuggestionTag } from "src/shared/components/MultiValueSearch";
import { CellHeaderProps, CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { CrawlingStatus, IoApplicationProviderData, IoApplicationSourceSummary, IoCrawlingSourcesOrderBy, OrderDirection } from "src/shared/dtos";
import { deserializeQueryParameters, getGridVisibleColumns, isColumnHidden, pushUrl, QueryParameterDescriptor, serializeQueryParameters, splitCamelCase, useBootstrapBreakpoint, useSubscription } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import api, { SourcesRequest } from "../api";
import { SelectProviderModal } from "./SelectProviderModal";
import styled, { css } from "styled-components";

const ActionButton = styled(Button) <{ disabled: boolean }>`
    opacity: 0.5;
    ${p => !p.disabled && css`
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    `}
`;

const StyledList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`;

const keyDataTypes: { [key: string]: IoCrawlingSourcesOrderBy } = {
    "appId": IoCrawlingSourcesOrderBy.AppId,
    "title": IoCrawlingSourcesOrderBy.Title
};
const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType as IoCrawlingSourcesOrderBy);

function getSearchValueByKey<T>(key: string, searchOptions?: Array<SearchOptions<unknown>>): T | undefined {
    return searchOptions?.find(s => s.key === key)?.value as T;
}

const searchQueryParameterDescriptors: QueryParameterDescriptor[] = [
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    { paramType: "search", paramName: "appId" },
    { paramType: "search", paramName: "title" },
    {
        paramType: "search",
        paramName: "status",
        serializer: (value: string[]) => value?.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "providers",
        serializer: (value: string[]) => value?.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    }
];
const statuses = Object.values(CrawlingStatus);

export const SourcesListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [items, setItems] = useState<IoApplicationSourceSummary[]>([]);
    const [visibleColumns, setVisibleColumns] = useState<string[] | "all" | undefined>();
    const [current, setCurrent] = useState<IoApplicationSourceSummary>();
    const [modalOpen, setModalOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const [providers, setProviders] = useState<string[]>([]);

    const updateProviders = useSubscription(() => defer(() => api.getProviders()).subscribe(setProviders), []);

    useEffect(() => {
        updateProviders();
    }, []);

    const openModal = useCallback((app: IoApplicationSourceSummary) => () => {
        setCurrent(app);
        setModalOpen(true);
    }, []);
    const closeModal = useCallback(() => setModalOpen(false), []);

    const breakpoint = useBootstrapBreakpoint();
    const updateVisibleColumns = useSubscription(() => defer(() => getGridVisibleColumns("IoCrawlingSources"))
        .subscribe(columns => setVisibleColumns(columns ?? "all")), []);

    useEffect(() => {
        updateVisibleColumns();
    }, [breakpoint]);

    const updateItem = useCallback((appId: string, data: Partial<IoApplicationSourceSummary>) => {
        setItems(prev => prev.map(item => item.id !== appId ? item : { ...item, ...data }));
    }, []);

    const crawlAllApps = useSubscription(() => defer(() => api.crawlAllApps())
        .subscribe({
            next: () => {
                setItems([]);
                setSaving(false);
                notifySuccess(`All apps have been added to crawler queue successfully.`);
            },
            error: () => {
                notifyError("Failed to add apps to crawler queue.");
                setSaving(false);
            }
        }), []);

    const crawlAllApplications = useCallback(() => {
        setSaving(true);
        crawlAllApps();
    }, []);

    const [tableFilters, sort] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...searchQueryParameterDescriptors);
        const searchParams = allParams
            .filter(p => p.paramType === "search")
            .map(qp => ({ key: qp.paramName, value: qp.value } as SearchOptions<unknown>));

        const orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        const orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;

        return [searchParams, { key: orderBy, order: orderDir } as SortOptions];
    }, [location.search]);

    const updateData = useSubscription((
        request: SourcesRequest,
        isReload: boolean,
        resolve: (val: boolean) => void) => defer(() => api.getSources(request)).subscribe(result => {
            if (result.length > 0) {
                setItems(prev => isReload ? result : (prev ?? []).concat(result));
            }
            resolve(result.length === 0);
        }), [api.list]);

    const load = useCallback(
        (request: LoadRequest) => {
            if (request.reload) {
                setItems([]);
            }

            const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : IoCrawlingSourcesOrderBy.AppId;
            const searchRequest = {
                orderBy: orderByKey,
                orderDir: orderByKey !== undefined
                    ? request.sort?.order === SortOrder.DESC ? OrderDirection.Desc : OrderDirection.Asc
                    : undefined,
                appId: getSearchValueByKey<string>("appId", request.search),
                title: getSearchValueByKey<string>("title", request.search),
                status: getSearchValueByKey<CrawlingStatus[]>("status", request.search),
                providers: getSearchValueByKey<string[]>("providers", request.search),
            };

            const requestBody: SourcesRequest = {
                offset: request.offset,
                limit: request.limit,
                orderBy: searchRequest.orderBy,
                orderDirection: searchRequest.orderDir ?? OrderDirection.Asc,
                appIdSearch: searchRequest.appId,
                titleSearch: searchRequest.title,
                statusSearch: searchRequest.status,
                availableProvidersSearch: searchRequest.providers,
            };

            const url = routes.ioCrawlingSources.url({
                searchParams: serializeQueryParameters(searchRequest, ...searchQueryParameterDescriptors)
            });
            pushUrl(location, history, url);

            return new Promise<boolean>(resolve => {
                updateData(requestBody, request?.reload, resolve);
            });
        }, [items, location]);

    const tagMapper = useCallback((id: string): SuggestionTag => ({ value: id, label: splitCamelCase(id) }), []);

    const TopPanel = useMemo(() =>
        (props: TopPanelRendererProps) =>
            <props.blockElement>
                <TopPanelLeftContainer>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.ioCrawlingResults}>
                                Crawling Io Apps
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Sources</BreadcrumbItem>
                    </Breadcrumb>
                </TopPanelLeftContainer>
                <props.reloadElement />
                <props.optionsElement />
            </props.blockElement>, []);

    const appIdRenderer = useMemo(() =>
        (props: CellProps<string | undefined, IoApplicationSourceSummary>) => {
            const { cellData, ...restProps } = props;
            const gsAppExists = restProps.rowData.gamestoreApplicationExists;
            const value = gsAppExists ? (
                <RouteLink
                    user={user}
                    to={{ route: routes.editGamestoreIoApplication, args: { appId: cellData!, language: "en" } }}
                    className="d-inline">
                    {props.rowData.id}
                </RouteLink>
            ) : cellData;
            return <DefaultCellRenderer cellData={value} title={cellData} {...restProps} />;
        }, []);

    const textRenderer = useMemo(() =>
        (props: CellProps<string | undefined, IoApplicationSourceSummary>) => {
            const { cellData, ...restProps } = props;
            return <DefaultCellRenderer cellData={cellData} title={cellData} {...restProps} />;
        }, []);

    const statusRenderer = useMemo(() =>
        (props: CellProps<CrawlingStatus | undefined, IoApplicationSourceSummary>) => {
            const { cellData, ...restProps } = props;
            const status = cellData ?? CrawlingStatus.None;
            const title = status !== CrawlingStatus.Failed ? status : restProps.rowData.error;
            const statusText = <CrawlingStatusText status={status} />;
            return <DefaultCellRenderer cellData={statusText} title={title} {...restProps} />;
        }, []);

    const actionsRenderer = useMemo(() =>
        (props: CellProps<string | undefined, IoApplicationSourceSummary>) => {
            const { cellData, ...restProps } = props;
            const app = restProps.rowData;

            const value = (
                <ActionButton color="primary" size="sm" onClick={openModal(app)}>
                    Crawl
                </ActionButton>);

            return <DefaultCellRenderer cellData={value} centerText={true} {...restProps} />;
        }, []);

    const actionsHeaderRenderer = useMemo(() =>
        (props: CellHeaderProps<string | undefined, IoApplicationSourceSummary>) => {
            return (
                <Container className="d-flex justify-content-center">
                    <ActionButton
                        color="primary"
                        size="sm"
                        onClick={crawlAllApplications}
                    >
                        Crawl All
                    </ActionButton>
                </Container>);
        }, []);

    const providersRenderer = useMemo(() =>
        (props: CellProps<IoApplicationProviderData[] | undefined, IoApplicationSourceSummary>) => {
            const { cellData, ...restProps } = props;
            const value = (
                <StyledList>
                    {cellData?.map(p => <li key={p.provider}>{p.provider}</li>)}
                </StyledList>
            );
            const title = cellData?.map(p => p.provider).join(", ") ?? "";
            return <DefaultCellRenderer cellData={value} title={title} {...restProps} />;
        }, []);

    const select = makeSelect<IoApplicationSourceSummary>();

    return (
        <VerticalBox>
            <DocumentTitle title="Io Applications Sources" />
            <SelectProviderModal
                isOpen={modalOpen}
                app={current}
                close={closeModal}
                updateItem={updateItem}
            />
            <Content overflowHidden>
                {!visibleColumns ? <Loader /> : (
                    <InfiniteTable
                        items={items}
                        load={load}
                        filters={tableFilters}
                        sortBy={sort}
                        topPanelRenderer={TopPanel}
                        visibleColumns={visibleColumns}
                        disabled={saving}
                    >
                        <Column
                            key="appId"
                            dataGetter={select(a => a.id)}
                            title="AppID"
                            width={320}
                            cellRenderer={appIdRenderer}
                            searchRenderer={StringSearch}
                            hidden={isColumnHidden("appId", visibleColumns)}
                        />
                        <Column
                            key="title"
                            dataGetter={select(a => a.title)}
                            title="Title"
                            width={320}
                            cellRenderer={textRenderer}
                            searchRenderer={StringSearch}
                            hidden={isColumnHidden("title", visibleColumns)}
                        />
                        <Column
                            key="status"
                            dataGetter={select(a => a.status)}
                            title="Status"
                            width={200}
                            sortable={false}
                            cellRenderer={statusRenderer}
                            searchRenderer={multiValueSearchFactory(statuses, tagMapper)}
                            hidden={isColumnHidden("status", visibleColumns)}
                        />
                        <Column
                            key="provider"
                            dataGetter={select(a => a.provider)}
                            title="Crawl provider"
                            width={200}
                            sortable={false}
                            cellRenderer={textRenderer}
                            hidden={isColumnHidden("provider", visibleColumns)}
                        />
                        <Column
                            key="preferredProvider"
                            dataGetter={select(a => a.preferredProvider)}
                            title="Preferred provider"
                            width={200}
                            sortable={false}
                            cellRenderer={textRenderer}
                            hidden={isColumnHidden("preferredProvider", visibleColumns)}
                        />
                        <Column
                            key="providers"
                            dataGetter={select(a => a.providers)}
                            title="Available providers"
                            width={200}
                            sortable={false}
                            cellRenderer={providersRenderer}
                            searchRenderer={multiValueSearchFactory(providers, tagMapper)}
                            hidden={isColumnHidden("providers", visibleColumns)}
                        />
                        <Column
                            key="actions"
                            dataGetter={select(a => a.id)}
                            width={300}
                            sortable={false}
                            cellRenderer={actionsRenderer}
                            headerRenderer={actionsHeaderRenderer}
                            hidden={isColumnHidden("actions", visibleColumns)}
                        />
                    </InfiniteTable>
                )}
            </Content>
        </VerticalBox>
    );
});