import React, { useMemo, useState, useEffect, forwardRef, useRef, useImperativeHandle, useCallback } from "react";
import { Form, Input, Button, FormGroup, Label, Row, Col } from "reactstrap";
import { AdCampaign, SourceSettings } from "src/shared/dtos";
import { SelectOption, setFromInput } from "src/shared/helpers";
import { EditField, FormLabel, Heading, Select } from "src/shared/components";
import { AdUnitListView } from "./AdUnitListView";
import { IUser } from "src/shared/client";
import { Styles } from "react-select";

type SettingsOption = SelectOption<number | undefined>;
const mapOptions = (settings: SourceSettings): SettingsOption => ({ value: settings.id, label: settings.source });

export interface EditFormRef {
    validate: () => boolean;
}

interface Props {
    value: AdCampaign;
    initial?: AdCampaign;
    isNew: boolean;
    saving: boolean;
    sourceSettingsList: SourceSettings[];
    update: React.Dispatch<Partial<AdCampaign> | undefined>;
    save: () => void;
    user: IUser | null;
}

export const EditForm = forwardRef((props: Props, ref: React.MutableRefObject<EditFormRef>) => {
    const setName = (v: string) => props.update({ name: v ? v : undefined });
    const setSourceSettings = (v: SettingsOption[]) => props.update({ sourceSettingsIds: v === null ? [] : v.map(c => c.value!) });

    const sourceOptions = useMemo(() => props.sourceSettingsList.filter(c => !c.isDeleted).map(mapOptions), [props.sourceSettingsList]);
    const sourceSettingsById = useMemo(() => new Map(props.sourceSettingsList.map(s => [s.id, s])), [props.sourceSettingsList]);

    const selectedSourceOptions = useMemo(() =>
        props.value.sourceSettingsIds?.map(sourceId => sourceSettingsById.get(sourceId)).filter(s => s).map(mapOptions) ?? [],
        [sourceSettingsById, props.value.sourceSettingsIds]);

    const [allSourcesSelected, setAllSourcesSelected] = useState<boolean>(!props.isNew && props.initial?.sourceSettingsIds?.length === 0);

    const changeAllSourcesSelected = (v: boolean) => {
        if (v) {
            setSourceSettings([]);
        }
        setAllSourcesSelected(v);
    };

    const deletedSourcesIds = useMemo(() =>
        new Set(props.sourceSettingsList.filter(s => s.isDeleted).map(v => v.id)), [props.sourceSettingsList.length]);

    const setIsDisabled = useCallback((v: boolean) => {
        if (props.value.sourceSettingsIds !== undefined && v) {
            props.update({ sourceSettingsIds: props.value.sourceSettingsIds.filter(srcId => !deletedSourcesIds.has(srcId)) });
        }

        props.update({ isDisabled: !v });
    }, [deletedSourcesIds, props.value.sourceSettingsIds, props.update]);

    const customStyles = useMemo<Partial<Styles>>(() => ({
        multiValue: (styles, { data }: { data: SettingsOption }) => ({
            ...styles,
            backgroundColor: deletedSourcesIds.has(data.value!) ? "#fecabd" : "#ddd",
        }),
        multiValueRemove: (styles, { data }: { data: SettingsOption }) => ({
            ...styles,
            color: deletedSourcesIds.has(data.value!) ? "#fecabd" : "#ddd",
        }),
    }), [deletedSourcesIds]);

    const hasDeletedSources = useMemo(
        () => props.value.sourceSettingsIds?.some(srcId => deletedSourcesIds.has(srcId)) ?? false, [props.value.sourceSettingsIds, deletedSourcesIds]);

    const [validate, setValidate] = useState(false);
    const [isSourcesValid, setIsSourcesValid] = useState(true);
    const sourcesFieldRef = useRef<HTMLDivElement>(null);

    const validateFields = (scrollToInvalid: boolean = false): boolean => {
        const sourcesValidationResult = allSourcesSelected || selectedSourceOptions.length > 0;
        setIsSourcesValid(sourcesValidationResult);

        if (scrollToInvalid) {
            if (!sourcesValidationResult) {
                sourcesFieldRef.current?.scrollIntoView({ behavior: "smooth", block: "nearest" });
            }
        }

        return sourcesValidationResult;
    };

    useEffect(() => {
        if (validate) {
            validateFields();
        }
    }, [validate, props.value.id, allSourcesSelected, selectedSourceOptions]);

    useImperativeHandle(ref, () => ({
        validate: () => {
            setValidate(true);
            return validateFields(true);
        }
    }), [sourcesFieldRef.current, allSourcesSelected, selectedSourceOptions]);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.save();
    };

    const sourceSettingsError = useMemo(() => hasDeletedSources
        ? <><strong>Warning!</strong> The targeted source(s) have been deleted and the Ad Campaign is no longer active. Please select a valid source.</>
        : !isSourcesValid ? "Sources must be selected." : null, [hasDeletedSources, isSourcesValid]);

    return (
        <Form onSubmit={save} noValidate>
            <Row>
                <Col sm={8}>
                    <EditField
                        label="Name"
                        fieldId="ad-campaign-name"
                        isChanged={props.value.name !== props.initial?.name}
                    >
                        <Input
                            type="text"
                            id="ad-campaign-name"
                            name="ad-campaign-name"
                            value={props.value.name}
                            onChange={setFromInput(setName)}
                            disabled={props.saving}
                        />
                    </EditField>
                </Col>
                <Col sm={4} className="text-right">
                    <Button
                        type="submit"
                        color="primary"
                        className="ml-2 mb-2"
                        disabled={props.saving}
                    >{props.isNew ? "Add" : "Save"} campaign</Button>
                </Col>
            </Row>
            <EditField>
                <FormGroup check>
                    <Label for="isEnabled" check>
                        <Input
                            type="checkbox"
                            id="isEnabled"
                            name="isEnabled"
                            checked={!props.value.isDisabled}
                            onChange={setFromInput(setIsDisabled)}
                            disabled={props.saving}
                        />{" "}
                        <FormLabel
                            isChanged={props.value.isDisabled !== props.initial?.isDisabled}
                            originalValue={props.isNew ? undefined : props.initial?.isDisabled}>
                            Enabled
                        </FormLabel>
                    </Label>
                </FormGroup>
            </EditField>

            <Heading label="Targeting" />
            <Col sm={12}>
                <FormGroup check>
                    <Label for="allSourcesSelected" check>
                        <Input
                            type="checkbox"
                            id="allSourcesSelected"
                            name="allSourcesSelected"
                            checked={allSourcesSelected}
                            onChange={setFromInput(changeAllSourcesSelected)}
                            disabled={props.saving}
                        />{" "}
                        All sources
                    </Label>
                </FormGroup>
            </Col>
            <EditField
                ref={sourcesFieldRef}
                label={"Marketing Sources"}
                fieldId="sourceSettings"
                isChanged={props.value.sourceSettingsIds?.join() !== props.initial?.sourceSettingsIds?.join()}
                errors={sourceSettingsError ? [sourceSettingsError] : undefined}
            >
                <Select
                    id="sourceSettings"
                    placeholder="Marketing Sources"
                    value={selectedSourceOptions}
                    onChange={setSourceSettings}
                    options={sourceOptions}
                    isMulti={true}
                    isSearchable={true}
                    styles={customStyles}
                    isDisabled={props.saving || allSourcesSelected}
                />
            </EditField>
            {!props.isNew && (
                <React.Fragment>
                    <Heading label="Ad Units" />
                    <AdUnitListView adCampaignId={props.value?.id} user={props.user} />
                </React.Fragment>
            )}
        </Form>
    );
});
