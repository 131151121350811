import React from "react";
import { BreadcrumbItem, Col, Row } from "reactstrap";
import {
    Breadcrumb,
    RouteLink,
} from "src/shared/components";
import { routes } from "src/shared/routes";
import "react-datepicker/dist/react-datepicker.css";
import { IUser } from "src/shared/client";
import styled from "styled-components";
import { Breakpoints } from "../mixin";

const HeaderRow = styled(Row)`
    display: flex;
    min-height: 50px;
    padding: 2px 0.5rem;
    box-shadow: #eee 0px 2px 4px 2px;
    margin: 0;
    margin-bottom: 1rem;
`;

const HeaderNav = styled(Col)`
    display: flex;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    ${Breakpoints.xl`
        margin-bottom: 0;
        margin-top: 0;
    `}
`;

const HeaderContent = styled(Col)`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: flex-end;
    ${Breakpoints.xl`
        margin-bottom: 0;
    `}
`;

type Props = {
    title: string;
    user: IUser | null;
};

export const StatisticsHeader = (props: React.PropsWithChildren<Props>) => {
    return (
        <HeaderRow>
            <HeaderNav xl={3}>
                <Breadcrumb>
                    <BreadcrumbItem>
                        <RouteLink user={props.user} to={routes.home}>
                            Home
                        </RouteLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>{props.title}</BreadcrumbItem>
                </Breadcrumb>
            </HeaderNav>
            <HeaderContent sm={{ size: 9, offset: 3 }} md={{ size: 6, offset: 6 }} xl={{ size: 9, offset: 0 }}>
                <Row className="m-0">
                    {props.children}
                </Row>
            </HeaderContent>
        </HeaderRow>
    );
};