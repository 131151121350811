import React, { useCallback, useState, forwardRef, useImperativeHandle } from "react";
import { Input, Button, Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AdBannerSize, AdBannerUnit } from "src/shared/dtos";
import { highlightTargetUrl, LinkButton, renderImage, StyledCodeEditor, TargetUrlPlaceholdersTip } from "./EditForm";
import { setFromInput, ValidateAsyncRef, useValidate } from "src/shared/helpers";
import { ImageSelector, EditField } from "src/shared/components";
import { bannerUnitValidationSchema } from "./validationSchema";

interface Props {
    value: AdBannerUnit;
    initial: AdBannerUnit;
    update: React.Dispatch<Partial<AdBannerUnit> | undefined>;
    saving: boolean;
    bannerSizes: AdBannerSize[];
}

export const EditBannerUnit = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const { value, initial, update, bannerSizes, saving } = props;

    const [isStandardDimensionModalOpen, setIsStandardDimensionModalOpen] = useState<boolean>(false);
    const closeStandardDimensionModal = () => (setIsStandardDimensionModalOpen(false));
    const openStandardDimensionModal = () => (setIsStandardDimensionModalOpen(true));

    const setHeader = useCallback(
        (v: string) => update({ bannerHeader: v }), [update]);

    const setImageUrl = useCallback(
        (url: string) => {
            update({ bannerImageUrl: url });
            return url;
    }, [update]);

    const setUrl = useCallback(
        (bannerUrl: string) => update({ bannerUrl }), [update]);

    const { validate, errors, register } = useValidate({ schema: bannerUnitValidationSchema, value: props.value });

    useImperativeHandle(ref, () => ({
        validate: async () => (await validate(true)).isValid
    }), [validate]);

    return (
        <React.Fragment>
            <EditField
                ref={register("bannerHeader")}
                label="Banner Header"
                fieldId="bannerHeader"
                isChanged={value.bannerHeader !== initial.bannerHeader}
            >
                <Input
                    type="text"
                    id="bannerHeader"
                    value={value.bannerHeader ?? ""}
                    onChange={setFromInput(setHeader)}
                    disabled={saving}
                />
            </EditField>
            <EditField
                ref={register("bannerImageUrl")}
                label="Banner Image URL"
                fieldId="bannerImageUrl"
                isChanged={value.bannerImageUrl !== initial.bannerImageUrl}
                errors={errors?.bannerImageUrl}
            >
                {(props.value.id !== 0
                    && bannerSizes !== undefined && bannerSizes?.length > 0
                    && bannerSizes.find(s => s.sizeX === value.bannerSizeX
                    && s.sizeY === value.bannerSizeY) === undefined) && (
                    <Alert color="warning" className="text-center">
                        Warning! The image dimension is {value.bannerSizeX}
                            x{value.bannerSizeY} which is not a&nbsp;
                        <LinkButton onClick={openStandardDimensionModal}>standard banner dimension</LinkButton>.
                    </Alert>
                )}
                <Modal isOpen={isStandardDimensionModalOpen} toggle={closeStandardDimensionModal}>
                    <ModalHeader toggle={closeStandardDimensionModal}>
                        Standard banner dimension
                    </ModalHeader>
                    <ModalBody>
                        {bannerSizes !== undefined && bannerSizes?.length > 0 && (
                            <ul className="list-group">
                                {bannerSizes.sort((a, b) => (a.sizeX - b.sizeX) * 1000 + (a.sizeY - b.sizeY)).map(adBannerSize => {
                                    return (
                                        <li key={adBannerSize.id} className="list-group-item list-group-item-action">
                                            {adBannerSize.sizeX} x {adBannerSize.sizeY}
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" type="button" onClick={closeStandardDimensionModal}>Close</Button>
                    </ModalFooter>
                </Modal>
                <ImageSelector
                    setInputId="bannerImageUrl"
                    value={value.bannerImageUrl}
                    set={setImageUrl}
                    render={renderImage}
                    deleteButtonHidden={value.bannerImageUrl ? true : false}
                    imageDimensions={{ width: "32rem" }}
                    disabled={saving} />
            </EditField>
            <EditField
                ref={register("bannerUrl")}
                label="Target URL"
                fieldId="bannerUrl"
                isChanged={value.bannerUrl !== initial.bannerUrl}
                errors={errors?.bannerUrl}
            >
                <StyledCodeEditor
                    id="bannerUrl"
                    value={value.bannerUrl ?? ""}
                    onValueChange={setUrl}
                    highlight={highlightTargetUrl}
                    padding={10}
                    tabSize={4}
                    insertSpaces={true}
                    disabled={saving}
                />
                <TargetUrlPlaceholdersTip />
            </EditField>
            <EditField
                label="Banner Size X"
                fieldId="bannerSizeX"
                isChanged={value.bannerSizeX !== initial.bannerSizeX}
            >
                <Input
                    type="text"
                    id="bannerSizeX"
                    value={value.bannerSizeX ?? ""}
                    disabled={true}
                />
            </EditField>
            <EditField
                label="Banner Size Y"
                fieldId="bannerSizeY"
                isChanged={value.bannerSizeY !== initial.bannerSizeY}
            >
                <Input
                    type="text"
                    id="bannerSizeY"
                    value={value.bannerSizeY ?? ""}
                    disabled={true}
                />
            </EditField>
        </React.Fragment>
    );
});