import React, { FC, useMemo, useEffect, useState } from "react";
import { Options as ChartOptions } from "highcharts";
import { ChartTypes, TotalGamingMinutesById } from "src/shared/dtos";
import { getHighChartsType, getStartDate } from "./utils";
import { useSubscription } from "src/shared/helpers";
import { defer } from "rxjs";
import api, { StartEndDate } from "./api";
import { ChartWidgetContent } from "./ChartWidgetContent";
import { ChartWidgetProps } from "./ChartWidget";

const makeChartOptions = (data: TotalGamingMinutesById[], chartType: ChartTypes): ChartOptions => {
    const chartData = data.map(g => ({ name: g.applicationId, y: g.totalMinutes }));
    return {
        title: {
            style: {
                display: "none"
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}",
                }
            }
        },
        series: [{
            name: "Gaming Minutes",
            type: getHighChartsType(chartType),
            data: chartData
        }]
    };
};

export const GamingMinutesByIdChartWidget: FC<ChartWidgetProps> = ({ chartWidget, resizeTrigger, setLoading }) => {
    const [data, setData] = useState<TotalGamingMinutesById[]>([]);
    const [actualEndDate, setActualEndDate] = useState(new Date());

    const requestDates = useMemo<StartEndDate>(() => ({
        startDate: getStartDate(new Date(), chartWidget.timeSpan).toISOString(),
        endDate: new Date().toISOString()
    }), [chartWidget.timeSpan]);

    const actualDates = useMemo<[Date, Date]>(
        () => ([getStartDate(actualEndDate, chartWidget.timeSpan), actualEndDate]), [actualEndDate, chartWidget.timeSpan]);

    const getGamingMinutesById = useSubscription((dates: StartEndDate) => defer(
        () => api.getGamingMinutesById({ ...dates, limit: 10 }))
        .subscribe(val => {
            setActualEndDate(new Date(val.endDate));
            setData(val.items ?? []);
            setLoading(false);
        }),
        [api.getGamingMinutesById]);

    useEffect(() => {
        setLoading(true);
        getGamingMinutesById(requestDates);
    }, [requestDates]);

    const chartOptions = useMemo<ChartOptions>(
        () => makeChartOptions(data, chartWidget.chartType), [chartWidget, data]);

    return (
        <ChartWidgetContent
            startDate={actualDates[0]}
            endDate={actualDates[1]}
            resizeTrigger={resizeTrigger}
            chartOptions={chartOptions}
        />
    );
};