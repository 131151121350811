import { validateUrl } from "src/shared/components/propertyField/validation/validateUrl";
import { ApkPlatform, GoogleAdditionalFile, GoogleApplicationLocalization, GoogleSplitFile, UrlValidationType } from "src/shared/dtos";
import { isNullOrUndefined, validateSource, validateSources, validateUrlField, validateUrls, FieldValidationFunc, ValidationSchema, getPlatformName } from "src/shared/helpers";
import { GoogleAppMainInfo } from "./googleApplicationReducer";
import { PlatformDetailsItem } from "./platformDetailsReducer";

type PlatformSplitFiles = Record<ApkPlatform, GoogleSplitFile[]>;

const validateSplitFiles: FieldValidationFunc<GoogleSplitFile[], GoogleApplicationLocalization> = (files) => {
    const filesByPlatform = files.reduce((res, file) => {
        res[file.platform] = (res[file.platform] ?? []).concat(file);
        return res;
    }, {} as PlatformSplitFiles);

    return Object.entries(filesByPlatform).reduce((errors, [platform, platformFiles]) => {
        const urls = platformFiles.map(f => f.fileDownloadUrl);
        const platformErrors = urls
            .map(url => validateUrl(url, UrlValidationType.Absolute))
            .map((err, idx) => err === null ? null : `line #${idx + 1}: ${err}`)
            .filter(err => err !== null)
            .map(err => `${getPlatformName(platform as ApkPlatform)} platform - ${err}`);

        return errors.concat(platformErrors);
    }, [] as string[]);
};

export const localizationValidationSchema: ValidationSchema<GoogleApplicationLocalization> = {
    iconUrl: url => validateUrlField({ url, isRequired: true }),
    screenshots: urls => validateUrls({ urls, itemName: "Screenshot" }),
    videos: urls => validateUrls({ urls, itemName: "Video" }),
    splitFiles: validateSplitFiles
};

export const localizationMediaValidationSchema: ValidationSchema<GoogleApplicationLocalization> = {
    iconUrl: url => validateSource({ url, type: "image" }),
    screenshots: urls => validateSources({ urls, itemName: "Screenshot", type: "image" }),
    videos: urls => validateSources({ urls, itemName: "Video", type: "youTube" }),
};

export const applicationValidationSchema: ValidationSchema<GoogleAppMainInfo> = {
    category: (_, data) => !data?.categoryId ? "Category must be added" : null,
    id: val => !val ? "Id is required" : null
};

const validateApkDownloadUrl: FieldValidationFunc<string, PlatformDetailsItem> = (url, apk) => {
    if (!apk.enabled) {
        return null;
    }
    return isNullOrUndefined(url) ? "You have to add an APK Download URL for enabled platform." : validateUrl(url!, UrlValidationType.Absolute);
};

const validateAdditionalFiles: FieldValidationFunc<GoogleAdditionalFile[], PlatformDetailsItem> = (files) => {
    const fileUrls = files.map(f => f.fileDownloadUrl);
    return validateUrls({ urls: fileUrls, itemName: "Line" });
};

export const apkDetailsValidationSchema: ValidationSchema<PlatformDetailsItem> = {
    apkDownloadUrl: validateApkDownloadUrl,
    additionalFiles: validateAdditionalFiles,
};
