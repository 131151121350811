import { ColorOption, PropertyDataType, PropertyMetadata, SourceSettingsData } from "src/shared/dtos";
import { isNullOrUndefined, validateSource, validateSources, validateUrlField, validateUrls, ValidationSchema } from "src/shared/helpers";

const validateColorOptions = (colorOptions: ColorOption[]): string[] => {
    const hexRegExp = /^#[A-Fa-f0-9]{6}(\t|\n)*$/;
    return colorOptions.map((op, i) => {
        const color = op.color?.trim();
        if (!color){
            return `Line #${i + 1}: Color is required`;
        }
        if (hexRegExp.test(color)){
            return null;
        }
        return `Line #${i + 1}: Invalid color ${color}. Color should be in HEX format (\"#******\") where ****** are a/A-f/F and 0-9.`;
    }).filter(v => v !== null) as string[];
};

export const makeValidationSchema = (metadata: PropertyMetadata[]): ValidationSchema<SourceSettingsData> => {
    return metadata.reduce((schema, meta) => {
        if (meta.propertyType === PropertyDataType.MultiValueEditor && meta.validateUrl) {
            schema[meta.propertyName] = (urls: string[] | undefined) => isNullOrUndefined(urls)
                ? null
                : validateUrls({ urls, itemName: "Line", urlType: meta.validateUrl });
        }
        else if (
            [PropertyDataType.SingleLineUrlEditorWithPreview, PropertyDataType.SingleLineEditor].includes(meta.propertyType) && meta.validateUrl) {
            schema[meta.propertyName] = (url: string | undefined) => validateUrlField({ url, urlType: meta.validateUrl });
        }
        else if (meta.propertyType === PropertyDataType.InstallerColorOptionsEditor) {
            schema[meta.propertyName] = (colors: ColorOption[] | undefined) => isNullOrUndefined(colors) ? null : validateColorOptions(colors);
        }
        return schema;
    }, {} as ValidationSchema<SourceSettingsData>);
};

export const mediaValidationSchema: ValidationSchema<SourceSettingsData> = {
    gameNotFoundImageUrl: url => validateSource({ url, type: "image" }),
    defaultGameIconUrl: url => validateSource({ url, type: "image" }),
    downloaderImageUrls: urls => isNullOrUndefined(urls) ? null : validateSources({ urls, type: "image", itemName: "Line" }),
    installationTaskbarIconUrl: url => validateSource({ url, type: "image" }),
    gameIntroVideoUrl: url => validateSource({ url, type: "video" }),
    generalControlsLandscapeImageUrl: url => validateSource({ url, type: "image" }),
    generalControlsPortraitImageUrl: url => validateSource({ url, type: "image" }),
    installationLeftCornerIconUrl: url => validateSource({ url, type: "image" }),
    repairWindowImageUrl: url => validateSource({ url, type: "image" }),
    updateImageUrls: urls => isNullOrUndefined(urls) ? null : validateSources({ urls, type: "image", itemName: "Line" }),
    uninstallIconUrl: url => validateSource({ url, type: "image" }),
};