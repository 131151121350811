import React from "react";
import { AdPixelType, AdUnitTypes } from "src/shared/dtos";
import styled from "styled-components";
import { DefaultCellRenderer, DefaultCellRendererProps } from ".";

const StyledCellRenderer = styled(DefaultCellRenderer) <{ highlight?: boolean }>`
    color: ${props => props.highlight ? "red" : ""};
    font-weight: ${props => props.highlight ? "bold" : ""};
`;

interface Props<TItem> extends DefaultCellRendererProps<TItem> {
    unitType: AdUnitTypes;
    pixelType?: AdPixelType;
    sizeX?: number;
    sizeY?: number;
    isNonStandard?: boolean;
}

export function BannerDimensionRenderer<TItem>(props: Props<TItem>) {
    const { unitType, pixelType, sizeX, sizeY, isNonStandard, cellData, ...restProps } = props;
    let pixelTypeText = "";

    switch (pixelType) {
        case AdPixelType.Dip:
            pixelTypeText = "(dp)";
            break;
        case AdPixelType.Percent:
            pixelTypeText = "(%)";
            break;
        case AdPixelType.Pixel:
            pixelTypeText = "(px)";
            break;
    }

    const data = unitType === AdUnitTypes.RichMediaOverlay || unitType === AdUnitTypes.BannerAction
        ? `${sizeX}x${sizeY} ${pixelTypeText}`
        : undefined;
    return <StyledCellRenderer highlight={isNonStandard} cellData={data} title={data} {...restProps} />;
}