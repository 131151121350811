import { AdUnitTypes } from "../dtos";

/**
 * Convert AdUnitTypes to string value.
 * @param unitType The unit type value.
 * @returns The plain text.
 */
export const adUnitTypeToString = (unitType: AdUnitTypes): string => {
    switch (unitType) {
        case AdUnitTypes.BannerAction:
            return "Banner";
        case AdUnitTypes.BrowserAction:
            return "Browser";
        case AdUnitTypes.RichMediaOverlay:
            return "Rich Media Overlay";
        case AdUnitTypes.DynamicWeb:
            return "Dynamic Web";
        case AdUnitTypes.SideAd:
            return "Side Ad";
        default:
            return "default";
    }
};