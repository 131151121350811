import { useCallback, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { QueryParametersSerializer, QueryParamsSerializerOptions } from "./QueryParametersSerializer";

type Props<TParams extends object> = Pick<RouteComponentProps, "history" | "location"> & {
    options: QueryParamsSerializerOptions<TParams>
};

export const useQueryState = <TParams extends object>(props: Props<TParams>) => {
    const { options, location, history } = props;
    const queryParametersSerializer = new QueryParametersSerializer(options);

    const state = useMemo(() => queryParametersSerializer.deserialize(location.search),
        [options, location.search]);

    const setState = useCallback((arg: Partial<TParams> & ((v: Partial<TParams>) => Partial<TParams>)) => {
        const value = typeof arg === "function" ? arg(state) : arg;
        const params = queryParametersSerializer.serialize(value);
        const queryString = Object.entries(params).map(([k, v]) => `${k}=${v}`).join("&");

        history.push(`${location.pathname}?${queryString}`);
    }, [history, location, options, state]);

    return [state, setState] as [Partial<TParams>, (val: Partial<TParams> | ((v: Partial<TParams>) => Partial<TParams>)) => void];
};