import { GamestoreIoApplication, IoApplication } from "src/shared/dtos";
import { deepEqual } from "src/shared/helpers";

export type GamestoreIoAppMainInfo = Omit<GamestoreIoApplication, "localizations">;

export type ApplicationState = {
    current?: GamestoreIoAppMainInfo,
    initial?: GamestoreIoAppMainInfo,
    changed: boolean
};

const getAppMainInfo = (app: GamestoreIoApplication): GamestoreIoAppMainInfo => {
    const { localizations, ...mainInfo } = app;
    return mainInfo;
};

export type ApplicationAction =
    { kind: "reset" } |
    { kind: "set", value: GamestoreIoApplication, initialValue: GamestoreIoApplication } |
    { kind: "update", value: Partial<GamestoreIoAppMainInfo> } |
    { kind: "set-io-app", value: IoApplication };

export const applicationReducer = (state: ApplicationState, action: ApplicationAction) => {
    switch (action.kind) {
        case "reset": {
            state = {
                current: undefined,
                initial: undefined,
                changed: false
            };
            break;
        }
        case "set": {
            state = {
                current: getAppMainInfo(action.value),
                initial: getAppMainInfo(action.initialValue),
                changed: false
            };
            break;
        }
        case "update": {
            if (!state.current){
                break;
            }
            Object.assign(state.current, action.value);
            state.changed = !deepEqual(state.current, state.initial);
            break;
        }
        case "set-io-app": {
            if (state.current === undefined) {
                break;
            }

            state.current.ioApplication = action.value;
            state.current.categories = [action.value.category];
            state.current.categoryIds = [action.value.category.id];
            break;
        }
    }

    return Object.assign({}, state);
};

export const applicationReducerDefault: ApplicationState = {
    current: undefined,
    initial: undefined,
    changed: false
};