import React, { useEffect, useMemo, useState } from "react";
import { PropertyEditorProps } from "../PropertyEditorProps";
import { defer } from "rxjs";
import { PinnedApplicationShortDetails } from "src/shared/dtos";
import { SelectOption, useDebounce, useSubscription } from "src/shared/helpers";
import { Select } from "src/shared/components";
import api from "src/components/SourceSettings/api";

export type ApplicationOption = SelectOption<string | undefined> & { data: PinnedApplicationShortDetails };

const mapOptions = (application: PinnedApplicationShortDetails): ApplicationOption => ({
    value: application.id,
    label: <React.Fragment>
        <img src={application.iconUrl} height="32px" /> {application.title} ({application.id})
    </React.Fragment>,
    data: application
});

export const ApplicationSelectPropertyEditor = (props: PropertyEditorProps<string[] | undefined>) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [idSearch, setIdSearch] = useState<string>("");
    const [availableApps, setAvailableApps] = useState<PinnedApplicationShortDetails[]>([]);
    const [selectedApps, setSelectedApps] = useState<PinnedApplicationShortDetails[]>([]);

    const searchId = (v: string) => setIdSearch(v);
    const debounceIdSearch = useDebounce(idSearch, 300);

    const updateSelectedApps = useSubscription((ids: string[]) => defer(
        () => api.getApplicationsDetails(ids))
        .subscribe({
            next: apps => setSelectedApps(apps),
            complete: () => setLoading(false)
        }), []);

    useEffect(() => {
        if (props.value && props.value.length > 0) {
            setLoading(true);
            updateSelectedApps(props.value);
        }
    }, []);

    const updateAvailableApps = useSubscription((appIdSearch: string) => defer(
        () => api.getAvailableApplications(appIdSearch))
        .subscribe({
            next: apps => setAvailableApps(apps),
            complete: () => setLoading(false)
        }), []);

    useEffect(() => {
        setLoading(true);
        updateAvailableApps(debounceIdSearch);
    }, [debounceIdSearch]);

    const setSelectedApplicationIds = (selectedOps: ApplicationOption[]) => {
        props.onChange(selectedOps === null ? [] : selectedOps.map(c => c.value!));
        setSelectedApps(selectedOps === null ? [] : selectedOps.map(o => o.data));
    };

    const applicationsOptions = useMemo(() => availableApps.map(mapOptions), [availableApps]);

    const selectedApplicationsOptions = useMemo(
        () => selectedApps.map(mapOptions),
        [selectedApps]);

    const applicationFilterOption = (option: any, inputValue: string) => {
        const loweredInputValue = inputValue.toLowerCase();
        return (
            option?.value?.toLowerCase().includes(loweredInputValue) ||
            option?.data?.data?.title?.toLowerCase().includes(loweredInputValue)
        ) ?? false;
    };

    return (
        <React.Fragment>
            <div>
                <Select
                    id="applications"
                    placeholder="Default pinned applications"
                    onInputChange={searchId}
                    isLoading={loading}
                    value={selectedApplicationsOptions}
                    onChange={setSelectedApplicationIds}
                    options={applicationsOptions}
                    isMulti={true}
                    isSearchable={true}
                    isDisabled={props.disabled}
                    filterOption={applicationFilterOption}
                    style={{ display: "flex", position: "relative" }}
                />
            </div>
        </React.Fragment>
    );
};