import React, { forwardRef, useMemo } from "react";
import { Col, FormGroup, Label } from "reactstrap";
import { FormLabel } from "src/shared/components";
import styled from "styled-components";

const CustomTooltipContainer = styled.div`
    position: relative;
`;

const CustomTooltip = styled.div`
    background-color: #d9534f;
    max-width: max-content;
    white-space: nowrap;
    width: max-content;
    border-color: #d9534f;
    opacity: 0.9;
    font-size: 0.875rem;
    color: #fff;
    text-align: center;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    position: absolute;
    z-index: 100;
    left: 50%;
    transform: translate(-50%, 6px);
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: -6px;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #d9534f;
    }
`;

type EditFieldProps = {
    label?: string | React.ReactNode;
    fieldId?: string;
    isChanged?: boolean;
    originalValue?: unknown;
    fullWidth?: boolean;
    errors?: Array<JSX.Element | string>;
    warnings?: string[];
};

export const EditField = forwardRef<HTMLDivElement, React.PropsWithChildren<EditFieldProps>>((props, scrollRef) => {
    const { children, label, fieldId, errors, ...labelProps } = props;
    const showErrors = useMemo(() => errors !== undefined && errors.length > 0, [errors]);

    return (
        <Col md={props.fullWidth === true ? 12 : 6}>
            <FormGroup>
                {label !== undefined &&
                    <Label for={fieldId}>
                        <FormLabel showWarnings={!showErrors} {...labelProps}>
                            {label}
                        </FormLabel>
                    </Label>}
                {props.children}
                {showErrors && (
                    <CustomTooltipContainer>
                        <CustomTooltip>
                            {errors!.slice(0, 4).map((err, i) => <div key={`${err.toString()}_${i}`}>{err}</div>)}
                        </CustomTooltip>
                    </CustomTooltipContainer>
                )}
                <div ref={scrollRef} />
            </FormGroup>
        </Col>
    );
});