import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState, forwardRef, useRef } from "react";
import { Row, Col } from "reactstrap";
import { SourceSettingsItem } from "./sourceSettingsReducer";
import { SourceSettingsData, PropertyMetadata, SourceSettings, SourceSettingsMetadata } from "src/shared/dtos";
import { PropertyField } from "src/shared/components/propertyField";
import styled from "styled-components";
import { ThinScrollbar } from "src/shared/components/mixin";
import { Observable, of, from } from "rxjs";
import { EditField, LoadingButton } from "src/shared/components";
import { switchMap } from "rxjs/operators";
import { getObjectFieldsArray } from "src/shared/helpers";
import { useValidate } from "src/shared/helpers";
import { makeValidationSchema, mediaValidationSchema } from "./validationSchema";
import { useConfirm } from "src/shared/helpers/useConfirm";
import { ConfirmFailedSources } from "src/shared/components/confirmWindow/samples/ConfirmFailedSources";
import { PreviewComponent } from "./PreviewComponent";
import { AliasesListEditor } from "src/shared/AliasesListEditor";

const Separator = styled.div`
    border-bottom: 1px solid lightgray;
    margin-left: 15px;
    width: 100%;
    padding-top: 10px;
`;

const PropertyContainer = styled.div`
    ${ThinScrollbar}
    height: 94%;
    overflow-y: auto;
    overflow-x: hidden;
`;

export interface EditSourceSettingsRef {
    validate: () => Observable<boolean>;
}

type Props = {
    settingsMetadata: SourceSettingsMetadata;
    item: SourceSettingsItem;
    update: (data:
        { newSource?: string, newSettings?: Partial<SourceSettingsData>, newApplicationIds?: string[], newAliases?: string[] }
    ) => void;
    save: () => void;
    saving: boolean;
    sourcesList: SourceSettings[];
};

export const EditSourceSettings = forwardRef((props: Props, ref: React.MutableRefObject<EditSourceSettingsRef>) => {
    const updateSettings = useCallback((value: SourceSettingsData) => props.update({ newSettings: value }), [props.update]);
    const updateAliases = useCallback((value?: string[]) => props.update({ newAliases: value }), [props.update]);
    const settings = props.item.current.settings;
    const { confirm } = useConfirm();

    const validationSchema = useMemo(() => makeValidationSchema(props.settingsMetadata.properties), [props.settingsMetadata]);
    const { errors, register, validate: validateFields } = useValidate({ schema: validationSchema, value: settings });
    const { errors: warnings, validate: validateMedia } = useValidate({ schema: mediaValidationSchema, value: settings, isActive: true });

    const aliasesFieldRef = useRef<HTMLDivElement>(null);
    const [aliasesError, setAliasesError] = useState<string | null>(null);

    const sourcesNames = useMemo(() => props.sourcesList.map(v => v.source.toLowerCase()), [props.sourcesList]);
    const otherAliasesList = useMemo(
        () => props.sourcesList
            .filter(v => v.aliases !== undefined && v.id !== props.item.current.id)
            .map(i => i.aliases!)
            .flat()
            .map(v => v.toLowerCase()), [props.sourcesList, props.item.current.id]);

    const validateAliases = useCallback(() => {
        const currentAliases = props.item.current.aliases?.map(v => v.toLowerCase()) ?? [];
        const initialAliases = props.item.initial.aliases?.map(v => v.toLowerCase()) ?? [];
        const newAliases = currentAliases?.filter(s => !initialAliases?.includes(s));
        const sourceName = props.item.current.source.toLowerCase();
        if (currentAliases.includes(sourceName)) {
            return `Alias '${sourceName}' is already used as a source name`;
        }
        const existingSourceName = sourcesNames.find(v => currentAliases.includes(v));
        if (existingSourceName) {
            return `Alias '${existingSourceName}' is already used as a name for other source`;
        }
        if (otherAliasesList.some(v => newAliases.includes(v))) {
            return "You are unable to use " + "`" + newAliases.join() + "`" +
                " as alias(es) because it's already in use in other sources.";
        }

        return null;
    }, [sourcesNames, otherAliasesList, props.item.current.source, props.item.current.aliases, props.item.initial.aliases]);

    useEffect(() => {
        setAliasesError(validateAliases());
    }, [validateAliases]);

    const productName = useMemo(() => props.item?.current.settings.productName, [props.item.current.settings.productName]);

    const addAliases = useCallback(
        (alias: string) => {
            if (!alias) {
                return;
            }

            const aliases = (props.item.current.aliases ?? []);
            if (aliases.includes(alias)) {
                return;
            }
            updateAliases(aliases.concat(alias));
        }, [props.item.current.aliases, updateAliases]);

    const removeAliases = useCallback(
        (alias: string) => {
            if (!props.item.current.aliases) {
                return;
            }
            const aliases = props.item.current.aliases.filter(i => i !== alias);
            updateAliases(aliases);
        }, [props.item.current.aliases, updateAliases]);

    const isAliasesChanged = useMemo(
        () => props.item.current.aliases?.join() !== props.item.initial.aliases?.join(),
        [props.item.current.aliases, props.item.initial.aliases]);

    const validate = useCallback(async () => {
        const validationRes = await validateFields(true);
        if (!validationRes.isValid) {
            return false;
        }
        if (aliasesError !== null) {
            aliasesFieldRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            return false;
        }
        return true;
    }, [validateFields, aliasesError, aliasesFieldRef?.current]);

    useImperativeHandle(ref, () => ({
        validate: () => from(validate()).pipe(
            switchMap(res => !res
                ? of(false)
                : from(validateMedia()).pipe(switchMap(imageRes => imageRes.isValid
                    ? of(true)
                    : from(confirm({
                        title: "Confirm saving application",
                        body: <ConfirmFailedSources failedFields={getObjectFieldsArray(imageRes.errors)} />,
                        size: "lg"
                    }))))
            )
        )
    }), [validate, validateMedia]);

    return (
        props.item !== undefined
            ? <React.Fragment>
                <Row>
                    <Col size={12}>
                        <LoadingButton
                            className="mr-2"
                            loading={props.saving}
                            loadingText={"Saving..."}
                            position="end"
                            onClick={props.save}
                            color="primary">
                            {props.item.type === "new" ? "Create" : "Save"}
                        </LoadingButton>
                    </Col>
                    <Separator />
                </Row>
                <PropertyContainer>
                    <Row className="mt-2">
                        <EditField
                            label="Aliases"
                            fieldId="aliases"
                            fullWidth={true}
                            ref={aliasesFieldRef}
                            isChanged={isAliasesChanged}
                            errors={aliasesError ? [aliasesError] : undefined}
                        >
                            <AliasesListEditor
                                mainInputId="aliases"
                                items={props.item.current.aliases ?? []}
                                add={addAliases}
                                remove={removeAliases}
                                invalid={!!aliasesError}
                                disabled={props.saving}
                            />
                        </EditField>
                    </Row>
                    {props.settingsMetadata.properties.map((metadata, i) =>
                        <Row key={metadata.propertyName} className="mt-2">
                            <Col size={12}>
                                <PropertyField
                                    ref={register(metadata.propertyName as keyof SourceSettingsData)}
                                    errors={errors[metadata.propertyName]}
                                    warnings={warnings[metadata.propertyName]}
                                    source={props.item!.initial.source}
                                    metadata={metadata}
                                    value={props.item!.current.settings}
                                    originalValue={props.item!.initial.settings}
                                    setValue={updateSettings}
                                    disabled={props.saving}
                                />
                            </Col>
                            <Separator />
                        </Row>
                    )}
                    <Row className="mt-2 pb-3">
                        <PreviewComponent
                            options={props.item?.current.settings.installerColorOptions}
                            colorSchemeMetadata={props.settingsMetadata.colors}
                            downloaderImages={props.item?.current.settings.downloaderImageUrls}
                            installationLeftCornerIconUrl={settings.installationLeftCornerIconUrl}
                            productName={productName}
                        />
                    </Row>
                </PropertyContainer>
            </React.Fragment>
            : null
    );
});
