import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import React, { useState, useMemo, useCallback, forwardRef, useImperativeHandle, useEffect } from "react";
import { Input } from "reactstrap";
import styled from "styled-components";
import { AdCampaign, AdTargeting, SourceSettings, SourceSettingsData } from "src/shared/dtos";
import { setFromInput } from "src/shared/helpers";
import { SourceSettingsItem } from "./sourceSettingsReducer";
import { SourceListItem, SourceListItemRef } from "./SourceListItem";
import { ThinScrollbar } from "src/shared/components/mixin";
import { Observable, of } from "rxjs";
import { SourceSettingsDeletionModal } from "src/shared/components/SourceSettingsDeletionModal";
import api from "../api";

const Header = styled.div`
    display: flex;
    border-bottom: 1px solid lightgray;
    padding-bottom: 2px;
    margin-bottom: 15px;
`;

const List = styled.ul`
    ${ThinScrollbar}
    overflow-y: auto;
    height: 92%;
    margin-top: 15px;
`;

const Icon = (props: FontAwesomeIconProps & { visible?: boolean }) => {
    const { visible, ...otherProps } = props;

    return <FontAwesomeIcon {...otherProps} />;
};

const StyledIcon = styled(Icon)`
    opacity: 70%;
    margin-left: 10px;
    height: 36px;
    font-size: 1.5em;
    visibility: ${props => props.visible ? null : "hidden"};

    :hover {
        opacity: 100%;
        cursor: pointer;
    }
`;

export interface SourceListRef {
    validateCurrent: () => Observable<boolean>;
}

type Props = {
    items: SourceSettingsItem[],
    onSelect: (selected: SourceSettings) => void,
    selectedSource: SourceSettingsItem | undefined,
    add: () => void,
    copy: () => void,
    update: (data: { newSource?: string, newSettings?: Partial<SourceSettingsData> }) => void,
    delete: () => void,
    saving: boolean
};

export const SourceList = forwardRef((props: Props, ref: React.MutableRefObject<SourceListRef>) => {
    const [search, setSearch] = useState("");
    const [confirmDelete, setConfirmDelete] = useState(false);
    const hasNewItem = props.items.find(i => i.type === "new") !== undefined;
    const filtered = useMemo(() => search.length > 0
        ? props.items.filter(i => i.type !== "normal" || i.initial.source.toLowerCase().indexOf(search.toLowerCase()) !== -1)
        : props.items, [props.items, search, hasNewItem]);
    const closeConfirmDelete = useCallback(() => setConfirmDelete(false), []);
    const updateSource = useCallback((value: string) => props.update({ newSource: value }), [props.update]);

    const [sourceListItemRefs, setSourceListItemRefs] = useState<React.RefObject<SourceListItemRef>[]>([]);
    const [adCampaigns, setAdCampaigns] = useState<AdCampaign[]>([]);
    const [adTargeting, setAdTargeting] = useState<AdTargeting[]>();

    useEffect(() => {
        api.getAdTargeting().then(setAdTargeting);
        api.getAdCampaigns().then(setAdCampaigns);
    }, []);

    const linkedAdCampaignsNames = useMemo(() => {
        if (props.selectedSource === undefined || adTargeting === undefined || adCampaigns === undefined) {
            return [];
        }
        const linkedAdCampaignsIds = adTargeting
            .filter(at => at.adTargetingSettings?.sources?.some(v => v === props.selectedSource!.initial.source))
            .map(at => at.adCampaignId);
        return adCampaigns.filter(c => linkedAdCampaignsIds.includes(c.id)).map(c => c.name);
    }, [adTargeting, adCampaigns, props.selectedSource]);

    useEffect(() => {
        setSourceListItemRefs(Array.from({ length: filtered.length }, _ => React.createRef()));
    }, [filtered.length]);

    const selectedIndex = filtered.findIndex(i => i.selected === true);
    useImperativeHandle(ref, () => ({
        validateCurrent: () => sourceListItemRefs[selectedIndex].current?.validate() ?? of(false)
    }), [sourceListItemRefs, selectedIndex]);

    const confirm = useCallback(() => {
        setConfirmDelete(false);
        props.delete();
    }, [props.delete]);

    const selected = props.items.find(i => i.selected === true);
    const canDelete = selected?.type === "normal" || selected?.type === "new";
    const onDelete = useCallback(() => {
        if (canDelete) {
            if (selected?.type === "new" && !selected?.hasChanges) {
                props.delete();
                return;
            }
            setConfirmDelete(true);
        }
    }, [canDelete, selected]);

    return (
        <React.Fragment>
            <Header>
                <Input
                    type="text"
                    className="mb-2"
                    placeholder="Filter by source name..."
                    value={search}
                    onChange={setFromInput(setSearch)}
                />
                <SourceSettingsDeletionModal
                    title="Confirm source settings deletion"
                    open={confirmDelete}
                    delete={confirm}
                    cancel={closeConfirmDelete}
                >
                    {linkedAdCampaignsNames.length > 0 && (
                        <>
                            <span>This source is used in the following Ad Campaigns:</span>
                            {linkedAdCampaignsNames.map((s, i) => (
                                <li key={"keyFor" + i}>{s}</li>
                            ))}
                            <span>All linked ad campaigns will be disabled after this source deletion.</span>
                        </>
                    )}
                    <br />
                    Do you want to delete source
                    {props.selectedSource?.type === "new" && props.selectedSource?.hasChanges
                        ? " that was not saved"
                        : <b> {props.selectedSource?.current.source}</b>}?
                </SourceSettingsDeletionModal>
                <StyledIcon className="text-success" icon="plus" visible={!hasNewItem} onClick={props.add} />
                <StyledIcon className="text-primary " icon="copy" visible={!hasNewItem} onClick={props.copy} />
                <StyledIcon className="text-danger" icon="times" visible={canDelete} onClick={onDelete} />
            </Header>
            <List className="list-group">
                {filtered.map((item, i) => {
                    const onSelect = () => props.onSelect(item.initial);
                    return (
                        <SourceListItem
                            ref={sourceListItemRefs[i]}
                            key={item.initial.id}
                            value={item}
                            onSelect={onSelect}
                            updateSource={updateSource}
                            delete={onDelete}
                            saving={props.saving}
                            hasNewItem={hasNewItem}
                        />
                    );
                })}
            </List>
        </React.Fragment>
    );
});
