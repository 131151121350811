import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ClickInfoStatisticsForPeriodSummary, ClickInfoStatisticsForPeriod } from "src/shared/dtos";
import { Container, Row, Col } from "reactstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import styled from "styled-components";
import { Loader, Select, StatisticsDatePicker } from "src/shared/components";
import { defer } from "rxjs";
import api, { DetailsRequest, GetAvailableClickTargetsRequest } from "../api";
import { ClickInfoStatisticsListView } from "./ClickInfoStatisticsListView";
import { deserializeDate, serializeDate } from "src/shared/helpers/dateSerialization";
import { RouteComponentProps } from "react-router-dom";
import { arrayDeserializer, QueryParamsSerializerOptions, splitCamelCase, useSubscription, plainTextMapper, ValueMapper, versionMapper, makeSelectOptionMapper, SelectOption, SelectOptionMapper, useDebounce } from "src/shared/helpers";
import { useQueryState } from "src/shared/helpers/useQueryState";

const StyledContainer = styled(Container)`
    padding-bottom: 50px;
    .row {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid;
        align-items: center;
    }
`;

const StyledHighcharts = styled.div`
    text.highcharts-credits {
        display:none !important;
    }
`;

const makeChartOptions = (statisticsList: ClickInfoStatisticsForPeriodSummary[]) => {
    const countData = statisticsList.map(r => {
        const date = new Date(r.date);
        const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        return [utcDate.getTime(), r.count];
    });
    return {
        title: {
            text: `<strong>Details</strong>`
        },
        xAxis: {
            type: "datetime",
            maxPadding: 0.1,
            minPadding: 0.1,
            minTickInterval: 24 * 3600 * 1000,
            dateTimeLabelFormats: {
                day: "%e %b"
            },
        },
        yAxis: [{
            title: { text: "Count" },
            labels: { format: "{value}" }
        }],
        tooltip: {
            valueDecimals: 0
        },
        series: [{
            name: "Count",
            type: "spline",
            data: countData
        }]
    };
};

const querySerializerOptions: QueryParamsSerializerOptions<DetailsRequest> = {
    endDate: {
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    startDate: {
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    clickTarget: {
        deserializer: arrayDeserializer
    },
    clickType: {
        deserializer: arrayDeserializer
    },
    country: {
        deserializer: arrayDeserializer
    },
    emulatorType: {
        deserializer: arrayDeserializer
    },
    platform: {
        deserializer: arrayDeserializer
    },
    productVersion: {
        deserializer: arrayDeserializer
    },
    sourceUrl: {
        deserializer: arrayDeserializer,
    },
};

export type FilterName = keyof Omit<DetailsRequest, "startDate" | "endDate">;

export type FilterDescriptor = {
    name: FilterName;
    title: string;
    valueMapper?: ValueMapper<unknown, string>;
    optionMapper: SelectOptionMapper<unknown>;
};

export const filterNames: FilterName[] = ["clickTarget", "clickType", "country", "emulatorType", "platform", "productVersion", "sourceUrl"];

const textOptionMapper = makeSelectOptionMapper(plainTextMapper);

const filterDescriptors: FilterDescriptor[] = [
    { name: "clickType", title: "Click Type", optionMapper: makeSelectOptionMapper(splitCamelCase), valueMapper: splitCamelCase },
    { name: "country", title: "Country", optionMapper: textOptionMapper },
    { name: "productVersion", title: "Product Version", optionMapper: makeSelectOptionMapper(versionMapper), valueMapper: versionMapper },
    { name: "sourceUrl", title: "SourceUrl", optionMapper: textOptionMapper },
    { name: "platform", title: "Platform", optionMapper: textOptionMapper },
    { name: "emulatorType", title: "Emulator Type", optionMapper: textOptionMapper }
];

export const DetailsComponent = (props: Pick<RouteComponentProps, "history" | "location">) => {
    const [clickTargetSearch, setClickTargetSearch] = useState("");
    const clickTargetDebounceSearch = useDebounce(clickTargetSearch, 500);
    const [clickTargets, setClickTargets] = useState<string[]>([]);
    const [clickTargetsLoading, setClickTargetsLoading] = useState<boolean>(false);
    const [data, setData] = useState<Required<ClickInfoStatisticsForPeriod>>({ items: [], filtersSuggestions: {} });
    const [loading, setLoading] = useState<boolean>(false);
    const [dates, setDates] = useState<string[]>([]);
    const [urlState, setUrlState] = useQueryState({
        options: querySerializerOptions,
        location: props.location,
        history: props.history
    });

    const search = useMemo<DetailsRequest>(() => {
        const endDate = urlState.endDate ?? new Date();
        let startDate = urlState.startDate;
        if (startDate === undefined) {
            startDate = new Date(endDate);
            startDate.setDate(-30);
        }
        return {
            ...urlState,
            startDate,
            endDate
        };
    }, [urlState]);
    const debounceSearch = useDebounce(search, 500);

    const chartOptions = useMemo(() => makeChartOptions(data.items), [data.items]);

    const changeStartDate = useCallback((date: Date) => {
        setUrlState(prev => ({ ...prev, startDate: date }));
    }, [setUrlState]);

    const changeEndDate = useCallback((date: Date) => {
        setUrlState(prev => ({ ...prev, endDate: date }));
    }, [setUrlState]);

    const endDates = useMemo(() => dates.filter(d => new Date(d) > search.startDate), [dates, search.startDate]);
    const startDates = useMemo(() => dates.filter(d => new Date(d) < search.endDate), [dates, search.endDate]);

    const updateDates = useSubscription(() => defer(() => api.getDates()).subscribe(setDates), [api.getDates]);

    useEffect(() => {
        updateDates();
    }, []);

    const searchClickTarget = (v: string) => setClickTargetSearch(v);
    const updateClickTargets = useSubscription((request: GetAvailableClickTargetsRequest) => defer(
        () => api.getAvailableClickTargets(request)).subscribe(v => {
            setClickTargetsLoading(false);
            setClickTargets(v);
        }), []);

    useEffect(() => {
        setClickTargetsLoading(true);
        updateClickTargets({
            idSearch: clickTargetSearch,
            clickType: debounceSearch.clickType ?? [],
            country: debounceSearch.country ?? [],
            emulatorType: debounceSearch.emulatorType ?? [],
            platform: debounceSearch.platform ?? [],
            productVersion: debounceSearch.productVersion ?? [],
            sourceUrl: debounceSearch.sourceUrl ?? [],
            startDate: debounceSearch.startDate,
            endDate: debounceSearch.endDate,
            limit: 100,
        });
    }, [clickTargetDebounceSearch, debounceSearch]);

    const clickTargetOptions = useMemo(() => clickTargets.map(textOptionMapper), [clickTargets]);

    const changeFilterValues = useCallback((filterName: FilterName) => (v: SelectOption<unknown>[]) => {
        setUrlState(prev => ({ ...prev, [filterName]: v?.map(x => x.value) ?? [] }));
    }, [setUrlState]);

    const selectedOptions = useMemo(
        () => filterDescriptors.reduce((options, fd) => {
            options[fd.name] = search[fd.name]?.map(fd.optionMapper) ?? [];
            return options;
        }, {} as Record<FilterName, SelectOption<unknown>[]>),
        [search]);

    const availableOptions = useMemo(
        () => filterDescriptors.reduce((options, fd) => {
            options[fd.name] = data.filtersSuggestions[fd.name]?.map(fd.optionMapper) ?? [];
            return options;
        }, {} as Record<FilterName, SelectOption<unknown>[]>),
        [data.filtersSuggestions]);

    const updateData = useSubscription((request: DetailsRequest) => defer(() => api.details(request)).subscribe({
        next: result => {
            const { items, filtersSuggestions } = result;
            if (items === undefined) {
                return;
            }
            setData(prev => ({
                filtersSuggestions: filtersSuggestions ?? prev.filtersSuggestions,
                items
            }));
        },
        complete: () => setLoading(false)
    }), [api.details]);

    useEffect(() => {
        setLoading(true);
        updateData(debounceSearch);
    }, [debounceSearch]);

    const activeFilters: FilterName[] = useMemo(() => filterNames
        .map(filterName => search[filterName] !== undefined && search[filterName]!.length > 0 ? filterName : null)
        .filter(f => f !== null) as FilterName[], [search]);

    return (
        <React.Fragment>
            {loading && <Loader transparent />}
            {data.filtersSuggestions.clickType !== undefined &&
                <React.Fragment >
                    <StyledContainer fluid>
                        <Row>
                            <Col sm={6} md={3}>
                                <strong>Start Date:</strong> <br />
                                <StatisticsDatePicker
                                    className="form-control"
                                    popperPlacement="top-end"
                                    selected={search.startDate}
                                    onChange={changeStartDate}
                                    dates={startDates}
                                />
                            </Col>
                            <Col sm={6} md={3}>
                                <strong>End Date:</strong><br />
                                <StatisticsDatePicker
                                    className="form-control"
                                    popperPlacement="top-end"
                                    selected={search.endDate}
                                    onChange={changeEndDate}
                                    dates={endDates}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {filterDescriptors.map(({ name, title }) => (
                                <Col md={6} lg={4} xl={3} key={name} className="my-2">
                                    <h6>{title}</h6>
                                    <Select
                                        value={selectedOptions[name]}
                                        onChange={changeFilterValues(name)}
                                        options={availableOptions[name] ?? []}
                                        isMulti={true}
                                        isSearchable={true}
                                        closeMenuOnSelect={false}
                                    />
                                </Col>
                            ))}
                            <Col md={6} lg={4} xl={3} className="my-2">
                                <h6>Click Target</h6>
                                <Select
                                    value={selectedOptions.clickTarget}
                                    onChange={changeFilterValues("clickTarget")}
                                    options={clickTargetOptions}
                                    onInputChange={searchClickTarget}
                                    isLoading={clickTargetsLoading}
                                    isMulti={true}
                                    isSearchable={true}
                                    closeMenuOnSelect={false}
                                />
                            </Col>
                        </Row>
                    </StyledContainer>
                    {
                        data.items.length !== 0 && (
                            <>
                                <StyledHighcharts>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions}
                                    />
                                </StyledHighcharts>
                                <br />
                            </>
                        )
                    }
                    <ClickInfoStatisticsListView
                        list={data.items}
                        activeFilters={activeFilters}
                        filterDescriptors={filterDescriptors}
                    />
                </React.Fragment>
            }
        </React.Fragment>
    );
};