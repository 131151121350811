import React, { useCallback, useState, useEffect, FC } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Input, Col } from "reactstrap";
import { InstanceProps, create } from "react-modal-promise";
import { GamestoreApplicationDisableReason } from "src/shared/dtos";
import { IUser } from "src/shared/client";
import { MultiValueSearch } from "src/shared/components";
import { ValidationSchema, setFromInput, useValidate } from "src/shared/helpers";
import styled from "styled-components";

const StyledModal = styled(Modal)`
    max-width: 600px !important;
`;

const StyledFooter = styled(ModalFooter)`
    justify-content: space-between;
    align-items: center;
`;

const PromptText = styled.p`
    font-weight: 400;
    font-size: 14px;
    color: #808080;
    margin-bottom: 6px;
`;

const validationSchema: ValidationSchema<GamestoreApplicationDisableReason> = {
    description: (v) => !v?.trim() ? "Description is required" : null,
};

export interface DisableReasonModalProps extends InstanceProps<GamestoreApplicationDisableReason> {
    date: Date;
    user: IUser | null;
    productVersions: string[];
}

const initialData: GamestoreApplicationDisableReason = {
    description: "",
    testedVersions: [],
    disabledBy: "",
    disableDate: "",
};

const DisableReasonModal: FC<DisableReasonModalProps> = (props) => {
    const [data, setData] = useState<GamestoreApplicationDisableReason>(initialData);
    const { errors, validate } = useValidate({ schema: validationSchema, value: data });

    useEffect(() => {
        const disableDate = props.date.toISOString().slice(0, 19);
        const disabledBy = props.user?.displayName;
        setData(p => ({ ...p, disableDate, disabledBy }));
    }, [props.date, props.user]);

    const changeDescription = useCallback(
        (val: string) => setData(p => ({ ...p, description: val })), []);

    const addVersion = useCallback(
        (val: string) => setData(p => ({ ...p, testedVersions: (p.testedVersions ?? []).concat(val) })), []);

    const deleteVersion = useCallback(
        (idx: number) => setData(p => ({ ...p, testedVersions: (p.testedVersions ?? []).filter((_, i) => i !== idx) })), []);

    const clearVersions = useCallback(
        () => setData(p => ({ ...p, testedVersions: [] })), []);

    const validateVersion = useCallback(
        (val: string) => /^\d+\.\d+\.\d+(\.\d)?$/.test(val), []);

    const submit = useCallback(async () => {
        const validationRes = await validate();
        if (validationRes.isValid) {
            data.description = data.description?.trim();
            props.onResolve(data);
        }
    }, [data, validate]);

    const reject = () => props.onReject();

    return (
        <StyledModal isOpen={props.isOpen} toggle={reject}>
            <ModalHeader toggle={reject}>
                Reasons For Disabling
            </ModalHeader>
            <ModalBody>
                <Row className="align-items-center mb-0">
                    <Col xs={3} className="pr-0">Version(s) Tested:</Col>
                    <Col xs={9}>
                        <MultiValueSearch
                            values={data.testedVersions ?? []}
                            onAddition={addVersion}
                            onDelete={deleteVersion}
                            clearTags={clearVersions}
                            suggestions={props.productVersions}
                            onValidate={validateVersion}
                            allowNew={true}
                        />
                    </Col>
                </Row>
                <Row className="align-items-center mb-4">
                    <Col xs={{ size: 9, offset: 3 }}>
                        <PromptText>Type and press Enter to add custom version.</PromptText>
                        <PromptText>Valid version formats are 9.9.9 and 9.9.9.9.</PromptText>
                    </Col>
                </Row>
                <Input
                    id="description"
                    type="textarea"
                    rows={4}
                    style={{ resize: "none" }}
                    value={data.description}
                    onChange={setFromInput(changeDescription)}
                    placeholder={"Provide a short description of the reason(s) for disabling."}
                    invalid={(errors.description?.length ?? 0) > 0}
                />
                {errors.description && (<p className="text-danger">{errors.description[0]}</p>)}
            </ModalBody>
            <StyledFooter>
                <div>
                    <p className="mb-0 text-secondary">{props.user?.displayName}</p>
                    <p className="mb-0 text-secondary">{props.date.toLocaleString()}</p>
                </div>
                <div className="d-flex">
                    <Button color="secondary" onClick={reject} className="mr-2">Cancel</Button>
                    <Button color="primary" onClick={submit}>Save</Button>
                </div>
            </StyledFooter>
        </StyledModal>
    );
};

export const confirmDisable = create<DisableReasonModalProps, GamestoreApplicationDisableReason>(DisableReasonModal);