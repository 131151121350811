import React from "react";
import { ToastContainer, ToastContainerProps, cssTransition, toast } from "react-toastify";
import styled, { keyframes } from "styled-components";

export enum AlertType {
    primary = "primary",
    secondary = "secondary",
    success = "success",
    danger = "danger",
    warning = "warning",
    info = "info",
    light = "light",
    dark = "dark"
}

const Appear = keyframes`
    from {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
`;

const Disappear = keyframes`
    from {
        opacity: 1;
    }

    50% {
        opacity: 0;
        transform: scale3d(.3, .3, .3);
    }

    to {
        opacity: 0;
    }
`;

const Toast = styled(ToastContainer)`
    position: fixed;
    bottom: 0;
    right: 2rem;
    padding-left: 2rem;
    z-index: 9999;

    .Toastify__toast {
        cursor: pointer;

        &.appear {
            animation: ${Appear};
        }

        &.disappear {
            animation: ${Disappear};
        }

        .Toastify__toast-body {
            min-height: 1.5rem;
        }
    }
`;

const ToastTransition = cssTransition({
    enter: "appear",
    exit: "disappear",
    duration: 750
});

const CloseButton = (props: { closeToast?: () => void }) =>
    <button type="button" className="close" aria-label="Close" onClick={props.closeToast}>
        <span aria-hidden="true">×</span>
    </button>;

export const NotificationContainer = (props: ToastContainerProps) =>
    <Toast {...props} />;

export const notify = (content: string | JSX.Element, type: AlertType = AlertType.info) => {
    toast(content, {
        autoClose: 5000,
        hideProgressBar: true,
        position: toast.POSITION.BOTTOM_RIGHT,
        draggablePercent: 30,
        type: "default",
        className: `alert alert-${type ?? "info"} alert-dismissible`,
        closeButton: <CloseButton />,
        transition: ToastTransition
    });
};

export const notifyError = (content: string | JSX.Element) => {
    notify(content, AlertType.danger);
};

export const notifySuccess = (content: string | JSX.Element) => {
    notify(content, AlertType.success);
};