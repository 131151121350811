import { capitalize } from "./capitalize";
import { splitCamelCase } from "./splitCamelCase";

export type FieldValidationFunc<FieldType, DataType> = (val: FieldType, data: DataType) => string | string[] | null;
export type AsyncFieldValidationFunc<FieldType, DataType> = (val: FieldType, data: DataType) => Promise<string | string[] | null> | null;
export type ValidationFunc<FieldType, DataType> = FieldValidationFunc<FieldType, DataType> | AsyncFieldValidationFunc<FieldType, DataType>;

export type ValidationSchema<T> = {
    [key in keyof T]?: ValidationFunc<T[key], T> | ValidationFunc<T[key], T>[]
};

export type FormErrors<T> = { [key in keyof T]?: string[] };

export type ValidationResult<T> = {
    errors: FormErrors<T>;
    isValid: boolean;
};

const validateField = async <FieldType, DataType>(field: FieldType, data: DataType, validationFunc: ValidationFunc<FieldType, DataType>): Promise<string[]> => {
    try {
        const result = await validationFunc(field, data);
        return Array.isArray(result)
            ? result
            : result !== null ? [result] : [];
    } catch (err) {
        return [err];
    }
};

export const validateForm = async <T extends object>(data: T, schema: ValidationSchema<T>): Promise<ValidationResult<T>> => {
    const validationResults = Object.keys(schema).map(async key => {
        const fieldResult = {
            name: key,
            errors: [] as string[]
        };
        const validate = schema[key] as ValidationFunc<T[keyof T], T>[] | ValidationFunc<T[keyof T], T>;
        if (!Array.isArray(validate)){
            fieldResult.errors = await validateField(data[key], data, validate);
            return fieldResult;
        }
        for (const validationFunc of validate) {
            fieldResult.errors = await validateField(data[key], data, validationFunc);
            if (fieldResult.errors.length > 0) {
                return fieldResult;
            }
        }
        return fieldResult;
    });
    return (await Promise.all(validationResults)).reduce((res, field) => {
        if (field.errors.length > 0) {
            res.errors[field.name] = field.errors;
            res.isValid = false;
        }
        return res;
    }, { isValid: true, errors: {} } as ValidationResult<T>);
};

export const getFormErrorsAsStringArray = (formErrors: FormErrors<unknown>): string[] => {
    return Object.keys(formErrors).reduce((errors, key) => {
        const fieldErrors = formErrors[key] as string[];
        if (fieldErrors.length === 0) {
            return errors;
        }
        const propName = splitCamelCase(capitalize(key));
        return errors.concat(fieldErrors.map(err => `${propName}: ${err}`));
    }, [] as string[]);
};

export interface ValidateRef {
    validate: () => boolean;
}

export interface ValidateAsyncRef {
    validate: () => Promise<boolean>;
}