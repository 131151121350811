import { deepClone } from "./deepClone";

/**
 * Applies fallback fields values to target object from source object.
 * @param {object} targetObj Target object.
 * @param {object} sourceObj Source object.
 * @param {string[]} fields Fields to merge.
 * @returns Copy of target object with new field values
 */
export const applyFallbackFields = <T1 extends Record<string, any>, T2 extends { [key in TKey]?: T1[key] }, TKey extends Extract<keyof T2, keyof T1>>(
    targetObj: T1, sourceObj: T2 | undefined, fields: Array<TKey>): T1 => {
    if (sourceObj === undefined) {
        return targetObj;
    }
    const objectCopy = deepClone(targetObj);
    fields.forEach(field => {
        if (objectCopy[field] === undefined) {
            objectCopy[field] = sourceObj[field] as unknown as T1[TKey];
        }
    });
    return objectCopy;
};