import React, { useMemo } from "react";
import { Form, Input, FormGroup, Label } from "reactstrap";
import { setFromInput } from "src/shared/helpers";
import { HorizontalField, FormLabel, Select } from "src/shared/components";
import { FeaturedAppMainData } from "./applicationReducer";

interface Props {
    value: FeaturedAppMainData;
    initial?: FeaturedAppMainData;
    isNewApp: boolean;
    newAppIds: string[];
    update: React.Dispatch<Partial<FeaturedAppMainData> | undefined>;
    saving: boolean;
    onSubmit: () => void;
}

type AppIdOption = { value: string, label: string };

export const EditForm = (props: Props) => {
    const setAppId = (v: AppIdOption) => props.update({ id: v.value });
    const setIsDisabled = (v: boolean) => props.update({ isDisabled: v });

    const appIdOptions = useMemo(
        () => props.newAppIds.map(id => ({ value: id, label: id } as AppIdOption)),
        [props.newAppIds]);
    const selectedAppIdOption = useMemo(
        () => appIdOptions.find(o => o.value === props.value.id),
        [props.value.id, appIdOptions]);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <Form onSubmit={save} noValidate>
            {props.isNewApp && (
                <React.Fragment>
                    <HorizontalField label="AppID" fieldId="appId">
                        <Select
                            id="appId"
                            value={selectedAppIdOption}
                            onChange={setAppId}
                            options={appIdOptions}
                            isMulti={false}
                            isSearchable={true}
                            isDisabled={props.saving}
                        />
                    </HorizontalField>
                </React.Fragment>
            )}
            <HorizontalField skipOffset={!props.isNewApp}>
                <FormGroup check>
                    <Label for="isDisabled" check>
                        <Input
                            type="checkbox"
                            id="isDisabled"
                            name="isDisabled"
                            checked={props.value.isDisabled}
                            onChange={setFromInput(setIsDisabled)}
                            disabled={props.saving}
                        />{" "}
                        <FormLabel
                            isChanged={props.value.isDisabled !== props.initial?.isDisabled}
                            originalValue={props.isNewApp ? undefined : props.initial?.isDisabled}>
                            Hide from Featured Applications
                        </FormLabel>
                    </Label>
                </FormGroup>
            </HorizontalField>
        </Form>
    );
};