import React, { useMemo, useEffect, forwardRef, useImperativeHandle } from "react";
import { Styles } from "react-select";
import { Form, Input, FormGroup, Label } from "reactstrap";
import { Category, IoApplication, Tag } from "src/shared/dtos";
import { formatAsAbbreviation, setFromInput, SetFromInputType, SelectOption, ValidateAsyncRef, useValidate } from "src/shared/helpers";
import { HorizontalField, Select, LoadingButton, FormLabel } from "src/shared/components";
import { ioProperty } from "./ioProperty";
import { CategorySelectOption as CategorySelectOptionBase, mapCategorySelectOption } from "src/shared/helpers/mapCategorySelectOption";
import { GamestoreIoAppMainInfo } from "./applicationReducer";
import { applicationValidationSchema } from "./validationSchema";
import { CategoryType } from "src/shared/CategoryType";

const categorySelectStyles: Partial<Styles> = {
    multiValue: (styles, { data }: { data: CategorySelectOption, hasGameCategory: boolean }) => ({
        ...styles,
        backgroundColor: data.isInherited ? "#e6e6e6" : "#007bff",
    }),
    multiValueLabel: (styles, { data }: { data: CategorySelectOption }) => ({
        ...styles,
        color: data.isInherited ? "" : "white",
    }),
    multiValueRemove: (styles, { data }: { data: CategorySelectOption }) => ({
        ...styles,
        color: data.isInherited ? "#333333" : "white",
        ":hover": {
            backgroundColor: data.isInherited ? "#545b62" : "#0069d9",
            color: "white",
        },
    })
};

type CategorySelectOption = CategorySelectOptionBase & { isInherited: boolean };

const defaultAvailableInstallsCounts = [
    0, 1, 10, 50, 100, 500,
    1000, 5000, 10000, 50000, 100000, 500000,
    1000000, 5000000, 10000000, 50000000, 100000000,
    1000000000, 5000000000, 10000000000, 50000000000, 100000000000,
    1000000000000, 5000000000000, 10000000000000, 50000000000000, 100000000000000
];

export interface EditFormRef {
    validate: () => boolean;
}

interface Props {
    value: GamestoreIoAppMainInfo;
    initial?: GamestoreIoAppMainInfo;
    isNewApp: boolean;
    categories: Category[];
    tags: Tag[];
    ioApplications: IoApplication[];
    update: React.Dispatch<Partial<GamestoreIoAppMainInfo> | undefined>;
    setIoApplication: React.Dispatch<IoApplication>;
    saving: boolean;
    onSubmit: () => void;
}

export const EditForm = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {

    const { validate, errors, register, reset } = useValidate({ schema: applicationValidationSchema, value: props.value });

    useEffect(() => {
        if (props.value.isDisabled) {
            reset();
        }
    }, [props.value.isDisabled]);

    useImperativeHandle(ref, () => ({
        validate: async () => props.value.isDisabled ? true : (await validate(true)).isValid
    }), [validate, props.value.isDisabled]);

    const availableInstallsCounts = useMemo(
        () => {
            const currentInstallCount = props.value.installsCount ?? 0;
            return defaultAvailableInstallsCounts.indexOf(currentInstallCount) === -1
                ? defaultAvailableInstallsCounts.concat(currentInstallCount).sort((a, b) => a - b)
                : defaultAvailableInstallsCounts;
        }, [props.value.installsCount]);

    const ioAppOptions = useMemo(
        () => props.ioApplications.map(a => ({ value: a.id, label: a.id } as SelectOption)),
        [props.ioApplications]);

    const selectedIoAppOption = useMemo(
        () => ioAppOptions.find(o => o.value === props.value.id),
        [props.value.id, ioAppOptions]);

    const categoryOptions = useMemo(
        () => props.categories
            .filter(c => c.parentId === CategoryType.Io && !c.isVirtual)
            .map(c => {
                const option = mapCategorySelectOption<CategorySelectOption>(c, props.categories);
                option.isInherited = props.value.ioApplication.category.id === c.id;

                return option;
            }),
        [props.categories, props.value.ioApplication.category]);

    const selectedCategoryOptions = useMemo(
        () => categoryOptions
            .filter(o => o.value !== undefined && props.value.categoryIds.includes(o.value))
            .sort((a, b) => props.value.categoryIds.indexOf(a.value!) - props.value.categoryIds.indexOf(b.value!)),
        [props.value.categoryIds, categoryOptions]);

    const originalCategoriesValue = useMemo(
        () => {
            const categories = props.initial?.categoryIds
                .map(id => props.categories.find(c => c.id === id))
                .filter(c => c !== undefined)
                .map(c => mapCategorySelectOption(c!, props.categories));
            return categories?.map(c => c.label).join(", ");
        },
        [props.categories, props.initial?.categoryIds]);

    const tagOptions = useMemo(
        () => props.tags.map(t => ({
            label: t.name,
            value: t.id
        }) as SelectOption<string>),
        [props.tags]);

    const selectedTagOptions = useMemo(
        () => tagOptions
            .filter(o => o.value !== undefined && props.value.tagIds.includes(o.value)),
        [props.value.tagIds, tagOptions]);

    const ioApplicationTags = useMemo(
        () => props.value.ioApplication.tagsIds.map(tagId => props.tags.find(t => t.id === tagId)?.name)
            .filter(t => t !== undefined),
        [props.value.ioApplication.tagsIds, props.tags]);

    const originalTagsValue = useMemo(
        () => {
            const tags = props.initial?.tagIds;
            return (tags !== undefined && tags.length > 0) ? tags.join(", ") : "No Value";
        },
        [props.initial?.tagIds]);

    const setAppId = (v: SelectOption<string | undefined>) => {
        props.update({ id: v.value });

        const ioApp = props.ioApplications.find(a => a.id === v.value);
        if (ioApp !== undefined) {
            props.setIoApplication(ioApp);
        }
    };
    const setRating = (v: number) => props.update({ rating: v });
    const setInstallsCount = (v: number) => props.update({ installsCount: v });
    const setCategories = (v: CategorySelectOption[]) => props.update({ categoryIds: v === null ? [] : v.map(c => c.value!) });
    const setTags = (v: SelectOption<string>[]) => props.update({ tagIds: v === null ? [] : v.map(c => c.value!) });
    const setIsEnabled = (v: boolean) => props.update({ isDisabled: !v });
    const setIsGraphicsCustomized = (v: boolean) => props.update({ isGraphicsCustomized: v });

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    useEffect(() => {
        const ioApp = props.ioApplications.find(a => a.id === props.value.id);
        if (ioApp !== undefined) {
            props.setIoApplication(ioApp);
        }
    }, [props.ioApplications, props.value.id, props.setIoApplication]);

    return (
        <Form onSubmit={save} noValidate>
            <HorizontalField className="text-right">
                <LoadingButton
                    loading={props.saving}
                    position="end"
                    type="submit"
                    color="primary"
                    className="ml-2 mb-2"
                    loadingText={"Saving..."}>
                    {props.isNewApp ? "Add" : "Save"} application
                </LoadingButton>
            </HorizontalField>
            {props.isNewApp &&
                <React.Fragment>
                    <HorizontalField
                        ref={register("id")}
                        label="Application ID"
                        fieldId="appId"
                        errors={errors?.id}>
                        <Select
                            id="appId"
                            value={selectedIoAppOption}
                            onChange={setAppId}
                            options={ioAppOptions}
                            isMulti={false}
                            isSearchable={true}
                            isDisabled={props.saving}
                            isInvalid={(errors?.id?.length ?? 0) > 0}
                        />
                    </HorizontalField>
                </React.Fragment>}
            <HorizontalField
                label={ioProperty("Rating", props.value.rating === undefined)}
                fieldId="rating"
                isChanged={props.value.rating !== props.initial?.rating}
                originalValue={props.isNewApp ? undefined : props.initial?.rating}>
                <Input
                    type="number"
                    id="rating"
                    min="0"
                    max="5"
                    step="0.01"
                    value={props.value.rating}
                    placeholder={props.value.ioApplication.rating + ""}
                    onChange={setFromInput(setRating)}
                    disabled={props.saving} />
            </HorizontalField>
            <HorizontalField
                label="Installs Count"
                fieldId="installsCount"
                isChanged={props.value.installsCount !== props.initial?.installsCount}
                originalValue={props.isNewApp ? undefined : (props.initial?.installsCount
                    ? formatAsAbbreviation(props.initial.installsCount)
                    : undefined)}>
                <Input
                    type="select"
                    id="installsCount"
                    value={props.value.installsCount}
                    onChange={setFromInput(setInstallsCount, SetFromInputType.Number)}
                    disabled={props.saving}>
                    {availableInstallsCounts.map((v, i) =>
                        <option key={`${v}_${i}`} value={v}>{formatAsAbbreviation(v)}</option>)}
                </Input>
            </HorizontalField>
            <HorizontalField
                ref={register("categoryIds")}
                label="Categories"
                fieldId="categories"
                isChanged={props.value.categoryIds?.join() !== props.initial?.categoryIds?.join()}
                originalValue={originalCategoriesValue}
                errors={errors?.categoryIds}
            >
                <Select
                    id="categories"
                    value={selectedCategoryOptions}
                    onChange={setCategories}
                    options={categoryOptions}
                    isMulti={true}
                    isSearchable={true}
                    isDisabled={props.saving}
                    styles={categorySelectStyles}
                    isInvalid={(errors?.categoryIds?.length ?? 0) > 0}
                />
            </HorizontalField>
            <HorizontalField
                ref={register("tags")}
                label={ioProperty("Tags", (props.value.tags?.length ?? 0) === 0)}
                fieldId="tags"
                isChanged={props.value.tagIds?.join() !== props.initial?.tagIds?.join()}
                originalValue={originalTagsValue}
                errors={errors?.tagIds}
            >
                <Select
                    id="tags"
                    value={selectedTagOptions}
                    onChange={setTags}
                    options={tagOptions}
                    isMulti={true}
                    isSearchable={true}
                    isDisabled={props.saving}
                    isInvalid={(errors?.tagIds?.length ?? 0) > 0}
                    placeholder={ioApplicationTags.join(", ")}
                />
            </HorizontalField>
            <HorizontalField>
                <FormGroup check>
                    <Label for="isEnabled" check>
                        <Input
                            type="checkbox"
                            id="isEnabled"
                            name="isEnabled"
                            checked={!props.value.isDisabled}
                            onChange={setFromInput(setIsEnabled)}
                            disabled={props.saving}
                        />{" "}
                        <FormLabel
                            isChanged={props.value.isDisabled !== props.initial?.isDisabled}
                            originalValue={props.isNewApp ? undefined : !props.initial?.isDisabled}>
                            Enabled
                        </FormLabel>
                    </Label>
                </FormGroup>
            </HorizontalField>
            <HorizontalField>
                <FormGroup check>
                    <Label for="isGraphicsCustomized" check>
                        <Input
                            type="checkbox"
                            id="isGraphicsCustomized"
                            name="isGraphicsCustomized"
                            checked={props.value.isGraphicsCustomized}
                            onChange={setFromInput(setIsGraphicsCustomized)}
                            disabled={props.saving}
                        />{" "}
                        <FormLabel
                            isChanged={props.value.isGraphicsCustomized !== props.initial?.isGraphicsCustomized}
                            originalValue={props.isNewApp ? undefined : props.initial?.isGraphicsCustomized}>
                            Graphics Customized
                        </FormLabel>
                    </Label>
                </FormGroup>
            </HorizontalField>
        </Form>
    );
});
