import React from "react";

export const SourceName = (props: { type: "normal" | "default" | "new", source: string }) => {
    let name: JSX.Element | null = null;
    switch (props.type) {
        case "normal":
            name = <React.Fragment>{props.source}</React.Fragment>;
            break;
        case "default":
            name = <i>[default]</i>;
            break;
        case "new":
            name = <i>[new]</i>;
            break;
    }

    return name;
};