/**
 * Picks object items by keys.
 * @param obj Object to pick items from.
 * @param keys Items to pick.
 * @return Subset of given object
 */
export const pickItems = <ValueType>(obj: {[key: string]: ValueType}, keys: string[]): {[key: string]: ValueType} =>
    keys.reduce((res, key) => {
        if (obj[key] !== undefined){
            res[key] = obj[key];
        }
        return res;
    }, {} as {[key: string]: ValueType});