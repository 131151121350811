import React, { useState, useEffect, useMemo } from "react";
import { ActiveInstallsStatistics } from "src/shared/dtos";
import { Container, Row, Col } from "reactstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import styled from "styled-components";
import { Loader, StatisticsDatePicker } from "src/shared/components";
import { defer } from "rxjs";
import api, { DetailsRequest } from "../api";
import { IUser } from "src/shared/client";
import { ActiveInstallsStatisticsListView } from "./ActiveInstallsStatisticsListView";
import { useSubscription } from "src/shared/helpers";

interface Props {
    productVersion: string;
    endDate: Date;
    user: IUser | null;
}

const StyledContainer = styled(Container)`
    padding-bottom: 50px;
    .row {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid;
        align-items: center;
    }
`;

const StyledHighcharts = styled.div`
    text.highcharts-credits {
        display:none !important;
    }
`;

export const DetailsComponent = (props: Props) => {
    const defaultStartDate = new Date(props.endDate.toISOString());
    defaultStartDate.setDate(defaultStartDate.getDate() - 30);
    const [startDate, setStartDate] = useState<Date>(defaultStartDate);
    const [endDate, setEndDate] = useState<Date>(props.endDate);
    const [dates, setDates] = useState<string[]>([]);
    const [activeInstallsStatisticsList, setActiveInstallsStatisticsList] = useState<ActiveInstallsStatistics[]>();
    const changeStartDate = (date: Date) => setStartDate(date);
    const changeEndDate = (date: Date) => setEndDate(date);
    const [countData, setCountData] = useState<any[]>([]);
    const versionName = props.productVersion === "0.0.0" ? "All versions" : props.productVersion;

    const [optionsState, setOptionsState] = useState<any>(
        {
            title: {
                text: `<strong>App version: </strong>${versionName}`
            },
            xAxis: {
                type: "datetime",
                maxPadding: 0.1,
                minPadding: 0.1,
                minTickInterval: 24 * 3600 * 1000,
                dateTimeLabelFormats: {
                    day: "%e %b"
                },
            },
            yAxis: [{
                title: {
                    text: "Count",
                },
                labels: {
                    format: "{value}",
                }

            }],
            tooltip: {
                valueDecimals: 0
            },
            series: [{
                name: "Count",
                type: "spline",
                data: countData
            }]
        });

    useEffect(() => {
        setOptionsState({
            ...optionsState,
            series: [{
                ...optionsState.series[0],
                data: countData
            }]
        });
    }, [countData]);

    const endDates = useMemo(() => dates.filter(d => new Date(d) > startDate), [dates, startDate]);
    const startDates = useMemo(() => dates.filter(d => new Date(d) < endDate), [dates, endDate]);

    const updateDates = useSubscription(() => defer(() => api.getDates()).subscribe(setDates), [api.getDates]);

    useEffect(() => {
        updateDates();
    }, []);

    const updateData = useSubscription((request: DetailsRequest) => defer(() => api.details(request)).subscribe({
        next: result => setActiveInstallsStatisticsList(result)
    }), [api.details]);

    useEffect(() => {
        if (startDate === undefined) {
            setStartDate(defaultStartDate);
            return;
        }
        if (startDate === undefined) {
            setEndDate(new Date());
        }

        updateData({ startDate, endDate, productVersion: props.productVersion });
    }, [props.productVersion, startDate, endDate]);

    useEffect(() => {
        if (activeInstallsStatisticsList === undefined) {
            setCountData([]);
            return;
        }
        setCountData(activeInstallsStatisticsList.map(r => {
            const date = new Date(r.date);
            const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
            return [utcDate.getTime(), r.count];
        }));
    }, [activeInstallsStatisticsList]);

    return (
        <React.Fragment>
            {activeInstallsStatisticsList === undefined && <Loader />}
            {activeInstallsStatisticsList !== undefined &&
                <React.Fragment >
                    <StyledContainer fluid>
                        <Row>
                            <Col sm={3}>
                                <strong>Start Date:</strong> <br />
                                <StatisticsDatePicker
                                    className="form-control"
                                    popperPlacement="top-end"
                                    selected={startDate}
                                    onChange={changeStartDate}
                                    dates={startDates}
                                />
                            </Col>
                            <Col sm={3}>
                                <strong>End Date:</strong><br />
                                <StatisticsDatePicker
                                    className="form-control"
                                    popperPlacement="top-end"
                                    selected={endDate}
                                    onChange={changeEndDate}
                                    dates={endDates}
                                />
                            </Col>
                        </Row>
                    </StyledContainer>
                    {
                        activeInstallsStatisticsList.length !== 0 && (
                            <>
                                <StyledHighcharts>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={optionsState}
                                    />
                                </StyledHighcharts>
                                <br />
                            </>
                        )
                    }
                    <ActiveInstallsStatisticsListView list={activeInstallsStatisticsList} />
                </React.Fragment>
            }
        </React.Fragment>
    );
};
