import React from "react";
import styled from "styled-components";

const Stars = styled.div<{ percent: number, max: number, size?: number }>`
    font-size: ${props => props.size !== undefined ? `${props.size}` : "14"}px;
    line-height: 1;

    &::before {
        content: '${props => Array.from({ length: props.max }, () => "★").join("")}';
        background: ${props => `linear-gradient(90deg, #fc0 ${props.percent}%, #949494 ${props.percent}%)`};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`;

export interface RatingProps {
    value: number;
    max: number;
    size?: number;
    className?: string;
    title?: string;
}

export const Rating = (props: RatingProps) => {
    const percent = props.value / props.max * 100;
    return <Stars className={props.className} title={props.title} percent={percent} max={props.max} size={props.size} />;
};