import { css } from "styled-components";

export const ThinScrollbar = css`
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    };
    &::-webkit-scrollbar-thumb {
        background-color: #b1b1b1;
    };
`;