import React, { useState, useCallback, useMemo, useEffect } from "react";
import { BreadcrumbItem, Button } from "reactstrap";
import { defer } from "rxjs";
import {
    GamestoreApplicationSummary,
    GamestoreApplicationListDataType,
    OrderDirection,
    Category,
    ExportApplicationsList,
    ScreenOrientationType,
    ApkPlatform
} from "src/shared/dtos";
import { Breadcrumb, DocumentTitle, RouteLink, RouteUserProps, Loader, BulkApplicationToggler, notifySuccess, notifyError } from "src/shared/components";
import {
    Column,
    DefaultCellRenderer,
    InfiniteTable,
    StringSearch,
    TopPanelRendererProps,
    TopPanelLeftContainer,
    LoadRequest,
    SearchOptions,
    BooleanSearch,
    multiValueSearchFactory,
    SuggestionTag,
    BooleanIndicator,
    DateSearch,
    multipleCategorySearchFactory,
    CellRenderer
} from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { Content, VerticalBox } from "src/shared/components/flex";
import {
    formatSections,
    formatAsAbbreviation,
    htmlToPlainText,
    GetGoogleAppPublisherLink,
    GetGoogleAppCategoryLink,
    deserializeQueryParameters,
    QueryParameterDescriptor,
    serializeQueryParameters,
    pushUrl,
    getGridVisibleColumns,
    useBootstrapBreakpoint,
    serializeDate,
    deserializeDate,
    useSubscription,
    isColumnHidden,
} from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { LanguageSelector } from "src/shared/components/LanguageSelector";
import { Rating } from "src/shared/components/Rating";
import api, { ListRequest } from "../api";
import { CategoryType } from "src/shared/CategoryType";
import { client } from "src/shared/client";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { multiValueCheckboxFactory } from "src/shared/components/InfiniteTable/MultiValueCheckboxSearch";

const keyDataTypes: { [key: string]: GamestoreApplicationListDataType } = {
    "appId": GamestoreApplicationListDataType.Id,
    "title": GamestoreApplicationListDataType.Title,
    "publisher": GamestoreApplicationListDataType.Publisher,
    "rating": GamestoreApplicationListDataType.Rating,
    "version32": GamestoreApplicationListDataType.Version32,
    "version64": GamestoreApplicationListDataType.Version64,
    "installsCount": GamestoreApplicationListDataType.InstallsCount,
    "reviewsCount": GamestoreApplicationListDataType.ReviewsCount,
    "modifiedAt": GamestoreApplicationListDataType.ModifiedDate,
    "x32ApkUpdatedAt": GamestoreApplicationListDataType.X32ApkUpdatedDate,
    "x64ApkUpdatedAt": GamestoreApplicationListDataType.X64ApkUpdatedDate,
};
const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType as GamestoreApplicationListDataType);

function getSearchValueByKey<T>(key: string, searchOptions?: Array<SearchOptions<unknown>>): T | undefined {
    return searchOptions?.find(s => s.key === key)?.value as T;
}

const searchQueryParameterDescriptors: QueryParameterDescriptor[] = [
    { paramType: "language", paramName: "language", serializer: (value: string) => value === "en" ? undefined : value },
    {
        paramType: "search",
        paramName: "platforms",
        serializer: (value: ApkPlatform[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    { paramType: "search", paramName: "title" },
    { paramType: "search", paramName: "appId" },
    { paramType: "search", paramName: "publisher" },
    {
        paramType: "search",
        paramName: "categories",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "screenOrientation",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    { paramType: "search", paramName: "isFeatured", serializer: "boolean", deserializer: "boolean" },
    { paramType: "search", paramName: "isGraphicsCustomized", serializer: "boolean", deserializer: "boolean" },
    { paramType: "search", paramName: "enabled", serializer: "boolean", deserializer: "boolean" },
    { paramType: "search", paramName: "isNew", serializer: "boolean", deserializer: "boolean" },
    {
        paramType: "search",
        paramName: "modifiedAtStartDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "modifiedAtEndDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "x32ApkUpdatedAtStartDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "x32ApkUpdatedAtEndDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "x64ApkUpdatedAtStartDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "x64ApkUpdatedAtEndDate",
        serializer: serializeDate,
        deserializer: deserializeDate
    }
];

const makeBooleanRenderer = (trueTitle: string, falseTitle: string): CellRenderer<boolean, GamestoreApplicationSummary> =>
    (props: CellProps<boolean, GamestoreApplicationSummary>) => {
        const { cellData, ...restProps } = props;
        const value = <BooleanIndicator value={cellData || undefined} />;
        const title = cellData ? trueTitle : falseTitle;

        return <DefaultCellRenderer cellData={value} title={title} centerText={true} {...restProps} />;
    };

const platforms = Object.values(ApkPlatform);

export const ListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [lang, tableFilters, sort] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...searchQueryParameterDescriptors);
        const languageParam = allParams.find(p => p.paramType === "language")?.value as string | undefined;
        let searchParams = allParams
            .filter(p => p.paramType === "search")
            .map(qp => ({ key: qp.paramName, value: qp.value } as SearchOptions<unknown>));

        const modifiedAtStartDate = searchParams.find(p => p.key === "modifiedAtStartDate");
        const modifiedAtEndDate = searchParams.find(p => p.key === "modifiedAtEndDate");
        searchParams = searchParams.filter(p => p.key !== "modifiedAtStartDate" && p.key !== "modifiedAtEndDate");
        if (modifiedAtStartDate?.value !== undefined && modifiedAtEndDate?.value !== undefined) {
            searchParams.push({ key: "modifiedAt", value: [modifiedAtStartDate.value, modifiedAtEndDate.value] });
        }

        const x32ApkUpdatedAtStartDate = searchParams.find(p => p.key === "x32ApkUpdatedAtStartDate");
        const x32ApkUpdatedAtEndDate = searchParams.find(p => p.key === "x32ApkUpdatedAtEndDate");
        searchParams = searchParams.filter(p => p.key !== "x32ApkUpdatedAtStartDate" && p.key !== "x32ApkUpdatedAtEndDate");
        if (x32ApkUpdatedAtStartDate?.value !== undefined && x32ApkUpdatedAtEndDate?.value !== undefined) {
            searchParams.push({ key: "x32ApkUpdatedAt", value: [x32ApkUpdatedAtStartDate.value, x32ApkUpdatedAtEndDate.value] });
        }

        const x64ApkUpdatedAtStartDate = searchParams.find(p => p.key === "x64ApkUpdatedAtStartDate");
        const x64ApkUpdatedAtEndDate = searchParams.find(p => p.key === "x64ApkUpdatedAtEndDate");
        searchParams = searchParams.filter(p => p.key !== "x64ApkUpdatedAtStartDate" && p.key !== "x64ApkUpdatedAtEndDate");
        if (x64ApkUpdatedAtStartDate?.value !== undefined && x64ApkUpdatedAtEndDate?.value !== undefined) {
            searchParams.push({ key: "x64ApkUpdatedAt", value: [x64ApkUpdatedAtStartDate.value, x64ApkUpdatedAtEndDate.value] });
        }

        const orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        const orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;

        return [languageParam, searchParams, { key: orderBy, order: orderDir } as SortOptions];
    }, [location.search]);

    const [items, setItems] = useState<GamestoreApplicationSummary[]>([]);
    const [visibleColumns, setVisibleColumns] = useState<string[] | "all" | undefined>();
    const [allCategories, setAllCategories] = useState<Category[] | undefined>();
    const [language, setLanguage] = useState("en");
    const [screenOrientation, setScreenOrientation] = useState<string[] | undefined>();
    const [reloadTrigger, setReloadTrigger] = useState<boolean>(false);

    const forceReload = (() => setReloadTrigger(p => !p));

    const breakpoint = useBootstrapBreakpoint();
    useEffect(() => setLanguage(lang ?? "en"), [lang]);

    useEffect(() => {
        setScreenOrientation(Object.values(ScreenOrientationType));
    }, []);

    const updateCategories = useSubscription(() => defer(
        () => api.getCategories()).subscribe(categories => {
            const validCategories = categories
                .filter(c => !c.isVirtual
                    && [CategoryType.Game, CategoryType.App, CategoryType.Discovery].some(v => c.parentId === v || c.id === v));
            setAllCategories(validCategories);
        }), [api.getCategories]);

    useEffect(() => {
        updateCategories();
    }, []);

    const updateVisibleColumns = useSubscription(() => defer(() => getGridVisibleColumns("Applications"))
        .subscribe(columns => setVisibleColumns(columns ?? "all")), []);

    useEffect(() => {
        updateVisibleColumns();
    }, [breakpoint]);

    const updateData = useSubscription((
        request: ListRequest,
        isReload: boolean,
        resolve: (val: boolean) => void) => defer(() => api.list(request)).subscribe(result => {
            if (result.length > 0) {
                setItems(prev => isReload ? result : prev.concat(result));
            }
            resolve(result.length === 0);
        }), [api.list]);

    const load = useCallback(
        (request: LoadRequest) => {
            if (request.reload) {
                setItems([]);
            }

            const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : GamestoreApplicationListDataType.Id;
            const searchRequest = {
                language,
                platforms: getSearchValueByKey<ApkPlatform[]>("platforms", request.search),
                orderBy: orderByKey,
                orderDir: orderByKey !== undefined
                    ? request.sort?.order === SortOrder.DESC ? OrderDirection.Desc : OrderDirection.Asc
                    : undefined,
                appId: getSearchValueByKey<string>("appId", request.search),
                title: getSearchValueByKey<string>("title", request.search),
                publisher: getSearchValueByKey<string>("publisher", request.search),
                categories: getSearchValueByKey<string[]>("categories", request.search),
                isFeatured: getSearchValueByKey<boolean>("isFeatured", request.search),
                screenOrientation: getSearchValueByKey<ScreenOrientationType[]>("screenOrientation", request.search),
                isGraphicsCustomized: getSearchValueByKey<boolean>("isGraphicsCustomized", request.search),
                isNew: getSearchValueByKey<boolean>("isNew", request.search),
                enabled: getSearchValueByKey<boolean>("enabled", request.search),
                modifiedAtStartDate: getSearchValueByKey<Date[]>("modifiedAt", request.search)?.[0],
                modifiedAtEndDate: getSearchValueByKey<Date[]>("modifiedAt", request.search)?.[1],
                x32ApkUpdatedAtStartDate: getSearchValueByKey<Date[]>("x32ApkUpdatedAt", request.search)?.[0],
                x32ApkUpdatedAtEndDate: getSearchValueByKey<Date[]>("x32ApkUpdatedAt", request.search)?.[1],
                x64ApkUpdatedAtStartDate: getSearchValueByKey<Date[]>("x64ApkUpdatedAt", request.search)?.[0],
                x64ApkUpdatedAtEndDate: getSearchValueByKey<Date[]>("x64ApkUpdatedAt", request.search)?.[1]
            };

            const requestBody: ListRequest = {
                language: searchRequest.language,
                platformsSearch: searchRequest.platforms,
                offset: request.offset,
                limit: request.limit,
                orderBy: searchRequest.orderBy,
                orderDirection: searchRequest.orderDir ?? OrderDirection.Asc,
                idSearch: searchRequest.appId,
                titleSearch: searchRequest.title,
                publisherSearch: searchRequest.publisher,
                categoriesSearch: searchRequest.categories,
                isFeaturedSearch: searchRequest.isFeatured,
                screenOrientationSearch: searchRequest.screenOrientation,
                isGraphicsCustomizedSearch: searchRequest.isGraphicsCustomized,
                isNewSearch: searchRequest.isNew,
                isAppDisabledSearch: searchRequest.enabled !== undefined ? !searchRequest.enabled : undefined,
                modifiedAtStartDateSearch: searchRequest.modifiedAtStartDate,
                modifiedAtEndDateSearch: searchRequest.modifiedAtEndDate,
                x32ApkUpdatedAtStartDateSearch: searchRequest.x32ApkUpdatedAtStartDate,
                x32ApkUpdatedAtEndDateSearch: searchRequest.x32ApkUpdatedAtEndDate,
                x64ApkUpdatedAtStartDateSearch: searchRequest.x64ApkUpdatedAtStartDate,
                x64ApkUpdatedAtEndDateSearch: searchRequest.x64ApkUpdatedAtEndDate
            };

            const url = routes.applications.url({
                searchParams: serializeQueryParameters(searchRequest, ...searchQueryParameterDescriptors)
            });
            pushUrl(location, history, url);

            return new Promise<boolean>(resolve => {
                updateData(requestBody, request?.reload, resolve);
            });
        }, [items, language, location]);

    const changeLanguage = useCallback(
        (value: string) => {
            setItems([]);
            setLanguage(value);
        }, [language]);

    const platformMapper = useCallback((val: ApkPlatform): string => {
        switch (val) {
            case ApkPlatform.X64:
                return "64 bit";
            case ApkPlatform.X32:
                return "32 bit";
            default:
                return "unknown";
        }
    }, []);

    const orientationValues = useMemo(() => screenOrientation !== undefined
        ? screenOrientation
        : [], [screenOrientation]);

    const orientationMapper = (id: string): SuggestionTag => {
        const orientation = screenOrientation?.find(el => el === id);
        return {
            value: orientation ?? "",
            label: orientation ?? ""
        };
    };

    const toggleApps = useCallback(
        (file: File, isDisabled: boolean) => api.toggleApps(file, isDisabled), []);

    const TopPanel = useMemo(() =>
        (props: TopPanelRendererProps) =>
            <props.blockElement>
                <TopPanelLeftContainer>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Applications</BreadcrumbItem>
                    </Breadcrumb>
                </TopPanelLeftContainer>
                <RouteLink user={user} to={routes.addApplication} button className="mr-2">
                    Add Application
                </RouteLink>
                <Button href={client.resolveUrl("GET", new ExportApplicationsList({ language }))} className="mr-2">
                    Export
                </Button>
                <BulkApplicationToggler className="mr-2" toggleApps={toggleApps} onSUccess={forceReload} />
                <LanguageSelector value={language} setValue={changeLanguage} className="mr-2" />
                <props.reloadElement />
                <props.optionsElement />
            </props.blockElement>, [language]);

    const iconRenderer = useMemo(() =>
        (props: CellProps<string | undefined, GamestoreApplicationSummary>) =>
            <RouteLink
                user={user}
                to={{ route: routes.editApplication, args: { appId: props.rowData.id, language } }}>
                <img src={props.cellData ?? ""} alt={props.cellData ?? "Application Icon"} width={50} height={50} />
            </RouteLink>, [language]);

    const titleRenderer = useMemo(() =>
        (props: CellProps<unknown, GamestoreApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{ route: routes.editApplication, args: { appId: props.rowData.id, language } }}
                    className="d-inline">
                    {props.rowData.title}
                </RouteLink>
            );

            return <DefaultCellRenderer cellData={link} title={props.rowData.title} {...restProps} />;
        }, [language]);

    const appIdRenderer = useMemo(() =>
        (props: CellProps<string, GamestoreApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{ route: routes.editApplication, args: { appId: props.rowData.id, language } }}
                    className="d-inline">
                    {props.rowData.id}
                </RouteLink>
            );

            return <DefaultCellRenderer cellData={link} title={cellData} {...restProps} />;
        }, [language]);

    const publisherRenderer = useMemo(() =>
        (props: CellProps<string | undefined, GamestoreApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <a
                    href={GetGoogleAppPublisherLink(language, cellData, props.rowData.publisherId)}
                    target="_blank">
                    {cellData}
                </a>
            );

            return <DefaultCellRenderer cellData={link} title={cellData} {...restProps} />;
        }, [language]);

    const ratingRenderer = useMemo(() =>
        (props: CellProps<number, GamestoreApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const rating = <Rating value={cellData} max={5} />;
            const title = `${cellData}`;

            return <DefaultCellRenderer cellData={rating} title={title} {...restProps} />;
        }, []);

    const screenOrientationRenderer = useMemo(() =>
        (props: CellProps<ScreenOrientationType, GamestoreApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const orientation = cellData;
            const title = `This game has ${cellData} screen orientation.`;

            return <DefaultCellRenderer cellData={orientation} title={title} {...restProps} />;
        }, []);

    const categoriesRenderer = useMemo(() =>
        (props: CellProps<Category[], GamestoreApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const sortedCats = cellData.sort((a, b) => {
                const parentResult = (a.parentId ?? "").localeCompare(b.parentId ?? "");
                return parentResult === 0
                    ? a.name.localeCompare(b.name)
                    : parentResult;
            });

            const cell = (
                <React.Fragment>
                    {sortedCats.map((c, i) => {
                        const title = `Parent ID: ${c.parentId}`;

                        return (
                            <span key={`${c.parentId} ${c.id}`}>
                                {c.parentId === CategoryType.Game || c.parentId === CategoryType.App
                                    ? (
                                        <a
                                            href={GetGoogleAppCategoryLink(c.id, language)}
                                            title={title}
                                            target="_blank"
                                        >
                                            {c.name}
                                        </a>
                                    )
                                    : <span title={title}>{c.name}</span>}
                                {i < sortedCats.length - 1 &&
                                    <span>, </span>}
                            </span>
                        );
                    })}
                </React.Fragment>
            );

            return <DefaultCellRenderer cellData={cell} {...restProps} />;
        }, [language]);

    const descriptionRenderer = useMemo(() =>
        (props: CellProps<string | undefined, GamestoreApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const title = `${cellData ?? ""}\n\n${htmlToPlainText(props.rowData.description ?? "")}`;

            return <DefaultCellRenderer cellData={cellData} title={title} {...restProps} />;
        }, [language]);

    const versionRenderer = useMemo(() =>
        (props: CellProps<string | undefined, GamestoreApplicationSummary>) => {
            const { cellData, ...restProps } = props;

            return <DefaultCellRenderer cellData={cellData} title={cellData} {...restProps} />;
        }, []);

    const installCountAndReviewCountRenderer = useMemo(() =>
        (props: CellProps<number, GamestoreApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const size = formatAsAbbreviation(cellData);
            const title = `${formatSections(cellData)}+`;

            return <DefaultCellRenderer cellData={size} title={title} {...restProps} />;
        }, []);

    const isFeaturedRenderer = useMemo(() => makeBooleanRenderer(
        "Application will be shown on the Game Center page as featured application.",
        "Application is not considered featured."), []);

    const isGraphicsCustomizedRenderer = useMemo(() => makeBooleanRenderer(
        "Application has fully customized graphics and will have show priority over non-customized apps.",
        "Application does not have customized graphics."), []);

    const isEnabledRenderer = useMemo(() => makeBooleanRenderer(
        "Application is enabled.",
        "Application is disabled."), []);

    const isNewRenderer = useMemo(() => makeBooleanRenderer(
        "Application is marked as new with NEW tag.",
        "Application is not marked with NEW tag."), []);

    const dateRenderer = useMemo(() =>
        (props: CellProps<Date | undefined, GamestoreApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const value = cellData !== undefined ? new Date(cellData).toLocaleDateString() : undefined;

            return <DefaultCellRenderer cellData={value} title={value} {...restProps} />;
        }, []);

    const platformRenderer = useMemo(() =>
        (props: CellProps<ApkPlatform[], GamestoreApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const label = cellData.sort().join(" & ");
            return <DefaultCellRenderer cellData={label} title={label} {...restProps} />;
        }, []);

    const testedVersionsRenderer = useMemo(() =>
        (props: CellProps<string[] | undefined, GamestoreApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const value = (cellData?.length ?? 0) === 0 ? "" : cellData!.join(", ");
            return <DefaultCellRenderer cellData={value} title={value} {...restProps} />;
        }, []);

    const select = makeSelect<GamestoreApplicationSummary>();

    const onOptionsClose = useCallback((visColumns: string[]) => {
        if (Array.isArray(visibleColumns) && visColumns.join("") === visibleColumns.join("")) {
            return;
        }
        if (breakpoint) {
            api.saveGridSettings(breakpoint, visColumns)
                .then(_ => {
                    notifySuccess("Columns settings saved successfully");
                    setVisibleColumns(visColumns);
                })
                .catch(_ => notifyError("Failed to save column settings"));
        }
    }, [breakpoint, visibleColumns]);

    return (
        <VerticalBox>
            <DocumentTitle title="Applications" />
            <Content overflowHidden>
                {(allCategories === undefined || visibleColumns === undefined) && <Loader />}
                {allCategories !== undefined && visibleColumns !== undefined &&
                    <InfiniteTable
                        items={items}
                        load={load}
                        renderContext={language + reloadTrigger}
                        filters={tableFilters}
                        sortBy={sort}
                        topPanelRenderer={TopPanel}
                        visibleColumns={visibleColumns}
                        onOptionsClose={onOptionsClose}
                    >
                        <Column
                            key="icon"
                            dataGetter={select(a => a.iconUrl)}
                            width={70}
                            minWidth={70}
                            cellRenderer={iconRenderer}
                            resizable={false}
                            sortable={false}
                        />
                        <Column
                            key="title"
                            title="Title"
                            width={260}
                            cellRenderer={titleRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="appId"
                            dataGetter={select(a => a.id)}
                            title="AppID"
                            width={320}
                            cellRenderer={appIdRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="publisher"
                            dataGetter={select(a => a.publisher)}
                            title="Publisher"
                            width={140}
                            cellRenderer={publisherRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="rating"
                            dataGetter={select(a => a.rating)}
                            title="Rating"
                            width={125}
                            minWidth={75}
                            cellRenderer={ratingRenderer}
                            resizable={false}
                        />
                        <Column
                            key="categories"
                            dataGetter={select(a => a.categories)}
                            title="Categories"
                            width={360}
                            sortable={false}
                            cellRenderer={categoriesRenderer}
                            searchRenderer={multipleCategorySearchFactory(allCategories)}
                        />
                        <Column
                            key="description"
                            dataGetter={select(a => a.shortDescription)}
                            title="Description"
                            width={420}
                            sortable={false}
                            cellRenderer={descriptionRenderer}
                        />
                        <Column
                            key="platforms"
                            dataGetter={select(a => a.platforms)}
                            title="Platform"
                            width={160}
                            sortable={false}
                            cellRenderer={platformRenderer}
                            searchRenderer={multiValueCheckboxFactory(platforms, platformMapper)}
                        />
                        <Column
                            key="version32"
                            dataGetter={select(a => a.version32)}
                            title="Version32"
                            width={140}
                            cellRenderer={versionRenderer}
                        />
                        <Column
                            key="version64"
                            dataGetter={select(a => a.version64)}
                            title="Version64"
                            width={140}
                            cellRenderer={versionRenderer}
                        />
                        <Column
                            key="installsCount"
                            dataGetter={select(a => a.installsCount)}
                            title="#install"
                            width={140}
                            cellRenderer={installCountAndReviewCountRenderer}
                        />
                        <Column
                            key="reviewsCount"
                            dataGetter={select(a => a.reviewsCount)}
                            title="#reviews"
                            width={140}
                            cellRenderer={installCountAndReviewCountRenderer}
                        />
                        <Column
                            key="screenOrientation"
                            dataGetter={select(s => s.screenOrientation)}
                            title="Screen Orientation"
                            width={260}
                            cellRenderer={screenOrientationRenderer}
                            searchRenderer={multiValueSearchFactory(orientationValues, orientationMapper)}
                        />
                        <Column
                            key="isFeatured"
                            dataGetter={select(a => a.isFeatured)}
                            title="Featured"
                            width={120}
                            sortable={false}
                            cellRenderer={isFeaturedRenderer}
                            searchRenderer={BooleanSearch}
                        />
                        <Column
                            key="isGraphicsCustomized"
                            dataGetter={select(a => a.isGraphicsCustomized)}
                            title="Graphics Customized"
                            width={120}
                            sortable={false}
                            cellRenderer={isGraphicsCustomizedRenderer}
                            searchRenderer={BooleanSearch}
                        />
                        <Column
                            key="isNew"
                            dataGetter={select(a => a.isNew)}
                            title="Is New"
                            width={120}
                            sortable={false}
                            cellRenderer={isNewRenderer}
                            searchRenderer={BooleanSearch}
                        />
                        <Column
                            key="enabled"
                            dataGetter={select(a => !a.isDisabled)}
                            title="Enabled"
                            width={120}
                            sortable={false}
                            cellRenderer={isEnabledRenderer}
                            searchRenderer={BooleanSearch}
                        />
                        <Column
                            key="disableDate"
                            dataGetter={select(a => a.disableDate)}
                            title="Disabled on"
                            width={120}
                            sortable={false}
                            cellRenderer={dateRenderer}
                            hidden={isColumnHidden("disableDate", visibleColumns)}
                        />
                        <Column
                            key="disableReason"
                            dataGetter={select(a => a.disableReason)}
                            title="Disable Reason"
                            width={160}
                            sortable={false}
                            hidden={isColumnHidden("disableReason", visibleColumns)}
                        />
                        <Column
                            key="testedVersions"
                            dataGetter={select(a => a.testedVersions)}
                            title="Tested Versions"
                            width={160}
                            sortable={false}
                            cellRenderer={testedVersionsRenderer}
                            hidden={isColumnHidden("testedVersions", visibleColumns)}
                        />
                        <Column
                            key="modifiedAt"
                            dataGetter={select(a => a.modifiedAt)}
                            title="Modified date"
                            width={100}
                            minWidth={100}
                            sortable={true}
                            cellRenderer={dateRenderer}
                            searchRenderer={DateSearch}
                        />
                        <Column
                            key="x32ApkUpdatedAt"
                            dataGetter={select(a => a.x32ApkUpdatedAt)}
                            title="X32 APK updated date"
                            width={120}
                            minWidth={120}
                            sortable={true}
                            cellRenderer={dateRenderer}
                            searchRenderer={DateSearch}
                        />
                        <Column
                            key="x64ApkUpdatedAt"
                            dataGetter={select(a => a.x64ApkUpdatedAt)}
                            title="X64 APK updated date"
                            width={120}
                            minWidth={120}
                            sortable={true}
                            cellRenderer={dateRenderer}
                            searchRenderer={DateSearch}
                        />
                    </InfiniteTable>}
            </Content>
        </VerticalBox>);
});


