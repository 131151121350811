export const previewVariables = {
    gameCardWidth: 170,
    gameCardHeight: 140,
    cardGap: 10,
    upperHeaderBgColor: "#3c3048",
    controlButtonColor: "#b094ac",
    lowerHeaderBgColor: "#272331",
    headerTextColor: "#b8b48c",
    headerTitleTextColor: "#ffc414",
    gameCardTextColor: "#fff",
    gameCardScoreColor: "#f4923b",
    gameCardBoxShadowColor: "rgba( 234, 85, 255, 0.4)",
    contentMainColor: "#fff",
    contentTitleColor: "#f4923b",
    contentFadeColor: "#ffffff9c",
    gameScreenButtonTextColor: "#fff",
    gameScreenButtonBgColor: "#ab2bf3",
    gameScreenButtonHoverTextColor: "#fff",
    gameScreenButtonHoverBgColor: "#fa9a3b",
    sideNavBorderColor: "#ab2bf3",
};