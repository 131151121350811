import { Layout, Layouts } from "react-grid-layout";
import { WidgetUnitLayouts, WidgetUnitLayout, WidgetUnitSummary, WidgetUnit, DashboardSettings } from "src/shared/dtos";

export const getDashboardLayouts = (widgets : WidgetUnitSummary[], settings: DashboardSettings): Layouts => {
    return widgets.reduce((layouts, widget) => {
        const widgetSettings = settings.widgetsSettings?.[widget.widgetType];
        const defaultSettings = settings.widgetsSettings?.default;
        const minWidth = widgetSettings?.minW ?? defaultSettings?.minW ?? 1;
        const minH = widgetSettings?.minH ?? defaultSettings?.minH ?? 8;
        const breakpoints = Object.keys(settings.columns);
        breakpoints.forEach(breakpoint => {
            const maxCols = settings.columns?.[breakpoint] ?? 1;
            const minW = Math.min(minWidth, maxCols);
            const layout = widget.layouts?.find(l => l.breakPoint === breakpoint);
            layouts[breakpoint] = (layouts[breakpoint] ?? []).concat({
                i: getLayoutKey(widget.id),
                w: Math.max(layout?.w ?? 0, minW),
                h: Math.max(layout?.h ?? 0, minH),
                x: layout?.x ?? 0,
                y: layout?.y ?? Infinity,
                minW,
                minH,
                maxH: widgetSettings?.maxH ?? defaultSettings?.maxH,
                maxW: widgetSettings?.maxW ?? defaultSettings?.maxW
            });
        });
        return layouts;
    }, {} as Layouts);
};

export const makeNewLayoutItem = (widget: WidgetUnit, breakpoint: string, settings: DashboardSettings): Layout => {
    const widgetSettings = settings.widgetsSettings?.[widget.widgetType];
    const defaultSettings = settings.widgetsSettings?.default;
    const minWidth = widgetSettings?.minW ?? defaultSettings?.minW ?? 1;
    const maxCols = settings.columns?.[breakpoint] ?? 1;
    const minW = Math.min(minWidth, maxCols);
    const minH = widgetSettings?.minH ?? defaultSettings?.minH ?? 4;
    return {
        i: getLayoutKey(widget.id),
        w: minW,
        h: minH,
        x: 0,
        y: Infinity,
        minW,
        minH,
        maxH: widgetSettings?.maxH ?? defaultSettings?.maxH,
        maxW: widgetSettings?.maxW ?? defaultSettings?.maxW
    };
};

export const getLayoutKey = (id: number): string => "widget-" + id;
const getWidgetId = (key: string): number => Number.parseInt(key.substring(7), 10);

export const getWidgetsLayouts = (layouts: Layouts): WidgetUnitLayouts[] => {
    const layoutsById: {[key: string]: WidgetUnitLayout[]} = {};
    for (const breakPoint of Object.keys(layouts)){
        layouts[breakPoint].forEach(layout => {
            layoutsById[layout.i] = (layoutsById[layout.i] ?? []).concat({
                breakPoint,
                x: layout.x,
                y: layout.y,
                w: layout.w,
                h: layout.h
            });
        });
    }
    return Object.entries(layoutsById).map(([key, val]) => ({
        id: getWidgetId(key),
        layouts: val
    }));
};