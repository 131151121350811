import React, { useState, useMemo, useCallback } from "react";
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CenterVertically, CenterVerticallyContainer } from "./mixin";
import { setFromInput } from "../helpers/setFromInput";
import { ThinScrollbar } from "./mixin/ThinScrollbar";
import { classNames } from "@servicestack/client";

export interface ListEditorAddControlRendererProps<TItem> {
    /**
     * The id of the input that is treated as main. Can be used for 'label' tag 'for' attribute.
     */
    mainInputId?: string;

    /**
     * The add item method.
     */
    add: (text: TItem) => void;

    /**
     * Is control disabled.
     */
    disabled: boolean;

    onFocus?: () => void;
}

export interface ListEditorRemoveButtonProps {
    /**
     * The element class name.
     */
    className?: string;

    /**
     * The remove item method.
     */
    remove: () => void;

    /**
     * Is control disabled.
     */
    disabled: boolean;
}

export interface ListEditorItemRendererProps<TItem> {
    /**
     * The item to render.
     */
    item: TItem;

    /**
     * The remove item method.
     */
    remove: () => void;

    /**
     * Is control disabled.
     */
    disabled: boolean;

    /**
     * The default remove element renderer
     */
    removeButton: (props: ListEditorRemoveButtonProps) => JSX.Element;
}

export interface ListEditorProps<TItem> {
    /**
     * The id of the list container. Can be used for 'label' tag 'for' attribute.
     */
    listContainerId?: string;

    /**
     * The id of the input that is treated as main. Can be used for 'label' tag 'for' attribute.
     */
    mainInputId?: string;

    /**
     * The root class name.
     */
    className?: string;

    /**
     * The current items.
     */
    items: TItem[];

    /**
     * The add item method.
     */
    add: (text: TItem) => void;

    /**
     * The remove item method.
     */
    remove: (item: TItem) => void;

    /**
     * The item renderer. Resulting element must have unique key, as the item will be rendered inside of the map.
     */
    itemRenderer?: (props: ListEditorItemRendererProps<TItem>) => JSX.Element;

    /**
     * The add control renderer.
     */
    addControlRenderer?: (props: ListEditorAddControlRendererProps<TItem>) => JSX.Element;

    /**
     * Is control disabled.
     */
    disabled?: boolean;

    /**
     * Is control invalid.
     */
    invalid?: boolean;

    /**
     * Pass onFocus event.
     */
    onFocus?: () => void;
}

const List = styled.div`
    height: 120px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0.5rem 1rem;
    margin: 0;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #212529;
    background-color: #fff;
    ${ThinScrollbar}
`;

const Item = styled.div`
    ${CenterVerticallyContainer}
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    padding-right: 1.25rem;
`;

const ItemText = styled.span`
    vertical-align: middle;
`;

const CloseIconContainer = styled.span<{ hidden?: boolean }>`
    position: absolute;
    display: ${props => props.hidden ? "none" : "inline"};
    cursor: pointer;
    height: 100%;
    width: 2rem;
    padding-left: 1rem;
    right: 0;
`;

const CloseIcon = styled(FontAwesomeIcon).attrs({ icon: "times" })`
    ${CenterVertically}
    color: #6c757d;

    &:hover {
        color: red;
    }
`;

const RemoveButton = (props: ListEditorRemoveButtonProps) => {
    const onClick = (e: React.SyntheticEvent<HTMLSpanElement>) => {
        e.preventDefault();
        e.stopPropagation();
        props.remove();
    };

    return (
        <CloseIconContainer className={props.className} onClick={onClick} hidden={props.disabled}>
            <CloseIcon />
        </CloseIconContainer>
    );
};

export function ListEditor<TItem>(props: ListEditorProps<TItem>) {
    const [addText, setAddText] = useState("");

    const items = useMemo(() => props.items ?? [], [props.items]);
    const disabled = useMemo(() => props.disabled === true, [props.disabled]);
    const makeRemove = useMemo(() =>
        (item: TItem) => () => props.remove(item), [props.remove]);
    const add = useCallback(() => {
        setAddText("");
        props.add?.call(null, addText);
    }, [props.add]);

    const renderedAdd = props.addControlRenderer === undefined ? undefined : () => {
        return props.addControlRenderer!({
            mainInputId: props.mainInputId,
            add: props.add,
            disabled
        });
    };

    const listClassName = classNames("form-control", { "is-invalid": props.invalid === true });

    return (
        <div className={props.className} onFocus={props.onFocus}>
            <List id={props.listContainerId} className={listClassName}>
                {items.map((item, index) => {
                    const remove = makeRemove(item);
                    const rendered = props.itemRenderer?.call(null, {
                        item,
                        remove,
                        disabled,
                        removeButton: RemoveButton
                    } as ListEditorItemRendererProps<TItem>) as JSX.Element;

                    return rendered ?? (
                        <Item
                            key={`${item}_${index}`}
                            title={"" + item}
                            tabIndex={0}>
                            <ItemText>{item}</ItemText>
                            <RemoveButton remove={remove} disabled={disabled} />
                        </Item>
                    );
                })}
            </List>
            {renderedAdd !== undefined
                ? renderedAdd()
                : (
                    <InputGroup className="mt-2">
                        <Input
                            type="text"
                            id={props.mainInputId}
                            value={addText}
                            onChange={setFromInput(setAddText)}
                            disabled={props.disabled} />
                        <InputGroupAddon addonType="append">
                            <Button type="button" color="primary" onClick={add} disabled={props.disabled}>
                                Add
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                )}
        </div>
    );
}
