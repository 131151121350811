import React, { useMemo } from "react";
import ReactSelect, { Props } from "react-select/async";
import { OptionTypeBase, Styles } from "react-select";


export interface SelectAsyncProps<TOption extends OptionTypeBase> extends Props<TOption> {
    isInvalid?: boolean;
    exists?: boolean;
}

export function SelectAsync<TOption extends OptionTypeBase>(props: SelectAsyncProps<TOption>) {
    const { styles: baseStyles, ...restProps } = props;
    const customStyles: Styles = useMemo(() => ({
        control: (styles: any, state: any) => ({
            ...styles,
            backgroundColor: state.selectProps.exists ? "lightyellow" : "",
            borderColor: state.selectProps.isInvalid ? "#d9534f" : "#ddd",
            boxShadow: state.isFocused
                ? (state.selectProps.isInvalid ? "0 0 3px 1px #d9534f" : "0 0 3px 1px #2684ff")
                : "",
            ":hover": {
                borderColor: state.selectProps.isInvalid ? "#d9534f" : "#ddd",
                boxShadow: state.isFocused
                    ? (state.selectProps.isInvalid ? "0 0 3px 1px #d9534f" : "0 0 3px 1px #2684ff")
                    : ""
            }
        }),
        menu: (base) => ({
            ...base,
            zIndex: 5,
        }),
        ...baseStyles
    }), [baseStyles]);

    return <ReactSelect styles={customStyles} {...restProps} />;
}