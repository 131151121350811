import React, { useRef, useCallback, useMemo } from "react";
import ReactTags, { Tag } from "react-tags-typeahead";
import { Category } from "src/shared/dtos";
import { SearchCellHeaderProps, StyledTags } from ".";
import { mapCategorySuggestionTag } from "src/shared/helpers";
import { SuggestionTag } from "../MultiValueSearch";

type Props = SearchCellHeaderProps<string[], unknown> & {
    categories: Category[]
};

const MultiCategorySearch = (props: Props) => {
    const categoryIdsByParentCategoryId = useMemo(() => props.categories.reduce((dict, cat) => {
        if (!cat.parentId){
            return dict;
        }
        dict[cat.parentId] = (dict[cat.parentId] ?? []).concat(cat.id);
        return dict;
    }, {}), [props.categories]);

    const onAddition = useCallback(
        (tag: Tag) => {
            const newValue = (props.searchValue ?? [])
            .filter(id => !categoryIdsByParentCategoryId[tag.id]?.includes(id)) // remove from selected categories those that are children of the added category
            .concat(tag.id as string);
            props.setSearchValue(newValue);
        },
        [props.searchValue]);

    const onDelete = useCallback(
        (index: number) => props.setSearchValue((props.searchValue ?? []).filter((_, i) => i !== index)),
        [props.searchValue]);

    const categoryMapper = useCallback((id: string): SuggestionTag => {
        const found = props.categories?.find(c => c.id === id);
        return mapCategorySuggestionTag(found, props.categories);
    }, [props.categories]);

    const tags = useMemo(
        () => props.searchValue?.map(categoryMapper).map(v => ({ id: v.value, name: v.label } as Tag)) ?? [],
        [props.searchValue, categoryMapper]);

    const suggestions = useMemo(
        () => props.categories
        .filter(c => !props.searchValue?.some(id => c.id === id || c.parentId === id)) // remove from suggestions already selected categories and their child categories
        .map((c, _, cats) => mapCategorySuggestionTag(c, cats))
        .map(v => ({ id: v.value, name: v.label } as Tag) ?? []),
        [props.categories, props.searchValue]);

    const ref = useRef<ReactTags>(null);
    const clearTags = useCallback(() => {
        props.setSearchValue([]);
        ref.current?.clearInput();
    }, [ref, ref.current]);

    return (
        <StyledTags>
            <ReactTags
                ref={ref}
                tags={tags}
                minQueryLength={0}
                placeholderText="Search..."
                suggestions={suggestions}
                onAddition={onAddition}
                onDelete={onDelete}
                maxSuggestionsLength={suggestions.length}
            />
            <button className="clear" type="button" onClick={clearTags} />
        </StyledTags>
    );
};

export function multipleCategorySearchFactory(categories: Category[]) {
    return (props: SearchCellHeaderProps<string[], unknown>): JSX.Element => (
        <MultiCategorySearch {...props} categories={categories} />
    );
}