import { Layouts } from "react-grid-layout";
import { DashboardSettings, WidgetUnit, WidgetUnitSummary } from "src/shared/dtos";
import { getDashboardLayouts, getLayoutKey, makeNewLayoutItem } from "./layoutsConverter";

export type DashboardState = {
    currentLayouts?: Layouts;
    initialLayouts?: Layouts;
    settings?: DashboardSettings;
    widgets: WidgetUnitSummary[];
};

const defaultDashboardSettings: DashboardSettings = {
    widgetsSettings: {
        TextWidget: {
            maxW: 2
        },
        ListWidget: {
            minW: 2,
            minH: 16
        },
        InfoWidget: {
            minW: 1,
            minH: 12,
            maxH: 22
        },
        ChartWidget: {
            minW: 1,
            minH: 12
        },
        default: {
            maxW: 5,
            maxH: 35,
            minW: 1,
            minH: 8
        }
    },
    columns: {
        xxl: 4,
        xl: 3,
        md: 2,
        sm: 1
    },
    rowHeight: 10,
    maxRows: 130
};

export type DashboardAction =
    { kind: "set", widgets: WidgetUnitSummary[], settings: DashboardSettings | undefined } |
    { kind: "update", layouts: Layouts } |
    { kind: "add", widget: WidgetUnit } |
    { kind: "remove", id: number } |
    { kind: "save" } |
    { kind: "reset" };

const clone = (val: object) => JSON.parse(JSON.stringify(val));
const addWidgetToLayouts = (val: Layouts, widget: WidgetUnit, settings: DashboardSettings): Layouts => {
    const layouts = Object.assign({}, val);
    for (const breakpoint of Object.keys(layouts)) {
        layouts[breakpoint] = layouts[breakpoint].concat(makeNewLayoutItem(widget, breakpoint, settings));
    }
    return layouts;
};
const removeWidgetFromLayouts = (val: Layouts, id: number): Layouts => {
    const layoutKey = getLayoutKey(id);
    const layouts = Object.assign({}, val);
    for (const breakpoint of Object.keys(layouts)) {
        layouts[breakpoint] = layouts[breakpoint].filter(l => l.i !== layoutKey);
    }
    return layouts;
};

export const dashboardReducer = (state: DashboardState, action: DashboardAction): DashboardState => {
    switch (action.kind) {
        case "set": {
            const settings = action.settings ?? defaultDashboardSettings;
            const layouts = action.widgets.length > 0
                ? getDashboardLayouts(action.widgets, settings)
                : makeEmptyLayouts(Object.keys(settings.columns));
            return {
                ...state,
                currentLayouts: layouts,
                initialLayouts: clone(layouts),
                settings,
                widgets: action.widgets
            };
        }
        case "update": {
            return {
                ...state,
                currentLayouts: action.layouts
            };
        }
        case "add": {
            if (!state.currentLayouts || !state.settings || !state.initialLayouts) {
                return state;
            }
            const widgetSummary: WidgetUnitSummary = {
                id: action.widget.id,
                widgetType: action.widget.widgetType
            };
            return {
                ...state,
                currentLayouts: addWidgetToLayouts(state.currentLayouts, action.widget, state.settings),
                initialLayouts: addWidgetToLayouts(state.initialLayouts, action.widget, state.settings),
                widgets: state.widgets.concat(widgetSummary)
            };
        }
        case "remove": {
            if (!state.currentLayouts || !state.initialLayouts) {
                return state;
            }
            return {
                ...state,
                currentLayouts: removeWidgetFromLayouts(state.currentLayouts, action.id),
                initialLayouts: removeWidgetFromLayouts(state.initialLayouts, action.id),
                widgets: state.widgets.filter(w => w.id !== action.id)
            };
        }
        case "save": {
            if (!state.currentLayouts) {
                return state;
            }
            return {
                ...state,
                initialLayouts: clone(state.currentLayouts)
            };
        }
        case "reset": {
            if (!state.initialLayouts) {
                return state;
            }
            return {
                ...state,
                currentLayouts: clone(state.initialLayouts)
            };
        }
    }
};

const makeEmptyLayouts = (breakpoints: string[]): Layouts =>
    breakpoints.reduce((layouts, breakpoint) => {
        layouts[breakpoint] = [];
        return layouts;
    }, {});

export const defaultDashboardState: DashboardState = {
    currentLayouts: {},
    settings: undefined,
    widgets: []
};