import { ValueMapper } from "./valueMappers";
import { queryString as parseQuery } from "./url";

type Serializer<T> = ValueMapper<T, string | undefined>;
type Deserializer<T> = ValueMapper<string, T | undefined>;

type QueryParameterSerializationObj<T> = {
    serializer?: Serializer<T>;
    deserializer?: Deserializer<T>;
};
export type QueryParamsSerializerOptions<TParams extends object> = { [key in keyof TParams]: QueryParameterSerializationObj<TParams[key]> };

export const textDeserializer: Deserializer<string> = v => v.charAt(0).toLowerCase() + (v).substring(1);
export const arrayDeserializer: Deserializer<any[]> = v => v.split(",");

const getDefaultSerializer = (val: any): Serializer<any> => {
    if (Array.isArray(val)) {
        return v => v.length > 0 ? v.join(",") : undefined;
    }

    switch (typeof (val)) {
        case "boolean":
            return v => v === true ? "true" : v === false ? "false" : undefined;
        case "number":
        case "string":
        default:
            return v => v + "";
    }
};

export class QueryParametersSerializer<TParams extends object> {
    constructor(private readonly serializerOptions: QueryParamsSerializerOptions<TParams>) {
        this.serializerOptions = serializerOptions;
    }

    public deserialize(query: string) {
        const params = parseQuery(query) as Record<string, string | undefined>;
        return Object.entries(params).reduce((result, [key, val]) => {
            if (val === undefined) {
                return result;
            }
            const paramsOptions = this.serializerOptions[key] as QueryParameterSerializationObj<unknown>;
            const deserializer = paramsOptions?.deserializer;
            if (deserializer !== undefined) {
                result[key] = deserializer(val);
            } else {
                result[key] = textDeserializer(String(val));
            }
            return result;
        }, {}) as Partial<TParams>;
    }

    public serialize(obj: Partial<TParams>): Record<keyof TParams, string> {
        return Object.entries(obj).reduce((result, [key, val]) => {
            if (val === undefined) {
                return result;
            }
            const paramsOptions = this.serializerOptions[key] as QueryParameterSerializationObj<unknown>;
            const serializer = paramsOptions?.serializer ?? getDefaultSerializer(val);
            const serialized = serializer(val);
            if (serialized === undefined) {
                return result;
            }
            result[key] = serialized;
            return result;
        }, {} as Record<keyof TParams, string>);
    }
}