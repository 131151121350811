import { client } from "src/shared/client";
import { GetAdUnit, GetAdCampaign, GetAdUnitTrigger, ManageAdUnitTrigger, AdUnitTrigger } from "src/shared/dtos";

export const AdTriggerUnitsApi = {
    getAdCampaign: async (id: number) => {
        const adCampaign = await client.get(new GetAdCampaign({ id }));
        return adCampaign.id === undefined ? undefined : adCampaign;
    },
    getAdUnit: async (id: number) => {
        const adUnit = await client.get(new GetAdUnit({ id }));
        return adUnit.id === undefined ? undefined : adUnit;
    },
    get: async (id: number) => {
        const adUnitTrigger = await client.get(new GetAdUnitTrigger({ id }));
        return adUnitTrigger.id === undefined ? undefined : adUnitTrigger;
    },
    create: (adUnitTrigger: AdUnitTrigger) => client.post(new ManageAdUnitTrigger({ adUnitTrigger })),
    update: (adUnitTrigger: AdUnitTrigger) => client.put(new ManageAdUnitTrigger({ adUnitTrigger }))
};

export default AdTriggerUnitsApi;