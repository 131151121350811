import { validateUrl } from "../components/propertyField/validation/validateUrl";
import { DownloaderVideo, UrlValidationType } from "../dtos";
import { imageExists } from "./imageExists";
import { isNullOrUndefined } from "./isNullOrUndefined";
import { truncateString } from "./truncateString";
import { validateJson } from "./validateJson";
import { videoExists } from "./videoExists";
import { youtubeVideoExists } from "./youtubeVideoExists";

type ValidateUrlArgs = {
    url: string | null | undefined;
    isRequired?: boolean;
    requiredMessage?: string;
    urlType?: UrlValidationType;
};

export const validateUrlField = ({ url, isRequired = false, requiredMessage, urlType = UrlValidationType.Absolute }: ValidateUrlArgs): string | null => {
    if (isNullOrUndefined(url)) {
        return isRequired ? (requiredMessage ?? "URL is required.") : null;
    }
    return validateUrl(url, urlType);
};

type ValidateUrlsArgs = {
    urls: string[];
    isRequired?: boolean;
    itemName?: string;
    requiredMessage?: string;
    urlType?: UrlValidationType;
};

export const validateUrls = ({ urls, isRequired = false, itemName, requiredMessage, urlType = UrlValidationType.Absolute }: ValidateUrlsArgs): string[] | string => {
    itemName = itemName || "Item";
    if (isRequired && urls.length === 0) {
        return requiredMessage ?? `At least one ${itemName} must be added.`;
    }
    const results = urls.map(url => validateUrl(url, urlType));
    return results.map((err, idx) => err === null ? null : `${itemName} #${idx + 1}: ${err}`).filter(err => err !== null) as string[];
};

type SourceType = "image" | "video" | "youTube";

const sourceNames: Record<SourceType, string> = {
    image: "Image",
    video: "Video",
    youTube: "Youtube video"
};

type ValidateSourceArgs = {
    url: string | undefined | null;
    type: SourceType
};

export const validateSource = async ({ url, type }: ValidateSourceArgs): Promise<string | null> => {
    if (isNullOrUndefined(url)) {
        return null;
    }
    let exists = false;
    if (type === "image") {
        exists = await imageExists(url);
    }
    if (type === "video") {
        exists = await videoExists(url);
    }
    if (type === "youTube") {
        exists = await youtubeVideoExists(url);
    }
    return exists ? null : `Can't find ${sourceNames[type]} "${truncateString(url, 50)}"`;
};

type ValidateSourcesArgs = {
    urls: string[];
    itemName?: string;
    type: SourceType;
};

export const validateSources = async ({ urls, itemName, type }: ValidateSourcesArgs): Promise<string[]> => {
    itemName = itemName || sourceNames[type];
    const results = await Promise.all(urls.map(url => validateSource({ url, type })));
    return results.map((err, idx) => err === null ? null : `${itemName} #${idx + 1}: ${err}`).filter(err => err !== null) as string[];
};

export const validateKeyBindings = (v: string | undefined, schemas: object[]) => {
    if (v === undefined) {
        return null;
    }
    try {
        const json = JSON.parse(v);
        return schemas.some(schema => validateJson(json, schema)) ? null : "Keybindings have wrong format";
    } catch (error) {
        return "Keybindings have wrong format";
    }
};

export const validateDownloaderVideoOptions = (val: DownloaderVideo | undefined): string | null => {
    if (isNullOrUndefined(val)) {
        return null;
    }
    if (val!.locationX < 0 || val!.locationY < 0) {
        return "Coordinates of Downloader Video cannot be less then 0";
    }
    if ((val!.width !== undefined && val!.width <= 0) || (val!.height !== undefined && val!.height <= 0)) {
        return "Dimension of Downloader Video cannot be equal or less then 0";
    }
    return isNullOrUndefined(val!.videoUrl) ? null : validateUrl(val!.videoUrl!, UrlValidationType.Absolute);
};

export const validateId = (val: string | undefined): string | null => {
    if (!val) {
        return "Id is required";
    }
    if (val.length < 2) {
        return "Id must be at least two characters long";
    }
    const idRegex = /^[a-zA-Z0-9]+(_[a-zA-Z0-9]+)*$/;
    if (!idRegex.test(val)) {
        return "Id can contain only latin alphabet characters and numbers, separated by underscore";
    }
    return null;
};