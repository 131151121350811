import styled from "styled-components";
import { previewVariables } from "../previewVariables";

export const SideNavContainer = styled.div`
    border: 2px solid ${previewVariables.sideNavBorderColor};
    border-radius: 10px;
    padding: 12px 16px 24px;
`;

export const CategoryList = styled.ul`
    color: ${previewVariables.contentTitleColor};
    list-style-type: none;
    font-size: 16px;
    font-weight: bold;
    padding-left: 0;
    text-transform: uppercase;
`;

export const SubCategoryList = styled.ul`
    color: ${previewVariables.contentFadeColor};
    list-style-type: none;
    font-size: 14px;
    font-weight: normal;
    text-transform: none;
    padding-left: 26px;
    margin: 10px 0;
    li {
        margin-bottom: .3rem;
    }
`;