import React, { CSSProperties } from "react";
import { Table } from "reactstrap";
import styled from "styled-components";

const Row = styled.tr`
    user-select: none;
    cursor: grab;
    flex: 1;
    min-height: 10px;
`;

export type ListWidgetTableColumn<T> = {
    label: string;
    renderer: (item: T) => number | string | JSX.Element | undefined;
    style?: CSSProperties;
    isSortedBy?: boolean | undefined;
};

interface ListWidgetTableProps<T> {
    data: T[];
    columns: ListWidgetTableColumn<T>[];
    rowKeySelector: (val: T) => string | number;
}

export function ListWidgetTable<T>(props: ListWidgetTableProps<T>) {
    const { data, columns, rowKeySelector } = props;
    return (
        <Table size="sm" striped borderless style={{ marginBottom: 0 }}>
            <thead>
                <Row>
                    {columns.map(col =>
                        <th key={col.label} style={col.style}>
                            {col.label} {col.isSortedBy && "↓"}
                        </th>)}
                </Row>
            </thead>
            <tbody>
                {data.map((item, rowIdx) =>
                    <Row key={`${rowKeySelector(item)}-${rowIdx}`}>
                        {columns.map((col, colIdx) =>
                            <td key={`${col.label}-${rowIdx}-${colIdx}`} style={col.style}>
                                {col.renderer(item)}
                            </td>)}
                    </Row>)}
            </tbody>
        </Table>
    );
}

