const pendingClones = new Map();
let nextKey = 0;

const channel = new MessageChannel();
const inPort = channel.port1;
const outPort = channel.port2;

outPort.onmessage = ({data: {key, value}}) => {
    const resolve = pendingClones.get(key);
    resolve(value);
    pendingClones.delete(key);
};
outPort.start();

/**
 * Provides cloning mechanism that will allow to create deep copy of the object.
 * Solution has been taken from https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/10916838#10916838
 * @param value The value to clone.
 * @returns The promise that will resolve the cloned value.
 */
export function structuredClone<T>(value: T): Promise<T> {
    return new Promise(resolve => {
      const key = nextKey++;
      pendingClones.set(key, resolve);
      inPort.postMessage({key, value});
    });
}