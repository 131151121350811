import React, { FC } from "react";
import styled from "styled-components";
import { CloseButton, CloseIcon, LeftCornerIcon } from "./components";
import { ThemedProps } from "../../types";

const PromptWindowContainer = styled.div`
    width: 450px;
    height: 150px;
    background-color: #150c1d;
    display: flex;
    flex-direction: column;
    font-size: 11px;
`;

const PromptHeader = styled.div`
    height: 25px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
`;

const PromptHeaderText = styled.span`
    color: #fff;
    margin-left: 8px;
    font-family: "Arial";
`;

const PromptBody = styled.div`
    flex: 1;
    padding: 25px 60px 15px;
    color: #fff;
    font-family: "Arial";
    text-align: justify;
`;

const PromptFooter = styled.div`
    height: 30px;
    min-height: 30px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
`;

const HelpButton = styled.button`
    color: ${(p: ThemedProps) => p.theme.HelpButtonTextColor};
    background: ${(p: ThemedProps) => p.theme.HelpButtonEnableColor};
    border: 2px solid ${(p: ThemedProps) => p.theme.HelpButtonEnableBorderColor};
    border-radius: 5px;
    font-weight: bold;
    font-family: "Arial";

    &:hover {
        background: ${(p: ThemedProps) => p.theme.HelpButtonMouseOverColor};
        border: 2px solid ${(p: ThemedProps) => p.theme.HelpButtonMouseOverBorderColor};
    }

    &:active {
        background: ${(p: ThemedProps) => p.theme.HelpButtonMouseClickColor};
        border: 2px solid ${(p: ThemedProps) => p.theme.HelpButtonMouseClickBorderColor};
    }

    &:focus {
        outline: none;
    }
`;

const PromptButtonsBlock = styled.div`
    display: flex;
    & > button:not(:first-child) {
        margin-left: 6px;
    }
`;

const PromptButton = styled.button`
    color: ${(p: ThemedProps) => p.theme.ContinueButtonTextColor};
    background: ${(p: ThemedProps) => p.theme.ContinueButtonEnableColor};
    width: 100px;
    border: 2px solid ${(p: ThemedProps) => p.theme.ContinueButtonEnableBorderColor};
    border-radius: 5px;
    font-weight: bold;
    font-family: "Arial";

    &:hover {
        background: ${(p: ThemedProps) => p.theme.ContinueButtonMouseOverColor};
        border-color: ${(p: ThemedProps) => p.theme.ContinueButtonMouseOverBorderColor};
    }

    &:active {
        background: ${(p: ThemedProps) => p.theme.ContinueButtonMouseClickColor};
        border-color: ${(p: ThemedProps) => p.theme.ContinueButtonMouseClickBorderColor};
    }

    &:focus {
        outline: none;
    }
`;

interface Props {
    text: string;
    yesBtnText: string;
    noBtnText: string;
    iconUlr: string;
    headerText: string | undefined;
}

export const PromptModal: FC<Props> = ({ text, yesBtnText, noBtnText, iconUlr, headerText }) => {
    return (
        <PromptWindowContainer>
            <PromptHeader>
                <LeftCornerIcon src={iconUlr} />
                <PromptHeaderText>
                    {headerText}
                </PromptHeaderText>
                <CloseButton className="ml-auto">
                    <CloseIcon />
                </CloseButton>
            </PromptHeader>
            <PromptBody>
                {text}
            </PromptBody>
            <PromptFooter>
                <HelpButton>?</HelpButton>
                <PromptButtonsBlock>
                    <PromptButton>
                        {yesBtnText}
                    </PromptButton>
                    <PromptButton>
                        {noBtnText}
                    </PromptButton>
                </PromptButtonsBlock>
            </PromptFooter>
        </PromptWindowContainer>
    );
};