import React, { useState, useEffect, useReducer, useMemo, createRef, useCallback } from "react";
import { defer, noop } from "rxjs";
import BaseTable, { AutoResizer, BaseTableProps, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { useFunctionState } from "src/shared/helpers";
import { customizeColumns } from "./customizeColumns";
import { Empty } from "./Empty";
import { customizeHeader } from "./customizeHeader";
import { Overlay } from "./Overlay";
import { SearchOptions } from ".";
import { TopPanel, TopPanelRendererProps } from "./TopPanel";
import styled from "styled-components";

const defaultMinimumLoadCount = 50;
const defaultOnEndReachedThreshold = 800;
const defaultHeaderHeight = 50;
const defaultTopPanelHeight = 50;
const defaultSearchHeaderHeight = 42;

const StyledBaseTable = styled(BaseTable)`
    [role=rowgroup], .BaseTable__header, .BaseTable__header-row {
        /* Overrides other !important style */
        height: auto !important;
        overflow: visible !important;
    }
    .BaseTable__empty-layer {
        top: auto !important;
        bottom: 50% !important;
    }
    .BaseTable__header-row {
        min-height: 42px;
    }
    .BaseTable__header-cell {
        min-height: 42px;
    }
    .BaseTable__body {
        overflow-x: hidden !important;
        overflow-y: auto !important;
    }
`;

export type LoadRequest = {
    reload: boolean,
    offset: number,
    limit: number,
    sort?: SortOptions,
    search?: Array<SearchOptions<unknown>>
};

export type LoadCallback = (request: LoadRequest) => Promise<boolean>;

export interface InfiniteTableProps<TItem, TRenderContext> extends Partial<BaseTableProps<TItem>> {
    /**
     * The items to display in the table.
     */
    items: TItem[];

    /**
     * The items loading operation callback that must return flag indicates is loading reached an end.
     */
    load: LoadCallback;

    /**
     * Custom property implemented to support contextual updates of the columns data retrieved from 'dataGetter'.
     * This certain value will be used to set property on every column, so when any changes will be made, column will
     * be updated.
     * @description
     * As explained in this discussion https://github.com/Autodesk/react-base-table/issues/111
     * we unable to force update of the 'dataGetter' property, without changing properties of a column.
     * While 'dataGetter' itself is cached, we need to change any other property to invalidate the cache.
     * NB: Task to provide another solution to this workaround was created:
     * https://github.com/Autodesk/react-base-table/issues/11 but there is no progress reported since 4/26/2019.
     */
    renderContext?: TRenderContext;

    /**
     * The search filters.
     */
    filters?: Array<SearchOptions<unknown>>;

    /**
     * The title that will be displayed in the top panel.
     */
    title?: string;

    /**
     * The top panel renderer.
     */
    topPanelRenderer?: React.FC<TopPanelRendererProps>;

    /**
     * The count of items that will be requested during load operation.
     * @default 50
     */
    minimumLoadCount?: number;

    /**
     * The height of the top panel.
     * @default 50
     */
    topPanelHeight?: number;

    /**
     * Show the top panel.
     */
    hideTopPanel?: boolean;

    /**
     * The height of the header with search components.
     * @default 42
     */
    searchHeaderHeight?: number;

    /**
     * The visible columns switch.
     */
    visibleColumns?: string[] | "all";

    /**
     * Callback invoked after closing options dropdown.
     */
    onOptionsClose?: (visibleColumns: string[]) => void;
}

/**
 * The table that supports infinite scrolling while data available.
 */
export function InfiniteTable<TItem, TRenderContext>(props: InfiniteTableProps<TItem, TRenderContext>) {
    const {
        items,
        children,
        title,
        topPanelRenderer,
        minimumLoadCount,
        load,
        renderContext,
        searchHeaderHeight,
        topPanelHeight,
        hideTopPanel,
        data,
        width,
        height,
        sortBy,
        onColumnSort,
        onEndReached,
        onEndReachedThreshold,
        headerRenderer,
        headerHeight,
        emptyRenderer,
        overlayRenderer,
        onOptionsClose,
        visibleColumns,
        ...restProps } = props;

    const tableRef = createRef<BaseTable<TItem>>();

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);

    const [sort, setSort] = useState(sortBy);
    const [loadRequested, setLoadRequested] = useState<true | false | "reload">(true);
    const [loading, setLoading] = useState(false);
    const [allLoaded, setAllLoaded] = useState(false);

    const [updateSearchDebounceTimer, setUpdateSearchDebounceTimer] = useState<number>();
    const searchReducer = ((
        current: Array<SearchOptions<unknown>>,
        state:
            { kind: "update-item", key: string, value: unknown } |
            { kind: "update-all", items: Array<SearchOptions<unknown>> }) => {

        if (state.kind === "update-all") {
            reload();
            return state.items;
        }

        let found = current.filter(o => o.key === state.key)[0];
        let changed = false;

        if (state.value === undefined && found) {
            current = current.filter(o => o !== found);
            changed = true;
        }
        if (state.value !== undefined) {
            const exist = !!found;
            if (!exist) {
                found = { key: state.key };
                current = [found, ...current];
            }
            if (!exist || state.value !== found.value) {
                found.value = state.value;
                changed = true;
            }
        }

        if (changed) {
            if (updateSearchDebounceTimer !== undefined) {
                if (loadRequested === false) {
                    clearTimeout(updateSearchDebounceTimer);
                    setUpdateSearchDebounceTimer(undefined);
                } else {
                    forceReload();
                }
            }

            const newTimer = setTimeout(() => {
                reload();
                setUpdateSearchDebounceTimer(undefined);
            }, 500);
            setUpdateSearchDebounceTimer(newTimer as unknown as number);
        }
        return current;
    });
    const [search, updateSearch] = useReducer(searchReducer, []);
    useEffect(() => {
        updateSearch({ kind: "update-all", items: props.filters ?? [] });
    }, [props.filters]);
    useEffect(() => {
        if (props.sortBy !== undefined) {
            setSort({ key: props.sortBy.key, order: props.sortBy.order });
            reload();
        }
    }, [props.sortBy]);

    useEffect(() => reload(), [renderContext]);
    useEffect(() => {
        if (loadRequested) {
            const isReload = loadRequested === "reload";
            loadMore(isReload);
        }
    }, [loadRequested]);

    useEffect(() => {
        // Try to load more items when parent resets items to empty.
        if (items.length === 0 && loadRequested === false && loading === false) {
            reload(true);
        }
    }, [items]);

    let allHeaderHeights = headerHeight === undefined
        ? [defaultHeaderHeight]
        :  typeof headerHeight === "number"
            ? [headerHeight]
            : headerHeight as number[];
    const searchHeaderIndex = allHeaderHeights.length;

    const updateSearchItem = useCallback(
        (key: string, value: unknown) => updateSearch({ kind: "update-item", key, value }),
        []);
    const customHeaderRenderer = useMemo(
        () => customizeHeader(searchHeaderIndex, search, updateSearchItem, headerRenderer),
        [allHeaderHeights, searchHeaderIndex, headerRenderer]);

    const resetSearch = useCallback((key: string) => updateSearch({ kind: "update-item", key, value: undefined }), []);
    const [customColumns, hasSearchColumns, columnOptions] =
        customizeColumns(children, searchHeaderIndex, resetSearch, visibleColumns, renderContext);
    if (hasSearchColumns) {
        allHeaderHeights = allHeaderHeights.concat(searchHeaderHeight || defaultSearchHeaderHeight);
    }

    function loadMore(doReload: boolean) {
        if (!doReload && allLoaded) {
            return;
        }

        setLoading(true);
        if (doReload) {
            setAllLoaded(false);
        }

        const offset = doReload ? 0 : items.length;
        const count = minimumLoadCount || defaultMinimumLoadCount;
        const subscription = defer(() => load({ reload: doReload, offset, limit: count, sort, search })).subscribe({
            next: (endReached) => {
                setLoading(false);
                setLoadRequested(false);
                if (endReached) {
                    setAllLoaded(true);
                }
            }
        });
        setUnsubscribe(() => subscription.unsubscribe());
    }

    function reload(doLoadMore = false) {
        if (!doLoadMore) {
            setAllLoaded(false);
        }

        const newState = doLoadMore === true ? true : "reload";
        setLoadRequested(newState);
    }
    function forceReload() {
        setLoadRequested(false);
        setTimeout(() => setLoadRequested("reload"), 0);
    }

    const handleColumnSort = useCallback(
        (p: { column: number, key: string, order: SortOrder }) => {
            setSort({ key: p.key, order: p.order });
            reload();

            if (onColumnSort) {
                onColumnSort(p);
            }
        }, [onColumnSort]);

    const handleEndReached = useCallback(
        (p: { distanceFromEnd: number }) => {
            setLoadRequested(true);

            if (onEndReached) {
                onEndReached(p);
            }
        }, [onEndReached]);

    const topHeight = topPanelHeight || defaultTopPanelHeight;
    return (
        <AutoResizer className={props.className}>
            {r => (
                <React.Fragment>
                    {hideTopPanel !== true &&
                        <TopPanel
                            width={width || r.width}
                            height={topHeight}
                            title={title}
                            columnOptions={columnOptions}
                            reload={reload}
                            onOptionsClose={onOptionsClose}
                            renderer={topPanelRenderer} />
                    }
                    <StyledBaseTable
                        innerRef={tableRef}
                        width={width || r.width}
                        height={(height || r.height) - topHeight}
                        data={items}
                        sortBy={sort}
                        onColumnSort={handleColumnSort}
                        onEndReached={handleEndReached}
                        onEndReachedThreshold={onEndReachedThreshold || defaultOnEndReachedThreshold}
                        headerHeight={allHeaderHeights}
                        headerRenderer={customHeaderRenderer}
                        emptyRenderer={<Empty loading={loading} baseEmptyRenderer={emptyRenderer} />}
                        overlayRenderer={<Overlay
                            loading={loading}
                            hasItems={items.length > 0}
                            baseOverlayRenderer={overlayRenderer} />}
                        {...restProps}>
                        {customColumns}
                    </StyledBaseTable>
                </React.Fragment>
            )}
        </AutoResizer>);
}
