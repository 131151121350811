import React, { FC } from "react";
import { DownloadInfoBlock, DownloadSpeedText, DownloadTimeText, ProgressBar, ProgressBarText, ProgressBarTitle, ProgressLine } from "./components";

export const ProgressFooter: FC = () => {
    return (
        <>
            <ProgressBarTitle>
                Progress bar title
            </ProgressBarTitle>
            <ProgressBar>
                <ProgressLine />
                <ProgressBarText>
                    22&nbsp;%
                </ProgressBarText>
            </ProgressBar>
            <DownloadInfoBlock>
                <DownloadSpeedText>
                    Downloading speed text
                </DownloadSpeedText>
                <DownloadTimeText>
                    Estimated Download Time Remaining:&nbsp;***
                </DownloadTimeText>
            </DownloadInfoBlock>
        </>
    );
};