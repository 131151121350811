import React, { forwardRef, useCallback, useImperativeHandle, useState, useEffect, useRef } from "react";
import { Form, Input, FormGroup, Label, Col, Row, Button } from "reactstrap";
import { Tag } from "src/shared/dtos";
import { setFromInput, useValidate, ValidateAsyncRef } from "src/shared/helpers";
import { EditField, FormLabel, Image, ImageSelector, LoadingButton } from "src/shared/components";
import { imagesValidationSchema, validationSchema } from "./validationSchema";
import { useConfirm } from "src/shared/helpers/useConfirm";
import { AliasesListEditor } from "src/shared/AliasesListEditor";

interface Props {
    value: Tag;
    initial?: Tag;
    tags: Tag[];
    update: React.Dispatch<Partial<Tag>>;
    saving: boolean;
    onSubmit: () => void;
    onDelete: () => void;
}

export interface EditFormRef {
    validate: () => boolean;
}

export const EditForm = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const { confirm } = useConfirm();
    const isNew = !props.initial?.id;

    const setId = (v: string) => props.update({ id: v ? v.trim().toLowerCase() : v });
    const setName = (v: string) => props.update({ name: v });
    const setShowInNavbar = (v: boolean) => props.update({ showInNavbar: v });
    const setIconUrl = (v: string | undefined) => props.update({ iconUrl: v });
    const setImportTagMapping = (v: string[]) => props.update({ importTagMapping: v.length > 0 ? v : undefined });

    const [tagMappingError, setTagMappingErrError] = useState<string | null>(null);
    const tagMappingFieldRef = useRef<HTMLDivElement>(null);

    const setIcon = useCallback(
        (url: string) => {
            setIconUrl((url.length !== 0) ? url : undefined);
            return "";
        }, []);

    const deleteIcon = useCallback((url: string) => {
        setIconUrl(url);
    }, []);

    const { validate: validateFields, errors } = useValidate({ schema: validationSchema, value: props.value });
    const { validate: validateImages, errors: imageWarnings } = useValidate({ schema: imagesValidationSchema, value: props.value });

    const addTagMapping = useCallback(
        (importName: string) => {
            if (!importName) {
                return;
            }

            importName = importName.toLowerCase();

            const tagMapping = (props.value.importTagMapping ?? []);
            if (tagMapping.includes(importName)) {
                return;
            }
            setImportTagMapping(tagMapping.concat(importName));
        }, [props.value.importTagMapping, setImportTagMapping]);

    const removeTagMapping = useCallback(
        (alias: string) => {
            if (!props.value.importTagMapping) {
                return;
            }
            const tagMapping = props.value.importTagMapping.filter(i => i !== alias);
            setImportTagMapping(tagMapping);
        }, [props.value.importTagMapping, setImportTagMapping]);

    const validateTagMapping = useCallback(() => {
        const currentMapping = props.value.importTagMapping;
        if (!currentMapping) {
            return null;
        }
        if (currentMapping.includes(props.value.id)) {
            return `Tag mapping '${props.value.id}' is already used as a current tag id`;
        }
        const otherTags = props.tags.filter(t => t.id !== props.value.id);
        const tagIds = new Map(otherTags.map(t => [t.id, t.id]));

        const conflictName = currentMapping.find(t => tagIds.has(t));
        if (conflictName) {
            return `Tag mapping '${conflictName}' is already used as another tag id`;
        }

        const tagMappings = new Map<string, string>();
        otherTags.forEach(t => {
            if (t.importTagMapping) {
                t.importTagMapping.forEach(v => tagMappings.set(v, t.id));
            }
        });

        for (const mappingName of currentMapping) {
            const conflictTag = tagMappings.get(mappingName);
            if (conflictTag) {
                return `Tag mapping '${mappingName}' is already used in tag '${conflictTag}'`;
            }
        }
        return null;
    }, [props.value.id, props.value.importTagMapping, props.tags]);

    useEffect(() => {
        setTagMappingErrError(validateTagMapping());
    }, [validateTagMapping]);

    const validate = useCallback(async () => {
        const validationRes = await validateFields(true);
        if (!validationRes.isValid) {
            return false;
        }
        if (tagMappingError !== null) {
            tagMappingFieldRef?.current?.scrollIntoView({ behavior: "smooth", block: "center" });
            return false;
        }
        return true;
    }, [validateFields, tagMappingError, tagMappingFieldRef?.current]);

    useImperativeHandle(ref, () => ({
        validate: async () => {
            if (!(await validate())) {
                return false;
            }
            if ((await validateImages()).isValid) {
                return true;
            }
            return confirm({
                body: "Can't load icon image. Do you want to save tag anyway?",
                title: "Confirm saving application"
            });
        }
    }), [validate, validateImages]);

    const renderImage = useCallback((url: string): Image | null => url === "" ? null : { url }, []);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    const onDelete = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (await confirm({ body: "Are you sure you want to delete this tag?", title: "Delete Tag" })) {
            props.onDelete();
        }
    };

    return (
        <Form className="mt-3" onSubmit={onSubmit} noValidate>
            <Row>
                <EditField
                    fullWidth
                    label="Tag ID"
                    fieldId="tagId"
                    isChanged={props.value.id !== props.initial?.id}
                    originalValue={isNew ? undefined : props.initial?.id}
                    errors={errors.id}
                >
                    <Input
                        type="text"
                        id="tagId"
                        value={props.value.id ?? ""}
                        onChange={setFromInput(setId)}
                        disabled={props.saving || !isNew}
                    />
                </EditField>
                <EditField
                    fullWidth
                    label="Name"
                    fieldId="name"
                    isChanged={props.value.name !== props.initial?.name}
                    originalValue={isNew ? undefined : props.initial?.name}
                    errors={errors.name}
                >
                    <Input
                        type="text"
                        id="name"
                        value={props.value.name ?? ""}
                        onChange={setFromInput(setName)}
                        disabled={props.saving}
                    />
                </EditField>
                <EditField
                    fullWidth
                    label="Icon URL"
                    fieldId="iconUrl"
                    isChanged={props.value.iconUrl !== props.initial?.iconUrl}
                    originalValue={isNew ? undefined : props.initial?.iconUrl}
                    errors={errors?.iconUrl ?? imageWarnings?.iconUrl}
                >
                    <ImageSelector
                        setInputId="iconUrl"
                        value={props.value.iconUrl ?? ""}
                        set={setIcon}
                        render={renderImage}
                        delete={deleteIcon}
                        imageDimensions={{ width: "12rem" }}
                        disabled={props.saving} />
                </EditField>
            </Row>
            <Row>
                <EditField>
                    <FormGroup check>
                        <Label for="showInNavbar" check>
                            <Input
                                type="checkbox"
                                id="showInNavbar"
                                name="showInNavbar"
                                checked={props.value.showInNavbar}
                                onChange={setFromInput(setShowInNavbar)}
                                disabled={props.saving}
                            />{" "}
                            <FormLabel
                                isChanged={props.value.showInNavbar !== props.initial?.showInNavbar}
                                originalValue={isNew ? undefined : props.initial?.showInNavbar}>
                                Show In Navbar
                            </FormLabel>
                        </Label>
                    </FormGroup>
                </EditField>
            </Row>
            <Row>
                <EditField
                    errors={tagMappingError ? [tagMappingError] : undefined}
                    label="Tag Mapping for Import"
                    fieldId="importTagMapping"
                    fullWidth={true}
                    isChanged={props.value.importTagMapping?.join() !== props.initial?.importTagMapping?.join()}
                >
                    <AliasesListEditor
                        mainInputId="importTagMapping"
                        items={props.value.importTagMapping ?? []}
                        add={addTagMapping}
                        remove={removeTagMapping}
                        invalid={!!tagMappingError}
                        disabled={props.saving}
                        placeholder={"New tag mapping"}
                    />
                </EditField>
            </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <LoadingButton
                        loading={props.saving}
                        loadingText={"Saving..."}
                        position="end"
                        type="submit"
                        color="primary">
                        {isNew ? "Add" : "Save"} Tag
                    </LoadingButton>
                    <Button
                        type="button"
                        className="ml-2"
                        color="danger"
                        disabled={props.saving}
                        onClick={onDelete}
                    >
                        Delete Tag
                    </Button>
                </Col>
            </Row>
        </Form>
    );
});