import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { previewVariables } from "../previewVariables";
import { SideNav } from "../SideNav";

export const ApplicationDetailsContainer = styled.div`
    position: relative;
    width: calc(${previewVariables.gameCardWidth}px * 6 + ${previewVariables.cardGap}px * 5);
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-column-gap: ${previewVariables.cardGap}px;
    margin: 0 auto;
    display: flex;
    padding: 16px 0 24px;
`;

export const StyledSideNav = styled(SideNav)`
    margin: ${previewVariables.cardGap}px 30px 0 -70px;
`;

export const MainContainer = styled.div`
    height: calc((${previewVariables.gameCardHeight}px + ${previewVariables.cardGap}px) * 5);
    color: ${previewVariables.contentMainColor};
`;

export const GameScreen = styled.div`
    height: calc(${previewVariables.gameCardHeight}px * 3 + ${previewVariables.cardGap}px * 2);
    background-image: url("https://images.crazygames.com/bubble-shooter-pop/20221212114549/bubble-shooter-pop-cover");
    border-radius: 10px;
    margin-bottom: 14px;
`;

export const GameScreenOverlay = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(14px);
    background-color: rgba(0, 0, 0, 0.4);
`;

export const GameScreenButton = styled.div`
    color: ${previewVariables.gameScreenButtonTextColor};
    font-size: 16px;
    text-transform: uppercase;
    background-color: ${previewVariables.gameScreenButtonBgColor};
    display: flex;
    align-items: center;
    padding: 6px 26px;
    border-radius: 35px;
    cursor: pointer;
    text-shadow: 0 2px 1px #000;
    user-select: none;

    &:hover {
        background-color: ${previewVariables.gameScreenButtonHoverBgColor};
        color: ${previewVariables.gameScreenButtonHoverTextColor};
    }
`;

export const PlayIconContainer = styled.div`
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid ${previewVariables.gameScreenButtonTextColor};
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 0 #000, inset 0 1px 0 #000;
`;

export const PlayIcon = styled(FontAwesomeIcon).attrs({ icon: "play" })`
    vertical-align: middle;
`;

export const GameTitle = styled.h4`
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 10px;
`;

export const TagsContainer = styled.div`
    display: flex;

    & > span:not(:first-child) {
        margin-left: 10px;
    }
    margin-bottom: 10px;
`;

export const Tag = styled.span`
    font-size: 10px;
    padding: 1px 25px;
    border: 2px solid ${previewVariables.contentMainColor};
    border-radius: 16px;
    text-transform: uppercase;
`;

export const InfoTable = styled.table`
    font-size: 12px;
    color: ${previewVariables.contentFadeColor};
    margin-bottom: 10px;
`;

export const TitleColumn = styled.td`
    color: ${previewVariables.contentTitleColor};
    padding-right: 4px;
`;

export const Description = styled.div`
    font-size: 12px;
    width: 95%;
`;

export const DescriptionLine = styled.hr`
    background-color: ${previewVariables.contentFadeColor};
    margin-bottom: 10px;
`;

export const ReadMore = styled.h4`
    font-size: 14px;
    color: ${previewVariables.contentTitleColor};
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
`;