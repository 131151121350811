import { client } from "src/shared/client";
import {
    GetClickInfoStatistics,
    GetClickInfoStatisticsAvailableDates,
    GetClickInfoStatisticsById,
    GetClickInfoStatisticsForDate,
    GetClickInfoStatisticsForPeriod,
    GetClickInfoStatisticsAppVersionsByDate,
    GetClickInfoStatisticsAvailableTargets
} from "src/shared/dtos";
import { ExcludeMethods } from "src/shared/helpers";
import { serializeDate } from "src/shared/helpers/dateSerialization";

export interface ListRequest extends Omit<ExcludeMethods<GetClickInfoStatisticsForDate>, "date"> {
    date: Date;
}

export interface DetailsRequest extends Omit<ExcludeMethods<GetClickInfoStatisticsForPeriod>, "startDate" | "endDate"> {
    startDate: Date;
    endDate: Date;
}

export interface GetAvailableClickTargetsRequest extends
    Omit<ExcludeMethods<GetClickInfoStatisticsAvailableTargets>, "startDate" | "endDate"> {
    startDate: Date;
    endDate: Date;
}

export type DetailsFilter = Exclude<keyof DetailsRequest, "startDate" | "endDate">;

export const ClickInfoStatisticsApi = {
    getById: (id: number) => client.get(new GetClickInfoStatisticsById({ id })),
    list: (request: ListRequest) => client.get(new GetClickInfoStatisticsForDate({
        ...request,
        date: serializeDate(request.date),
    })),
    all: () => client.get(new GetClickInfoStatistics()),
    getDates: () => client.get(new GetClickInfoStatisticsAvailableDates()),
    details: (request: DetailsRequest) => client.get(new GetClickInfoStatisticsForPeriod({
        ...request,
        startDate: serializeDate(request.startDate),
        endDate: serializeDate(request.endDate)
    })),
    getVersionsByDate: (date: Date) => client.get(
        new GetClickInfoStatisticsAppVersionsByDate({ date: serializeDate(date) })),
    getAvailableClickTargets: (request: GetAvailableClickTargetsRequest) => client.get(
        new GetClickInfoStatisticsAvailableTargets({
            ...request,
            startDate: serializeDate(request.startDate),
            endDate: serializeDate(request.endDate)
        })),
};

export default ClickInfoStatisticsApi;