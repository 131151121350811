import React, { useState, useEffect } from "react";
import { useForm, ErrorMessage } from "react-hook-form";
import { Form, Input, Button, FormFeedback, Row, Col } from "reactstrap";
import { noop, defer } from "rxjs";
import { setFromInput, useFunctionState, formValidation } from "src/shared/helpers";
import { HorizontalField } from "src/shared/components";

interface Props {
    isOwnPassword: boolean;
    saving: boolean;
    onSubmit: (newPassword: string, oldPassword?: string) => Promise<boolean>;
}

export const minPasswordLength = 4;

export type EditFormValues = {
    oldPassword?: string,
    password: string,
    confirmPassword: string
};
export const ChangePasswordForm = (props: Props) => {
    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);

    const { register, handleSubmit, errors, reset, watch } = useForm<EditFormValues>({ reValidateMode: "onChange" });
    const changes = watch();

    useEffect(() => unsubscribe, [unsubscribe]);
    const save = (form: EditFormValues) => {
        const subscription = defer(() => props.onSubmit(form.password, form.oldPassword))
            .subscribe(savedSuccessfully => {
                if (savedSuccessfully) {
                    reset();
                }
            });
        setUnsubscribe(() => subscription.unsubscribe());
    };

    return (
        <Form onSubmit={handleSubmit(save)}>
            <Row>
                <Col xl={{ offset: 2, size: 8 }}>
                    <HorizontalField>
                        <h2>
                            Change password
                </h2>
                    </HorizontalField>
                    {props.isOwnPassword &&
                        <HorizontalField
                            label="Old password"
                            fieldId="oldPassword">
                            <Input
                                type="password"
                                id="oldPassword"
                                name="oldPassword"
                                disabled={props.saving}
                                invalid={errors.oldPassword !== undefined}
                                innerRef={register(formValidation({ required: true, minLength: minPasswordLength }))} />
                            <ErrorMessage
                                as={FormFeedback}
                                errors={errors}
                                name="oldPassword" />
                        </HorizontalField>}
                    <HorizontalField
                        label="Password"
                        fieldId="password">
                        <Input
                            type="password"
                            id="password"
                            name="password"
                            disabled={props.saving}
                            invalid={errors.password !== undefined}
                            innerRef={register(formValidation({ required: true, minLength: minPasswordLength }))} />
                        <ErrorMessage
                            as={FormFeedback}
                            errors={errors}
                            name="password" />
                    </HorizontalField>
                    <HorizontalField
                        label="Confirm"
                        fieldId="confirmPassword">
                        <Input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            disabled={props.saving}
                            invalid={errors.confirmPassword !== undefined}
                            innerRef={register(formValidation({
                                required: true,
                                validate: {
                                    match: v => v === changes.password ? true : "Passwords must match"
                                }
                            }))} />
                        <ErrorMessage
                            as={FormFeedback}
                            errors={errors}
                            name="confirmPassword" />
                    </HorizontalField>
                    <HorizontalField>
                        <Button
                            type="submit"
                            color="primary"
                            className="float-right"
                            disabled={props.saving}>
                            Save password
                </Button>
                    </HorizontalField>
                </Col>
            </Row>
        </Form>
    );
};