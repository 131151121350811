import { client } from "src/shared/client";
import {
    GetPlaystoreActivityStatistics,
    GetPlaystoreActivityStatisticsAvailableDates,
    GetPlaystoreActivityStatisticsById,
    GetPlaystoreActivityStatisticsByDate,
    GetPlaystoreActivityStatisticsByPeriod,
    GetPlaystoreActivityAppVersionsByDate,
    GetPlaystoreActivityStatisticsAvailablePackageNames,
} from "src/shared/dtos";
import { ExcludeMethods } from "src/shared/helpers";
import { serializeDate } from "src/shared/helpers/dateSerialization";

export interface ListRequest extends Omit<ExcludeMethods<GetPlaystoreActivityStatisticsByDate>, "date"> {
    date: Date;
}

export interface DetailsRequest extends Omit<ExcludeMethods<GetPlaystoreActivityStatisticsByPeriod>, "startDate" | "endDate"> {
    startDate: Date;
    endDate: Date;
}

export interface GetAvailableApkPackageNamesRequest extends
    Omit<ExcludeMethods<GetPlaystoreActivityStatisticsAvailablePackageNames>, "startDate" | "endDate"> {
    startDate: Date;
    endDate: Date;
}

export type DetailsFilter = Exclude<keyof DetailsRequest, "startDate" | "endDate">;

export const PlaystoreActivityStatisticsApi = {
    getById: (id: number) => client.get(new GetPlaystoreActivityStatisticsById({ id })),
    list: async (request: ListRequest) => client.get(new GetPlaystoreActivityStatisticsByDate({
        ...request,
        date: serializeDate(request.date),
    })),
    all: () => client.get(new GetPlaystoreActivityStatistics()),
    getDates: () => client.get(new GetPlaystoreActivityStatisticsAvailableDates()),
    details: (request: DetailsRequest) => client.get(new GetPlaystoreActivityStatisticsByPeriod({
        ...request,
        startDate: serializeDate(request.startDate),
        endDate: serializeDate(request.endDate)
    })),
    getVersionsByDate: (date: Date) => client.get(new GetPlaystoreActivityAppVersionsByDate({ date: serializeDate(date) })),
    getAvailableApkPackageNames: (request: GetAvailableApkPackageNamesRequest) => client.get(
        new GetPlaystoreActivityStatisticsAvailablePackageNames({
            ...request,
            startDate: serializeDate(request.startDate),
            endDate: serializeDate(request.endDate)
        })),
};

export default PlaystoreActivityStatisticsApi;