import React from "react";
import { Col } from "reactstrap";
import styled from "styled-components";

const Separator = styled.hr`
    height: 1;
    color: black;
    background-color: black;
    border-color: black;
`;

export const Heading = (props: { label: string }) =>
    <React.Fragment>
        <Col sm={12}>
            <h3>{props.label}</h3>
        </Col>
        <Col sm={12}>
            <Separator />
        </Col>
    </React.Fragment>;