import React, { useCallback, useMemo, forwardRef, useRef } from "react";
import { MultiValueEditor, StyledTooltip } from "src/shared/components";
import { PropertyEditorProps } from "../PropertyEditorProps";

export const MultiValuePropertyEditor = forwardRef(
    (props: PropertyEditorProps<string[] | undefined>, ref: React.MutableRefObject<HTMLDivElement>) => {
        const tooltipRef = useRef<HTMLDivElement>(null);
        const itemValues = useMemo(() => props.value ?? [], [props.value]);

        const addValue = useCallback(
            (item: string) => {
                const value = item.trim();
                if (!value) {
                    return;
                }
                if (itemValues.includes(value)) {
                    return;
                }
                props.onChange(itemValues.concat(value));
            }, [itemValues, props.onChange]);

        const removeValue = useCallback(
            (item: string) => {
                const values = itemValues.filter(i => i !== item);
                props.onChange(values.length === 0 ? undefined : values);
            }, [itemValues, props.onChange]);

        const listContainerId = `${props.id}_list`;

        return (
            <div ref={ref}>
                <MultiValueEditor
                    listContainerId={listContainerId}
                    mainInputId={props.id}
                    items={itemValues}
                    add={addValue}
                    remove={removeValue}
                    disabled={props.disabled}
                    invalid={props.errors && props.errors.length > 0}
                />
                <div ref={tooltipRef} />
                <StyledTooltip placement="bottom" isOpen={props.errors && props.errors.length > 0} target={tooltipRef}>
                    {props.errors?.map((err, i) => <div key={`${err}_${i}`}>{err}</div>)}
                </StyledTooltip>
            </div>
        );
    });
