import React, { useCallback, forwardRef, useImperativeHandle } from "react";
import { Input, FormGroup, Label } from "reactstrap";
import { AdDynamicWebUnit } from "src/shared/dtos";
import { highlightTargetUrl, StyledCodeEditor, TargetUrlPlaceholdersTip } from "./EditForm";
import { setFromInput, useValidate, ValidateAsyncRef } from "src/shared/helpers";
import { FormLabel, EditField } from "src/shared/components";
import { dynamicWebValidationSchema } from "./validationSchema";

interface Props {
    value: AdDynamicWebUnit;
    initial: AdDynamicWebUnit;
    update: React.Dispatch<Partial<AdDynamicWebUnit> | undefined>;
    saving: boolean;
}

export const EditDynamicWebUnit = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const { value, initial, update, saving } = props;

    const setUrl = useCallback((url: string) => update({ url }), [update]);
    const setHeader = useCallback((v: string) => update({ header: v }), [update]);
    const setWebSizeX = useCallback((sizeX: number) => update({ sizeX }), [update]);
    const setWebSizeY = useCallback((sizeY: number) => update({ sizeY }), [update]);
    const setLaunchInDesktop = useCallback((v: boolean) => update({ launchInDesktopBrowser: v }), [update]);

    const { validate, errors, register } = useValidate({ schema: dynamicWebValidationSchema, value: props.value });

    useImperativeHandle(ref, () => ({
        validate: async () => (await validate(true)).isValid
    }), [validate]);

    return (
        <React.Fragment>
            <EditField
                ref={register("header")}
                label="Header"
                fieldId="dynamicWebHeader"
                isChanged={value.header !== initial.header}
                errors={errors?.header}
            >
                <Input
                    type="text"
                    id="dynamicWebHeader"
                    value={value.header ?? ""}
                    onChange={setFromInput(setHeader)}
                    disabled={saving}
                />
            </EditField>
            <EditField
                ref={register("url")}
                label="URL"
                fieldId="dynamicWebUrl"
                isChanged={value.url !== initial.url}
                errors={errors?.url}
            >
                <StyledCodeEditor
                    id="dynamicWebUrl"
                    value={value.url ?? ""}
                    onValueChange={setUrl}
                    highlight={highlightTargetUrl}
                    padding={10}
                    tabSize={4}
                    insertSpaces={true}
                    disabled={saving}
                />
                <TargetUrlPlaceholdersTip />
            </EditField>
            <EditField
                label="Size X"
                fieldId="dynamicWebUrlSizeX"
                isChanged={value.sizeX !== initial.sizeX}
            >
                <Input
                    type="number"
                    id="dynamicWebUrlSizeX"
                    onChange={setFromInput(setWebSizeX)}
                    value={value.sizeX ?? ""}
                />
            </EditField>
            <EditField
                label="Size Y"
                fieldId="dynamicWebUrlSizeY"
                isChanged={value.sizeY !== initial.sizeY}
            >
                <Input
                    type="number"
                    id="dynamicWebUrlSizeY"
                    onChange={setFromInput(setWebSizeY)}
                    value={value.sizeY ?? ""}
                />
            </EditField>
            <EditField>
                <FormGroup check>
                    <Label for="launchInDesktopBrowser" check style={{cursor: "pointer"}}>
                        <Input
                            type="checkbox"
                            id="launchInDesktopBrowser"
                            name="launchInDesktopBrowser"
                            checked={value.launchInDesktopBrowser}
                            onChange={setFromInput(setLaunchInDesktop)}
                            disabled={saving}
                        />{" "}
                        <FormLabel
                            isChanged={value.launchInDesktopBrowser !== initial.launchInDesktopBrowser}>
                            Launch in Desktop Browser
                        </FormLabel>
                    </Label>
                </FormGroup>
            </EditField>
        </React.Fragment>
    );
});