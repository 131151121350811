import React, { useMemo, useCallback, useEffect, useState, forwardRef, useImperativeHandle, useRef, ChangeEvent } from "react";
import CodeEditor from "react-simple-code-editor";
import { Form, Input, Button, FormGroup, Label, Row, Col, Alert } from "reactstrap";
import {
    AdUnit,
    AdUnitTypes,
    AdBannerUnit,
    AdBrowserUnit,
    AdRichMediaOverlayUnit,
    AdPixelType,
    AdOrientationType,
    AdBannerSize,
    AdAnimationType,
    AdDynamicWebUnit,
    AdSideUnit,
    Category,
    AdFilterType
} from "src/shared/dtos";
import { highlight } from "prismjs";
import "prismjs/components/prism-json";
import { SelectOption, setFromInput, useSubscription, useValidate, ValidateAsyncRef } from "src/shared/helpers";
import { FormLabel, Select, SelectAsync, Slide, Heading, EditField } from "src/shared/components";
import { AdUnitTriggerListView } from "./AdUnitTriggerListView";
import { IUser } from "src/shared/client";
import { defer } from "rxjs";
import api from "../api";
import styled from "styled-components";
import { EditBannerUnit } from "./EditBannerUnit";
import { EditRichMediaOverlayUnit } from "./EditRichMediaOverlayUnit";
import { EditBrowserUnit } from "./EditBrowserUnit";
import { EditDynamicWebUnit } from "./EditDynamicWebUnit";
import { makeEmptyAdBannerUnit, makeEmptyAdBrowserUnit, makeEmptyAdDynamicWebUnit, makeEmptyAdRichMediaOverlayUnit, makeEmptySideAdUnit } from "./adUnitReducer";
import { EditSideAdUnit } from "./EditSideAdUnit";
import { CategorySelectOption, mapCategorySelectOption } from "src/shared/helpers/mapCategorySelectOption";
import { AdUnitFormValues, adUnitValidationSchema } from "./validationSchema";

export type TypeSelectOption = SelectOption<AdUnitTypes | undefined>;
export type FilterTypeSelectOption = SelectOption<AdFilterType | undefined>;
export type PixelTypeSelectOption = SelectOption<AdPixelType | undefined>;
export type OrientationTypeSelectOption = SelectOption<AdOrientationType | undefined>;
export type IdOption = SelectOption<string | undefined>;
export type AnimationOption = SelectOption<AdAnimationType | undefined>;

export const LinkButton = styled.span`
    cursor: pointer;
    font-size: 1.15rem;
    text-align: center;
    vertical-align: center;
    text-decoration: underline;
    font-weight: bold;

    &:hover, &:focus {
        opacity: 0.9;
    }
`;

export const StyledCodeEditor = styled(CodeEditor)`
    textarea {
        outline: none !important;
        border: none !important;
    }

    pre {
        border: 1px solid #ced4da !important;
        border-radius: 0.25rem !important;
        display: block;
    }
`;

const mapOptions = (applicationId: string): IdOption => ({ value: applicationId, label: applicationId });

export const mapTypeSelectOption = (unitType: AdUnitTypes) => {
    const value = unitType;
    let label = "";
    switch (unitType) {
        case AdUnitTypes.BannerAction:
            label = "Banner";
            break;
        case AdUnitTypes.BrowserAction:
            label = "Browser";
            break;
        case AdUnitTypes.RichMediaOverlay:
            label = "Rich Media Overlay";
            break;
        case AdUnitTypes.DynamicWeb:
            label = "Dynamic Web";
            break;
        case AdUnitTypes.SideAd:
            label = "Side Ad";
            break;
        default:
            label = "Not found";
            break;
    }
    return { value, label } as TypeSelectOption;
};

export const TargetUrlPlaceholdersTip = () =>
    <small className="mt-1">
        Supported placeholders (case-insensitive): <code>{"{appId}"}</code> <code>{"{hwid}"}</code>
    </small>;

const filterTypeOptions = Object.values(AdFilterType).map(v => ({ value: v, label: v.toString() }) as FilterTypeSelectOption);

interface Props {
    value: AdUnit;
    initial?: AdUnit;
    isNewAdUnit: boolean;
    update: React.Dispatch<Partial<AdUnit> | undefined>;
    setType: (type: AdUnitTypes) => void;
    saving: boolean;
    onSubmit: () => void;
    user: IUser | null;
}

export const highlightTargetUrl = (code: string) => highlight(code, { keyword: /(\{appId\})|(\{hwid\})/gi }, "target-url");
export const renderImage = (url: string): Slide | null => url === "" ? null : { type: "image", url };

export const EditForm = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const bannerUnitRef = useRef<ValidateAsyncRef | null>(null);
    const browserUnitRef = useRef<ValidateAsyncRef | null>(null);
    const dynamicWebUnitRef = useRef<ValidateAsyncRef | null>(null);
    const richMediaOverlayUnitRef = useRef<ValidateAsyncRef | null>(null);
    const sideAdUnitRef = useRef<ValidateAsyncRef | null>(null);
    const [categories, setCategories] = useState<Category[]>([]);

    const updateCategories = useSubscription(() => defer(
        () => api.getCategories()).subscribe(setCategories), [api.getCategories]);

    useEffect(() => {
        updateCategories();
    }, []);

    const adType = props.value.unitType;

    const initialBannerUnit = useMemo(
        () => props.initial?.adBannerUnit ?? makeEmptyAdBannerUnit(props.value.id),
        [props.initial?.adBannerUnit]);

    const initialBrowserUnit = useMemo(
        () => props.initial?.adBrowserUnit ?? makeEmptyAdBrowserUnit(props.value.id),
        [props.initial?.adBrowserUnit]);

    const initialDynamicWebUnit = useMemo(
        () => props.initial?.adDynamicWebUnit ?? makeEmptyAdDynamicWebUnit(props.value.id),
        [props.initial?.adDynamicWebUnit]);

    const initialRichMediaOverlayUnit = useMemo(
        () => props.initial?.adRichMediaOverlayUnit ?? makeEmptyAdRichMediaOverlayUnit(props.value.id),
        [props.initial?.adRichMediaOverlayUnit]);

    const initialSideAdUnit = useMemo(
        () => props.initial?.adSideUnit ?? makeEmptySideAdUnit(props.value.id),
        [props.initial?.adSideUnit]);

    const [adBannerSizes, setAdBannerSizes] = useState<AdBannerSize[]>();
    const [allApplicationsSelected, setAllApplicationsSelected] = useState<boolean>(true);
    const [allCategoriesSelected, setAllCategoriesSelected] = useState<boolean>(true);

    const fomValues: AdUnitFormValues = useMemo(() => ({
        allApplicationsSelected,
        allCategoriesSelected,
        applicationIds: props.value.applicationIds ?? [],
        categoryIds: props.value.categoryIds ?? []
    }), [props.value, allApplicationsSelected, allCategoriesSelected]);

    const { validate, errors, register } = useValidate({ schema: adUnitValidationSchema, value: fomValues });

    const validateFields = useCallback(async (): Promise<boolean> => {
        const isMainFormValid = (await validate(true)).isValid;
        if (!isMainFormValid) {
            return false;
        }
        switch (adType) {
            case AdUnitTypes.BannerAction:
                return await bannerUnitRef.current?.validate() ?? false;
            case AdUnitTypes.RichMediaOverlay:
                return await richMediaOverlayUnitRef.current?.validate() ?? false;
            case AdUnitTypes.BrowserAction:
                return await browserUnitRef.current?.validate() ?? false;
            case AdUnitTypes.DynamicWeb:
                return await dynamicWebUnitRef.current?.validate() ?? false;
            case AdUnitTypes.SideAd:
                return await sideAdUnitRef.current?.validate() ?? false;
        }
    }, [adType, bannerUnitRef.current, richMediaOverlayUnitRef.current, browserUnitRef.current, dynamicWebUnitRef.current, validate]);

    useImperativeHandle(ref, () => ({
        validate: async () => await validateFields()
    }), [validateFields]);

    const loadBannerSizes = useSubscription(() => defer(() => api.getAdBannerSizes())
        .subscribe(setAdBannerSizes), []);

    useEffect(() => {
        loadBannerSizes();
    }, []);

    const updateAdBannerUnit = useCallback(
        (data: Partial<AdBannerUnit>) => {
            const current = props.value.adBannerUnit ?? initialBannerUnit;
            const updated = Object.assign(current, data);
            props.update({ adBannerUnit: updated });
        }, [props.value.adBannerUnit, initialBannerUnit, props.update]);

    const updateAdBrowserUnit = useCallback(
        (data: Partial<AdBrowserUnit>) => {
            const current = props.value.adBrowserUnit ?? initialBrowserUnit;
            const updated = Object.assign(current, data);
            props.update({ adBrowserUnit: updated });
        }, [props.value.adBrowserUnit, initialBrowserUnit, props.update]);

    const updateAdRichMediaOverlayUnit = useCallback(
        (data: Partial<AdRichMediaOverlayUnit>) => {
            const current = props.value.adRichMediaOverlayUnit ?? initialRichMediaOverlayUnit;
            const updated = Object.assign(current, data);
            props.update({ adRichMediaOverlayUnit: updated });
        }, [props.value.adRichMediaOverlayUnit, initialRichMediaOverlayUnit, props.update]);

    const updateAdDynamicWebUnit = useCallback(
        (data: Partial<AdDynamicWebUnit>) => {
            const current = props.value.adDynamicWebUnit ?? initialDynamicWebUnit;
            const updated = Object.assign(current, data);
            props.update({ adDynamicWebUnit: updated });
        }, [props.value.adDynamicWebUnit, initialDynamicWebUnit, props.update]);

    const updateSideAdUnit = useCallback(
        (data: Partial<AdSideUnit>) => {
            const current = props.value.adSideUnit ?? initialSideAdUnit;
            const updated = Object.assign(current, data);
            props.update({ adSideUnit: updated });
        }, [props.value.adSideUnit, initialSideAdUnit, props.update]);

    const setSelectedApplicationIds = (v: IdOption[]) => props.update({ applicationIds: v === null ? [] : v.map(c => c.value!) });

    const selectedApplicationsOptions = useMemo(
        () => props.value.applicationIds !== undefined ? props.value.applicationIds.map(mapOptions) : [],
        [props.value.applicationIds]);

    const changeAllApplicationsSelected = (v: boolean) => {
        setAllApplicationsSelected(v);
        if (v) {
            setSelectedApplicationIds([]);
        }
    };

    const changeAllCategoriesSelected = (v: boolean) => {
        setAllCategoriesSelected(v);
        if (v) {
            setSelectedCategoryIds([]);
        }
    };

    useEffect(() => {
        if (props.value.applicationIds && props.value.applicationIds?.length > 0) {
            setAllApplicationsSelected(false);
        }
    }, [props.value.applicationIds]);

    useEffect(() => {
        if (props.value.categoryIds && props.value.categoryIds?.length > 0) {
            setAllCategoriesSelected(false);
        }
    }, [props.value.categoryIds]);

    const isApplicationIdsChanged = useMemo(
        () => (props.value?.applicationIds ?? []).join() !== (props.initial?.applicationIds ?? []).join(),
        [props.value?.applicationIds, props.initial?.applicationIds]);

    const loadAppIdOptions = async (idSearch: string) => (await api.getAvailableApplicationIds(idSearch)).map(mapOptions);

    const allCategoryOptions = useMemo(
        () => categories
            .map(c => mapCategorySelectOption<CategorySelectOption>(c, categories)),
        [categories]);

    const filteredCategories = useMemo(
        () => props.value.categoryIds === undefined
            ? categories
            : categories.filter(c => c.parentId === undefined || !props.value.categoryIds!.includes(c.parentId)),
        [props.value.categoryIds, categories]);

    const filteredCategoryOptions = useMemo(
        () => filteredCategories
            .map(c => mapCategorySelectOption<CategorySelectOption>(c, categories)),
        [filteredCategories, categories]);

    const selectedCategoryOptions = useMemo(
        () => props.value.categoryIds === undefined ? []
            : allCategoryOptions
                .filter(o => props.value.categoryIds!.some(id => o.value === id))
                .sort((a, b) => props.value.categoryIds!.indexOf(a.value!) - props.value.categoryIds!.indexOf(b.value!)),
        [props.value.categoryIds, allCategoryOptions]);

    const setSelectedCategoryIds = useCallback((options: IdOption[]) => {
        if (options === null) {
            props.update({ categoryIds: [] });
            return;
        }
        let selectedCategories = categories.filter(c => options.some(opt => c.id === opt.value));
        const selectedRootCategories = selectedCategories.filter(c => c.parentId === undefined);
        selectedCategories = selectedCategories.filter(c => !selectedRootCategories.some(r => r.id === c.parentId));
        const selectedCategoryIds = selectedCategories
            .map(c => c.id)
            .sort((a, b) => options.findIndex(o => o.value === a) - options.findIndex(o => o.value === b));
        props.update({ categoryIds: selectedCategoryIds });
    }, [categories]);

    const isCategoryIdsChanged = useMemo(
        () => (props.value?.categoryIds ?? []).join() !== (props.initial?.categoryIds ?? []).join(),
        [props.value?.categoryIds, props.initial?.categoryIds]);

    const setType = (v: TypeSelectOption) => {
        if (v.value) {
            props.setType(v.value);
        }
    };

    const setFilterType = (v: FilterTypeSelectOption) => {
        if (v.value) {
            props.update({ filterType: v.value });
        }
    };

    const setIsDisabled = (v: boolean) => props.update({ isDisabled: !v });
    const changeName = (e: ChangeEvent<HTMLInputElement>) => props.update({ name: e.target.value });
    const setDailyLimit = (v: number) => props.update({ dailyLimit: Math.trunc(v) });
    const setDaysPerWeek = (v: number) => props.update({ daysPerWeek: Math.trunc(v) });
    const setWeeksTotal = (v: number) => props.update({ weeksTotal: Math.trunc(v) });
    const setDisableOnClick = (v: boolean) => props.update({ disableOnClick: v });

    const typeOptions = useMemo(() => [
        mapTypeSelectOption(AdUnitTypes.BannerAction),
        mapTypeSelectOption(AdUnitTypes.BrowserAction),
        mapTypeSelectOption(AdUnitTypes.RichMediaOverlay),
        mapTypeSelectOption(AdUnitTypes.DynamicWeb),
        mapTypeSelectOption(AdUnitTypes.SideAd),
    ], []);
    const selectedType = useMemo(
        () => typeOptions.find(o => o.value === props.value.unitType), [props.value.unitType, typeOptions]);

    const selectedFilterType = useMemo(
        () => filterTypeOptions.find(o => o.value === props.value.filterType), [props.value.filterType, filterTypeOptions]);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    const limitsDefines = useMemo(
        () => props.value.dailyLimit > 0 || props.value.daysPerWeek > 0 || props.value.weeksTotal > 0,
        [props.value.dailyLimit, props.value.daysPerWeek, props.value.weeksTotal]);

    return (
        <Form onSubmit={save} noValidate>
            <Row className="mb-sm-4">
                <Col sm={7}>
                     <Col xs={12}>
                        <FormGroup check className="mb-1">
                            <Label for="isEnabled" check>
                                <Input
                                    type="checkbox"
                                    id="isEnabled"
                                    name="isEnabled"
                                    checked={!props.value.isDisabled}
                                    onChange={setFromInput(setIsDisabled)}
                                    disabled={props.saving}
                                />{" "}
                                <FormLabel
                                    isChanged={props.value.isDisabled !== props.initial?.isDisabled}
                                    originalValue={props.isNewAdUnit ? undefined : props.initial?.isDisabled}>
                                    Enabled
                                </FormLabel>
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col xs={12}>
                        <FormGroup check>
                            <Label for="disableOnClick" check>
                                <Input
                                    type="checkbox"
                                    id="disableOnClick"
                                    name="disableOnClick"
                                    checked={props.value.disableOnClick}
                                    onChange={setFromInput(setDisableOnClick)}
                                    disabled={props.saving}
                                />{" "}
                                <FormLabel
                                    isChanged={props.value.disableOnClick !== props.initial?.disableOnClick}
                                    originalValue={props.isNewAdUnit ? undefined : props.initial?.disableOnClick}>
                                    Disable on click
                                </FormLabel>
                            </Label>
                        </FormGroup>
                    </Col>
                </Col>
                <Col sm={5} className="text-right">
                    <Button
                        type="submit"
                        color="primary"
                        className="ml-2 mb-2"
                        disabled={props.saving}>
                        {props.isNewAdUnit ? "Add" : "Save"} unit
                    </Button>
                </Col>
            </Row>
            <Col xl={8} className="mt-2">
                {props.value.disableOnClick && (
                    <Alert color="warning" className="text-center" >
                        Warning! Ads with <b>Disable on click</b> option enabled are not shown in client <b>v.3.76.1</b> or less
                    </Alert>
                )}
            </Col>
            <Heading label="Ad Limits" />
            <Row>
                <Col md={6} xl={4}>
                    <EditField
                        fieldId="dailyLimit"
                        label="Daily limit"
                        fullWidth={true}
                        isChanged={props.initial !== undefined && props.value.dailyLimit !== props.initial?.dailyLimit}
                    >
                        <Input
                            type="number"
                            min="0"
                            step="1"
                            id="dailyLimit"
                            value={props.value.dailyLimit}
                            onChange={setFromInput(setDailyLimit)}
                            disabled={props.saving}
                        />
                    </EditField>
                </Col>
                <Col md={6} xl={4}>
                    <EditField
                        fieldId="daysPerWeek"
                        label="Days per week"
                        fullWidth={true}
                        isChanged={props.initial !== undefined && props.value.daysPerWeek !== props.initial?.daysPerWeek}
                    >
                        <Input
                            type="number"
                            min="0"
                            step="1"
                            id="daysPerWeek"
                            value={props.value.daysPerWeek}
                            onChange={setFromInput(setDaysPerWeek)}
                            disabled={props.saving}
                        />
                    </EditField>
                </Col>
                <Col md={6} xl={4}>
                    <EditField
                        fieldId="weeksTotal"
                        label="Weeks total"
                        fullWidth={true}
                        isChanged={props.initial !== undefined && props.value.weeksTotal !== props.initial?.weeksTotal}
                    >
                        <Input
                            type="number"
                            min="0"
                            step="1"
                            id="weeksTotal"
                            value={props.value.weeksTotal}
                            onChange={setFromInput(setWeeksTotal)}
                            disabled={props.saving}
                        />
                    </EditField>
                </Col>
            </Row>
            {limitsDefines && (
                <Col xl={8} className="mt-2">
                    <Alert color="warning" className="text-center">
                        Warning! Ads with <b>Ad limits</b> option enabled are not shown in client <b>v.3.76.1</b> or less
                    </Alert>
                </Col>
            )}
            <Heading label="Ad Content" />
            <EditField
                fieldId="name"
                label="Name"
                isChanged={props.initial !== undefined && props.value.name !== props.initial?.name}
            >
                <Input
                    type="text"
                    id="name"
                    value={props.value.name ?? ""}
                    onChange={changeName}
                    disabled={props.saving}
                />
            </EditField>
            <EditField
                fieldId="type"
                label="Type"
                isChanged={props.initial !== undefined && props.value.unitType !== props.initial?.unitType}
            >
                <Select
                    id="type"
                    placeholder="Type"
                    value={selectedType}
                    onChange={setType}
                    options={typeOptions}
                    isMulti={false}
                    isSearchable={false}
                    isDisabled={props.saving || props.value.id !== 0}
                />
            </EditField>
            <EditField
                fieldId="filterType"
                label="Filter Type"
                isChanged={props.initial !== undefined && props.value.filterType !== props.initial?.filterType}
            >
                <Select
                    id="filterType"
                    placeholder="Filter Type"
                    value={selectedFilterType}
                    onChange={setFilterType}
                    options={filterTypeOptions}
                    isMulti={false}
                    isSearchable={false}
                    isDisabled={props.saving}
                />
            </EditField>
            {props.value.filterType === AdFilterType.Category && (
                <Col xl={6} className="mt-2">
                    <Alert color="warning" className="text-center">
                        Warning! Ads with <b>Filter type: Category</b> are not shown in client <b>v.3.76.1</b> or less
                    </Alert>
                </Col>
            )}
            {props.value.filterType === AdFilterType.Application &&
                <>
                    <Col sm={12} className="mb-2">
                        <FormGroup check>
                            <Label for="allAppsSelected" check>
                                <Input
                                    type="checkbox"
                                    id="allAppsSelected"
                                    name="allAppsSelected"
                                    checked={allApplicationsSelected}
                                    onChange={setFromInput(changeAllApplicationsSelected)}
                                    disabled={props.saving}
                                />{" "}
                                All Applications
                            </Label>
                        </FormGroup>
                    </Col>
                    <EditField
                        ref={register("applicationIds")}
                        fieldId="applications"
                        label="Applications"
                        isChanged={isApplicationIdsChanged}
                        errors={errors.applicationIds}
                    >
                        <SelectAsync
                            id="applications"
                            placeholder="Applications"
                            value={selectedApplicationsOptions}
                            onChange={setSelectedApplicationIds}
                            loadOptions={loadAppIdOptions}
                            cacheOptions={true}
                            isMulti={true}
                            defaultOptions={true}
                            isDisabled={props.saving || allApplicationsSelected}
                        />
                    </EditField>
                </>}
            {props.value.filterType === AdFilterType.Category &&
                <>
                    <Col sm={12} className="mb-2">
                        <FormGroup check>
                            <Label for="allCategoriesSelected" check>
                                <Input
                                    type="checkbox"
                                    id="allCategoriesSelected"
                                    name="allCategoriesSelected"
                                    checked={allCategoriesSelected}
                                    onChange={setFromInput(changeAllCategoriesSelected)}
                                    disabled={props.saving}
                                />{" "}
                                All Categories
                            </Label>
                        </FormGroup>
                    </Col>
                    <EditField
                        ref={register("categoryIds")}
                        fieldId="categories"
                        label="Categories"
                        isChanged={isCategoryIdsChanged}
                        errors={errors.categoryIds}
                    >
                        <Select
                            id="categories"
                            placeholder="Categories"
                            value={selectedCategoryOptions}
                            onChange={setSelectedCategoryIds}
                            options={filteredCategoryOptions}
                            isMulti={true}
                            isSearchable={true}
                            isDisabled={props.saving || allCategoriesSelected}
                        />
                    </EditField>
                </>}
            {adType === AdUnitTypes.BannerAction && props.value.adBannerUnit && adBannerSizes &&
                <EditBannerUnit
                    ref={bannerUnitRef}
                    bannerSizes={adBannerSizes}
                    initial={initialBannerUnit}
                    value={props.value.adBannerUnit}
                    saving={props.saving}
                    update={updateAdBannerUnit}
                />
            }
            {adType === AdUnitTypes.RichMediaOverlay && props.value.adRichMediaOverlayUnit &&
                <EditRichMediaOverlayUnit
                    ref={richMediaOverlayUnitRef}
                    initial={initialRichMediaOverlayUnit}
                    value={props.value.adRichMediaOverlayUnit}
                    saving={props.saving}
                    update={updateAdRichMediaOverlayUnit}
                />
            }
            {adType === AdUnitTypes.BrowserAction && props.value.adBrowserUnit &&
                <EditBrowserUnit
                    ref={browserUnitRef}
                    initial={initialBrowserUnit}
                    value={props.value.adBrowserUnit}
                    saving={props.saving}
                    update={updateAdBrowserUnit}
                />
            }
            {adType === AdUnitTypes.DynamicWeb && props.value.adDynamicWebUnit &&
                <EditDynamicWebUnit
                    ref={dynamicWebUnitRef}
                    initial={initialDynamicWebUnit}
                    value={props.value.adDynamicWebUnit}
                    saving={props.saving}
                    update={updateAdDynamicWebUnit}
                />
            }
            {adType === AdUnitTypes.SideAd && props.value.adSideUnit &&
                <EditSideAdUnit
                    ref={sideAdUnitRef}
                    initial={initialSideAdUnit}
                    value={props.value.adSideUnit}
                    saving={props.saving}
                    update={updateSideAdUnit}
                />
            }
            {!props.isNewAdUnit && (
                <React.Fragment >
                    <Heading label="Ad Triggers" />
                    <AdUnitTriggerListView adCampaignId={props.value.adCampaignId} adUnitId={props.value.id} user={props.user} />
                </React.Fragment>
            )}
        </Form>
    );
});
