import { Category } from "../dtos";
import { SelectOption } from "./SelectOption";

export type CategorySelectOption = SelectOption<string | undefined>;

export function mapCategorySelectOption<TOption extends CategorySelectOption = CategorySelectOption>(
    category: Category,
    allCategories: Category[]) {

    const value = category.id;
    let label;

    label = category.name;

    let maxDepth = 5;
    while (maxDepth > 0 && category.parentId !== undefined) {
        maxDepth--;

        const parent = allCategories.find(c => c.id === category.parentId);
        if (parent === undefined) {
            break;
        }

        label = `${parent.name} / ${label}`;
        category = parent;

        if (maxDepth === 0 && category.parentId !== undefined) {
            label = `... / ${label}`;
        }
    }

    return { value, label } as TOption;
}

export const mapCategorySelectOptions = (categories: Category[], allCategories: Category[]) => {
    return categories.map(c => mapCategorySelectOption(c, allCategories));
};