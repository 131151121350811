import React from "react";
import { Col, FormGroup, Label } from "reactstrap";
import { FormLabel } from "src/shared/components";

type EditFieldProps = {
    label?: string | React.ReactNode;
    fieldId?: string;
    isChanged?: boolean;
    fullWidth?: boolean;
};

export const EditField = (props: React.PropsWithChildren<EditFieldProps>) => {
    const { children, label, fieldId, isChanged, ...changesProps } = props;
    return (
        <Col md={props.fullWidth === true ? 12 : 6}>
            <FormGroup>
                {label !== undefined &&
                    <Label for={fieldId}>
                        {isChanged === undefined
                            ? label
                            : (
                                <FormLabel isChanged={isChanged} {...changesProps}>
                                    {label}
                                </FormLabel>
                            )}
                    </Label>}
                {props.children}
            </FormGroup>
        </Col>
    );
};