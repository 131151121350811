import { SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { SearchOptions } from "src/shared/components/InfiniteTable";

export interface InfiniteTableRequest {
    offset: number;
    limit: number;
    sort?: SortOptions;
    search?: Array<SearchOptions<unknown>>;
}

export type CustomValueGetter<T> = (item: T, key: string) => any;

export function infiniteTableResult<T>(
    items: T[],
    request: InfiniteTableRequest,
    customValueGetter?: CustomValueGetter<T>): T[] {

    items = ApplySearch(items, request.search, customValueGetter);
    items = ApplySort(items, request.sort, customValueGetter);

    return items.slice(request.offset, request.offset + request.limit);
}

function ApplySearch<T>(
    items: T[],
    options?: Array<SearchOptions<unknown>>,
    customValueGetter?: CustomValueGetter<T>): T[] {

    if (!options) {
        return items;
    }

    options.forEach(search => {
        items = items.filter(i => {
            const data = i[search.key] ?? customValueGetter?.call(null, i, search.key);
            const dataString = (data + "").toLowerCase();
            const value = (search.value + "").toLowerCase();
            return dataString.indexOf(value) !== -1;
        });
    });

    return items;
}

function ApplySort<T>(items: T[], options?: SortOptions, customValueGetter?: CustomValueGetter<T>): T[] {
    if (!options) {
        return items;
    }

    return items.sort((a, b) => {
        const aValue = a[options.key] ?? customValueGetter?.call(null, a, options.key);
        const bValue = b[options.key] ?? customValueGetter?.call(null, b, options.key);

        let result = 0;
        if (aValue < bValue) {
            result = -1;
        }
        if (aValue > bValue) {
            result = 1;
        }

        if (result === 0) {
            return 0;
        }

        return options.order === SortOrder.DESC ? -result : result;
    });
}