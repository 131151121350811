import React, { useEffect, useState, useReducer, useMemo, useCallback, useRef } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { forkJoin, defer } from "rxjs";
import {
    GamestoreApplication,
    GamestoreApplicationLocalization,
    Category,
    GoogleApplication,
    ApkDetails,
    ApkPlatform
} from "src/shared/dtos";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    LeavingViewProtector,
    Loader,
    LocalizationSelector,
    DocumentTitle,
    RouteLink,
    RouteUserProps,
    EditError,
    EditErrorOptions,
    notifySuccess,
    notifyError,
    FormLabel,
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import {
    availableLanguages,
    queryString,
    structuredClone,
    useSubscription,
    makeEmptyGamestoreApp,
    deepEqual,
    ValidateAsyncRef,
    getLangName,
    getFormErrorsAsStringArray,
    splitCamelCase,
    capitalize,
    validateForm,
    validateKeyBindings
} from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { EditForm } from "./EditForm";
import { EditInfo } from "./EditInfo";
import { EditLocalization } from "./EditLocalization";
import { applicationReducer, applicationReducerDefault, GamestoreAppMainInfo } from "./applicationReducer";
import { localizationReducer, makeLocalizationReducerDefault } from "./localizationReducer";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { EditKeyBindings } from "./EditKeyBindings";
import { CategoryType } from "src/shared/CategoryType";
import { platformDetailsReducerDefault, platformDetailsReducer } from "./platformDetailsReducer";
import { localizationMediaValidationSchema, makeLocalizationValidationSchema } from "./validationSchema";
import { useConfirm } from "src/shared/helpers/useConfirm";
import { ConfirmFailedLocalizationSources, LocalizationsFailedFields } from "src/shared/components/confirmWindow/samples/ConfirmFailedSources";
import { confirmDisable } from "./DisableReasonModal";

const getDefaultLocale = (locs: GamestoreApplicationLocalization[], lang: string): GamestoreApplicationLocalization => {
    const locale = locs.find(l => l.language === lang) || locs[0];
    if (locale === undefined) {
        throw new Error(`Gamestore Application has no localizations.`);
    }
    return locale;
};

const ErrorIcon = styled(FontAwesomeIcon)`
    color: red;
    cursor: help;
    margin-left: 0.175rem;
`;

const NoKeyBindingsIcon = styled(FontAwesomeIcon)`
    color: red;
    cursor: help;
    margin-left: 0.175rem;
`;

type Props = RouteComponentProps<{ appId: string }> & RouteUserProps;

export const EditView = withRouter(({ match, location, history, user }: Props) => {
    const appId = match.params.appId;
    const isNewApp = useMemo(() => appId === undefined, [appId]);
    const mainFormRef = useRef<ValidateAsyncRef>(null);
    const localizationFormRef = useRef<ValidateAsyncRef>(null);
    const queryParams = queryString(location.search);
    const googleAppId = queryParams.id;

    const defaultLang = isNewApp ? "en" : queryParams.lang || "en";

    const [isNewAppSaved, setIsNewAppSaved] = useState(!isNewApp);
    const [categories, setCategories] = useState<Category[] | undefined>([]);
    const [googleApps, setGoogleApps] = useState<GoogleApplication[]>([]);
    const [productVersions, setProductVersions] = useState<string[]>([]);
    const [keyBindingsSchemas, setKeyBindingsSchemas] = useState<object[]>([]);
    const [error, setError] = useState<EditErrorOptions | undefined>();
    const [saving, setSaving] = useState(false);
    const [editSection, setEditSection] = useState<"localizations" | "key-bindings">("localizations");
    const [app, changeApp] = useReducer(applicationReducer, applicationReducerDefault);
    const [platformDetails, changePlatformDetails] = useReducer(platformDetailsReducer, platformDetailsReducerDefault);
    const [loc, changeLocs] = useReducer(localizationReducer, makeLocalizationReducerDefault(defaultLang));
    const { confirm } = useConfirm();
    const backToList = useCallback(() => history.push(routes.applications.url()), []);
    const makeSetEditSection = (section: "localizations" | "key-bindings") => () => setEditSection(section);

    const isDefaultKeyBindingsValid = useMemo(
        () => validateKeyBindings(app.current?.defaultKeyBindings, keyBindingsSchemas) === null,
        [app.current?.defaultKeyBindings, keyBindingsSchemas.length]);

    const updateState = useSubscription((value: GamestoreApplication) => forkJoin([
        defer(() => structuredClone(value)),
        defer(() => structuredClone(value.localizations)),
        defer(() => structuredClone(value.localizations))])
        .subscribe(clones => {
            changeApp({ kind: "set", value, initialValue: clones[0] });
            changePlatformDetails({ kind: "set", app: value });
            changeLocs({
                kind: "set",
                app: value,
                resetCurrent: true,
                localizations: clones[1],
                initialLocalizations: clones[2]
            });
            // Check for an unknown localization languages.
            const allLangs = value.localizations.map(l => l.language);
            const unknownLangs = allLangs
                .filter(l => availableLanguages.find(al => al.value === l) === undefined)
                .join();
            if (unknownLangs.length > 0) {
                console.warn(
                    `GamestoreApplication '${value.id}' contains unknown localization languages: '${unknownLangs}'.`);
            }
        }), []);

    const setApp = useCallback((data?: GamestoreApplication) => {
        if (data !== undefined && !data.categories.some(c => [CategoryType.Game, CategoryType.App].includes(c.parentId!))) {
            data.categories.unshift(data.googleApplication.category);
            data.categoryIds = data.categories.map(c => c.id);
        }
        const value = data ?? makeEmptyGamestoreApp(googleAppId);

        updateState(value);
    }, [googleAppId]);

    const updateGoogleApps = useSubscription(() => defer(() => api.getGoogleAppsWithoutGamestoreApp()).subscribe({
        next: result => {
            setGoogleApps(result);
            setApp(undefined);
        },
        error: () => setError({
            text: "Unable to load google applications.",
            actionText: "Back to list",
            action: backToList
        })
    }), [setApp]);

    const updateGamestoreApp = useSubscription((id: string) => defer(() => api.get(id)).subscribe({
        next: result => {
            if (result?.localizations === undefined) {
                setError({
                    text: "Unable to load application localizations.",
                    actionText: "Back to list",
                    action: backToList
                });
                return;
            }

            setApp(result);
        },
        error: () => setError({
            text: "Unable to load application.",
            actionText: "Back to list",
            action: backToList
        })
    }), [setApp]);

    const updateAuxiliaryData = useSubscription(() => forkJoin([
        defer(() => api.getCategories()),
        defer(() => api.getKeyBindingsSchemas()),
        defer(() => api.getProductVersions())
    ]).subscribe(results => {
        setCategories(results[0]);
        setKeyBindingsSchemas([JSON.parse(results[1].camelCaseSchema), JSON.parse(results[1].pascalCaseSchema)]);
        setProductVersions(results[2].map(v => v.version));
    }), []);

    useEffect(() => {
        updateAuxiliaryData();
        if (isNewApp) {
            updateGoogleApps();
            return;
        }
        updateGamestoreApp(appId);
    }, [updateGoogleApps, updateGamestoreApp, isNewApp]);

    useEffect(() => {
        // Go to edit view after successful save of the new entity.
        // It is implemented this way to let leaving view protector know, that there is no changes.
        if (isNewApp && isNewAppSaved && error === undefined) {
            history.push(routes.editApplication.url({ appId: app.current!.id, language: defaultLang }));
        }
    }, [isNewApp, isNewAppSaved, app.current]);

    const saveApp = useSubscription((request: GamestoreApplication) => defer(() =>
        isNewApp ? api.create(request) : api.update(request)).subscribe({
            next: result => {
                notifySuccess(`Gamestore Application ${isNewApp ? "added" : "saved"} successfully.`);
                setApp(result);

                if (isNewApp) {
                    setIsNewAppSaved(true);
                }
            },
            error: () => {
                setSaving(false);
                setError({
                    text: "Unable to save application.",
                    actionText: "Continue",
                    action: () => setError(undefined)
                });
            },
            complete: () => setSaving(false)
        }), [api.update, api.create, isNewApp]);

    const save = useCallback(
        async () => {
            if (saving || app.current === undefined) {
                return;
            }

            if (!isDefaultKeyBindingsValid) {
                setEditSection("key-bindings");
                notifyError("Invalid key bindings format.");
                return;
            }

            if (loc.all.length === 0) {
                notifyError("GamestoreApplication must have at least one localization.");
                return;
            }

            setSaving(true);

            const isMainFormValid = await mainFormRef.current?.validate();
            if (!isMainFormValid) {
                setSaving(false);
                return;
            }

            const isLocalizationFormValid = !localizationFormRef.current || await localizationFormRef.current.validate();
            if (!isLocalizationFormValid) {
                setSaving(false);
                return;
            }

            const localizationValidationSchema = makeLocalizationValidationSchema(app.current?.isGraphicsCustomized);
            const localizationsErrors = (await Promise.all(
                loc.all.map(async l => {
                    const locValidationResult = await validateForm(l, localizationValidationSchema);
                    const locErrors = getFormErrorsAsStringArray(locValidationResult.errors);
                    return locErrors.map(err => `${getLangName(l.language)} Localization: ${err}`);
                })
            )).flat();

            if (localizationsErrors.length > 0) {
                setSaving(false);
                localizationsErrors.forEach(notifyError);
                return;
            }

            if ((app.current?.rating ?? app.current?.googleApplication.rating ?? 0) === 0) {
                const confirmSave = await confirm({
                    body: "Do you want to save application with rating 0?",
                    title: "Confirm saving application"
                });
                if (!confirmSave) {
                    setSaving(false);
                    return;
                }
            }

            if (!app.current.isDisabled) {
                const failedSources = (await Promise.all(
                    loc.all.map(async l => {
                        const locValidationResult = await validateForm(l, localizationMediaValidationSchema);
                        if (locValidationResult.isValid) {
                            return null;
                        }
                        const failedFields = Object.keys(locValidationResult.errors).map(capitalize).map(splitCamelCase);
                        return { lang: getLangName(l.language) ?? l.language, fields: failedFields } as LocalizationsFailedFields;
                    })
                )).filter(v => v !== null) as LocalizationsFailedFields[];

                if (failedSources.length > 0) {
                    const confirmSave = await confirm({
                        body: <ConfirmFailedLocalizationSources failedFields={failedSources} />,
                        title: "Confirm saving application",
                        size: "lg"
                    });
                    if (!confirmSave) {
                        setSaving(false);
                        return;
                    }
                }
            }

            let disableReason;

            if (app.current.isDisabled && !app.initial?.isDisabled) {
                try {
                    disableReason = await confirmDisable({
                        date: new Date(),
                        user,
                        productVersions
                    });
                } catch (error) {
                    setSaving(false);
                    return;
                }
            }

            const request: GamestoreApplication = {
                ...app.current,
                disableReason,
                localizations: loc.all,
                apkDetails: platformDetails.all,
                defaultKeyBindings: app.current.defaultKeyBindings === undefined
                    ? undefined : JSON.parse(app.current.defaultKeyBindings)
            };
            saveApp(request);
        }, [app, app.current, loc, loc.all, saving, saveApp, isDefaultKeyBindingsValid]);


    const updateApp = useCallback(
        (value: Partial<GamestoreAppMainInfo>) => changeApp({ kind: "update", value }), []);

    const updateDetails = useCallback(
        (value: Partial<ApkDetails>) => changePlatformDetails({ kind: "update", value }), []);

    const selectPlatform = useCallback(
        (platform: ApkPlatform) => changePlatformDetails({ kind: "select", platform }), []);

    const selectedGoogleApkDetails = useMemo(
        () => app.current?.googleApplication.apkDetails.find(d => d.platform === platformDetails.current?.platform),
        [app.current, platformDetails.current]);

    const setGoogleApp = useCallback(
        (value: GoogleApplication) => {
            changeApp({ kind: "set-google-app", value });
            changePlatformDetails({ kind: "set-google-app", value });
            changeLocs({ kind: "set-google-app", value });
        }, []);

    const updateLocalization = useCallback(
        (value: Partial<GamestoreApplicationLocalization>) => changeLocs({ kind: "update", value }), []);

    const removeLocalization = useCallback(() => changeLocs({ kind: "remove" }), []);

    const changed = useMemo(
        () => app.changed || loc.changedLanguages.length > 0 || loc.removedLocalizations.length > 0, [
        app.changed,
        loc.changedLanguages,
        loc.changedLanguages.length,
        loc.removedLocalizations,
        loc.removedLocalizations.length]);

    const title = useMemo(
        () => {
            const appTitle = isNewApp
                ? "[new application]"
                : (loc.current !== undefined
                    ? getDefaultLocale(loc.all, defaultLang).title
                    : "Loading...");
            return `Google Application - ${appTitle}`;
        },
        [loc.current, loc.all, defaultLang]);

    const setLanguage = (lang: string) => changeLocs({ kind: "select", lang });
    const addLanguage = (lang: string) => changeLocs({ kind: "add", lang });

    return (
        <VerticalBox>
            <DocumentTitle title={title} />
            <LeavingViewProtector showConfirmation={changed && (isNewApp ? !isNewAppSaved : true)} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.applications}>
                                Applications
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {isNewApp ? "[new application]" : match.params.appId}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    {error && <EditError error={error} />}
                    {!error && app.current === undefined || loc.current === undefined || categories === undefined &&
                        <Loader />}
                    {!error && categories !== undefined && app.current !== undefined && loc.current !== undefined &&
                        <React.Fragment>
                            <Row>
                                <Col sm={5} xs={12}>
                                    <EditInfo
                                        app={app.current}
                                        locale={loc.current}
                                        googleLocale={loc.googleLocale}
                                        googleApkDetails={selectedGoogleApkDetails}
                                        isNewApp={isNewApp} />
                                </Col>
                                <Col sm={7}>
                                    <EditForm
                                        ref={mainFormRef}
                                        value={app.current}
                                        platformDetails={platformDetails}
                                        initial={app.initial}
                                        isNewApp={isNewApp}
                                        categories={categories}
                                        googleApplications={googleApps}
                                        update={updateApp}
                                        updateDetails={updateDetails}
                                        selectPlatform={selectPlatform}
                                        setGoogleApplication={setGoogleApp}
                                        saving={saving}
                                        onSubmit={save}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                active={editSection === "localizations"}
                                                onClick={makeSetEditSection("localizations")}>
                                                <FormLabel isChanged={loc.changedLanguages.length > 0}>
                                                    Localizations
                                                </FormLabel>
                                                {app.current.isGraphicsCustomized &&
                                                    (loc.all.filter(t => t.thumbnailUrl == null || t.banners.length === 0).length > 0) &&
                                                    (
                                                        <ErrorIcon
                                                            icon="exclamation-circle"
                                                            title="Add a Thumbnail URL and at least one Banner into all localizations to save a graphics customized application."
                                                        />
                                                    )}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                active={editSection === "key-bindings"}
                                                onClick={makeSetEditSection("key-bindings")}>
                                                <FormLabel isChanged={!deepEqual(app.initial?.defaultKeyBindings, app.current.defaultKeyBindings)}>
                                                    Key Bindings
                                                </FormLabel>
                                                {app.current?.defaultKeyBindings === undefined && (
                                                    <NoKeyBindingsIcon
                                                        icon="exclamation-circle"
                                                        title="Key Bindings is not defined."
                                                    />
                                                )}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Col>
                            </Row>
                            {editSection === "localizations" && (
                                <Row>
                                    <Col>
                                        <LocalizationSelector
                                            currentLanguage={loc.current.language ?? defaultLang}
                                            languages={loc.all.map(l => l.language)}
                                            removedLanguages={loc.removedLocalizations.map(l => l.language)}
                                            changedLanguages={loc.changedLanguages}
                                            setLanguage={setLanguage}
                                            addLanguage={addLanguage}
                                        />
                                        {loc.current &&
                                            <EditLocalization
                                                ref={localizationFormRef}
                                                value={loc.current}
                                                initial={loc.initial}
                                                googleLocale={loc.googleLocale}
                                                isNewApp={isNewApp}
                                                update={updateLocalization}
                                                remove={removeLocalization}
                                                saving={saving}
                                                onSubmit={save}
                                                isGraphicsCustomized={app.current?.isGraphicsCustomized}
                                                isLastLocalization={loc.all.length < 2} />}
                                    </Col>
                                </Row>
                            )}
                            {editSection === "key-bindings" && (
                                <Row>
                                    <Col>
                                        <EditKeyBindings
                                            schemas={keyBindingsSchemas}
                                            value={app.current?.defaultKeyBindings}
                                            initial={app.initial?.defaultKeyBindings}
                                            update={updateApp}
                                            saving={saving}
                                            onSubmit={save}
                                            isValid={isDefaultKeyBindingsValid}
                                        />
                                    </Col>
                                </Row>
                            )}
                        </React.Fragment>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});
