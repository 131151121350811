import { makeUrl } from "./url";

const googlePlayLinkWithLanguage = (url: string, language?: string) =>
    makeUrl(url, language ? { hl: language } : {});

export const GetGoogleAppLink = (appId: string, locale: string) =>
    googlePlayLinkWithLanguage(`https://play.google.com/store/apps/details?id=${appId}`, locale);

export const GetGoogleAppCategoryLink = (category: string, locale: string) =>
    googlePlayLinkWithLanguage(`https://play.google.com/store/apps/category/${category}`, locale);

export const GetGoogleAppPublisherLink = (locale: string, publisher?: string, publisherId?: string) => {
    const link = publisherId
        ? `https://play.google.com/store/apps/dev?id=${publisherId ?? ""}`
        : `https://play.google.com/store/apps/developer?id=${publisher?.replace(" ", "+") ?? ""}`;

    return googlePlayLinkWithLanguage(link, locale);
};