import React, { useState, useEffect } from "react";
import { useForm, ErrorMessage } from "react-hook-form";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Row, Col, FormGroup, Label, Input, Form, Container, Button, FormFeedback } from "reactstrap";
import { noop, defer } from "rxjs";
import { useFunctionState, formValidation } from "src/shared/helpers";
import { client } from "src/shared/client";
import { DocumentTitle, notifyError } from "src/shared/components";

type FormValues = { email: string, password: string, rememberMe: boolean };
export const LoginView = withRouter((props: RouteComponentProps) => {
    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    const [submitting, setSubmitting] = useState(false);
    const [invalid, setInvalid] = useState(false);

    const { register, handleSubmit, errors, watch, clearError } = useForm<FormValues>({ reValidateMode: "onChange" });
    const email = watch("email");
    const password = watch("password");

    useEffect(() => {
        setInvalid(false);
        clearError();
    }, [email, password]);

    useEffect(() => unsubscribe, [unsubscribe]);
    const login = (form: FormValues) => {
        if (submitting) {
            return;
        }

        setSubmitting(true);
        clearError();
        const subscription = defer(() => client.login(form.email, form.password, form.rememberMe)).subscribe({
            next: result => {
                setSubmitting(false);
                if (result === null) {
                    setInvalid(true);
                    notifyError("Invalid email or password.");
                } else {
                    props.history.push("/");
                }
            },
            error: () => {
                setSubmitting(false);
                notifyError("Login failed. Please try again later.");
            }
        });
        setUnsubscribe(() => subscription.unsubscribe());
    };

    return (
        <Container>
            <DocumentTitle title="Login" />
            <Row className="mt-4">
                <Col lg={{ size: 6, offset: 3 }} md={{ size: 8, offset: 2 }}>
                    <h2>Login to Playstore Admin</h2>
                    <Form className="mt-4" onSubmit={handleSubmit(login)} noValidate>
                        <FormGroup>
                            <Label for="email">
                                Email
                            </Label>
                            <Input
                                type="email"
                                id="email"
                                name="email"
                                disabled={submitting}
                                autoFocus
                                invalid={invalid || errors.email !== undefined}
                                innerRef={register(formValidation({ required: true, email: true }))} />
                            <ErrorMessage
                                as={FormFeedback}
                                errors={errors}
                                name="email" />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">
                                Password
                            </Label>
                            <Input
                                type="password"
                                id="password"
                                name="password"
                                disabled={submitting}
                                invalid={invalid || errors.email !== undefined}
                                innerRef={register(formValidation({ required: true }))} />
                            <ErrorMessage
                                as={FormFeedback}
                                errors={errors}
                                name="password" />
                        </FormGroup>
                        <FormGroup check>
                            <Label for="rememberMe" check>
                                <Input
                                    type="checkbox"
                                    id="rememberMe"
                                    name="rememberMe"
                                    disabled={submitting}
                                    innerRef={register({})} />{" "}
                                    Remember me
                            </Label>
                        </FormGroup>
                        <FormGroup className="float-right">
                            <Button type="submit" color="primary" disabled={submitting}>
                                {submitting ? "Logging in..." : "Log in"}
                            </Button>
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
});
