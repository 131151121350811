import { client } from "src/shared/client";
import { CrawlAllIoApplications, CrawledIoApplication, CrawlIoApplication, GetIoApplicationSourceSummaries, GetIoCrawlingProviders, GetIoCrawlingResults } from "src/shared/dtos";
import { ExcludeMethods, serializeDate } from "src/shared/helpers";

export type ListRequest = Omit<ExcludeMethods<GetIoCrawlingResults>,
    "addedAtStartDateSearch" | "addedAtEndDateSearch" | "updatedAtStartDateSearch" | "updatedAtEndDateSearch"> & {
        addedAtStartDateSearch?: Date,
        addedAtEndDateSearch?: Date,
        updatedAtStartDateSearch?: Date,
        updatedAtEndDateSearch?: Date
    };

export type SourcesRequest = ExcludeMethods<GetIoApplicationSourceSummaries>;

export const CrawlingAppsApi = {
    list: async (request: ListRequest) => {
        const {
            addedAtStartDateSearch,
            addedAtEndDateSearch,
            updatedAtStartDateSearch,
            updatedAtEndDateSearch,
            ...restRequest
        } = request;
        return client.get(new GetIoCrawlingResults({
            addedAtStartDateSearch: serializeDate(addedAtStartDateSearch),
            addedAtEndDateSearch: serializeDate(addedAtEndDateSearch),
            updatedAtStartDateSearch: serializeDate(updatedAtStartDateSearch),
            updatedAtEndDateSearch: serializeDate(updatedAtEndDateSearch),
            ...restRequest
        }));
    },
    getProviders: async () => client.get(new GetIoCrawlingProviders()),
    getSources: async (request: SourcesRequest) => client.get(new GetIoApplicationSourceSummaries({ ...request })),
    crawlApp: async (app: CrawledIoApplication) => client.post(new CrawlIoApplication({ app })),
    crawlAllApps: async () => client.post(new CrawlAllIoApplications())
};

export default CrawlingAppsApi;