import { ChartWidgetTimeSpan } from "src/shared/dtos";
import { addDays, addMonths, addYears } from "src/shared/helpers";

export const getStartDate = (endDate: Date, timeSpan: ChartWidgetTimeSpan): Date => {
    if (timeSpan === ChartWidgetTimeSpan.Week) {
        return addDays(endDate, -6);
    }
    if (timeSpan === ChartWidgetTimeSpan.Month) {
        return addMonths(endDate, -1);
    }
    if (timeSpan === ChartWidgetTimeSpan.Year) {
        return addYears(endDate, -1);
    }
    return addDays(endDate, -7);
};