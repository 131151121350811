import { RouteComponentProps } from "react-router-dom";
import { Role, IUser } from "src/shared/client";
import { makeUrl } from "src/shared/helpers";
import { ListView as ApplicationListView, EditView as ApplicationEditView } from "src/components/Applications";
import { ListView as AdCampaignListView, EditView as AdCampaignEditView } from "src/components/AdCampaigns";
import { EditView as AdUnitEditView, ListView as AdUnitsListView } from "src/components/AdUnits";
import { EditView as AdUnitTriggerEditView } from "src/components/AdTriggers";
import { ListView as CategoryListView, EditView as CategoryEditView } from "src/components/Categories";
import { ListView as TagsListView, EditView as TagEditView } from "src/components/Tags";
import { ListView as RequestStatisticsListView, DetailsView as RequestStatisticsDetailsView } from "src/components/RequestStatistic";
import { ListView as GamingMinutesStatisticsListView, DetailsView as GamingMinutesStatisticsDetailsView } from "src/components/GamingStatistics";
import { ListView as ActiveInstallsStatisticsListView, DetailsView as ActiveInstallsStatisticsDetailsView } from "src/components/ActiveInstallsStatistics";
import {
    ListView as PlaystoreActivityStatisticsListView,
    DetailsView as PlaystoreActivityStatisticsDetailsView,
    DetailsRouteParams as PlaystoreActivityDetailsRouteParams,
    ListRouteParams as PlaystoreActivityListRouteParams,
    defaultRouteParams as PlaystoreActivityListDefaultRouteParams
} from "src/components/PlaystoreActivityStatistics";
import {
    ListView as FeaturedApplicationListView,
    EditView as FeaturedApplicationEditView
} from "src/components/FeaturedApplications";
import {
    ListView as GoogleApplicationListView,
    EditView as GoogleApplicationEditView
} from "src/components/GoogleApplications";
import {
    ListView as IoApplicationListView,
    EditView as IoApplicationEditView
} from "src/components/IoApplications";
import {
    ListView as GamestoreIoApplicationListView,
    EditView as GamestoreIoApplicationEditView
} from "src/components/GamestoreIoApplications";
import {
    SourcesListView as IoCrawlingSourcesListView,
    ResultsListView as IoCrawlingResultsListView
} from "src/components/CrawlingIoApps";
import { ListView as CrawlingAppsListView } from "src/components/CrawlingGoogleApps";
import { HomeView } from "src/components/Home/HomeView";
import { LoginView } from "src/components/Login";
import {
    ListView as ProductVersionInfosListView,
    EditView as ProductVersionInfosEditView
} from "src/components/ProductVersions";
import { ListView as SourceSettingsListView } from "src/components/SourceSettings";
import { ListView as UserListView, EditView as UserEditView } from "src/components/Users";
import { ListView as AdBrowserExtensionList } from "src/components/AdBrowserExtensions";
import { DistributionView as GamingDistributionView } from "src/components/GamingStatistics/Distribution/DistributionView";
import { DistributionView as RequestStatisticsDistributionView } from "src/components/RequestStatistic/Distribution/DistributionView";
import {
    ListView as ClickInfoStatisticsListView,
    DetailsView as ClickInfoStatisticsDetailsView,
    DetailsRouteParams as ClickInfoDetailsRouteParams,
    ListRouteParams as ClickInfoListRouteParams,
    defaultRouteParams as ClickInfoListDefaultRouteParams
} from "src/components/ClickInfoStatistics";
import { ChangePasswordView } from "src/components/Users/Edit/ChangePasswordView";

export type Pattern = { path: string, exact: boolean };
export type Component = React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
export type Renderer = (props: RouteComponentProps<any>) => React.ReactNode;
export type User = IUser | null;

export interface IRoute<TArgs> {
    pattern: Pattern;
    component?: Component;
    render?: Renderer;
    requireLogin?: boolean;
    requireRoles?: Role[];
    url(args: TArgs): string;
    loginRequired(user: User): boolean;
    hasAccess(user: User): boolean;
}

type RouteConstructorData<TArgs> = Omit<Omit<Omit<IRoute<TArgs>, "loginRequired">, "hasAccess">, "url">
    & Partial<Pick<IRoute<TArgs>, "url">>;

class Route<TArgs> implements IRoute<TArgs> {
    public static make<TArgs>(data: RouteConstructorData<TArgs>): IRoute<TArgs> {
        return new Route(data);
    }

    public readonly pattern: Pattern;
    public readonly component?: Component;
    public readonly render?: Renderer;
    public readonly requireLogin?: boolean;
    public readonly requireRoles?: Role[];

    constructor(data: RouteConstructorData<TArgs>) {
        Object.assign(this, data);
    }

    public url = () => this.pattern.path;
    public loginRequired = (user: User) => this.requireLogin === true && user === null;
    public hasAccess = (user: User) =>
        this.requireRoles === undefined || user?.hasRoles(...this.requireRoles) === true
}

const makePattern = (path: string, exact?: boolean) => ({ path, exact: exact ?? false });

export const routes = {
    home: Route.make<void>({
        pattern: makePattern("/", true),
        component: HomeView,
        requireLogin: true
    }),
    login: Route.make<void>({
        pattern: makePattern("/login", true),
        component: LoginView,
        requireLogin: false,
        url: () => "/login"
    }),
    applications: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/applications", true),
        component: ApplicationListView,
        requireLogin: true,
        url: args => makeUrl("/applications", args
            ? args?.searchParams ?? { orderBy: "ApkUpdatedDate", orderDir: "Desc" }
            : { orderBy: "ApkUpdatedDate", orderDir: "Desc" })
    }),
    addApplication: Route.make<{ appId?: string } | void>({
        pattern: makePattern("/applications/new", true),
        component: ApplicationEditView,
        requireLogin: true,
        url: args => makeUrl(`/applications/new`, args === undefined ? {} : { id: args.appId })
    }),
    editApplication: Route.make<{ appId: string, language: string }>({
        pattern: makePattern("/applications/:appId"),
        component: ApplicationEditView,
        requireLogin: true,
        url: args => makeUrl(`/applications/${args.appId}`, { lang: args.language })
    }),
    featuredApplications: Route.make<void>({
        pattern: makePattern("/featured-applications", true),
        component: FeaturedApplicationListView,
        requireLogin: true
    }),
    addFeaturedApplication: Route.make<void>({
        pattern: makePattern("/featured-applications/new", true),
        component: FeaturedApplicationEditView,
        requireLogin: true
    }),
    editFeaturedApplication: Route.make<{ appId: string }>({
        pattern: makePattern("/featured-applications/:appId"),
        component: FeaturedApplicationEditView,
        requireLogin: true,
        url: args => makeUrl(`/featured-applications/${args.appId}`, {})
    }),
    googleApplications: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/google-applications", true),
        component: GoogleApplicationListView,
        requireLogin: true,
        url: args => makeUrl("/google-applications", args ? args?.searchParams ?? {} : {})
    }),
    addGoogleApplication: Route.make<{ appId?: string } | void>({
        pattern: makePattern("/google-applications/new", true),
        component: GoogleApplicationEditView,
        requireLogin: true,
        url: args => makeUrl(`/google-applications/new`, args === undefined ? {} : { id: args.appId })
    }),
    editGoogleApplication: Route.make<{ appId: string, language: string }>({
        pattern: makePattern("/google-applications/:appId"),
        component: GoogleApplicationEditView,
        requireLogin: true,
        url: args => makeUrl(`/google-applications/${args.appId}`, { lang: args.language })
    }),
    ioApplications: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/io-applications", true),
        component: IoApplicationListView,
        requireLogin: true,
        url: args => makeUrl("/io-applications", args ? args?.searchParams ?? {} : {})
    }),
    addIoApplication: Route.make<{ appId?: string } | void>({
        pattern: makePattern("/io-applications/new", true),
        component: IoApplicationEditView,
        requireLogin: true,
        url: args => makeUrl(`/io-applications/new`, args === undefined ? {} : { id: args.appId })
    }),
    editIoApplication: Route.make<{ appId: string, language: string }>({
        pattern: makePattern("/io-applications/:appId"),
        component: IoApplicationEditView,
        requireLogin: true,
        url: args => makeUrl(`/io-applications/${args.appId}`, { lang: args.language })
    }),
    gamestoreIoApplications: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/io-gamestore-applications", true),
        component: GamestoreIoApplicationListView,
        requireLogin: true,
        url: args => makeUrl("/io-gamestore-applications", args ? args?.searchParams ?? {} : {})
    }),
    addGamestoreIoApplication: Route.make<{ appId?: string } | void>({
        pattern: makePattern("/io-gamestore-applications/new", true),
        component: GamestoreIoApplicationEditView,
        requireLogin: true,
        url: args => makeUrl(`/io-gamestore-applications/new`, args === undefined ? {} : { id: args.appId })
    }),
    editGamestoreIoApplication: Route.make<{ appId: string, language: string }>({
        pattern: makePattern("/io-gamestore-applications/:appId"),
        component: GamestoreIoApplicationEditView,
        requireLogin: true,
        url: args => makeUrl(`/io-gamestore-applications/${args.appId}`, { lang: args.language })
    }),
    categories: Route.make<void>({
        pattern: makePattern("/categories", true),
        component: CategoryListView,
        requireLogin: true
    }),
    addCategory: Route.make<void>({
        pattern: makePattern("/categories/new", true),
        component: CategoryEditView,
        requireLogin: true
    }),
    editCategory: Route.make<{ id: string }>({
        pattern: makePattern("/categories/:id"),
        component: CategoryEditView,
        requireLogin: true,
        url: args => makeUrl(`/categories/${args.id}`, {})
    }),
    tags: Route.make<void>({
        pattern: makePattern("/tags", true),
        component: TagsListView,
        requireLogin: true
    }),
    addTag: Route.make<void>({
        pattern: makePattern("/tags/new", true),
        component: TagEditView,
        requireLogin: true
    }),
    editTag: Route.make<{ id: string }>({
        pattern: makePattern("/tags/:id"),
        component: TagEditView,
        requireLogin: true,
        url: args => makeUrl(`/tags/${args.id}`, {})
    }),
    ioCrawlingResults: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/crawling-io-apps", true),
        component: IoCrawlingResultsListView,
        requireLogin: true,
        url: args => makeUrl("/crawling-io-apps", args
            ? args?.searchParams ?? { orderBy: "AddedAt", orderDir: "Desc" }
            : { orderBy: "AddedAt", orderDir: "Desc" })
    }),
    ioCrawlingSources: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/io-apps-sources", true),
        component: IoCrawlingSourcesListView,
        requireLogin: true,
        url: args => makeUrl("/io-apps-sources", args ? args?.searchParams ?? {} : {})
    }),
    crawlingGoogleApplications: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/crawling-google-apps", true),
        component: CrawlingAppsListView,
        requireLogin: true,
        url: args => makeUrl("/crawling-google-apps", args
            ? args?.searchParams ?? { orderBy: "AddedAt", orderDir: "Desc" }
            : { orderBy: "AddedAt", orderDir: "Desc" })
    }),
    sourceSettings: Route.make<void>({
        pattern: makePattern("/source-settings", true),
        component: SourceSettingsListView,
        requireLogin: true
    }),
    productVersions: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/product-versions", true),
        component: ProductVersionInfosListView,
        requireLogin: true,
        url: args => makeUrl("/product-versions", args ? args?.searchParams ?? {} : {})
    }),
    addProductVersion: Route.make<void>({
        pattern: makePattern("/product-versions/new", true),
        component: ProductVersionInfosEditView,
        requireLogin: true
    }),
    editProductVersion: Route.make<{ id: string }>({
        pattern: makePattern("/product-versions/:id"),
        component: ProductVersionInfosEditView,
        requireLogin: true,
        url: args => makeUrl(`/product-versions/${args.id}`, {})
    }),
    adBrowserExtensions: Route.make<void>({
        pattern: makePattern("/ad-browser-extensions", true),
        component: AdBrowserExtensionList,
        requireLogin: true
    }),
    adCampaigns: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/ad-campaigns", true),
        component: AdCampaignListView,
        requireLogin: true,
        url: args => makeUrl("/ad-campaigns", args ? args?.searchParams ?? {} : {})
    }),
    adUnits: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/ad-units", true),
        component: AdUnitsListView,
        requireLogin: true,
        url: args => makeUrl("/ad-units", args ? args?.searchParams ?? {} : {})
    }),
    addAdUnitTrigger: Route.make<{ adCampaignId: number, adUnitId: number }>({
        pattern: makePattern("/ad-campaigns/:adCampaignId/ad-units/:adUnitId/ad-unit-triggers/new"),
        component: AdUnitTriggerEditView,
        requireLogin: true,
        url: args => makeUrl(`/ad-campaigns/${args.adCampaignId}/ad-units/${args.adUnitId}/ad-unit-triggers/new`, {})
    }),
    editAdUnitTrigger: Route.make<{ adCampaignId: number, adUnitId: number, id: number }>({
        pattern: makePattern("/ad-campaigns/:adCampaignId/ad-units/:adUnitId/ad-unit-triggers/:id"),
        component: AdUnitTriggerEditView,
        requireLogin: true,
        url: args => makeUrl(`/ad-campaigns/${args.adCampaignId}/ad-units/${args.adUnitId}/ad-unit-triggers/${args.id}`, {})
    }),
    addAdUnit: Route.make<{ adCampaignId: number }>({
        pattern: makePattern("/ad-campaigns/:adCampaignId/ad-units/new"),
        component: AdUnitEditView,
        requireLogin: true,
        url: args => makeUrl(`/ad-campaigns/${args.adCampaignId}/ad-units/new`, {})
    }),
    editAdUnit: Route.make<{ adCampaignId: number, id: number }>({
        pattern: makePattern("/ad-campaigns/:adCampaignId/ad-units/:id"),
        component: AdUnitEditView,
        requireLogin: true,
        url: args => makeUrl(`/ad-campaigns/${args.adCampaignId}/ad-units/${args.id}`, {})
    }),
    addAdCampaign: Route.make<void>({
        pattern: makePattern("/ad-campaigns/new", true),
        component: AdCampaignEditView,
        requireLogin: true
    }),
    editAdCampaign: Route.make<{ id: number }>({
        pattern: makePattern("/ad-campaigns/:id"),
        component: AdCampaignEditView,
        requireLogin: true,
        url: args => makeUrl(`/ad-campaigns/${args.id}`, {})
    }),
    requestStatistics: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/request-statistics", true),
        component: RequestStatisticsListView,
        requireLogin: true,
        url: args => makeUrl("/request-statistics", args ? args?.searchParams ?? {} : {})
    }),
    viewRequestStatistic: Route.make<{ path: string, movingAvgDays: number | undefined, endDate: string }>({
        pattern: makePattern("/request-statistics-details/:path/:movingAvgDays/:endDate"),
        component: RequestStatisticsDetailsView,
        requireLogin: true,
        url: args => makeUrl(`/request-statistics-details/${args.path}/${args.movingAvgDays}/${args.endDate}`, {})
    }),
    gamingStatistics: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/gaming-statistics", true),
        component: GamingMinutesStatisticsListView,
        requireLogin: true,
        url: args => makeUrl("/gaming-statistics", args ? args?.searchParams ?? { "productVersion": "0.0.0" } : { "productVersion": "0.0.0" })
    }),
    viewGamingStatistic: Route.make<{ appId: string, version: string, movingAvgDays: number | undefined, endDate: string }>({
        pattern: makePattern("/gaming-statistics-details/:appId/:version/:movingAvgDays/:endDate"),
        component: GamingMinutesStatisticsDetailsView,
        requireLogin: true,
        url: args => makeUrl(`/gaming-statistics-details/${args.appId}/${args.version}/${args.movingAvgDays}/${args.endDate}`, {})
    }),
    activeInstallsStatistics: Route.make<{ searchParams?: { [name: string]: string } } | void>({
        pattern: makePattern("/active-users-statistics", true),
        component: ActiveInstallsStatisticsListView,
        requireLogin: true,
        url: args => makeUrl("/active-users-statistics", args ? args?.searchParams ?? { "productVersion": "0.0.0" } : { "productVersion": "0.0.0" })
    }),
    viewActiveInstallsStatistic: Route.make<{ version: string, endDate: string }>({
        pattern: makePattern("/active-users-statistics-details/:version/:endDate"),
        component: ActiveInstallsStatisticsDetailsView,
        requireLogin: true,
        url: args => makeUrl(`/active-users-statistics-details/${args.version}/${args.endDate}`, {})
    }),
    gamingStatisticsDistribution: Route.make<{ id: number, movingAvgDays: number | undefined, endDate: string }>({
        pattern: makePattern("/gaming-minutes-statistics-details-distribution/:id/:movingAvgDays"),
        component: GamingDistributionView,
        requireLogin: true,
        url: args => makeUrl(`/gaming-minutes-statistics-details-distribution/${args.id}/${args.movingAvgDays}/${args.endDate}`, {})
    }),
    requestStatisticsDistribution: Route.make<{ id: number, movingAvgDays: number, endDate: string }>({
        pattern: makePattern("/request-statistics-details-distribution/:id/:movingAvgDays/:endDate"),
        component: RequestStatisticsDistributionView,
        requireLogin: true,
        url: args => makeUrl(`/request-statistics-details-distribution/${args.id}/${args.movingAvgDays}/${args.endDate}`, {})
    }),
    playstoreActivityStatistics: Route.make<PlaystoreActivityListRouteParams>({
        pattern: makePattern("/playstore-activity-statistics", true),
        component: PlaystoreActivityStatisticsListView,
        requireLogin: true,
        url: args => makeUrl("/playstore-activity-statistics", args ? args : PlaystoreActivityListDefaultRouteParams)
    }),
    viewPlaystoreActivityStatistics: Route.make<PlaystoreActivityDetailsRouteParams>({
        pattern: makePattern("/playstore-activity-statistics-details"),
        component: PlaystoreActivityStatisticsDetailsView,
        requireLogin: true,
        url: args => makeUrl(`/playstore-activity-statistics-details`, args ?? {})
    }),
    clickInfoStatistics: Route.make<ClickInfoListRouteParams>({
        pattern: makePattern("/click-info-statistics", true),
        component: ClickInfoStatisticsListView,
        requireLogin: true,
        url: args => makeUrl("/click-info-statistics", args ? args : ClickInfoListDefaultRouteParams)
    }),
    viewClickInfoStatistic: Route.make<ClickInfoDetailsRouteParams>({
        pattern: makePattern("/click-info-statistics-details"),
        component: ClickInfoStatisticsDetailsView,
        requireLogin: true,
        url: args => makeUrl(`/click-info-statistics-details`, args ?? {})
    }),
    users: Route.make<void>({
        pattern: makePattern("/users", true),
        component: UserListView,
        requireLogin: true,
        requireRoles: [Role.Admin]
    }),
    addUser: Route.make<void>({
        pattern: makePattern("/users/new", true),
        component: UserEditView,
        requireLogin: true,
        requireRoles: [Role.Admin]
    }),
    editUser: Route.make<{ id: number }>({
        pattern: makePattern("/users/:id"),
        component: UserEditView,
        requireLogin: true,
        requireRoles: [Role.Admin],
        url: args => `/users/${args.id}`
    }),
    changePassword: Route.make<{ id: number }>({
        pattern: makePattern("/change-password/:id"),
        component: ChangePasswordView,
        requireLogin: true,
        url: args => `/change-password/${args.id}`
    })
};
