import { client } from "src/shared/client";
import { DeleteAdBrowserExtension, GetAdBrowserExtensions, ManageAdBrowserExtension, AdBrowserExtension, ImportAdBrowserExtensions } from "src/shared/dtos";

export const AdBrowserExtensionApi = {
    list: () => client.get(new GetAdBrowserExtensions()),
    create: (extensionId: string) => {
        const adBrowserExtension = new AdBrowserExtension();
        adBrowserExtension.extensionId = extensionId;

        return client.post(new ManageAdBrowserExtension({ adBrowserExtension }));
    },
    delete: (id: number) => client.delete(new DeleteAdBrowserExtension({ id })),
    importData: (file: File) => {
        const request = new ImportAdBrowserExtensions();
        const formData = new FormData();
        formData.append("description", file.name);
        formData.append("type", "file");
        formData.append("file", file);
        return client.postBody(request, formData);
    }
};

export default AdBrowserExtensionApi;