import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown as RsDropdown, DropdownToggle, DropdownMenu as RsDropdownMenu, DropdownMenuProps, DropdownItem } from "reactstrap";
import { CenterVertically } from "src/shared/components/mixin";
import { ColumnOptions } from "./ColumnOptions";
import { useBootstrapBreakpoint } from "src/shared/helpers";

const Dropdown = styled(RsDropdown)`
    width: 42px;
    height: 100%;
`;

const Toggle = styled(DropdownToggle)`
    ${CenterVertically}
    margin: 0;
`;

const ToggleHiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    margin-right: 8px;
    cursor: pointer;
`;

const ToggleHidden = (props: { hidden: boolean }) =>
    <ToggleHiddenCheckbox checked={!props.hidden} readOnly />;

const DropdownMenu = (props: { fullScreen: boolean } & DropdownMenuProps) => {
    const { fullScreen, ...restProps } = props;
    return <RsDropdownMenu {...restProps} />;
};

const StyledDropdownMenu = styled(DropdownMenu)`
    ${props => props.fullScreen ? "position: fixed !important;" : null}
    ${props => props.fullScreen ? "top: 0 !important;" : null}
    ${props => props.fullScreen ? "left: 0 !important;" : null}
    ${props => props.fullScreen ? "width: calc(100% - 2em) !important;" : null}
    ${props => props.fullScreen ? "height: calc(100% - 2em) !important;" : null}
    ${props => props.fullScreen ? "margin: 1em !important;" : null}
    ${props => props.fullScreen ? "transform: none !important;" : null}
    overflow: auto;
`;

const CloseButton = styled.div`
    cursor: pointer;
    position: sticky;
    float: right;
    margin-right: 10px;
    top: 0;

    &&> svg {
        width: 1.5em;
        height: 1.5em;
    }
`;

export interface OptionsProps {
    columns: ColumnOptions[];
    onClose?: (visibleColumns: string[]) => void;
}

export const Options = (props: OptionsProps) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const visibleColumns = useMemo(() => props.columns.filter(c => !c.hidden).map(c => c.key), [JSON.stringify(props.columns)]);

    const toggle = useCallback(() => {
        if (dropdownOpen && props.onClose !== undefined) {
            props.onClose(visibleColumns);
        }
        setDropdownOpen(prevState => !prevState);
    }, [dropdownOpen, visibleColumns, props.onClose]);

    const breakpoint = useBootstrapBreakpoint();
    const fullScreen = breakpoint === "xs" || breakpoint === "sm" || breakpoint === "md";

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <Toggle>
                <FontAwesomeIcon icon="cog" />
            </Toggle>
            <StyledDropdownMenu right fullScreen={fullScreen}>
                {fullScreen && (
                    <CloseButton onClick={toggle}>
                        <FontAwesomeIcon icon="times" />
                    </CloseButton>
                )}
                <DropdownItem header>Columns</DropdownItem>
                <DropdownItem divider />
                {props.columns.map(c =>
                    <DropdownItem key={c.key} toggle={false} onClick={c.toggleHidden}>
                        <ToggleHidden hidden={c.hidden} />
                        <span>{c.title || <i>&lt;{c.key}&gt;</i>}</span>
                    </DropdownItem>)}
            </StyledDropdownMenu>
        </Dropdown>
    );
};
