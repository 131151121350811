import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { defer, noop, of } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { ContentBox, EditError, EditErrorOptions, Loader, notifyError, notifySuccess, RouteUserProps } from "src/shared/components";
import { Content, VerticalBox } from "src/shared/components/flex";
import { useFunctionState } from "src/shared/helpers";
import api from "../api";
import { ChangePasswordForm } from "./ChangePasswordForm";
type Props = RouteComponentProps<{ id?: string }> & RouteUserProps;
const getUserInfo = (userId?: string, currentUserId?: number): [boolean, number] => {
    const id = +(userId ?? 0);
    const isOwnProfile = id === currentUserId;
    return [isOwnProfile, id];
};
export const ChangePasswordView = withRouter((props: Props) => {
    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);
    const [error, setError] = useState<EditErrorOptions | undefined>();
    const [saving, setSaving] = useState(false);

    const [isOwnProfile] = useMemo(
        () => getUserInfo(props.match.params.id, props.user?.userId),
        [props.match.params.id, props.user?.userId]);
    const resetPassword = useCallback(
        (newPassword: string, oldPassword: string) => {
            if (saving || props.user === undefined) {
                return Promise.resolve(false);
            }
            setSaving(true);
            const observable = (defer(() => api.resetPassword(props.user?.username ?? "", oldPassword, newPassword)))
                .pipe(
                    catchError(() => of(false)),
                    tap(saved => saved
                        ? notifySuccess("Password changed successfully.")
                        : notifyError("Unable to change password.")),
                    finalize(() => setSaving(false)));
            return observable.toPromise();
        }, [saving, props.user?.username]);

    useEffect(() => {
        if (!isOwnProfile) {
            props.history.goBack();
        }
    }, [isOwnProfile]);

    return (
        <VerticalBox>
            <Content>
                <ContentBox>
                    {error && <EditError error={error} />}
                    {!error && props.user === undefined &&
                        <Loader />}
                    {!error && props.user !== undefined &&
                    <React.Fragment>
                        <ChangePasswordForm
                            isOwnPassword={isOwnProfile}
                            saving={saving}
                            onSubmit={resetPassword}
                        />
                    </React.Fragment>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});