import { Tag } from "src/shared/dtos";
import { validateId, validateSource, validateUrlField, ValidationSchema } from "src/shared/helpers";

const validateName = (val: string | undefined): string | null => {
    if (!val) {
        return "Name is required";
    }
    if (val.trim().length < 2) {
        return "Name must be at least two characters long";
    }
    if (/^ +/.test(val) || / +$/.test(val)) {
        return "Name cannot contain leading or trailing spaces";
    }
    const nameRegex = /^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$/;
    if (!nameRegex.test(val)) {
        return "Name can contain only latin alphabet characters and numbers, separated by space";
    }
    return null;
};

export const validationSchema: ValidationSchema<Tag> = {
    id: v => validateId(v),
    name: v => validateName(v),
    iconUrl: async (url, tag) => {
        if (!url && tag.showInNavbar) {
            return "Icon URL is required for tag with 'Show In Navbar' option on";
        }
        const urlError = validateUrlField({ url });
        if (urlError !== null || !url) {
            return urlError;
        }
        const isSvg = url.endsWith(".svg");
        return !isSvg ? "Only SVG images are allowed" : null;
    },
};

export const imagesValidationSchema: ValidationSchema<Tag> = {
    iconUrl: url => validateSource({ url, type: "image" })
};