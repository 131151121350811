import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BreadcrumbItem, Button, Input, Label } from "reactstrap";
import { defer, noop } from "rxjs";
import styled from "styled-components";
import {
    Breadcrumb,
    DocumentTitle,
    Loader,
    RouteLink,
    RouteUserProps,
    SortableList,
    ToolBox,
    ToolBoxLeftContainer,
    ToolBoxRightContainer
} from "src/shared/components";
import { FeaturedApplication } from "src/shared/dtos";
import { Content, VerticalBox, Header } from "src/shared/components/flex";
import { useFunctionState, useDebounce, setFromInput } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import api from "../api";

const HideDisabledLabel = styled(Label)`
    cursor: pointer;
    margin-right: 1em;
`;

export const ListView = withRouter(({ user, history }: RouteComponentProps<{}> & RouteUserProps) => {
    const [apps, setApps] = useState<FeaturedApplication[]>();
    const [reordering, setReordering] = useState(false);

    const [hideDisabled, setHideDisabled] = useState(false);
    const [search, setSearch] = useState("");
    const debounceSearch = useDebounce(search, 500) as string;

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);

    useEffect(() => {
        if (apps === undefined) {
            const subscription = defer(() => api.list()).subscribe(setApps);
            return () => subscription.unsubscribe();
        }

        return undefined;
    }, [apps]);

    const reload = () => {
        setReordering(false);
        setApps(undefined);
    };

    const items = useMemo(() => {
        if (apps === undefined) {
            return [];
        }

        return apps
            .filter(a => hideDisabled ? !a.isDisabled : true)
            .filter(a => debounceSearch
                ? a.id.toLowerCase().indexOf(debounceSearch.toLowerCase()) !== -1
                : true);
    }, [apps, hideDisabled, debounceSearch]);

    const idResolver = useCallback((item: FeaturedApplication) => item.id, []);
    const renderer = useCallback((item: FeaturedApplication) => (
        <span className="d-flex justify-content-between align-items-center">
            <span className={item.isDisabled ? "text-muted" : ""}>
                <span>{item.id}</span>&nbsp;
                {item.isDisabled && (
                    <em><small>(disabled)</small></em>
                )}
            </span>
            <span className={`badge badge-pill ${item.localizations.length > 0 ? "badge-info" : "badge-secondary"}`}>
                {item.localizations.length > 0
                    ? item.localizations.map(l => l.language).join(", ")
                    : "not localized"}
            </span>
        </span>
    ), []);

    const onSelect = (item: FeaturedApplication) => {
        history.push(routes.editFeaturedApplication.url({ appId: item.id }));
    };

    const onMove = (id: string, startIndex: number, endIndex: number) => {
        if (apps === undefined || startIndex === endIndex) {
            return;
        }

        setReordering(true);

        const subscription = defer(() => api.reorder(id, endIndex)).subscribe(result => {
            setApps(result);
            setReordering(false);
        });

        setUnsubscribe(() => subscription.unsubscribe());
    };

    return (
        <VerticalBox>
            <DocumentTitle title="Featured Applications" />
            <Header>
                <ToolBox>
                    <ToolBoxLeftContainer>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <RouteLink user={user} to={routes.home}>
                                    Home
                                </RouteLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                Featured Applications
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </ToolBoxLeftContainer>
                    <ToolBoxRightContainer>
                        <HideDisabledLabel for="hideDisabled" check>
                            <Input
                                type="checkbox"
                                id="hideDisabled"
                                name="hideDisabled"
                                checked={hideDisabled}
                                onChange={setFromInput(setHideDisabled)}
                            />
                            Hide disabled
                        </HideDisabledLabel>
                        <RouteLink user={user} to={routes.addFeaturedApplication} button className="mr-2">
                            Add Featured Application
                        </RouteLink>
                        <Button onClick={reload}>
                            <FontAwesomeIcon icon="sync" />
                        </Button>
                    </ToolBoxRightContainer>
                </ToolBox>
            </Header>
            <Content style={{ overflow: "hidden" }}>
                {apps === undefined && <Loader />}
                {apps !== undefined && (
                    <div className="row">
                        <div className="offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6 offset-xl-4 col-xl-4">
                            <div className="row">
                                <div className="col-md-12 mt-1 mb-4">
                                    <Input
                                        type="text"
                                        placeholder="Search application..."
                                        value={search}
                                        onChange={setFromInput(setSearch)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <SortableList
                                        items={items}
                                        disabled={reordering}
                                        itemIdResolver={idResolver}
                                        itemRenderer={renderer}
                                        onSelect={onSelect}
                                        onMove={onMove}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Content>
        </VerticalBox>);
});