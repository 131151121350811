import React, { useEffect, useCallback, useReducer, useMemo, useState } from "react";
import { defer, forkJoin } from "rxjs";
import { DocumentTitle, RouteUserProps } from "src/shared/components";
import { ChartWidgetDataTypes, InfoWidgetDataTypes, ListWidgetDataTypes, WidgetUnit, WidgetUnitLayouts } from "src/shared/dtos";
import { BootstrapBreakpoints, pickItems, useSubscription } from "src/shared/helpers";
import api from "./api";
import styled from "styled-components";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, Spinner } from "reactstrap";
import { Layouts, Responsive, WidthProvider  } from "react-grid-layout";
import { makeEmptyTextWidget, makeEmptyChartWidget, makeEmptyInfoWidget, makeEmptyListWidget } from "./widgetFactory";
import { Widget } from "./Widgets/Widget";
import { getLayoutKey, getWidgetsLayouts } from "./layoutsConverter";
import { defaultDashboardState, dashboardReducer } from "./dashboardReducer";
import { ThinScrollbar } from "./Widgets/mixins/ThinScrollbar";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { notifySuccess, notifyError } from "src/shared/components";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

const Main = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f8f8f8;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 40px;
`;

const StyledGridLayout = styled(ResponsiveGridLayout)`
    flex: 1;
    overflow-x: hidden;
    ${ThinScrollbar};
`;

const WidgetMenu = styled.header`
    height: 60px;
    padding: 10px;
    display: flex;
    border-bottom: 1px solid #ccc;
    justify-content: space-between;
`;

const EditBtnsGroup = styled.div`
    display: flex;
    button {
        margin: 0 6px;
    }
`;

const EditBtn = styled(Button)`
    color: #fff;
    background-color: #343a40;
`;

const StyledDropdownToggle = styled(DropdownToggle)`
    background-color: #343a40;
`;

const LoadingScreen = styled(Modal)`
    .modal-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: transparent;
        border: none;
    }
`;

const StyledSpinner = styled(Spinner)`
    width: 60px;
    height: 60px;
`;

export const WidgetHeaderClassName = "widget-header";

export const HomeView = withRouter(({ user }: RouteComponentProps<{}> & RouteUserProps) => {
    const [dashboard, updateDashboard] = useReducer(dashboardReducer, defaultDashboardState);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [changed, setChanged] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [resizeTrigger, setResizeTrigger] = useState(false);

    const getDashboardData = useSubscription(() => forkJoin([
        defer(() => api.getWidgets()),
        defer(() => api.getFrontendSettings())
    ]).subscribe(results => {
        updateDashboard({kind: "set", widgets: results[0], settings: results[1].defaultSettings?.dashboardSettings});
        setLoading(false);
    }), []);

    useEffect(() => {
        setLoading(true);
        getDashboardData();
    }, []);

    const createWidget = useSubscription((widget: WidgetUnit) => defer(
        () => api.create(widget))
        .subscribe({
            next: val => {
                updateDashboard({kind: "add", widget: val});
                setLoading(false);
                setEdit(true);
                notifySuccess("Widget has been created.");
            },
            error: err => {
                setLoading(false);
                notifyError(err?.responseStatus?.message ?? "Failed to create widget.");
            }
        }), []);

    const addWidget = useCallback((widget: WidgetUnit) => {
        setLoading(true);
        createWidget(widget);
    }, []);

    const addTextWidget = useCallback(() => {
        addWidget(makeEmptyTextWidget());
    }, [addWidget]);

    const addInfoWidget = useCallback((dataType: InfoWidgetDataTypes) => () => {
        addWidget(makeEmptyInfoWidget(dataType));
    }, [addWidget]);

    const addChartWidget = useCallback((dataType: ChartWidgetDataTypes) => () => {
        addWidget(makeEmptyChartWidget(dataType));
    }, [addWidget]);

    const addListWidget = useCallback((dataType: ListWidgetDataTypes) => () => {
        addWidget(makeEmptyListWidget(dataType));
    }, [addWidget]);

    const toggleDropdown = useCallback(() => setDropdownOpen(v => !v), []);
    const enableEditMode = useCallback(() => setEdit(true), []);

    const isSaveButtonDisabled = useMemo(() => loading || !changed, [loading, changed]);

    const removeWidgetFromDashboard = useCallback(
        (id: number) => updateDashboard({kind: "remove", id}), []);

    const onLayoutChange = useCallback((_, layouts: Layouts) => {
        setChanged(edit);
        updateDashboard({kind: "update", layouts});
    }, [edit]);

    const onResize = useCallback(() => {
        setResizeTrigger(t => !t);
    }, []);

    const resetDashboard = useCallback(() => {
        updateDashboard({kind: "reset"});
        setResizeTrigger(t => !t);
        setEdit(false);
    }, []);

    const saveDashboard = useSubscription((val: WidgetUnitLayouts[]) => defer(
        () => api.updateLayouts(val))
        .subscribe({
            next: _ => {
                setLoading(false);
                setEdit(false);
                setChanged(false);
                updateDashboard({kind: "save"});
                notifySuccess("Dashboard has been saved.");
            },
            error: err => {
                setLoading(false);
                notifyError(err?.responseStatus?.message ?? "Failed to save dashboard.");
            }
        }),
    [api.updateLayouts]);

    const saveDashboardCallback = useCallback(() => {
        setLoading(true);
        if (dashboard.currentLayouts){
            saveDashboard(getWidgetsLayouts(dashboard.currentLayouts));
        }
    }, [dashboard.currentLayouts]);

    const breakPoints = useMemo(
        () => dashboard.settings ? pickItems(BootstrapBreakpoints, Object.keys(dashboard.settings.columns)) : {}, [dashboard.settings]);

    return (
        <Main>
            <DocumentTitle title="Dashboard" />
            <LoadingScreen isOpen={loading} centered fade={false}>
                <StyledSpinner color="light"/>
            </LoadingScreen>
            <WidgetMenu>
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction={"down"}>
                    <StyledDropdownToggle>Add Widget +</StyledDropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={addTextWidget}>Text Notes</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem header>Info Widgets</DropdownItem>
                        <DropdownItem onClick={addInfoWidget(InfoWidgetDataTypes.ApplicationsInfo)}>Applications Info</DropdownItem>
                        <DropdownItem onClick={addInfoWidget(InfoWidgetDataTypes.CrawledApplicationInfo)}>Crawling Info</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem header>Chart Widgets</DropdownItem>
                        <DropdownItem onClick={addChartWidget(ChartWidgetDataTypes.GamingMinutesData)}>Gaming Minutes</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem header>Table Widgets</DropdownItem>
                        <DropdownItem onClick={addListWidget(ListWidgetDataTypes.LastUpdatedApplications)}>Last Updated Applications</DropdownItem>
                        <DropdownItem onClick={addListWidget(ListWidgetDataTypes.TopRatedApplications)}>Top Rated Applications</DropdownItem>
                        <DropdownItem onClick={addListWidget(ListWidgetDataTypes.RequestsByAverageTime)}>Requests By Average Time</DropdownItem>
                        <DropdownItem onClick={addListWidget(ListWidgetDataTypes.RequestsByCount)}>Requests By Count</DropdownItem>
                        <DropdownItem onClick={addListWidget(ListWidgetDataTypes.RequestsByTotalTime)}>Requests By Total Time</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <EditBtnsGroup>
                    {!edit
                    ? <EditBtn onClick={enableEditMode}>Edit Dashboard</EditBtn>
                    : <>
                        <EditBtn onClick={resetDashboard}>Cancel</EditBtn>
                        <Button
                            color="primary"
                            onClick={saveDashboardCallback}
                            disabled={isSaveButtonDisabled}
                        >
                            Save
                        </Button>
                    </>}
                </EditBtnsGroup>
            </WidgetMenu>
            <Content>
                {dashboard.settings && dashboard.currentLayouts &&
                <StyledGridLayout
                    breakpoints={breakPoints}
                    cols={dashboard.settings.columns}
                    draggableHandle={`.${WidgetHeaderClassName}`}
                    containerPadding={[24, 24]}
                    layouts={dashboard.currentLayouts}
                    margin={[10, 10]}
                    rowHeight={dashboard.settings.rowHeight}
                    isResizable={edit}
                    isDraggable={edit}
                    compactType={"vertical"}
                    onLayoutChange={onLayoutChange}
                    onResizeStop={onResize}
                    maxRows={dashboard.settings.maxRows}
                    preventCollision={false}
                    allowOverlap={false}
                >
                    {dashboard.widgets.map(w =>
                        <div key={getLayoutKey(w.id)}>
                            <Widget
                                user={user}
                                editMode={edit}
                                widget={w}
                                removeFromDashboard={removeWidgetFromDashboard}
                                resizeTrigger={resizeTrigger}
                            />
                        </div>)}
                </StyledGridLayout>}
            </Content>
        </Main>);
});