import React, { FC, Dispatch } from "react";
import { InfoWidgetDataTypes, InfoWidgetUnit } from "src/shared/dtos";
import styled from "styled-components";
import { ApplicationsInfoList } from "./ApplicationsInfo";
import { CrawlingInfo } from "./CrawlingInfo";

const Main = styled.div`
    background-color: #fff;
    height: 100%;
    padding: 8px;
    border-radius: 0 0 12px 12px;
`;

export interface InfoWidgetProps {
    infoWidget: InfoWidgetUnit;
    setLoading: Dispatch<boolean>;
}

export const InfoWidget: FC<InfoWidgetProps> = ({ infoWidget, setLoading }) => {
    return (
        <Main>
            {infoWidget.dataType === InfoWidgetDataTypes.ApplicationsInfo &&
                <ApplicationsInfoList setLoading={setLoading} />}
            {infoWidget.dataType === InfoWidgetDataTypes.CrawledApplicationInfo &&
                <CrawlingInfo setLoading={setLoading} />}
        </Main>
    );
};