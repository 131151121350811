import React from "react";
import { BaseTableProps } from ".";
import { BaseTableWrapper } from "./ReactBaseTableWrapper";

type InnerRef<TItem> = { innerRef: React.Ref<BaseTable<TItem>> };

export enum Align {
    /**
     * Scroll as little as possible to ensure the row is visible.
     */
    auto = "auto",

    /**
     * Same as 'auto' if it is less than one viewport away, or it's the same as 'center'.
     */
    smart = "smart",

    /**
     * Center align the row within the table.
     */
    center = "center",

    /**
     * Align the row to the bottom side of the table.
     */
    end = "end",

    /**
     * Align the row to the top side of the table.
     */
    start = "start"
}

/**
 * React table component.
 */
export class BaseTable<TItem> extends React.Component<BaseTableProps<TItem> & InnerRef<TItem>> {
    /**
     * Get the DOM node of the table.
     * @description To use with 'innerRef' only.
     */
    public getDOMNode(): React.ReactNode {
        return null as React.ReactNode;
    }

    /**
     * Get the column manager.
     * @description To use with 'innerRef' only.
     */
    public getColumnManager(): unknown {
        return null;
    }

    /**
     * Get internal expandedRowKeys state.
     * @description To use with 'innerRef' only.
     */
    public getExpandedRowKeys(): unknown {
        return null;
    }

    /**
     * Get the expanded state, fallback to normal state if not expandable.
     * @description To use with 'innerRef' only.
     */
    public getExpandedState(): unknown {
        return null;
    }

    /**
     * Get the total height of all rows, including expanded rows.
     * @description To use with 'innerRef' only.
     */
    public getTotalRowsHeight(): number {
        return 0;
    }

    /**
     * Get the total width of all columns.
     * @description To use with 'innerRef' only.
     */
    public getTotalColumnsWidth(): number {
        return 0;
    }

    /**
     * Forcefully re-render the inner Grid component.
     * @summary Calling forceUpdate on Table may not re-render the inner Grid since it uses shallowCompare as a performanc
     * optimization. Use this method if you want to manually trigger a re-render. This may be appropriate if the
     * underlying row data has changed but the row sizes themselves have not.
     * @description To use with 'innerRef' only.
     */
    // tslint:disable-next-line: no-empty
    public forceUpdateTable(): void {
    }

    /**
     * Scroll to the specified offset. Useful for animating position changes.
     * @description To use with 'innerRef' only.
     */
    // tslint:disable-next-line: no-empty
    public scrollToPosition(options: { scrollTop: number, scrollLeft: number }): void {
    }

    /**
     * Scroll to the specified offset vertically.
     * @description To use with 'innerRef' only.
     */
    // tslint:disable-next-line: no-empty
    public scrollToTop(scrollTop: number): void {
    }

    /**
     * Scroll to the specified offset horizontally.
     * @description To use with 'innerRef' only.
     */
    // tslint:disable-next-line: no-empty
    public scrollToLeft(scrollLeft: number): void {
    }

    /**
     * Scroll to the specified row. By default, the table will scroll as little as possible to ensure the row is
     * visible. You can control the alignment of the row though by specifying an align property.
     * @param rowIndex The index of a row.
     * @param align The relative alignment for the 'rowIndex'. Default value: 'auto'.
     * @description To use with 'innerRef' only.
     */
    // tslint:disable-next-line: no-empty
    public scrollToRow(rowIndex: number, align?: Align): void {
    }

    /**
     * Set expandedRowKeys manually. This method is available only if expandedRowKeys is uncontrolled.
     * @description To use with 'innerRef' only.
     */
    // tslint:disable-next-line: no-empty
    public setExpandedRowKeys(expandedRowKeys: string[]): void {
    }

    public render(): React.ReactNode {
        const { innerRef, ...restProps } = this.props;
        return <BaseTableWrapper ref={innerRef} {...restProps} />;
    }
}