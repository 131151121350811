import React, { forwardRef, useCallback, useMemo, useImperativeHandle, useEffect } from "react";
import { Button, Form, FormGroup, Input, Row, Col } from "reactstrap";
import styled from "styled-components";
import { DownloaderVideo, GamestoreIoApplicationLocalization, IoApplicationLocalization } from "src/shared/dtos";
import { applyFallbackFields, setFromInput, useValidate, ValidateAsyncRef } from "src/shared/helpers";
import { SlideListEditor, Slide, HtmlEditor, ImageSelector, EditField, LoadingButton } from "src/shared/components";
import { ioProperty } from "./ioProperty";
import { VideoSelector } from "src/shared/components/VideoSelector";
import { locSourcesValidationSchema, makeLocalizationValidationSchema } from "./validationSchema";

interface Props {
    value: GamestoreIoApplicationLocalization;
    initial?: GamestoreIoApplicationLocalization;
    ioLocale?: IoApplicationLocalization;
    isNewApp: boolean;
    update: (value: Partial<GamestoreIoApplicationLocalization>) => void;
    remove: () => void;
    saving: boolean;
    onSubmit: () => void;
    isGraphicsCustomized: boolean;
    isAppDisabled: boolean;
    isLastLocalization: boolean;
}

const makeEmptyDownloaderVideoOptions = (): DownloaderVideo => ({
    videoUrl: "",
    locationX: 25,
    locationY: 28,
    width: 749,
    height: 427
});

const StyledInputBlock = styled.div`
    padding: 0.4rem;
`;

export const applyIoLocaleFallbackData = (
    loc: GamestoreIoApplicationLocalization, ioLoc: IoApplicationLocalization | undefined): GamestoreIoApplicationLocalization =>
    applyFallbackFields(loc, ioLoc, ["title", "description", "applicationUrl", "iconUrl", "thumbnailUrl"]);

export const EditLocalization = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const setTitle = (v: string) => props.update({ title: v ? v : undefined });
    const setApplicationUrl = (v: string) => props.update({ applicationUrl: v ? v : undefined });
    const setDescription = (v: string) => props.update({ description: v ? v : undefined });
    const setIconUrl = (v: string) => props.update({ iconUrl: v });
    const setThumbnailUrl = (v: string) => props.update({ thumbnailUrl: v });
    const setLoadingImageUrl = (v: string) => props.update({ loadingImageUrl: v });
    const setLogoUrl = (v: string) => props.update({ logoUrl: v ? v : undefined });
    const setBackgroundUrl = (v: string) => props.update({ backgroundUrl: v ? v : undefined });
    const setVideos = (v: string[]) => props.update({ videos: v });
    const setScreenshots = (v: string[]) => props.update({ screenshots: v });
    const setBanners = (v: string[]) => props.update({ banners: v });

    const updateDownloaderVideoOptions = (data: Partial<DownloaderVideo>) => {
        const current = props.value.downloaderVideoOptions ?? makeEmptyDownloaderVideoOptions();
        const updated = Object.assign(current, data);
        props.update({ downloaderVideoOptions: updated });
    };
    const setDownloaderVideoUrl = (v: string) => updateDownloaderVideoOptions({ videoUrl: v ? v : undefined });
    const setLocationX = (v: number) => updateDownloaderVideoOptions({ locationX: v });
    const setLocationY = (v: number) => updateDownloaderVideoOptions({ locationY: v });
    const setVideoWidth = (v: number) => updateDownloaderVideoOptions({ width: v });
    const setVideoHeight = (v: number) => updateDownloaderVideoOptions({ height: v });

    const validationSchema = useMemo(
        () => makeLocalizationValidationSchema(props.isGraphicsCustomized),
        [props.isGraphicsCustomized, props.ioLocale]);

    const localizationData = useMemo(
        () => applyIoLocaleFallbackData(props.value, props.ioLocale), [JSON.stringify(props.value), props.ioLocale]);

    const { validate, errors, register, reset: resetErrors } = useValidate({
        schema: validationSchema,
        value: localizationData
    });
    const { validate: validateSources, errors: sourceWarnings, reset: resetWarnings } = useValidate({
        schema: locSourcesValidationSchema,
        value: localizationData
    });

    useEffect(() => {
        if (props.isAppDisabled){
            resetErrors();
            resetWarnings();
        }
    }, [props.isAppDisabled]);

    useImperativeHandle(ref, () => ({
        validate: async () => {
            if (props.isAppDisabled){
                return true;
            }
            const isValid = (await validate(true)).isValid;
            validateSources();
            return isValid;
        }
    }), [validate, validateSources, props.isAppDisabled]);

    const setIcon = useCallback(
        (url: string) => {
            setIconUrl(url);
            return "";
        }, []);
    const setThumbnail = useCallback(
        (url: string) => {
            setThumbnailUrl(url);
            return "";
        }, []);
    const setLoadingImage = useCallback(
        (url: string) => {
            setLoadingImageUrl(url);
            return "";
        }, []);
    const setLogo = useCallback(
        (url: string) => {
            setLogoUrl(url);
            return "";
        }, []);
    const setBackground = useCallback(
        (url: string) => {
            setBackgroundUrl(url);
            return "";
        }, []);
    const setDownloaderVideo = useCallback(
        (url: string) => {
            setDownloaderVideoUrl(url);
            return "";
        }, []);

    const isVideosChanged = useCallback((a?: string[], b?: string[]) => a?.join() !== b?.join(), []);
    const addVideo = useCallback(
        (url: string): [string, number] => {
            if (props.value.videos.length === 0) {
                props.value.videos = props.ioLocale?.videos ?? [];
            }

            const videos = props.value.videos.concat(url);
            setVideos(videos);

            return ["", props.value.videos.length - 1];
        }, [props.value.videos]);
    const removeVideo = useCallback(
        (url: string) => {
            if (props.value.videos.length === 0) {
                props.value.videos = props.ioLocale?.videos ?? [];
            }

            const videos = props.value.videos.filter(v => v !== url);
            setVideos(videos);
        }, [props.value.videos]);

    const isScreenshotsChanged = useCallback((a?: string[], b?: string[]) => a?.join() !== b?.join(), []);
    const addScreenshot = useCallback(
        (url: string): [string, number] => {
            if (props.value.screenshots.length === 0) {
                props.value.screenshots = props.ioLocale?.screenshots ?? [];
            }

            const screenshots = props.value.screenshots.concat(url);
            setScreenshots(screenshots);

            return ["", props.value.screenshots.length - 1];
        }, [props.value.screenshots]);
    const removeScreenshot = useCallback(
        (url: string) => {
            if (props.value.screenshots.length === 0) {
                props.value.screenshots = props.ioLocale?.screenshots ?? [];
            }

            const screenshots = props.value.screenshots.filter(s => s !== url);
            setScreenshots(screenshots);
        }, [props.value.screenshots]);

    const isBannerChanged = useCallback((a?: string[], b?: string[]) => a?.join() !== b?.join(), []);
    const addBanner = useCallback(
        (url: string): [string, number] => {
            const banners = props.value.banners.concat(url);
            setBanners(banners);

            return ["", props.value.banners.length - 1];
        }, [props.value.banners]);
    const removeBanner = useCallback(
        (url: string) => {
            const banners = props.value.banners.filter(b => b !== url);
            setBanners(banners);
        }, [props.value.banners]);

    const renderIcon = useCallback((url: string): Slide | null => url === "" ? null : { type: "image", url }, []);
    const renderImage = useCallback((url: string): Slide => ({ type: "image", url }), []);
    const renderVideo = useCallback((url: string): Slide => ({ type: "srcVideo", url }), []);
    const renderSingleVideo = useCallback((url: string): Slide | null => url === "" ? null : { type: "video", url }, []);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <Form className="mt-3" onSubmit={save} noValidate>
            <Row>
                <EditField
                    ref={register("title")}
                    label={ioProperty("Title", props.value.title === undefined)}
                    fieldId="title"
                    isChanged={props.value.title !== props.initial?.title}
                    originalValue={props.isNewApp ? undefined : props.initial?.title}
                    errors={errors?.title}>
                    <Input
                        type="text"
                        id="title"
                        value={props.value.title ?? ""}
                        placeholder={props.ioLocale?.title}
                        onChange={setFromInput(setTitle)}
                        disabled={props.saving}
                    />
                </EditField>
                <EditField
                    ref={register("applicationUrl")}
                    label={ioProperty("Application Url", props.value.applicationUrl === undefined)}
                    fieldId="applicationUrl"
                    isChanged={props.value.applicationUrl !== props.initial?.applicationUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.applicationUrl}
                    errors={errors?.applicationUrl}>
                    <Input
                        type="text"
                        id="applicationUrl"
                        value={props.value.applicationUrl ?? ""}
                        placeholder={props.ioLocale?.applicationUrl}
                        onChange={setFromInput(setApplicationUrl)}
                        disabled={props.saving}
                    />
                </EditField>
                <EditField
                    ref={register("thumbnailUrl")}
                    label={ioProperty("Thumbnail URL", props.value.thumbnailUrl === undefined)}
                    fieldId="thumbnailUrl"
                    isChanged={props.value.thumbnailUrl !== props.initial?.thumbnailUrl}
                    originalValue={(props.isNewApp ? undefined : props.initial?.thumbnailUrl)}
                    errors={errors?.thumbnailUrl ?? sourceWarnings?.thumbnailUrl}>
                    <ImageSelector
                        setInputId="thumbnailUrl"
                        value={props.value.thumbnailUrl ?? props.ioLocale?.thumbnailUrl ?? ""}
                        set={setThumbnail}
                        render={renderIcon}
                        delete={setThumbnail}
                        imageDimensions={{ width: "12rem", height: "12rem" }}
                        deleteButtonHidden={!props.value.thumbnailUrl}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("loadingImageUrl")}
                    label="Loading image URL"
                    fieldId="loadingImageUrl"
                    isChanged={props.value.loadingImageUrl !== props.initial?.loadingImageUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.loadingImageUrl}
                    errors={errors?.loadingImageUrl ?? sourceWarnings?.loadingImageUrl}>
                    <ImageSelector
                        setInputId="loadingImageUrl"
                        value={props.value.loadingImageUrl ?? ""}
                        set={setLoadingImage}
                        render={renderIcon}
                        delete={setLoadingImage}
                        invalid={(errors?.loadingImageUrl?.length ?? 0) > 0}
                        imageDimensions={{ width: "12rem", height: "12rem" }}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("logoUrl")}
                    label="Logo URL"
                    fieldId="logoUrl"
                    isChanged={props.value.logoUrl !== props.initial?.logoUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.logoUrl}
                    errors={errors?.logoUrl ?? sourceWarnings?.logoUrl}>
                    <ImageSelector
                        setInputId="logoUrl"
                        value={props.value.logoUrl ?? ""}
                        set={setLogo}
                        render={renderIcon}
                        delete={setLogo}
                        invalid={(errors?.logoUrl?.length ?? 0) > 0}
                        imageDimensions={{ width: "12rem", height: "12rem" }}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("iconUrl")}
                    label={ioProperty("Icon URL", props.value.iconUrl === undefined)}
                    fieldId="iconUrl"
                    isChanged={props.value.iconUrl !== props.initial?.iconUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.iconUrl}
                    errors={errors?.iconUrl ?? sourceWarnings?.iconUrl}>
                    <ImageSelector
                        setInputId="iconUrl"
                        value={props.value.iconUrl ?? props.ioLocale?.iconUrl ?? ""}
                        set={setIcon}
                        render={renderIcon}
                        delete={setIcon}
                        imageDimensions={{ width: "12rem", height: "12rem" }}
                        deleteButtonHidden={!props.value.iconUrl}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("backgroundUrl")}
                    label="Background URL"
                    fieldId="backgroundUrl"
                    isChanged={props.value.backgroundUrl !== props.initial?.backgroundUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.backgroundUrl}
                    errors={errors?.backgroundUrl ?? sourceWarnings?.backgroundUrl}>
                    <ImageSelector
                        setInputId="backgroundUrl"
                        value={props.value.backgroundUrl ?? ""}
                        set={setBackground}
                        render={renderIcon}
                        delete={setBackground}
                        imageDimensions={{ width: "36rem", height: "31.875rem" }}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("banners")}
                    label="Banners"
                    fieldId="banners"
                    isChanged={isBannerChanged(props.value.banners, props.initial?.banners)}
                    errors={errors?.banners ?? sourceWarnings?.banners}>
                    <SlideListEditor
                        addInputId="banners"
                        values={props.value.banners ?? []}
                        add={addBanner}
                        remove={removeBanner}
                        render={renderImage}
                        invalid={(errors?.banners?.length ?? 0) > 0}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("description")}
                    label={ioProperty("Description", props.value.description === undefined)}
                    fieldId="description"
                    isChanged={props.value.description !== props.initial?.description}
                    fullWidth={true}
                    errors={errors?.description}>
                    <HtmlEditor
                        id="description"
                        value={props.value.description}
                        onChange={setDescription}
                        disabled={props.saving}
                        placeholder={props.ioLocale?.description}
                    />
                </EditField>
                <EditField
                    ref={register("videos")}
                    label={ioProperty("Videos", props.value.videos.length === 0)}
                    fieldId="videos"
                    isChanged={isVideosChanged(props.value.videos, props.initial?.videos)}
                    errors={errors?.videos ?? sourceWarnings?.videos}>
                    <SlideListEditor
                        addInputId="videos"
                        values={props.value.videos.length === 0
                            ? (props.ioLocale?.videos ?? [])
                            : props.value.videos}
                        add={addVideo}
                        remove={removeVideo}
                        render={renderVideo}
                        placeholder="Add video url"
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("screenshots")}
                    label={ioProperty("Screenshots", props.value.screenshots.length === 0)}
                    fieldId="screenshots"
                    isChanged={isScreenshotsChanged(props.value.screenshots, props.initial?.screenshots)}
                    errors={errors?.screenshots ?? sourceWarnings?.screenshots}>
                    <SlideListEditor
                        addInputId="screenshots"
                        values={props.value.screenshots.length === 0
                            ? (props.ioLocale?.screenshots ?? [])
                            : props.value.screenshots}
                        add={addScreenshot}
                        remove={removeScreenshot}
                        render={renderImage}
                        invalid={(errors?.screenshots?.length ?? 0) > 0}
                        disabled={props.saving} />
                </EditField>
                <EditField fullWidth={true}>
                    <h2 style={{ display: "flex", justifyContent: "center", padding: "1rem" }}>Downloader Video Options</h2>
                </EditField>
                <EditField
                    ref={register("downloaderVideoOptions")}
                    label="Downloader Video"
                    fieldId="downloaderVideo"
                    isChanged={props.value.downloaderVideoOptions?.videoUrl !== props.initial?.downloaderVideoOptions?.videoUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.downloaderVideoOptions?.videoUrl}
                    errors={errors?.downloaderVideoOptions ?? sourceWarnings?.downloaderVideoOptions}>
                    <VideoSelector
                        setInputId="downloaderVideo"
                        value={props.value.downloaderVideoOptions?.videoUrl ?? ""}
                        set={setDownloaderVideo}
                        render={renderSingleVideo}
                        delete={setDownloaderVideo}
                        deleteButtonHidden={props.value.downloaderVideoOptions?.videoUrl ? false : true}
                        videoDimensions={{ width: "12rem", height: "12rem" }}
                        disabled={props.saving} />
                </EditField>
                {props.value.downloaderVideoOptions?.videoUrl
                    ?
                    (<EditField>
                        <StyledInputBlock>
                            <span>Location X</span>
                            <Input
                                min={0}
                                type="number"
                                id="locationX"
                                value={props.value.downloaderVideoOptions?.locationX}
                                onChange={setFromInput(setLocationX)}
                                disabled={props.saving}
                            />
                        </StyledInputBlock>
                        <StyledInputBlock>
                            <span>Location Y</span>
                            <Input
                                min={0}
                                type="number"
                                id="locationY"
                                value={props.value.downloaderVideoOptions?.locationY}
                                onChange={setFromInput(setLocationY)}
                                disabled={props.saving}
                            />
                        </StyledInputBlock>
                        <StyledInputBlock>
                            <span>Width</span>
                            <Input
                                min={1}
                                type="number"
                                id="width"
                                value={props.value.downloaderVideoOptions?.width}
                                onChange={setFromInput(setVideoWidth)}
                                disabled={props.saving}
                            />
                        </StyledInputBlock>
                        <StyledInputBlock>
                            <span>Height</span>
                            <Input
                                min={1}
                                type="number"
                                id="height"
                                value={props.value.downloaderVideoOptions?.height}
                                onChange={setFromInput(setVideoHeight)}
                                disabled={props.saving}
                            />
                        </StyledInputBlock>
                    </EditField>)
                    :
                    <EditField>
                        <div style={{ display: "block", textAlign: "center", margin: "0 auto", marginTop: "8.25rem" }}>[no video options]</div>
                    </EditField>}
            </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <FormGroup>
                        <Button
                            type="button"
                            color="danger"
                            className="mr-2"
                            title={props.isLastLocalization ? "Unable to remove last localization." : undefined}
                            disabled={props.isLastLocalization || props.saving}
                            onClick={props.remove}>
                            Remove localization
                        </Button>
                        <LoadingButton
                            loading={props.saving}
                            loadingText={"Saving..."}
                            position="end"
                            type="submit"
                            color="primary">
                            {props.isNewApp ? "Add" : "Save"} application
                        </LoadingButton>
                    </FormGroup>
                </Col>
            </Row>
        </Form >
    );
});
