import React, { useMemo } from "react";
import { Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import styled from "styled-components";
import { FormLabel } from "src/shared/components";
import { availableLanguages } from "src/shared/helpers";

interface Props {
    currentLanguage: string;
    languages: string[];
    removedLanguages: string[];
    changedLanguages: string[];
    setLanguage: (language: string) => void;
    addLanguage: (language: string) => void;
}

const NavItemHelp = styled(NavItem)`
    cursor: help;
`;

export const LocalizationSelector = (props: Props) => {
    const makeSetLang = (l: string) => () => props.setLanguage(l);
    const makeAddLang = (l: string) => () => props.addLanguage(l);
    const langsToAdd = useMemo(
        () => availableLanguages.filter(l =>
            props.languages.indexOf(l.value) === -1 && props.removedLanguages.indexOf(l.value) === -1),
        [props.languages, props.removedLanguages]);
    const getLangName = (value: string) =>
        availableLanguages.find(l => l.value === value)?.name;

    return (
        <Nav tabs>
            {props.languages.map((lang) => {
                const langName = getLangName(lang);
                const title = langName === undefined ? "Unknown language" : undefined;
                const name = langName ?? <i>[{lang}]</i>;
                const isChanged = props.changedLanguages.indexOf(lang) !== -1;
                return (
                    <NavItem key={lang} title={title}>
                        <NavLink
                            href="#"
                            active={lang === props.currentLanguage}
                            onClick={makeSetLang(lang)}>
                            <FormLabel isChanged={isChanged}>
                                {name}
                            </FormLabel>
                        </NavLink>
                    </NavItem>
                );
            })}
            {(langsToAdd.length > 0 || props.removedLanguages.length > 0) &&
                <UncontrolledDropdown nav>
                    <DropdownToggle nav>+</DropdownToggle>
                    <DropdownMenu>
                        {langsToAdd.length > 0 &&
                            <React.Fragment>
                                <DropdownItem header>Add</DropdownItem>
                                {langsToAdd.map(lang =>
                                    <DropdownItem
                                        key={lang.name}
                                        onClick={makeAddLang(lang.value)}>
                                        {lang.name}
                                    </DropdownItem>)}
                            </React.Fragment>}
                        {props.removedLanguages.length > 0 &&
                            <React.Fragment>
                                <DropdownItem header>Restore</DropdownItem>
                                {props.removedLanguages.map(lang => {
                                    const name = getLangName(lang)
                                        ?? <i>[{lang}]</i>;
                                    return (
                                        <DropdownItem
                                            key={lang}
                                            onClick={makeAddLang(lang)}>
                                            {name}
                                        </DropdownItem>
                                    );
                                })}
                            </React.Fragment>}
                    </DropdownMenu>
                </UncontrolledDropdown>}
            {props.removedLanguages.map((lang) => {
                const name = getLangName(lang) ?? <i>[{lang}]</i>;
                return (
                    <NavItemHelp key={lang} title="Deleted language">
                        <NavLink href="#" disabled>
                            <FormLabel isChanged={true}>{name}</FormLabel>
                        </NavLink>
                    </NavItemHelp>
                );
            })}
        </Nav>
    );
};