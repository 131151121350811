import { AdCampaign } from "src/shared/dtos";
import { deepEqual } from "src/shared/helpers";

export type AdCampaignState = {
    current?: AdCampaign,
    initial?: AdCampaign,
    changed: boolean
};

export type AdCampaignAction =
    { kind: "set", value: AdCampaign, initialValue?: AdCampaign } |
    { kind: "update", value: Partial<AdCampaign> };

export const adCampaignReducer = (state: AdCampaignState, action: AdCampaignAction) => {
    switch (action.kind) {
        case "set": {
            state = {
                current: action.value,
                initial: action.initialValue,
                changed: false
            };
            break;
        }
        case "update": {
            if (state.current === undefined){
                break;
            }
            Object.assign(state.current, action.value);
            state.changed = !deepEqual(state.current, state.initial);
            break;
        }
    }

    return Object.assign({}, state);
};

export const adCampaignReducerDefault: AdCampaignState = {
    current: undefined,
    initial: undefined as unknown as AdCampaign,
    changed: false
};