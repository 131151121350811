import React from "react";
import styled from "styled-components";

const EmptyBlock = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface EmptyProps {
    loading: boolean;
    baseEmptyRenderer?: React.FC | React.ReactNode | JSX.Element;
}

export const Empty = (props: EmptyProps) => {
    if (props.loading) {
        return null;
    }

    const BaseEmpty = props.baseEmptyRenderer! as React.FC;
    return props.baseEmptyRenderer
        ? <BaseEmpty />
        : <EmptyBlock>No data available</EmptyBlock>;
};