import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon = styled(FontAwesomeIcon).attrs({ icon: ["fab", "google"] })`
    cursor: help;
    color: #007bff;
    font-size: 0.75em;
    vertical-align: top;
    margin-left: 2px;
`;

const Label = (props: { text: string, isInherited: boolean }) =>
    <React.Fragment>
        {props.text}
        {props.isInherited && <Icon title={"This property is inherited from Google Application."} />}
    </React.Fragment>;

export const googleProperty = (text: string, isInherited: boolean) =>
    <Label text={text} isInherited={isInherited} />;
