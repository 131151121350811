import React, { useMemo, forwardRef, useImperativeHandle } from "react";
import { Form, Input } from "reactstrap";
import { Category, IoApplication, GamestoreIoApplication, Tag } from "src/shared/dtos";
import { SelectOption, setFromInput, useValidate, ValidateAsyncRef } from "src/shared/helpers";
import { HorizontalField, LoadingButton, Select, overriddenProperty } from "src/shared/components";
import { CategorySelectOption, mapCategorySelectOption } from "src/shared/helpers/mapCategorySelectOption";
import styled from "styled-components";
import { IoAppMainInfo } from "./ioApplicationReducer";
import { applicationValidationSchema } from "./validationSchema";
import { CategoryType } from "src/shared/CategoryType";

const StyledInput = styled(Input) <{ exists: boolean }>`
    background: ${props => props.exists ? "lightyellow" : ""};

    :focus {
        background: ${props => props.exists ? "lightyellow" : ""};
    }
`;
interface Props {
    value: IoAppMainInfo;
    initial?: IoAppMainInfo;
    isNewApp: boolean;
    categories: Category[];
    tags: Tag[];
    gamestoreApp: GamestoreIoApplication;
    setIoApplication: React.Dispatch<IoApplication>;
    update: React.Dispatch<Partial<IoAppMainInfo> | undefined>;
    saving: boolean;
    onSubmit: () => void;
}

export const EditForm = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {

    const { validate, errors, register } = useValidate({ schema: applicationValidationSchema, value: props.value });

    useImperativeHandle(ref, () => ({
        validate: async () => (await validate(true)).isValid
    }), [validate]);

    const categoryOptions = useMemo(
        () => props.categories
            .filter(c => c.parentId === CategoryType.Io && !c.isVirtual)
            .map(c => mapCategorySelectOption(c, props.categories)),
        [props.categories]);

    const selectedCategoryOptions = useMemo(() => {
        const category = props.categories.find(c => c.id === props.value.categoryId);
        return category !== undefined ? mapCategorySelectOption(category, props.categories) : undefined;
    }, [props.value.categoryId, props.categories, props.value]);

    const tagOptions = useMemo(
        () => props.tags
            .map(t => ({
                label: t.name,
                value: t.id
            }) as SelectOption<string>),
        [props.tags]);

    const selectedTagOptions = useMemo(
        () => tagOptions.filter(t => props.value.tagsIds.includes(t.value)),
        [props.value.tagsIds, tagOptions]);

    const originalTagsValue = useMemo(
        () => {
            const tags = props.initial?.tagsIds;
            return (tags !== undefined && tags.length > 0) ? tags.join(", ") : "No Value";
        },
        [props.initial?.tagsIds]);

    const setAppId = (v: string) => props.update({ id: v ? v : undefined });
    const setRating = (v: number) => props.update({ rating: v ? v : undefined });
    const setCategory = (v: CategorySelectOption) => props.update({ categoryId: v === null ? "" : v.value });
    const setTags = (v: SelectOption<string>[]) => props.update({ tagsIds: v?.length > 0 ? v.map(c => c.value) : [] });
    const setDeveloper = (v: string) => props.update({ developer: v ? v : undefined });
    const setSourceProvider = (v: string) => props.update({ sourceProvider: v ? v : undefined });

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <Form onSubmit={save} noValidate>
            <HorizontalField className="text-right">
                <LoadingButton
                    loading={props.saving}
                    loadingText="Saving..."
                    position="end"
                    type="submit"
                    color="primary"
                    className="ml-2 mb-2">
                    {props.isNewApp ? "Add" : "Save"} application
                </LoadingButton>
            </HorizontalField>
            {props.isNewApp &&
                <React.Fragment>
                    <HorizontalField
                        ref={register("id")}
                        label="Application ID"
                        fieldId="appId"
                        errors={errors?.id}>
                        <Input
                            id="appId"
                            type="text"
                            value={props.value.id ?? ""}
                            onChange={setFromInput(setAppId)}
                            disabled={props.saving}
                        />
                    </HorizontalField>
                </React.Fragment>}
            <HorizontalField
                label="Developer name"
                fieldId="developer">
                <Input
                    id="developer"
                    type="text"
                    value={props.value.developer ?? ""}
                    onChange={setFromInput(setDeveloper)}
                    disabled={props.saving}
                />
            </HorizontalField>
            <HorizontalField
                label="Source Provider"
                ref={register("sourceProvider")}
                fieldId="sourceProvider"
                errors={errors?.sourceProvider}>
                <Input
                    id="sourceProvider"
                    type="text"
                    value={props.value.sourceProvider ?? ""}
                    onChange={setFromInput(setSourceProvider)}
                    disabled={props.saving}
                />
            </HorizontalField>
            <HorizontalField
                label={overriddenProperty("Rating", props.gamestoreApp?.rating !== undefined)}
                fieldId="rating"
                isChanged={props.value.rating !== props.initial?.rating}
                originalValue={props.isNewApp ? 0 : props.initial?.rating}>
                <StyledInput
                    className="form-control"
                    exists={props.gamestoreApp?.rating !== undefined ? 1 : 0}
                    type="number"
                    id="rating"
                    min="0"
                    max="5"
                    step="0.01"
                    value={props.value.rating ?? 0}
                    placeholder={props.value.rating + ""}
                    onChange={setFromInput(setRating)}
                    disabled={props.saving}
                />
            </HorizontalField>
            <HorizontalField
                ref={register("category")}
                label={overriddenProperty("Category", (props.gamestoreApp?.categories?.length ?? 0) !== 0)}
                fieldId="categories"
                isChanged={props.value.categoryId !== props.initial?.categoryId}
                originalValue={props.initial?.categoryId}
                errors={errors?.category}>
                <Select
                    classNamePrefix="inner"
                    exists={(props.gamestoreApp?.categories?.length ?? 0) !== 0}
                    id="categories"
                    value={selectedCategoryOptions}
                    onChange={setCategory}
                    placeholder={props.value.category.name}
                    options={categoryOptions}
                    isMulti={false}
                    isSearchable={true}
                    isDisabled={props.saving}
                    isInvalid={(errors?.category?.length ?? 0) > 0}
                />
            </HorizontalField>
            <HorizontalField
                ref={register("tags")}
                label={overriddenProperty("Tags", (props.gamestoreApp?.tags?.length ?? 0) !== 0)}
                fieldId="tags"
                isChanged={props.value.tagsIds?.join("") !== props.initial?.tagsIds?.join("")}
                originalValue={originalTagsValue}
                errors={errors?.tagsIds}>
                <Select
                    classNamePrefix="inner"
                    exists={(props.gamestoreApp?.tagIds?.length ?? 0) !== 0}
                    id="tags"
                    value={selectedTagOptions}
                    onChange={setTags}
                    options={tagOptions}
                    isMulti={true}
                    isSearchable={true}
                    isDisabled={props.saving}
                    isInvalid={(errors?.tagsIds?.length ?? 0) > 0}
                />
            </HorizontalField>
        </Form>
    );
});
