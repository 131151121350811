import React, { useCallback, forwardRef, useImperativeHandle, useMemo } from "react";
import { StyledInput } from "./EditForm";
import { GamestoreApplication, GoogleAdditionalFile, GoogleApkDetails } from "src/shared/dtos";
import { FormLabel, HorizontalField } from "src/shared/components";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { setFromInput, useValidate, ValidateAsyncRef } from "src/shared/helpers";
import { PlatformDetailsItem } from "./platformDetailsReducer";
import { AdditionalFilesEditor } from "src/shared/AdditionalFilesEditor";
import { apkDetailsValidationSchema } from "./validationSchema";

const toDateTime = (value?: string): string => {
    if (!value) {
        return "[not defined]";
    }

    const date = new Date(value);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

interface Props {
    value: PlatformDetailsItem;
    initial?: PlatformDetailsItem;
    updateValues: React.Dispatch<Partial<GoogleApkDetails> | undefined>;
    updateFiles: React.Dispatch<GoogleAdditionalFile[]>;
    saving: boolean;
    isNewApp: boolean;
    onSubmit: () => void;
    gamestoreApp: GamestoreApplication;
}

export const EditPlatformDetails = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const { value, initial } = props;
    const setVersionName = (v: string) => props.updateValues({ versionName: v });
    const setVersionCode = (v: number) => props.updateValues({ versionCode: v ? v : undefined });
    const setApkDownloadUrl = (v: string) => props.updateValues({ apkDownloadUrl: v ? v : undefined});
    const setApkDownloadSize = (v: number) => props.updateValues({ apkDownloadSize: v ? v : undefined });
    const setEnabled = (v: boolean) => props.updateValues({ enabled: v });
    const setAdditionalFiles = (v: GoogleAdditionalFile[]) => props.updateFiles(v);

    const { validate, errors, register, resetField } = useValidate({ schema: apkDetailsValidationSchema, value });

    useImperativeHandle(ref, () => ({
        validate: async () => (await validate(true)).isValid
    }), [validate]);

    const onAdditionalFilesEditorFocus = useCallback(() => {
        resetField("additionalFiles");
    }, [resetField]);

    const isAddFilesChanged = useMemo(
        () => value.additionalFiles?.join() !== initial?.additionalFiles.join(),
        [value.additionalFiles, initial?.additionalFiles]);

    const addFiles = useCallback(
        (item: Omit<GoogleAdditionalFile, "platform">) => {
            const newItem: GoogleAdditionalFile = { ...item, platform: props.value.platform };
            const files = props.value.additionalFiles.concat(newItem);
            setAdditionalFiles(files);

        }, [props.value.additionalFiles, props.value.platform]);

    const removeFiles = useCallback(
        (item: GoogleAdditionalFile) => {
            const files = props.value.additionalFiles.filter(i => i !== item);
            setAdditionalFiles(files);
        }, [props.value.additionalFiles]);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    const isVersionOverridden = useMemo(() => {
        const gsApkDetails = props.gamestoreApp?.apkDetails?.find(a => a.platform === props.value.platform);
        if (gsApkDetails === undefined) {
            return 0;
        }
        return gsApkDetails.versionName !== undefined ? 1 : 0;
    },
        [props.gamestoreApp, props.value.platform]);

    return (
        <Form onSubmit={save} noValidate>
            <HorizontalField>
                <FormGroup check>
                    <Label for="isEnabled" check>
                        <Input
                            type="checkbox"
                            id="isEnabled"
                            checked={value.enabled}
                            onChange={setFromInput(setEnabled)}
                            disabled={props.saving}
                        />{" "}
                        <FormLabel
                            isChanged={props.value.enabled !== props.initial?.enabled}
                            originalValue={initial?.enabled}>
                            Enabled
                        </FormLabel>
                    </Label>
                </FormGroup>
            </HorizontalField>
            <HorizontalField label="Apk Updated At" className="align-items-center">
                {toDateTime(value.updatedAt)}
            </HorizontalField>
            <HorizontalField
                label="Version name"
                fieldId="versionName"
                isChanged={value.versionName !== initial?.versionName}
                originalValue={props.isNewApp ? undefined : initial?.versionName}>
                <StyledInput
                    className="form-control"
                    exists={isVersionOverridden}
                    type="text"
                    id="versionName"
                    value={value.versionName ?? ""}
                    placeholder={value.versionName}
                    onChange={setFromInput(setVersionName)}
                    disabled={props.saving}
                />
            </HorizontalField>
            <HorizontalField
                label="Version code"
                fieldId="versionCode"
                isChanged={value.versionCode !== initial?.versionCode}
                originalValue={props.isNewApp ? 0 : initial?.versionName}>
                <Input
                    type="number"
                    id="versionCode"
                    value={value.versionCode ?? 0}
                    placeholder={value.versionCode + ""}
                    onChange={setFromInput(setVersionCode)}
                    disabled={props.saving}
                />
            </HorizontalField>
            <HorizontalField
                ref={register("apkDownloadUrl")}
                label="APK Download URL"
                fieldId="apkDownloadUrl"
                isChanged={value.apkDownloadUrl !== initial?.apkDownloadUrl}
                originalValue={props.isNewApp ? undefined : initial?.apkDownloadUrl}
                errors={errors?.apkDownloadUrl}>
                <Input
                    type="text"
                    id="apkDownloadUrl"
                    invalid={errors?.apkDownloadUrl && errors.apkDownloadUrl.length > 0}
                    value={value.apkDownloadUrl ?? ""}
                    placeholder={value.apkDownloadUrl}
                    onChange={setFromInput(setApkDownloadUrl)}
                    disabled={props.saving}
                />
            </HorizontalField>
            <HorizontalField
                label="APK Download size"
                fieldId="apkDownloadSize"
                isChanged={value.apkDownloadSize !== initial?.apkDownloadSize}
                originalValue={props.isNewApp ? 0 : initial?.apkDownloadSize}>
                <Input
                    type="number"
                    id="apkDownloadSize"
                    value={value.apkDownloadSize ?? 0}
                    placeholder={value.apkDownloadSize + ""}
                    onChange={setFromInput(setApkDownloadSize)}
                    disabled={props.saving}
                />
            </HorizontalField>
            <HorizontalField
                ref={register("additionalFiles")}
                label="Additional files"
                fieldId="additionalFiles"
                isChanged={isAddFilesChanged}
                errors={errors?.additionalFiles}>
                <AdditionalFilesEditor
                    mainInputId="additionalFiles"
                    items={value.additionalFiles}
                    add={addFiles}
                    remove={removeFiles}
                    invalid={errors?.additionalFiles && errors.additionalFiles.length > 0}
                    disabled={props.saving}
                    onFocus={onAdditionalFilesEditorFocus}
                />
            </HorizontalField>
        </Form>);
});