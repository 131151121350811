import React, { useState, useEffect, useCallback } from "react";
import { BrowserRouter, Redirect, } from "react-router-dom";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavLink as BsNavLink,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Dropdown
} from "reactstrap";
import styled, { ThemeProvider } from "styled-components";
import { ResponseStatus } from "@servicestack/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlaystoreTheme } from "src/DefaultTheme";
import { GlobalStyles } from "src/GlobalStyles";
import { client } from "src/shared/client";
import { Route, RouteSwitch, RouteLink, ErrorModal } from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { routes, IRoute } from "src/shared/routes";
import { NotificationContainer } from "src/shared/components/Notification";
import { useBootstrapBreakpoint } from "./shared/helpers";
import ConfirmModal from "./shared/components/confirmWindow/ConfirmModal";
import { Container as ModalContainer } from "react-modal-promise";

const AppBoxContainer = styled.div<{ isMobile: boolean }>`
    height: ${props => props.isMobile ? "200%" : "100%"};
`;

const mobileBreakpoints = ["xs", "sm", "md"];
const AppBox = (props: React.PropsWithChildren<{}>) => {
    const breakpoint = useBootstrapBreakpoint();
    const isMobile = mobileBreakpoints.indexOf(breakpoint ?? "") !== -1;

    return <AppBoxContainer isMobile={isMobile}>{props.children}</AppBoxContainer>;
};

export const App = () => {
    const [user, setUser] = useState(client.getCurrentUser());

    const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);
    const toggleNavMenu = () => setIsNavMenuOpen(v => !v);
    const closeNavMenu = () => setIsNavMenuOpen(false);

    const [isAppsDropdownOpen, setIsAppsDropdownOpen] = useState(false);
    const toggleAppsDropdown = () => setIsAppsDropdownOpen(v => !v);
    const closeAppsDropdown = useCallback(() => {
        if (isNavMenuOpen && isAppsDropdownOpen) {
            setIsNavMenuOpen(false);
        }
        setIsAppsDropdownOpen(false);
    }, [isNavMenuOpen, isAppsDropdownOpen]);

    const [isSettingsDropdownOpen, setIsSettingsDropdownOpen] = useState(false);
    const toggleSettingsDropdown = () => setIsSettingsDropdownOpen(v => !v);
    const closeSettingsDropdown = useCallback(() => {
        if (isNavMenuOpen && isSettingsDropdownOpen) {
            setIsNavMenuOpen(false);
        }
        setIsSettingsDropdownOpen(false);
    }, [isNavMenuOpen, isSettingsDropdownOpen]);

    const [isAdDropdownOpen, setIsAdDropdownOpen] = useState(false);
    const toggleAdDropdown = () => setIsAdDropdownOpen(v => !v);
    const closeAdDropdown = useCallback(() => {
        if (isNavMenuOpen && isAdDropdownOpen) {
            setIsNavMenuOpen(false);
        }
        setIsAdDropdownOpen(false);
    }, [isNavMenuOpen, isAdDropdownOpen]);

    const [isStatisticDropdownOpen, setIsStatisticDropdownOpen] = useState(false);
    const toggleStatisticDropdown = () => setIsStatisticDropdownOpen(v => !v);
    const closeStatisticDropdown = useCallback(() => {
        if (isNavMenuOpen && isStatisticDropdownOpen) {
            setIsNavMenuOpen(false);
        }
        setIsStatisticDropdownOpen(false);
    }, [isNavMenuOpen, isStatisticDropdownOpen]);


    useEffect(() => {
        client.onUserLoggedIn(u => setUser(u));
        client.onUserLoggedOut(() => setUser(null));

        if (user !== null) {
            client.checkSession();
        }
    }, []);

    const [error, setError] = useState<ResponseStatus | undefined>(undefined);
    const closeError = () => { setError(undefined); };
    client.onError(e => setError(e.responseStatus));

    const logout = useCallback(() => client.logout(), []);

    return (
        <BrowserRouter>
            <GlobalStyles />
            <ThemeProvider theme={PlaystoreTheme}>
                <AppBox>
                    <VerticalBox>
                        <ErrorModal error={error} closeError={closeError} />
                        <ConfirmModal />
                        <ModalContainer />
                        <NotificationContainer />
                        <Header>
                            <Navbar color="dark" dark expand="lg">
                                <NavbarBrand tag={RouteLink} to={routes.home}>
                                    Playstore Admin
                                </NavbarBrand>
                                {user !== null &&
                                    <NavbarToggler onClick={toggleNavMenu} />}
                                <Collapse isOpen={isNavMenuOpen} navbar>
                                    {user !== null &&
                                        <React.Fragment>
                                            <Dropdown className="navbar-nav" isOpen={isAppsDropdownOpen} toggle={toggleAppsDropdown} inNavbar>
                                                <DropdownToggle nav caret>
                                                    Applications
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.applications}
                                                        className="dropdown-item"
                                                        onClick={closeAppsDropdown}
                                                    >
                                                        Gamestore
                                                    </RouteLink>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.googleApplications}
                                                        className="dropdown-item"
                                                        onClick={closeAppsDropdown}
                                                    >
                                                        Google Play
                                                    </RouteLink>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.gamestoreIoApplications}
                                                        className="dropdown-item"
                                                        onClick={closeAppsDropdown}
                                                    >
                                                        IO Gamestore
                                                    </RouteLink>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.ioApplications}
                                                        className="dropdown-item"
                                                        onClick={closeAppsDropdown}
                                                    >
                                                        IO Games
                                                    </RouteLink>
                                                </DropdownMenu>
                                            </Dropdown>
                                            <Nav navbar>
                                                <RouteLink user={user} to={routes.featuredApplications} onClick={closeNavMenu} nav>
                                                    Featured Applications
                                                </RouteLink>
                                            </Nav>
                                            <Nav navbar>
                                                <RouteLink user={user} to={routes.categories} onClick={closeNavMenu} nav>
                                                    Categories
                                                </RouteLink>
                                            </Nav>
                                            <Nav navbar>
                                                <RouteLink user={user} to={routes.tags} onClick={closeNavMenu} nav>
                                                    Tags
                                                </RouteLink>
                                            </Nav>
                                            <Dropdown className="navbar-nav" isOpen={isSettingsDropdownOpen} toggle={toggleSettingsDropdown} inNavbar>
                                                <DropdownToggle nav caret>
                                                    Settings
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.sourceSettings}
                                                        className="dropdown-item"
                                                        onClick={closeSettingsDropdown}
                                                    >
                                                        Source Settings
                                                    </RouteLink>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.productVersions}
                                                        onClick={closeSettingsDropdown}
                                                        className="dropdown-item"
                                                    >
                                                        Product Versions
                                                    </RouteLink>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.crawlingGoogleApplications}
                                                        onClick={closeSettingsDropdown}
                                                        className="dropdown-item"
                                                    >
                                                        Crawling Google Apps
                                                    </RouteLink>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.ioCrawlingResults}
                                                        onClick={closeSettingsDropdown}
                                                        className="dropdown-item"
                                                    >
                                                        Crawling Io Apps
                                                    </RouteLink>
                                                </DropdownMenu>
                                            </Dropdown>
                                            <Dropdown className="navbar-nav" isOpen={isAdDropdownOpen} toggle={toggleAdDropdown} inNavbar>
                                                <DropdownToggle nav caret>
                                                    Ads
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.adCampaigns}
                                                        className="dropdown-item"
                                                        onClick={closeAdDropdown}
                                                    >Ad Campaigns</RouteLink>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.adUnits}
                                                        className="dropdown-item"
                                                        onClick={closeAdDropdown}
                                                    >Ad Units</RouteLink>
                                                    <DropdownItem divider />
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.adBrowserExtensions}
                                                        className="dropdown-item"
                                                        onClick={closeAdDropdown}
                                                    >Extensions</RouteLink>
                                                </DropdownMenu>
                                            </Dropdown>
                                            <Dropdown className="navbar-nav" isOpen={isStatisticDropdownOpen} toggle={toggleStatisticDropdown} inNavbar>
                                                <DropdownToggle nav caret>
                                                    Statistics
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.requestStatistics}
                                                        className="dropdown-item"
                                                        onClick={closeStatisticDropdown}
                                                    >
                                                        Request Statistics
                                                    </RouteLink>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.gamingStatistics}
                                                        className="dropdown-item"
                                                        onClick={closeStatisticDropdown}
                                                    >
                                                        Gaming Minutes Statistics
                                                    </RouteLink>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.clickInfoStatistics}
                                                        className="dropdown-item"
                                                        onClick={closeStatisticDropdown}
                                                    >
                                                        Click Info Statistics
                                                    </RouteLink>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.activeInstallsStatistics}
                                                        className="dropdown-item"
                                                        onClick={closeStatisticDropdown}
                                                    >
                                                        Active Users Statistics
                                                    </RouteLink>
                                                    <RouteLink
                                                        user={user}
                                                        to={routes.playstoreActivityStatistics}
                                                        className="dropdown-item"
                                                        onClick={closeStatisticDropdown}
                                                    >
                                                        Playstore Activity Statistics
                                                    </RouteLink>
                                                </DropdownMenu>
                                            </Dropdown>
                                            <Nav navbar>
                                                <RouteLink user={user} to={routes.users} onClick={closeNavMenu} nav>
                                                    Users
                                                </RouteLink>
                                            </Nav>
                                            <Nav className="ml-auto" navbar>
                                                <RouteLink
                                                    user={user}
                                                    to={{ route: routes.changePassword, args: { id: user.userId } }}
                                                    nav>
                                                    {user.displayName}
                                                </RouteLink>
                                            </Nav>
                                            <Nav navbar>
                                                <BsNavLink href="#" onClick={logout}>
                                                    <span className="d-inline d-sm-inline d-md-none">
                                                        Logout{" "}
                                                    </span>
                                                    <FontAwesomeIcon icon="sign-out-alt" />
                                                </BsNavLink>
                                            </Nav>
                                        </React.Fragment>}
                                </Collapse>
                            </Navbar>
                        </Header>
                        <Content>
                            <RouteSwitch>
                                {Object.keys(routes).map(name => {
                                    const route = routes[name] as IRoute<unknown>;
                                    return <Route key={name} route={route} user={user} />;
                                })}
                                <Redirect from="*" to={routes.home.url()} />
                            </RouteSwitch>
                        </Content>
                    </VerticalBox>
                </AppBox>
            </ThemeProvider>
        </BrowserRouter>);
};
