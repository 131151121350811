import React, { FC } from "react";

export type LocalizationsFailedFields = { lang: string, fields: string[] };

export const ConfirmFailedLocalizationSources: FC<{ failedFields: LocalizationsFailedFields[] }> = ({ failedFields }) => (
    <div>
        <p>Some of media sources in localizations can't be found. Check following urls:</p>
        {failedFields.map(v =>
            <p key={v!.lang}>
                {v!.lang} localization: {v!.fields.join(", ")}
            </p>)}
        <p>Do you want to save application anyway?</p>
    </div>
);

export const ConfirmFailedSources: FC<{ failedFields: string[] }> = ({ failedFields }) => (
    <div>
        <p>Some of media sources can't be found.</p>
        <p>Check following urls: {failedFields.join(", ")}</p>
        <p>Do you want to save application anyway?</p>
    </div>
);