import { IoApplication } from "src/shared/dtos";
import { deepEqual } from "src/shared/helpers";

export type IoAppMainInfo = Omit<IoApplication, "localizations">;

const getAppMainInfo = (app: IoApplication): IoAppMainInfo => {
    const { localizations, ...mainInfo } = app;
    return mainInfo;
};

export type IoApplicationState = {
    current?: IoAppMainInfo,
    initial?: IoAppMainInfo,
    changed: boolean
};

export type IoApplicationAction =
    { kind: "set", value: IoApplication, initialValue: IoApplication } |
    { kind: "update", value: Partial<IoAppMainInfo> };

export const ioApplicationReducer = (state: IoApplicationState, action: IoApplicationAction) => {
    switch (action.kind) {
        case "set": {
            state = {
                current: getAppMainInfo(action.value),
                initial: getAppMainInfo(action.initialValue),
                changed: false
            };
            break;
        }
        case "update": {
            if (!state.current) {
                break;
            }
            Object.assign(state.current, action.value);
            state.changed = !deepEqual(state.current, state.initial);
            break;
        }
    }
    return Object.assign({}, state);
};

export const ioApplicationReducerDefault: IoApplicationState = {
    current: undefined,
    initial: undefined,
    changed: false
};