import React, { FC, useState } from "react";
import { NavTabs } from "src/shared/components";
import { PageContainer } from "../../components";
import { Header } from "./Header";
import { SubCategoriesBar } from "./SubCategoriesBar";
import { ApplicationDetailsView } from "./ApplicationDetailsView";
import { ThemedProps } from "../../types";
import styled from "styled-components";

export const IoAppsPreviewBlock = styled.div`
    position: relative;
    width: 1240px;
    background-color: ${(p: ThemedProps) => p.theme.WindowBackColor};
    display: flex;
    flex-direction: column;
`;

interface Props {
    leftCornerIconUrl: string | undefined;
}

export const IoApplicationsView: FC<Props> = ({ leftCornerIconUrl }) => {
    const [page, setPage] = useState(0);

    return (
        <>
            <NavTabs links={["Details"]} page={page} setPage={setPage} />
            <PageContainer>
                <IoAppsPreviewBlock>
                    <Header leftCornerIconUrl={leftCornerIconUrl} />
                    <SubCategoriesBar />
                        {page === 0 && <ApplicationDetailsView />}
                </IoAppsPreviewBlock>
            </PageContainer>
        </>
    );
};
