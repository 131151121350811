import React, { useEffect, useState, useReducer, useMemo, useCallback, useRef, } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { BreadcrumbItem, Row, Col } from "reactstrap";
import { defer, noop } from "rxjs";
import { Category, CategoryApplicationsIds, EmulatorOption } from "src/shared/dtos";
import {
    Breadcrumb,
    ToolBox,
    ContentBox,
    LeavingViewProtector,
    Loader,
    DocumentTitle,
    RouteLink,
    RouteUserProps,
    EditError,
    EditErrorOptions,
    notifySuccess
} from "src/shared/components";
import { Content, Header, VerticalBox } from "src/shared/components/flex";
import { structuredClone, useFunctionState, useSubscription, ValidateAsyncRef } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { CategoryFormData, categoryReducer, categoryReducerDefault } from "./categoryReducer";
import api from "../api";
import { EditForm } from "./EditForm";

type Props = RouteComponentProps<{ id: string }> & RouteUserProps;

const makeEmptyCategory = (): Category => {
    return {
        id: "",
        parentId: undefined,
        name: "",
        displayName: "",
        description: "",
        backgroundImageUrl: undefined,
        order: 0,
        isActive: true,
        isVirtual: false,
        showInNavbar: true,
    };
};

export const EditView = withRouter(({ match, history, user }: Props) => {

    const editFormRef = useRef<ValidateAsyncRef>(null);

    const catId = match.params.id;
    const isNew = catId === undefined;

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);

    const [isNewCategorySaved, setIsNewCategorySaved] = useState(!isNew);
    const [categories, setCategories] = useState<Category[] | undefined>([]);
    const [categoryApplicationsIds, setCategoryApplicationsIds] = useState<CategoryApplicationsIds>(
        { googleApplicationsIds: [], ioApplicationsIds: [] });

    const [error, setError] = useState<EditErrorOptions | undefined>();
    const [saving, setSaving] = useState(false);
    const [category, changeCategory] = useReducer(categoryReducer, categoryReducerDefault);

    const [keyBindingSchemas, setKeyBindingSchemas] = useState<object[]>([]);

    const loadKeyBindings = useSubscription(() => defer(() => api.getKeyBindingsSchemas()).subscribe({
        next: result => {
            setKeyBindingSchemas([JSON.parse(result.camelCaseSchema), JSON.parse(result.pascalCaseSchema)]);
        },
    }), []);

    const loadApplicationsIds = useSubscription(
        (categoryId: string) => defer(() => api.getCategoryApplicationsIds(categoryId))
            .subscribe(setCategoryApplicationsIds),
        []);

    useEffect(() => {
        loadKeyBindings();
    }, []);

    const backToList = useCallback(() => history.push(routes.categories.url()), []);

    const showCategoryNotFoundError = () => setError({
        text: "Unable to load category.",
        actionText: "Back to list",
        action: backToList
    });

    const loadCategories = useSubscription((categoryId: string) => defer(() => api.list()).subscribe({
        next: result => {
            setCategories(result);
            if (categoryId === undefined) {
                setCategory(undefined);
                return;
            }
            const cat = result.find(c => c.id === catId);
            if (cat === undefined) {
                showCategoryNotFoundError();
                return;
            }
            setCategory(cat);
            loadApplicationsIds(cat.id);
        },
        error: showCategoryNotFoundError
    }), []);

    useEffect(() => {
        loadCategories(catId);
    }, [catId]);

    useEffect(() => {
        // Go to edit view after successful save of the new entity.
        // It is implemented this way to let leaving view protector know, that there is no changes.
        if (isNew && isNewCategorySaved && error === undefined) {
            history.push(routes.editCategory.url({ id: category.current!.id }));
        }
    }, [isNew, isNewCategorySaved, category.current]);

    const setCategory = (data?: Category) => {
        const value = data ?? makeEmptyCategory();

        const subscription = defer(() => structuredClone(value))
            .subscribe(clone => changeCategory({ kind: "set", value, initialValue: clone }));
        setUnsubscribe(() => subscription.unsubscribe());
    };

    const save = useCallback(async () => {
        if (saving || category.current === undefined) {
            return;
        }

        setSaving(true);
        const isEditFormValid = await editFormRef.current?.validate();
        if (!isEditFormValid) {
            setSaving(false);
            return;
        }

        const request: Category = {
            ...category.current,
            defaultKeyBindings: !category.current.defaultKeyBindings
                ? undefined
                : JSON.parse(category.current.defaultKeyBindings) as EmulatorOption
        };
        const subscription = defer(() => isNew ? api.create(request) : api.update(request)).subscribe({
            next: result => {
                notifySuccess(`Category ${isNew ? "added" : "saved"} successfully.`);
                setCategory(result);
                setSaving(false);

                if (isNew) {
                    setIsNewCategorySaved(true);
                }
            },
            error: () => {
                setSaving(false);
                setError({
                    text: "Unable to save Category.",
                    actionText: "Continue",
                    action: () => setError(undefined)
                });
            }
        });
        setUnsubscribe(() => subscription.unsubscribe());
    }, [category, category.current, isNew, saving, editFormRef.current]);

    const deleteCategory = useCallback(() => {
        if (saving || category.current === undefined) {
            return;
        }

        setSaving(true);
        const subscription = defer(() => api.delete(catId)).subscribe({
            next: () => {
                notifySuccess(`Category deleted successfully.`);
                setSaving(false);
                history.push(routes.categories.url());
            },
            error: () => {
                setSaving(false);
                setError({
                    text: "Unable to delete Category.",
                    actionText: "Continue",
                    action: () => setError(undefined)
                });
            }
        });
        setUnsubscribe(() => subscription.unsubscribe());
    }, [catId, category.current, isNew, saving]);

    const updateCategory = useCallback((value: Partial<CategoryFormData>) => changeCategory({ kind: "update", value }), []);

    const title = useMemo(
        () => {
            const categoryTitle = isNew
                ? "[new category]"
                : (category.current !== undefined
                    ? category.current.id
                    : "Loading...");
            return `Category - ${categoryTitle}`;
        },
        [category.current]);

    return (
        <VerticalBox>
            <DocumentTitle title={title} />
            <LeavingViewProtector showConfirmation={category.changed && (isNew ? !isNewCategorySaved : true)} />
            <Header>
                <ToolBox>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.categories}>
                                Categories
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>
                            {isNew ? "[new category]" : catId}
                        </BreadcrumbItem>
                    </Breadcrumb>
                </ToolBox>
            </Header>
            <Content>
                <ContentBox>
                    {error && <EditError error={error} />}
                    {!error && (category.current === undefined || categories === undefined) && <Loader />}
                    {!error && category.current !== undefined && categories !== undefined &&
                        <Row>
                            <Col
                                sm={{ offset: 1, size: 10 }}
                                md={{ offset: 2, size: 8 }}
                                lg={{ offset: 2, size: 6 }}
                                xl={{ offset: 3, size: 5 }}
                            >
                                <Row>
                                    <Col>
                                        <EditForm
                                            ref={editFormRef}
                                            value={category.current}
                                            initial={category.initial}
                                            categories={categories}
                                            update={updateCategory}
                                            saving={saving}
                                            onSubmit={save}
                                            onDelete={deleteCategory}
                                            keyBindingSchemas={keyBindingSchemas}
                                            categoryApplicationsIds={categoryApplicationsIds}
                                            user={user}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>}
                </ContentBox>
            </Content>
        </VerticalBox>
    );
});