// Solution taken from https://stackoverflow.com/a/37285344

/**
 * Make element visible in a scrollable container if it isn't.
 * @param container Container element.
 * @param element Source element.
 */
export const ensureInView = (container: HTMLElement, element: HTMLElement) => {
    // Determine container top and bottom.
    const cTop = container.scrollTop;
    const cBottom = cTop + container.clientHeight;

    // Determine element top and bottom.
    const eTop = element.offsetTop;
    const eBottom = eTop + element.clientHeight;

    // Check if out of view.
    if (eTop < cTop) {
        container.scrollTop -= (cTop - eTop);
    }
    else if (eBottom > cBottom) {
        container.scrollTop += (eBottom - cBottom);
    }
};