import HighchartsReact from "highcharts-react-official";
import React, { FC, useMemo, useRef, RefObject, useEffect } from "react";
import Highcharts, { Chart, Options as ChartOptions } from "highcharts";
import styled from "styled-components";

const StyledHighcharts = styled.div`
    height: 100%;
    padding: 12px;
    padding-bottom: 30px;
    display: flex;
    & > div  {
        flex: 1;
    }
    text.highcharts-credits {
        display:none !important;
    }

    .highcharts-container {
        height: calc(100% - 60px);
    }
`;

const ChartHeader = styled.p`
    text-align: center;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 14px;
`;

interface Props {
    startDate: Date;
    endDate: Date;
    resizeTrigger: boolean;
    chartOptions: ChartOptions | null;
}

export const ChartWidgetContent: FC<Props> = (props) => {
    const chartRef = useRef<{ chart: Chart; container: RefObject<HTMLDivElement> } | null>(null);

    const dates = useMemo<[string, string]>(
        () => [props.startDate.toLocaleDateString(), props.endDate.toLocaleDateString()],
        [props.startDate, props.endDate]);

    useEffect(() => {
        chartRef.current?.chart.reflow();
    }, [props.resizeTrigger, chartRef.current]);

    return (
        <>
            <ChartHeader>Statistics for dates: {dates[0]} - {dates[1]}</ChartHeader>
            {props.chartOptions !== null &&
                <StyledHighcharts>
                    <HighchartsReact
                        ref={chartRef}
                        highcharts={Highcharts}
                        options={props.chartOptions}
                    />
                </StyledHighcharts>}
        </>
    );
};