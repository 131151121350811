import React, { useState, useCallback, useMemo, useEffect } from "react";
import { BreadcrumbItem } from "reactstrap";
import { defer } from "rxjs";
import { IoApplicationSummary, IoApplicationListDataType, OrderDirection, Category, Tag } from "src/shared/dtos";
import { Breadcrumb, DocumentTitle, RouteLink, RouteUserProps, Loader, notifySuccess, notifyError } from "src/shared/components";
import {
    Column,
    DefaultCellRenderer,
    InfiniteTable,
    StringSearch,
    TopPanelRendererProps,
    TopPanelLeftContainer,
    LoadRequest,
    SearchOptions,
    multipleCategorySearchFactory,
    multiValueSearchFactory
} from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { Content, VerticalBox } from "src/shared/components/flex";
import {
    htmlToPlainText,
    deserializeQueryParameters,
    QueryParameterDescriptor,
    serializeQueryParameters,
    pushUrl,
    getGridVisibleColumns,
    useBootstrapBreakpoint,
    useSubscription,
} from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { LanguageSelector } from "src/shared/components/LanguageSelector";
import { Rating } from "src/shared/components/Rating";
import api, { ListRequest } from "../api";
import { CategoryType } from "src/shared/CategoryType";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { SuggestionTagMapper } from "src/shared/helpers/InfiniteTable";

const keyDataTypes: { [key: string]: IoApplicationListDataType } = {
    "appId": IoApplicationListDataType.Id,
    "title": IoApplicationListDataType.Title,
    "developer": IoApplicationListDataType.Developer,
    "rating": IoApplicationListDataType.Rating
};
const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType as IoApplicationListDataType);

function getSearchValueByKey<T>(key: string, searchOptions?: Array<SearchOptions<unknown>>): T {
    return searchOptions?.find(s => s.key === key)?.value as T;
}

const searchQueryParameterDescriptors: QueryParameterDescriptor[] = [
    { paramType: "language", paramName: "language", serializer: (value: string) => value === "en" ? undefined : value },
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    { paramType: "search", paramName: "title" },
    { paramType: "search", paramName: "appId" },
    { paramType: "search", paramName: "developer" },
    {
        paramType: "search",
        paramName: "category",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "tags",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    }
];

export const ListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [lang, tableFilters, sort] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...searchQueryParameterDescriptors);
        const languageParam = allParams.find(p => p.paramType === "language")?.value as string | undefined;
        const searchParams = allParams
            .filter(p => p.paramType === "search")
            .map(qp => ({ key: qp.paramName, value: qp.value } as SearchOptions<unknown>));
        const orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        const orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;

        return [languageParam, searchParams, { key: orderBy, order: orderDir } as SortOptions];
    }, [location.search]);

    const [visibleColumns, setVisibleColumns] = useState<string[] | "all" | undefined>();
    const [allCategories, setAllCategories] = useState<Category[] | undefined>();
    const [allTags, setAllTags] = useState<Tag[] | undefined>();
    const [items, setItems] = useState<IoApplicationSummary[]>([]);
    const [language, setLanguage] = useState("en");
    const breakpoint = useBootstrapBreakpoint();

    const allTagIds = useMemo(() => allTags?.map(t => t.id) ?? [], [allTags]);

    useEffect(() => setLanguage(lang ?? "en"), [lang]);

    const updateCategories = useSubscription(() => defer(
        () => api.getCategories()).subscribe(categories => {
            const validCategories = categories
                .filter(c => !c.isVirtual && (c.id === CategoryType.Io || c.parentId === CategoryType.Io));
            setAllCategories(validCategories);
        }), [api.getCategories]);

    const updateTags = useSubscription(() => defer(() => api.getTags())
        .subscribe(setAllTags), []);

    useEffect(() => {
        updateCategories();
        updateTags();
    }, []);

    const updateVisibleColumns = useSubscription(() => defer(() => getGridVisibleColumns("IoApplications"))
        .subscribe(columns => setVisibleColumns(columns ?? "all")), [getGridVisibleColumns]);

    useEffect(() => {
        updateVisibleColumns();
    }, [breakpoint, updateVisibleColumns]);

    const updateData = useSubscription((requestObj: ListRequest, isReload: boolean, resolve: (val: boolean) => void) => defer(() =>
        api.list(requestObj)).subscribe(result => {
            if (result.length > 0) {
                setItems(prev => isReload ? result : prev.concat(result));
            }
            resolve(result.length === 0);
        }), [api.list]);

    const load = useCallback(
        (request: LoadRequest) => {
            if (request.reload) {
                setItems([]);
            }

            const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : IoApplicationListDataType.Id;
            const searchRequest = {
                language,
                orderBy: orderByKey,
                orderDir: orderByKey !== undefined
                    ? request.sort?.order === SortOrder.DESC ? OrderDirection.Desc : OrderDirection.Asc
                    : undefined,
                appId: getSearchValueByKey<string>("appId", request.search),
                title: getSearchValueByKey<string>("title", request.search),
                developer: getSearchValueByKey<string>("developer", request.search),
                category: getSearchValueByKey<string[]>("category", request.search),
                tags: getSearchValueByKey<string[]>("tags", request.search),
            };

            const requestObj: ListRequest = {
                language: searchRequest.language,
                offset: request.offset,
                limit: request.limit,
                orderBy: searchRequest.orderBy,
                orderDirection: searchRequest.orderDir ?? OrderDirection.Asc,
                idSearch: searchRequest.appId,
                titleSearch: searchRequest.title,
                developerSearch: searchRequest.developer,
                categoriesSearch: searchRequest.category,
                tagsSearch: searchRequest.tags
            };

            const url = routes.ioApplications.url({
                searchParams: serializeQueryParameters(searchRequest, ...searchQueryParameterDescriptors)
            });
            pushUrl(location, history, url);

            return new Promise<boolean>(resolve => {
                updateData(requestObj, request?.reload, resolve);
            });
        }, [language, location, updateData]);

    const changeLanguage = useCallback(
        (value: string) => {
            setItems([]);
            setLanguage(value);
        }, [language]);

    const TopPanel = useMemo(() =>
        (props: TopPanelRendererProps) =>
            <props.blockElement>
                <TopPanelLeftContainer>
                    <Breadcrumb>
                        <BreadcrumbItem>
                            <RouteLink user={user} to={routes.home}>
                                Home
                            </RouteLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem active>Io Applications</BreadcrumbItem>
                    </Breadcrumb>
                </TopPanelLeftContainer>
                <RouteLink user={user} to={routes.addIoApplication} button className="mr-2">
                    Add Application
                </RouteLink>
                <LanguageSelector value={language} setValue={changeLanguage} className="mr-2" />
                <props.reloadElement />
                <props.optionsElement />
            </props.blockElement>, [language]);

    const iconRenderer = useMemo(() =>
        (props: CellProps<string | undefined, IoApplicationSummary>) =>
            <RouteLink
                user={user}
                to={{ route: routes.editIoApplication, args: { appId: props.rowData.id, language } }}>
                <img src={props.cellData ?? ""} alt={props.cellData ?? "Application Icon"} width={50} height={50} />
            </RouteLink>, [language]);

    const titleRenderer = useMemo(() =>
        (props: CellProps<unknown, IoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{ route: routes.editIoApplication, args: { appId: props.rowData.id, language } }}
                    className="d-inline">
                    {props.rowData.title}
                </RouteLink>
            );

            return <DefaultCellRenderer cellData={link} title={props.rowData.title} {...restProps} />;
        }, [language]);

    const appIdRenderer = useMemo(() =>
        (props: CellProps<string, IoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{ route: routes.editIoApplication, args: { appId: props.rowData.id, language } }}
                    className="d-inline">
                    {props.rowData.id}
                </RouteLink>
            );

            return <DefaultCellRenderer cellData={link} title={cellData} {...restProps} />;
        }, [language]);

    const developerRenderer = useMemo(() =>
        (props: CellProps<string | undefined, IoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const title = cellData ?? "";
            return <DefaultCellRenderer cellData={title} title={title} {...restProps} />;
        }, [language]);

    const ratingRenderer = useMemo(() =>
        (props: CellProps<number, IoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const rating = <Rating value={cellData} max={5} />;
            const title = `${cellData}`;

            return <DefaultCellRenderer cellData={rating} title={title} {...restProps} />;
        }, []);

    const categoryRenderer = useMemo(() =>
        (props: CellProps<Category, IoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const category = cellData;
            const title = `Parent ID: ${category.parentId}`;
            const value = category.name;
            return <DefaultCellRenderer cellData={value} title={title} {...restProps} />;
        }, [language]);

    const tagsRenderer = useMemo(() =>
        (props: CellProps<string[] | undefined, IoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            if (cellData === undefined) {
                return <DefaultCellRenderer cellData={""} title={"No tags"} {...restProps} />;
            }
            const appTags = allTags?.filter(t => cellData.includes(t.id)) ?? [];
            const value = (
                <React.Fragment>
                    {appTags?.map((t, i) => (
                        <span key={t.id} title={t.id}>
                            {t.name}
                            {appTags.length > 0 && i < appTags.length -1 && ", "}
                        </span>
                    ))}
                </React.Fragment>
            );
            return <DefaultCellRenderer cellData={value} {...restProps} />;
        }, [language, allTags]);

    const descriptionRenderer = useMemo(() =>
        (props: CellProps<string | undefined, IoApplicationSummary>) => {
            const { cellData, ...restProps } = props;
            const value = htmlToPlainText(cellData ?? "");
            return <DefaultCellRenderer cellData={value} title={value} {...restProps} />;
        }, [language]);

    const tagSuggestionMapper: SuggestionTagMapper<string> = useCallback(tagId => {
        const tag = allTags?.find(t => t.id === tagId);
        return {
            label: tag?.name ?? "",
            value: tag?.id ?? ""
        };
    }, [allTags]);

    const select = makeSelect<IoApplicationSummary>();

    const onOptionsClose = useCallback((visColumns: string[]) => {
        if (Array.isArray(visibleColumns) && visColumns.join("") === visibleColumns.join("")) {
            return;
        }
        if (breakpoint) {
            api.saveGridSettings(breakpoint, visColumns)
                .then(_ => {
                    notifySuccess("Columns settings saved successfully");
                    setVisibleColumns(visColumns);
                })
                .catch(_ => notifyError("Failed to save column settings"));
        }
    }, [breakpoint, visibleColumns]);

    return (
        <VerticalBox>
            <DocumentTitle title="Io Applications" />
            <Content overflowHidden>
                {(allCategories === undefined || visibleColumns === undefined || allTags === undefined) && <Loader />}
                {allCategories !== undefined && visibleColumns !== undefined && allTags !== undefined &&
                    <InfiniteTable
                        items={items}
                        load={load}
                        renderContext={language}
                        filters={tableFilters}
                        sortBy={sort}
                        topPanelRenderer={TopPanel}
                        visibleColumns={visibleColumns}
                        onOptionsClose={onOptionsClose}
                    >
                        <Column
                            key="icon"
                            dataGetter={select(a => a.iconUrl)}
                            width={70}
                            minWidth={70}
                            cellRenderer={iconRenderer}
                            resizable={false}
                            sortable={false}
                        />
                        <Column
                            key="title"
                            title="Title"
                            width={260}
                            cellRenderer={titleRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="appId"
                            dataGetter={select(a => a.id)}
                            title="AppID"
                            width={300}
                            cellRenderer={appIdRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="developer"
                            dataGetter={select(a => a.developer)}
                            title="Developer"
                            width={180}
                            cellRenderer={developerRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="rating"
                            dataGetter={select(a => a.rating)}
                            title="Rating"
                            width={125}
                            minWidth={125}
                            cellRenderer={ratingRenderer}
                            resizable={false}
                        />
                        <Column
                            key="category"
                            dataGetter={select(a => a.category)}
                            title="Category"
                            width={360}
                            sortable={false}
                            cellRenderer={categoryRenderer}
                            searchRenderer={multipleCategorySearchFactory(allCategories)}
                        />
                        <Column
                            key="tags"
                            dataGetter={select(a => a.tags)}
                            title="Tags"
                            width={300}
                            sortable={false}
                            cellRenderer={tagsRenderer}
                            searchRenderer={multiValueSearchFactory(allTagIds, tagSuggestionMapper)}
                        />
                        <Column
                            key="description"
                            dataGetter={select(a => a.description)}
                            title="Description"
                            width={550}
                            sortable={false}
                            cellRenderer={descriptionRenderer}
                        />
                    </InfiniteTable>}
            </Content>
        </VerticalBox>);
});