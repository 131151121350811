import React, { useState, useEffect, useMemo } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "@servicestack/client";
import styled from "styled-components";

const Item = styled.li`
    cursor: pointer;
`;

const DragHandle = styled.span`
    display: inline-block;
    width: 0.8rem;
    padding-right: 0.5rem;
    margin-right: 0.25rem;
`;

export interface SortableListProps<TItem> {
    className?: string;
    items: TItem[];
    selected?: number;
    disabled?: boolean;
    itemIdResolver: (item: TItem, index: number) => string;
    itemRenderer?: (item: TItem, index: number) => string | React.ReactNode;
    onSelect?: (item: TItem, index: number) => void;
    onMove?: (itemId: string, startIndex: number, endIndex: number) => void;
}

export function SortableList<TItem>(props: SortableListProps<TItem>) {
    const [items, setItems] = useState(props.items);
    useEffect(() => {
        if (props.disabled !== true) {
            setItems(props.items);
        }
    }, [props.items, props.disabled]);

    const reorder = (currentItems: TItem[], startIndex: number, endIndex: number) => {
        const result = Array.from(currentItems);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        setItems(result);
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        reorder(items, result.source.index, result.destination.index);
        if (props.onMove !== undefined) {
            props.onMove(result.draggableId, result.source.index, result.destination.index);
        }
    };

    const listClassName = useMemo(() => classNames("list-group", props.className), [props.className]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(droppable) => (
                    <ul className={listClassName} ref={droppable.innerRef} {...droppable.droppableProps}>
                        {items.map((item, index) => {
                            const itemId = props.itemIdResolver(item, index);
                            const className = classNames(
                                "list-group-item",
                                "list-group-item-action", {
                                active: index === props.selected,
                                disabled: props.disabled === true
                            });

                            const onClick = () => {
                                if (props.onSelect !== undefined) {
                                    props.onSelect(item, index);
                                }
                            };

                            return (
                                <Draggable key={itemId} index={index} draggableId={itemId}>
                                    {(draggable) => (
                                        <Item
                                            ref={draggable.innerRef}
                                            className={className}
                                            onClick={onClick}
                                            {...draggable.draggableProps}
                                        >
                                            <span className="d-flex">
                                                <DragHandle {...draggable.dragHandleProps}>
                                                    <FontAwesomeIcon icon={"ellipsis-v"} />
                                                </DragHandle>
                                                <span className="flex-grow-1">
                                                    {props.itemRenderer ? props.itemRenderer(item, index) : itemId}
                                                </span>
                                            </span>
                                        </Item>
                                    )}
                                </Draggable>
                            );
                        })}
                        {droppable.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
}