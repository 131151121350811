import React, { useState, useCallback, useMemo, useEffect } from "react";
import { defer } from "rxjs";
import {
    OrderDirection,
    ClickInfoStatisticsListDataType,
    ClickInfoStatistics,
    ClickInfoStatisticsForDate
} from "src/shared/dtos";
import {
    DocumentTitle,
    HeaderField,
    RouteLink,
    RouteUserProps,
    StatisticsDatePicker,
    StatisticsHeader,
} from "src/shared/components";
import {
    Column,
    DefaultCellRenderer,
    LoadRequest,
    InfiniteTable,
    SearchOptions,
    multiValueSearchFactory,
    SuggestionTag,
} from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { Content, VerticalBox } from "src/shared/components/flex";
import { routes } from "src/shared/routes";
import {
    deserializeQueryParameters,
    QueryParameterDescriptor,
    serializeQueryParameters,
    pushUrl,
    useSubscription,
    deserializeDate,
    serializeDate,
    getNextDateWithinDays,
    splitCamelCase,
} from "src/shared/helpers";
import api, { ListRequest } from "../api";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { FilterName, filterNames } from "../Details/DetailsComponent";
import { DetailsRouteParams } from "../Details/DetailsView";

export type ListRouteParams = {
    clickTarget?: string;
    clickType?: string;
    country?: string;
    platform?: string;
    emulatorType?: string;
    sourceUrl?: string;
    productVersion?: string;
    reportDate?: string;
    orderBy?: string;
    orderDir?: string;
};

export const defaultRouteParams: ListRouteParams = {
    clickTarget: "All",
    clickType: "PinnedDesktopShortcut",
    country: "All",
    platform: "All",
    emulatorType: "All",
    sourceUrl: "All",
    productVersion: "All"
};

const keyDataTypes: { [key: string]: ClickInfoStatisticsListDataType } = {
    "productVersion": ClickInfoStatisticsListDataType.ProductVersion,
    "count": ClickInfoStatisticsListDataType.Count,
    "clickTarget": ClickInfoStatisticsListDataType.ClickTarget,
    "clickType": ClickInfoStatisticsListDataType.ClickType,
    "country": ClickInfoStatisticsListDataType.Country,
    "emulatorType": ClickInfoStatisticsListDataType.EmulatorType,
    "platform": ClickInfoStatisticsListDataType.Platform,
    "sourceUrl": ClickInfoStatisticsListDataType.SourceUrl
};

const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType) as ClickInfoStatisticsListDataType;

function getSearchValueByKey<T>(key: string, searchOptions?: Array<SearchOptions<unknown>>): T | undefined {
    return searchOptions?.find(s => s.key === key)?.value as T;
}

function getDetailsRouteParamsFromRowData(rowData: ClickInfoStatistics): DetailsRouteParams {
    const endDate = rowData.date.slice(0, 10);
    const startDate = getNextDateWithinDays(endDate, -30);
    return filterNames.reduce((routeParams, name) => {
        if (rowData[name]) {
            routeParams[name] = rowData[name];
        }
        return routeParams;
    }, { endDate, startDate } as DetailsRouteParams);
}

const sortQueryParameterDescriptors: QueryParameterDescriptor[] = [
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    {
        paramType: "search",
        paramName: "reportDate",
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "clickType",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "productVersion",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "clickTarget",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "country",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "emulatorType",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "platform",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
    {
        paramType: "search",
        paramName: "sourceUrl",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    },
];

export const ListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [sort, tableFilters, initDate] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...sortQueryParameterDescriptors);
        const searchParams = allParams
            .filter(p => p.paramType === "search")
            .map(qp => ({ key: qp.paramName, value: qp.value } as SearchOptions<unknown>));
        let orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        let orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;
        let dateParam = allParams.find(p => p.paramType === "search" && p.paramName === "reportDate")?.value as Date | undefined;

        if (orderBy === undefined) {
            orderBy = "count";
        }
        if (orderDir === undefined) {
            orderDir = SortOrder.DESC;
        }
        if (dateParam === undefined) {
            dateParam = new Date();
            dateParam.setDate(dateParam.getDate() - 1);
        }

        return [{ key: orderBy, order: orderDir } as SortOptions, searchParams, dateParam];
    }, [location.search]);

    const [data, setData] = useState<Required<ClickInfoStatisticsForDate>>({ items: [], filtersSuggestions: {} });
    const [dates, setDates] = useState<string[]>([]);
    const [reportDate, setReportDate] = useState<Date>(initDate);
    const changeReportDate = (date: Date) => setReportDate(date);

    const updateDates = useSubscription(() => defer(() => api.getDates()).subscribe(setDates), [api.getDates]);

    useEffect(() => {
        updateDates();
    }, []);

    const itemsWithId = useMemo(
        () => data.items.map((item, idx) => ({ ...item, id: `${item.clickType ?? "no-type"}` + idx })),
        [data.items]);

    const updateData = useSubscription((
        requestObj: ListRequest,
        isReload: boolean,
        resolve: (val: boolean) => void) => defer(() => api.list(requestObj)).subscribe(result => {
            const { items, filtersSuggestions } = result;
            if (items === undefined) {
                return;
            }
            setData(prev => ({
                filtersSuggestions: filtersSuggestions ?? prev.filtersSuggestions,
                items: isReload ? items : prev.items.concat(items)
            }));
            resolve(items?.length === 0);
        }), [api.list]);

    const load = useCallback(
        (request: LoadRequest) => {
            if (request.reload) {
                setData(prev => ({ ...prev, items: [] }));
            }

            const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : ClickInfoStatisticsListDataType.ClickTarget;
            const searchRequest = {
                orderBy: orderByKey,
                orderDir: orderByKey !== undefined
                    ? request.sort?.order === SortOrder.DESC ? OrderDirection.Desc : OrderDirection.Asc
                    : undefined,
                reportDate: serializeDate(reportDate),
                clickType: getSearchValueByKey<string[]>("clickType", request.search),
                productVersion: getSearchValueByKey<string[]>("productVersion", request.search),
                clickTarget: getSearchValueByKey<string[]>("clickTarget", request.search),
                country: getSearchValueByKey<string[]>("country", request.search),
                platform: getSearchValueByKey<string[]>("platform", request.search),
                emulatorType: getSearchValueByKey<string[]>("emulatorType", request.search),
                sourceUrl: getSearchValueByKey<string[]>("sourceUrl", request.search),
            };

            const requestObj: ListRequest = {
                limit: request.limit,
                offset: request.offset,
                date: reportDate,
                productVersionSearch: searchRequest.productVersion,
                clickTypeSearch: searchRequest.clickType,
                clickTargetSearch: searchRequest.clickTarget,
                countrySearch: searchRequest.country,
                platformSearch: searchRequest.platform,
                emulatorTypeSearch: searchRequest.emulatorType,
                sourceUrlSearch: searchRequest.sourceUrl,
                orderBy: searchRequest.orderBy,
                orderDirection: searchRequest.orderDir ?? OrderDirection.Desc
            };

            const url = routes.clickInfoStatistics.url(serializeQueryParameters(searchRequest, ...sortQueryParameterDescriptors));
            pushUrl(location, history, url);

            return new Promise<boolean>(resolve => {
                updateData(requestObj, request?.reload, resolve);
            });
        }, [reportDate, location, history]);

    const versionMapper = useCallback((value: string): SuggestionTag => {
        const found = data.filtersSuggestions.productVersion?.find(el => el === value);
        return {
            value: found ?? "",
            label: found ?? ""
        };
    }, [data.filtersSuggestions.productVersion]);

    const clickTypeMapper = useCallback((value: string): SuggestionTag => {
        const found = data.filtersSuggestions.clickType?.find(el => el === value);
        return {
            value: found ?? "",
            label: found !== undefined ? splitCamelCase(String(found)) : ""
        };
    }, [data.filtersSuggestions.clickType]);

    const standardValueMapper = useCallback((value: string): SuggestionTag => ({
        value: value ?? "",
        label: value ?? ""
    }), []);

    const getSuggestionValues: (filterName: FilterName) => string[] = useCallback(
        (filterName) => data.filtersSuggestions[filterName] ?? [] as string[],
        [data.filtersSuggestions]);

    const getLinkRenderer = useCallback((valueMapper?: (val: string) => string) =>
        (props: CellProps<string, ClickInfoStatistics>) => {
            const { cellData, ...restProps } = props;
            const title = `${cellData !== undefined
                ? valueMapper !== undefined ? valueMapper(cellData) : cellData
                : "All"}`;

            const routeParams = getDetailsRouteParamsFromRowData(props.rowData);
            const link = (
                <RouteLink
                    user={user}
                    to={{
                        route: routes.viewClickInfoStatistic,
                        args: routeParams
                    }}
                    className="d-inline">
                    {title}
                </RouteLink>
            );
            return <DefaultCellRenderer cellData={link} title={title} {...restProps} />;
        }, []);

    const intRenderer = useMemo(() =>
        (props: CellProps<number, ClickInfoStatistics>) => {
            const { cellData, ...restProps } = props;
            const text = cellData.toLocaleString(
                undefined,
                { maximumFractionDigits: 0, minimumFractionDigits: 0 }
            );
            const title = `${cellData}`;
            return <DefaultCellRenderer cellData={text} title={title} {...restProps} />;
        }, []);

    const select = makeSelect<ClickInfoStatistics>();
    return (
        <VerticalBox>
            <DocumentTitle title="Click Info Statistics" />
            <StatisticsHeader
                user={user}
                title="Click Info Statistics"
            >
                <HeaderField label="Date">
                    <StatisticsDatePicker
                        id="date"
                        className="form-control"
                        popperPlacement="top-end"
                        selected={reportDate}
                        onChange={changeReportDate}
                        dates={dates}
                    />
                </HeaderField>
            </StatisticsHeader>
            <Content>
                <InfiniteTable
                    items={itemsWithId}
                    load={load}
                    sortBy={sort}
                    filters={tableFilters}
                    renderContext={JSON.stringify(data.filtersSuggestions) + reportDate.toISOString()}
                    hideTopPanel={true}
                    minimumLoadCount={200}
                >
                    <Column
                        key="clickType"
                        title="Click Type"
                        width={400}
                        dataGetter={select(a => a.clickType)}
                        cellRenderer={getLinkRenderer(splitCamelCase)}
                        searchRenderer={multiValueSearchFactory(getSuggestionValues("clickType"), clickTypeMapper)}
                    />
                    <Column
                        key="productVersion"
                        dataGetter={select(a => a.productVersion)}
                        title="Product Version"
                        width={300}
                        cellRenderer={getLinkRenderer()}
                        searchRenderer={multiValueSearchFactory(getSuggestionValues("productVersion"), versionMapper)}
                    />
                    <Column
                        key="country"
                        title="Country"
                        width={250}
                        dataGetter={select(a => a.country)}
                        cellRenderer={getLinkRenderer()}
                        searchRenderer={multiValueSearchFactory(getSuggestionValues("country"), standardValueMapper)}
                    />
                    <Column
                        key="platform"
                        title="Platform"
                        width={230}
                        dataGetter={select(a => a.platform)}
                        cellRenderer={getLinkRenderer()}
                        searchRenderer={multiValueSearchFactory(getSuggestionValues("platform"), standardValueMapper)}
                    />
                    <Column
                        key="sourceUrl"
                        title="Source Url"
                        width={250}
                        dataGetter={select(a => a.sourceUrl)}
                        cellRenderer={getLinkRenderer()}
                        searchRenderer={multiValueSearchFactory(getSuggestionValues("sourceUrl"), standardValueMapper)}
                    />
                    <Column
                        key="emulatorType"
                        title="Emulator Type"
                        width={200}
                        dataGetter={select(a => a.emulatorType)}
                        cellRenderer={getLinkRenderer()}
                        searchRenderer={multiValueSearchFactory(getSuggestionValues("emulatorType"), standardValueMapper)}
                    />
                    <Column
                        key="clickTarget"
                        title="Click Target"
                        width={350}
                        dataGetter={select(a => a.clickTarget)}
                        cellRenderer={getLinkRenderer()}
                        searchRenderer={multiValueSearchFactory(getSuggestionValues("clickTarget"), standardValueMapper)}
                    />
                    <Column
                        key="count"
                        title="Count"
                        align="left"
                        width={220}
                        dataGetter={select(a => a.count)}
                        cellRenderer={intRenderer}
                    />
                </InfiniteTable>
            </Content>
        </VerticalBox>);
});
