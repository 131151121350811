import React, { useCallback } from "react";
import { Form } from "reactstrap";
import { EditField, KeyBindingsEditor } from "src/shared/components";
import { GamestoreAppMainInfo } from "./applicationReducer";

interface Props {
    schemas: object[];
    value: string | undefined;
    initial?: string;
    update: React.Dispatch<Partial<GamestoreAppMainInfo> | undefined>;
    saving: boolean;
    onSubmit: () => void;
    isValid: boolean;
}

export const EditKeyBindings = (props: Props) => {
    const setKeyBindings = useCallback(
        (v: string) => props.update({ defaultKeyBindings: v ? v : undefined }), [props.update]);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <Form className="mt-3" onSubmit={save} noValidate>
            <EditField
                label={
                    <span>Default Key Bindings{" "}
                        {!props.isValid && <strong className="text-danger">(invalid)</strong>}
                    </span>}
                fieldId="defaultKeyBindings"
                fullWidth={true}
                isChanged={props.value !== props.initial}
            >
                <KeyBindingsEditor
                    value={props.value ?? ""}
                    setValue={setKeyBindings}
                    disabled={props.saving}
                />
            </EditField>
        </Form>
    );
};