import React, { useState, useCallback, useEffect, useMemo, ChangeEvent } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BreadcrumbItem, Button, Col, Input, Row } from "reactstrap";
import { defer, noop } from "rxjs";
import {
    Breadcrumb,
    DocumentTitle,
    Loader,
    RouteLink,
    RouteUserProps,
    ToolBox,
    ToolBoxLeftContainer,
    notifyError,
    notifySuccess
} from "src/shared/components";
import { Content, VerticalBox, Header } from "src/shared/components/flex";
import { AdBrowserExtension } from "src/shared/dtos";
import { setFromInput, useFunctionState } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import api from "../api";
import { classNames } from "@servicestack/client";
import { ActionButton } from "src/shared/components/InfiniteTable";
import styled from "styled-components";
import { ImportFileModal } from "./ImportFileModal";

const DeleteButton = styled(ActionButton)`
    float: right;
`;

export const ListView = withRouter(({ user }: RouteComponentProps<{}> & RouteUserProps) => {
    const [items, setItems] = useState<AdBrowserExtension[]>();
    const [saving, setSaving] = useState(false);
    const [search, setSearch] = useState("");
    const [showImportDialog, setShowImportDialog] = useState(false);

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);

    const filtered = useMemo(() => items !== undefined && search.length > 0
        ? items.filter(i => i.extensionId.toLowerCase().indexOf(search.toLowerCase()) !== -1)
        : items ?? [], [items, search]);
    const isExist = useMemo(() =>
        search.length > 0 && items !== undefined && items.find(i => i.extensionId.toLowerCase() === search.toLowerCase()) !== undefined,
        [items, search]);

    useEffect(() => {
        const subscription = defer(() => api.list()).subscribe(result => setItems(result.sort((a, b) => a.id - b.id)));
        return () => subscription.unsubscribe();
    }, []);

    const onCreate = useCallback(() => {
        if (saving) {
            return;
        }

        setSaving(true);
        const subscription = defer(() => api.create(search)).subscribe({
            next: extension => {
                notifySuccess(`Extension with ID '${search}' has been successfully added.`);
                setItems(old => {
                    old?.push(extension);
                    return old;
                });

                setSearch("");
                setSaving(false);
            },
            error: () => {
                notifyError("Unable to add ExtensionId.");
                setSaving(false);
            }
        });
        setUnsubscribe(() => subscription.unsubscribe());
    }, [saving, search]);

    const onDeleteExtension = useCallback((extension: AdBrowserExtension) => {
        if (saving) {
            return;
        }

        setSaving(true);
        defer(() => api.delete(extension!.id)).subscribe({
            next: () => {
                setItems(old => old?.filter(e => e.id !== extension.id));
                notifySuccess(`Extension with ID '${extension.extensionId}' has been successfully deleted.`);
                setSaving(false);
            },
            error: () => {
                notifyError(`Unable to delete Extension '${extension.extensionId}'`);
                setSaving(false);
            }
        });
    }, [saving]);

    const onImportFormCancel = () => setShowImportDialog(false);
    const onImportClick = () => setShowImportDialog(true);

    return (
        <VerticalBox>
            <DocumentTitle title="Ad Browser Extensions" />
            <Header>
                <ToolBox>
                    <ToolBoxLeftContainer>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <RouteLink user={user} to={routes.home}>
                                    Home
                                </RouteLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                Ad Browser Extensions
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </ToolBoxLeftContainer>
                </ToolBox>
            </Header>
            <Content>
                {items === undefined && (<Loader />)}
                {items !== undefined && (
                    <div className="row">
                        <div className="col-12 col-sm-12 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 py-1">
                            <Row>
                                <Col className="col-10">
                                    <Input
                                        type="text"
                                        className="mb-2"
                                        placeholder="Enter extension ID here to search for it or add a new one if none found"
                                        value={search}
                                        onChange={setFromInput(setSearch)}
                                    />
                                </Col>
                                <Col className="col-2 text-right">
                                    <Button className="mr-2" color="primary" disabled={isExist} onClick={onCreate}>
                                        Add
                                    </Button>
                                    <ImportFileModal
                                        close={onImportFormCancel}
                                        isOpen ={showImportDialog}
                                        title="Choose file to upload (txt, csv)"
                                        setResult={setItems}
                                    />
                                    <Button className="mr-2" color="success" onClick={onImportClick}>
                                        Import
                                    </Button>
                                </Col>
                            </Row>
                            <ul className="list-group">
                                <li className="list-group-item list-group-item-secondary" >Extension ID</li>
                                {filtered.map(extension => {
                                    const onDelete = () => onDeleteExtension(extension);
                                    const className = classNames(
                                        "list-group-item",
                                        "list-group-item-action", {
                                        disabled: saving
                                    });

                                    return (
                                        <li key={extension.id} className={className}>
                                            {extension.extensionId}
                                            <DeleteButton icon="trash" className="text-danger" onClick={onDelete} />
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                )}
            </Content>
        </VerticalBox>
    );
});