import { Tooltip } from "reactstrap";
import styled from "styled-components";

export const StyledTooltip = styled(Tooltip)<{color?: string}>`
    .tooltip {
        z-index: 100 !important;
    }
    .tooltip-inner {
        background-color: ${p => p.color ?? "#d9534f"};
        max-width: max-content;
        /* Additional width attributes for firefox. */
        white-space: nowrap;
        width: max-content;
    }

    .arrow::before {
        border-bottom-color: ${p => p.color ?? "#d9534f"} !important;
    }
`;