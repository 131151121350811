import { IoApplicationLocalization } from "src/shared/dtos";
import { isNullOrUndefined, validateSource, validateSources, validateUrlField, validateUrls, ValidationSchema } from "src/shared/helpers";
import { IoAppMainInfo } from "./ioApplicationReducer";

export const localizationValidationSchema: ValidationSchema<IoApplicationLocalization> = {
    applicationUrl: url => validateUrlField({ url }),
    iconUrl: url => validateUrlField({ url, isRequired: true }),
    thumbnailUrl: url => validateUrlField({ url }),
    sourceUrl: url => validateUrlField({ url, isRequired: true }),
    screenshots: urls => validateUrls({ urls, itemName: "Screenshot" }),
    title: v => isNullOrUndefined(v) || v.length < 2 ? "Title must be a minimum of two characters." : null,
    videos: urls => validateUrls({ urls, itemName: "Video" }),
};

export const locSourcesValidationSchema: ValidationSchema<IoApplicationLocalization> = {
    iconUrl: url => validateSource({ url, type: "image" }),
    thumbnailUrl: url => validateSource({ url, type: "image" }),
    screenshots: urls => validateSources({ urls, itemName: "Screenshot", type: "image" }),
    videos: urls => validateSources({ urls, itemName: "Video", type: "video" }),
};

export const applicationValidationSchema: ValidationSchema<IoAppMainInfo> = {
    category: (_, data) => !data?.categoryId ? "Category must be added" : null,
    id: val => !val ? "Id is required" : null
};