import React, { forwardRef, useCallback, useRef } from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import { PropertyEditorProps } from "../PropertyEditorProps";
import { setFromInput } from "src/shared/helpers";
import { useState } from "react";
import { StyledTooltip } from "../../StyledTooltip";
import styled from "styled-components";

const StyledPreviewImageBox = styled.div`
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 100%;
    height: 2.375rem;
`;

const StyledPreviewImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
    @media(hover: hover) and (pointer: fine) {
        :hover {
            display: block;
            position: fixed;
            bottom: 25%;
            width: 640px;
            height: 480px;
            object-fit: contain;
            z-index: 5;
            background: rgba(0, 0, 0, .5);
            visibility: visible;
        }
    }
`;

const StyledOpenedImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const SingleLineUrlWithPreviewPropertyEditor = forwardRef(
    (props: PropertyEditorProps<string | undefined>, ref: React.MutableRefObject<HTMLDivElement>) => {
        const tooltipRef = useRef<HTMLDivElement>(null);

        const [isOpenImageModal, setIsOpenImageModal] = useState<boolean>(false);
        const closeImageModal = () => setIsOpenImageModal(false);
        const openImageModal = () => setIsOpenImageModal(true);

        const onChange = useCallback((v: string) => props.onChange(v === "" ? undefined : v), [props.onChange]);
        return (
            <div ref={ref}>
                <div className="row">
                    {props.value && (
                        <div className="col-lg-1">
                            <StyledPreviewImageBox>
                                <StyledPreviewImage src={props.value ?? ""} onClick={openImageModal} />
                            </StyledPreviewImageBox>
                        </div>
                    )}
                    <div className={props.value ? "col-lg-11" : "col-lg-12"}>
                        <Input
                            type="text"
                            id={props.id}
                            value={props.value ?? ""}
                            onChange={setFromInput(onChange)}
                            disabled={props.disabled}
                            invalid={props.errors && props.errors.length > 0}
                        />
                        <div ref={tooltipRef} />
                        <StyledTooltip placement="bottom" isOpen={props.errors && props.errors.length > 0} target={tooltipRef}>
                            {props.errors?.map((err, i) => <div key={`${err}_${i}`}>{err}</div>)}
                        </StyledTooltip>
                    </div>
                </div>
                <div>
                    <Modal isOpen={isOpenImageModal} toggle={closeImageModal}>
                        <ModalHeader toggle={closeImageModal} charCode="&times;" />
                        <ModalBody>
                            <StyledOpenedImage src={props.value} />
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        );
    });
