import { client } from "src/shared/client";
import {
    Category,
    GetCategories,
    GetCategory,
    ManageCategory,
    DeleteCategory,
    CategoryOrder,
    ManageCategoriesOrder,
    GetKeyBindingsSchemas,
    GetCategoryApplicationsIds
} from "src/shared/dtos";

export const CategoriesApi = {
    list: () => client.get(new GetCategories()),
    get: (id: string) => client.get(new GetCategory({ id })),
    create: (category: Category) => client.post(new ManageCategory({ category })),
    update: (category: Category) => client.put(new ManageCategory({ category })),
    delete: (id: string) => client.delete(new DeleteCategory({ id })),
    updateCategoriesOrder: (categories: CategoryOrder[]) => client.post(new ManageCategoriesOrder({ categories })),
    getKeyBindingsSchemas: () => client.get(new GetKeyBindingsSchemas()),
    getCategoryApplicationsIds: (categoryId: string) => client.get(new GetCategoryApplicationsIds({ categoryId })),
};

export default CategoriesApi;