import React, { FC, useCallback, useState } from "react";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from "reactstrap";
import { WidgetHeaderClassName } from "../HomeView";

const WidgetMain = styled.div`
    position: relative;
    border-radius: 12px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3);
    position: relative;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    &:hover {
        & .hidden-toggle {
            opacity: 1;
            transition: opacity .3s ease;
        }
    }
`;

const LoadingScreen = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-radius: 12px;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.4);
`;

const WidgetHeader = styled.div<{ editMode: boolean }>`
    position: relative;
    border-bottom: 2px solid #f8f8f8;
    border-radius: 12px 12px 0 0;
    background-color: #fff;
    height: 44px;
    padding: 10px 14px;
    display: flex;
    justify-content: space-between;
    ${props => props.editMode && css`cursor: pointer;`};
`;

const WidgetBody = styled.div`
    flex: 1;
    max-height: calc(100% - 44px);
    background-color: #fff;
    border-radius: 0 0 12px 12px;
`;

const WidgetTitle = styled.span`
    font-family: "Inter",-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
    flex: 1;
    color: #121828;
    font-size: 1.1rem;
    font-weight: 500;
    user-select: none;
`;

const StyledDropdownToggle = styled(DropdownToggle).attrs({ tag: "span" })`
    color: #aaa;
    cursor: pointer;
    font-size: 1rem;
    opacity: 0;
`;

const EditIcon = styled(FontAwesomeIcon)`
    transition: all .2s linear;
`;

const EditButton = styled(DropdownItem)`
    &:active {
        outline: none;
    }
`;

const RemoveButton = styled(DropdownItem)`
    &:active {
        background-color: #dc3545;
        outline: none;
    }
`;

export interface WidgetBaseProps {
    title?: string;
    loading: boolean;
    editMode: boolean;
    remove: () => void;
    edit: () => void;
}

export const WidgetBase: FC<WidgetBaseProps> = (props) => {
    const { remove, title, children, loading, editMode, edit } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropDown = useCallback(() => {
        setDropdownOpen(prev => !prev);
    }, []);

    return (
        <WidgetMain>
            {loading && <LoadingScreen><Spinner /></LoadingScreen>}
            <WidgetHeader editMode={editMode}>
                <WidgetTitle className={WidgetHeaderClassName}>{title ?? ""}</WidgetTitle>
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropDown} direction="left">
                    <StyledDropdownToggle className="hidden-toggle">
                        <EditIcon icon={faCog} width={30} height={30} rotation={dropdownOpen ? 90 : undefined} />
                    </StyledDropdownToggle>
                    <DropdownMenu>
                        <EditButton onClick={edit}>
                            <FontAwesomeIcon icon={faEdit} />{" "}Edit
                        </EditButton>
                        <RemoveButton onClick={remove}>
                            <FontAwesomeIcon icon={faTrash} />{" "}Remove
                        </RemoveButton>
                    </DropdownMenu>
                </Dropdown>
            </WidgetHeader>
            <WidgetBody>
                {children}
            </WidgetBody>
        </WidgetMain>
    );
};