import React, { useCallback, forwardRef, useRef } from "react";
import { PropertyEditorProps } from "../PropertyEditorProps";
import { HtmlEditor } from "../../HtmlEditor";
import { StyledTooltip } from "../../StyledTooltip";

export const HtmlPropertyEditor = forwardRef(
    (props: PropertyEditorProps<string | undefined>, ref: React.MutableRefObject<HTMLDivElement>) => {
        const tooltipRef = useRef<HTMLDivElement>(null);
        const onChange = useCallback((v: string) => props.onChange(v === "" ? undefined : v), [props.onChange]);
        return (
            <div ref={ref}>
                <HtmlEditor
                    id={props.id}
                    value={props.value ?? ""}
                    onChange={onChange}
                    disabled={props.disabled}
                />
                <div ref={tooltipRef} />
                <StyledTooltip placement="bottom" isOpen={props.errors && props.errors.length > 0} target={tooltipRef}>
                    {props.errors?.map((err, i) => <div key={`${err}_${i}`}>{err}</div>)}
                </StyledTooltip>
            </div>
        );
    });
