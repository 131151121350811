import { ResponseStatus } from "@servicestack/client";
import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

export const ErrorModal = (props: { error: ResponseStatus | undefined, closeError: () => void }) =>
    <Modal isOpen={props.error !== undefined} toggle={props.closeError}>
        <ModalHeader toggle={props.closeError}>
            An error ocurred
        </ModalHeader>
        <ModalBody>
            <p><b>{props.error?.errorCode}</b></p>
            <p>{props.error?.message}</p>
            <pre><code>{props.error?.stackTrace}</code></pre>
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={props.closeError}>Close</Button>
        </ModalFooter>
    </Modal>;