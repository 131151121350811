import React, { FC, useState, useCallback } from "react";
import { ThemedProps } from "../../../types";
import { SubCategoryTag } from "./SubCategoryTag";
import { subCategories } from "./subCategories";
import styled from "styled-components";

export const StyledBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    background-color: ${(p: ThemedProps) => p.theme.SubCategoryBarBackgroundColor};
    margin-bottom: 25px;
`;

export const SubCategoriesBar: FC = () => {
    const [activeTag, setActiveTag] = useState("");

    const tagClickHandler = useCallback(setActiveTag, []);

    return (
        <StyledBar>
            {subCategories.map(c => (
                <SubCategoryTag
                    key={c.label}
                    label={c.label}
                    icon={c.icon}
                    onClick={tagClickHandler}
                    isActive={activeTag === c.label}
                />
            ))}
        </StyledBar>
    );
};
