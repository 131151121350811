import { GoogleApplicationLocalization, GoogleApplication } from "src/shared/dtos";
import { availableLanguages, deepEqual } from "src/shared/helpers";

export type LocalizationState = {
    defaultLanguage: string,
    app: GoogleApplication,
    current?: GoogleApplicationLocalization,
    initial?: GoogleApplicationLocalization,
    all: GoogleApplicationLocalization[],
    allInitial: GoogleApplicationLocalization[],
    changedLanguages: string[],
    removedLocalizations: GoogleApplicationLocalization[]
};

export type LocalizationAction =
    {
        kind: "set",
        app: GoogleApplication,
        resetCurrent: boolean,
        localizations: GoogleApplicationLocalization[],
        initialLocalizations: GoogleApplicationLocalization[]
    } |
    { kind: "select", lang: string } |
    { kind: "add", lang: string } |
    { kind: "remove" } |
    { kind: "update", value: Partial<GoogleApplicationLocalization> } |
    { kind: "add-exist", value: GoogleApplication };

const sortByAvailableLanguages = (a: GoogleApplicationLocalization, b: GoogleApplicationLocalization) =>
    availableLanguages.findIndex(l => l.value === a.language)
    - availableLanguages.findIndex(l => l.value === b.language);

const makeEmptyLocale = (appId: string, lang: string) => ({
    id: 0,
    googleApplicationId: appId,
    language: lang,
    title: undefined,
    iconUrl: undefined,
    publisher: undefined,
    publisherId: undefined,
    shortDescription: undefined,
    description: undefined,
    screenshots: [],
    videos: [],
    size: 0,
    splitFiles: []
});

export const localizationReducer = (state: LocalizationState, action: LocalizationAction) => {
    const processChangedLanguages = () => {
        const isChanged = !deepEqual(state.current, state.initial);
        const changedIndex = state.changedLanguages.indexOf(state.current!.language);
        if (isChanged && changedIndex === -1) {
            state.changedLanguages = state.changedLanguages.concat(state.current!.language);
        }
        if (!isChanged && changedIndex !== -1) {
            state.changedLanguages.splice(changedIndex, 1);
        }
    };

    switch (action.kind) {
        case "set": {
            const current = action.resetCurrent === true
                ? action.localizations.find(l => l.language === state.defaultLanguage) || action.localizations[0]
                : state.current;
            const initial = action.app?.localizations.find(l => l.language === state.defaultLanguage);
            state = {
                defaultLanguage: state.defaultLanguage,
                app: action.app,
                current,
                initial,
                all: action.localizations.sort(sortByAvailableLanguages),
                allInitial: action.initialLocalizations,
                changedLanguages: [],
                removedLocalizations: []
            };
            break;
        }
        case "select": {
            const found = state.all.find(l => l.language === action.lang);
            if (found === undefined) {
                break;
            }
            state.current = found;
            state.initial = state.allInitial.find(l => l.language === action.lang);
            break;
        }
        case "add": {
            if (state.all.find(l => l.language === action.lang) !== undefined) {
                break;
            }
            const removedLoc = state.removedLocalizations.find(l => l.language === action.lang);
            state.current = removedLoc ?? makeEmptyLocale(state.app.id, action.lang);
            state.initial = state.allInitial.find(l => l.language === action.lang);
            state.all = state.all.concat(state.current).sort(sortByAvailableLanguages);

            const isChanged = !deepEqual(state.current, state.initial);
            if (isChanged && state.changedLanguages.indexOf(action.lang) === -1) {
                state.changedLanguages = state.changedLanguages.concat(action.lang);
            }

            const removedLanguageIndex = state.removedLocalizations.findIndex(l => l.language === action.lang);
            if (removedLanguageIndex !== -1) {
                state.removedLocalizations.splice(removedLanguageIndex, 1);
            }
            break;
        }
        case "remove": {
            if (state.current === undefined || state.all.length < 2) {
                break;
            }
            const index = state.all.findIndex(l => l.language === state.current!.language);
            state.all = state.all.filter(l => l.language !== state.current!.language);

            const changedLanguageIndex = state.changedLanguages.findIndex(l => l === state.current!.language);
            if (changedLanguageIndex !== -1) {
                state.changedLanguages.splice(changedLanguageIndex, 1);
            }

            if (state.allInitial.find(l => l.language === state.current!.language) !== undefined) {
                state.removedLocalizations = state.removedLocalizations.concat(state.current);
            }

            const nextIndex = Math.min(state.all.length - 1, Math.max(0, index));
            state.current = state.all[nextIndex];
            state.initial = state.allInitial.find(l => l.language === state.current?.language);
            break;
        }
        case "update": {
            if (state.current === undefined) {
                break;
            }
            Object.assign(state.current, action.value);
            processChangedLanguages();
            break;
        }
        case "add-exist": {
            if (state.current === undefined) {
                break;
            }
            const existLangs = action.value.localizations.map(l => l.language);
            const currentLangs = state.all.map(l => l.language);
            const newLangs = existLangs.filter(lang => currentLangs.indexOf(lang) === -1);

            newLangs.forEach(lang => {
                state.all = state.all.concat(makeEmptyLocale(state.current!.googleApplicationId, lang));
            });

            Object.assign(state.current, action.value);
            break;
        }
    }

    return Object.assign({}, state);
};

export const makeLocalizationReducerDefault = (defaultLanguage: string): LocalizationState => ({
    defaultLanguage,
    app: undefined as unknown as GoogleApplication,
    current: undefined,
    initial: undefined,
    all: [],
    allInitial: [],
    changedLanguages: [],
    removedLocalizations: []
});