import React, { FC, useState, useRef, useCallback, MouseEventHandler } from "react";
import styled from "styled-components";

const Main = styled.div`
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    };
    &::-webkit-scrollbar-thumb {
        background-color: #b1b1b1;
    };
    padding: 10px;
    overflow: auto;
    height: 100%;
`;

let pos = { top: 0, left: 0, x: 0, y: 0 };

export const DragScrollBox: FC = ({children}) => {
    const [dragMode, setDragMode] = useState(false);
    const boxRef = useRef<HTMLDivElement | null>(null);

    const onMouseMove: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
        if (!dragMode || !boxRef.current){
            return;
        }
        e.preventDefault();
        const dx = e.clientX - pos.x;
        const dy = e.clientY - pos.y;

        // Scroll the element
        boxRef.current.scrollTop = pos.top - dy;
        boxRef.current.scrollLeft = pos.left - dx;
    }, [dragMode, boxRef.current]);

    const disableDragScroll = useCallback(() => setDragMode(false), []);
    const enableDragScroll: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
        pos = {
            // The current scroll
            top: boxRef.current?.scrollTop ?? 0,
            left: boxRef.current?.scrollLeft ?? 0,
            // Get the current mouse position
            x: e.clientX,
            y: e.clientY,
        };
        setDragMode(true);
    }, [boxRef.current]);

    return (
        <Main
            ref={boxRef}
            onMouseDown={enableDragScroll}
            onMouseUp={disableDragScroll}
            onMouseLeave={disableDragScroll}
            onMouseMove={onMouseMove}
            >
            {children}
        </Main>
    );
};