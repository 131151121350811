import React, { useState, useCallback, useEffect, useReducer, useRef, useMemo } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BreadcrumbItem } from "reactstrap";
import { defer, forkJoin } from "rxjs";
import { switchMap, map } from "rxjs/operators";
import styled from "styled-components";
import {
    Breadcrumb,
    DocumentTitle,
    Loader,
    RouteLink,
    RouteUserProps,
    ToolBox,
    ToolBoxLeftContainer,
    LeavingViewProtector,
    notifyError,
    notifySuccess
} from "src/shared/components";
import { Content, VerticalBox, Header } from "src/shared/components/flex";
import { ColorOption, PropertyMetadata, SourceSettings, SourceSettingsData, SourceSettingsMetadata } from "src/shared/dtos";
import { structuredClone } from "src/shared/helpers";
import { routes } from "src/shared/routes";
import { EditSourceSettings, EditSourceSettingsRef } from "./EditSourceSettings";
import { SourceList, SourceListRef } from "./SourceList";
import { SourceName } from "./SourceName";
import { sourceSettingsReducer, sourceSettingsReducerDefault, SourceSettingsSetActionValue } from "./sourceSettingsReducer";
import api from "../api";

const StyledContent = styled(Content)`
    overflow: hidden;
    margin: 0 1rem;
`;

const ContentRow = styled.div`
    height: 86vh;
`;

const SourcesContainer = styled.div`
    height: 100%;
`;

const SettingsContainer = styled.div`
    border-left: 1px solid lightgray;
    height: 100%;
`;

const removeTabsAndSpacesFromColorValue = (option: ColorOption): ColorOption => {
    if (!option.color) {
        return option;
    }
    option.color = option.color.trim().replace(/(\t|\n)+/, "");
    return option;
};

export const ListView = withRouter(({ user }: RouteComponentProps & RouteUserProps) => {
    const [sourceSettings, changeSourceSettings] = useReducer(sourceSettingsReducer, sourceSettingsReducerDefault);
    const [settingsMetadata, setSettingsMetadata] = useState<SourceSettingsMetadata>();
    const [saving, setSaving] = useState(false);

    const sourceListRef = useRef<SourceListRef>(null);
    const editSourceSettingsRef = useRef<EditSourceSettingsRef>(null);

    const sourcesList = useMemo(() => sourceSettings.items?.map(v => v.current) ?? [], [sourceSettings.items]);

    useEffect(() => {
        if (sourceSettings.items === undefined) {
            changeSourceSettings({ kind: "select", id: undefined });
            const subscription = defer(() => api.list())
                .pipe(
                    switchMap(all => {
                        return forkJoin(all.map(s => structuredClone(s))).pipe(
                            map(clones => clones.map((clone, i) => ({
                                current: all[i],
                                initial: clone
                            } as SourceSettingsSetActionValue)))
                        );
                    })
                )
                .subscribe(values => changeSourceSettings({ kind: "init", values }));

            return () => subscription.unsubscribe();
        }

        if (sourceSettings.selected === undefined) {
            const defaultId = sourceSettings.items.find(s => s.type === "default")?.initial.id;
            if (defaultId !== undefined) {
                changeSourceSettings({ kind: "select", id: defaultId });
            }
        }

        return undefined;
    }, [sourceSettings.items, sourceSettings.selected]);

    useEffect(() => {
        const subscription = defer(() => api.getMetadata()).subscribe(setSettingsMetadata);
        return () => subscription.unsubscribe();
    }, []);

    const onSelect = useCallback((selected: SourceSettings) => {
        if (saving) {
            return;
        }

        changeSourceSettings({ kind: "select", id: selected?.id });
    }, [saving]);

    const updateSelected = useCallback((data: {
        newSource?: string,
        newAliases?: string[],
        newSettings?: Partial<SourceSettingsData>,
        newApplicationIds?: string[]
    }) => {
        if (saving) {
            return;
        }

        changeSourceSettings({
            kind: "update-selected",
            value: {
                source: data.newSource,
                aliases: data.newAliases,
                settings: data.newSettings,
                pinToDesktopApplicationIds: data.newApplicationIds
            }
        });
    }, [saving]);

    const add = useCallback(() => {
        if (saving) {
            return;
        }

        changeSourceSettings({ kind: "ensure-new-exist" });
        changeSourceSettings({ kind: "select", id: 0 });
    }, [saving]);

    const copy = useCallback(() => {
        if (saving || sourceSettings.selected === undefined || sourceSettings.selected.type === "new") {
            return;
        }

        setSaving(true);
        forkJoin([
            defer(() => structuredClone(sourceSettings.selected!.current)),
            defer(() => structuredClone(sourceSettings.selected!.current))
        ]).subscribe({
            next: clones => {
                changeSourceSettings({ kind: "set-new-value", value: { current: clones[0], initial: clones[1] } });
                changeSourceSettings({ kind: "select", id: 0 });
                setSaving(false);
            },
            error: () => setSaving(false)
        });
    }, [saving, sourceSettings]);

    const saveSelected = useCallback(() => {
        const selected = sourceSettings.selected;
        if (saving || selected === undefined || sourceListRef.current === null || editSourceSettingsRef.current === null
            || selected.current.source === "") {
            return;
        }

        setSaving(true);
        forkJoin([
            sourceListRef.current.validateCurrent(),
            editSourceSettingsRef.current.validate()
        ]).subscribe({
            next: validationResults => {
                if (validationResults.some(r => !r)) {
                    setSaving(false);
                    return;
                }
                const srcSettings = { ...selected.current };
                srcSettings.settings.installerColorOptions = srcSettings.settings.
                    installerColorOptions?.map(removeTabsAndSpacesFromColorValue);
                const isNew = selected.type === "new";
                if (isNew) {
                    const srcSettingsAliases = sourcesList.map(s => s.aliases).flat();
                    if (srcSettingsAliases.includes(srcSettings.source)){
                        notifyError(`Source name '${srcSettings.source}' is already used as an alias name for other source`);
                        setSaving(false);
                        return;
                    }
                }
                defer(() => isNew ? api.create(srcSettings) : api.update(srcSettings))
                    .pipe(
                        switchMap(saved => {
                            return defer(() => structuredClone(saved)).pipe(
                                map(clone => ({
                                    current: saved,
                                    initial: clone
                                } as SourceSettingsSetActionValue))
                            );
                        })
                    )
                    .subscribe({
                        next: value => {
                            changeSourceSettings({ kind: "confirm-save-selected", value });
                            notifySuccess(`Settings for source '${value.current.source}' has been successfully saved.`);
                            setSaving(false);

                            if (isNew) {
                                changeSourceSettings({ kind: "select", id: value.current.id });
                            }
                        },
                        error: () => {
                            notifyError("Unable to save source settings.");
                            setSaving(false);
                        }
                    });
            },
            error: () => setSaving(false)
        });
    }, [sourceSettings.selected?.current, saving, sourceListRef.current, editSourceSettingsRef.current, sourcesList]);

    const deleteSelected = useCallback(() => {
        if (saving || sourceSettings.selected?.type !== "normal") {
            if (sourceSettings.selected?.type === "default") {
                notifyError("Unable to delete default source settings.");
            }
            if (sourceSettings.selected?.type === "new") {
                changeSourceSettings({ kind: "delete-unsaved-item" });

                const defaultId = sourceSettings.items?.find(s => s.type === "default")?.initial.id;
                if (defaultId !== undefined) {
                    changeSourceSettings({ kind: "select", id: defaultId });
                }
            }
            return;
        }

        setSaving(true);
        defer(() => api.delete(sourceSettings.selected!.initial.id)).subscribe({
            next: () => {
                notifySuccess(`Settings for source '${sourceSettings.selected!.initial.source}' has been successfully deleted.`);
                changeSourceSettings({ kind: "confirm-delete-selected" });
                setSaving(false);
            },
            error: () => {
                notifyError("Unable to delete source settings.");
                setSaving(false);
            }
        });
    }, [sourceSettings.selected, saving]);

    return (
        <VerticalBox>
            <DocumentTitle title="Source Settings" />
            <LeavingViewProtector showConfirmation={sourceSettings.hasChanges} ignoreRoute={routes.sourceSettings} />
            <Header>
                <ToolBox>
                    <ToolBoxLeftContainer>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <RouteLink user={user} to={routes.home}>
                                    Home
                                </RouteLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem active={sourceSettings.selected === undefined}>
                                {sourceSettings.selected === undefined && "Source Settings"}
                                {sourceSettings.selected !== undefined && (
                                    <RouteLink user={user} to={routes.sourceSettings}>
                                        Source Settings
                                    </RouteLink>
                                )}
                            </BreadcrumbItem>
                            {sourceSettings.selected !== undefined && (
                                <BreadcrumbItem active>
                                    <SourceName type={sourceSettings.selected.type} source={sourceSettings.selected.initial.source} />
                                </BreadcrumbItem>
                            )}
                        </Breadcrumb>
                    </ToolBoxLeftContainer>
                </ToolBox>
            </Header>
            <StyledContent>
                {sourceSettings.items === undefined || settingsMetadata === undefined && <Loader />}
                {sourceSettings.items !== undefined && settingsMetadata !== undefined && (
                    <ContentRow className="row">
                        <SourcesContainer className="col-sm-3 col-md-6 col-lg-4">
                            <SourceList
                                ref={sourceListRef}
                                items={sourceSettings.items}
                                selectedSource={sourceSettings.selected}
                                onSelect={onSelect}
                                add={add}
                                copy={copy}
                                update={updateSelected}
                                delete={deleteSelected}
                                saving={saving}
                            />
                        </SourcesContainer>
                        <SettingsContainer className="col-sm-9 col-md-6 col-lg-8">
                            {sourceSettings.selected && (
                                <EditSourceSettings
                                    ref={editSourceSettingsRef}
                                    settingsMetadata={settingsMetadata}
                                    item={sourceSettings.selected}
                                    update={updateSelected}
                                    save={saveSelected}
                                    saving={saving}
                                    sourcesList={sourcesList}
                                />
                            )}
                        </SettingsContainer>
                    </ContentRow>
                )}
            </StyledContent>
        </VerticalBox>
    );
});
