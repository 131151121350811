import React from "react";
import { Switch, RouteProps as ReactRouteProps } from "react-router-dom";
import { RouteProps } from "src/shared/components";

export const RouteSwitch = (props: ReactRouteProps & React.PropsWithChildren<{}>) => {
    const children = React.Children.map(props.children, node => {
        if (React.isValidElement(node)) {
            const { route, path, exact } = node.props as ReactRouteProps & RouteProps<unknown>;
            if (route !== undefined) {
                // The 'react-router-dom' component actually reads exact and path values from the virtual tree,
                // so rendering they inside of HOC will have no effect. This workaroud passes correct values to
                // the Switch component.
                return React.cloneElement(node, {
                    path: path ?? route.pattern.path,
                    exact: exact ?? route.pattern.exact,
                    ...node.props
                });
            }
        }

        return node;
    });

    return <Switch>{children}</Switch>;
};