import React, { FC, useState, Dispatch, useEffect } from "react";
import { CrawledApplicationsInfo } from "src/shared/dtos";
import styled from "styled-components";
import api from "./api";
import { useSubscription } from "src/shared/helpers";
import { defer } from "rxjs";

const StyledTable = styled.table`
    width: 100%;
    height: 100%;
`;

const StyledCell = styled.td`
    text-align: right;
    padding-right: 4px;
    font-size: 1.1rem;
    border: none;
    font-family: sans-serif;
    color: #343a40;
`;

const StyledNamingCell = styled.td`
    text-align: left;
    padding-left: 4px;
    font-size: 1.1rem;
    border: none;
    font-family: sans-serif;
    color: #343a40;
`;

const withSpaces = (val: any) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

interface Props {
    setLoading: Dispatch<boolean>;
}

export const CrawlingInfo: FC<Props> = ({ setLoading }) => {
    const [data, setData] = useState<CrawledApplicationsInfo | null>(null);

    const getCrawledApplicationsInfo = useSubscription(() => defer(
        () => api.getCrawledApplicationsInfo()).subscribe(value => {
            setLoading(false);
            setData(value);
        }),
        [api.getCrawledApplicationsInfo]);

    useEffect(() => {
        setLoading(true);
        getCrawledApplicationsInfo();
    }, []);

    return data === null ? null
        : (<StyledTable>
            <tbody>
                <tr>
                    <StyledCell />
                    <StyledCell>x32</StyledCell>
                    <StyledCell>x64</StyledCell>
                    <StyledCell>Total</StyledCell>
                </tr>
                <tr>
                    <StyledNamingCell>None</StyledNamingCell>
                    <StyledCell>{withSpaces(data.none?.x32)}</StyledCell>
                    <StyledCell>{withSpaces(data.none?.x64)}</StyledCell>
                    <StyledCell>{withSpaces(data.none?.total)}</StyledCell>
                </tr>
                <tr>
                    <StyledNamingCell>Crawled</StyledNamingCell>
                    <StyledCell>{withSpaces(data.crawled?.x32)}</StyledCell>
                    <StyledCell>{withSpaces(data.crawled?.x64)}</StyledCell>
                    <StyledCell>{withSpaces(data.crawled?.total)}</StyledCell>
                </tr>
                <tr>
                    <StyledNamingCell>Failed</StyledNamingCell>
                    <StyledCell>{withSpaces(data.failed?.x32)}</StyledCell>
                    <StyledCell>{withSpaces(data.failed?.x64)}</StyledCell>
                    <StyledCell>{withSpaces(data.failed?.total)}</StyledCell>
                </tr>
                <tr>
                    <StyledNamingCell>Updating</StyledNamingCell>
                    <StyledCell>{withSpaces(data.updating?.x32)}</StyledCell>
                    <StyledCell>{withSpaces(data.updating?.x64)}</StyledCell>
                    <StyledCell>{withSpaces(data.updating?.total)}</StyledCell>
                </tr>
                <tr>
                    <StyledNamingCell>Updated</StyledNamingCell>
                    <StyledCell>{withSpaces(data.updated?.x32)}</StyledCell>
                    <StyledCell>{withSpaces(data.updated?.x64)}</StyledCell>
                    <StyledCell>{withSpaces(data.updated?.total)}</StyledCell>
                </tr>
                <tr>
                    <StyledNamingCell>Update Failed</StyledNamingCell>
                    <StyledCell>{withSpaces(data.updateFailed?.x32)}</StyledCell>
                    <StyledCell>{withSpaces(data.updateFailed?.x64)}</StyledCell>
                    <StyledCell>{withSpaces(data.updateFailed?.total)}</StyledCell>
                </tr>
                <tr>
                    <StyledNamingCell>Token is Absent</StyledNamingCell>
                    <StyledCell>{withSpaces(data.tokenIsAbsent?.x32)}</StyledCell>
                    <StyledCell>{withSpaces(data.tokenIsAbsent?.x64)}</StyledCell>
                    <StyledCell>{withSpaces(data.tokenIsAbsent?.total)}</StyledCell>
                </tr>
                <tr>
                    <StyledNamingCell>Total</StyledNamingCell>
                    <StyledCell>{withSpaces(data.total?.x32)}</StyledCell>
                    <StyledCell>{withSpaces(data.total?.x64)}</StyledCell>
                    <StyledCell>{withSpaces(data.total?.total)}</StyledCell>
                </tr>
            </tbody>
        </StyledTable>);
};