import React, { FC, useState, Dispatch, useEffect } from "react";
import { ApplicationsInfo } from "src/shared/dtos";
import styled from "styled-components";
import { ThinScrollbar } from "../mixins/ThinScrollbar";
import api from "./api";
import { useSubscription } from "src/shared/helpers";
import { defer } from "rxjs";

const InfoList = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 10px;
    height: 100%;
    list-style-type: none;
    user-select: none;
    overflow: auto;
    ${ThinScrollbar}
`;

const InfoListItem = styled.li`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    min-height: 20px;
    margin-bottom: 10px 0;
    color: #343a40;
    font-size: 1.1rem;
    font-family: sans-serif;
`;

const Value = styled.span`
    text-align: end;
    min-width: 20px;
`;

const Text = styled.span`
    line-height: 100%;
`;

interface Props {
    setLoading: Dispatch<boolean>;
}

export const ApplicationsInfoList: FC<Props> = ({ setLoading }) => {
    const [data, setData] = useState<ApplicationsInfo | null>(null);

    const getApplicationsInfo = useSubscription(() => defer(
        () => api.getApplicationsInfo()).subscribe(val => {
            setLoading(false);
            setData(val);
        }),
        [api.getApplicationsInfo]);

    useEffect(() => {
        setLoading(true);
        getApplicationsInfo();
    }, []);

    return data === null ? null
        : (<InfoList>
            <InfoListItem>
                <Text>Applications Count</Text>
                <Value>{data.applicationsCount}</Value>
            </InfoListItem>
            <InfoListItem>
                <Text>Disabled Applications Count</Text>
                <Value>{data.disabledApplicationsCount}</Value>
            </InfoListItem>
            <InfoListItem>
                <Text>Featured Applications Count</Text>
                <Value>{data.featuredApplicationsCount}</Value>
            </InfoListItem>
            <InfoListItem>
                <Text>Graphics Customized Applications Count</Text>
                <Value>{data.graphicsCustomizedApplicationsCount}</Value>
            </InfoListItem>
            <InfoListItem>
                <Text>Last Update</Text>
                <Value>{data.lastUpdate.slice(0, 10)}</Value>
            </InfoListItem>
        </InfoList>
        );
};