import React, { useMemo } from "react";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import { GamestoreApplicationLocalization, GoogleApkDetails, GoogleApplicationLocalization } from "src/shared/dtos";
import { Rating } from "src/shared/components/Rating";
import { GetGoogleAppLink, GetGoogleAppPublisherLink, GetGoogleAppCategoryLink } from "src/shared/helpers";
import noImage from "src/images/no-image-icon.png";
import { GamestoreAppMainInfo } from "./applicationReducer";

const ResponsiveImg = styled.img`
    max-width: 100%;
`;

const DateTimeBlock = styled.div`
    color: #6c757d;
    margin-top: 0.25em;
`;

const DisableReasonBlock = styled.div`
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 10px;
    width: 80%;
    min-height: 180px;
    color: #6c757d;
`;

interface Props {
    app: GamestoreAppMainInfo;
    locale: GamestoreApplicationLocalization;
    googleLocale?: GoogleApplicationLocalization;
    googleApkDetails?: GoogleApkDetails;
    isNewApp: boolean;
}

const toDateTime = (value: string | undefined) => {
    if (!value) {
        return "[not defined]";
    }

    const date = new Date(value);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const EditInfo = (props: Props) => {
    const onImageClick = props.isNewApp && !props.locale.iconUrl
        ? (e: React.FormEvent) => e.preventDefault()
        : undefined;

    const googleAppUpdatedAt = useMemo(
        () => toDateTime(props.googleApkDetails?.updatedAt),
        [props.googleApkDetails]);

    const modifiedAt = useMemo(() => toDateTime(props.app.modifiedAt), [props.app.modifiedAt]);

    const googleAppUpdatedOn = useMemo(
        () => toDateTime(props.app.googleApplication.updatedOn ?? ""),
        [props.app.googleApplication.updatedOn]);

    const disableDate = useMemo(
        () => props.app.disableReason === undefined
            ? undefined : new Date(props.app.disableReason.disableDate).toLocaleString(), [props.app.disableReason?.disableDate]);

    const testedVersions = useMemo(
        () => (props.app.disableReason?.testedVersions?.length ?? 0) === 0
            ? "[ ]" : props.app.disableReason!.testedVersions?.join(", "), [props.app.disableReason?.testedVersions]);

    return (
        <div>
            <Row className="pr-sm-4">
                <Col className="d-none d-sm-block mb-3" lg={4}>
                    <a href={props.locale.iconUrl ?? props.googleLocale?.iconUrl ?? "#"} target="_blank" onClick={onImageClick}>
                        <ResponsiveImg
                            src={props.locale.iconUrl ?? props.googleLocale?.iconUrl ?? noImage}
                            alt={props.locale.iconUrl ?? props.googleLocale?.iconUrl ?? "Application icon"} />
                    </a>
                </Col>
                <Col className="mb-3" lg={8}>
                    <Rating
                        className="float-right"
                        title={`${props.app.rating ?? props.app.googleApplication.rating}`}
                        value={props.app.rating ?? props.app.googleApplication.rating}
                        max={5}
                        size={26} />
                    <a
                        href={GetGoogleAppLink(props.app.id, props.locale.language)}
                        target="_blank">
                        <h3>
                            {props.locale.title ?? props.googleLocale?.title}
                        </h3>
                    </a>
                    <a
                        className="mr-3"
                        href={GetGoogleAppPublisherLink(
                            props.locale.language,
                            props.locale.publisher ?? props.googleLocale?.publisher,
                            props.locale.publisherId ?? props.googleLocale?.publisherId)}
                        target="_blank">
                        {props.locale.publisher ?? props.googleLocale?.publisher}
                    </a>
                    <a
                        className="d-block d-md-inline"
                        href={GetGoogleAppCategoryLink(props.app.googleApplication.categoryId, props.locale.language)}
                        target="_blank">
                        {props.app.googleApplication.category.name}
                    </a>
                    <DateTimeBlock>
                        APK updated at: <span>{googleAppUpdatedAt}</span>
                    </DateTimeBlock>
                    <DateTimeBlock>
                        Google app updated on: <span>{googleAppUpdatedOn}</span>
                    </DateTimeBlock>
                    <DateTimeBlock>
                        Modified at: <span>{modifiedAt}</span>
                    </DateTimeBlock>
                </Col>
            </Row>
            <p className="mb-2">Latest Record of Disabling</p>
            <DisableReasonBlock>
                {props.app.disableReason === undefined
                    ? <p>No prior record</p>
                    : <>
                        <p className="mb-0">Disabled On: {disableDate}</p>
                        <p className="mb-0">Disabled By: {props.app.disableReason.disabledBy}</p>
                        <p className="mb-2">Version(s) Tested: {testedVersions}</p>
                        <p>{props.app.disableReason.description}</p>
                    </>
                }
            </DisableReasonBlock>
        </div>
    );
};
