import React, { useState, useCallback, useMemo, useEffect } from "react";
import { defer } from "rxjs";
import { OrderDirection, ActiveInstallsStatisticsListDataType, ActiveInstallsStatistics } from "src/shared/dtos";
import {
    DocumentTitle,
    HeaderField,
    Loader,
    RouteLink,
    RouteUserProps,
    StatisticsDatePicker,
    StatisticsHeader
} from "src/shared/components";
import {
    Column,
    DefaultCellRenderer,
    LoadRequest,
    InfiniteTable,
    SearchOptions,
    multiValueSearchFactory,
    SuggestionTag
} from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { Content, VerticalBox } from "src/shared/components/flex";
import { routes } from "src/shared/routes";
import {
    deserializeQueryParameters,
    QueryParameterDescriptor,
    serializeQueryParameters,
    pushUrl,
    useSubscription,
    deserializeDate,
    serializeDate
} from "src/shared/helpers";
import api, { ListRequest } from "../api";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";

const keyDataTypes: { [key: string]: ActiveInstallsStatisticsListDataType } = {
    "productVersion": ActiveInstallsStatisticsListDataType.ProductVersion,
    "count": ActiveInstallsStatisticsListDataType.Count,
};

const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType as ActiveInstallsStatisticsListDataType);

function getSearchValueByKey<T>(key: string, searchOptions?: Array<SearchOptions<unknown>>): T | undefined {
    return searchOptions?.find(s => s.key === key)?.value as T;
}

const sortQueryParameterDescriptors: QueryParameterDescriptor[] = [
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    {
        paramType: "search",
        paramName: "reportDate",
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "productVersion",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    }
];

export const ListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [sort, tableFilters, initDate] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...sortQueryParameterDescriptors);
        const searchParams = allParams
            .filter(p => p.paramType === "search")
            .map(qp => ({ key: qp.paramName, value: qp.value } as SearchOptions<unknown>));
        let orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        let orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;
        let dateParam = allParams.find(p => p.paramType === "search" && p.paramName === "reportDate")?.value as Date | undefined;

        if (orderBy === undefined) {
            orderBy = "productVersion";
        }
        if (orderDir === undefined) {
            orderDir = SortOrder.DESC;
        }
        if (dateParam === undefined) {
            dateParam = new Date();
            dateParam.setDate(dateParam.getDate() - 1);
        }

        return [{ key: orderBy, order: orderDir } as SortOptions, searchParams, dateParam];
    }, [location.search]);

    const [items, setItems] = useState<ActiveInstallsStatistics[]>([]);
    const [dates, setDates] = useState<string[]>([]);
    const [reportDate, setReportDate] = useState<Date>(initDate);
    const [versions, setVersions] = useState<string[] | undefined>();
    const changeReportDate = (date: Date) => setReportDate(date);

    const updateDates = useSubscription(() => defer(() => api.getDates()).subscribe(setDates), [api.getDates]);

    useEffect(() => {
        updateDates();
    }, []);

    const updateVersions = useSubscription(() => defer(() => api.getVersionsByDate(reportDate)).subscribe(inputVersions => {
        setVersions(undefined);
        const vers = inputVersions.map(a => a.split(".").map(n => +n + 100000).join(".")).sort().reverse()
            .map(a => a.split(".").map(n => +n - 100000).join("."));
        setVersions(["0.0.0"].concat(vers.slice(0, inputVersions.length - 1)));
    }), [reportDate]);

    useEffect(() => {
        updateVersions();
    }, [updateVersions]);

    const updateData = useSubscription((
        requestObj: ListRequest,
        isReload: boolean,
        resolve: (val: boolean) => void) => defer(() => api.list(requestObj)).subscribe(result => {
            if (result.length > 0) {
                setItems(prev => isReload ? result : prev.concat(result));
            }
            resolve(result.length === 0);
        }), [setItems, api.list]);

    const load = useCallback(
        (request: LoadRequest) => {
            if (request.reload) {
                setItems([]);
            }

            const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : ActiveInstallsStatisticsListDataType.ProductVersion;
            const searchRequest = {
                orderBy: orderByKey,
                orderDir: orderByKey !== undefined
                    ? request.sort?.order === SortOrder.DESC ? OrderDirection.Desc : OrderDirection.Asc
                    : undefined,
                reportDate: serializeDate(reportDate),
                productVersion: getSearchValueByKey<string[]>("productVersion", request.search)
            };

            const requestObj: ListRequest = {
                limit: request.limit,
                offset: request.offset,
                date: reportDate,
                versionSearch: searchRequest.productVersion,
                orderBy: searchRequest.orderBy,
                orderDirection: searchRequest.orderDir ?? OrderDirection.Desc
            };

            const url = routes.activeInstallsStatistics.url({
                searchParams: serializeQueryParameters(searchRequest, ...sortQueryParameterDescriptors)
            });
            pushUrl(location, history, url);

            return new Promise<boolean>(resolve => {
                updateData(requestObj, request?.reload, resolve);
            });
        }, [reportDate, location, history, updateData, setItems]);

    const versionMapper = useCallback((version: string): SuggestionTag => {
        const found = versions?.find(i => i === version);
        return {
            value: found ?? "",
            label: found === "0.0.0" ? "[All]" : (found ?? version)
        };
    }, [versions]);

    const versionRenderer = useMemo(() =>
        (props: CellProps<string, ActiveInstallsStatistics>) => {
            const { cellData, ...restProps } = props;
            const version = cellData?.toString();
            let title = version;
            if (title === "0.0.0") {
                title = "[All]";
            }
            const link = (
                <RouteLink
                    user={user}
                    to={{ route: routes.viewActiveInstallsStatistic, args: { version, endDate: props.rowData.date.slice(0, 10) } }}
                    className="d-inline">
                    {title}
                </RouteLink>
            );
            return <DefaultCellRenderer cellData={link} title={title} {...restProps} />;
        }, [reportDate]);

    const intRenderer = useMemo(() =>
        (props: CellProps<number, ActiveInstallsStatistics>) => {
            const { cellData, ...restProps } = props;
            const text = cellData.toLocaleString(
                undefined,
                { maximumFractionDigits: 0, minimumFractionDigits: 0 }
            );
            const title = `${cellData}`;
            return <DefaultCellRenderer cellData={text} title={title} {...restProps} />;
        }, [reportDate]);

    const select = makeSelect<ActiveInstallsStatistics>();
    return (
        <VerticalBox>
            <DocumentTitle title="Active Users Statistics" />
            <StatisticsHeader
                user={user}
                title="Active Users Statistics"
            >
                <HeaderField label="Date">
                    <StatisticsDatePicker
                        id="date"
                        className="form-control"
                        popperPlacement="top-end"
                        selected={reportDate}
                        onChange={changeReportDate}
                        dates={dates}
                    />
                </HeaderField>
            </StatisticsHeader>
            <Content>
                {versions === undefined && <Loader />}
                {versions !== undefined &&
                    <InfiniteTable
                        items={items}
                        load={load}
                        sortBy={sort}
                        filters={tableFilters}
                        renderContext={reportDate.toISOString()}
                        hideTopPanel={true}
                        minimumLoadCount={200}
                    >
                        <Column
                            key="productVersion"
                            dataGetter={select(a => a.productVersion)}
                            title="Product Version"
                            width={400}
                            cellRenderer={versionRenderer}
                            searchRenderer={multiValueSearchFactory(versions, versionMapper)}
                        />
                        <Column
                            key="count"
                            title="Count"
                            width={300}
                            dataGetter={select(a => a.count)}
                            cellRenderer={intRenderer}
                        />
                    </InfiniteTable>}
            </Content>
        </VerticalBox>);
});
