import React, { useCallback, useMemo, useState, MouseEvent } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, FormGroup, Input, Label } from "reactstrap";
import { setFromInput } from "src/shared/helpers";
import styled from "styled-components";
import { SearchCellHeaderProps } from ".";

const StyledDropdown = styled(Dropdown)`
    width: 100%;
    margin: 8px 0 8px 0;
`;

const StyledDropdownToggle = styled(DropdownToggle)`
    width: 100%;
`;

const StyledFormGroup = styled(FormGroup)`
    padding: 2px 0px;
    &:hover {
        background-color: #deebff;
    }
`;

const StyledLabel = styled(Label)`
    width: 100%;
    margin-left: 24px;
    cursor: pointer;
`;

const SearchContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 27px;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #d1d1d1;
    padding: 4px 23px 4px 4px;
    cursor: text;
`;

const SearchInput = styled.input.attrs({ type: "text", placeholder: "Search..." })`
    padding: 1px;
    max-width: 100%;
    border: none;
    outline: none;
`;

const SearchText = styled.div`
    margin-bottom: 0;
    background-color: #fff;
    padding: 1px;
    font-weight: 500;
    font-size: 1.1em;
`;

const ClearButton = styled.button`
    position: absolute;
    top: 3px;
    right: 3px;
    padding: 2px;
    background-color: #fff;
    cursor: pointer;
    border: none;
    outline: none;
    &:focus {
        outline: none;
    }
    font-weight: 700;
    padding: 2px 4px;
    &:after {
        content: "\\2715";
    }
`;

type Props = SearchCellHeaderProps<unknown[], unknown> & {
    suggestions: unknown[],
    valueMapper: (val: unknown) => string;
};

const MultiValueCheckboxSearch = (props: Props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [search, setSearch] = useState("");

    const filteredSuggestions = useMemo(
        () => props.suggestions.filter(s => new RegExp(search).test(props.valueMapper(s))),
        [props.suggestions, search, props.searchValue, props.valueMapper]);

    const toggleDropdown = useCallback(() => setDropdownOpen(prevState => !prevState), []);

    const onToggle = useCallback(
        (val: unknown) => (enabled: boolean) => {
            if (enabled && !props.searchValue?.includes(val)) {
                return props.setSearchValue((props.searchValue ?? []).concat(val));
            }
            if (!enabled && props.searchValue?.includes(val)) {
                return props.setSearchValue((props.searchValue ?? []).filter(v => v !== val));
            }
        },
        [props.searchValue]);

    const suggestionTags = useMemo(
        () => filteredSuggestions.map(s => ({ value: s, label: props.valueMapper(s) })),
        [filteredSuggestions, props.valueMapper]);

    const values = useMemo(
        () => (props.searchValue ?? []).sort().map(props.valueMapper).join(" & "),
        [props.searchValue, props.valueMapper]);

    const clearTags = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        props.setSearchValue([]);
        setSearch("");
    }, []);

    return (
        <StyledDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <StyledDropdownToggle tag="div">
                <SearchContainer>
                    <SearchText>{values}</SearchText>
                    <SearchInput value={search} onChange={setFromInput(setSearch)} />
                    <ClearButton onClick={clearTags} />
                </SearchContainer>
            </StyledDropdownToggle>
            <DropdownMenu >
                {suggestionTags.map(c => (
                    <StyledFormGroup check key={c.label}>
                        <StyledLabel for={c.label} check >
                            <Input
                                type="checkbox"
                                id={c.label}
                                name={c.label}
                                checked={props.searchValue?.includes(c.value) ?? false}
                                onChange={setFromInput(onToggle(c.value))}
                            />
                            {c.label}
                        </StyledLabel>
                    </StyledFormGroup>
                ))}
            </DropdownMenu>
        </StyledDropdown>
    );
};

export function multiValueCheckboxFactory(
    suggestions: unknown[],
    valueMapper: (val: unknown) => string) {
    return (props: SearchCellHeaderProps<unknown[], unknown>): JSX.Element => (
        <MultiValueCheckboxSearch {...props} suggestions={suggestions} valueMapper={valueMapper} />
    );
}