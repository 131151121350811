import React, { useRef, useCallback, useMemo } from "react";
import ReactTags, { Tag } from "react-tags-typeahead";
import styled from "styled-components";
import { ThinScrollbar } from "./mixin";

export type StyledTagsProps = {
    tagColor?: string;
    bgColor?: string;
    tagBgColor?: string;
    suggestionTagColor?: string;
    suggestionTagBgColor?: string;
    suggestionTagHoverColor?: string;
    suggestionTagHoverBgColor?: string;
    searchTextColor?: string;
};

const StyledTags = styled.div<StyledTagsProps>`
    background: ${props => props.bgColor};
    margin: 8px 0 8px 0;
    border-radius: 5px;
    width: 100%;
    min-height: 27px;
    height: auto;
    border: 1px solid #d1d1d1;
    position: relative;

    ul {
        ${ThinScrollbar}
    }

    .react-tags {
        position: relative;
        padding: 1px 23px 1px 3px;
        border-radius: 5px;
        min-height: 27px;
        height: auto;
        font-size: 1em;
        line-height: 1.2;
        cursor: text;
    }

    .react-tags.is-focused {
        border-color: #b1b1b1;
    }

    .react-tags__selected {
        display: inline;
    }

    .react-tags__selected-tag {
        display: inline-block;
        box-sizing: border-box;
        margin: 3px 2px 1px 1px;
        padding: 2px 5px 6px 6px;
        border: none;
        border-radius: 2px;
        background: ${props => props.tagBgColor};
        max-height: 21px;
        font-size: 13px;
        bottom: 10px;
        color: ${props => props.tagColor};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: inherit;
    }

    .react-tags__selected-tag:after {
        content: "\\2715";
        color: ${props => props.tagColor};
        padding: 0px 0px 2px 6px;
        margin-left: 8px;
        font-size: 10px;
    }

    .react-tags__search {
        display: block;
        padding: 0;
        left: 1px;
        bottom: 1px;
        max-width: 100%;
    }

    @media screen and (min-width: 30em) {
        .react-tags__search {
            position: relative;
        }
    }

    .react-tags__search-input {
        max-width: 100%;
        min-width: fit-content;
        background-color: ${props => props.bgColor};
        color: ${props => props.searchTextColor};
        margin: auto;
        padding: 2px;
        border: 0;
        outline: none;
        font-size: inherit;
        line-height: inherit;
    }

    .react-tags__search-input::-ms-clear {
        display: none;
    }

    .react-tags__suggestions {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: fit-content;
        z-index: 1000;
    }

    @media screen and (min-width: 30em) {
        .react-tags__suggestions {
            width: 240px;
        }
    }

    .react-tags__suggestions ul {
        white-space: nowrap;
        max-width: fit-content;
        background: url(new_arrow.png) no-repeat right #ddd;
        border: 1px solid #ccc;
        margin: 0;
        padding: 0;
        font-size: 1rem;
        list-style: none;
        background: ${props => props.suggestionTagBgColor};
        border-radius: 4px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
    }

    .react-tags__suggestions li {
        border-right: 7px solid ${props => props.suggestionTagBgColor};
        color: ${props => props.suggestionTagColor};
        padding: 8px 12px;
        font-weight: 400;
        font-family: "Segoe UI", Roboto;
        line-height: 1.5;
        display: block;
        font-size: inherit;
        max-width: 100%;
        min-width: 130px;
        padding: 5px;
        height: 34px;
    }

    .react-tags__suggestions li mark {
        display: none;
        background: none;
    }

    .react-tags__suggestions li mark:not(:empty) {
        display: inline-block;
        width: auto;
        margin: 0;
        padding: 0;
    }

    .react-tags__suggestions li:hover {
        cursor: default;
        border-right: 7px solid ${props => props.suggestionTagHoverBgColor};
        color: ${props => props.suggestionTagHoverColor};
        background-color: ${props => props.suggestionTagHoverBgColor};
    }

    .react-tags__suggestions li.is-active {
        border-right: 7px solid #2684ff;
        background: #2684ff;
        color: hsl(0,0%,100%);
    }

    .react-tags__suggestions li.is-disabled {
        opacity: 0.5;
        cursor: auto;
    }

    .clear {
        border: none;
        background: ${props => props.bgColor};
        color: ${props => props.searchTextColor};
        font-weight: 700;
        font-size: .8rem;
        position: absolute;
        right: 3px;
        top: 3px;
    }

    .clear:focus {
        outline: none;
    }

    .clear:after {
        content: "\\2715"
    }
`;

StyledTags.defaultProps = {
    tagColor: "hsl(0,0%,20%)",
    bgColor: "#fff",
    tagBgColor: "#e6e6e6",
    suggestionTagColor: "hsl(0,0%,20%)",
    suggestionTagBgColor: "#fff",
    suggestionTagHoverColor: "hsl(0,0%,20%)",
    suggestionTagHoverBgColor: "#deebff",
    searchTextColor: "hsl(0,0%,20%)",
};

export type SuggestionTag = {
    value: string | number;
    label: string;
};

export type SuggestionMapper<T> = (value: T) => SuggestionTag;

type Props<T extends string | number> = {
    suggestions?: T[];
    values: T[];
    onAddition: (val: string) => void;
    onDelete: (idx: number) => void;
    clearTags: () => void;
    onInput?: (v: string) => void;
    onValidate?: (val: string) => boolean;
    valueTagMapper?: SuggestionMapper<T>;
    maxSuggestionsLength?: number;
    allowNew?: boolean;
    styles?: StyledTagsProps;
};

export const MultiValueSearch = <T extends string | number>(props: Props<T>) => {

    const valueMapper = useCallback((val) => props.valueTagMapper !== undefined
        ? props.valueTagMapper(val)
        : ({ value: val, label: val }) as SuggestionTag, [props.valueTagMapper]);

    const tags = useMemo(
        () => props.values?.map(valueMapper).map(tag => ({
            id: tag.value,
            name: tag.label
        } as Tag)) ?? [],
        [props.values, valueMapper]);

    const suggestions = useMemo(
        () => props.suggestions !== undefined
            ? props.suggestions
                .map(valueMapper)
                .map(tag => ({ id: tag.value, name: tag.label } as Tag))
                .filter(v => tags.find(t => t.id === v.id) === undefined)
            : [],
        [tags, props.suggestions, valueMapper]);

    const onAddition = useCallback((tag: Tag) => {
        const value = tag.id !== undefined ? tag.id + "" : tag.name;
        if (props.onValidate !== undefined && !props.onValidate(value)) {
            return;
        }
        props.onAddition(value);
    }, [props.onAddition]);

    const onDelete = useCallback(
        (index: number) => props.onDelete(index),
        [props.onDelete]);

    const ref = useRef<ReactTags>(null);
    const clearTags = useCallback(() => {
        props.clearTags();
        ref.current?.clearInput();
    }, [ref, ref.current, props.clearTags]);

    const stylingProps = useMemo<StyledTagsProps>(() => props.styles !== undefined ? ({
        bgColor: props.styles.bgColor,
        tagBgColor: props.styles.tagBgColor,
        tagColor: props.styles.tagColor,
        suggestionTagBgColor: props.styles.suggestionTagBgColor,
        suggestionTagColor: props.styles.suggestionTagColor,
        suggestionTagHoverColor: props.styles.suggestionTagHoverColor,
        suggestionTagHoverBgColor: props.styles.suggestionTagHoverBgColor,
        searchTextColor: props.styles.searchTextColor
    }) : {}, [props]);

    return (
        <StyledTags {...stylingProps}>
            <ReactTags
                ref={ref}
                tags={tags}
                minQueryLength={0}
                placeholderText="Search..."
                suggestions={suggestions}
                onAddition={onAddition}
                onDelete={onDelete}
                handleInputChange={props.onInput}
                maxSuggestionsLength={props.maxSuggestionsLength ?? suggestions.length}
                allowNew={props.allowNew}
            />
            <button className="clear" type="button" onClick={clearTags} />
        </StyledTags>
    );
};
