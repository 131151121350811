import React, { forwardRef, useCallback, useImperativeHandle, useMemo } from "react";
import { Button, Form, FormGroup, Input, Row, Col } from "reactstrap";
import styled from "styled-components";
import { IoApplicationLocalization, GamestoreIoApplicationLocalization } from "src/shared/dtos";
import { setFromInput, useValidate, ValidateAsyncRef } from "src/shared/helpers";
import { SlideListEditor, Slide, HtmlEditor, ImageSelector, EditField, LoadingButton, overriddenProperty } from "src/shared/components";
import { localizationValidationSchema, locSourcesValidationSchema } from "./validationSchema";

export interface EditLocalizationRef {
    validate: () => boolean;
}

interface Props {
    value: IoApplicationLocalization;
    initial?: IoApplicationLocalization;
    gamestoreLocale?: GamestoreIoApplicationLocalization;
    isNewApp: boolean;
    update: (value: Partial<IoApplicationLocalization>) => void;
    remove: () => void;
    saving: boolean;
    onSubmit: () => void;
    isLastLocalization: boolean;
}

const StyledInput = styled(Input) <{ overridden: number }>`
    background: ${props => props.overridden === 1 ? "lightyellow" : ""};

    :focus {
        background: ${props => props.overridden === 1 ? "lightyellow" : ""};
    }
`;

export const EditLocalization = forwardRef((props: Props, ref: React.MutableRefObject<ValidateAsyncRef>) => {
    const setTitle = (v: string) => props.update({ title: v ? v : undefined });
    const setDescription = (v: string) => props.update({ description: v ? v : undefined });
    const setApplicationUrl = (v: string) => props.update({ applicationUrl: v ? v : undefined });
    const setSourceUrl = (v: string) => props.update({ sourceUrl: v ? v : undefined });
    const setIconUrl = (v: string) => props.update({ iconUrl: v });
    const setThumbnailUrl = (v: string) => props.update({ thumbnailUrl: v });
    const setVideos = (v: string[]) => props.update({ videos: v });
    const setScreenshots = (v: string[]) => props.update({ screenshots: v });

    const setIcon = useCallback((url: string) => {
        setIconUrl(url);
        return "";
    }, []);

    const setThumbnail = useCallback((url: string) => {
        setThumbnailUrl(url);
        return "";
    }, []);

    const { validate, errors, register } = useValidate({ schema: localizationValidationSchema, value: props.value });
    const { validate: validateSources, errors: sourceWarnings } = useValidate({ schema: locSourcesValidationSchema, value: props.value });

    useImperativeHandle(ref, () => ({
        validate: async () => {
            const isValid = (await validate(true)).isValid;
            validateSources();
            return isValid;
        }
    }), [validate, validateSources]);

    const isVideosChanged = useMemo(
        () => props.value.videos.join() !== props.initial?.videos.join(),
        [props.value.videos, props.initial?.videos]);

    const addVideo = useCallback(
        (url: string): [string, number] => {
            url = url.trim();
            if (url === "") {
                return ["", 0];
            }
            setVideos(props.value.videos.concat(url));
            return ["", props.value.videos.length - 1];
        }, [props.value.videos]);

    const removeVideo = useCallback(
        (url: string) => {
            setVideos(props.value.videos.filter(v => v !== url));
        }, [props.value.videos]);

    const isScreenshotsChanged = useMemo(
        () => props.value.screenshots.join() !== props.initial?.screenshots.join(),
        [props.value.screenshots, props.initial?.screenshots]);

    const addScreenshot = useCallback(
        (url: string): [string, number] => {
            url = url.trim();
            if (url === "") {
                return ["", 0];
            }
            setScreenshots(props.value.screenshots.concat(url));
            return ["", props.value.screenshots.length - 1];
        }, [props.value.screenshots]);

    const removeScreenshot = useCallback(
        (url: string) => {
            setScreenshots(props.value.screenshots.filter(s => s !== url));
        }, [props.value.screenshots]);

    const renderIcon = useCallback((url: string): Slide | null => url === "" ? null : { type: "image", url }, []);
    const renderImage = useCallback((url: string): Slide => ({ type: "image", url }), []);
    const renderVideo = useCallback((url: string): Slide => ({ type: "srcVideo", url }), []);

    const save = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        props.onSubmit();
    };

    return (
        <Form className="mt-3" onSubmit={save} noValidate>
            <Row>
                <EditField
                    ref={register("title")}
                    label={overriddenProperty("Title", props.gamestoreLocale?.title !== undefined)}
                    fieldId="appTitle"
                    isChanged={props.value.title !== props.initial?.title}
                    originalValue={props.isNewApp ? undefined : props.initial?.title}
                    errors={errors?.title}>
                    <StyledInput
                        overridden={props.gamestoreLocale?.title !== undefined ? 1 : 0}
                        type="text"
                        id="appTitle"
                        value={props.value.title ?? ""}
                        placeholder={props.value?.title}
                        onChange={setFromInput(setTitle)}
                        disabled={props.saving}
                    />
                </EditField>
                <EditField
                    ref={register("applicationUrl")}
                    label={overriddenProperty("Application Url", props.gamestoreLocale?.applicationUrl !== undefined)}
                    fieldId="applicationUrl"
                    isChanged={props.value.applicationUrl !== props.initial?.applicationUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.applicationUrl}
                    errors={errors?.applicationUrl}>
                    <StyledInput
                        overridden={props.gamestoreLocale?.applicationUrl !== undefined ? 1 : 0}
                        type="text"
                        id="applicationUrl"
                        value={props.value.applicationUrl ?? ""}
                        placeholder={props.value?.applicationUrl}
                        onChange={setFromInput(setApplicationUrl)}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("sourceUrl")}
                    fullWidth
                    label={"Source Url"}
                    fieldId="sourceUrl"
                    isChanged={props.value.sourceUrl !== props.initial?.sourceUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.sourceUrl}
                    errors={errors?.sourceUrl}>
                    <StyledInput
                        type="text"
                        id="sourceUrl"
                        value={props.value.sourceUrl ?? ""}
                        placeholder={props.value?.sourceUrl}
                        onChange={setFromInput(setSourceUrl)}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    fullWidth
                    label={overriddenProperty("Description", props.gamestoreLocale?.description !== undefined)}
                    fieldId="description"
                    isChanged={props.value.description !== props.initial?.description}>
                    <HtmlEditor
                        highlight={props.gamestoreLocale?.description !== undefined}
                        id="description"
                        value={props.value.description}
                        onChange={setDescription}
                        disabled={props.saving}
                        placeholder={props.value?.description}
                    />
                </EditField>
                <EditField
                    ref={register("thumbnailUrl")}
                    label={overriddenProperty("Thumbnail URL", props.gamestoreLocale?.thumbnailUrl !== undefined)}
                    fieldId="thumbnailUrl"
                    isChanged={props.value.thumbnailUrl !== props.initial?.thumbnailUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.thumbnailUrl}
                    errors={errors?.thumbnailUrl ?? sourceWarnings?.thumbnailUrl}>
                    <ImageSelector
                        highlight={props.gamestoreLocale?.thumbnailUrl !== undefined}
                        setInputId="thumbnailUrl"
                        value={props.value?.thumbnailUrl ?? ""}
                        set={setThumbnail}
                        delete={setThumbnail}
                        render={renderIcon}
                        imageDimensions={{ width: "12rem", height: "25.5rem" }}
                        invalid={(errors?.thumbnailUrl?.length ?? 0) > 0}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("iconUrl")}
                    label={overriddenProperty("Icon URL", props.gamestoreLocale?.iconUrl !== undefined)}
                    fieldId="iconUrl"
                    isChanged={props.value.iconUrl !== props.initial?.iconUrl}
                    originalValue={props.isNewApp ? undefined : props.initial?.iconUrl}
                    errors={errors?.iconUrl ?? sourceWarnings?.iconUrl}>
                    <ImageSelector
                        highlight={props.gamestoreLocale?.iconUrl !== undefined}
                        setInputId="iconUrl"
                        value={props.value?.iconUrl ?? ""}
                        set={setIcon}
                        delete={setIcon}
                        render={renderIcon}
                        imageDimensions={{ width: "12rem", height: "25.5rem" }}
                        invalid={(errors?.iconUrl?.length ?? 0) > 0}
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("videos")}
                    label={overriddenProperty("Videos", (props.gamestoreLocale?.videos.length ?? 0) !== 0)}
                    fieldId="videos"
                    isChanged={isVideosChanged}
                    errors={errors?.videos ?? sourceWarnings?.videos}>
                    <SlideListEditor
                        highlight={(props.gamestoreLocale?.videos.length ?? 0) !== 0}
                        addInputId="videos"
                        values={props.value.videos.length === 0
                            ? ([])
                            : props.value.videos}
                        add={addVideo}
                        remove={removeVideo}
                        render={renderVideo}
                        placeholder="Add video url"
                        disabled={props.saving} />
                </EditField>
                <EditField
                    ref={register("screenshots")}
                    label={overriddenProperty("Screenshots", (props.gamestoreLocale?.screenshots.length ?? 0) > 0)}
                    fieldId="screenshots"
                    isChanged={isScreenshotsChanged}
                    errors={errors?.screenshots ?? sourceWarnings?.screenshots}>
                    <SlideListEditor
                        highlight={(props.gamestoreLocale?.screenshots.length ?? 0) > 0}
                        addInputId="screenshots"
                        values={props.value.screenshots.length === 0
                            ? ([])
                            : props.value.screenshots}
                        add={addScreenshot}
                        remove={removeScreenshot}
                        render={renderImage}
                        invalid={(errors?.screenshots?.length ?? 0) > 0}
                        disabled={props.saving} />
                </EditField>
            </Row>
            <Row>
                <Col xs={12} className="text-right">
                    <FormGroup>
                        <Button
                            type="button"
                            color="danger"
                            className="mr-2"
                            title={props.isLastLocalization ? "Unable to remove last localization." : undefined}
                            disabled={props.isLastLocalization || props.saving}
                            onClick={props.remove}>
                            Remove localization
                        </Button>
                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={props.saving}
                            loadingText="Saving..."
                            position="end">
                            Save application
                        </LoadingButton>
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );
});
