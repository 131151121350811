import { SuggestionTag } from "../components/MultiValueSearch";
import { Category } from "../dtos";

export function mapCategorySuggestionTag(category?: Category, allCategories?: Category[]): SuggestionTag {

    if (category === undefined || allCategories === undefined){
        return { label: "", value: "" };
    }

    const value = category.id;
    let label = category.name;

    if (!category.parentId){
        return { value, label: "All " + label };
    }

    let current: Category | undefined = {...category};

    while (current?.parentId !== undefined) {
        current = allCategories.find(c => c.id === current?.parentId);
        if (current === undefined) {
            break;
        }
    }

    if (current !== undefined && current.id !== category.id){
        label = `${current.name} / ${label}`;
    }

    return { value, label };
}