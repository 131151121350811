import React, { useState, useCallback, useMemo, useEffect } from "react";
import { noop, defer } from "rxjs";
import { OrderDirection, GamingMinutesStatisticsListDataType, GamingMinutesStatisticsSummary } from "src/shared/dtos";
import { deserializeDate, serializeDate, setFromInput, useSubscription } from "src/shared/helpers";
import {
    DocumentTitle,
    HeaderField,
    Loader,
    RouteLink,
    RouteUserProps,
    StatisticsDatePicker,
    StatisticsHeader
} from "src/shared/components";
import {
    Column,
    DefaultCellRenderer,
    LoadRequest,
    InfiniteTable,
    SearchOptions,
    StringSearch,
    multiValueSearchFactory,
    SuggestionTag
} from "src/shared/components/InfiniteTable";
import { CellProps, makeSelect, SortOptions, SortOrder } from "src/shared/components/ReactBaseTable";
import { Content, VerticalBox } from "src/shared/components/flex";
import { routes } from "src/shared/routes";
import {
    useFunctionState,
    deserializeQueryParameters,
    QueryParameterDescriptor,
    serializeQueryParameters,
    pushUrl
} from "src/shared/helpers";
import api from "../api";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
import { FormGroup, Input, Label } from "reactstrap";

const StyledDefaultCellRenderer = styled(DefaultCellRenderer)`
    span {
        text-align: right;
        padding-right: 50px;
    }
`;

const keyDataTypes: { [key: string]: GamingMinutesStatisticsListDataType } = {
    "title": GamingMinutesStatisticsListDataType.Title,
    "appId": GamingMinutesStatisticsListDataType.ApplicationId,
    "count": GamingMinutesStatisticsListDataType.Count,
    "movingAverageCount": GamingMinutesStatisticsListDataType.MovingAverageCount,
    "totalMinutes": GamingMinutesStatisticsListDataType.TotalMinutes,
    "movingAverageTotalMinutes": GamingMinutesStatisticsListDataType.MovingAverageTotalMinutes,
    "averageMinutes": GamingMinutesStatisticsListDataType.AverageMinutes,
    "movingAverageAverageMinutes": GamingMinutesStatisticsListDataType.MovingAverageAverageMinutes,
    "standardDeviationMinutes": GamingMinutesStatisticsListDataType.StandardDeviationMinutes,
    "productVersion": GamingMinutesStatisticsListDataType.ProductVersion,
};

const dataTypeToKey = (dataType: string) =>
    Object.keys(keyDataTypes).find(k => keyDataTypes[k] === dataType as GamingMinutesStatisticsListDataType);

function getSearchValueByKey<T>(key: string, searchOptions?: Array<SearchOptions<unknown>>): T | undefined {
    return searchOptions?.find(s => s.key === key)?.value as T;
}

const sortQueryParameterDescriptors: QueryParameterDescriptor[] = [
    {
        paramType: "sort",
        paramName: "orderBy",
        deserializer: (value: string) => dataTypeToKey(value)
    },
    {
        paramType: "sort",
        paramName: "orderDir",
        deserializer: (value: string) => value === OrderDirection.Desc ? SortOrder.DESC : SortOrder.ASC
    },
    {
        paramType: "search",
        paramName: "reportDate",
        deserializer: deserializeDate
    },
    {
        paramType: "search",
        paramName: "movingAvgDays",
        deserializer: (value: string) => value !== undefined ? parseInt(value, 10) : 7
    },
    { paramType: "search", paramName: "appId" },
    { paramType: "search", paramName: "title" },
    {
        paramType: "search",
        paramName: "productVersion",
        serializer: (value: string[]) => value !== undefined && value.length > 0 ? value.join(",") : undefined,
        deserializer: (value: string) => value.split(",")
    }
];

export const ListView = withRouter(({ user, history, location }: RouteComponentProps<{}> & RouteUserProps) => {
    const [sort, tableFilters, initDate, initMovingAvgDays] = useMemo(() => {
        const allParams = deserializeQueryParameters(location.search, ...sortQueryParameterDescriptors);
        const searchParams = allParams
            .filter(p => p.paramType === "search")
            .map(qp => ({ key: qp.paramName, value: qp.value } as SearchOptions<unknown>));
        let orderBy = allParams.find(p => p.paramType === "sort" && p.paramName === "orderBy")?.value as string | undefined;
        let orderDir = allParams.find(p => p.paramType === "sort" && p.paramName === "orderDir")?.value as SortOrder | undefined;
        let dateParam = allParams.find(p => p.paramType === "search" && p.paramName === "reportDate")?.value as Date | undefined;
        let movingAvgDaysParam = allParams.find(p => p.paramType === "search" && p.paramName === "movingAvgDays")?.value as number | undefined;

        const defaultMovingAvgDays = 7; // Week.
        movingAvgDaysParam = movingAvgDaysParam ?? defaultMovingAvgDays;

        if (orderBy === undefined) {
            orderBy = "totalMinutes";
        }
        if (orderDir === undefined) {
            orderDir = SortOrder.DESC;
        }
        if (dateParam === undefined) {
            dateParam = new Date();
            dateParam.setDate(dateParam.getDate() - 1);
        }

        return [{ key: orderBy, order: orderDir } as SortOptions, searchParams, dateParam, movingAvgDaysParam];
    }, [location.search]);

    const [items, setItems] = useState<GamingMinutesStatisticsSummary[]>([]);
    const [dates, setDates] = useState<string[]>([]);
    const [reportDate, setReportDate] = useState<Date>(initDate);
    const [versions, setVersions] = useState<string[] | undefined>();
    const [movingAvgDays, setMovingAvgDays] = useState<number>(initMovingAvgDays);
    const [showPlaystoreActivity, setShowPlaystoreActivity] = useState<boolean>(false);
    const changeReportDate = (date: Date) => setReportDate(date);

    const updateDates = useSubscription(() => defer(() => api.getDates()).subscribe(setDates), [api.getDates]);

    useEffect(() => {
        updateDates();
    }, []);

    const [unsubscribe, setUnsubscribe] = useFunctionState(noop);
    useEffect(() => unsubscribe, [unsubscribe]);

    const load = useCallback(
        (request: LoadRequest) => {
            if (request.reload) {
                setItems([]);
            }

            const orderByKey = request.sort !== undefined ? keyDataTypes[request.sort.key] : GamingMinutesStatisticsListDataType.TotalMinutes;
            const searchRequest = {
                orderBy: orderByKey,
                orderDir: orderByKey !== undefined
                    ? request.sort?.order === SortOrder.DESC ? OrderDirection.Desc : OrderDirection.Asc
                    : undefined,
                reportDate: serializeDate(reportDate),
                movingAvgDays,
                showPlaystoreActivity,
                title: getSearchValueByKey<string>("title", request.search),
                appId: getSearchValueByKey<string>("appId", request.search),
                productVersion: getSearchValueByKey<string[]>("productVersion", request.search)
            };
            const observable = defer(() => api.list({
                limit: request.limit,
                offset: request.offset,
                date: reportDate,
                movingAvgDays,
                showPlaystoreActivity,
                appIdSearch: searchRequest.appId,
                titleSearch: searchRequest.title,
                productVersionSearch: searchRequest.productVersion,
                orderBy: searchRequest.orderBy,
                orderDirection: searchRequest.orderDir ?? OrderDirection.Desc
            }));
            const url = routes.gamingStatistics.url({
                searchParams: serializeQueryParameters(searchRequest, ...sortQueryParameterDescriptors)
            });
            pushUrl(location, history, url);

            return new Promise<boolean>(resolve => {
                const subscription = observable.subscribe(result => {
                    if (result.length > 0) {
                        setItems(request?.reload ? result : items.concat(result));
                    }
                    resolve(result.length === 0);
                });
                setUnsubscribe(() => subscription.unsubscribe());
            });
        }, [items, reportDate, movingAvgDays, showPlaystoreActivity, location]);

    useEffect(() => {
        const subscription = defer(() => api.getVersionsByDate(reportDate)).subscribe(inputVersions => {
            setVersions(undefined);
            const vers = inputVersions.map(a => a.split(".").map(n => +n + 100000).join(".")).sort().reverse()
                .map(a => a.split(".").map(n => +n - 100000).join("."));
            setVersions(["0.0.0"].concat(vers.slice(0, inputVersions.length - 1)));
        });
        return () => subscription.unsubscribe();
    }, [reportDate]);

    const versionMapper = useCallback((version: string): SuggestionTag => {
        const found = versions?.find(i => i === version);
        return {
            value: found ?? "",
            label: found === "0.0.0" ? "[All]" : (found ?? "")
        };
    }, [versions]);

    const titleRenderer = useMemo(() =>
        (props: CellProps<unknown, GamingMinutesStatisticsSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{
                        route: routes.viewGamingStatistic, args: {
                            appId: props.rowData.applicationId as unknown as string,
                            version: props.rowData.productVersion!,
                            movingAvgDays,
                            endDate: props.rowData.date.slice(0, 10)
                        }
                    }}
                    className="d-inline">
                    {props.rowData.title}
                </RouteLink>
            );
            return <DefaultCellRenderer cellData={link} title={props.rowData.title} {...restProps} />;
        }, [reportDate, movingAvgDays]);

    const appIdRenderer = useMemo(() =>
        (props: CellProps<string, GamingMinutesStatisticsSummary>) => {
            const { cellData, ...restProps } = props;
            const link = (
                <RouteLink
                    user={user}
                    to={{
                        route: routes.viewGamingStatistic, args: {
                            appId: cellData as unknown as string,
                            version: props.rowData.productVersion!,
                            movingAvgDays,
                            endDate: props.rowData.date.slice(0, 10)
                        }
                    }}
                    className="d-inline">
                    {props.rowData.applicationId}
                </RouteLink>
            );
            return <DefaultCellRenderer cellData={link} title={cellData} {...restProps} />;
        }, [reportDate, movingAvgDays]);

    const versionRenderer = useMemo(() =>
        (props: CellProps<number | undefined, GamingMinutesStatisticsSummary>) => {
            const { cellData, ...restProps } = props;
            let text = cellData?.toString();
            if (text === "0.0.0") {
                text = "[All]";
            }
            const title =
                `${text}`;
            return <DefaultCellRenderer cellData={text} title={title} {...restProps} />;
        }, [reportDate, movingAvgDays]);

    const intRenderer = useMemo(() =>
        (props: CellProps<number, GamingMinutesStatisticsSummary>) => {
            const { cellData, ...restProps } = props;
            const text = cellData.toLocaleString(
                undefined,
                { maximumFractionDigits: 0, minimumFractionDigits: 0 }
            );
            const title = `${cellData}`;
            return <StyledDefaultCellRenderer cellData={text} title={title} {...restProps} />;
        }, [reportDate, movingAvgDays]);

    const decimalRenderer = useMemo(() =>
        (props: CellProps<number, GamingMinutesStatisticsSummary>) => {
            const { cellData, ...restProps } = props;
            const text = cellData.toLocaleString(
                undefined,
                { maximumFractionDigits: 2, minimumFractionDigits: 2 }
            );
            const title = `${cellData}`;
            return <StyledDefaultCellRenderer cellData={text} title={title} {...restProps} />;
        }, [reportDate, movingAvgDays]);

    const renderContext = useMemo(
        () => movingAvgDays.toString() + reportDate.toString() + String(showPlaystoreActivity),
        [movingAvgDays, reportDate, showPlaystoreActivity]);

    const select = makeSelect<GamingMinutesStatisticsSummary>();
    return (
        <VerticalBox>
            <DocumentTitle title="Gaming Minutes Statistics" />
            <StatisticsHeader
                user={user}
                title="Gaming Minutes Statistics"
            >
                <HeaderField>
                    <FormGroup check>
                        <Label
                            for="showPlaystoreActivity"
                            check
                            title="Show Playstore Activity">
                            <Input
                                type="checkbox"
                                id="showPlaystoreActivity"
                                name="showPlaystoreActivity"
                                checked={showPlaystoreActivity}
                                onChange={setFromInput(setShowPlaystoreActivity)}
                            />
                            Show Playstore Activity
                        </Label>
                    </FormGroup>
                </HeaderField>
                <HeaderField label="Moving Days">
                    <Input
                        type="number"
                        min={1}
                        step={1}
                        onChange={setFromInput(setMovingAvgDays)}
                        value={movingAvgDays}
                    />
                </HeaderField>
                <HeaderField label="Date">
                    <StatisticsDatePicker
                        id="date"
                        className="form-control"
                        popperPlacement="top-end"
                        selected={reportDate}
                        onChange={changeReportDate}
                        dates={dates}
                    />
                </HeaderField>
            </StatisticsHeader>
            <Content>
                {versions === undefined && <Loader />}
                {versions !== undefined &&
                    <InfiniteTable
                        items={items}
                        load={load}
                        sortBy={sort}
                        filters={tableFilters}
                        renderContext={renderContext}
                        hideTopPanel={true}
                        minimumLoadCount={200}
                    >
                        <Column
                            key="title"
                            title="Title"
                            width={300}
                            dataGetter={select(a => a.title)}
                            cellRenderer={titleRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="appId"
                            title="AppId"
                            width={500}
                            dataGetter={select(a => a.applicationId)}
                            cellRenderer={appIdRenderer}
                            searchRenderer={StringSearch}
                        />
                        <Column
                            key="productVersion"
                            dataGetter={select(a => a.productVersion)}
                            title="Version"
                            width={200}
                            cellRenderer={versionRenderer}
                            searchRenderer={multiValueSearchFactory(versions, versionMapper)}
                        />
                        <Column
                            key="count"
                            title="Count"
                            width={200}
                            dataGetter={select(a => a.count)}
                            cellRenderer={intRenderer}
                        />
                        <Column
                            key="movingAverageCount"
                            title="Moving Avg Count"
                            width={200}
                            dataGetter={select(a => a.movingAverageCount)}
                            cellRenderer={intRenderer}
                        />
                        <Column
                            key="totalMinutes"
                            title="Total (min)"
                            width={200}
                            dataGetter={select(a => a.totalMinutes)}
                            cellRenderer={intRenderer}
                        />
                        <Column
                            key="movingAverageTotalMinutes"
                            title="Moving Avg Total (min)"
                            width={200}
                            dataGetter={select(a => a.movingAverageTotalMinutes)}
                            cellRenderer={intRenderer}
                        />
                        <Column
                            key="averageMinutes"
                            title="Avg (min)"
                            width={200}
                            dataGetter={select(a => a.averageMinutes)}
                            cellRenderer={decimalRenderer}
                        />
                        <Column
                            key="movingAverageAverageMinutes"
                            title="Moving Avg Avg (min)"
                            width={200}
                            dataGetter={select(a => a.movingAverageAverageMinutes)}
                            cellRenderer={decimalRenderer}
                        />
                        <Column
                            key="standardDeviationMinutes"
                            title="Deviation (min)"
                            width={200}
                            dataGetter={select(a => a.standardDeviationMinutes)}
                            cellRenderer={decimalRenderer}
                        />
                    </InfiniteTable>}
            </Content>
        </VerticalBox>);
});