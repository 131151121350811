
import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type ChangesIndicatorProps = {
    originalValue?: unknown;
};

const ChangedIcon = styled(FontAwesomeIcon)`
    vertical-align: top;
    height: 0.75rem;
    margin-left: 0.175rem;
    cursor: help;
`;

export const ChangesIndicator = (props: ChangesIndicatorProps) => {
    const title = ("originalValue" in props)
        ? props.originalValue !== undefined ? `Original value: '${props.originalValue}'` : "Had no value."
        : undefined;

    return (
        <ChangedIcon icon="pencil-alt" title={title}/>
    );
};