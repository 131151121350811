import React, { useCallback, useState } from "react";
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import {
    ListEditor,
    ListEditorProps,
    ListEditorAddControlRendererProps
} from "src/shared/components";
import { setFromInput } from "src/shared/helpers";

interface ControlRendererProps extends ListEditorAddControlRendererProps<string> {
    placeholder?: string;
}

const addControlRenderer = (props: ControlRendererProps) => {
    const [value, setValue] = useState("");
    const add = () => {
        if (value.trim()) {
            props.add(value.trim());
        }
        setValue("");
    };

    return (
        <div>
            <InputGroup className="mt-2">
                <Input
                    type="text"
                    placeholder={props.placeholder ?? "New alias"}
                    value={value}
                    onChange={setFromInput(setValue)}
                    disabled={props.disabled} />
                <InputGroupAddon addonType="append">
                    <Button type="button" color="primary" disabled={props.disabled} onClick={add}>
                        Add
                    </Button>
                </InputGroupAddon>
            </InputGroup>
        </div>
    );
};

export type AliasesListEditorProps = Omit<ListEditorProps<string>, "addControlRenderer"> & {
    placeholder?: string;
};

export const AliasesListEditor = (props: AliasesListEditorProps) => {
    const addRenderer = () => addControlRenderer(props as ControlRendererProps);
    return <ListEditor addControlRenderer={addRenderer} {...props} />;
};
